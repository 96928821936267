var CollectionView, DraftQuestions, NextStepButton, NoneditableDraftQuestionView, QuestionsBrainstormingView, Scrolling, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

CollectionView = require('base/views/collection_view');

DraftQuestions = require('base/models/draft_questions');

NoneditableDraftQuestionView = require('views/scope/noneditable_draft_question_view');

NextStepButton = require('views/scope/next_step_button_view');

Scrolling = require('base/lib/traits/scrolling');

template = require('views/templates/scope/brainstorming');

mediator = require('mediator');

module.exports = QuestionsBrainstormingView = (function(_super) {
  __extends(QuestionsBrainstormingView, _super);

  function QuestionsBrainstormingView() {
    return QuestionsBrainstormingView.__super__.constructor.apply(this, arguments);
  }

  QuestionsBrainstormingView.prototype.className = 'questions-brainstorming';

  QuestionsBrainstormingView.prototype.template = template;

  QuestionsBrainstormingView.prototype.container = '#page-container';

  QuestionsBrainstormingView.prototype.itemView = NoneditableDraftQuestionView;

  QuestionsBrainstormingView.prototype.listSelector = 'ol';

  QuestionsBrainstormingView.prototype.animationDuration = 0;

  QuestionsBrainstormingView.prototype.initialize = function() {
    this.collection = new DraftQuestions();
    QuestionsBrainstormingView.__super__.initialize.apply(this, arguments);
    this._refreshCollection();
    this.delegate('click', 'button.refresh', this._refreshCollection);
    return this.enable(Scrolling, {
      fixedElements: '.information, .bottom-bar'
    });
  };

  QuestionsBrainstormingView.prototype._refreshCollection = function() {
    this.collection.reset();
    this.loading = true;
    this.render();
    return this.options.service.getBrainstormingResults({
      force: this.options.preview
    }).then((function(_this) {
      return function(questions) {
        _this.collection.reset(questions.models);
        _this.loading = false;
        return _this.render();
      };
    })(this));
  };

  QuestionsBrainstormingView.prototype.getTemplateData = function() {
    return _(QuestionsBrainstormingView.__super__.getTemplateData.apply(this, arguments)).extend({
      preview: this.options.preview
    });
  };

  QuestionsBrainstormingView.prototype.afterRender = function() {
    QuestionsBrainstormingView.__super__.afterRender.apply(this, arguments);
    this.$('.questions').toggleClass('box-loading', this.loading);
    return this.subview('next-step', new NextStepButton({
      step: 'questions.brainstorming',
      container: this.$('.bottom-bar')
    }));
  };

  return QuestionsBrainstormingView;

})(CollectionView);
