var FileSaver, Outcome, Rm5Export, formatters, mediator, rm5ContOutcome, rm5DichOutcome, rm5ExportConfig, rm5NarrativeOutcome, rm5TableHeader, rm5Thead, sofTemplate,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

mediator = require('mediator');

sofTemplate = require('views/templates/export/sof');

Outcome = require('models/outcome');

FileSaver = require('lib/file_saver');

formatters = require('lib/formatters');

rm5ExportConfig = require('lib/rm5_export_config');

rm5TableHeader = require('lib/rm5export/rm5_table_header');

rm5Thead = require('lib/rm5export/rm5_thead');

rm5DichOutcome = require('lib/rm5export/rm5_dich_outcome');

rm5ContOutcome = require('lib/rm5export/rm5_cont_outcome');

rm5NarrativeOutcome = require('lib/rm5export/rm5_narrative_outcome');

module.exports = Rm5Export = (function() {
  Rm5Export.prototype.MAX_FILENAME_LEN = 60;

  function Rm5Export(model, viewType, chosenOutcomeCids) {
    this.model = model;
    this.viewType = viewType;
    this.chosenOutcomeCids = chosenOutcomeCids;
  }

  Rm5Export.prototype["export"] = function() {
    var content, fileName, reader, shortened;
    shortened = _.str.prune(mediator.project.get('name'), this.MAX_FILENAME_LEN, '');
    this.currentFile = {
      name: _.str.slugify(shortened) + '.sof',
      newSof: true
    };
    if (this.currentFile.imported) {
      content = mediator.project.get('importedFileContent');
      fileName = mediator.project.get('importedFileName');
      return this.appendToFile(content, fileName);
    } else if (this.currentFile.newSof) {
      return this.createFile(this.currentFile.name);
    } else {
      reader = new FileReader();
      reader.onload = (function(_this) {
        return function(loadEvent) {
          return _this.appendToFile(loadEvent.target.result, _this.currentFile.name);
        };
      })(this);
      return utils.detectRm5Encoding(this.currentFile).done((function(_this) {
        return function(encoding) {
          return reader.readAsText(_this.currentFile, encoding);
        };
      })(this)).fail(mediator.dialogs.fatalError);
    }
  };

  Rm5Export.prototype.writeFile = function(fileName, content, xmlEncoding, xmlVersion) {
    var blob, blobContent;
    blobContent = [content];
    if (xmlVersion) {
      blobContent.splice(0, 0, "<?xml version='" + xmlVersion + "' encoding='" + xmlEncoding + "'?>");
    }
    blob = new Blob(blobContent, {
      type: "text/xml;charset=" + xmlEncoding
    });
    return FileSaver.saveFile(blob, fileName).then(function() {
      return mediator.dialogs.success({
        message: $.t('projects:export_dialog.rm5_export_successful'),
        ms: 10000,
        closeBtn: true
      });
    }, function(reason) {
      if (reason !== 'cancelled') {
        return mediator.dialogs.error(reason);
      }
    });
  };

  Rm5Export.prototype.appendToFile = function(content, fileName) {
    var $xml, sofTable, sofTableTemplate, stringDoc, xmldoc;
    $xml = $($.parseXML(content));
    sofTableTemplate = this.prepareSofTable($xml.find('SOF_TABLES').find('SOF_TABLE').length);
    sofTable = $($.parseXML(sofTableTemplate))[0];
    $xml.find('SOF_TABLES').append(sofTable.documentElement);
    xmldoc = $xml[0];
    stringDoc = (new XMLSerializer).serializeToString(xmldoc.documentElement);
    return this.writeFile(fileName, stringDoc, 'utf-8', xmldoc.xmlVersion);
  };

  Rm5Export.prototype.createFile = function(fileName) {
    var table;
    table = this.prepareSofTable(1);
    return this.writeFile(fileName, table, 'utf-8');
  };

  Rm5Export.prototype.prepareSofTable = function(tableNo) {
    var templ;
    templ = sofTemplate(this.getSofData(tableNo)).replace(/<br>/g, '<BR/>');
    return templ.replace(/<[a-z]+.*?>|<\/[a-z]+>/g, '');
  };

  Rm5Export.prototype._escStringAttrs = function(obj) {
    var key, val;
    for (key in obj) {
      val = obj[key];
      if (_.isString(val)) {
        obj[key] = _.escape(val);
      }
    }
    return obj;
  };

  Rm5Export.prototype._calcDichRowspan = function(outcomeAttrs, controlChecks) {
    var _ref;
    if ((_ref = outcomeAttrs.metaType) === 'NOT_MEASURED' || _ref === 'NOT_REPORTED') {
      return 1;
    } else {
      return controlChecks.length * 2;
    }
  };

  Rm5Export.prototype._calcTableColsNo = function(thead) {
    var cell, colsNo, _i, _len, _ref;
    colsNo = 0;
    _ref = thead[0].cells;
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      cell = _ref[_i];
      colsNo += cell.colspan;
    }
    return colsNo;
  };

  Rm5Export.prototype._prepareDichOutcomeRows = function(attributes, controlChecks) {
    var rowspan;
    rowspan = this._calcDichRowspan(attributes, controlChecks);
    return {
      rows: rm5DichOutcome(attributes, rowspan, controlChecks)
    };
  };

  Rm5Export.prototype._prepareFootnotes = function(outcomeFootnotes, activeNotes) {
    var attrName, notesIds, notesIndexes, preapredNotes;
    preapredNotes = {};
    for (attrName in outcomeFootnotes) {
      notesIds = outcomeFootnotes[attrName];
      if (_(notesIds).isEmpty()) {
        continue;
      }
      notesIndexes = [];
      _(notesIds).forEach((function(_this) {
        return function(id) {
          var index;
          if (!_this.model.get('footnotesList').get(id)) {
            return;
          }
          activeNotes.add(id);
          index = _(activeNotes).indexOf(id) + 1;
          return notesIndexes.push(index);
        };
      })(this));
      preapredNotes[attrName] = notesIndexes;
    }
    return preapredNotes;
  };

  Rm5Export.prototype._prepareOutcomeRows = function(outcomeAttrs, controlChecks) {
    var rows, tableMode, type;
    type = outcomeAttrs.type;
    tableMode = this.model.get('$tableMode');
    rows = [];
    _(outcomeAttrs).extend({
      tableView: this.viewType
    });
    outcomeAttrs = this._escStringAttrs(outcomeAttrs);
    switch (type) {
      case 'dich':
        return this._prepareDichOutcomeRows(outcomeAttrs, controlChecks);
      case 'cont':
        rows.push(rm5ContOutcome(outcomeAttrs));
        return {
          rows: rows
        };
      case 'narrative':
        rows.push(tableMode === 'narrative' ? rm5NarrativeOutcome(outcomeAttrs, 'narrative') : rm5NarrativeOutcome(outcomeAttrs));
        return {
          rows: rows
        };
    }
  };

  Rm5Export.prototype.getSofData = function(tableNo) {
    var activeControlChecks, activeNotes, data, footnotes, outcome, outcomeAttrs, outcomeData, outcomeFootnotes, outcomes, _i, _len, _ref, _ref1, _ref2;
    data = {
      title: this.model.get('sofTitle'),
      modificationDate: ((_ref = moment(this.model.get('$timestamp'))) != null ? _ref.format() : void 0) || 0,
      hpOrPop: this.model.get('healthProblemOrPopulation'),
      settings: this.model.get('settings') || '',
      intervention: this.model.get('intervention'),
      comparison: this.model.get('comparison'),
      bibliography: this.model.get('bibliography') || '',
      sofTableNo: _.str.pad(tableNo, 2, '0'),
      tableMode: this.model.get('$tableMode'),
      rowsNo: 4
    };
    this._escStringAttrs(data);
    data.thead = data.tableMode === 'narrative' ? rm5Thead(this.viewType) : rm5Thead(this.viewType, data.comparison, data.intervention);
    data.colsNo = this._calcTableColsNo(data.thead);
    data.tableHeader = rm5TableHeader(this.viewType, data);
    outcomes = [];
    activeNotes = [];
    activeNotes.add = function(id) {
      if (_(this).indexOf(id) === -1) {
        return this.push(id);
      }
    };
    _ref1 = this.model.get('outcomes').models;
    for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
      outcome = _ref1[_i];
      if (!(_ref2 = outcome.cid, __indexOf.call(this.chosenOutcomeCids, _ref2) >= 0)) {
        continue;
      }
      outcomeAttrs = JSON.parse(JSON.stringify(outcome));
      activeControlChecks = outcome.getActiveControlChecks();
      outcomeFootnotes = outcomeAttrs.footnotes;
      if (!_(outcomeFootnotes).isEmpty()) {
        footnotes = this._prepareFootnotes(outcomeFootnotes, activeNotes);
        outcomeAttrs.footnotes = footnotes;
      }
      outcomeData = this._prepareOutcomeRows(outcomeAttrs, activeControlChecks);
      data.rowsNo += outcomeData.rows.length;
      outcomes.push(outcomeData);
    }
    data.outcomes = outcomes;
    if (!_(activeNotes).isEmpty()) {
      data.footnotesList = [];
      _(activeNotes).forEach((function(_this) {
        return function(noteId) {
          return data.footnotesList.push({
            note: _this.model.get('footnotesList').get(noteId)
          });
        };
      })(this));
    }
    return data;
  };

  return Rm5Export;

})();
