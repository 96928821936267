Tooltip = require 'components/common/tooltip'
Spinner = require 'components/common/spinner'

Button = React.createClass
  render: ->
    <Tooltip>
      <button {...this.props} style={position: 'relative'} disabled={@props.loading}>
        {if @props.loading
          <Spinner options={scale: 0.5}/>
        }
        {@props.label}
      </button>
    </Tooltip>

module.exports = Button
