var EditableItemView, ProjectSharingView, ProjectsListItemQuestionsView, ProjectsListItemView, View, mediator, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

EditableItemView = require('base/views/editable_item_view');

View = require('base/views/view');

template = require('views/templates/projects_list_item');

mediator = require('mediator');

ProjectsListItemQuestionsView = require('views/projects_list_item_questions_view');

ProjectSharingView = require('views/project_sharing_view');

module.exports = ProjectsListItemView = (function(_super) {
  __extends(ProjectsListItemView, _super);

  function ProjectsListItemView() {
    this._projectSharedAction = __bind(this._projectSharedAction, this);
    this.saveChanges = __bind(this.saveChanges, this);
    this.showEditBox = __bind(this.showEditBox, this);
    this._switchQuestions = __bind(this._switchQuestions, this);
    return ProjectsListItemView.__super__.constructor.apply(this, arguments);
  }

  ProjectsListItemView.prototype.template = template;

  ProjectsListItemView.prototype.className = 'projects-list-item';

  ProjectsListItemView.prototype.tagName = 'tbody';

  ProjectsListItemView.prototype.bindings = {
    '#name': 'name',
    '#customer': 'customer',
    '#orderNumber': 'orderNumber'
  };

  ProjectsListItemView.prototype.initialize = function() {
    ProjectsListItemView.__super__.initialize.apply(this, arguments);
    this.delegate('click', '.toggle-button', this._switchQuestions);
    this.delegate('click', '.share', this._shareProject);
    this.delegate('click', '.archive', this._archiveProject);
    this.delegate('click', 'button.activate.action', this._activateProject);
    this.subscribeEvent('projectShared', this._projectSharedAction);
    return this.subscribeEvent('projects:active_projects_sorted', (function(_this) {
      return function() {
        var idx, _ref;
        idx = (_ref = _this.parentView.collection) != null ? _ref.indexOf(_this.model) : void 0;
        if ((idx != null) && idx !== -1) {
          _this.model.orderNumber = _this.parentView.collection._getItemOrderNumber(idx);
          return _this.render();
        }
      };
    })(this));
  };

  ProjectsListItemView.prototype.getTemplateData = function() {
    return {
      id: this.model.id,
      name: this.model.attributes.name,
      orderNumber: this.model.orderNumber,
      customer: this.model.attributes.customer,
      date: this.model.changeTime,
      isActive: this.parentView.options.isActive,
      isMenuMode: this.parentView.options.isMenuMode,
      editing: this.editing,
      shared: this.model.shared != null
    };
  };

  ProjectsListItemView.prototype.afterRender = function() {
    this.stickit();
    return this.model.getCollections().questions.fetch().then((function(_this) {
      return function(questions) {
        _this.questions = questions;
        return _this._renderQuestions();
      };
    })(this));
  };

  ProjectsListItemView.prototype._switchQuestions = function(e) {
    if (this.editing) {
      return this._close();
    } else {
      this._renderQuestions();
      return this._toggleQuestions(e);
    }
  };

  ProjectsListItemView.prototype._renderQuestions = function() {
    var _ref;
    if ((_ref = this.model) != null ? _ref.id : void 0) {
      return this.subview('projectQuestions', new ProjectsListItemQuestionsView({
        questions: this.questions,
        projectId: this.model.id,
        container: this.$el.find('.questions-list-container')[0]
      }));
    }
  };

  ProjectsListItemView.prototype._toggleQuestions = function(e) {
    if (e != null) {
      e.stopPropagation();
    }
    return this.$el.toggleClass('opened');
  };

  ProjectsListItemView.prototype.showEditBox = function() {
    this.model.attributes.orderNumber = this.model.orderNumber;
    ProjectsListItemView.__super__.showEditBox.apply(this, arguments);
    return this._renderQuestions();
  };

  ProjectsListItemView.prototype.saveChanges = function() {
    var fromIdx, toIdx;
    if (this.model.attributes.orderNumber) {
      if (this.model.attributes.orderNumber >= this.parentView.collection.length) {
        this.model.attributes.orderNumber = this.parentView.collection.length;
      }
      fromIdx = this.model.orderNumber - 1;
      toIdx = parseInt(this.model.attributes.orderNumber) - 1;
      delete this.model.attributes.orderNumber;
      mediator.publish('projects:active_projects_reordered', this.model.id, fromIdx, toIdx);
    }
    this._toggleQuestions();
    return ProjectsListItemView.__super__.saveChanges.apply(this, arguments);
  };

  ProjectsListItemView.prototype._shareProject = function() {
    return this.parentView.subview('projectSharing', new ProjectSharingView({
      model: this.model
    }));
  };

  ProjectsListItemView.prototype._projectSharedAction = function(projectId) {
    if (this.model.id === projectId) {
      return this.render();
    }
  };

  ProjectsListItemView.prototype._archiveProject = function() {
    return mediator.dialogs.confirm({
      yesClass: 'success',
      message: $.t('projects:archive_confirmation')
    }, (function(_this) {
      return function(confirmed) {
        if (!confirmed) {
          return;
        }
        _this._close();
        mediator.user.moveProjectToTrash(_this.model.id);
        return mediator.publish('projects:project_archived', _this.model);
      };
    })(this));
  };

  ProjectsListItemView.prototype._activateProject = function() {
    return mediator.dialogs.confirm({
      yesClass: 'success',
      message: $.t('projects:activate_confirmation')
    }, (function(_this) {
      return function(confirmed) {
        if (!confirmed) {
          return;
        }
        _this._close();
        mediator.user.restoreProjectFromTrash(_this.model.id);
        return mediator.publish('projects:project_activated', _this.model.id);
      };
    })(this));
  };

  ProjectsListItemView.prototype["delete"] = function() {
    return mediator.dialogs.confirm({
      yesClass: 'success',
      message: $.t('projects:delete_confirmation')
    }, (function(_this) {
      return function(confirmed) {
        if (!confirmed) {
          return;
        }
        _this._close();
        mediator.user.irreversiblyDestroyProjectAndAllItsData(_this.model.id);
        return mediator.publish('projects:project_removed', _this.model.id);
      };
    })(this));
  };

  return ProjectsListItemView;

})(EditableItemView);
