var BottomPaneToggleableView, View, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

template = require('views/templates/bottom_pane_toggleable');

module.exports = BottomPaneToggleableView = (function(_super) {
  __extends(BottomPaneToggleableView, _super);

  function BottomPaneToggleableView() {
    this._adjustSiblingHeight = __bind(this._adjustSiblingHeight, this);
    return BottomPaneToggleableView.__super__.constructor.apply(this, arguments);
  }

  BottomPaneToggleableView.prototype.className = 'bottom-toggleable-pane';

  BottomPaneToggleableView.prototype.template = template;

  BottomPaneToggleableView.prototype.container = 'body';

  BottomPaneToggleableView.prototype.paneTitle = '';

  BottomPaneToggleableView.prototype.paneContentTemplate = null;

  BottomPaneToggleableView.prototype.paneContentData = null;

  BottomPaneToggleableView.prototype.initialize = function() {
    BottomPaneToggleableView.__super__.initialize.apply(this, arguments);
    if (!this.paneContentTemplate) {
      throw new Error('No `paneContentTemplate` set');
    }
    this.paneContentData = this.options.paneContentData;
    this.options.parentView.on('addedToDOM', this._adjustSiblingHeight);
    this.$container = $(this.container);
    this._attachBottomPane();
    this.closed = true;
    return this.delegate('click', '.bottom-pane-bar', this.togglePanel);
  };

  BottomPaneToggleableView.prototype._attachBottomPane = function() {
    this.render();
    this.$container.append(this.$el);
    this.$container.css({
      'padding-bottom': this.$el.height()
    });
    return this._addPaneContentTemplate();
  };

  BottomPaneToggleableView.prototype._addPaneContentTemplate = function() {
    return this.$('.pane-content').append(this.paneContentTemplate(this.paneContentData));
  };

  BottomPaneToggleableView.prototype._adjustSiblingHeight = function() {
    this.$paneSibling = this.$container.children(':not(.bottom-toggleable-pane)').eq(0);
    return this.$paneSibling.height(this.$container.height() - this.$el.height());
  };

  BottomPaneToggleableView.prototype.togglePanel = function() {
    var $paneContent, _ref;
    $paneContent = this.$('.pane-content');
    this.$paneBar = (_ref = this.$paneBar) != null ? _ref : this.$('.bottom-pane-bar');
    if (this.closed) {
      $paneContent.show();
      this.$paneBar.removeClass('closed');
      this.$paneBar.addClass('opened');
    } else {
      $paneContent.hide();
      this.$paneBar.removeClass('opened');
      this.$paneBar.addClass('closed');
    }
    this.closed = !this.closed;
    return this._adjustSiblingHeight();
  };

  BottomPaneToggleableView.prototype.getTemplateData = function() {
    return {
      paneTitle: this.paneTitle
    };
  };

  BottomPaneToggleableView.prototype._hide = function() {
    this.options.parentView.off('addedToDOM', this._adjustSiblingHeight);
    this.$el.remove();
    return this._adjustSiblingHeight();
  };

  BottomPaneToggleableView.prototype.dispose = function() {
    this._hide();
    return BottomPaneToggleableView.__super__.dispose.apply(this, arguments);
  };

  return BottomPaneToggleableView;

})(View);
