var createData, namespace, viewHelperUtils;

viewHelperUtils = require('lib/view_helper_utils');

namespace = 'es:outcome.';

createData = function(itemData, dataColNo, params) {
  var data, k, v;
  data = itemData === void 0 ? {} : _(itemData).clone();
  data.colspan = null;
  data.dataColNo = dataColNo;
  if (_(params).isString()) {
    params = JSON.parse(params);
    for (k in params) {
      v = params[k];
      data[k] = v;
    }
  }
  data.className = typeof params.className === 'string' ? params.className : '';
  return data;
};

Handlebars.registerHelper('controlNotEditableCell', function(itemData, dataColNo, params) {
  var data, message, partial;
  partial = 'views/templates/evidence_syntheses/cells/not_editable';
  data = createData(itemData, dataColNo, params);
  message = '-';
  if (data.type === 'dich' && data.viewTypeName !== 'OneRow') {
    message = viewHelperUtils.i18n(namespace, 'see_comment');
  }
  if (data.metaType === 'NOT_POOLED') {
    message = viewHelperUtils.i18n(namespace, 'not_pooled');
  }
  data.value = message;
  return viewHelperUtils.regPartial(name, partial, data);
});

Handlebars.registerHelper('absoluteEffectNotEditableCell', function(itemData, dataColNo, params) {
  var data, message, partial;
  partial = 'views/templates/evidence_syntheses/cells/not_editable';
  data = createData(itemData, dataColNo, params);
  message = '-';
  if (data.metaType === 'NOT_POOLED') {
    message = data.viewTypeName === 'OneRow' ? viewHelperUtils.i18n(namespace, 'not_pooled') : viewHelperUtils.i18n(namespace, 'see_comment');
  } else if (data.metaType === 'RANGE_OF_EFFECTS') {
    if (data.viewTypeName !== 'OneRow') {
      message = viewHelperUtils.i18n(namespace, 'not_estimable');
    }
  } else {
    if (data.viewTypeName !== 'OneRow') {
      message = viewHelperUtils.i18n(namespace, 'see_comment');
    }
  }
  data.value = message;
  return viewHelperUtils.regPartial(name, partial, data);
});

Handlebars.registerHelper('relativeEffectNotEditableCell', function(itemData, dataColNo, params) {
  var data, message, partial;
  partial = 'views/templates/evidence_syntheses/cells/not_editable';
  data = createData(itemData, dataColNo, params);
  message = '-';
  if (data.type === 'dich') {
    message = data.metaType === 'NOT_POOLED' ? viewHelperUtils.i18n(namespace, 'not_pooled') : data.viewTypeName !== 'OneRow' ? viewHelperUtils.i18n(namespace, 'not_estimable') : void 0;
  }
  data.value = message;
  return viewHelperUtils.regPartial(name, partial, data);
});

Handlebars.registerHelper('riskNotEditableCell', function(itemData, dataColNo, params) {
  var data, message, partial;
  partial = 'views/templates/evidence_syntheses/cells/not_editable';
  data = createData(itemData, dataColNo, params);
  message = data.viewTypeName === 'OneRow' ? '-' : viewHelperUtils.i18n(namespace, 'see_comment');
  if (data.metaType === 'NOT_POOLED') {
    message = data.type === 'cont' ? viewHelperUtils.i18n(namespace, 'not_estimable') : data.viewTypeName === 'SOF' ? viewHelperUtils.i18n(namespace, 'see_comment') : viewHelperUtils.i18n(namespace, 'not_pooled');
  }
  data.value = message;
  return viewHelperUtils.regPartial(name, partial, data);
});
