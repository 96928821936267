var Entity, Footnotes,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

Entity = require('base/models/entity');

module.exports = Footnotes = (function(_super) {
  __extends(Footnotes, _super);

  Footnotes.prototype.noExplanationProvided = 'no_explanation_provided';

  function Footnotes(footnotes) {
    this.get = __bind(this.get, this);
    this.set = __bind(this.set, this);
    var id, numericIds, _i, _len, _ref, _ref1, _ref2;
    this.idsOrder = (_ref = footnotes != null ? footnotes.idsOrder : void 0) != null ? _ref : [];
    if (_ref1 = this.noExplanationProvided, __indexOf.call(this.idsOrder, _ref1) < 0) {
      this.idsOrder.splice(0, 0, this.noExplanationProvided);
    }
    numericIds = this.idsOrder.filter(function(id) {
      return _.isFinite(id);
    });
    this.currentId = footnotes != null ? footnotes.currentId : void 0;
    if (this.currentId == null) {
      this.currentId = numericIds.length ? _.max(_.map(numericIds, function(item) {
        return parseInt(item);
      })) : 0;
    }
    this.notes = {};
    this.notes[this.noExplanationProvided] = footnotes != null ? (_ref2 = footnotes.notes) != null ? _ref2[this.noExplanationProvided] : void 0 : void 0;
    for (_i = 0, _len = numericIds.length; _i < _len; _i++) {
      id = numericIds[_i];
      this.notes[id] = footnotes.notes[id];
    }
  }

  Footnotes.prototype.addNote = function(noteText) {
    this.currentId += 1;
    this.idsOrder.push(this.currentId);
    this.notes[this.currentId] = noteText;
    return this.currentId;
  };

  Footnotes.prototype.updateNote = function(id, newVal) {
    return this.notes[id] = newVal;
  };

  Footnotes.prototype.removeNote = function(id) {
    delete this.notes[id];
    this.idsOrder.splice(this.idsOrder.indexOf(parseInt(id)), 1);
    return this.idsOrder;
  };

  Footnotes.prototype.moveFootnote = function(fromIdx, toIdx) {
    var tmp;
    tmp = this.idsOrder[fromIdx];
    this.idsOrder.splice(fromIdx, 1);
    return this.idsOrder.splice(toIdx, 0, tmp);
  };

  Footnotes.prototype.sortFootnotes = function(tableFootnoteIds) {
    var prevOrder;
    prevOrder = _.clone(this.idsOrder);
    this.idsOrder = _(this.idsOrder).sortBy(function(v) {
      var idx;
      idx = tableFootnoteIds.indexOf(v);
      if (idx < 0) {
        return tableFootnoteIds.length;
      } else {
        return idx;
      }
    });
    return !_.isEqual(prevOrder, this.idsOrder);
  };

  Footnotes.prototype.set = function(id, text) {
    return this.notes[id] = text;
  };

  Footnotes.prototype.get = function(id) {
    return this.notes[id];
  };

  Footnotes.prototype.getNotesList = function(includeSpecialNotes, notesIds) {
    var getNoteObject, id, idx, notesList, _i, _len;
    if (includeSpecialNotes == null) {
      includeSpecialNotes = true;
    }
    if (notesIds == null) {
      notesIds = null;
    }
    getNoteObject = (function(_this) {
      return function(noteId, idx) {
        return {
          id: noteId,
          idx: idx,
          note: _.isFinite(noteId) ? _this.notes[noteId] : $.t("es:footnotes.special." + noteId),
          special: !_.isFinite(noteId)
        };
      };
    })(this);
    notesList = [];
    idx = 0;
    notesIds = notesIds ? _(this.idsOrder).intersection(notesIds) : this.idsOrder;
    for (_i = 0, _len = notesIds.length; _i < _len; _i++) {
      id = notesIds[_i];
      if (_.isFinite(id) || (!_.isFinite(id) && includeSpecialNotes)) {
        notesList.push(getNoteObject(id, idx));
        idx += 1;
      }
    }
    return notesList;
  };

  return Footnotes;

})(Entity);
