var VotingActions, VotingStore, alt, immutable;

alt = require('alt');

immutable = require('stores/utils/immutable');

VotingActions = require('actions/voting_actions');

VotingStore = (function() {
  function VotingStore() {
    this.state = Immutable.Map({
      voting: null,
      votingStatuses: Immutable.Map()
    });
    this.bindActions(VotingActions);
    this.exportPublicMethods({
      getVoting: this.getVoting,
      getMailMessage: this.getMailMessage,
      isFetching: this.isFetching,
      getVotingStatuses: this.getVotingStatuses,
      isSendingMail: this.isSendingMail,
      isFetchingVotingResults: this.isFetchingVotingResults,
      getVotingResults: this.getVotingResults,
      isRefreshingVotingStatuses: this.isRefreshingVotingStatuses
    });
  }

  VotingStore.prototype.getVoting = function() {
    return this.state.get('voting');
  };

  VotingStore.prototype.getMailMessage = function(type) {
    return this.state.getIn(['voting', type]);
  };

  VotingStore.prototype.isFetching = function() {
    return this.state.getIn(['_meta', 'fetching']);
  };

  VotingStore.prototype.isSendingMail = function() {
    return this.state.getIn(['_meta', 'sendingMail']);
  };

  VotingStore.prototype.isRefreshingVotingStatuses = function() {
    return this.state.getIn(['_meta', 'refreshingVotingStatuses']);
  };

  VotingStore.prototype.isFetchingVotingResults = function() {
    return this.state.getIn(['_meta', 'fetchingVotingResults']);
  };

  VotingStore.prototype.getVotingResults = function() {
    return this.state.get('votingResults');
  };

  VotingStore.prototype.getVotingStatuses = function() {
    return this.state.getIn(['votingStatuses']);
  };

  VotingStore.prototype.onUpdateMailMessage = function(data) {
    return this.setState(this.state.setIn(['voting', data.messageType], data.message));
  };

  VotingStore.prototype.onSendMailMessage = function() {
    return this.setState(this.state.setIn(['_meta', 'sendingMail'], true));
  };

  VotingStore.prototype.onSendMailMessageSuccess = function() {
    return this.setState(this.state.setIn(['_meta', 'sendingMail'], false));
  };

  VotingStore.prototype.onSendMailMessageFailure = function(e) {
    this.setState(this.state.setIn(['_meta', 'sendingMail'], false));
    if (typeof Raven !== "undefined" && Raven !== null) {
      Raven.captureException(e);
    }
    return console.error(e);
  };

  VotingStore.prototype.onRefreshVotingStatuses = function() {
    return this.setState(this.state.setIn(['_meta', 'refreshingVotingStatuses'], true));
  };

  VotingStore.prototype.onRefreshVotingStatusesSuccess = function(data) {
    return this.setState(this.state.withMutations(function(state) {
      state.update('votingStatuses', function(votingStatuses) {
        return votingStatuses.merge(data.votingStatuses);
      });
      return state.setIn(['_meta', 'refreshingVotingStatuses'], false);
    }));
  };

  VotingStore.prototype.onRefreshVotingStatusesFailure = function(e) {
    this.state.setIn(['_meta', 'refreshingVotingStatuses'], false);
    if (typeof Raven !== "undefined" && Raven !== null) {
      Raven.captureException(e);
    }
    return console.error(e);
  };

  VotingStore.prototype.onFetchVotingResults = function() {
    return this.setState(this.state.setIn(['_meta', 'fetchingVotingResults'], true));
  };

  VotingStore.prototype.onFetchVotingResultsSuccess = function(votingResults) {
    return this.setState(this.state.withMutations(function(state) {
      state.set('votingResults', votingResults);
      return state.setIn(['_meta', 'fetchingVotingResults'], false);
    }));
  };

  VotingStore.prototype.onFetchVotingResultsFailure = function(e) {
    return this.setState(this.state.setIn(['_meta', 'fetchingVotingResults'], false));
  };

  VotingStore.prototype.onFetch = function() {
    return this.setState(this.state.setIn(['_meta', 'fetching'], true));
  };

  VotingStore.prototype.onFetchSuccess = function(data) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'fetching'], false);
      state.set('voting', Immutable.fromJS(data.doc));
      return state.set('votingStatuses', Immutable.fromJS(data.votingStatuses));
    }));
  };

  VotingStore.prototype.onFetchFailure = function(e) {
    this.setState(this.state.setIn(['_meta', 'fetching'], false));
    if (typeof Raven !== "undefined" && Raven !== null) {
      Raven.captureException(e);
    }
    return console.error(e);
  };

  VotingStore.prototype.onSaveSuccess = function(data) {
    return this.setState(this.state.set(data.storeKey, Immutable.fromJS(data.doc)));
  };

  VotingStore.prototype.onSaveFailure = function(e) {
    if (typeof Raven !== "undefined" && Raven !== null) {
      Raven.captureException(e);
    }
    return console.error(e);
  };

  return VotingStore;

})();

module.exports = alt.createStore(immutable(VotingStore), 'VotingStore');
