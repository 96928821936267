var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compoundEdit || (depth0 && depth0.compoundEdit) || helperMissing).call(depth0, "correspondingRisk", "corresponding_risk", {"name":"compoundEdit","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "    <div class=\"edit-input-block form-group row mt-10\" data-property=\"effectMeasure\">\n      <div class=\"col-3\">\n        <label for=\"effectMeasure\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "estimate_of_the_effect", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n      </div>\n      <div class=\"col-3\">\n        <div class=\"select-input-container  ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.effectMeasure : depth0), "other", {"name":"is","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n          "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "effectMeasure", (depth0 != null ? depth0.effectMeasure : depth0), "MD: measure.md, SMD: measure.smd, mean: measure.mean,\n          median: measure.median, other: measure.other", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n          <input type=\"text\" name=\"otherMeasure\" value=\""
    + escapeExpression(((helper = (helper = helpers.otherMeasure || (depth0 != null ? depth0.otherMeasure : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"otherMeasure","hash":{},"data":data}) : helper)))
    + "\"\n            ";
  stack1 = ((helpers.isnt || (depth0 && depth0.isnt) || helperMissing).call(depth0, (depth0 != null ? depth0.effectMeasure : depth0), "other", {"name":"isnt","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n          </div>\n      </div>\n      <div class=\"col-1\">\n        <label for=\"effectSize\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "relative_of", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n      </div>\n      <div class=\"col-2\">\n        <input type=\"text\" name=\"effectSize\" id=\"effectSize\" class=\"form-control\"\n          value=\""
    + escapeExpression(((helper = (helper = helpers.effectSize || (depth0 != null ? depth0.effectSize : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"effectSize","hash":{},"data":data}) : helper)))
    + "\">\n      </div>\n      <div class=\"col-2 countable-uncountable-select\">\n        "
    + escapeExpression(((helpers.unitSelectableAttr || (depth0 && depth0.unitSelectableAttr) || helperMissing).call(depth0, depth0, {"name":"unitSelectableAttr","hash":{},"data":data})))
    + "\n      </div>\n      <div class=\"col-1 sup\"></div>\n    </div>\n    <div class=\"edit-input-block form-group row mt-10\" data-property=\"confidenceIntervalTo\">\n      <div class=\"col-3\">\n        <label for=\"from_ci\">95% CI ("
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "confidence_limits", {"name":"i18n","hash":{},"data":data})))
    + ") "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "from", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n      </div>\n      <div class=\"col-3\">\n        <input class=\"form-control\" id=\"from_ci\" type=\"text\"\n          name=\"confidenceIntervalFrom\" value=\""
    + escapeExpression(((helper = (helper = helpers.confidenceIntervalFrom || (depth0 != null ? depth0.confidenceIntervalFrom : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"confidenceIntervalFrom","hash":{},"data":data}) : helper)))
    + "\">\n      </div>\n      <div class=\"col-1\">\n        <label for=\"to_ci\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "to", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n      </div>\n      <div class=\"col-4\">\n        <input class=\"form-control\" id=\"to_ci\" type=\"text\"\n          name=\"confidenceIntervalTo\" value=\""
    + escapeExpression(((helper = (helper = helpers.confidenceIntervalTo || (depth0 != null ? depth0.confidenceIntervalTo : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"confidenceIntervalTo","hash":{},"data":data}) : helper)))
    + "\">\n      </div>\n      <div class=\"col-1 sup\"></div>\n    </div>\n";
},"4":function(depth0,helpers,partials,data) {
  return "other";
  },"6":function(depth0,helpers,partials,data) {
  return "style=\"display: none\"";
  },"8":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compoundEdit || (depth0 && depth0.compoundEdit) || helperMissing).call(depth0, "intervention", "intervention", {"name":"compoundEdit","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"9":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"10":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "    <div class=\"row mt-10\">\n      <div class=\"col-11\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "edit_infos.enter_intervention_data", {"name":"i18n","hash":{},"data":data})))
    + "\n      </div>\n    </div>\n    <div class=\"edit-input-block form-group row mt-10\">\n      <div class=\"col-4\">\n        <label for=\"interventionCount\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "with_event", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n      </div>\n      <div class=\"col-7\">\n        <input id=\"interventionCount\" name=\"interventionCount\" class=\"form-control\"\n        type=\"text\" value=\""
    + escapeExpression(((helper = (helper = helpers.interventionCount || (depth0 != null ? depth0.interventionCount : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"interventionCount","hash":{},"data":data}) : helper)))
    + "\">\n      </div>\n      <div class=\"col-1 sup\"></div>\n    </div>\n    <div class=\"edit-input-block form-group row mt-10\">\n      <div class=\"col-4\">\n        <label for=\"interventionTotal\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "total", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n      </div>\n      <div class=\"col-7\">\n        <input id=\"interventionTotal\" name=\"interventionTotal\" class=\"form-control\"\n        type=\"text\" value=\""
    + escapeExpression(((helper = (helper = helpers.interventionTotal || (depth0 != null ? depth0.interventionTotal : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"interventionTotal","hash":{},"data":data}) : helper)))
    + "\">\n      </div>\n      <div class=\"col-1 sup\"></div>\n    </div>\n    <hr>\n    <div class=\"row mt-10\">\n      <div class=\"col-11\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "edit_infos.sof_data_auto_calculated", {"name":"i18n","hash":{},"data":data})))
    + "\n      </div>\n    </div>\n    <div class=\"row mt-10\">\n      <div class=\"col-11\">\n        "
    + escapeExpression(((helpers.displayDichCorrespondingRiskEditInfo || (depth0 && depth0.displayDichCorrespondingRiskEditInfo) || helperMissing).call(depth0, depth0, (depth0 != null ? depth0.controlRiskLevel : depth0), {"name":"displayDichCorrespondingRiskEditInfo","hash":{},"data":data})))
    + "\n      </div>\n    </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "cont", {"name":"is","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "dich", {"name":"is","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}