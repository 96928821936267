var MemberRatingStatusView, View, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

template = require('views/templates/scope/member_rating_status');

module.exports = MemberRatingStatusView = (function(_super) {
  __extends(MemberRatingStatusView, _super);

  function MemberRatingStatusView() {
    this.className = __bind(this.className, this);
    return MemberRatingStatusView.__super__.constructor.apply(this, arguments);
  }

  MemberRatingStatusView.prototype.tagName = 'li';

  MemberRatingStatusView.prototype.className = function() {
    var _ref;
    return (_ref = this.model.get('userRatingStatus')) != null ? _ref : this.model.get('status');
  };

  MemberRatingStatusView.prototype.template = template;

  MemberRatingStatusView.prototype.getTemplateData = function() {
    return MemberRatingStatusView.__super__.getTemplateData.apply(this, arguments);
  };

  return MemberRatingStatusView;

})(View);
