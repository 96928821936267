Button = require './button'
ListItem = require './list_item'
List = require './list'
Label = require './label'
RadioInput = require './radio_input'
Translation = require '../mixins/translation'

InsertDialog = React.createClass
  mixins: [Translation('es:recommendations.insert_modal')]

  propTypes:
    insertOptions: React.PropTypes.array.isRequired
    onSubmit: React.PropTypes.func.isRequired

  getInitialState: ->
    selectedOption: ''
    file: null
    wrongFile: null

  close: ->
    @props.removeDialog()

  submitInsert: ->
    {selectedOption, file} = @state
    @props.onSubmit {selectedOption, file}
    @close()

  optionSelect: (e) ->
    selectedOption = e.target.value
    @setState {selectedOption}

  isSubmitDisabled: ->
    if @state.selectedOption is 'image'
      not @state.file
    else
      not @state.selectedOption

  getImageInsertState: ->
    @state.selectedOption isnt 'image'

  showFileUpload: (e) ->
    @refs.fileInput.getDOMNode().click()

  fileSelected: (e) ->
    VALID_TYPES =['image/jpeg', 'image/png']
    file = e.target.files[0]

    @setState if file.type in VALID_TYPES
      {file, wrongFile: false}
    else
      wrongFile: true

  render: ->
    errorClasses = classNames 'error', 'hidden': not @state.wrongFile

    insertOptions = @props.insertOptions.map (option, index) =>
      <Label htmlFor={option} key={index}>
        <RadioInput id={option} name="insert-object" value={option} onChange={@optionSelect}/>
        <span>{@i18n option}</span>
        {
          if option is 'image'
            [
              <input id="file" type="file" onChange={@fileSelected} ref="fileInput" key=11 />
              <Button
                key=22
                className="file btn"
                disabled={@getImageInsertState()}
                label={@i18n 'choose_file'}
                onClick={@showFileUpload}
              />
            ]
        }
      </Label>

    <div>
      <div className="insert-options">
        <List ordered={false}>
          {insertOptions}
        </List>
      </div>
      <div className={errorClasses}>
        {@i18n 'unsupported_image_format'}
      </div>
      <div className="row mt-20">
        <div className="col-6">
          <Button
            className="btn btn-block cancel"
            label={@i18n '/translation:actions.cancel'}
            onMouseDown={@close}/>
        </div>
        <div className="col-6">
          <Button
            className="btn btn-block btn-success"
            label={@i18n '/translation:actions.insert'}
            type="submit"
            disabled={@isSubmitDisabled()}
            onMouseDown={@submitInsert}
          />
        </div>
      </div>
    </div>

module.exports = InsertDialog
