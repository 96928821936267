GeneralScopeRow = require './general_scope_row'
GeneralScopeStore = require 'stores/general_scope_store'
GeneralScopeActions = require 'actions/general_scope_actions'
CreateStoreMixin = require '../mixins/create_store'
FetchingSpinner = require 'components/mixins/fetching_spinner'

GeneralScopeTable = React.createClass
  mixins: [
    React.addons.PureRenderMixin
    CreateStoreMixin(GeneralScopeStore)
    FetchingSpinner
  ]

  getStateFromStores: ->
    isFetching: GeneralScopeStore.isFetching()
    currentEditable: GeneralScopeStore.getActiveContentEditable()
    scope: GeneralScopeStore.getState().get 'scope'

  componentWillMount: ->
    GeneralScopeActions.fetch()

  renderFetched: ->
    <table className="standard-table scope-table">
      {
        for attr in ['title', 'purpose', 'target_population', 'healthcare_setting'
          'intervention_types', 'key_stakeholders_users', 'key_resources', 'existing_documents']
          <GeneralScopeRow
            key={attr}
            title={$.t "scope:#{attr}"}
            content={@state.scope.get(attr) ? ''}
            currentEditable={@state.currentEditable}
            description={$.t "scope:descriptions.#{attr}"}
            fieldName={attr}
            applyCellData={@applyCellData}
          />
      }
    </table>

module.exports = GeneralScopeTable
