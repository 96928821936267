var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "  <form action=\"javascript:void(0);\">\n    <input type=\"email\" name=\"email\" required placeholder=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "email", {"name":"i18n","hash":{},"data":data})))
    + "\">\n    <input name=\"name\" required placeholder=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "name", {"name":"i18n","hash":{},"data":data})))
    + "\">\n    <input name=\"targetPlatforms\" required placeholder=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "target_platforms", {"name":"i18n","hash":{},"data":data})))
    + "\">\n    <textarea name=\"message\" placeholder=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "additional_message", {"name":"i18n","hash":{},"data":data})))
    + "\"></textarea>\n    <label>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "purpose", {"name":"i18n","hash":{},"data":data})))
    + "<input name=\"purpose\"></label>\n\n    <div class=\"row mt-20\">\n      <div class=\"col-6\">\n        <button class=\"btn btn-block cancel\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/actions.cancel", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n      </div>\n      <div class=\"col-6\">\n        <button type=\"submit\"\n          class=\"btn btn-block btn-success ok finish\"\n          >"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/actions.ok", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n      </div>\n    </div>\n  </form>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "dissemination:publish.contact_form", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}