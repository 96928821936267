_.namespace(module, function(require) {
  var OutcomeViewType, mediator;
  OutcomeViewType = require('models/outcome/view_types');
  mediator = require('mediator');
  return {
    _getAttrName: function(tableType) {
      switch (tableType) {
        case 'diagnostic':
          return 'diagnosticOutcomeViewType';
        case 'prognostic':
          return 'prognosisOutcomeViewType';
        case 'recommendations':
          return 'recommendationsMode';
        default:
          return 'outcomeViewType';
      }
    },
    getPreferredType: function(project, tableType) {
      var attrName, typeName, value, _ref;
      attrName = this._getAttrName(tableType);
      typeName = project.getPersonalMetadoc().get(attrName);
      if (!typeName) {
        value = mediator.user.get(attrName);
        typeName = (_ref = value != null ? value.name : void 0) != null ? _ref : value;
      }
      if (tableType === 'recommendations') {
        return typeName;
      } else {
        return OutcomeViewType.get(typeName);
      }
    },
    savePreference: function(project, tableType, type, projectLevel) {
      var attrName;
      if (projectLevel == null) {
        projectLevel = false;
      }
      attrName = this._getAttrName(tableType);
      if (projectLevel) {
        return project.getPersonalMetadoc().save(attrName, type);
      } else {
        project.getPersonalMetadoc().unset(attrName);
        return project.getPersonalMetadoc().save().then(function() {
          return mediator.user.save(attrName, type);
        });
      }
    },
    getDefaultViewAttrs: function() {
      return {
        outcomeViewType: 'OneRow',
        diagnosticOutcomeViewType: 'LayerOne',
        prognosisOutcomeViewType: 'Prognosis'
      };
    }
  };
});
