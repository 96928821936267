var DbHelper, FormsApiHelper, W, mediator, utils;

DbHelper = require('./db_helper');

W = require('when/when');

utils = require('base/lib/utils');

mediator = require('mediator');

FormsApiHelper = (function() {
  function FormsApiHelper(type) {
    this.formsApiUrl = "" + (DbHelper.getBackendUrl()) + "/forms/" + type;
  }

  FormsApiHelper.prototype.errorHandler = function(error, customMessage) {
    mediator.dialogs.error(customMessage != null ? customMessage : error.responseText);
    return utils.reportRavenError(error);
  };

  FormsApiHelper.prototype._ajaxRequest = function(uri, type, data) {
    if (data == null) {
      data = {};
    }
    return W($.ajax({
      type: type,
      dataType: 'json',
      data: JSON.stringify(data),
      contentType: 'application/json',
      url: "" + this.formsApiUrl + uri
    }));
  };

  FormsApiHelper.prototype.get = function(uri) {
    return this._ajaxRequest(uri, 'GET');
  };

  FormsApiHelper.prototype.put = function(uri, data) {
    return this._ajaxRequest(uri, 'PUT', data);
  };

  FormsApiHelper.prototype.post = function(uri, data) {
    return this._ajaxRequest(uri, 'POST', data);
  };

  FormsApiHelper.prototype.getFormDoc = function(docId) {
    return this.get("/" + docId);
  };

  FormsApiHelper.prototype.saveFormDoc = function(formDoc) {
    if (formDoc._id) {
      return this.put("/" + formDoc._id, formDoc);
    } else {
      return this.post('', formDoc);
    }
  };

  FormsApiHelper.prototype.bulkSaveFormDocs = function(formDocs) {
    return this.post('/_bulk_docs', {
      docs: formDocs
    });
  };

  FormsApiHelper.prototype.getFormDocs = function(docIds) {
    return this.post('/_all_docs', {
      keys: docIds
    });
  };

  FormsApiHelper.prototype.submitForm = function(formDoc) {
    return this.post("/" + formDoc._id + "/_submit", formDoc);
  };

  return FormsApiHelper;

})();

module.exports = {
  coi: new FormsApiHelper('coi'),
  questionGeneration: new FormsApiHelper('questions-gen')
};
