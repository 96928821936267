var Rm5ExportTableCell, rm5ExportConfig, rm5TbodyCells, suffixGetter, _getCellFootnotes, _makeRiskDetailsRow;

rm5ExportConfig = require('lib/rm5_export_config');

suffixGetter = require('lib/view_helper_utils').controlRiskFormatterSuffix;

rm5TbodyCells = rm5ExportConfig.tbodyCells;

Rm5ExportTableCell = rm5ExportConfig.Rm5ExportTableCell;

_makeRiskDetailsRow = function(attributes, controlCheck) {
  var controlSuffix, correspondingRiskNotes, footnotes, riskDetailsRow, sofControlNotes, tableView;
  riskDetailsRow = {
    cells: []
  };
  tableView = attributes.tableView;
  footnotes = attributes.footnotes;
  if (!_(footnotes).isEmpty() && attributes.metaType !== 'NOT_POOLED') {
    controlSuffix = suffixGetter(controlCheck);
    sofControlNotes = _getCellFootnotes("dichSofControl" + controlSuffix, footnotes);
    correspondingRiskNotes = _getCellFootnotes('dichCorrespondingRisk', footnotes);
  }
  riskDetailsRow.cells.push(new Rm5ExportTableCell({
    textContent: rm5TbodyCells['dichSofControl'](attributes, controlCheck),
    sup: sofControlNotes || ''
  }));
  switch (tableView) {
    case 'sof':
      riskDetailsRow.cells.push(new Rm5ExportTableCell({
        textContent: rm5TbodyCells['dichCorrespondingRisk'](attributes, controlCheck),
        sup: correspondingRiskNotes || ''
      }));
      break;
    case 'accp_sof':
      riskDetailsRow.cells.push(new Rm5ExportTableCell({
        textContent: rm5TbodyCells['dichRiskDifference'](attributes, controlCheck),
        sup: correspondingRiskNotes || ''
      }));
      break;
    case 'sof_v3':
      riskDetailsRow.cells.push(new Rm5ExportTableCell({
        textContent: rm5TbodyCells['dichCorrespondingRisk'](attributes, controlCheck),
        sup: correspondingRiskNotes || ''
      }));
      riskDetailsRow.cells.push(new Rm5ExportTableCell({
        textContent: rm5TbodyCells['dichRiskDifference'](attributes, controlCheck)
      }));
  }
  return riskDetailsRow;
};

_getCellFootnotes = function(cell, footnotes) {
  var attribute, cellFootnotes, cellPossibleAttributes, _i, _len;
  cellPossibleAttributes = rm5ExportConfig.attributesMap[cell];
  cellFootnotes = [];
  for (_i = 0, _len = cellPossibleAttributes.length; _i < _len; _i++) {
    attribute = cellPossibleAttributes[_i];
    if (attribute in footnotes) {
      cellFootnotes = cellFootnotes.concat(footnotes[attribute]);
    }
  }
  cellFootnotes.sort(function(a, b) {
    return a - b;
  });
  if (!_(cellFootnotes).isEmpty()) {
    return _(cellFootnotes).unique().join(' ');
  } else {
    return '';
  }
};

module.exports = function(attributes, rowspan, controlChecks) {
  var cellData, cellName, cellNotes, colspan, controlCheck, dichRows, firstControlCheck, firstRow, firstRowSchema, outcomeHasFootnotes, tableType, tableView, _i, _len;
  dichRows = [];
  outcomeHasFootnotes = !_(attributes.footnotes).isEmpty();
  tableView = attributes.tableView;
  tableType = "" + tableView + "_regular";
  firstRowSchema = rm5ExportConfig.theadSchemas[tableType][0];
  firstRow = {
    cells: []
  };
  for (cellName in firstRowSchema) {
    if (cellName === 'absoluteEffect') {
      firstRow.cells.push(new Rm5ExportTableCell({
        textContent: rm5TbodyCells['dichNotReportedSofControl'](),
        rowspan: rowspan
      }));
      firstRow.sofControlIndex = firstRow.cells.length - 1;
      firstRow.cells.push(new Rm5ExportTableCell({
        textContent: rm5TbodyCells['dichNotReportedCorrespondingRisk'](),
        rowspan: rowspan
      }));
      if (tableView === 'sof_v3') {
        firstRow.cells.push(new Rm5ExportTableCell);
        ({
          textContent: rm5TbodyCells['dichNotReportedRiskDifference'](),
          rowspan: rowspan
        });
      }
    } else {
      cellData = {
        textContent: rm5TbodyCells[cellName](attributes),
        rowspan: rowspan
      };
      if (outcomeHasFootnotes) {
        cellNotes = _getCellFootnotes(cellName, attributes.footnotes);
        if (cellNotes) {
          cellData.sup = cellNotes;
        }
      }
      firstRow.cells.push(new Rm5ExportTableCell(cellData));
    }
  }
  if (rowspan === 1) {
    dichRows.push(firstRow);
    return dichRows;
  } else {
    colspan = tableView === 'sof_v3' ? 3 : 2;
    firstControlCheck = controlChecks.shift();
    firstRow.cells.splice(firstRow.sofControlIndex, colspan, new Rm5ExportTableCell({
      textContent: rm5TbodyCells['controlLabel'](firstControlCheck),
      colspan: colspan
    }));
    dichRows.push(firstRow);
    dichRows.push(_makeRiskDetailsRow(attributes, firstControlCheck));
    for (_i = 0, _len = controlChecks.length; _i < _len; _i++) {
      controlCheck = controlChecks[_i];
      dichRows.push({
        cells: [
          new Rm5ExportTableCell({
            colspan: colspan,
            textContent: rm5TbodyCells['controlLabel'](controlCheck)
          })
        ]
      });
      dichRows.push(_makeRiskDetailsRow(attributes, controlCheck));
    }
    return dichRows;
  }
};
