var AdaptiveLayout, ISoFDx, ISoFView, Outcome, OutcomeViewType, View, mediator, stats, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

Outcome = require('models/outcome');

OutcomeViewType = require('models/outcome/view_types');

AdaptiveLayout = require('base/lib/traits/adaptive_layout');

stats = require('lib/statistical_utils');

template = require('views/templates/evidence_syntheses/isof');

mediator = require('mediator');

ISoFDx = require('views/evidence_syntheses/isof-dx');

module.exports = ISoFView = (function(_super) {
  __extends(ISoFView, _super);

  function ISoFView() {
    this._testSwitched = __bind(this._testSwitched, this);
    return ISoFView.__super__.constructor.apply(this, arguments);
  }

  ISoFView.prototype.className = 'isof-view';

  ISoFView.prototype.template = template;

  ISoFView.prototype.initialize = function() {
    ISoFView.__super__.initialize.apply(this, arguments);
    this.enable(AdaptiveLayout);
    this.indexTestData = this._fetchData();
    if (Boolean(this.options.questionModel.get('comparatorTestPresent'))) {
      this.testChoiceDropdown = true;
      this.comparatorTestData = this._fetchData(true);
      this.delegate('change', '.test-dropdown', this._testSwitched);
      this.data = {
        ok: this.indexTestData.ok && this.comparatorTestData.ok
      };
      this.data.message = this.indexTestData.message || this.comparatorTestData.message;
    } else {
      this.testSelected = 'index';
      this.data = this.indexTestData;
    }
    this.delegate('click', 'button.go-to-source', (function(_this) {
      return function() {
        var editView;
        editView = (_this.options.previousViewType != null) && !_this.options.previousViewType.isof ? _this.options.previousViewType.name : 'LayerOne';
        return _this.parentView.subview('toolbar').changeOutcomeView(editView);
      };
    })(this));
    return this.render();
  };

  ISoFView.prototype._testSwitched = function(e) {
    this.testSelected = $(e.target).val();
    this.data = (function() {
      switch (this.testSelected) {
        case 'index':
          return this.indexTestData;
        case 'comparator':
          return this.comparatorTestData;
        default:
          this.testSelected = null;
          return {
            ok: true
          };
      }
    }).call(this);
    return this.render();
  };

  ISoFView.prototype._fetchData = function(comparison) {
    if (comparison == null) {
      comparison = false;
    }
    if (this.options.questionModel.isDiagnostic()) {
      return this._convertDiagnostic(this.options.questionModel, comparison);
    } else {
      return {};
    }
  };

  ISoFView.prototype.afterRender = function() {
    ISoFView.__super__.afterRender.apply(this, arguments);
    this.trigger('!fixLayout');
    if (this.data.ok) {
      new ISoFDx('#isof-dx-content', this.data);
    }
    return this.publishEvent('deactivatedProperty');
  };

  ISoFView.prototype.fixLayout = function() {
    if (!this.data.ok) {
      return;
    }
    return this.$('#isof-content').height($('#page-container').height() - 10);
  };

  ISoFView.prototype.getTemplateData = function() {
    var prefix;
    prefix = this.options.questionModel.isDiagnostic() ? 'dx' : 'tx';
    return _.extend({
      testChoiceDropdown: this.testChoiceDropdown,
      testSelected: this.testSelected,
      indexTest: this.options.questionModel.get('indexTest'),
      comparatorTest: this.options.questionModel.get('comparatorTest')
    }, !this.data.ok ? {
      message: this.data.message
    } : void 0);
  };

  ISoFView.prototype._convertDiagnostic = function(question, comparison) {
    var footnotesList, getFootnote, getPrevalenceGroups, outcomeWithLowerQuality, pickMin, prevalenceGroup, prevalences, qualityFootnote, sensitivity, sensitivityFrom, sensitivityOutcome, sensitivityTo, specificity, specificityFrom, specificityOutcome, specificityTo, testInfix, testNameAttr, _ref, _ref1;
    if (question.get('sourceOfDxData') === 'rangeFromStudies') {
      return {
        ok: false,
        message: $.t('es:isof.range_from_studies')
      };
    }
    footnotesList = question.get('footnotesList');
    getFootnote = function(attr, model) {
      var _ref;
      if (model == null) {
        model = question;
      }
      return _((_ref = model.get('footnotes')) != null ? _ref[attr] : void 0).map(footnotesList.get).join('\n');
    };
    prevalences = _(['prevalence1', 'prevalence2', 'prevalence3']).chain().map(function(attr) {
      return {
        value: question.get(attr),
        description: question.get("" + attr + "Characteristics"),
        footnote: getFootnote(attr)
      };
    }).filter(function(o) {
      return o.value != null;
    }).value();
    if (!prevalences.length) {
      return {
        ok: false,
        message: $.t('es:isof.no_prevalence')
      };
    }
    sensitivityOutcome = question.get('outcomes').at(0);
    specificityOutcome = question.get('outcomes').at(2);
    pickMin = function(attr) {
      return Math.min(sensitivityOutcome.get(attr), specificityOutcome.get(attr));
    };
    outcomeWithLowerQuality = sensitivityOutcome.get('quality') < specificityOutcome.get('quality') ? sensitivityOutcome : specificityOutcome;
    qualityFootnote = _(Outcome.QUALITY_MODIFIERS).map(function(attr) {
      return getFootnote(attr, outcomeWithLowerQuality);
    }).join('\n');
    testInfix = comparison ? 'Comparison' : '';
    _ref = [question.get("pooledSpecificity" + testInfix + "From"), question.get("pooledSpecificity" + testInfix), question.get("pooledSpecificity" + testInfix + "To")], specificityFrom = _ref[0], specificity = _ref[1], specificityTo = _ref[2];
    _ref1 = [question.get("pooledSensitivity" + testInfix + "From"), question.get("pooledSensitivity" + testInfix), question.get("pooledSensitivity" + testInfix + "To")], sensitivityFrom = _ref1[0], sensitivity = _ref1[1], sensitivityTo = _ref1[2];
    prevalenceGroup = function(name, _arg) {
      var description, footnote, prevalence;
      prevalence = _arg.value, description = _arg.description, footnote = _arg.footnote;
      return {
        name: name,
        prevalenceExplanation: name,
        prevalence: prevalence * 10,
        prevalence_explanation: description,
        prevalence_footnote: footnote,
        false_negatives: stats.diagnosticEffectFN(sensitivity, prevalence),
        fn_from: stats.diagnosticEffectFN(sensitivityFrom, prevalence),
        fn_to: stats.diagnosticEffectFN(sensitivityTo, prevalence),
        false_positives: stats.diagnosticEffectFP(specificity, prevalence),
        fp_from: stats.diagnosticEffectFP(specificityFrom, prevalence),
        fp_to: stats.diagnosticEffectFP(specificityTo, prevalence),
        true_positives: stats.diagnosticEffectTP(sensitivity, prevalence),
        tp_from: stats.diagnosticEffectTP(sensitivityFrom, prevalence),
        tp_to: stats.diagnosticEffectTP(sensitivityTo, prevalence),
        true_negatives: stats.diagnosticEffectTN(specificity, prevalence),
        tn_from: stats.diagnosticEffectTN(specificityFrom, prevalence),
        tn_to: stats.diagnosticEffectTN(specificityTo, prevalence),
        atp_positive: stats.diagnosticSpecificityOfPositiveResult(sensitivity, specificity, prevalence),
        atp_positive_from: stats.diagnosticSpecificityOfPositiveResult(sensitivityFrom, specificityFrom, prevalence),
        atp_positive_to: stats.diagnosticSpecificityOfPositiveResult(sensitivityTo, specificityTo, prevalence),
        atp_negative: stats.diagnosticSpecificityOfNegativeResult(sensitivity, specificity, prevalence),
        atp_negative_from: stats.diagnosticSpecificityOfNegativeResult(sensitivityFrom, specificityFrom, prevalence),
        atp_negative_to: stats.diagnosticSpecificityOfNegativeResult(sensitivityTo, specificityTo, prevalence)
      };
    };
    getPrevalenceGroups = function(prevalences) {
      var p, t, titles, _i, _len, _ref2, _ref3, _results;
      titles = (function() {
        switch (prevalences.length) {
          case 1:
            return ['Normal probability'];
          case 2:
            return ['Low probability', 'High probability'];
          case 3:
            return ['Low probability', 'Medium probability', 'High probability'];
        }
      })();
      _ref2 = _.zip(titles, prevalences);
      _results = [];
      for (_i = 0, _len = _ref2.length; _i < _len; _i++) {
        _ref3 = _ref2[_i], t = _ref3[0], p = _ref3[1];
        _results.push(prevalenceGroup(t, p));
      }
      return _results;
    };
    testNameAttr = comparison ? 'comparatorTest' : 'indexTest';
    return {
      ok: true,
      id: question.id,
      is_public: true,
      name: question.get('targetCondition'),
      test: question.get(testNameAttr),
      test_long: question.get(testNameAttr),
      test_footnote: getFootnote(testNameAttr),
      title: question.get('tableTitle'),
      who_prepared_this_sof: question.get('authors'),
      based_on: mediator.project.get('name'),
      denom: 1000,
      grade: pickMin('quality'),
      grade_footnote: qualityFootnote.trim(),
      groups: getPrevalenceGroups(prevalences),
      participants: pickMin('noOfPatients'),
      studies: pickMin('noOfStudies'),
      patients: question.get('healthProblemOrPopulation'),
      patients_footnote: getFootnote('healthProblemOrPopulation'),
      settings: question.get('settings'),
      settings_footnote: getFootnote('settings'),
      sens_from: sensitivityFrom,
      sensitivity: sensitivity,
      sens_to: sensitivityTo,
      spec_from: specificityFrom,
      specificity: specificity,
      spec_to: specificityTo,
      cutoff: question.get("" + testNameAttr + "CutOff"),
      cutoff_footnote: getFootnote("" + testNameAttr + "CutOff"),
      reference_test: question.get('referenceTestShortName'),
      reference_test_footnote: getFootnote('referenceTestShortName'),
      threshold: question.get('referenceTestThreshold'),
      threshold_footnote: getFootnote('referenceTestThreshold')
    };
  };

  return ISoFView;

})(View);


/*
    Following attributes are either not available in the GDT:

    "negative_lr": # Negative likelihood ratio
    "negative_lr_from": # Negative likelihood ratio confidence interval from
    "negative_lr_to": # Negative likelihood ratio confidence interval to
    "positive_lr": # Not used
    "positive_lr_from": # Not used
    "positive_lr_to": # Not used
    Doesn't seem to work:
    "outcomes": [
        {
            "comments": "Uninterpretable, intermediate, or indeterminate test results."
            "grade": 1,
            "name": "Inconclusive results",
            "participants": 11238,
            "result": "140 per 1000 (14%) in 1 study;\n10 per 1000 (1%) to 50 per 1000 (5%) in ..."
            "studies": 16,
            "type": "non_standard"
        },
        {
            "comments": "",
            "grade": "-",
            "name": "Complications",
            "participants": "-",
            "result": "Not reported",
            "studies": "",
            "type": "non_standard"
        }
    ]
 */
