var CellFootnotesView, ClearRadioContextMenuView, HelpBoxView, HelpResources, SelectionView, mediator, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

SelectionView = require('base/views/selection_view');

CellFootnotesView = require('views/evidence_syntheses/cell_footnotes_view');

mediator = require('mediator');

HelpBoxView = require('views/help_box_view');

HelpResources = require('lib/help_resources');

utils = require('base/lib/utils');

module.exports = ClearRadioContextMenuView = (function(_super) {
  __extends(ClearRadioContextMenuView, _super);

  function ClearRadioContextMenuView() {
    this._acceptHandler = __bind(this._acceptHandler, this);
    return ClearRadioContextMenuView.__super__.constructor.apply(this, arguments);
  }

  ClearRadioContextMenuView.prototype.initialize = function() {
    var _base;
    this.options.listItems = [
      {
        value: 'clearRadioBtns',
        text: $.t('translation:actions.clear_radio_inputs'),
        disabled: this.options.radioButtonsUnchecked
      }
    ];
    if (this.options.judgement) {
      this.options.listItems.push({
        value: 'clearFullJudgementColumn',
        text: $.t('translation:actions.clear_judgement_column'),
        disabled: this.options.judgementRadioButtonsUnchecked
      });
    }
    if ((_base = this.options).acceptHandler == null) {
      _base.acceptHandler = this._acceptHandler;
    }
    return ClearRadioContextMenuView.__super__.initialize.apply(this, arguments);
  };

  ClearRadioContextMenuView.prototype._acceptHandler = function() {
    switch (this.getSelectedItemValue()) {
      case 'clearRadioBtns':
        return this._clearRadioButton();
      case 'clearFullJudgementColumn':
        return this._clearJudgementColumn();
    }
  };

  ClearRadioContextMenuView.prototype._clearRadioButton = function() {
    var $el;
    $el = this.options.target.closest('.radio-group');
    this._uncheckRadioInput($el);
    return this.options.model.save();
  };

  ClearRadioContextMenuView.prototype._clearJudgementColumn = function() {
    return mediator.dialogs.confirm({
      message: $.t('translation:messages.clear_judgement_column_confirm')
    }, (function(_this) {
      return function(confirmed) {
        if (!confirmed) {
          return;
        }
        $('.judgement').each(function(index, item) {
          return _this._uncheckRadioInput(item);
        });
        return _this.options.model.save();
      };
    })(this));
  };

  ClearRadioContextMenuView.prototype._uncheckRadioInput = function(parentEl) {
    var $radioButton;
    $radioButton = $(parentEl).find('input[type="radio"]:checked');
    $radioButton.prop('checked', false);
    return this.options.model.set($radioButton.attr('name'), null);
  };

  ClearRadioContextMenuView.prototype.hide = function() {
    if (!this.hidden) {
      this.$el.remove();
      return this.hidden = true;
    }
  };

  return ClearRadioContextMenuView;

})(SelectionView);
