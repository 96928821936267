var Controller, Project, WelcomeController, WelcomeView, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

WelcomeView = require('views/welcome_view');

Controller = require('controllers/base/controller');

mediator = require('mediator');

Project = require('models/project');

module.exports = WelcomeController = (function(_super) {
  __extends(WelcomeController, _super);

  function WelcomeController() {
    return WelcomeController.__super__.constructor.apply(this, arguments);
  }

  WelcomeController.prototype.historyURL = '';

  WelcomeController.prototype.index = function(params) {
    var _updateProjects;
    if (mediator.services.switches.isOn('new_projects_list')) {
      _updateProjects = function() {
        mediator.activeProjects.updateOrderNumbers();
        mediator.archivedProjects.updateOrderNumbers();
        return mediator.publish('projects:active_projects_sorted');
      };
      mediator.subscribe('projects:active_projects_reordered', function(projectId, fromIdx, toIdx) {
        mediator.activeProjects.switchItems(fromIdx, toIdx);
        return mediator.publish('projects:active_projects_sorted');
      });
      mediator.subscribe('projects:project_archived', function(project) {
        mediator.archivedProjects.add(mediator.activeProjects.get(project.id), mediator.activeProjects.getAdditionalProjectData(project.id));
        mediator.activeProjects.remove(project.id);
        return _updateProjects();
      });
      mediator.subscribe('projects:project_activated', function(projectId) {
        mediator.activeProjects.add(mediator.archivedProjects.get(projectId), mediator.archivedProjects.getAdditionalProjectData(projectId));
        mediator.projects.fetch();
        mediator.archivedProjects.remove(projectId);
        return _updateProjects();
      });
      mediator.subscribe('projects:project_removed', function(projectId) {
        mediator.archivedProjects.remove(projectId);
        return _updateProjects();
      });
      return this.view = new WelcomeView({
        collection: mediator.activeProjects,
        archivedProjects: mediator.archivedProjects
      });
    } else {
      return this.view = new WelcomeView({
        collection: mediator.projects
      });
    }
  };

  return WelcomeController;

})(Controller);
