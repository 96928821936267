var ReactComponent, ViewTrait, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ViewTrait = require('./view_trait');

alt = require('alt');

mediator = require('mediator');

module.exports = ReactComponent = (function(_super) {
  __extends(ReactComponent, _super);

  function ReactComponent() {
    return ReactComponent.__super__.constructor.apply(this, arguments);
  }

  ReactComponent.prototype.apply = function(view) {
    ReactComponent.__super__.apply.apply(this, arguments);
    this._addFunction(view, 'renderComponent');
    return this._addFunction(view, 'unmountComponent');
  };

  ReactComponent.prototype.renderComponent = function(originalFunction, trait, Component, Actions, ids, options) {
    var component, params;
    if (ids == null) {
      ids = null;
    }
    if (options == null) {
      options = {};
    }
    this._stopInputsPropagation = false;
    if (ids) {
      params = {
        dbId: mediator.project.id,
        docId: ids
      };
      Actions.fetch(params);
      this.listener = mediator.services.storePersistenceAdapter.setupListening(mediator.project.id, ids, options, function() {
        return Actions.fetch(params);
      });
    } else {
      Actions.fetch();
      this.modelBind('change', Actions.fetch);
    }
    component = React.createElement(Component, null);
    return React.render(component, this.el);
  };

  ReactComponent.prototype.unmountComponent = function(originalFunction, trait) {
    var _ref;
    if ((_ref = this.listener) != null) {
      _ref.cancel();
    }
    this.listener = null;
    return React.unmountComponentAtNode(this.el);
  };

  return ReactComponent;

})(ViewTrait);
