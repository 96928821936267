var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <th colspan=\"3\" class=\"regular-header\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.effect", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n";
},"3":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <th colspan=\"3\" rowspan=\"2\" class=\"impact narrative-header\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "impact", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n";
},"5":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "        <th class=\"events regular-header\">";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.no_of_events", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</th>\n        <th class=\"individuals regular-header\">";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.no_of_individuals", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</th>\n        <th class=\"rate regular-header\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.rate", {"name":"i18n","hash":{},"data":data})))
    + "<br>(95% CI)</th>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"question-data\">\n</div>\n<table class=\"outcomes-table\" valign=\"top\" cellpadding=\"5\" width=\"100%\">\n  <thead>\n    <tr class=\"prognostic-table table-header\">\n      <th class=\"no-of-studies\" rowspan=\"2\">";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.no_of_studies", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</th>\n      <th colspan=\"6\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.quality_assessment", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.$tableMode : depth0), "regular", {"name":"is","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "      <th rowspan=\"2\" class=\"quality\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.quality", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n      <th rowspan=\"2\" class=\"importance\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.importance", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n    </tr>\n    <tr class=\"prognostic-table table-header\">\n      <th class=\"design-studies\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.study_design", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n      <th class=\"risk-of-bias\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.risk_of_bias", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n      <th class=\"inconsistency\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.inconsistency", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n      <th class=\"indirectness\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.indirectness", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n      <th class=\"imprecision\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.imprecision", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n      <th class=\"other-considerations\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.other_considerations", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.$tableMode : depth0), "regular", {"name":"is","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </tr>\n  </thead>\n  <tbody class=\"printout-rows\">\n\n  </tbody>\n</table>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}