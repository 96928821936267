var ReactMediumEditorMixin;

ReactMediumEditorMixin = {
  displayName: 'ReactMediumEditor',
  getDefaultProps: function() {
    return {
      mediumEditorOptions: {
        disablePlaceholders: true,
        forcePlainText: false,
        cleanPastedHTML: true
      }
    };
  },
  componentDidMount: function() {
    if (this.props.contentEditable && this.props.withEditor) {
      return this.medium = new MediumEditor(this.getDOMNode(), this.props.mediumEditorOptions);
    }
  },
  componentWillUnmount: function() {
    if (this.props.contentEditable && this.medium) {
      return this.medium.destroy();
    }
  },
  disableMediumEditor: function() {
    return this.medium.destroy();
  }
};

module.exports = ReactMediumEditorMixin;
