var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "short";
  },"3":function(depth0,helpers,partials,data) {
  return "      <tr class=\"table-header\">\n        <td colspan=\"6\" class=\"title question-data\">\n        </td>\n      </tr>\n";
  },"5":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <th rowspan=\"2\" class=\"blue-cell regular-header\">\n          <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.relative_effect", {"name":"i18n","hash":{},"data":data})))
    + "<br/>(95% CI)</p>\n        </th>\n        <th colspan=\"2\" class=\"grey-cell regular-header\">\n          <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.anticipated_absolute_effects", {"name":"i18n","hash":{},"data":data})))
    + "</p>\n        </th>\n";
},"7":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <th colspan=\"3\" rowspan=\"2\" class=\"impact narrative-header\">\n          <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.impact", {"name":"i18n","hash":{},"data":data})))
    + "</p>\n        </th>\n";
},"9":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "          <th class=\"grey-cell regular-header\">\n            <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.risk_with", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.comparison || (depth0 != null ? depth0.comparison : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparison","hash":{},"data":data}) : helper)))
    + "</p>\n          </th>\n          <th class=\"grey-cell regular-header\">\n            <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.risk_difference_with", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.intervention || (depth0 != null ? depth0.intervention : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"intervention","hash":{},"data":data}) : helper)))
    + "</p>\n          </th>\n";
},"11":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "  <tbody>\n    <tr>\n      <th colspan=\"7\" class=\"asterix-text\">\n        "
    + escapeExpression(((helpers.i18nNoEsc || (depth0 && depth0.i18nNoEsc) || helperMissing).call(depth0, "es:outcome.printout.asterisk_text", {"name":"i18nNoEsc","hash":{},"data":data})))
    + "\n        <br>\n        <br>\n        "
    + escapeExpression(((helpers.i18nNoEsc || (depth0 && depth0.i18nNoEsc) || helperMissing).call(depth0, "es:outcome.printout.ci_rr_or", {"name":"i18nNoEsc","hash":{},"data":data})))
    + "\n      </th>\n    </tr>\n  </tbody>\n  <tbody>\n    <tr>\n      <th colspan=\"7\"  class=\"quality-legend\">\n        "
    + escapeExpression(((helpers.i18nNoEsc || (depth0 && depth0.i18nNoEsc) || helperMissing).call(depth0, "es:outcome.printout.qualities_legend", {"name":"i18nNoEsc","hash":{},"data":data})))
    + "\n      </th>\n    </tr>\n  </tbody>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<table class=\"outcomes-table ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0['short'] : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" valign=\"top\" cellpadding=\"5\" width=\"100%\">\n  <thead>\n    <tr class=\"table-header\">\n      <td colspan=\"6\" class=\"title printout\">\n        <p class=\"title\"><b>"
    + escapeExpression(((helper = (helper = helpers.tableTitle || (depth0 != null ? depth0.tableTitle : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"tableTitle","hash":{},"data":data}) : helper)))
    + "</b></p>\n      </td>\n    </tr>\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0['short'] : depth0), {"name":"unless","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    <tr>\n      <th rowspan=\"2\" class=\"blue-cell\">\n        <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcomes", {"name":"i18n","hash":{},"data":data})))
    + "</p>\n      </th>\n      <th rowspan=\"2\" class=\"blue-cell\">\n        <p>";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.no_of_participants", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "<br/>(studies)<br/>Follow-up</p>\n      </th>\n      <th rowspan=\"2\" class=\"blue-cell\">\n        <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.printout.quality_of_evidence", {"name":"i18n","hash":{},"data":data})))
    + "<br>(GRADE)</p>\n      </th>\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.$tableMode : depth0), "regular", {"name":"is","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.$tableMode : depth0), "narrative", {"name":"is","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </tr>\n      <tr class=\"border-bottom\">\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.$tableMode : depth0), "regular", {"name":"is","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "      </tr>\n  </thead>\n\n  <tbody class=\"printout-rows\"></tbody>\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0['short'] : depth0), {"name":"unless","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</table>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}