var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "      ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.top : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n      ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.right : depth0), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n      ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.bottom : depth0), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n      ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.left : depth0), {"name":"if","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"2":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return " margin-top:    "
    + escapeExpression(((helper = (helper = helpers.top || (depth0 != null ? depth0.top : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"top","hash":{},"data":data}) : helper)))
    + "mm;    ";
},"4":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return " margin-right:  "
    + escapeExpression(((helper = (helper = helpers.right || (depth0 != null ? depth0.right : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"right","hash":{},"data":data}) : helper)))
    + "mm;  ";
},"6":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return " margin-bottom: "
    + escapeExpression(((helper = (helper = helpers.bottom || (depth0 != null ? depth0.bottom : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"bottom","hash":{},"data":data}) : helper)))
    + "mm; ";
},"8":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return " margin-left:   "
    + escapeExpression(((helper = (helper = helpers.left || (depth0 != null ? depth0.left : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"left","hash":{},"data":data}) : helper)))
    + "mm;   ";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "@font-face {\n  font-family: \"GRADE-quality\";\n  src: url();\n  src: url('http://www.guidelinedevelopment.org/fonts/GRADE-quality.eot');\n  src: url('http://www.guidelinedevelopment.org/fonts/GRADE-quality.eot?#iefix')\n      format('embedded-opentype'),\n    url('http://www.guidelinedevelopment.org/fonts/GRADE-quality.woff') format('woff'),\n    url('http://www.guidelinedevelopment.org/fonts/GRADE-quality.ttf') format('truetype'),\n    url('http://www.guidelinedevelopment.org/fonts/GRADE-quality.svg#GRADE-quality')\n      format('svg');\n  font-weight: normal;\n  font-style: normal;\n}\n\n@media print {\n  @page {\n    size: "
    + escapeExpression(((helper = (helper = helpers.orientation || (depth0 != null ? depth0.orientation : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"orientation","hash":{},"data":data}) : helper)))
    + ";\n";
  stack1 = helpers['with'].call(depth0, (depth0 != null ? depth0.margins : depth0), {"name":"with","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  }\n}\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}