var QuestionForProposalView, View, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

template = require('views/templates/scope/question_for_proposal');

module.exports = QuestionForProposalView = (function(_super) {
  __extends(QuestionForProposalView, _super);

  function QuestionForProposalView() {
    return QuestionForProposalView.__super__.constructor.apply(this, arguments);
  }

  QuestionForProposalView.prototype.tagName = 'tr';

  QuestionForProposalView.prototype.template = template;

  QuestionForProposalView.prototype.initialize = function() {
    QuestionForProposalView.__super__.initialize.apply(this, arguments);
    this.bindings = {};
    return this.bindings["[name=" + (this._getStatusGroupName()) + "]"] = {
      observe: 'approvalStatus',
      updateModel: (function(_this) {
        return function(val) {
          _this.model.set('approvalStatus', val);
          _this.model.save();
          _this.render();
          return false;
        };
      })(this)
    };
  };

  QuestionForProposalView.prototype.getTemplateData = function() {
    return _(QuestionForProposalView.__super__.getTemplateData.apply(this, arguments)).extend({
      statusGroup: this._getStatusGroupName()
    });
  };

  QuestionForProposalView.prototype._getStatusGroupName = function() {
    return "approvalStatus-" + this.model.id;
  };

  QuestionForProposalView.prototype.afterRender = function() {
    QuestionForProposalView.__super__.afterRender.apply(this, arguments);
    this.$el.removeClass().addClass(this.model.get('approvalStatus'));
    return this.stickit();
  };

  return QuestionForProposalView;

})(View);
