var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "<ul>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.modules : depth0), {"name":"each","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</ul>\n";
},"2":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.switchOn || (depth0 && depth0.switchOn) || helperMissing).call(depth0, (depth0 != null ? depth0.featureSwitch : depth0), {"name":"switchOn","hash":{},"fn":this.program(3, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    <li>\n      "
    + escapeExpression(((helpers.moduleLink || (depth0 && depth0.moduleLink) || helperMissing).call(depth0, (depth0 != null ? depth0.id : depth0), (depth0 != null ? depth0.selected : depth0), (depth0 != null ? depth0.disabled : depth0), (depth0 != null ? depth0.missing : depth0), {"name":"moduleLink","hash":{},"data":data})))
    + "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.submodules : depth0), {"name":"if","hash":{},"fn":this.program(4, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </li>\n";
},"4":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "        <ol class=\"submenu\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.submodules : depth0), {"name":"each","hash":{},"fn":this.program(5, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </ol>\n";
},"5":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.switchOn || (depth0 && depth0.switchOn) || helperMissing).call(depth0, (depth0 != null ? depth0.featureSwitch : depth0), {"name":"switchOn","hash":{},"fn":this.program(6, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"6":function(depth0,helpers,partials,data,depths) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          <li>\n            "
    + escapeExpression(((helpers.submoduleLink || (depth0 && depth0.submoduleLink) || helperMissing).call(depth0, (depths[2] != null ? depths[2].id : depths[2]), (depth0 != null ? depth0.id : depth0), (depths[6] != null ? depths[6].currentQuestionId : depths[6]), (depth0 != null ? depth0.selected : depth0), (depth0 != null ? depth0.disabled : depth0), (depth0 != null ? depth0.missing : depth0), {"name":"submoduleLink","hash":{},"data":data})))
    + "\n          </li>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.currentModule : depth0), {"name":"if","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}