var BaseModel, Model, utils,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

BaseModel = require('base/models/model');

utils = require('base/lib/utils');

module.exports = Model = (function(_super) {
  __extends(Model, _super);

  function Model() {
    return Model.__super__.constructor.apply(this, arguments);
  }

  Model.prototype.idAttribute = '_id';

  Model.prototype.initialize = function() {
    Model.__super__.initialize.apply(this, arguments);
    return _.defaults(this.attributes, {
      docType: utils.firstLetterToLowerCase(_.functionName(this.constructor))
    });
  };

  return Model;

})(BaseModel);
