var ActiveFootnoteHighlight, ViewTrait, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

ViewTrait = require('base/lib/traits/view_trait');

mediator = require('mediator');

module.exports = ActiveFootnoteHighlight = (function(_super) {
  __extends(ActiveFootnoteHighlight, _super);

  function ActiveFootnoteHighlight() {
    return ActiveFootnoteHighlight.__super__.constructor.apply(this, arguments);
  }

  ActiveFootnoteHighlight.prototype.apply = function(view) {
    ActiveFootnoteHighlight.__super__.apply.apply(this, arguments);
    this._addFunction(view, 'stickHighlightFootnotes');
    this._addFunction(view, 'unstickHighlightFootnotes');
    this._addFunction(view, 'highlightCells');
    this._addFunction(view, 'removeHighlighForCells');
    this._addFunction(view, '_toggleHighlightningOfFootnote');
    this._addFunction(view, 'footnoteActivated');
    return this._addFunction(view, 'footnoteDeactivated');
  };

  ActiveFootnoteHighlight.prototype.stickHighlightFootnotes = function(originalFunction, trait, activeFootnoteClass) {
    if (activeFootnoteClass == null) {
      activeFootnoteClass = 'footnote-active';
    }
    if (!this.highlightFootnoteBingings) {
      throw new Error('No highlightFootnoteBingings defined!');
    }
    this._hightlightFootnoteBindings = _(this).result('highlightFootnoteBingings');
    this._activeFootnoteClass = activeFootnoteClass;
    this.subscribeEvent('footnoteActivated', this.footnoteActivated);
    return this.subscribeEvent('footnoteDeactivated', this.footnoteDeactivated);
  };

  ActiveFootnoteHighlight.prototype.unstickHighlightFootnotes = function(originalFunction, trait) {
    this.unsubscribeEvent('footnoteActivated', this.footnoteActivated);
    return this.unsubscribeEvent('footnoteDeactivated', this.footnoteDeactivated);
  };

  ActiveFootnoteHighlight.prototype.highlightCells = function(originalFunction, trait, selectorsArray) {
    return this.$(selectorsArray.join(', ')).addClass(this._activeFootnoteClass);
  };

  ActiveFootnoteHighlight.prototype.removeHighlighForCells = function(originalFunction, trait, selectorsArray) {
    var selector, _i, _len, _results;
    _results = [];
    for (_i = 0, _len = selectorsArray.length; _i < _len; _i++) {
      selector = selectorsArray[_i];
      _results.push(this.$el.find(selector).removeClass(this._activeFootnoteClass));
    }
    return _results;
  };

  ActiveFootnoteHighlight.prototype._toggleHighlightningOfFootnote = function(originalFunction, trait, toggleFunction, footnoteId) {
    var footnoteIds, property, _ref, _ref1, _results;
    _ref1 = (_ref = this.model.get('footnotes')) != null ? _ref : {};
    _results = [];
    for (property in _ref1) {
      footnoteIds = _ref1[property];
      if (__indexOf.call(footnoteIds, footnoteId) >= 0) {
        _results.push(toggleFunction.call(this, _(this._hightlightFootnoteBindings[property]).pluck('selector')));
      } else {
        _results.push(void 0);
      }
    }
    return _results;
  };

  ActiveFootnoteHighlight.prototype.footnoteActivated = function(originalFunction, trait, footnoteId) {
    this.footnoteDeactivated(this._activeFootnote);
    this._activeFootnote = footnoteId;
    return this._toggleHighlightningOfFootnote(this.highlightCells, footnoteId);
  };

  ActiveFootnoteHighlight.prototype.footnoteDeactivated = function(originalFunction, trait, footnoteId) {
    return this._toggleHighlightningOfFootnote(this.removeHighlighForCells, footnoteId != null ? footnoteId : this._activeFootnote);
  };

  return ActiveFootnoteHighlight;

})(ViewTrait);
