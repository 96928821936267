Translation = require 'components/mixins/translation'
PureRenderMixin = React.addons.PureRenderMixin
EditableItem = require 'components/common/editable_item'
EtdTemplatesActions = require 'actions/etd_templates_actions'
TemplateSectionsSelection = require 'components/administration/template_sections_selection'
BaseTemplateSelection = require 'components/administration/base_template_selection'

SelectedTemplate = React.createClass
  displayName: 'SelectedTemplate'

  mixins: [PureRenderMixin, Translation('administration:etd_templates')]

  getInitialState: ->
    baseTemplateChanged: false

  editTemplate: ->
    @props.editTemplate @props.templateType

  saveTemplateChanges: ->
    EtdTemplatesActions.saveTemplate @props.template, @props.templateType

  changeBaseTemplate: ->
    @setState baseTemplateChanged: true
    @props.changeBaseTemplate arguments...

  componentWillReceiveProps: (nextProps) ->
    unless nextProps.editingTemplate
      @setState baseTemplateChanged: false

  render: ->
    # if currently editing template is not this template then do not display it
    if @props.editingTemplate and @props.editingTemplate isnt @props.templateType
      null
    else if @props.templateName
      <div className="selected-template-block">
        <div>{@i18n "template_for_#{@props.templateType}"}</div>
        <div className="template-box">
          <ul className="standard-list">
            <EditableItem.Item
              editing={@props.editingTemplate is @props.templateType}
              edit={@editTemplate}>
              <EditableItem.Static>
                <div>{@props.templateName}</div>
              </EditableItem.Static>
              <EditableItem.Editing renderSave={false} renderDelete={false}>
                <div>
                  <BaseTemplateSelection
                    showEmptyValue={false}
                    listTxTemplates={@props.templateType is 'tx'}
                    listDxTemplates={@props.templateType is 'dx'}
                    selectedTemplate={@props.template.getIn ['templateDef', 'id']}
                    changeBaseTemplate={@changeBaseTemplate}/>
                  <TemplateSectionsSelection
                    template={@props.template}
                    templateName={@props.baseTemplateName}
                    nameEditable={true}
                    templateChanged={@props.templateChanged or @state.baseTemplateChanged}
                    autoUpdateName={false}
                    saveTemplateChanges={@saveTemplateChanges}
                    templateInUse={true} />
                </div>
              </EditableItem.Editing>
            </EditableItem.Item>
          </ul>
        </div>
      </div>
    else
      null

module.exports = SelectedTemplate
