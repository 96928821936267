var EditableItemView, TeamMemberView, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

EditableItemView = require('base/views/editable_item_view');

template = require('views/templates/team/member');

module.exports = TeamMemberView = (function(_super) {
  __extends(TeamMemberView, _super);

  function TeamMemberView() {
    return TeamMemberView.__super__.constructor.apply(this, arguments);
  }

  TeamMemberView.prototype.template = template;

  TeamMemberView.prototype.bindings = {
    '#givenNames': 'givenNames',
    '#lastName': 'lastName',
    '#email': {
      observe: 'email',
      onSet: function(val) {
        return _.string.trim(val);
      }
    },
    '.caption > a': {
      observe: ['givenNames', 'lastName'],
      onGet: function(values) {
        return "" + values[0] + " " + values[1];
      },
      attributes: [
        {
          name: 'href',
          observe: 'email',
          onGet: function(val) {
            return "mailto:" + val;
          }
        }
      ]
    }
  };

  TeamMemberView.prototype.getTemplateData = function() {
    return _(TeamMemberView.__super__.getTemplateData.apply(this, arguments)).extend({
      memberFullName: this.model.getFullName()
    });
  };

  TeamMemberView.prototype["delete"] = function() {
    return TeamMemberView.__super__["delete"].call(this, $.t('team:member.delete_confirmation'));
  };

  return TeamMemberView;

})(EditableItemView);
