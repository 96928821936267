PureRenderMixin = React.addons.PureRenderMixin

Summary = React.createClass
  displayName: 'SojSummary'
  mixins: [PureRenderMixin]

  render: ->
    options = @props.criterion.get 'options'
    additionalOptions = @props.criterion.get 'additionalOptions'
    optionGroups = @props.criterion.get 'optionGroups'

    setOption = (option) =>
      selected = @props.criterion.get 'selectedOption'
      text = option.get?('text') ? option
      value = option.get?('value') ? option
      <td key={value}
        className={classNames 'option', checked: "#{selected}" is "#{value}"}>
        {text}
      </td>

    allOptions = if optionGroups
      optionGroups.get('groups').map (group) ->
        group.get 'options'
      .flatten().map(setOption).toList()
    else
      options.map(setOption).toList().concat additionalOptions.map(setOption).toList()

    <td className="options-container">
      <table className="options-container-table">
        <tr className="options">
          {allOptions}
        </tr>
      </table>
    </td>

module.exports = Summary
