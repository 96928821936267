var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "  <div>\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.sourceOfDxData : depth0), "fromSingleStudy", {"name":"is","hash":{},"fn":this.program(2, data),"inverse":this.program(4, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "    "
    + escapeExpression(((helpers.sensitivity || (depth0 && depth0.sensitivity) || helperMissing).call(depth0, depth0, false, {"name":"sensitivity","hash":{},"data":data})))
    + "\n    |\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.sourceOfDxData : depth0), "fromSingleStudy", {"name":"is","hash":{},"fn":this.program(12, data),"inverse":this.program(14, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "    "
    + escapeExpression(((helpers.specificity || (depth0 && depth0.specificity) || helperMissing).call(depth0, depth0, false, {"name":"specificity","hash":{},"data":data})))
    + "\n  </div>\n  <div>\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.sourceOfDxData : depth0), "fromSingleStudy", {"name":"is","hash":{},"fn":this.program(22, data),"inverse":this.program(24, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "    "
    + escapeExpression(((helpers.sensitivity || (depth0 && depth0.sensitivity) || helperMissing).call(depth0, depth0, true, {"name":"sensitivity","hash":{},"data":data})))
    + "\n    |\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.sourceOfDxData : depth0), "fromSingleStudy", {"name":"is","hash":{},"fn":this.program(32, data),"inverse":this.program(34, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    "
    + escapeExpression(((helpers.specificity || (depth0 && depth0.specificity) || helperMissing).call(depth0, depth0, true, {"name":"specificity","hash":{},"data":data})))
    + "\n  </div>\n";
},"2":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "      <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.single_study_sensitivity", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.indexTest || (depth0 != null ? depth0.indexTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"indexTest","hash":{},"data":data}) : helper)))
    + "</b>:\n";
},"4":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.sourceOfDxData : depth0), "rangeFromStudies", {"name":"is","hash":{},"fn":this.program(5, data),"inverse":this.program(7, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "        <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.range_sensitivity", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.indexTest || (depth0 != null ? depth0.indexTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"indexTest","hash":{},"data":data}) : helper)))
    + "</b>:\n";
},"7":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.sourceOfDxData : depth0), "pooledAcrossStudies", {"name":"is","hash":{},"fn":this.program(8, data),"inverse":this.program(10, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "          <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.pooled_sensitivity", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.indexTest || (depth0 != null ? depth0.indexTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"indexTest","hash":{},"data":data}) : helper)))
    + "</b>:\n";
},"10":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "          <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.sensitivity", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.indexTest || (depth0 != null ? depth0.indexTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"indexTest","hash":{},"data":data}) : helper)))
    + "</b>:\n";
},"12":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "      <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.single_study_specificity", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.indexTest || (depth0 != null ? depth0.indexTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"indexTest","hash":{},"data":data}) : helper)))
    + "</b>:\n";
},"14":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.sourceOfDxData : depth0), "rangeFromStudies", {"name":"is","hash":{},"fn":this.program(15, data),"inverse":this.program(17, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"15":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "        <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.range_specificity", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.indexTest || (depth0 != null ? depth0.indexTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"indexTest","hash":{},"data":data}) : helper)))
    + "</b>:\n";
},"17":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.sourceOfDxData : depth0), "pooledAcrossStudies", {"name":"is","hash":{},"fn":this.program(18, data),"inverse":this.program(20, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"18":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "          <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.pooled_specificity", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.indexTest || (depth0 != null ? depth0.indexTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"indexTest","hash":{},"data":data}) : helper)))
    + "</b>:\n";
},"20":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "          <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.specificity", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.indexTest || (depth0 != null ? depth0.indexTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"indexTest","hash":{},"data":data}) : helper)))
    + "</b>:\n";
},"22":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "      <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.single_study_sensitivity", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.comparatorTest || (depth0 != null ? depth0.comparatorTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparatorTest","hash":{},"data":data}) : helper)))
    + "</b>:\n";
},"24":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.sourceOfDxData : depth0), "rangeFromStudies", {"name":"is","hash":{},"fn":this.program(25, data),"inverse":this.program(27, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"25":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "        <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.range_sensitivity", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.comparatorTest || (depth0 != null ? depth0.comparatorTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparatorTest","hash":{},"data":data}) : helper)))
    + "</b>:\n";
},"27":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.sourceOfDxData : depth0), "pooledAcrossStudies", {"name":"is","hash":{},"fn":this.program(28, data),"inverse":this.program(30, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"28":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "          <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.pooled_sensitivity", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.comparatorTest || (depth0 != null ? depth0.comparatorTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparatorTest","hash":{},"data":data}) : helper)))
    + "</b>:\n";
},"30":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "          <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.sensitivity", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.comparatorTest || (depth0 != null ? depth0.comparatorTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparatorTest","hash":{},"data":data}) : helper)))
    + "</b>:\n";
},"32":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "      <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.single_study_specificity", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.comparatorTest || (depth0 != null ? depth0.comparatorTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparatorTest","hash":{},"data":data}) : helper)))
    + "</b>:\n";
},"34":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.sourceOfDxData : depth0), "rangeFromStudies", {"name":"is","hash":{},"fn":this.program(35, data),"inverse":this.program(37, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"35":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "        <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.range_specificity", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.comparatorTest || (depth0 != null ? depth0.comparatorTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparatorTest","hash":{},"data":data}) : helper)))
    + "</b>:\n";
},"37":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.sourceOfDxData : depth0), "pooledAcrossStudies", {"name":"is","hash":{},"fn":this.program(38, data),"inverse":this.program(40, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"38":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "          <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.pooled_specificity", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.comparatorTest || (depth0 != null ? depth0.comparatorTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparatorTest","hash":{},"data":data}) : helper)))
    + "</b>:\n";
},"40":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "          <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.specificity", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.comparatorTest || (depth0 != null ? depth0.comparatorTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparatorTest","hash":{},"data":data}) : helper)))
    + "</b>:\n";
},"42":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "  <div>\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.sourceOfDxData : depth0), "fromSingleStudy", {"name":"is","hash":{},"fn":this.program(43, data),"inverse":this.program(45, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "    "
    + escapeExpression(((helpers.sensitivity || (depth0 && depth0.sensitivity) || helperMissing).call(depth0, depth0, false, {"name":"sensitivity","hash":{},"data":data})))
    + "\n    |\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.sourceOfDxData : depth0), "fromSingleStudy", {"name":"is","hash":{},"fn":this.program(53, data),"inverse":this.program(55, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    "
    + escapeExpression(((helpers.specificity || (depth0 && depth0.specificity) || helperMissing).call(depth0, depth0, false, {"name":"specificity","hash":{},"data":data})))
    + "\n  </div>\n";
},"43":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.single_study_sensitivity", {"name":"i18n","hash":{},"data":data})))
    + "</b>:\n";
},"45":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.sourceOfDxData : depth0), "rangeFromStudies", {"name":"is","hash":{},"fn":this.program(46, data),"inverse":this.program(48, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"46":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.range_sensitivity", {"name":"i18n","hash":{},"data":data})))
    + "</b>:\n";
},"48":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.sourceOfDxData : depth0), "pooledAcrossStudies", {"name":"is","hash":{},"fn":this.program(49, data),"inverse":this.program(51, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"49":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.pooled_sensitivity", {"name":"i18n","hash":{},"data":data})))
    + "</b>:\n";
},"51":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.sensitivity", {"name":"i18n","hash":{},"data":data})))
    + "</b>:\n";
},"53":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.single_study_specificity", {"name":"i18n","hash":{},"data":data})))
    + "</b>:\n";
},"55":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.sourceOfDxData : depth0), "rangeFromStudies", {"name":"is","hash":{},"fn":this.program(56, data),"inverse":this.program(58, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"56":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.range_specificity", {"name":"i18n","hash":{},"data":data})))
    + "</b>:\n";
},"58":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.sourceOfDxData : depth0), "pooledAcrossStudies", {"name":"is","hash":{},"fn":this.program(59, data),"inverse":this.program(61, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"59":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.pooled_specificity", {"name":"i18n","hash":{},"data":data})))
    + "</b>:\n";
},"61":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.specificity", {"name":"i18n","hash":{},"data":data})))
    + "</b>:\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "<div><b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:question.setting", {"name":"i18n","hash":{},"data":data})))
    + "</b>: "
    + escapeExpression(((helper = (helper = helpers.settings || (depth0 != null ? depth0.settings : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"settings","hash":{},"data":data}) : helper)))
    + "</div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.comparatorTestPresent : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(42, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}