_.namespace(module, function(require) {
  var DbHelper, Exceptions, W;
  Exceptions = require('lib/exceptions');
  DbHelper = require('base/lib/db_helper');
  W = require('when');
  return {
    checkIfEmailUsed: function(email) {
      var url;
      url = "" + (DbHelper.getBackendUrl()) + "/accounts/by-email/" + email;
      return W($.ajax(url, {
        type: 'GET'
      })).then(function(response) {
        return response.count > 0;
      }, function(xhr) {
        var response;
        response = $.parseJSON(xhr.responseText);
        if (xhr.status === 404 && (response != null ? response.error : void 0) === 'not_found') {
          return false;
        } else {
          throw new Exceptions.server_error;
        }
      });
    }
  };
});
