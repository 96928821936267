var OrderedCollection, Project, Projects, Set, User, mediator, sequence,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

OrderedCollection = require('models/base/ordered_collection');

Project = require('models/project');

User = require('models/user');

Set = require('base/lib/set');

sequence = require('when/sequence');

mediator = require('mediator');

module.exports = Projects = (function(_super) {
  __extends(Projects, _super);

  function Projects() {
    return Projects.__super__.constructor.apply(this, arguments);
  }

  Projects.prototype.model = Project;

  Projects.prototype._redirects = {};

  Projects.prototype.setOrderOnAdd = false;

  Projects.prototype.initialize = function() {
    Projects.__super__.initialize.apply(this, arguments);
    this.uninitializedProjects = new Set();
    return this.subscribeEvent('replication.bgChanges', (function(_this) {
      return function(changes) {
        if (_(changes.projects).any(function(p) {
          return _this.uninitializedProjects.contains(p);
        })) {
          return _this.fetch({
            add: true
          });
        }
      };
    })(this));
  };

  Projects.prototype.removeProject = function(id) {
    return this.remove(this.get(id));
  };

  Projects.prototype.fetch = function(options) {
    var project, projectDBs;
    projectDBs = mediator.user.getProjects();
    return sequence((function() {
      var _i, _len, _results;
      _results = [];
      for (_i = 0, _len = projectDBs.length; _i < _len; _i++) {
        project = projectDBs[_i];
        _results.push(this._fetchProject(project));
      }
      return _results;
    }).call(this)).then((function(_this) {
      return function(results) {
        var method;
        method = (options != null ? options.add : void 0) ? 'add' : 'reset';
        return _this[method](_(results).compact());
      };
    })(this));
  };

  Projects.prototype._fetchProject = function(project) {
    return (function(_this) {
      return function() {
        return new Project({
          _id: project
        }).fetch().then(function(model) {
          _this.uninitializedProjects.remove(project);
          _this._addRedirectsFor(model);
          return model;
        }, function(error) {
          if (error.status !== 404) {
            throw error;
          }
          _this.uninitializedProjects.add(project);
          return null;
        });
      };
    })(this);
  };

  Projects.prototype._addRedirectsFor = function(model) {
    var redirect, redirects, _i, _len, _results;
    redirects = mediator.services.projectDb.projectsRedirectingTo(model);
    _results = [];
    for (_i = 0, _len = redirects.length; _i < _len; _i++) {
      redirect = redirects[_i];
      _results.push(this._redirects[redirect] = model.id);
    }
    return _results;
  };

  Projects.prototype.getRedirectsMap = function() {
    return this._redirects;
  };

  Projects.prototype.create = function(model, options) {
    var _ref;
    options = (_ref = _.clone(options)) != null ? _ref : {};
    return this._prepareModel(model, options).save().then((function(_this) {
      return function(model) {
        _this.add(model);
        return mediator.user.addProject(model.id).then(function() {
          _this._assignOrderNumber(model);
          return model.fetch();
        });
      };
    })(this)).then(function(project) {
      if (typeof options.success === "function") {
        options.success(project);
      }
      return project;
    }, function(error) {
      return typeof options.error === "function" ? options.error(error) : void 0;
    });
  };

  Projects.prototype.getMaxOrderNumber = function() {
    return mediator.user.getProjectsMaxOrderNumber();
  };

  Projects.prototype._checkOrder = function() {
    var maxOrderNumber, orderNumber, ordersList, projectId, projects;
    maxOrderNumber = this.getMaxOrderNumber();
    projects = mediator.user.getProjectsWithOrderNumbers();
    ordersList = (function() {
      var _results;
      _results = [];
      for (projectId in projects) {
        orderNumber = projects[projectId];
        if (!(!_.isNumber(orderNumber))) {
          continue;
        }
        maxOrderNumber += 1;
        mediator.user.setProjectOrderNumber(projectId, maxOrderNumber);
        _results.push(maxOrderNumber);
      }
      return _results;
    })();
    if (ordersList.length) {
      return mediator.user.save().otherwise(mediator.dialogs.fatalError);
    }
  };

  Projects.prototype._assignOrderNumber = function(project) {
    var maxOrderNumber;
    maxOrderNumber = this.getMaxOrderNumber();
    mediator.user.setProjectOrderNumber(project.id, maxOrderNumber + 1);
    return mediator.user.save().otherwise(mediator.dialogs.fatalError);
  };

  Projects.prototype.comparator = function(p1, p2) {
    var o1, o2, revertOrderIfExactlyOneMissing, _ref, _ref1;
    o1 = (_ref = mediator.user.getProjectOrderNumber(p1.id)) != null ? _ref : -1;
    o2 = (_ref1 = mediator.user.getProjectOrderNumber(p2.id)) != null ? _ref1 : -1;
    revertOrderIfExactlyOneMissing = o1 * o2;
    return revertOrderIfExactlyOneMissing * (o1 - o2);
  };

  Projects.prototype._getItemOrderNumber = function(idx) {
    return mediator.user.getProjectOrderNumber(this.at(idx).id);
  };

  Projects.prototype._setItemOrderNumber = function(idx, value) {
    return mediator.user.setProjectOrderNumber(this.at(idx).id, value);
  };

  Projects.prototype._saveReorderedItems = function() {
    return mediator.user.save().otherwise(mediator.dialogs.fatalError);
  };

  return Projects;

})(OrderedCollection);
