var Ellipsis, Router, Scrolling, SearchboxView, SidebarView, View, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

SearchboxView = require('views/searchbox_view');

Scrolling = require('base/lib/traits/scrolling');

Ellipsis = require('base/lib/traits/ellipsis');

Router = require('router');

template = require('views/templates/sidebar');

mediator = require('mediator');

module.exports = SidebarView = (function(_super) {
  __extends(SidebarView, _super);

  function SidebarView() {
    return SidebarView.__super__.constructor.apply(this, arguments);
  }

  SidebarView.prototype.template = template;

  SidebarView.prototype.id = 'sidebar';

  SidebarView.prototype.tagName = 'nav';

  SidebarView.prototype.container = '#sidebar-container';

  SidebarView.prototype.autoRender = true;

  SidebarView.prototype.initialize = function() {
    SidebarView.__super__.initialize.apply(this, arguments);
    this.subscribeEvent('moduleChanged', function(module) {
      this.currentQuestionId = null;
      this.currentSubmodule = null;
      this.currentModule = module;
      return this.render();
    });
    this.subscribeEvent('submoduleChanged', function(submodule, params) {
      if (this.currentModule === 'evidence_syntheses') {
        this.currentQuestionId = params.id;
      }
      this.currentSubmodule = submodule;
      return this.render();
    });
    this.subscribeEvent('questionChanged', function(questionId) {
      this.currentQuestionId = questionId;
      return this.render();
    });
    return this.subscribeEvent('modulesChanged', this.render);
  };

  SidebarView.prototype.getTemplateData = function() {
    return {
      currentModule: this.currentModule,
      currentQuestionId: this.currentQuestionId,
      modules: this._getModules()
    };
  };

  SidebarView.prototype._getModules = function() {
    if (mediator.project) {
      return this._markSelectedModule(mediator.services.modules.getEnabledModules(mediator.project));
    }
  };

  SidebarView.prototype._markSelectedModule = function(modules) {
    var module, _ref;
    module = _(modules).findWhere({
      id: this.currentModule
    });
    if (!module) {
      return modules;
    }
    if (this.currentModule === 'evidence_syntheses' && (this.currentQuestionId == null)) {
      delete module.submodules;
    }
    module.selected = true;
    if (module.submodules) {
      if ((_ref = _(module.submodules).find((function(_this) {
        return function(v) {
          return Router.prototype.urlizeModuleName(v.id) === _this.currentSubmodule;
        };
      })(this))) != null) {
        _ref.selected = true;
      }
    }
    return modules;
  };

  SidebarView.prototype.afterRender = function() {
    SidebarView.__super__.afterRender.apply(this, arguments);
    if (!this.traitsEnabled) {
      this._enableTraits();
    }
    return this.trigger('!fixLayout');
  };

  SidebarView.prototype._enableTraits = function() {
    var moduleButtonHeight, moduleButtonsTotalHeight, _ref;
    moduleButtonHeight = this.$('ul > li:not(.selected)').height();
    moduleButtonsTotalHeight = this.$('ul > li').size() * moduleButtonHeight;
    if (!moduleButtonsTotalHeight || this.$('.selected + .submenu').length === 0) {
      return;
    }
    this.enable(Scrolling, {
      adjustTo: '.outer-container',
      content: '.selected + .submenu',
      wrapped: false,
      fixedElementsHeight: moduleButtonsTotalHeight,
      forceCustom: true
    });
    this.traitsEnabled = true;
    this.off('addedToDOM', null, 'AdaptiveLayout');
    return (_ref = mediator.heirView) != null ? _ref.trigger('!fixLayout') : void 0;
  };

  return SidebarView;

})(View);
