DialogBox = React.createClass
  mixins: [
    OnClickOutside
  ],

  displayName: 'DialogBox'

  componentDidMount: ->
    @boxEl = $(@getDOMNode())
    @boxEl.css display: 'block'
    windowHeight = $(window).height()
    windowWidth = $(window).width()
    top = Math.min @props.offset.top, windowHeight - @boxEl.height() - 50
    @boxEl.css
      left: Math.min(@props.offset.left, windowWidth - @boxEl.width() - 30)
      top: top

  handleClickOutside: ->
    @hide()

  hide: ->
    parent = @boxEl[0].parentNode
    React.unmountComponentAtNode parent
    $(parent).remove()

  render: ->
    <div className={ classNames("dialog-box", @props.dialogClass) }>
      <div className="box-content">
        { @props.children }
      </div>
    </div>

module.exports = DialogBox
