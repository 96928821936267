var ProjectSharingService, mediator;

mediator = require('mediator');

module.exports = ProjectSharingService = (function() {
  function ProjectSharingService() {}

  ProjectSharingService.prototype.shareProjectCopy = function(project, withEmail) {
    return mediator.user.getRequests().create({
      type: 'share_project_copy',
      project: project,
      "with": withEmail
    });
  };

  ProjectSharingService.prototype.shareProject = function(project, withEmail) {
    return mediator.user.getRequests().create({
      type: 'share_project',
      project: project,
      "with": withEmail
    });
  };

  return ProjectSharingService;

})();
