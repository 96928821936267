var DbHelper, W, mediator, randVotingHelpers;

mediator = require('mediator');

W = require('when/when');

DbHelper = require('base/lib/db_helper');

randVotingHelpers = {
  getVotingDocId: function(member) {
    return "" + mediator.project.id + "-" + (member.get('_id'));
  },
  createVotingDocs: function(members) {
    var baseDoc;
    baseDoc = Immutable.fromJS({
      status: 'new',
      projectId: mediator.project.id,
      projectName: mediator.project.get('name')
    });
    return W.settle(mediator.questions.map(function(q) {
      var recommendationId, _ref;
      recommendationId = (_ref = q.get('recommendationIds')) != null ? _ref[0] : void 0;
      if (recommendationId) {
        return mediator.services.storePersistenceAdapter.fetch(mediator.project.id, recommendationId, {
          attachments: true
        }).then(function(etdDoc) {
          return {
            questionId: q.id,
            question: q.get('question'),
            etdTemplate: etdDoc
          };
        });
      } else {
        return W.reject('etdNotCompleted');
      }
    })).then((function(_this) {
      return function(settledJS) {
        var settled, votingDocs;
        settled = Immutable.fromJS(settledJS);
        votingDocs = members.map(function(member) {
          var questions, votingDoc;
          questions = settled.filter(function(s) {
            return s.get('state') === 'fulfilled';
          }).map(function(s) {
            return s.get('value');
          });
          return votingDoc = baseDoc.withMutations(function(doc) {
            doc.set('_id', _this.getVotingDocId(member));
            doc.set('questions', questions);
            doc.set('email', member.get('email'));
            doc.set('givenNames', member.get('givenNames'));
            return doc.set('lastName', member.get('lastName'));
          });
        });
        return W.all(votingDocs.map(function(votingDoc) {
          return W($.ajax({
            url: "" + (DbHelper.getRandVotingDbUrl()) + "/" + (votingDoc.get('_id')),
            type: 'PUT',
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(votingDoc.toJS()),
            xhrFields: {
              withCredentials: true
            }
          }))["catch"](function(e) {
            if (e.status === 409) {
              return votingDoc.toJS();
            } else {
              throw e;
            }
          });
        }).toArray());
      };
    })(this));
  },
  getVotingDocs: function(members) {
    var ids;
    ids = members.map(this.getVotingDocId).toArray();
    return W.all(members.map((function(_this) {
      return function(member) {
        return W($.ajax({
          url: "" + (DbHelper.getRandVotingDbUrl()) + "/" + (_this.getVotingDocId(member)),
          type: 'GET',
          dataType: 'json'
        }))["catch"](function(e) {
          if (e.status === 404) {
            return {
              _id: _this.getVotingDocId(member),
              status: 'unsent'
            };
          } else {
            throw e;
          }
        });
      };
    })(this)).toArray());
  },
  getVotingDocsStatuses: function(members) {
    return this.getVotingDocs(members).then(function(votingDocs) {
      return _.object(_(votingDocs).pluck('_id'), _(votingDocs).pluck('status'));
    });
  },
  prepareEmailRequestDoc: function(member, message) {
    return {
      type: 'rand-voting',
      email: member.get('email'),
      message: message,
      projectId: mediator.project.id,
      projectName: mediator.project.get('name')
    };
  },
  replaceLinkPlaceholder: function(message, member) {
    var votingDocId;
    votingDocId = this.getVotingDocId(member);
    return message.replace(/#link#/g, "http://rand.gradepro.org/_db/rand-voting/_design/voting/index.html#" + votingDocId);
  },
  _getResultsForQuestion: function(question) {
    var assessment, assessmentSectionsOrder, optionTextFromValue, questionName, sections;
    optionTextFromValue = function(options, selectedOption) {
      var _ref;
      return (_ref = options.find(function(option) {
        return option.get('value') === selectedOption;
      })) != null ? _ref.get('text') : void 0;
    };
    questionName = question.get('question');
    assessment = question.getIn(['etdTemplate', 'templateData', 'assessment']);
    assessmentSectionsOrder = assessment.getIn(['sectionsOrder']);
    sections = assessment.getIn(['sections']);
    return Immutable.Map().set(questionName, assessmentSectionsOrder.map(function(sectionId) {
      var criterion, criterionId, options, selections;
      criterionId = sections.getIn([sectionId, 'criterionId']);
      criterion = assessment.getIn(['criteria', criterionId]);
      selections = Immutable.fromJS(criterion.keySeq().includes('optionGroups') ? {
        selectedOption: criterion.get('selectedOption'),
        panelMemberSelectedOption: criterion.get('panelMemberSelectedOption')
      } : (options = criterion.get('options'), {
        selectedOption: optionTextFromValue(options, criterion.get('selectedOption')),
        panelMemberSelectedOption: optionTextFromValue(options, criterion.get('panelMemberSelectedOption'))
      }));
      return Immutable.Map().set(criterion.get('description'), selections);
    }));
  },
  _getResultsFromVotingDoc: function(votingDoc) {
    return votingDoc.get('questions').map((function(_this) {
      return function(question) {
        return _this._getResultsForQuestion(question);
      };
    })(this));
  },
  getVotingResults: function(members) {
    return this.getVotingDocs(members).then((function(_this) {
      return function(votingDocs) {
        return Immutable.fromJS(votingDocs).map(function(votingDoc) {
          return _this._getResultsFromVotingDoc(votingDoc);
        });
      };
    })(this));
  }
};

module.exports = randVotingHelpers;
