var ManagementQuestionExporter, QuestionExporter, formatter, statisticalUtils, utils, _controlRisksNames, _effectMeasures, _metaTypes, _outcomeTypes, _studyDesignTypes, _unitCodes,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

QuestionExporter = require('./question_exporter');

utils = require('./exporter_utils');

formatter = require('lib/formatters').outcome;

statisticalUtils = require('lib/statistical_utils');

_unitCodes = {
  'weeks': 'WEE',
  'years': 'ANN',
  'months': 'MON',
  'days': 'DAY',
  'patientYears': 'dbep:PTY',
  'others': 'dbep:OTH'
};

_outcomeTypes = {
  'narrative': 'NarrativeSummary',
  'dich': 'DichotomousData',
  'cont': 'ContinuousData'
};

_metaTypes = {
  'POOLED': 'Pooled',
  'SINGLE_STUDY': 'SingleStudy',
  'NOT_POOLED': 'NotPooled',
  'RANGE_OF_EFFECTS': 'RangeOfEffects',
  'NOT_REPORTED': 'NotReported',
  'NOT_MEASURED': 'NotMeasured'
};

_controlRisksNames = {
  'lowControl': 'LowControlRisk',
  'moderateControl': 'ModerateControlRisk',
  'highControl': 'HighControlRisk'
};

_effectMeasures = {
  'RR': 'RiskRatio',
  'HR': 'HazardRatio',
  'OR': 'OddsRatio',
  'other': 'OtherEffectMeasure',
  'rateRatio': 'RateRatio',
  'MD': 'MeanDifference',
  'SMD': 'StandardizedMeanDifference',
  'median': 'Median',
  'mean': 'Mean'
};

_studyDesignTypes = {
  'RandTrials': 'RandomisedTrials',
  'ObsStudies.interrupted_time_series': 'ObservationalStudiesInterruptedTimeSeries',
  'ObsStudies.before_after_studies': 'ObservationalStudiesBeforeAfter',
  'ObsStudies.cohort_studies': 'ObservationalStudiesCohort',
  'ObsStudies.case_control_studies': 'ObservationalStudiesCaseControl',
  'ObsStudies.cross_sectional_studies': 'ObservationalStudiesCrossSectional',
  'ObsStudies.case_series': 'ObservationalStudiesCaseSeries',
  'ObsStudies.case_reports': 'ObservationalStudiesCaseReports',
  'ObsStudies.other_design': 'ObservationalStudiesOtherDesign',
  'ObsStudies.case_control_and_other_combined': 'ObservationalStudiesCaseControlAndOtherCombined'
};

module.exports = ManagementQuestionExporter = (function(_super) {
  __extends(ManagementQuestionExporter, _super);

  function ManagementQuestionExporter(_originalQuestion) {
    this.exportEvidenceSummary = __bind(this.exportEvidenceSummary, this);
    this._exportEffectSummaryAndControlRisks = __bind(this._exportEffectSummaryAndControlRisks, this);
    ManagementQuestionExporter.__super__.constructor.call(this, _originalQuestion);
    this._currentCRId = 1;
  }

  ManagementQuestionExporter.prototype._exportPatientGroup = function(outcome) {
    var caseControlPatientGroup, controlGroup, interventionGroup, patientGroup, _ref, _ref1, _ref2, _ref3;
    interventionGroup = {
      '@type': 'InterventionGroup',
      'totalCount': (_ref = outcome['interventionTotal']) != null ? _ref : ''
    };
    if ((outcome['interventionCount'] != null) && outcome['interventionCount'] !== '') {
      interventionGroup['withOutcome'] = outcome['interventionCount'];
    }
    controlGroup = {
      '@type': 'ControlGroup',
      'totalCount': (_ref1 = outcome['controlTotal']) != null ? _ref1 : ''
    };
    if ((outcome['controlCount'] != null) && outcome['controlCount'] !== '') {
      controlGroup['withOutcome'] = outcome['controlCount'];
    }
    patientGroup = [];
    if (interventionGroup['totalCount'] !== '') {
      patientGroup.push(interventionGroup);
    }
    if (controlGroup['totalCount'] !== '') {
      patientGroup.push(controlGroup);
    }
    if ((_ref2 = (_ref3 = outcome['designStudies']) != null ? _ref3['fullName'] : void 0) === 'ObsStudies.case_control_studies' || _ref2 === 'ObsStudies.case_control_and_other_combined') {
      caseControlPatientGroup = {
        '@type': 'CaseControlGroup',
        'cases': outcome['withOutcome'],
        'controls': outcome['withoutOutcome']
      };
      patientGroup.push(caseControlPatientGroup);
    }
    return patientGroup;
  };

  ManagementQuestionExporter.prototype._followUpUnitCode = function(unit) {
    if (unit in _unitCodes) {
      return _unitCodes[unit];
    } else {
      return null;
    }
  };

  ManagementQuestionExporter.prototype._followUpUnitText = function(unit, otherUnit) {
    var unitText;
    unitText = unit !== 'others' ? unit : otherUnit;
    if (unitText !== 'patientYears') {
      return unitText;
    } else {
      return 'patient years';
    }
  };

  ManagementQuestionExporter.prototype._exportFollowUp = function(outcome) {
    var followUp, followUpType, lowerUnitCode, lowerUnitText, lowerValue, name, text, type, unitCode, unitText, upperUnitCode, upperUnitText, upperValue, value;
    followUpType = outcome['followUpType'];
    if (followUpType === 'range') {
      lowerUnitCode = this._followUpUnitCode(outcome['followUpTimeUnit']);
      upperUnitCode = this._followUpUnitCode(outcome['followUpToTimeUnit']);
      lowerUnitText = this._followUpUnitText(outcome['followUpTimeUnit'], outcome['othersFollowUpTimeUnit']);
      upperUnitText = this._followUpUnitText(outcome['followUpToTimeUnit'], outcome['othersFollowUpToTimeUnit']);
      lowerValue = outcome['followUpLength'];
      upperValue = outcome['followUpLengthTo'];
      text = "" + lowerValue + " " + lowerUnitText + " to " + upperValue + " " + upperUnitText;
      followUp = {
        '@type': 'RangeFollowUp',
        'text': text,
        'lowerBound': {
          '@type': 'QuantitativeValue',
          'value': lowerValue,
          'unitCode': lowerUnitCode,
          'unitText': lowerUnitText
        },
        'upperBound': {
          '@type': 'QuantitativeValue',
          'value': upperValue,
          'unitCode': upperUnitCode,
          'unitText': upperUnitText
        }
      };
      return followUp;
    } else {
      unitCode = this._followUpUnitCode(outcome['followUpTimeUnit']);
      unitText = this._followUpUnitText(outcome['followUpTimeUnit'], outcome['othersFollowUpTimeUnit']);
      value = outcome['followUpLength'];
      name = "" + value + " " + unitText;
      type = followUpType === 'mean' ? 'MeanFollowUp' : 'MedianFollowUp';
      followUp = {
        '@type': [type, 'QuantitativeValue'],
        'name': name,
        'value': value,
        'unitCode': unitCode,
        'unitText': unitText
      };
      return followUp;
    }
  };

  ManagementQuestionExporter.prototype._exportEffectMeasure = function(outcome) {
    var effectMeasure;
    effectMeasure = outcome['effectMeasure'];
    if (effectMeasure !== 'other') {
      if (effectMeasure in _effectMeasures) {
        effectMeasure = _effectMeasures[effectMeasure];
      }
      return [effectMeasure];
    } else {
      return ['OtherEffectMeasure', outcome['otherMeasure']];
    }
  };

  ManagementQuestionExporter.prototype._exportEffectSummaryType = function(outcome) {
    if (outcome['metaType'] in _metaTypes) {
      return _metaTypes[outcome['metaType']];
    } else {
      return null;
    }
  };

  ManagementQuestionExporter.prototype._getStudyDesign = function(outcome) {
    var studyDesign, _ref, _ref1, _ref2, _ref3;
    studyDesign = (_ref = (_ref1 = (_ref2 = outcome['designStudies']) != null ? _ref2['fullName'] : void 0) != null ? _ref1 : (_ref3 = outcome['designStudies']) != null ? _ref3['name'] : void 0) != null ? _ref : null;
    if (studyDesign != null) {
      return studyDesign;
    } else {
      throw new Error('Unknown study design!');
    }
  };

  ManagementQuestionExporter.prototype._exportStudyDesignType = function(outcome) {
    var studyDesignTypeLabel;
    studyDesignTypeLabel = this._getStudyDesign(outcome);
    if (studyDesignTypeLabel in _studyDesignTypes) {
      return _studyDesignTypes[studyDesignTypeLabel];
    } else {
      throw new Error('Unknown study design!');
    }
  };

  ManagementQuestionExporter.prototype._exportStudyDesignName = function(outcome) {
    var obsStudiesName, studiesName, studyDesignTypeLabel, studyLabel;
    studyDesignTypeLabel = this._getStudyDesign(outcome);
    if (studyDesignTypeLabel === 'RandTrials') {
      return $.t('es:outcome.randomised_trials_plural');
    } else {
      studyLabel = studyDesignTypeLabel.slice('ObsStudies.'.length);
      obsStudiesName = $.t('es:outcome.observational_studies_plural');
      studiesName = $.t("es:outcome." + studyLabel);
      return "" + obsStudiesName + " (" + studiesName + ")";
    }
  };

  ManagementQuestionExporter.prototype._exportCalculatedControlEffectSummaryAndControlRisk = function(id, outcome, controlRisks, exportAbsoluteEffect, absoluteEffects) {
    var absoluteEffect, cr, effectTypePrefix;
    cr = {
      '@id': '_:cr' + id,
      '@type': 'CalculatedControlRisk',
      'value': outcome['calculatedControlValue']
    };
    controlRisks.push(cr);
    effectTypePrefix = outcome['absEffectAutoCalc'] ? 'AutoCalculated' : 'Custom';
    if (exportAbsoluteEffect) {
      absoluteEffect = {
        '@type': "" + effectTypePrefix + "AbsoluteEffect",
        'forControlRisk': {
          '@id': cr['@id']
        },
        'value': outcome['absEffectSize'],
        'confidenceLevel': statisticalUtils.confidenceLevel,
        'confidenceIntervalFrom': outcome['absEffectFrom'],
        'confidenceIntervalTo': outcome['absEffectTo'],
        'denominator': outcome['absDenominator']
      };
      return absoluteEffects.push(absoluteEffect);
    }
  };

  ManagementQuestionExporter.prototype._exportControlRisksAndAbsoluteEffect = function(id, field, outcome, controlRisks, exportAbsoluteEffect, absoluteEffects) {
    var absoluteEffect, cr, effectSizeFieldSuffix, effectTypePrefix;
    cr = {
      '@id': '_:cr' + id,
      '@type': _controlRisksNames[field],
      'value': outcome[field + 'Risk']
    };
    controlRisks.push(cr);
    if (exportAbsoluteEffect) {
      effectSizeFieldSuffix = utils.capitalizeFirstLetter(field.slice(0, -'Control'.length));
      effectTypePrefix = outcome["absEffectAutoCalc" + effectSizeFieldSuffix] ? 'AutoCalculated' : 'Custom';
      absoluteEffect = {
        '@type': "" + effectTypePrefix + "AbsoluteEffect",
        'forControlRisk': {
          '@id': cr['@id']
        },
        'value': outcome["absEffectSize" + effectSizeFieldSuffix],
        'confidenceLevel': statisticalUtils.confidenceLevel,
        'confidenceIntervalFrom': outcome["absEffectFrom" + effectSizeFieldSuffix],
        'confidenceIntervalTo': outcome["absEffectTo" + effectSizeFieldSuffix],
        'denominator': outcome['absDenominator']
      };
      return absoluteEffects.push(absoluteEffect);
    }
  };

  ManagementQuestionExporter.prototype._exportRelativeEffect = function(outcome, effectMeasure, otherMeasure) {
    var lowerBound, measure, relativeEffect, upperBound;
    relativeEffect = {
      '@type': effectMeasure
    };
    if (outcome['metaType'] === 'RANGE_OF_EFFECTS') {
      lowerBound = outcome['confidenceIntervalFrom'];
      upperBound = outcome['confidenceIntervalTo'];
      measure = otherMeasure != null ? otherMeasure : effectMeasure;
      relativeEffect['value'] = "" + measure + " ranged from " + lowerBound + " to " + upperBound;
      relativeEffect['lowerBound'] = lowerBound;
      relativeEffect['upperBound'] = upperBound;
    } else {
      relativeEffect['value'] = outcome['effectSize'];
      relativeEffect['confidenceLevel'] = statisticalUtils.confidenceLevel;
      relativeEffect['confidenceIntervalFrom'] = outcome['confidenceIntervalFrom'];
      relativeEffect['confidenceIntervalTo'] = outcome['confidenceIntervalTo'];
    }
    return relativeEffect;
  };

  ManagementQuestionExporter.prototype._exportDichotomousEffectSummaryAndControlRisks = function(outcome) {
    var absoluteEffect, absoluteEffects, controlRisks, controlRisksFields, effectMeasure, effectSummary, exportAbsoluteEffect, field, id, otherMeasure, relativeEffect, summaryType, _i, _j, _len, _len1, _ref, _ref1, _ref2;
    summaryType = this._exportEffectSummaryType(outcome);
    if (outcome['metaType'] === 'NOT_POOLED' || ((_ref = (_ref1 = outcome['designStudies']) != null ? _ref1['fullName'] : void 0) === 'ObsStudies.case_series' || _ref === 'ObsStudies.case_reports')) {
      effectSummary = {
        '@type': summaryType
      };
      return [effectSummary];
    } else {
      exportAbsoluteEffect = outcome['metaType'] !== 'RANGE_OF_EFFECTS';
      _ref2 = this._exportEffectMeasure(outcome), effectMeasure = _ref2[0], otherMeasure = _ref2[1];
      id = this._currentCRId;
      controlRisks = [];
      absoluteEffects = [];
      controlRisksFields = ['lowControl', 'moderateControl', 'highControl'];
      if (utils.existsAndIsTrue(outcome['calculatedControl'])) {
        this._exportCalculatedControlEffectSummaryAndControlRisk(id++, outcome, controlRisks, exportAbsoluteEffect, absoluteEffects);
      }
      for (_i = 0, _len = controlRisksFields.length; _i < _len; _i++) {
        field = controlRisksFields[_i];
        if (utils.existsAndIsTrue(outcome[field])) {
          this._exportControlRisksAndAbsoluteEffect(id++, field, outcome, controlRisks, exportAbsoluteEffect, absoluteEffects);
        }
      }
      this._currentCRId = id;
      relativeEffect = this._exportRelativeEffect(outcome, effectMeasure, otherMeasure);
      effectSummary = {
        '@type': summaryType,
        'relativeEffect': relativeEffect
      };
      if (otherMeasure != null) {
        effectSummary['relativeEffect']['name'] = otherMeasure;
        if (exportAbsoluteEffect) {
          for (_j = 0, _len1 = absoluteEffects.length; _j < _len1; _j++) {
            absoluteEffect = absoluteEffects[_j];
            absoluteEffect['name'] = otherMeasure;
          }
        }
      }
      if (exportAbsoluteEffect) {
        effectSummary['absoluteEffect'] = absoluteEffects;
      }
      return [effectSummary, controlRisks];
    }
  };

  ManagementQuestionExporter.prototype._exportEffectSummaryAndControlRisks = function(outcome) {
    var absoluteEffect, effectMeasure, effectSummary, otherMeasure, _ref, _ref1, _ref2;
    _ref = this._exportEffectMeasure(outcome), effectMeasure = _ref[0], otherMeasure = _ref[1];
    switch (outcome['type']) {
      case 'narrative':
        effectSummary = {
          '@type': this._exportEffectSummaryType(outcome),
          'narrativeSummary': (_ref1 = outcome['narrativeDesc']) != null ? _ref1 : ''
        };
        return [effectSummary];
      case 'cont':
        absoluteEffect = null;
        if ((_ref2 = outcome['metaType']) === 'POOLED' || _ref2 === 'SINGLE_STUDY') {
          absoluteEffect = {
            '@type': [effectMeasure, 'QuantitativeValue'],
            'value': outcome['effectSize'],
            'confidenceLevel': statisticalUtils.confidenceLevel,
            'confidenceIntervalFrom': outcome['confidenceIntervalFrom'],
            'confidenceIntervalTo': outcome['confidenceIntervalTo']
          };
          if (otherMeasure != null) {
            absoluteEffect['name'] = otherMeasure;
          }
          if ((outcome['units'] != null) && outcome['units'] !== '') {
            absoluteEffect['unitText'] = outcome['units'];
            absoluteEffect['unitCode'] = _unitCodes['others'];
          }
        }
        effectSummary = {
          '@type': this._exportEffectSummaryType(outcome)
        };
        if (absoluteEffect != null) {
          effectSummary['absoluteEffect'] = absoluteEffect;
        }
        return [effectSummary];
      case 'dich':
        return this._exportDichotomousEffectSummaryAndControlRisks(outcome);
      default:
        throw new Error("Unknown outcome's type");
    }
  };

  ManagementQuestionExporter.prototype._exportQuality = function(outcome) {
    var doseResponseGradient, doseResponseGradientExplanations, doseResponseGradientValue, explanations, imprecision, imprecisionExplanations, imprecisionValue, inconsistency, inconsistencyExplanations, inconsistencyValue, indirectness, indirectnessExplanations, indirectnessValue, largeEffect, largeEffectExplanations, largeEffectValue, plausibleConfounding, plausibleConfoundingExplanations, plausibleConfoundingValue, publicationBias, publicationBiasExplanations, publicationBiasValue, quality, qualityValue, riskOfBias, riskOfBiasExplanations, riskOfBiasValue;
    qualityValue = outcome['quality'];
    explanations = outcome['footnotes'];
    riskOfBias = outcome['riskOfBias']['label'];
    riskOfBiasValue = outcome['riskOfBias']['score'];
    riskOfBiasExplanations = this._findExplanations(explanations, 'riskOfBias', riskOfBiasValue);
    inconsistency = outcome['inconsistency']['label'];
    inconsistencyValue = outcome['inconsistency']['score'];
    inconsistencyExplanations = this._findExplanations(explanations, 'inconsistency', inconsistencyValue);
    indirectness = outcome['indirectness']['label'];
    indirectnessValue = outcome['indirectness']['score'];
    indirectnessExplanations = this._findExplanations(explanations, 'indirectness', indirectnessValue);
    imprecision = outcome['imprecision']['label'];
    imprecisionValue = outcome['imprecision']['score'];
    imprecisionExplanations = this._findExplanations(explanations, 'imprecision', imprecisionValue);
    publicationBias = this._getQualityOptionForLabel(outcome['publicationBias']['label'], 'publicationBias');
    publicationBiasValue = outcome['publicationBias']['score'];
    publicationBiasExplanations = this._findExplanations(explanations, 'publicationBias', publicationBiasValue);
    doseResponseGradient = this._getQualityOptionForLabel(outcome['doseResponseGradient']['label']);
    doseResponseGradientValue = outcome['doseResponseGradient']['score'];
    doseResponseGradientExplanations = this._findExplanations(explanations, 'doseResponseGradient', doseResponseGradientValue);
    plausibleConfounding = this._getQualityOptionForLabel(outcome['plausibleConfounding']['label']);
    plausibleConfoundingValue = outcome['plausibleConfounding']['score'];
    plausibleConfoundingExplanations = this._findExplanations(explanations, 'plausibleConfounding', plausibleConfoundingValue);
    largeEffect = this._getQualityOptionForLabel(outcome['largeEffect']['label']);
    largeEffectValue = outcome['largeEffect']['score'];
    largeEffectExplanations = this._findExplanations(explanations, 'largeEffect', largeEffectValue);
    quality = {
      '@type': 'GradeQuality',
      'value': qualityValue,
      'name': this._qualityNameForValue(qualityValue),
      'riskOfBias': {
        '@type': this._qualityLabelForScore(riskOfBiasValue),
        'name': this._getQualityOptionForLabel(riskOfBias)
      },
      'inconsistency': {
        '@type': this._qualityLabelForScore(inconsistencyValue),
        'name': this._getQualityOptionForLabel(inconsistency)
      },
      'indirectness': {
        '@type': this._qualityLabelForScore(indirectnessValue),
        'name': this._getQualityOptionForLabel(indirectness)
      },
      'imprecision': {
        '@type': this._qualityLabelForScore(imprecisionValue),
        'name': this._getQualityOptionForLabel(imprecision)
      },
      'otherConsiderations': {
        'name': formatter.otherConsiderations(outcome),
        'publicationBias': {
          '@type': this._qualityLabelForScore(publicationBiasValue),
          'name': publicationBias === 'not serious' ? 'not detected' : publicationBias
        },
        'doseResponseGradient': {
          '@type': this._qualityLabelForScore(doseResponseGradientValue),
          'name': doseResponseGradient
        },
        'plausibleConfounding': {
          '@type': this._qualityLabelForScore(plausibleConfoundingValue),
          'name': plausibleConfounding
        },
        'largeEffect': {
          '@type': this._qualityLabelForScore(largeEffectValue),
          'name': largeEffect
        }
      }
    };
    if (riskOfBiasExplanations.length > 0) {
      quality['riskOfBias']['explanation'] = riskOfBiasExplanations;
    }
    if (inconsistencyExplanations.length > 0) {
      quality['inconsistency']['explanation'] = inconsistencyExplanations;
    }
    if (indirectnessExplanations.length > 0) {
      quality['indirectness']['explanation'] = indirectnessExplanations;
    }
    if (imprecisionExplanations.length > 0) {
      quality['imprecision']['explanation'] = imprecisionExplanations;
    }
    if (publicationBiasExplanations.length > 0) {
      quality['otherConsiderations']['publicationBias']['explanation'] = publicationBiasExplanations;
    }
    if (doseResponseGradientExplanations.length > 0) {
      quality['otherConsiderations']['doseResponseGradient']['explanation'] = doseResponseGradientExplanations;
    }
    if (plausibleConfoundingExplanations.length > 0) {
      quality['otherConsiderations']['plausibleConfounding']['explanation'] = plausibleConfoundingExplanations;
    }
    if (largeEffectExplanations.length > 0) {
      quality['otherConsiderations']['largeEffect']['explanation'] = largeEffectExplanations;
    }
    return quality;
  };

  ManagementQuestionExporter.prototype._processOutcomeToEvidenceSummary = function(outcome) {
    var comments, controlRisks, effectSummary, explanations, exportedEvidenceSummary, followUp, importanceExplanations, metaType, outcomeId, outcomeType, _ref, _ref1, _ref10, _ref2, _ref3, _ref4, _ref5, _ref6, _ref7, _ref8, _ref9;
    outcomeId = this._outcomes[outcome['name']];
    outcomeType = _outcomeTypes[outcome['type']];
    metaType = outcome['metaType'];
    comments = outcome['comments'];
    explanations = outcome['footnotes'];
    importanceExplanations = this._findExplanations(explanations, 'importance', 0);
    followUp = this._exportFollowUp(outcome);
    exportedEvidenceSummary = {};
    if (metaType === 'NOT_MEASURED' || metaType === 'NOT_REPORTED') {
      exportedEvidenceSummary = {
        '@type': outcomeType,
        'forOutcome': {
          '@id': outcomeId
        },
        'followUp': followUp,
        'measuredWith': {
          '@type': 'OutcomeMeasure',
          'name': (_ref = outcome['measuredWith']) != null ? _ref : ''
        },
        'effectSummary': {
          '@type': this._exportEffectSummaryType(outcome)
        },
        'importance': {
          '@type': 'GradeImportance',
          'value': (_ref1 = outcome['importance']) != null ? _ref1 : '',
          'name': (_ref2 = outcome['importanceLabel']) != null ? _ref2 : ''
        }
      };
      if (outcome['type'] === 'narrative') {
        exportedEvidenceSummary['effectSummary']['narrativeSummary'] = (_ref3 = outcome['narrativeDesc']) != null ? _ref3 : '';
      }
    } else {
      _ref4 = this._exportEffectSummaryAndControlRisks(outcome), effectSummary = _ref4[0], controlRisks = _ref4[1];
      exportedEvidenceSummary = {
        '@type': outcomeType,
        'forOutcome': {
          '@id': outcomeId
        },
        'studyDesign': {
          '@type': this._exportStudyDesignType(outcome),
          'name': this._exportStudyDesignName(outcome)
        },
        'numberOfStudies': (_ref5 = outcome['noOfStudies']) != null ? _ref5 : '',
        'patientGroup': this._exportPatientGroup(outcome),
        'followUp': followUp,
        'measuredWith': {
          '@type': 'OutcomeMeasure',
          'name': (_ref6 = outcome['measuredWith']) != null ? _ref6 : ''
        },
        'importance': {
          '@type': 'GradeImportance',
          'value': (_ref7 = outcome['importance']) != null ? _ref7 : '',
          'name': (_ref8 = outcome['importanceLabel']) != null ? _ref8 : ''
        },
        'effectSummary': effectSummary,
        'importance': {
          '@type': 'GradeImportance',
          'value': (_ref9 = outcome['importance']) != null ? _ref9 : '',
          'name': (_ref10 = outcome['importanceLabel']) != null ? _ref10 : ''
        },
        'quality': this._exportQuality(outcome)
      };
      if (controlRisks != null) {
        exportedEvidenceSummary['controlRisk'] = controlRisks;
      }
    }
    if (utils.existsAndNotEmpty(comments)) {
      exportedEvidenceSummary['comments'] = comments;
    }
    if (outcome['type'] === 'cont') {
      if (utils.existsAndNotEmpty(outcome['scaleTo'])) {
        exportedEvidenceSummary['measuredWith']['upperBound'] = outcome['scaleTo'];
      }
      if (utils.existsAndNotEmpty(outcome['scaleFrom'])) {
        exportedEvidenceSummary['measuredWith']['lowerBound'] = outcome['scaleFrom'];
      }
    }
    if ((importanceExplanations != null) && importanceExplanations.length > 0) {
      exportedEvidenceSummary['importance']['explanation'] = importanceExplanations;
    }
    return exportedEvidenceSummary;
  };

  ManagementQuestionExporter.prototype.exportEvidenceSummary = function() {
    var outcome, _i, _len, _ref, _results;
    _ref = this._originalQuestion['outcomes'];
    _results = [];
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      outcome = _ref[_i];
      _results.push(this._processOutcomeToEvidenceSummary(outcome));
    }
    return _results;
  };

  return ManagementQuestionExporter;

})(QuestionExporter);
