var CellFootnotesView, CharactersLimit, ContextMenuView, EditableItemView, HelpBoxView, LoginView, Question, QuestionView, Router, WysiwygFootnotes, mediator, template, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

EditableItemView = require('base/views/editable_item_view');

template = require('views/templates/evidence_syntheses/question');

Question = require('models/question');

Router = require('router');

mediator = require('mediator');

utils = require('base/lib/utils');

CellFootnotesView = require('views/evidence_syntheses/cell_footnotes_view');

WysiwygFootnotes = require('lib/traits/wysiwyg_footnotes');

ContextMenuView = require('views/evidence_syntheses/context_menu_view');

LoginView = require('views/login_view');

HelpBoxView = require('views/help_box_view');

CharactersLimit = require('lib/traits/characters_limit');

module.exports = QuestionView = (function(_super) {
  __extends(QuestionView, _super);

  function QuestionView() {
    this.onFocusLost = __bind(this.onFocusLost, this);
    return QuestionView.__super__.constructor.apply(this, arguments);
  }

  QuestionView.prototype.template = template;

  QuestionView.prototype.className = 'question';

  QuestionView.prototype.ellipsisSelector = 'caption > a';

  QuestionView.prototype.confirmDelete = false;

  QuestionView.prototype.getBindings = function(tableTitle) {
    var binds;
    binds = {
      '#intervention': 'intervention',
      '#comparison': 'comparison',
      '#healthProblemOrPopulation': 'healthProblemOrPopulation',
      '#questionFormat': {
        observe: 'questionFormat',
        selectOptions: {
          collection: (function() {
            return Question.QUESTION_FORMATS;
          })
        }
      },
      '#tableTitle': 'tableTitle',
      '#bibliography': 'bibliography',
      '#authors': 'authors',
      '#settings': 'settings',
      '#question': 'question',
      '#indexTest': 'indexTest',
      '#comparatorTest': 'comparatorTest',
      '#targetCondition': 'targetCondition',
      '#indexTestCutOff': 'indexTestCutOff',
      '#comparatorTestCutOff': 'comparatorTestCutOff',
      '#referenceTest': 'referenceTest',
      '#referenceTestShortName': 'referenceTestShortName',
      '#referenceTestThreshold': 'referenceTestThreshold',
      '#healthCondition': 'healthCondition',
      '#population': {
        observe: 'population',
        selectOptions: {
          collection: this.model.getManagementPopulations
        }
      },
      '#populationOther': 'populationOther',
      '#overTime': 'overTime'
    };
    _(binds).extend(tableTitle ? {
      '.caption a': 'tableTitle'
    } : {
      '.caption a': 'question'
    });
    return binds;
  };

  QuestionView.prototype.footnotesBindings = function() {
    var bindings, property;
    bindings = {
      'intervention': [
        {
          selector: '#intervention',
          method: 'after'
        }, {
          selector: 'label[for="question"] ~ .sup',
          method: 'after'
        }
      ],
      'indexTest': [
        {
          selector: '#indexTest',
          method: 'after'
        }
      ],
      'comparatorTest': [
        {
          selector: '#comparatorTest',
          method: 'after'
        }
      ],
      'targetCondition': [
        {
          selector: '#targetCondition',
          method: 'after'
        }
      ],
      'comparison': [
        {
          selector: '#comparison',
          method: 'after'
        }, {
          selector: 'label[for="question"] ~ .sup',
          method: 'after'
        }
      ],
      'healthProblemOrPopulation': [
        {
          selector: '#healthProblemOrPopulation',
          method: 'after'
        }, {
          selector: 'label[for="question"] ~ .sup',
          method: 'after'
        }
      ],
      'settings': [
        {
          selector: 'label[for="settings"] ~ .sup',
          method: 'append'
        }
      ],
      'indexTestCutOff': [
        {
          selector: 'label[for="indexTestCutOff"] ~ .sup',
          method: 'append'
        }
      ],
      'comparatorTestCutOff': [
        {
          selector: 'label[for="comparatorTestCutOff"] ~ .sup',
          method: 'append'
        }
      ],
      'tableTitle': [
        {
          selector: 'label[for="tableTitle"] ~ .sup',
          method: 'append'
        }, {
          selector: 'label[for="question"] ~ .sup',
          method: 'after'
        }
      ],
      'referenceTest': [
        {
          selector: 'label[for="referenceTest"] ~ .sup',
          method: 'append'
        }
      ],
      'referenceTestShortName': [
        {
          selector: 'label[for="referenceTestShortName"] ~ .sup',
          method: 'append'
        }
      ],
      'referenceTestThreshold': [
        {
          selector: 'label[for="referenceTestThreshold"] ~ .sup',
          method: 'append'
        }
      ],
      'bibliography': [
        {
          selector: 'label[for="bibliography"] ~ .sup',
          method: 'append'
        }
      ],
      'authors': [
        {
          selector: 'label[for=authors] ~ .sup',
          method: 'append'
        }
      ]
    };
    for (property in bindings) {
      bindings[property].push({
        selector: '.question-footnotes-attr',
        method: 'append'
      });
    }
    return bindings;
  };

  QuestionView.prototype.initialize = function() {
    QuestionView.__super__.initialize.apply(this, arguments);
    this.bindings = this.getBindings(this.options.tableTitle);
    this._delegateEvents();
    this.modelBind('destroy', this.questionDeleted);
    this.modelBind('change:population', this._togglePopulationOther);
    this.enable(WysiwygFootnotes);
    return this.enable(CharactersLimit, {
      limits: [
        {
          selector: '#referenceTestShortName',
          limit: 100
        }
      ]
    });
  };

  QuestionView.prototype._delegateEvents = function() {
    this.delegate('click', 'button.save-proceed', this._saveAndProceed);
    this.delegate('click', 'button.duplicate', this._duplicate);
    this.delegate('click', '#edit-title', this._editTitle);
    this.delegate('focus', '.separated input', function(e) {
      return _.defer(function() {
        return $(e.target).select();
      });
    });
    this.delegate('focus', 'input, textarea, select', this._propertyActivated);
    this.delegate('click', '.disabled-sof-title > div', function(e) {
      this.$("[name=" + this.property + "]").blur();
      return this._propertyActivated(e);
    });
    this.delegate('click', '.add-comparator-test', this._addComparatorTest);
    this.delegate('click', '.remove-comparator-test', this._removeComparatorTest);
    this.delegate('contextmenu', 'input, textarea, select', this.showContextMenu);
    return this.delegate('contextmenu', '.disabled-sof-title > div', this.showContextMenu);
  };

  QuestionView.prototype._togglePopulationOther = function() {
    if (this.model.get('population') === 'other') {
      return this.$('#populationOther').show();
    } else {
      return this.$('#populationOther').hide();
    }
  };

  QuestionView.prototype.focusInput = function() {
    return this.$('#intervention, #indexTest').focus();
  };

  QuestionView.prototype._addComparatorTest = function() {
    this.model.set('comparatorTestPresent', true);
    return this.render();
  };

  QuestionView.prototype._removeComparatorTest = function() {
    this.model.set('comparatorTestPresent', false);
    return this.render();
  };

  QuestionView.prototype._propertyActivated = function(e) {
    this.property = $(e.target).attr('name');
    return this.publishEvent('activatedProperty', this.property, $(e.target), this.model);
  };

  QuestionView.prototype._editTitle = function() {
    var toggleManualTitle;
    if (!this.editing) {
      return;
    }
    toggleManualTitle = (function(_this) {
      return function(msg, confirmText, declineText, isManualTitle) {
        return mediator.dialogs.confirm({
          yesClass: 'danger',
          message: msg,
          confirmText: confirmText,
          declineText: declineText
        }, function(confirmed) {
          if (confirmed) {
            _this.model.set('manualTableTitle', isManualTitle);
            if (!isManualTitle) {
              _this.model.set(_this.model.calculateTitles());
            }
            return _this.render();
          } else {
            return _this.focusInput();
          }
        });
      };
    })(this);
    if (this.model.get('manualTableTitle')) {
      return toggleManualTitle($.t('es:question.switch_to_auto_table_title_warning'), $.t('es:question.change_to_auto_title'), $.t('es:question.keep_manual_title'), false);
    } else {
      return toggleManualTitle($.t('es:question.switch_to_manual_table_title_warning'), $.t('es:question.change_to_manual_title'), $.t('es:question.keep_auto_title'), true);
    }
  };

  QuestionView.prototype.showContextMenu = function(e) {
    mediator.setFocus(this, (function(_this) {
      return function() {
        var $target, contextMenu;
        $target = $(e.target);
        contextMenu = new ContextMenuView({
          questionModel: _this.model,
          footnotesBindingModel: _this.model,
          property: _this.property,
          propertyView: _this,
          cell: $target,
          abortHandler: function() {
            return mediator.setFocus(_this);
          }
        });
        _this.subview('contextMenu', contextMenu);
        return contextMenu.show(utils.getEventCoordinates(e));
      };
    })(this));
    return false;
  };

  QuestionView.prototype.isAbandoningChanges = function(focusTo) {
    return !_["instanceof"](focusTo, [HelpBoxView, CellFootnotesView, LoginView]) && (this.model.isNew() || this.model.isDirty());
  };

  QuestionView.prototype._saveAndProceed = function() {
    return this.saveChanges().then((function(_this) {
      return function() {
        return mediator.publish('!router:route', Router.prototype.getProjectRelativeUrl("/evidence-syntheses/" + _this.model.id));
      };
    })(this));
  };

  QuestionView.prototype._duplicate = function() {
    return this.saveChanges().then((function(_this) {
      return function() {
        return _this.model.collection.create(_this.model.duplicate());
      };
    })(this));
  };

  QuestionView.prototype.onFocusLost = function(focusTo) {
    var allowedToBeFocused;
    allowedToBeFocused = [CellFootnotesView, ContextMenuView, HelpBoxView, LoginView];
    if (!_["instanceof"](focusTo, allowedToBeFocused)) {
      this._close();
    }
    return this.publishEvent('deactivatedProperty');
  };

  QuestionView.prototype.afterRender = function() {
    QuestionView.__super__.afterRender.apply(this, arguments);
    this.stickFootnotes(this.model);
    if (this.model.get('comparatorTestPresent')) {
      this.$('button.add-comparator-test').remove();
    }
    this.$el.attr('data-question-id', this.model.id);
    this.$el.attr('data-question-order-number', this.model.get('orderNumber'));
    this._setDiagnosticQuestionClass();
    return this._togglePopulationOther();
  };

  QuestionView.prototype._setDiagnosticQuestionClass = function() {
    var _base;
    if (typeof (_base = this.model).isDiagnostic === "function" ? _base.isDiagnostic() : void 0) {
      if (this.model.get('comparatorTestPresent')) {
        this.$el.addClass('diagnostic-comparator');
        return this.$el.removeClass('diagnostic-index');
      } else {
        this.$el.addClass('diagnostic-index');
        return this.$el.removeClass('diagnostic-comparator');
      }
    }
  };

  QuestionView.prototype.getTemplateData = function() {
    var data, _ref;
    data = QuestionView.__super__.getTemplateData.apply(this, arguments);
    return _(data).extend({
      isPrintout: this.options.isPrintout,
      viewTypeName: this.options.viewTypeName,
      tableTitle: this.options.tableTitle,
      lastUpdate: (_ref = data.$timestamp) != null ? _ref : data.lastUpdate
    });
  };

  QuestionView.prototype.questionDeleted = function(deletedQuestion) {
    if (deletedQuestion.isNew()) {
      return;
    }
    return this.parentView.questionDeleted(deletedQuestion);
  };

  QuestionView.prototype.dispose = function() {
    this.unstickFootnotes();
    return QuestionView.__super__.dispose.apply(this, arguments);
  };

  return QuestionView;

})(EditableItemView);
