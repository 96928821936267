var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "type=\"password\"";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<fieldset>\n  <div class=\"row\">\n    <div class=\"col-12\">"
    + escapeExpression(((helper = (helper = helpers.message || (depth0 != null ? depth0.message : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"message","hash":{},"data":data}) : helper)))
    + "</div>\n  </div>\n  <div class=\"row mt-20\">\n    <input class=\"col-10 col-offset-1\"\n      ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.maskedInput : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n      size=\"50\">\n  </div>\n</fieldset>\n<div class=\"row mt-20\">\n  <div class=\"col-4 col-offset-2\">\n    <button class=\"btn btn-block cancel\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/actions.cancel", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n  </div>\n  <div class=\"col-4\">\n    <button class=\"btn btn-block btn-success ok\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/actions.ok", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n  </div>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}