var ScopeOutcomesController, SubmoduleController, W, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

SubmoduleController = require('./base/submodule_controller');

W = require('when');

mediator = require('mediator');

module.exports = ScopeOutcomesController = (function(_super) {
  __extends(ScopeOutcomesController, _super);

  function ScopeOutcomesController() {
    return ScopeOutcomesController.__super__.constructor.apply(this, arguments);
  }

  ScopeOutcomesController.prototype.historyURL = function(params) {
    return ScopeOutcomesController.__super__.historyURL.apply(this, arguments) + ("/" + params.step);
  };

  ScopeOutcomesController.prototype.initialize = function() {
    ScopeOutcomesController.__super__.initialize.apply(this, arguments);
    return this.service = mediator.services.outcomeGeneration;
  };

  ScopeOutcomesController.prototype.dispatch = function(params) {
    var _ref;
    return W((_ref = params.step) != null ? _ref : this.service.getCurrentStep()).then((function(_this) {
      return function(step) {
        return _this.redirectTo('scope_outcomes', step, _(params).extend({
          step: step
        }));
      };
    })(this));
  };

  ScopeOutcomesController.prototype.initial = function(params) {
    return alert('it works!');
  };

  return ScopeOutcomesController;

})(SubmoduleController);
