Translation = require 'components/mixins/translation'
HorizontalRadios = require './horizontal_radios'
EtdActions = require 'actions/etd_actions'
Button = require 'components/common/button'
EditButton = require 'components/common/edit_button'
SaveButton = require 'components/common/save_button'
DeleteButton = require 'components/common/delete_button'

ResourcesQuestions = React.createClass
  displayName: 'DetailedJudgementsResourcesQuestions'

  mixins: [Translation('es:recommendations.table')]

  propTypes:
    groupPrefix: React.PropTypes.string.isRequired
    sectionId: React.PropTypes.string.isRequired
    questions: React.PropTypes.object.isRequired

  getInitialState: ->
    editedResource: null

  changeResourceName: (questionIdx, resourceIdx) -> (e) =>
    value = e.currentTarget.value
    @_debouncedChangeResourceName ?= _.debounce(EtdActions.changeDetailedResourceName, 200)
    @_debouncedChangeResourceName @props.sectionId, questionIdx, resourceIdx, value

  changeRadioOption: (questionIdx, resourceIdx) -> (value) =>
    EtdActions.updateDetailedResourcesQuestion @props.sectionId, questionIdx, resourceIdx, value

  addResource: (questionIdx) -> =>
    @setState editedResource: null
    EtdActions.addDetailedResource @props.sectionId, questionIdx

  saveResource: (questionIdx, resourceIdx) -> =>
    @setState editedResource: null

  editResource: (questionIdx, resourceIdx) -> =>
    @setState editedResource: "#{questionIdx}-#{resourceIdx}"

  deleteResource: (questionIdx, resourceIdx) -> =>
    @setState editedResource: null
    EtdActions.removeDetailedResource @props.sectionId, questionIdx, resourceIdx

  isResourceEdited: (questionIdx, resourceIdx) ->
    @state.editedResource is "#{questionIdx}-#{resourceIdx}"

  render: ->
    <div className="resources-questions">
      {@props.questions.map (question, questionIdx) =>
        <div key={questionIdx} className="judgements-table-container">
          <span>{question.get 'question'}</span>
          <table className="judgements-table">
            <thead className="judgements-table-header">
              <tr className="judgement-table-row">
                <th>{if @props.anticipatedOutcomes
                  @i18n 'anticipated_outcomes'
                else
                  @i18n 'main_resources'}</th>
                <th colSpan=2>{@i18n 'judgements'}</th>
              </tr>
            </thead>
            <tbody>
              {(question.get('resources') ? Immutable.List()).map (resource, resourceIdx) =>
                <tr key={resourceIdx} className="judgement-table-row">
                  <td className="resource-name">
                  {
                    if @isResourceEdited questionIdx, resourceIdx
                      <textarea
                        className="resource-name-editor"
                        defaultValue={resource.get 'name'}
                        onChange={@changeResourceName questionIdx, resourceIdx}
                      />
                    else
                      resource.get 'name'
                  }
                  </td>
                  <td>
                    <HorizontalRadios
                      groupName={"#{@props.groupPrefix}_resources_quesitons_#{resourceIdx}"}
                      options={question.get('options')}
                      additionalOptions={question.get('additionalOptions')}
                      selectedOption={resource.get('selectedOption')}
                      onChange={@changeRadioOption questionIdx, resourceIdx}
                    />
                  </td>
                  <td className="buttons-cell">
                    {if @isResourceEdited questionIdx, resourceIdx
                      [
                        <SaveButton
                          key='save-resource'
                          className='save-resource'
                          onClick={@saveResource questionIdx, resourceIdx}
                        />
                        <DeleteButton
                          key='delete-resource'
                          className='delete-resource'
                          onClick={@deleteResource questionIdx, resourceIdx}
                        />
                      ]
                    else
                      <EditButton
                        className="edit-resource"
                        onClick={@editResource questionIdx, resourceIdx}/>
                    }
                  </td>
                </tr>
              }
            </tbody>
          </table>
          <Button
            className="btn btn-block add-resource-btn"
            label={if @props.anticipatedOutcomes
              @i18n 'add_anticipated_outcome'
            else
              @i18n 'add_resource'}
            onClick={@addResource questionIdx}
          />
        </div>
      }
    </div>

module.exports = ResourcesQuestions
