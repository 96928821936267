var CoiMatrixView, CoiMembersView, CoiToolbarView, CoiView, FormsApiHelper, TabsView, View, W, mediator, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

CoiToolbarView = require('./coi_toolbar_view');

template = require('views/templates/team/coi');

mediator = require('mediator');

CoiMembersView = require('views/team/coi_members_view');

FormsApiHelper = require('base/lib/forms_api_helper').coi;

TabsView = require('views/tabs_view');

CoiMatrixView = require('views/team/coi_matrix_view');

W = require('when');

module.exports = CoiView = (function(_super) {
  __extends(CoiView, _super);

  function CoiView() {
    this._createCoiForm = __bind(this._createCoiForm, this);
    this._createCoiFormReminder = __bind(this._createCoiFormReminder, this);
    return CoiView.__super__.constructor.apply(this, arguments);
  }

  CoiView.prototype.container = '#page-container';

  CoiView.prototype.className = 'coi-view';

  CoiView.prototype.template = template;

  CoiView.prototype.autoRender = true;

  CoiView.prototype.bindings = {
    'input[name=formType]': {
      observe: 'coiFormType',
      onGet: function(val) {
        if (val === 'imcje') {
          return 'icmje';
        } else {
          return val;
        }
      }
    }
  };

  CoiView.prototype._filteredCoiMembersViewTabOptions = function(title, filter) {
    return {
      view: CoiMembersView,
      title: title,
      options: {
        collection: this.members,
        filterer: function(member) {
          return member.hasCoiFormStatus.apply(member, filter);
        }
      }
    };
  };

  CoiView.prototype.initialize = function() {
    var tabsView;
    CoiView.__super__.initialize.apply(this, arguments);
    this.project = mediator.project;
    this.members = mediator.colls.teamMembers;
    this.delegate('click', 'button.send-mail', this.sendNewNotifications);
    this.delegate('click', 'button.send-reminder-mail', this.sendReminderNotifications);
    this.delegate('click', 'button.refresh-statuses', this.refreshCoiStatuses);
    tabsView = new TabsView({
      container: '.tabs-container',
      tabViews: [
        {
          view: CoiMembersView,
          title: $.t('team:coi.all_members_tab'),
          options: {
            collection: this.members
          }
        }, this._filteredCoiMembersViewTabOptions($.t('team:coi.unnotified_members_tab'), ['not-sent']), this._filteredCoiMembersViewTabOptions($.t('team:coi.notified_members_tab'), ['new', 'mail-sent', 'in-progress']), this._filteredCoiMembersViewTabOptions($.t('team:coi.completed_members_tab'), ['completed']), {
          view: CoiMatrixView,
          title: $.t('team:coi.matrix_tab'),
          options: {
            collection: mediator.questions
          }
        }
      ]
    });
    return this.subview('tabs', tabsView);
  };

  CoiView.prototype.getTemplateData = function() {
    return {
      coiFormCreated: this.project.get('coiFormCreated'),
      members: this.members,
      coiFormTypes: ['icmje', 'who', 'nhf', 'mspsc']
    };
  };

  CoiView.prototype.afterRender = function() {
    CoiView.__super__.afterRender.apply(this, arguments);
    this.stickit(this.project, this.bindings);
    return this.subview('tabs').render();
  };

  CoiView.prototype.dispose = function() {
    this.unstickit(this.project);
    return CoiView.__super__.dispose.apply(this, arguments);
  };

  CoiView.prototype._disableCoiFormTypeRadioButtons = function() {
    return this.$('input[name="formType"]').prop('disabled', true);
  };

  CoiView.prototype._sendNotifications = function(membersFilter, mapper) {
    if (!mediator.project.get('coiFormType')) {
      mediator.dialogs.error($.t('team:coi.form_type.not_selected'));
      return;
    }
    return mediator.dialogs.confirm($.t('messages.confirm'), (function(_this) {
      return function(confirmed) {
        if (confirmed) {
          mediator.project.set('coiFormCreated', true);
          _this._disableCoiFormTypeRadioButtons();
          return mediator.project.save().then(function() {
            return _this.members.filter(membersFilter).map(mapper);
          }).otherwise(mediator.dialogs.fatalError);
        }
      };
    })(this));
  };

  CoiView.prototype.sendReminderNotifications = function() {
    return this._sendNotifications((function(m) {
      return m.hasCoiFormStatus('new', 'mail-sent', 'in-progress');
    }), this._createCoiFormReminder);
  };

  CoiView.prototype.sendNewNotifications = function() {
    return this._sendNotifications((function(m) {
      return !m.get('coiFormId');
    }), this._createCoiForm);
  };

  CoiView.prototype._getCoiForm = function(member, type) {
    if (type == null) {
      type = 'coiForm';
    }
    return {
      type: type,
      coiFormType: mediator.project.get('coiFormType'),
      user: member.id,
      firstName: member.get('givenNames'),
      lastName: member.get('lastName'),
      email: member.get('email'),
      project: mediator.project.get('name'),
      status: 'new'
    };
  };

  CoiView.prototype._createCoiFormReminder = function(member) {
    return FormsApiHelper.saveFormDoc(_(this._getCoiForm(member, 'coiFormReminder')).extend({
      coiDocId: member.get('coiFormId')
    })).otherwise(mediator.dialogs.couchError);
  };

  CoiView.prototype._createCoiForm = function(member) {
    return FormsApiHelper.saveFormDoc(this._getCoiForm(member)).otherwise(mediator.dialogs.couchError).then((function(_this) {
      return function(response) {
        member.set({
          coiFormId: response.id
        });
        return member.save().then(member.checkCoiFormStatus).then(function() {
          return _this.subview('tabs').render();
        });
      };
    })(this));
  };

  CoiView.prototype.refreshCoiStatuses = function() {
    var member;
    return W.all((function() {
      var _i, _len, _ref, _results;
      _ref = this.members.models;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        member = _ref[_i];
        _results.push(member.checkCoiFormStatus());
      }
      return _results;
    }).call(this)).then((function(_this) {
      return function() {
        return _this.subview('tabs').render();
      };
    })(this));
  };

  return CoiView;

})(View);
