var Chaplin, Validation, View, enableTraitMixin, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Chaplin = require('chaplin');

require('lib/view_helper');

require('lib/view_partials');

mediator = require('mediator');

Validation = require('base/validation');

enableTraitMixin = require('base/enable_trait_mixin');

module.exports = View = (function(_super) {
  __extends(View, _super);

  function View() {
    _(this).extend(enableTraitMixin);
    View.__super__.constructor.apply(this, arguments);
  }

  View.prototype.getTemplateFunction = function() {
    return this.template;
  };

  View.prototype.initialize = function() {
    if (this.parentView == null) {
      this.parentView = this.options.parentView;
    }
    View.__super__.initialize.apply(this, arguments);
    this.enable(require('base/lib/traits/tooltips'));
    mediator.actionsQueue.push("Initialized view: " + (_.functionName(this.constructor)));
    return ga('send', 'event', {
      eventCategory: 'View',
      eventAction: 'created',
      eventLabel: _.functionName(this.constructor)
    });
  };

  View.prototype.bindValidationTooltips = function() {
    return Validation.bind(this, {
      valid: function(view, attr) {
        var input;
        if (view.disposed) {
          return;
        }
        input = view.$("[name='" + attr + "']");
        input.removeClass('input-error');
        return view._hideTooltips(input);
      },
      invalid: function(view, attr, error) {
        var input;
        if (view.disposed) {
          return;
        }
        if (mediator.debug) {
          console.log("invalid " + attr + " - " + error);
        }
        input = view.$("[name='" + attr + "']");
        input.addClass('input-error');
        if (!input.data('tooltip-id')) {
          return view._addTooltips('validationError', input, "" + error);
        }
      }
    });
  };

  View.prototype.afterRender = function() {
    var _ref;
    View.__super__.afterRender.apply(this, arguments);
    this.$el.attr('data-item-cid', (_ref = this.model) != null ? _ref.cid : void 0);
    this._addTooltips('info', this.$('button, a, .info-sign, .explanation-tooltip'));
    this._addTooltips('questionMark', this.$('.question-mark-sign'));
    return this.$('input[type="text"], input[type="email"]').attr('autocomplete', 'off');
  };

  View.prototype.dispose = function() {
    this.container = null;
    this.parentView = null;
    this.willBeDisposed = false;
    this._disableTraits();
    View.__super__.dispose.apply(this, arguments);
    return ga('send', 'event', {
      eventCategory: 'View',
      eventAction: 'disposed',
      eventLabel: _.functionName(this.constructor)
    });
  };

  return View;

})(Chaplin.View);
