var FootnotesService, mediator, outcomeCellTypes;

mediator = require('mediator');

outcomeCellTypes = require('models/outcome/outcome_cell_types');

module.exports = FootnotesService = (function() {
  function FootnotesService(options) {
    this.options = options;
    this.model = options.model;
  }

  FootnotesService.prototype._isOutcomeRowPresent = function() {
    return $('#page-container .outcomes .outcome-label-row').length > 0;
  };

  FootnotesService.prototype._getOutcomePropertyObjs = function(outcome) {
    var propertyObjs;
    propertyObjs = this._getOutcomeCellPropertyObjs(outcome);
    if (this._isOutcomeRowPresent()) {
      propertyObjs.unshift({
        property: 'oneRowOutcomeLabel'
      });
    }
    return propertyObjs;
  };

  FootnotesService.prototype._getOutcomeCellPropertyObjs = function(outcome) {
    var $cell, $cellContent, cell, cellContentClasses, cellPropertyObjs, outcomeCells, propObj;
    outcomeCells = $('#page-container .outcomes').find("tr.outcome-row[data-item-cid=" + outcome.cid + "] > td");
    outcomeCells = _(outcomeCells).sortBy(function(cell) {
      return Number(cell.dataset.colNo);
    });
    return cellPropertyObjs = (function() {
      var _i, _len, _results;
      _results = [];
      for (_i = 0, _len = outcomeCells.length; _i < _len; _i++) {
        cell = outcomeCells[_i];
        $cell = $(cell);
        $cellContent = $cell.find('.cell-content');
        cellContentClasses = $cellContent.length ? _($cellContent.attr('class').split(/\s+/)).without('cell-content') : [];
        _results.push(propObj = {
          property: $cell.data('property'),
          cellContentClass: cellContentClasses[0]
        });
      }
      return _results;
    })();
  };

  FootnotesService.prototype.automaticallySortFootnotes = function(outcomes) {
    var attr, footnotesList, outcome, outcomeFootnotes, propertyObjs, reordered, tableFootnoteIds, _i, _j, _len, _len1, _ref, _ref1;
    if (outcomes == null) {
      outcomes = null;
    }
    tableFootnoteIds = this._getQuestionFootnotesIds();
    outcomes = outcomes != null ? outcomes : this.model.get('outcomes').models;
    for (_i = 0, _len = outcomes.length; _i < _len; _i++) {
      outcome = outcomes[_i];
      propertyObjs = this._getOutcomePropertyObjs(outcome);
      outcomeFootnotes = outcome.get('footnotes');
      _ref = this._mapCellPropertiesToOutcomeAttributes(propertyObjs);
      for (_j = 0, _len1 = _ref.length; _j < _len1; _j++) {
        attr = _ref[_j];
        tableFootnoteIds = tableFootnoteIds.concat((_ref1 = outcomeFootnotes[attr]) != null ? _ref1 : []);
      }
    }
    footnotesList = this.model.get('footnotesList');
    reordered = footnotesList.sortFootnotes(tableFootnoteIds);
    if (reordered) {
      return this._saveFootnotesOnReorder();
    }
  };

  FootnotesService.prototype._getFootnoteAttributesForProperty = function(property, contentDefaultClass) {
    var attrs, currentViewName, propAttrs, propObj, viewName, _ref;
    propAttrs = [];
    propObj = contentDefaultClass ? _(outcomeCellTypes[property].otherFormatters).findWhere({
      contentDefaultClass: contentDefaultClass
    }) : outcomeCellTypes[property];
    if (!propObj) {
      return [];
    }
    currentViewName = mediator.services.esView.getPreferredType(mediator.project, this.model.get('type')).name;
    if (propObj.footnotesAttrs) {
      _ref = propObj.footnotesAttrs;
      for (viewName in _ref) {
        attrs = _ref[viewName];
        if (viewName === currentViewName || viewName === 'common') {
          propAttrs = propAttrs.concat(attrs);
        }
      }
    } else if (propObj.formatterAttrsObserve) {
      propAttrs = propAttrs.concat(propObj.formatterAttrsObserve);
    } else {
      propAttrs.push(property);
    }
    return propAttrs;
  };

  FootnotesService.prototype._mapCellPropertiesToOutcomeAttributes = function(cellPropertyObjs) {
    var cellPropertyObj, contentClass, outcomeAttrs, prop, _i, _len, _ref;
    outcomeAttrs = [];
    for (_i = 0, _len = cellPropertyObjs.length; _i < _len; _i++) {
      cellPropertyObj = cellPropertyObjs[_i];
      _ref = [cellPropertyObj.property, cellPropertyObj.cellContentClass], prop = _ref[0], contentClass = _ref[1];
      outcomeAttrs = outcomeAttrs.concat(this._getFootnoteAttributesForProperty(prop, contentClass));
    }
    return outcomeAttrs;
  };

  FootnotesService.prototype._saveFootnotesOnReorder = function() {
    return this.model.save().then(function() {
      return mediator.publish('footnotesReordered');
    }).otherwise(mediator.dialogs.fatalError);
  };

  FootnotesService.prototype._getQuestionFootnotesIds = function() {
    var questionFootnotesAttributesOrder, questionFootnotesAttrs;
    questionFootnotesAttributesOrder = ['indexTest', 'comparatorTest', 'targetCondition', 'intervention', 'comparison', 'healthProblemOrPopulation', 'settings', 'indexTestCutOff', 'comparatorTestCutOff', 'referenceTest', 'referenceTestShortName', 'referenceTestThreshold', 'tableTitle', 'bibliography', 'authors'];
    questionFootnotesAttrs = this.options.questionFootnotesAttr ? _(questionFootnotesAttributesOrder).intersection(this.options.questionFootnotesAttr) : questionFootnotesAttributesOrder;
    return _(questionFootnotesAttrs).chain().map((function(_this) {
      return function(attr) {
        return _this.model.get('footnotes')[attr];
      };
    })(this)).flatten().uniq().value();
  };

  return FootnotesService;

})();
