_.namespace(module, function(require) {
  var Exceptions, Request, W, adapter, mediator;
  Request = require('models/request');
  Exceptions = require('lib/exceptions');
  mediator = require('mediator');
  adapter = require('lib/pouch_adapter');
  W = require('when');
  return {
    _createRequestStub: function(formatOptions) {
      var request;
      request = new Request({
        type: 'pdf_export',
        status: 'stub',
        format: formatOptions
      });
      mediator.user.getRequests().add(request);
      return request.save();
    },
    _createRequest: function(htmlSource, formatOptions) {
      return this._createRequestStub(formatOptions).then(function(request) {
        return adapter.attach(request, 'htmlSource', new Blob([htmlSource], {
          type: 'text/html;charset=utf-8'
        }));
      });
    },
    _sendRequest: function(request) {
      return request.save({
        status: 'new'
      });
    },
    _watchForStatusChange: function(request) {
      return W.promise(function(resolve, reject) {
        return request.on('change:status', function() {
          switch (request.get('status')) {
            case 'complete':
              return adapter.getAttachment(request, 'pdf').then(function(pdf) {
                return resolve([
                  pdf, request.destroy.bind(request, {
                    wait: true
                  })
                ]);
              });
            case 'error':
              return reject(new Exceptions.server_reported(request.get('error_message')));
          }
        });
      });
    },
    "export": function(htmlSource, formatOptions) {
      return this._createRequest(htmlSource, formatOptions).then((function(_this) {
        return function(request) {
          var promise;
          promise = _this._watchForStatusChange(request);
          return _this._sendRequest(request).then(function() {
            return promise;
          });
        };
      })(this));
    }
  };
});
