var MembersActions, alt, mediator, mocHelpers;

alt = require('alt');

mediator = require('mediator');

mocHelpers = require('actions/utils/moc_helpers');

MembersActions = (function() {
  function MembersActions() {
    this.generateActions('add', 'saveFailure', 'deleteSuccess', 'deleteFailure', 'editSuccess', 'validationSuccess', 'validationFailure');
  }

  MembersActions.prototype.selectMembersForVoting = function(ids) {
    return this.dispatch({
      ids: ids
    });
  };

  MembersActions.prototype.toggleMemberForVoting = function(id) {
    return this.dispatch({
      id: id
    });
  };

  MembersActions.prototype.updateMember = function(memberId, attr, value) {
    return this.dispatch({
      memberId: memberId,
      attr: attr,
      value: value
    });
  };

  MembersActions.prototype.edit = function(memberKey, currentlyEditedMember) {
    if (currentlyEditedMember) {
      return this.actions.save(currentlyEditedMember, memberKey);
    } else {
      return this.actions.editSuccess(memberKey);
    }
  };

  MembersActions.prototype.save = function(member, memberKeyToEdit) {
    var memberModel, validationErrors;
    validationErrors = mocHelpers.validate(member.toJS(), mediator.colls.teamMembers.model);
    if (validationErrors) {
      this.actions.validationFailure({
        key: member.get('_id'),
        validationErrors: validationErrors
      });
    } else {
      memberModel = mocHelpers.getOrAddModel(mediator.colls.teamMembers, member.toJS());
      this.actions.validationSuccess({
        key: member.get('_id')
      });
      mocHelpers.save(memberModel).then((function(_this) {
        return function(memberModel) {
          return _this.actions.saveSuccess({
            memberJS: memberModel.toJSON(),
            memberKeyToEdit: memberKeyToEdit
          });
        };
      })(this))["catch"]((function(_this) {
        return function(e) {
          return _this.actions.saveFailure(e);
        };
      })(this));
    }
    return this.dispatch({
      member: member
    });
  };

  MembersActions.prototype.saveSuccess = function(data) {
    this.dispatch(data.memberJS);
    return this.actions.editSuccess(data.memberKeyToEdit);
  };

  MembersActions.prototype["delete"] = function(memberId) {
    mocHelpers["delete"](mediator.colls.teamMembers, memberId).then((function(_this) {
      return function(memberId) {
        return _this.actions.deleteSuccess(memberId);
      };
    })(this))["catch"]((function(_this) {
      return function(e) {
        return _this.actions.deleteFailure(e);
      };
    })(this));
    return this.dispatch({
      memberId: memberId
    });
  };

  MembersActions.prototype.fetch = function() {
    return this.dispatch(mediator.colls.teamMembers);
  };

  return MembersActions;

})();

module.exports = alt.createActions(MembersActions);
