var AdaptiveLayout, HandbookView, ModalView, W, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModalView = require('base/views/modal_view');

AdaptiveLayout = require('base/lib/traits/adaptive_layout');

template = require('views/templates/handbook');

W = require('when');

mediator = require('mediator');

module.exports = HandbookView = (function(_super) {
  __extends(HandbookView, _super);

  function HandbookView() {
    return HandbookView.__super__.constructor.apply(this, arguments);
  }

  HandbookView.prototype.className = 'webpage-view';

  HandbookView.prototype.template = template;

  HandbookView.prototype.shortcuts = {
    'esc': 'close'
  };

  HandbookView.prototype.initialize = function() {
    this.options.position = 'top';
    this.options.offsetY = 50;
    this.options.width = 1180;
    this.options.fadeInMs = 0;
    this.options.fadeOutMs = 0;
    HandbookView.__super__.initialize.apply(this, arguments);
    this.delegate('click', '.close', this.close);
    return this.enable(AdaptiveLayout);
  };

  HandbookView.prototype.afterRender = function() {
    var $iframe;
    HandbookView.__super__.afterRender.apply(this, arguments);
    this.trigger('!fixLayout');
    $iframe = this.$('iframe');
    return $iframe.on('load', function() {
      var contents, error, _ref;
      try {
        contents = $iframe.contents();
        return contents.scrollTop((_ref = mediator.user.get('handbookScrollPosition')) != null ? _ref : 0);
      } catch (_error) {
        error = _error;
        return typeof Raven !== "undefined" && Raven !== null ? Raven.captureException(error) : void 0;
      }
    });
  };

  HandbookView.prototype.close = function() {
    var contents, error;
    try {
      contents = this.$('iframe').contents();
      return mediator.user.save('handbookScrollPosition', contents.scrollTop());
    } catch (_error) {
      error = _error;
      return typeof Raven !== "undefined" && Raven !== null ? Raven.captureException(error) : void 0;
    } finally {
      this.dispose();
    }
  };

  HandbookView.prototype.fixLayout = function() {
    return this.$('#webpage-content').height($('#page-container').height() - this.options.offsetY);
  };

  return HandbookView;

})(ModalView);
