var Chaplin, Focusable, ViewTrait, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Chaplin = require('chaplin');

ViewTrait = require('./view_trait');

mediator = require('mediator');

module.exports = Focusable = (function(_super) {
  __extends(Focusable, _super);

  function Focusable() {
    return Focusable.__super__.constructor.apply(this, arguments);
  }

  Focusable.prototype.apply = function(view) {
    var applyFocusPath;
    Focusable.__super__.apply.apply(this, arguments);
    applyFocusPath = function(subviewFunction, trait, name, view) {
      if (view) {
        view._focusPath = "" + (this._focusPath || this.cid) + "." + view.cid;
        trait._addFunction(view, 'subview', applyFocusPath);
      }
      return subviewFunction.call(this, name, view);
    };
    view._focusPath = view.cid;
    this._addFunction(view, 'subview', applyFocusPath);
    this._addFunction(view, 'gainFocus');
    this._addFunction(view, 'beforeFocusLost');
    this._addFunction(view, '_focusGained');
    this._addFunction(view, 'onFocusLost');
    this._addFunction(view, 'onFocusGained');
    this._addFunction(view, 'changingFocusToSubview');
    this._addFunction(view, 'dispose');
    this._addFunction(view, 'isPopupView');
    if (view._stopInputsPropagation == null) {
      view._stopInputsPropagation = true;
    }
    if (view._stopInputsPropagation) {
      return view.delegate('click', 'input, textarea, button', function(e) {
        return e.stopPropagation();
      });
    }
  };

  Focusable.prototype.gainFocus = function(originalFunction, trait, e) {
    if (originalFunction) {
      originalFunction.call(this, e);
    }
    e.stopPropagation();
    return mediator.setFocus(this);
  };

  Focusable.prototype.beforeFocusLost = function(originalFunction, trait, deferred, focusTo) {
    if (originalFunction) {
      return originalFunction.call(this, deferred, focusTo);
    } else {
      return deferred.resolve(true);
    }
  };

  Focusable.prototype._focusGained = function(originalFunction, trait) {
    if (originalFunction) {
      originalFunction.call(this);
    }
    mediator.currentFocus = this;
    return this.onFocusGained();
  };

  Focusable.prototype.onFocusLost = function(originalFunction, trait, focusTo) {
    this._onFocusLostTriggered = true;
    this._changingFocusTo = focusTo;
    if (originalFunction) {
      return originalFunction.call(this, focusTo);
    }
  };

  Focusable.prototype.onFocusGained = function(originalFunction, trait) {
    this._onFocusLostTriggered = false;
    this._changingFocusTo = null;
    if (originalFunction) {
      return originalFunction.call(this);
    }
  };

  Focusable.prototype.changingFocusToSubview = function(originalFunction, trait, focusTo) {
    return focusTo.isPopupView() || _.string.startsWith(focusTo._focusPath, mediator.currentFocus._focusPath);
  };

  Focusable.prototype.dispose = function(originalFunction) {
    var _ref;
    if (!this._changingFocusTo && ((_ref = mediator.currentFocus) != null ? _ref.cid : void 0) === this.cid) {
      mediator.setFocus(mediator.heirView);
    }
    this._changingFocusTo = null;
    if (originalFunction) {
      return originalFunction.apply(this);
    }
  };

  Focusable.prototype.isPopupView = function(originalFunction) {
    if (originalFunction) {
      return originalFunction.apply(this);
    }
    return false;
  };

  return Focusable;

})(ViewTrait);
