var BaseException, ServerException, ValidationException, exception,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

BaseException = require('base/lib/exception');

exception = function(name, params) {
  var Exception;
  if (params == null) {
    params = {};
  }
  return Exception = (function(_super) {
    __extends(Exception, _super);

    function Exception() {
      Exception.__super__.constructor.call(this, name, $.t("errors:" + name, params));
    }

    return Exception;

  })(BaseException);
};

ValidationException = (function(_super) {
  __extends(ValidationException, _super);

  function ValidationException(msg) {
    ValidationException.__super__.constructor.call(this, 'validation', $.t('errors:general.data_validation', {
      msg: msg
    }));
  }

  return ValidationException;

})(BaseException);

ServerException = (function(_super) {
  __extends(ServerException, _super);

  function ServerException(msg) {
    ServerException.__super__.constructor.call(this, 'server', $.t('errors:general.server_reported_error', {
      msg: msg
    }));
  }

  return ServerException;

})(BaseException);

module.exports = {
  localized: function(name) {
    return new (exception(name));
  },
  authentication: {
    server: exception('authentication.server'),
    local_login: exception('authentication.local_login'),
    empty_login: exception('authentication.empty_login'),
    empty_email: exception('authentication.empty_email'),
    empty_password: exception('authentication.empty_password'),
    empty_repeat_password: exception('authentication.empty_repeat_password'),
    local_no_udb: exception('authentication.local_no_udb'),
    invalid_email: exception('authentication.invalid_email'),
    email_in_use: exception('authentication.email_in_use'),
    invalid_login: exception('authentication.invalid_login'),
    invalid_password: exception('authentication.invalid_password'),
    tos_not_accepted: exception('authentication.tos_not_accepted'),
    login_in_use: exception('authentication.login_in_use'),
    cannot_load_local_data: exception('authentication.cannot_load_local_data'),
    user_not_found: exception('authentication.user_not_found'),
    user_inactive: exception('authentication.user_inactive'),
    no_login_for_email: exception('authentication.no_login_for_email'),
    no_login_for_email_local: exception('authentication.no_login_for_email_local'),
    unable_to_get_login_for_email: exception('authentication.unable_to_get_login_for_email'),
    oauth_flow_failed: exception('authentication.oauth_flow_failed')
  },
  no_connection: exception('general.no_connection'),
  server_error: exception('general.server_error'),
  request_not_found: exception('general.request_not_found'),
  migration_failed: function(name) {
    return new (exception("migration." + (_.str.underscored(name))));
  },
  validation: ValidationException,
  server_reported: ServerException,
  project_document_not_valid: exception('general.project_document_not_valid'),
  data_changed: exception('general.data_changed'),
  document_missing: exception('general.document_missing'),
  "import": {
    unrecognized_grd: exception('import.unrecognized_grd'),
    no_projects_in_file: exception('import.no_projects_in_file'),
    malformed_grd_file: exception('import.malformed_grd_file'),
    unsupported_review_type: function(reviewType) {
      return exception('import.unsupported_review_type', {
        reviewType: reviewType
      });
    }
  }
};
