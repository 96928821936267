var FileSaver;

FileSaver = require('lib/file_saver');

module.exports = {
  _getTableTemplate: function(worksheetName, tableBody) {
    return "<html\n  xmlns:o=\"urn:schemas-microsoft-com:office:office\"\n  xmlns:x=\"urn:schemas-microsoft-com:office:excel\"\n  xmlns=\"http://www.w3.org/TR/REC-html40\">\n  <head>\n    <!--[if gte mso 9]>\n      <xml>\n        <x:ExcelWorkbook><x:ExcelWorksheets>\n        <x:ExcelWorksheet>\n          <x:Name>__worksheetName__</x:Name>\n          <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions>\n        </x:ExcelWorksheet></x:ExcelWorksheets>\n        </x:ExcelWorkbook>\n      </xml>\n    <![endif]-->\n  </head>\n  <body>\n  <table border=\"1\">\n    __tableBody__\n  </table>\n  </body></html>".replace('__worksheetName__', worksheetName).replace('__tableBody__', tableBody);
  },
  downloadExcel: function(votingResults) {
    var blob, criteriaRow, questionsRow, tableBody, xlsDoc;
    tableBody = $('<tbody></tbody>');
    questionsRow = $('<tr></tr>');
    questionsRow.append($('<td></td>'));
    tableBody.append(questionsRow);
    criteriaRow = $('<tr></tr>');
    criteriaRow.append($('<td></td>'));
    tableBody.append(criteriaRow);
    votingResults.map(function(votingResult, memberIdx) {
      var memberVotingResults, memberVotingRow;
      memberVotingRow = $('<tr></tr>');
      memberVotingRow.append($("<td>member " + (memberIdx + 1) + "</td>"));
      memberVotingResults = votingResult.map(function(questionCriteria) {
        return questionCriteria.map(function(criteria, question) {
          if (memberIdx === 0) {
            questionsRow.append($("<td colspan=" + criteria.size + ">" + question + "</td>"));
          }
          return criteria.map(function(criterion, criterionIdx) {
            return criterion.map(function(results, description) {
              if (memberIdx === 0) {
                criteriaRow.append($("<td>" + description + "</td>"));
              }
              return memberVotingRow.append($("<td>" + (results.get('panelMemberSelectedOption')) + "</td>"));
            });
          });
        });
      });
      return tableBody.append(memberVotingRow);
    });
    xlsDoc = this._getTableTemplate('Voting results', tableBody.prop('outerHTML'));
    blob = new Blob([xlsDoc], {
      type: 'application/vnd.ms-excel;charset=utf-8'
    });
    return FileSaver.saveFile(blob, 'voting.xls');
  }
};
