var DataFromService, DbepPublicationNotificationView, MobilePreviewView, MobilePublicationContactForm, PublishView, TopAcceptanceNotificationView, View, W, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

DataFromService = require('base/lib/traits/data_from_service');

MobilePublicationContactForm = require('views/dissemination/mobile_publication_contact_form_view');

TopAcceptanceNotificationView = require('views/dissemination/top_acceptance_notification_view');

DbepPublicationNotificationView = require('views/dissemination/dbep_publication_notification_view');

MobilePreviewView = require('views/dissemination/mobile_preview_view');

W = require('when');

template = require('views/templates/dissemination/publish');

mediator = require('mediator');

module.exports = PublishView = (function(_super) {
  __extends(PublishView, _super);

  function PublishView() {
    return PublishView.__super__.constructor.apply(this, arguments);
  }

  PublishView.prototype.className = 'document-publish';

  PublishView.prototype.container = '#page-container';

  PublishView.prototype.template = template;

  PublishView.prototype.initialize = function() {
    PublishView.__super__.initialize.apply(this, arguments);
    this.on('loadingCompleted', function(statuses) {
      this.mobileStatus = statuses[0];
      this.dbepStatus = statuses[1];
      return this.render();
    });
    this.enable(DataFromService, {
      dataContainer: '.publication-channels',
      serviceMethods: [_.partial(this.options.service.checkStatus, this.model, 'mobile'), _.partial(this.options.service.checkStatus, this.model, 'evidenceDb')]
    });
    this.delegate('click', 'button.mobile', (function(_this) {
      return function() {
        var form;
        _this.subview('contactForm', form = new MobilePublicationContactForm());
        return form.promise().then(function(contactInfo) {
          return _this.options.service.publish(_this.model, 'mobile', contactInfo);
        }).then(function() {
          _this.trigger('!reloadData');
          return _this._mobileInProgress();
        }, function(reason) {
          if (reason !== 'cancelled') {
            return mediator.dialogs.error(reason);
          }
        });
      };
    })(this));
    this.delegate('click', 'button.mobile-preview', function() {
      return this.subview('previewForm', new MobilePreviewView());
    });
    this.delegate('click', 'button.dbep-preview', this.dbepPreview);
    return this.delegate('click', 'button.dbep', this.dbepPublish);
  };

  PublishView.prototype._mobileInProgress = function() {
    return mediator.dialogs.warning($.t('dissemination:dialogs.mobile_in_progress'));
  };

  PublishView.prototype.getTemplateData = function() {
    return {
      mobileStatus: _.str.dasherize(this.mobileStatus),
      mobileStatusText: 'status.mobile_' + _.str.underscored(this.mobileStatus),
      mobileStatusDetails: '/dissemination:dialogs.mobile_' + _.str.underscored(this.mobileStatus),
      dbepStatus: _.str.dasherize(this.dbepStatus),
      dbepStatusText: 'status.dbep_' + _.str.underscored(this.dbepStatus),
      dbepStatusDetails: '/dissemination:dialogs.dbep_' + _.str.underscored(this.dbepStatus)
    };
  };

  PublishView.prototype.dbepPreview = function() {
    return console.log('dbep preview event');
  };

  PublishView.prototype.dbepPublish = function() {
    var topAccepted;
    if (this.dbepStatus !== 'notPublished') {
      return;
    }
    topAccepted = mediator.user.get('topAccepted');
    if (topAccepted) {
      return this._showPublicationNotification();
    } else {
      return this._showTopAcceptanceNotification();
    }
  };

  PublishView.prototype._showTopAcceptanceNotification = function() {
    var view;
    this.subview('topAcceptance', view = new TopAcceptanceNotificationView());
    return view.promise().then((function(_this) {
      return function() {
        return _this._showPublicationNotification();
      };
    })(this));
  };

  PublishView.prototype._showPublicationNotification = function() {
    var notification;
    this.subview('publicationNotiication', notification = new DbepPublicationNotificationView());
    return notification.promise().then((function(_this) {
      return function() {
        return _this._initDbepPublication();
      };
    })(this));
  };

  PublishView.prototype._initDbepPublication = function() {
    var userDetails;
    userDetails = {
      email: mediator.user.get('email')
    };
    return this.options.service.publish(this.model, 'evidenceDb', userDetails).then((function(_this) {
      return function() {
        return _this.trigger('!reloadData');
      };
    })(this));
  };

  return PublishView;

})(View);
