var DiagnosticQuestionExporter, QuestionExporter, formatter, isDefined, statisticalUtils, utils, _dataSources, _studiesTypes,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

statisticalUtils = require('lib/statistical_utils');

QuestionExporter = require('./question_exporter');

formatter = require('lib/formatters').outcome;

utils = require('./exporter_utils');

_studiesTypes = {
  'cross_sectional_cohort_type': 'CrossSectionalCohortAccuracyStudy',
  'case_control_type_accuracy': 'CaseControlAccuracyStudy',
  'cohort_and_case_control_type': 'CohortAndCaseControlStudy'
};

_dataSources = {
  'rangeFromStudies': 'RangeFromStudies',
  'pooledAcrossStudies': 'Pooled',
  'fromSingleStudy': 'SingleStudy'
};

isDefined = function(val) {
  return Boolean(val);
};

module.exports = DiagnosticQuestionExporter = (function(_super) {
  __extends(DiagnosticQuestionExporter, _super);

  function DiagnosticQuestionExporter(_originalQuestion) {
    this.exportEvidenceSummary = __bind(this.exportEvidenceSummary, this);
    this._exportTestParameters = __bind(this._exportTestParameters, this);
    this.exportTests = __bind(this.exportTests, this);
    this._processTest = __bind(this._processTest, this);
    this.exportPrevalence = __bind(this.exportPrevalence, this);
    this._processPrevalence = __bind(this._processPrevalence, this);
    DiagnosticQuestionExporter.__super__.constructor.call(this, _originalQuestion);
    switch (this._originalQuestion['sourceOfDxData']) {
      case "rangeFromStudies":
        this._sensitivity = 0;
        this._specificity = 0;
        this._sensitivityFrom = this._originalQuestion['pooledSensitivityRangeFrom'];
        this._sensitivityTo = this._originalQuestion['pooledSensitivityRangeTo'];
        this._specificityFrom = this._originalQuestion['pooledSpecificityRangeFrom'];
        this._specificityTo = this._originalQuestion['pooledSpecificityRangeTo'];
        if (this._hasComparatorTest()) {
          this._sensitivityC = 0;
          this._specificityC = 0;
          this._sensitivityFromC = this._originalQuestion['pooledSensitivityComparisonRangeFrom'];
          this._sensitivityToC = this._originalQuestion['pooledSensitivityComparisonRangeTo'];
          this._specificityFromC = this._originalQuestion['pooledSpecificityComparisonRangeFrom'];
          this._specificityToC = this._originalQuestion['pooledSpecificityComparisonRangeTo'];
        }
        break;
      default:
        this._sensitivity = this._originalQuestion['pooledSensitivity'];
        this._specificity = this._originalQuestion['pooledSpecificity'];
        this._sensitivityFrom = this._originalQuestion['pooledSensitivityFrom'];
        this._sensitivityTo = this._originalQuestion['pooledSensitivityTo'];
        this._specificityFrom = this._originalQuestion['pooledSpecificityFrom'];
        this._specificityTo = this._originalQuestion['pooledSpecificityTo'];
        if (this._hasComparatorTest()) {
          this._sensitivityC = this._originalQuestion['pooledSensitivityComparison'];
          this._specificityC = this._originalQuestion['pooledSpecificityComparison'];
          this._sensitivityFromC = this._originalQuestion['pooledSensitivityComparisonFrom'];
          this._sensitivityToC = this._originalQuestion['pooledSensitivityComparisonTo'];
          this._specificityFromC = this._originalQuestion['pooledSpecificityComparisonFrom'];
          this._specificityToC = this._originalQuestion['pooledSpecificityComparisonTo'];
        }
    }
  }

  DiagnosticQuestionExporter.prototype._processPrevalence = function(key, index) {
    var characteristicsSuffix, prevalence;
    characteristicsSuffix = 'Characteristics';
    prevalence = {
      '@id': "_:p" + index,
      '@type': 'ConditionPrevalence',
      'value': this._originalQuestion[key],
      'name': this._originalQuestion["" + key + characteristicsSuffix]
    };
    if (prevalence.value) {
      return prevalence;
    }
  };

  DiagnosticQuestionExporter.prototype.exportPrevalence = function() {
    var exportedPrevalence, i, index, key, keys, p, _i, _j, _len, _len1;
    keys = ['prevalence1', 'prevalence2', 'prevalence3'];
    exportedPrevalence = _.compact((function() {
      var _i, _len, _results;
      _results = [];
      for (index = _i = 0, _len = keys.length; _i < _len; index = ++_i) {
        key = keys[index];
        _results.push(this._processPrevalence(key, index + 1));
      }
      return _results;
    }).call(this));
    this._prevalencesValues = {};
    for (i = _i = 0, _len = exportedPrevalence.length; _i < _len; i = ++_i) {
      p = exportedPrevalence[i];
      this._prevalencesValues[i] = p['value'];
    }
    this._prevalencesIds = {};
    for (i = _j = 0, _len1 = exportedPrevalence.length; _j < _len1; i = ++_j) {
      p = exportedPrevalence[i];
      this._prevalencesIds[i + 1] = p['@id'];
    }
    return exportedPrevalence;
  };

  DiagnosticQuestionExporter.prototype._hasComparatorTest = function() {
    var _ref;
    return (_ref = this._originalQuestion['comparatorTestPresent']) != null ? _ref : false;
  };

  DiagnosticQuestionExporter.prototype._processTest = function(key, check, index) {
    if (!check) {
      return null;
    }
    return {
      '@id': "_:t" + index,
      '@type': 'MedicalTest',
      'name': this._originalQuestion[key]
    };
  };

  DiagnosticQuestionExporter.prototype.exportTests = function() {
    var checks, exportedTests, i, key, keys;
    keys = ['indexTest', 'comparatorTest', 'referenceTest'];
    checks = [true, this._hasComparatorTest(), isDefined(this._originalQuestion['referenceTest'])];
    exportedTests = _.compact((function() {
      var _i, _len, _results;
      _results = [];
      for (i = _i = 0, _len = keys.length; _i < _len; i = ++_i) {
        key = keys[i];
        _results.push(this._processTest(key, checks[i], i + 1));
      }
      return _results;
    }).call(this));
    this._tests = {
      'indexTest': '_:t1'
    };
    if (this._hasComparatorTest()) {
      this._tests['comparatorTest'] = '_:t2';
    }
    return exportedTests;
  };

  DiagnosticQuestionExporter.prototype._isDataReady = function() {
    return (this._tests != null) && (this._explanations != null) && (this._outcomes != null) && (this._prevalencesValues != null);
  };

  DiagnosticQuestionExporter.prototype._findOutcomeByName = function(name, outcomes) {
    return _.findWhere(outcomes, {
      'name': name
    });
  };

  DiagnosticQuestionExporter.prototype._exportTestParameters = function() {
    var comparatorTestParameters, indexTestParameters, parameters, referenceTestParameters;
    parameters = [];
    if (utils.existsAndNotEmpty(this._originalQuestion['indexTestCutOff'])) {
      indexTestParameters = {
        '@type': 'CutOffValue',
        'forTest': '_:t1',
        'value': this._originalQuestion['indexTestCutOff']
      };
      parameters.push(indexTestParameters);
    }
    if (utils.existsAndNotEmpty(this._originalQuestion['comparatorTestCutOff'])) {
      comparatorTestParameters = {
        '@type': 'CutOffValue',
        'forTest': '_:t2',
        'value': this._originalQuestion['comparatorTestCutOff']
      };
      parameters.push(comparatorTestParameters);
    }
    if (utils.existsAndNotEmpty(this._originalQuestion['referenceTestThreshold'] && utils.existsAndNotEmpty(this._originalQuestion['referenceTest']))) {
      referenceTestParameters = {
        '@type': 'CutOffValue',
        'forTest': '_:t3',
        'value': this._originalQuestion['referenceTestThreshold']
      };
      parameters.push(referenceTestParameters);
    }
    if (parameters.length > 0) {
      return parameters;
    } else {
      return null;
    }
  };

  DiagnosticQuestionExporter.prototype._result = function(type, testId, testValue, prevalenceNum) {
    var fromValue, i, prevalenceId, result, toValue, value;
    value = testValue[0], fromValue = testValue[1], toValue = testValue[2];
    i = parseInt(prevalenceNum);
    prevalenceId = (i + 1).toString();
    result = {
      "@type": type,
      "forPrevalence": {
        "@id": this._prevalencesIds[prevalenceId]
      },
      "forTest": {
        "@id": testId
      }
    };
    if (this._originalQuestion['sourceOfDxData'] === 'rangeFromStudies') {
      result['value'] = "" + fromValue[i] + " to " + toValue[i];
      result['lowerBound'] = fromValue[i];
      result['upperBound'] = toValue[i];
    } else {
      result['value'] = value[i];
      result['confidenceLevel'] = statisticalUtils.confidenceLevel;
      result['confidenceIntervalFrom'] = fromValue[i];
      result['confidenceIntervalTo'] = toValue[i];
    }
    return result;
  };

  DiagnosticQuestionExporter.prototype._results = function(typeName, indexValue, comparatorValue) {
    var comparatorResults, comparatorTestId, i, p, prevalenceNums, r, results, testId, type, _i, _len;
    type = this._outcomesTypes[typeName];
    testId = this._tests['indexTest'];
    prevalenceNums = (function() {
      var _ref, _results;
      _ref = this._prevalencesValues;
      _results = [];
      for (i in _ref) {
        p = _ref[i];
        _results.push(i);
      }
      return _results;
    }).call(this);
    results = (function() {
      var _i, _len, _results;
      _results = [];
      for (_i = 0, _len = prevalenceNums.length; _i < _len; _i++) {
        i = prevalenceNums[_i];
        _results.push(this._result(type, testId, indexValue, i));
      }
      return _results;
    }).call(this);
    if (comparatorValue != null) {
      comparatorTestId = this._tests['comparatorTest'];
      comparatorResults = (function() {
        var _i, _len, _results;
        _results = [];
        for (_i = 0, _len = prevalenceNums.length; _i < _len; _i++) {
          i = prevalenceNums[_i];
          _results.push(this._result(type, comparatorTestId, comparatorValue, i));
        }
        return _results;
      }).call(this);
      for (_i = 0, _len = comparatorResults.length; _i < _len; _i++) {
        r = comparatorResults[_i];
        results.push(r);
      }
    }
    return results;
  };

  DiagnosticQuestionExporter.prototype._dataTP = function() {
    var cVal, diagTP, i, p, val;
    diagTP = function(sensitivity, prevalence) {
      return statisticalUtils.diagnosticEffectTP(sensitivity, prevalence);
    };
    val = [
      (function() {
        var _ref, _results;
        _ref = this._prevalencesValues;
        _results = [];
        for (i in _ref) {
          p = _ref[i];
          _results.push(diagTP(this._sensitivity, p));
        }
        return _results;
      }).call(this), (function() {
        var _ref, _results;
        _ref = this._prevalencesValues;
        _results = [];
        for (i in _ref) {
          p = _ref[i];
          _results.push(diagTP(this._sensitivityFrom, p));
        }
        return _results;
      }).call(this), (function() {
        var _ref, _results;
        _ref = this._prevalencesValues;
        _results = [];
        for (i in _ref) {
          p = _ref[i];
          _results.push(diagTP(this._sensitivityTo, p));
        }
        return _results;
      }).call(this)
    ];
    cVal = null;
    if (this._hasComparatorTest()) {
      cVal = [
        (function() {
          var _ref, _results;
          _ref = this._prevalencesValues;
          _results = [];
          for (i in _ref) {
            p = _ref[i];
            _results.push(diagTP(this._sensitivityC, p));
          }
          return _results;
        }).call(this), (function() {
          var _ref, _results;
          _ref = this._prevalencesValues;
          _results = [];
          for (i in _ref) {
            p = _ref[i];
            _results.push(diagTP(this._sensitivityFromC, p));
          }
          return _results;
        }).call(this), (function() {
          var _ref, _results;
          _ref = this._prevalencesValues;
          _results = [];
          for (i in _ref) {
            p = _ref[i];
            _results.push(diagTP(this._sensitivityToC, p));
          }
          return _results;
        }).call(this)
      ];
    }
    return this._results('TP', val, cVal);
  };

  DiagnosticQuestionExporter.prototype._dataFN = function() {
    var cVal, diagFN, i, p, val;
    diagFN = function(sensitivity, prevalence) {
      return statisticalUtils.diagnosticEffectFN(sensitivity, prevalence);
    };
    val = [
      (function() {
        var _ref, _results;
        _ref = this._prevalencesValues;
        _results = [];
        for (i in _ref) {
          p = _ref[i];
          _results.push(diagFN(this._sensitivity, p));
        }
        return _results;
      }).call(this), (function() {
        var _ref, _results;
        _ref = this._prevalencesValues;
        _results = [];
        for (i in _ref) {
          p = _ref[i];
          _results.push(diagFN(this._sensitivityFrom, p));
        }
        return _results;
      }).call(this), (function() {
        var _ref, _results;
        _ref = this._prevalencesValues;
        _results = [];
        for (i in _ref) {
          p = _ref[i];
          _results.push(diagFN(this._sensitivityTo, p));
        }
        return _results;
      }).call(this)
    ];
    cVal = null;
    if (this._hasComparatorTest()) {
      cVal = [
        (function() {
          var _ref, _results;
          _ref = this._prevalencesValues;
          _results = [];
          for (i in _ref) {
            p = _ref[i];
            _results.push(diagFN(this._sensitivityC, p));
          }
          return _results;
        }).call(this), (function() {
          var _ref, _results;
          _ref = this._prevalencesValues;
          _results = [];
          for (i in _ref) {
            p = _ref[i];
            _results.push(diagFN(this._sensitivityFromC, p));
          }
          return _results;
        }).call(this), (function() {
          var _ref, _results;
          _ref = this._prevalencesValues;
          _results = [];
          for (i in _ref) {
            p = _ref[i];
            _results.push(diagFN(this._sensitivityToC, p));
          }
          return _results;
        }).call(this)
      ];
    }
    return this._results('FN', val, cVal);
  };

  DiagnosticQuestionExporter.prototype._dataTN = function() {
    var cVal, diagTN, i, p, val;
    diagTN = function(specificity, prevalence) {
      return statisticalUtils.diagnosticEffectTN(specificity, prevalence);
    };
    val = [
      (function() {
        var _ref, _results;
        _ref = this._prevalencesValues;
        _results = [];
        for (i in _ref) {
          p = _ref[i];
          _results.push(diagTN(this._specificity, p));
        }
        return _results;
      }).call(this), (function() {
        var _ref, _results;
        _ref = this._prevalencesValues;
        _results = [];
        for (i in _ref) {
          p = _ref[i];
          _results.push(diagTN(this._specificityFrom, p));
        }
        return _results;
      }).call(this), (function() {
        var _ref, _results;
        _ref = this._prevalencesValues;
        _results = [];
        for (i in _ref) {
          p = _ref[i];
          _results.push(diagTN(this._specificityTo, p));
        }
        return _results;
      }).call(this)
    ];
    cVal = null;
    if (this._hasComparatorTest()) {
      cVal = [
        (function() {
          var _ref, _results;
          _ref = this._prevalencesValues;
          _results = [];
          for (i in _ref) {
            p = _ref[i];
            _results.push(diagTN(this._specificityC, p));
          }
          return _results;
        }).call(this), (function() {
          var _ref, _results;
          _ref = this._prevalencesValues;
          _results = [];
          for (i in _ref) {
            p = _ref[i];
            _results.push(diagTN(this._specificityFromC, p));
          }
          return _results;
        }).call(this), (function() {
          var _ref, _results;
          _ref = this._prevalencesValues;
          _results = [];
          for (i in _ref) {
            p = _ref[i];
            _results.push(diagTN(this._specificityToC, p));
          }
          return _results;
        }).call(this)
      ];
    }
    return this._results('TN', val, cVal);
  };

  DiagnosticQuestionExporter.prototype._dataFP = function() {
    var cVal, diagFP, i, p, val;
    diagFP = function(specificity, prevalence) {
      return statisticalUtils.diagnosticEffectFP(specificity, prevalence);
    };
    val = [
      (function() {
        var _ref, _results;
        _ref = this._prevalencesValues;
        _results = [];
        for (i in _ref) {
          p = _ref[i];
          _results.push(diagFP(this._specificity, p));
        }
        return _results;
      }).call(this), (function() {
        var _ref, _results;
        _ref = this._prevalencesValues;
        _results = [];
        for (i in _ref) {
          p = _ref[i];
          _results.push(diagFP(this._specificityFrom, p));
        }
        return _results;
      }).call(this), (function() {
        var _ref, _results;
        _ref = this._prevalencesValues;
        _results = [];
        for (i in _ref) {
          p = _ref[i];
          _results.push(diagFP(this._specificityTo, p));
        }
        return _results;
      }).call(this)
    ];
    cVal = null;
    if (this._hasComparatorTest()) {
      cVal = [
        (function() {
          var _ref, _results;
          _ref = this._prevalencesValues;
          _results = [];
          for (i in _ref) {
            p = _ref[i];
            _results.push(diagFP(this._specificityC, p));
          }
          return _results;
        }).call(this), (function() {
          var _ref, _results;
          _ref = this._prevalencesValues;
          _results = [];
          for (i in _ref) {
            p = _ref[i];
            _results.push(diagFP(this._specificityFromC, p));
          }
          return _results;
        }).call(this), (function() {
          var _ref, _results;
          _ref = this._prevalencesValues;
          _results = [];
          for (i in _ref) {
            p = _ref[i];
            _results.push(diagFP(this._specificityToC, p));
          }
          return _results;
        }).call(this)
      ];
    }
    return this._results('FP', val, cVal);
  };

  DiagnosticQuestionExporter.prototype._processQuality = function(outcome) {
    var explanations, imprecision, imprecisionExplanations, imprecisionValue, inconsistency, inconsistencyExplanations, inconsistencyValue, indirectness, indirectnessExplanations, indirectnessValue, publicationBias, publicationBiasExplanations, publicationBiasValue, quality, qualityValue, riskOfBias, riskOfBiasExplanations, riskOfBiasValue;
    qualityValue = outcome['quality'];
    explanations = outcome['footnotes'];
    riskOfBias = outcome['riskOfBias']['label'];
    riskOfBiasValue = outcome['riskOfBias']['score'];
    riskOfBiasExplanations = this._findExplanations(explanations, 'riskOfBias', riskOfBiasValue);
    inconsistency = outcome['inconsistency']['label'];
    inconsistencyValue = outcome['inconsistency']['score'];
    inconsistencyExplanations = this._findExplanations(explanations, 'inconsistency', inconsistencyValue);
    indirectness = outcome['indirectness']['label'];
    indirectnessValue = outcome['indirectness']['score'];
    indirectnessExplanations = this._findExplanations(explanations, 'indirectness', indirectnessValue);
    imprecision = outcome['imprecision']['label'];
    imprecisionValue = outcome['imprecision']['score'];
    imprecisionExplanations = this._findExplanations(explanations, 'imprecision', imprecisionValue);
    publicationBias = this._getQualityOptionForLabel(outcome['publicationBias']['label'], 'publicationBias');
    publicationBiasValue = outcome['publicationBias']['score'];
    publicationBiasExplanations = this._findExplanations(explanations, 'publicationBias', publicationBiasValue);
    quality = {
      "@type": "GradeQuality",
      "value": qualityValue,
      "name": this._qualityNameForValue(qualityValue),
      "riskOfBias": {
        "@type": this._qualityLabelForScore(riskOfBiasValue),
        "name": this._getQualityOptionForLabel(riskOfBias)
      },
      "inconsistency": {
        "@type": this._qualityLabelForScore(inconsistencyValue),
        "name": this._getQualityOptionForLabel(inconsistency)
      },
      "indirectness": {
        "@type": this._qualityLabelForScore(indirectnessValue),
        "name": this._getQualityOptionForLabel(indirectness)
      },
      "imprecision": {
        "@type": this._qualityLabelForScore(imprecisionValue),
        "name": this._getQualityOptionForLabel(imprecision)
      },
      "otherConsiderations": {
        "name": formatter.otherConsiderations(outcome),
        "publicationBias": {
          "@type": this._qualityLabelForScore(publicationBiasValue),
          "name": publicationBias
        }
      }
    };
    if (riskOfBiasExplanations.length > 0) {
      quality['riskOfBias']['explanation'] = riskOfBiasExplanations;
    }
    if (inconsistencyExplanations.length > 0) {
      quality['inconsistency']['explanation'] = inconsistencyExplanations;
    }
    if (indirectnessExplanations.length > 0) {
      quality['indirectness']['explanation'] = indirectnessExplanations;
    }
    if (imprecisionExplanations.length > 0) {
      quality['imprecision']['explanation'] = imprecisionExplanations;
    }
    if (publicationBiasExplanations.length > 0) {
      quality['otherConsiderations']['publicationBias']['explanation'] = publicationBiasExplanations;
    }
    return quality;
  };

  DiagnosticQuestionExporter.prototype._evidenceSummaryForOutcome = function(outcome, outcomeResult) {
    var explanations, importanceExplanations, outcomeId, studyType, summary, testParameters;
    studyType = outcome['designStudies']['value'];
    outcomeId = this._outcomes[outcome['name']];
    explanations = outcome['footnotes'];
    importanceExplanations = this._findExplanations(explanations, "importance", 0);
    summary = {
      '@type': 'DiagnosticData',
      'forOutcome': {
        '@id': outcomeId
      },
      'numberOfStudies': outcome['noOfStudies'],
      'patientGroup': {
        '@type': 'ObservedGroup',
        'totalCount': outcome['noOfPatients']
      },
      'studyDesign': {
        '@type': _studiesTypes[studyType],
        'name': $.t("es:outcome." + studyType)
      },
      'effectSummary': {
        '@type': _dataSources[this._originalQuestion['sourceOfDxData']],
        'testResult': outcomeResult
      },
      'importance': {
        '@type': 'GradeImportance',
        'value': outcome['importance'],
        'name': outcome['importanceLabel']
      },
      'quality': this._processQuality(outcome)
    };
    testParameters = this._exportTestParameters();
    if (testParameters != null) {
      summary['testParameter'] = testParameters;
    }
    if ((importanceExplanations != null) && importanceExplanations.length > 0) {
      summary['importance']['explanation'] = importanceExplanations;
    }
    return summary;
  };

  DiagnosticQuestionExporter.prototype._copyQualityAndBasicProperties = function(from, to) {
    var propertiesToCopy, property, _i, _len;
    propertiesToCopy = ['quality', 'noOfOfStudies', 'noOfPatients', 'riskOfBias', 'inconsistency', 'indirectness', 'imprecision', 'publicationBias', 'designStudies'];
    for (_i = 0, _len = propertiesToCopy.length; _i < _len; _i++) {
      property = propertiesToCopy[_i];
      to[property] = from[property];
    }
    return to;
  };

  DiagnosticQuestionExporter.prototype._processInconclusive = function(outcome) {
    var summary;
    summary = {
      '@type': 'DiagnosticData',
      'forOutcome': this._outcomes[outcome['name']],
      'numberOfStudies': outcome['noOfStudies'],
      'patientGroup': {
        '@type': 'ObservedGroup',
        'totalCount': outcome['noOfPatients']
      },
      'effectSummary': {
        '@type': _dataSources[this._originalQuestion['sourceOfDxData']],
        'narrativeSummary': outcome['inconclusivePrevalencesDesc']
      },
      'importance': {
        '@type': 'GradeImportance',
        'value': outcome['importance'],
        'name': outcome['importanceLabel']
      }
    };
    return summary;
  };

  DiagnosticQuestionExporter.prototype._processComplications = function(outcome) {
    var summary;
    summary = {
      '@type': 'DiagnosticData',
      'forOutcome': this._outcomes[outcome['name']],
      'numberOfStudies': outcome['noOfStudies'],
      'patientGroup': {
        '@type': 'ObservedGroup',
        'totalCount': outcome['noOfPatients']
      },
      'effectSummary': {
        '@type': _dataSources[this._originalQuestion['sourceOfDxData']],
        'narrativeSummary': outcome['complicationsPrevalencesDesc']
      },
      'importance': {
        '@type': 'GradeImportance',
        'value': outcome['importance'],
        'name': outcome['importanceLabel']
      },
      'quality': this._processQuality(outcome)
    };
    return summary;
  };

  DiagnosticQuestionExporter.prototype._processSummary = function(outcomes) {
    var FN, FNEvidenceSummary, FNResults, FP, FPEvidenceSummary, FPResults, TN, TNEvidenceSummary, TNResults, TP, TPEvidenceSummary, TPResults, complications, complicationsSummary, inconclusive, inconclusiveSummary;
    TP = this._findOutcomeByName('TP', outcomes);
    FN = this._findOutcomeByName('FN', outcomes);
    TN = this._findOutcomeByName('TN', outcomes);
    FP = this._findOutcomeByName('FP', outcomes);
    inconclusive = this._findOutcomeByName('Inconclusive', outcomes);
    complications = this._findOutcomeByName('Complications', outcomes);
    FN = this._copyQualityAndBasicProperties(TP, FN);
    FP = this._copyQualityAndBasicProperties(TN, FP);
    TPResults = this._dataTP();
    FNResults = this._dataFN();
    TNResults = this._dataTN();
    FPResults = this._dataFP();
    TPEvidenceSummary = this._evidenceSummaryForOutcome(TP, TPResults);
    FNEvidenceSummary = this._evidenceSummaryForOutcome(FN, FNResults);
    TNEvidenceSummary = this._evidenceSummaryForOutcome(TN, TNResults);
    FPEvidenceSummary = this._evidenceSummaryForOutcome(FP, FPResults);
    inconclusiveSummary = this._processInconclusive(inconclusive);
    complicationsSummary = this._processComplications(complications);
    return [].concat.apply([], [TPEvidenceSummary, FNEvidenceSummary, TNEvidenceSummary, FPEvidenceSummary, inconclusiveSummary, complicationsSummary]);
  };

  DiagnosticQuestionExporter.prototype.exportEvidenceSummary = function() {
    var originalOutcomes;
    originalOutcomes = this._originalQuestion['outcomes'];
    if (!this._isDataReady()) {
      this.exportExplanations();
      this.exportOutcomes();
      this.exportPrevalence();
      this.exportTests();
    }
    return this._processSummary(originalOutcomes);
  };

  return DiagnosticQuestionExporter;

})(QuestionExporter);
