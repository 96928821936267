EtdActions = require 'actions/etd_actions'
EtdStore = require 'stores/etd_store'
PureRenderMixin = React.addons.PureRenderMixin
Translation = require 'components/mixins/translation'
ContentEditable = require 'components/common//content_editable'
ButtonsRow = require 'components/common/buttons_row'
Button = require 'components/common/button'
HorizontalRadios = require './horizontal_radios'
GeneralQuestions = require './general_questions'
OutcomesQuestions = require './outcomes_questions'
ResourcesQuestions = require './resources_questions'

DetailedJudgements = React.createClass
  displayName: 'DetailedJudgements'
  mixins: [PureRenderMixin, Translation('es:recommendations.table.detailed_judgements')]

  propTypes:
    sectionId: React.PropTypes.string.isRequired
    sectionName: React.PropTypes.string.isRequired
    criterionDescription: React.PropTypes.string.isRequired
    details: React.PropTypes.object.isRequired
    finalJudgementOptions: React.PropTypes.object.isRequired
    finalJudgementAdditionalOptions: React.PropTypes.object.isRequired
    finalJudgementSelectedOption: React.PropTypes.string.isRequired
    onApply: React.PropTypes.func.isRequired
    onCancel: React.PropTypes.func.isRequired

  updatePanelDiscussion: (e) ->
    @_debouncedUpdatePanelDiscussion ?= _.debounce(EtdActions.updatePanelDiscussion, 200)
    @_debouncedUpdatePanelDiscussion @props.sectionId, e.currentTarget.innerHTML

  getCriterionDescription: (criterionAnswer) ->
    EtdStore.getCriterionDescription criterionAnswer.get 'criterionId'

  getSelectedOptionText: (criterionAnswer) ->
    EtdStore.getSelectedOptionText criterionAnswer.get 'criterionId'

  render: ->
    <div>
      <div>
        <span className="section-name">{@props.sectionName}</span>:
        <span className="criterion-description">{@props.criterionDescription}</span>
      </div>
      {if @props.details.get 'additionalInfos'
        <div className="additional-infos">
          {@props.details.get('additionalInfos').map (additionalInfo, idx) =>
            <div key={idx} className="additional-info">
              <div>{additionalInfo.get 'description'}</div>
              {
                if additionalInfo.get 'criteriaAnswers'
                  additionalInfo.get('criteriaAnswers').map (criterionAnswer, idx) =>
                    <div key={idx}>
                      <span>{@getCriterionDescription criterionAnswer}</span>:
                      <span>{@getSelectedOptionText criterionAnswer}</span>
                    </div>
                else if additionalInfo.get 'additions'
                  additionalInfo.get('additions').map (addition, idx) ->
                    <div key={idx}>{addition}</div>
              }
            </div>
          }
        </div>
      }
      <div className="panel-discussion">
        {@i18n 'panel_discussion'}
        <ContentEditable
          className="discussion-content"
          onInput={@updatePanelDiscussion}
          html={@props.details.get 'panelDiscussion'} />
      </div>
      {if @props.details.get 'questions'
        <div className="details">
          <span className="inner-label">{@i18n 'detailed_questions'}</span>
          {switch "#{@props.details.get('questionsType')}-#{@props.questionType}"
            when 'general-tx', 'general-dx'
              <GeneralQuestions
                sectionId={@props.sectionId}
                groupPrefix='g1'
                questions={@props.details.get 'questions'}/>
            when 'outcomes-tx'
              <OutcomesQuestions
                sectionId={@props.sectionId}
                groupPrefix='o1'
                questions={@props.details.get 'questions'}
                criticalOutcomes={@props.criticalOutcomes}
              />
            # treat 'outcomes-dx' as resources as they can be added, removed and edited
            when 'resources-tx', 'resources-dx', 'outcomes-dx'
              <ResourcesQuestions
                sectionId={@props.sectionId}
                groupPrefix='r1'
                questionType={@props.questionType}
                anticipatedOutcomes={@props.details.get('questionsType') is 'outcomes'}
                questions={@props.details.get 'questions'}
              />
          }
        </div>
      }
      <div className="final-judgement">
        <span className="inner-label">{@i18n 'final_judgement'}</span>
        <span>{@props.criterionDescription}</span>
        <HorizontalRadios
          groupName="#{@props.sectionId}-final-judgement"
          options={@props.finalJudgementOptions}
          additionalOptions={@props.finalJudgementAdditionalOptions}
          selectedOption={@props.finalJudgementSelectedOption}
          onChange={EtdActions.changeJudgement.bind null, @props.sectionId}
        />
      </div>
      <ButtonsRow className="bottom-buttons">
        <Button
          className="cancel"
          label={@i18n '/translation:actions.cancel'}
          onClick={@props.onCancel}
        />
        <Button
          className="btn-success"
          label={@i18n '/translation:actions.apply'}
          onClick={@props.onApply}
        />
      </ButtonsRow>
    </div>

module.exports = DetailedJudgements
