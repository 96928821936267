var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "  <div class=\"edit-input-block form-group row\" data-property=\"effectRate\">\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "cont", {"name":"is","hash":{},"fn":this.program(2, data),"inverse":this.program(8, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "  </div>\n  <div class=\"edit-input-block form-group row mt-10\" data-property=\"confidenceIntervalTo\">\n    <div class=\"col-4\">\n      <label>\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.metaType : depth0), "RANGE_OF_EFFECTS", {"name":"is","hash":{},"fn":this.program(11, data),"inverse":this.program(13, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </label>\n      <label for=\"from_ci\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.from", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n    </div>\n    <div class=\"col-7\">\n      <div class=\"row\">\n        <div class=\"col-6\">\n        <input class=\"form-control\" id=\"from_ci\" type=\"text\"\n          data-tip-position=\"right\" data-target-position=\"left\"\n          name=\"confidenceIntervalFrom\" value=\""
    + escapeExpression(((helper = (helper = helpers.confidenceIntervalFrom || (depth0 != null ? depth0.confidenceIntervalFrom : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"confidenceIntervalFrom","hash":{},"data":data}) : helper)))
    + "\">\n        </div>\n        <div class=\"col-1\">\n          <label for=\"to_ci\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.to", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n        </div>\n        <div class=\"col-5\">\n          <input class=\"form-control\" id=\"to_ci\" type=\"text\"\n            data-tip-position=\"top\" data-target-position=\"bottom\"\n            name=\"confidenceIntervalTo\" value=\""
    + escapeExpression(((helper = (helper = helpers.confidenceIntervalTo || (depth0 != null ? depth0.confidenceIntervalTo : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"confidenceIntervalTo","hash":{},"data":data}) : helper)))
    + "\">\n        </div>\n      </div>\n    </div>\n    <div class=\"col-1 sup\"></div>\n  </div>\n";
},"2":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "    <div class=\"col-4\">\n      <label>\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "effect", {"name":"i18n","hash":{},"data":data})))
    + "\n      </label>\n    </div>\n    <div class='col-7'>\n      <div class=\"row\">\n        <div class=\"col-6\">\n          <div class=\"select-input-container ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.effectMeasure : depth0), "other", {"name":"is","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n            "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "effectMeasure", (depth0 != null ? depth0.effectMeasure : depth0), "MD: measure.md, SMD: measure.smd, mean: measure.mean,\n              median: measure.median, other: measure.other", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n            <input type=\"text\" name=\"otherMeasure\" value=\""
    + escapeExpression(((helper = (helper = helpers.otherMeasure || (depth0 != null ? depth0.otherMeasure : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"otherMeasure","hash":{},"data":data}) : helper)))
    + "\"\n            ";
  stack1 = ((helpers.isnt || (depth0 && depth0.isnt) || helperMissing).call(depth0, (depth0 != null ? depth0.effectMeasure : depth0), "other", {"name":"isnt","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n          </div>\n        </div>\n        <div class=\"col-3\">\n          <input class=\"form-control\" type=\"text\" name=\"effectRate\" id=\"effectRate\"\n            value=\""
    + escapeExpression(((helper = (helper = helpers.effectRate || (depth0 != null ? depth0.effectRate : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"effectRate","hash":{},"data":data}) : helper)))
    + "\">\n        </div>\n        <div class=\"col-3\">\n          <input class=\"form-control\" id=\"units\" type=\"text\" name=\"units\" value=\""
    + escapeExpression(((helper = (helper = helpers.units || (depth0 != null ? depth0.units : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"units","hash":{},"data":data}) : helper)))
    + "\">\n        </div>\n      </div>\n    </div>\n    <div class=\"col-1 sup\"></div>\n";
},"4":function(depth0,helpers,partials,data) {
  return "other";
  },"6":function(depth0,helpers,partials,data) {
  return "style=\"display: none\"";
  },"8":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    <div class=\"col-2\">\n      <input id=\"effectRate\" type=\"text\" class=\"form-control\" value=\""
    + escapeExpression(((helper = (helper = helpers.effectRate || (depth0 != null ? depth0.effectRate : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"effectRate","hash":{},"data":data}) : helper)))
    + "\"\n        data-tip-position=\"right\" data-target-position=\"left\" name=\"effectRate\" >\n    </div>\n    <div class=\"col-9 effect-rate\">\n      <div class=\"inline label\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.per", {"name":"i18n","hash":{},"data":data})))
    + "\n      </div>\n      <div class=\"inline select-input-container ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.effectDenominator : depth0), "other", {"name":"is","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n        "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "effectDenominator", (depth0 != null ? depth0.effectDenominator : depth0), "1: 1, 10: 10, 100: 100, 1000: 1000, 10000: 10 000, 100000: 100 000,\n          other: other", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n        <input type=\"text\" name=\"effectOtherDenominator\" class=\"form-control\"\n          value=\""
    + escapeExpression(((helper = (helper = helpers.effectOtherDenominator || (depth0 != null ? depth0.effectOtherDenominator : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"effectOtherDenominator","hash":{},"data":data}) : helper)))
    + "\"\n          ";
  stack1 = ((helpers.isnt || (depth0 && depth0.isnt) || helperMissing).call(depth0, (depth0 != null ? depth0.effectDenominator : depth0), "other", {"name":"isnt","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n      </div>\n      <div class=\"inline select-input-container ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.effectDuration : depth0), "other", {"name":"is","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </div>\n    </div>\n    <div class=\"col-1 sup\"></div>\n";
},"9":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "          "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "effectDuration", (depth0 != null ? depth0.effectDuration : depth0), "person_hour: person_hour, person_day: person_day, person_week: person_week,\n            person_month: person_month, person_year: person_year, other: other", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n          <input type=\"text\" name=\"effectOtherDuration\" class=\"form-control\"\n            value=\""
    + escapeExpression(((helper = (helper = helpers.effectOtherDuration || (depth0 != null ? depth0.effectOtherDuration : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"effectOtherDuration","hash":{},"data":data}) : helper)))
    + "\"\n            ";
  stack1 = ((helpers.isnt || (depth0 && depth0.isnt) || helperMissing).call(depth0, (depth0 != null ? depth0.effectDuration : depth0), "other", {"name":"isnt","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n";
},"11":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.ranged", {"name":"i18n","hash":{},"data":data})))
    + "\n";
},"13":function(depth0,helpers,partials,data) {
  return "          95% CI\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compoundEdit || (depth0 && depth0.compoundEdit) || helperMissing).call(depth0, "rate", "rate", {"name":"compoundEdit","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}