var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "<div class=\"questions-with-conflicts\">\n  <div class=\"questions\">\n    <table class=\"standard-table\">\n      <thead>\n        <tr>\n          <th>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "question", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n          <th>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "conflict", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        </tr>\n      </thead>\n      <tbody>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.questions : depth0), {"name":"each","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "      </tbody>\n    </table>\n  </div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.formRetrieved : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.program(30, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n<div class=\"row mt-20\">\n  <div class=\"col-6\">\n    <button class=\"btn btn-block cancel\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/actions.cancel", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n  </div>\n  <div class=\"col-6\">\n    <button class=\"btn btn-block btn-success finish apply\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/actions.save", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n  </div>\n</div>\n<div class=\"text-center mt-10\">\n  <a href=\""
    + escapeExpression(((helper = (helper = helpers.previewFormUrl || (depth0 != null ? depth0.previewFormUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"previewFormUrl","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\" class=\"link\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "preview_coi_form", {"name":"i18n","hash":{},"data":data})))
    + "</a>\n</div>\n";
},"2":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "         <tr>\n           <td>"
    + escapeExpression(((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"question","hash":{},"data":data}) : helper)))
    + "</td>\n           <td><input data-question=\""
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\" type=\"checkbox\" ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.value : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "></td>\n         </tr>\n";
},"3":function(depth0,helpers,partials,data) {
  return "checked";
  },"5":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isConflict : depth0), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.program(28, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "      <div class=\"answers\">\n";
  stack1 = ((helpers.inStringArray || (depth0 && depth0.inStringArray) || helperMissing).call(depth0, "who, nhf", (depth0 != null ? depth0.coiFormType : depth0), {"name":"inStringArray","hash":{},"fn":this.program(7, data),"inverse":this.program(16, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </div>\n";
},"7":function(depth0,helpers,partials,data) {
  var stack1, buffer = "          <ul>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.conflicts : depth0), {"name":"each","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "          </ul>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.other : depth0), {"name":"if","hash":{},"fn":this.program(14, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "team:coi.who_form", {"name":"i18nNamespace","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"9":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "              <li>\n                <strong>"
    + escapeExpression(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"description","hash":{},"data":data}) : helper)))
    + "</strong><br>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.organizationName : depth0), {"name":"if","hash":{},"fn":this.program(10, data),"inverse":this.program(12, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "              </li>\n";
},"10":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "                  "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "organization", {"name":"i18n","hash":{},"data":data})))
    + ": "
    + escapeExpression(((helper = (helper = helpers.organizationName || (depth0 != null ? depth0.organizationName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"organizationName","hash":{},"data":data}) : helper)))
    + "<br>\n                  "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "belongs_to_you_etc", {"name":"i18n","hash":{},"data":data})))
    + ": "
    + escapeExpression(((helper = (helper = helpers.whoBelongsTo || (depth0 != null ? depth0.whoBelongsTo : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"whoBelongsTo","hash":{},"data":data}) : helper)))
    + "<br>\n                  "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "income_amount", {"name":"i18n","hash":{},"data":data})))
    + ": "
    + escapeExpression(((helper = (helper = helpers.incomeAmount || (depth0 != null ? depth0.incomeAmount : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"incomeAmount","hash":{},"data":data}) : helper)))
    + "<br>\n                  "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "current_interest", {"name":"i18n","hash":{},"data":data})))
    + ": "
    + escapeExpression(((helper = (helper = helpers.currentInterest || (depth0 != null ? depth0.currentInterest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"currentInterest","hash":{},"data":data}) : helper)))
    + "<br>\n";
},"12":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "                  "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "subject_description", {"name":"i18n","hash":{},"data":data})))
    + ": "
    + escapeExpression(((helper = (helper = helpers.subjectDescription || (depth0 != null ? depth0.subjectDescription : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"subjectDescription","hash":{},"data":data}) : helper)))
    + "\n";
},"14":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "            <h2>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "other_relationships", {"name":"i18n","hash":{},"data":data})))
    + "</h2>\n            <p>"
    + escapeExpression(((helper = (helper = helpers.other || (depth0 != null ? depth0.other : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"other","hash":{},"data":data}) : helper)))
    + "</p>\n";
},"16":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, "mspsc", (depth0 != null ? depth0.coiFormType : depth0), {"name":"is","hash":{},"fn":this.program(17, data),"inverse":this.program(22, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"17":function(depth0,helpers,partials,data) {
  var stack1, buffer = "            <ul>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.conflicts : depth0), {"name":"each","hash":{},"fn":this.program(18, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </ul>\n";
},"18":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "team:coi.mspsc_form", {"name":"i18nNamespace","hash":{},"fn":this.program(19, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"19":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "                <li>\n                  <strong>"
    + escapeExpression(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"description","hash":{},"data":data}) : helper)))
    + "</strong><br>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.relationship : depth0), {"name":"if","hash":{},"fn":this.program(20, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                  "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "activity_desc", {"name":"i18n","hash":{},"data":data})))
    + ": "
    + escapeExpression(((helper = (helper = helpers.activityDesc || (depth0 != null ? depth0.activityDesc : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"activityDesc","hash":{},"data":data}) : helper)))
    + "<br>\n                  "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "funding_source", {"name":"i18n","hash":{},"data":data})))
    + ": "
    + escapeExpression(((helper = (helper = helpers.foundingSource || (depth0 != null ? depth0.foundingSource : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"foundingSource","hash":{},"data":data}) : helper)))
    + "<br>\n                  "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "activity_date_duration", {"name":"i18n","hash":{},"data":data})))
    + ": "
    + escapeExpression(((helper = (helper = helpers.activityDateDuration || (depth0 != null ? depth0.activityDateDuration : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"activityDateDuration","hash":{},"data":data}) : helper)))
    + "<br>\n                </li>\n";
},"20":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "                    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "relationship", {"name":"i18n","hash":{},"data":data})))
    + ": "
    + escapeExpression(((helper = (helper = helpers.relationship || (depth0 != null ? depth0.relationship : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"relationship","hash":{},"data":data}) : helper)))
    + "<br>\n";
},"22":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "            <h2>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "declared_conflicts", {"name":"i18n","hash":{},"data":data})))
    + "</h2>\n            <ul>\n";
  stack1 = ((helpers.eachProperty || (depth0 && depth0.eachProperty) || helperMissing).call(depth0, (depth0 != null ? depth0.conflicts : depth0), {"name":"eachProperty","hash":{},"fn":this.program(23, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "            </ul>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.other : depth0), {"name":"if","hash":{},"fn":this.program(26, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"23":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "              <li>\n                <span>"
    + escapeExpression(((helper = (helper = helpers.key || (depth0 != null ? depth0.key : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"key","hash":{},"data":data}) : helper)))
    + "</span>\n                <ol>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.value : depth0), {"name":"each","hash":{},"fn":this.program(24, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                </ol>\n              </li>\n";
},"24":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                  <li>"
    + escapeExpression(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"description","hash":{},"data":data}) : helper)))
    + "</li>\n";
},"26":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "              <h2>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "other_relationships", {"name":"i18n","hash":{},"data":data})))
    + "</h2>\n              <p>"
    + escapeExpression(((helper = (helper = helpers.other || (depth0 != null ? depth0.other : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"other","hash":{},"data":data}) : helper)))
    + "</p>\n";
},"28":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <div>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "no_conflicts", {"name":"i18n","hash":{},"data":data})))
    + "</div>\n";
},"30":function(depth0,helpers,partials,data) {
  return "    <div class=\"answers box-loading\"></div>\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "team:coi", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}