var DbHelper, Exceptions, Model, Requests, UdbService, User, UserSessions, W, nodefn, poll,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

Exceptions = require('lib/exceptions');

User = require('models/user');

Requests = require('models/requests');

UserSessions = require('models/user_sessions');

Model = require('models/base/model');

DbHelper = require('base/lib/db_helper');

W = require('when');

nodefn = require('when/node/function');

poll = require('when/poll');

module.exports = UdbService = (function() {
  function UdbService() {
    this._triggerInitialReplication = __bind(this._triggerInitialReplication, this);
  }

  UdbService.prototype.retryTimeout = null;

  UdbService.prototype.isInitialized = function(username) {
    var p;
    p = new Pouch(this.getUdbName(username), {
      size: INITIAL_POUCHDB_SIZE
    });
    return nodefn.call(_(p.get).bind(p), this.getUdbName(username)).then((function() {
      return true;
    }), function(error) {
      if (error.status === 404) {
        return false;
      } else {
        throw error;
      }
    });
  };

  UdbService.prototype.initialize = function(username) {
    return this.isInitialized(username).then((function(_this) {
      return function(isInitialized) {
        if (isInitialized) {
          return;
        }
        return poll((function() {
          return _this._triggerInitialReplication(username);
        }), _this.retryTimeout, function(v) {
          return v === 'completed';
        });
      };
    })(this));
  };

  UdbService.prototype._triggerInitialReplication = function(username) {
    var dbName;
    dbName = this.getUdbName(username);
    return nodefn.call(Pouch.replicate.bind(Pouch), DbHelper.centralDbUrl(dbName), dbName).then((function(_this) {
      return function() {
        return _this.isInitialized(username);
      };
    })(this)).then(function(isInitialized) {
      if (isInitialized) {
        return 'completed';
      } else {
        return 'not_initialized_yet';
      }
    }, function(error) {
      if (!error.status) {
        throw error;
      }
      if (error.status === 500) {
        return 'not_initialized_yet';
      }
      if (error.status !== 404) {
        throw new Exceptions.no_connection;
      }
      return 'no_central_udb';
    });
  };

  UdbService.prototype.loadUserData = function(username) {
    var requests, sessionsPromise, udbName, user;
    user = new User({
      _id: this.getUdbName(username),
      name: username
    });
    udbName = this.getUdbName(username);
    requests = new Requests(null, {
      url: udbName
    });
    sessionsPromise = this.adapter.getDoc(UserSessions, 'user-sessions', udbName);
    return W.all([requests.fetch(), sessionsPromise]).then(function(_arg) {
      var requests, sessions;
      requests = _arg[0], sessions = _arg[1];
      user.requests = requests;
      user.sessions = sessions;
      return user.fetch();
    });
  };

  UdbService.prototype.getLocalUserDocByEmail = function(email) {
    return gdt.storage.get('users').then((function(_this) {
      return function(value) {
        var userDocPromises, users;
        users = value ? JSON.parse(value) : [];
        userDocPromises = _(users).map(function(user) {
          var dbName, udb;
          dbName = _this.getUdbName(user);
          udb = new PouchDB(dbName);
          return nodefn.call(udb.get.bind(udb), dbName);
        });
        return W.all(userDocPromises).then(function(userDocs) {
          return _(userDocs).findWhere({
            email: email
          });
        });
      };
    })(this));
  };

  UdbService.prototype.getLocalUserEmail = function(username) {
    var dbName, udb;
    dbName = this.getUdbName(username);
    udb = new PouchDB(dbName);
    return udb.get(dbName).then(function(userDoc) {
      return userDoc.email;
    });
  };

  UdbService.prototype.getUdbName = function(username) {
    return "u_" + username;
  };

  UdbService.prototype.getDoc = function(username, id) {
    var udbName;
    udbName = this.getUdbName(username);
    return this.adapter.getDoc(Model, id, udbName);
  };

  return UdbService;

})();
