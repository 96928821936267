EtdTemplatesActions = require 'actions/etd_templates_actions'
PureRenderMixin = React.addons.PureRenderMixin

SectionCheckbox = React.createClass
  displayName: 'SectionCheckbox'

  mixins: [PureRenderMixin]

  propTypes:
    sectionDef: React.PropTypes.object.isRequired
    label: React.PropTypes.string.isRequired
    blockName: React.PropTypes.string.isRequired
    description: React.PropTypes.string

  render: ->
    sectionDef = @props.sectionDef
    id = sectionDef.get('id')

    readOnlyOrChangeProp = if sectionDef.get('required')
      readOnly: true
    else
      onChange: => EtdTemplatesActions.toggleSection @props.blockName, sectionDef.get('id')

    labelClasses = classNames 'section-checkbox', disabled: sectionDef.get('required')
    sectionNameClasses = classNames 'section-name', 'with-desc': @props.description

    <div className={@props.className}>
      <label className={labelClasses}>
        <input
          type="checkbox"
          value={id}
          checked={sectionDef.get('required') or sectionDef.get('checked')}
          {...readOnlyOrChangeProp} />
        <span className={sectionNameClasses}>{@props.label}</span>
      </label>
      <div className='section-desc'>{@props.description}</div>
    </div>

module.exports = SectionCheckbox
