var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.message : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.program(4, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <div class=\"message\">"
    + escapeExpression(((helper = (helper = helpers.message || (depth0 != null ? depth0.message : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"message","hash":{},"data":data}) : helper)))
    + "</div>\n        <div class=\"col-3 mt-20\">\n            <button class=\"btn btn-block go-to-source\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "check_source_data", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n        </div>\n";
},"4":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.testChoiceDropdown : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.testSelected : depth0), {"name":"if","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "            <select class=\"test-dropdown\">\n                <option value=\"none\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "select_test", {"name":"i18n","hash":{},"data":data})))
    + "</option>\n                <option ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.testSelected : depth0), "index", {"name":"is","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += " value=\"index\">\n                    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "index_test", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.indexTest || (depth0 != null ? depth0.indexTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"indexTest","hash":{},"data":data}) : helper)))
    + "\n                </option>\n                <option ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.testSelected : depth0), "comparator", {"name":"is","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + " value=\"comparator\">\n                    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "comparator_test", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.comparatorTest || (depth0 != null ? depth0.comparatorTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparatorTest","hash":{},"data":data}) : helper)))
    + "\n                </option>\n            </select>\n";
},"6":function(depth0,helpers,partials,data) {
  return "selected";
  },"8":function(depth0,helpers,partials,data) {
  return "            <div id=\"isof-dx-content\" class=\"isof-dx container\">\n                <diagnosis></diagnosis>\n            </div>\n            <div id=\"isof-tx-content\" class=\"isof-tx container\">\n                <isof-table isof=\"isof\" editable=\"false\"></isof-table>\n            </div>\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:isof", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}