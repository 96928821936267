var ModalView, OpenProjectFromFileView, Shortcuts, gdtBackup, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModalView = require('base/views/modal_view');

template = require('views/templates/open_project_from_file');

Shortcuts = require('base/lib/traits/shortcuts');

gdtBackup = require('lib/gdt_backup');

mediator = require('mediator');

module.exports = OpenProjectFromFileView = (function(_super) {
  __extends(OpenProjectFromFileView, _super);

  function OpenProjectFromFileView() {
    return OpenProjectFromFileView.__super__.constructor.apply(this, arguments);
  }

  OpenProjectFromFileView.prototype.template = template;

  OpenProjectFromFileView.prototype.id = 'open-project-dialog';

  OpenProjectFromFileView.prototype.title = $.t('projects:open_from_file_dialog.title');

  OpenProjectFromFileView.prototype.shortcuts = {
    'enter': 'openFile',
    'esc': 'dispose'
  };

  OpenProjectFromFileView.prototype.initialize = function() {
    OpenProjectFromFileView.__super__.initialize.apply(this, arguments);
    this.enable(Shortcuts);
    this.delegate('change', '#file', this.fileChanged);
    this.delegate('click', '.open', this.openFile);
    this.delegate('click', 'button.cancel', this.dispose);
    this.delegate('click', 'button.file', function() {
      return this.$('#file').click();
    });
    this.delegate('mouseenter', 'button.file', function(e) {
      var button, height, width;
      if (!this.currentFile) {
        return;
      }
      button = this.$(e.target);
      width = button.outerWidth();
      height = button.outerHeight();
      return button.text($.t('projects:import_dialog.change_file')).width(width).height(height);
    });
    return this.delegate('mouseleave', 'button.file', this.setFileButtonCaption);
  };

  OpenProjectFromFileView.prototype.fileChanged = function(e) {
    var file, valid;
    file = e.target.files[0];
    valid = this.isValidFile(file);
    this.render();
    if (valid) {
      this.currentFile = file;
      this.setFileButtonCaption();
      return this.$('button.open').prop('disabled', false);
    }
  };

  OpenProjectFromFileView.prototype.setFileButtonCaption = function() {
    if (!this.currentFile) {
      return;
    }
    return this.$('button.file').text(this.currentFile.name).width('auto');
  };

  OpenProjectFromFileView.prototype.getTemplateData = function() {
    var data;
    data = OpenProjectFromFileView.__super__.getTemplateData.apply(this, arguments);
    data.error = this.validationError;
    return data;
  };

  OpenProjectFromFileView.prototype.openFile = function() {
    var reader;
    if (!this.currentFile) {
      return;
    }
    this.$('button.open').addClass('loading');
    reader = new FileReader();
    reader.onload = (function(_this) {
      return function(loadEvent) {
        gdtBackup.createProjects(loadEvent.target.result).then(function(projects) {
          return mediator.services.replication.sendLocalChanges(_(projects).pluck('id'));
        });
        return _this.dispose();
      };
    })(this);
    return reader.readAsText(this.currentFile);
  };

  OpenProjectFromFileView.prototype.isValidFile = function(f) {
    var fileExt;
    this.validationError = null;
    fileExt = f.name.split('.').pop();
    if (fileExt !== 'gdt') {
      this.validationError = $.t('projects:open_from_file_dialog.invalid_format');
      return false;
    }
    return true;
  };

  return OpenProjectFromFileView;

})(ModalView);
