var Ellipsis, FootnotesService, FootnotesView, Shortcuts, View, mediator, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

template = require('views/templates/evidence_syntheses/footnotes');

mediator = require('mediator');

Shortcuts = require('base/lib/traits/shortcuts');

FootnotesService = require('lib/services/footnotes_service');

Ellipsis = require('base/lib/traits/ellipsis');

module.exports = FootnotesView = (function(_super) {
  __extends(FootnotesView, _super);

  function FootnotesView() {
    this.onFocusLost = __bind(this.onFocusLost, this);
    this._removeOutcomeHandler = __bind(this._removeOutcomeHandler, this);
    this._restoreOutcomeHandler = __bind(this._restoreOutcomeHandler, this);
    this._addOutcomeHandler = __bind(this._addOutcomeHandler, this);
    this._footnoteBoundUnboudHandler = __bind(this._footnoteBoundUnboudHandler, this);
    this.automaticallySortFootnotes = __bind(this.automaticallySortFootnotes, this);
    return FootnotesView.__super__.constructor.apply(this, arguments);
  }

  FootnotesView.prototype.template = template;

  FootnotesView.prototype.className = 'footnotes';

  FootnotesView.prototype.container = '.footnotes-container';

  FootnotesView.prototype.ellipsisSelector = '.note-text';

  FootnotesView.prototype.shortcuts = {
    'down': 'selectNextFootnote',
    'up': 'selectPreviosFootnote',
    'space': 'editActiveFootnote',
    'esc': 'close',
    'esc.editing': 'closeEditing',
    'enter.editing': 'saveFootnote'
  };

  FootnotesView.prototype.initialize = function() {
    FootnotesView.__super__.initialize.apply(this, arguments);
    this.delegate('click', '.footnotes-bar', this.toggleFootnotes);
    this.delegate('click', 'button.edit', this.editFootnote);
    this.delegate('click', 'button.save', this.saveFootnote);
    this.delegate('click', 'button.delete', this.deleteFootnote);
    this.delegate('click', '.add-note', this.addFootnote);
    this._listenToFootnotesBinding();
    this.delegate('mouseenter', '.footnotes-block li', this.mouseEnterFootnote);
    this.modelBind('change:footnotesList', this.render);
    this.subscribeEvent('cellFootnotesClosed', this.render);
    this.footnotesOpened = false;
    this.enable(Shortcuts);
    this.enable(Ellipsis, {
      selector: this.ellipsisSelector
    });
    this.model.store();
    this.delegate('click', function(e) {
      return e.stopPropagation();
    });
    this.subscribeEvent('footnotesReordered', this.assignFootnoteItems);
    return this.subscribeEvent('footnotesListChanged', this.automaticallySortFootnotes);
  };

  FootnotesView.prototype.automaticallySortFootnotes = function() {
    var fs;
    fs = new FootnotesService({
      model: this.model
    });
    return fs.automaticallySortFootnotes();
  };

  FootnotesView.prototype._footnoteBoundUnboudHandler = function(bound, outcome) {
    var onOff;
    onOff = bound ? 'on' : 'off';
    outcome[onOff]('footnoteBound', this.automaticallySortFootnotes);
    return outcome[onOff]('footnoteUnbound', this.automaticallySortFootnotes);
  };

  FootnotesView.prototype._addOutcomeHandler = function(outcome) {
    return this._footnoteBoundUnboudHandler(true, outcome);
  };

  FootnotesView.prototype._restoreOutcomeHandler = function() {
    this.automaticallySortFootnotes();
    return _(this.model.get('outcomes').models).each(_(this._footnoteBoundUnboudHandler).partial(true));
  };

  FootnotesView.prototype._removeOutcomeHandler = function(outcome) {
    this.automaticallySortFootnotes();
    return this._footnoteBoundUnboudHandler(false, outcome);
  };

  FootnotesView.prototype._listenToFootnotesBinding = function(start) {
    var onOff;
    if (start == null) {
      start = true;
    }
    onOff = start ? 'on' : 'off';
    this._footnoteBoundUnboudHandler(start, this.model);
    _(this.model.get('outcomes').models).each(_(this._footnoteBoundUnboudHandler).partial(start));
    this.model.get('outcomes')[onOff]('add', this._addOutcomeHandler);
    this.model.get('outcomes')[onOff]('restore', this._restoreOutcomeHandler);
    return this.model.get('outcomes')[onOff]('remove', this._removeOutcomeHandler);
  };

  FootnotesView.prototype.toggleFootnotes = function() {
    if (this.footnotesBlock.is(':visible')) {
      return this.close();
    } else {
      return this.open();
    }
  };

  FootnotesView.prototype.getTemplateData = function() {
    return _(FootnotesView.__super__.getTemplateData.apply(this, arguments)).extend({
      footnotesList: this.model.getFootnotesArray()
    });
  };

  FootnotesView.prototype.assignFootnoteItems = function() {
    return this.footnoteItems = this.$('.footnotes-block li');
  };

  FootnotesView.prototype.afterRender = function() {
    FootnotesView.__super__.afterRender.apply(this, arguments);
    this.footnotesBlock = this.$('.footnotes-block');
    if (this.footnotesOpened) {
      this.footnotesBlock.show();
    } else {
      this.footnotesBlock.hide();
    }
    this.assignFootnoteItems();
    return this.automaticallySortFootnotes();
  };

  FootnotesView.prototype.close = function() {
    this.closeEditing();
    this.footnotesBlock.slideUp('fast');
    return this.footnotesBlock.promise().done((function(_this) {
      return function() {
        _this.footnotesOpened = false;
        _this.$('.footnotes-bar').removeClass('opened');
        _this.$('.footnotes-bar').addClass('closed');
        mediator.setFocus();
        _this._blockToggled();
        return _this.publishEvent('footnoteActivated');
      };
    })(this));
  };

  FootnotesView.prototype.open = function() {
    this.footnotesBlock.slideDown('fast');
    return this.footnotesBlock.promise().done((function(_this) {
      return function() {
        var _ref;
        _this.setActiveFootnote((_ref = _this.activeFootnoteIdx) != null ? _ref : null);
        _this.footnotesOpened = true;
        _this.$('.footnotes-bar').removeClass('closed');
        _this.$('.footnotes-bar').addClass('opened');
        mediator.setFocus(_this);
        _this._blockToggled();
        return _this.fixLayout();
      };
    })(this));
  };

  FootnotesView.prototype._blockToggled = function() {
    this.footnotesBlock.css('overflow', '');
    return mediator.publish('footnotesToggled');
  };

  FootnotesView.prototype.editFootnote = function(e, footnoteId) {
    this.closeEditing();
    this.footnoteItemEditing = footnoteId ? this.$("li[data-footnote-id=" + footnoteId + "]") : $(e.target).closest('li');
    this.footnoteItemEditing.find('.edit-footnote').show();
    this.footnoteItemEditing.find('.display-footnote').hide();
    this.footnoteItemEditing.find('.footnote-input-field').focus();
    return this.setDefaultScope('editing');
  };

  FootnotesView.prototype.closeEditing = function() {
    if (this.footnoteItemEditing) {
      this.footnoteItemEditing.find('.edit-footnote').hide();
      this.footnoteItemEditing.find('.display-footnote').show();
      this.fixLayout();
      this.footnoteItemEditing = null;
      return this.unsetDefaultScope();
    }
  };

  FootnotesView.prototype.saveFootnote = function() {
    var noteText;
    if (!this.footnoteItemEditing) {
      return;
    }
    noteText = this.footnoteItemEditing.find('.footnote-input-field').val();
    this.model.get('footnotesList').set(this.footnoteItemEditing.data('footnoteId'), noteText);
    this.footnoteItemEditing.find('.note-text').text(noteText);
    this.model.save();
    this.model.store();
    return this.closeEditing();
  };

  FootnotesView.prototype.deleteFootnote = function() {
    var noteId;
    if (!this.footnoteItemEditing) {
      return;
    }
    noteId = this.footnoteItemEditing.data('footnoteId');
    this.model.store();
    this.model.deleteFootnote(noteId);
    this.footnoteItemEditing.remove();
    this.closeEditing();
    return this.notifyFootnoteDeleted();
  };

  FootnotesView.prototype.addFootnote = function() {
    var newNoteId;
    this.saveFootnote();
    newNoteId = this.model.get('footnotesList').addNote('');
    this.render();
    return this.editFootnote(null, newNoteId);
  };

  FootnotesView.prototype.notifyFootnoteDeleted = function() {
    var _ref;
    if ((_ref = this.undoNotifier) != null) {
      _ref.destroy();
    }
    return this.undoNotifier = mediator.dialogs.undo($.t('es:footnotes.deleted')).on('click:undo', (function(_this) {
      return function() {
        return _this.undoDeleteFootnote();
      };
    })(this));
  };

  FootnotesView.prototype.undoDeleteFootnote = function() {
    this.undoNotifier.destroy();
    this.modelUnbind('change:footnotesList', this.render);
    this.model.restore();
    this.publishEvent('footnoteRestored');
    this.modelBind('change:footnotesList', this.render);
    this.model.parse(this.model.attributes);
    mediator.dialogs.success($.t('es:footnotes.delete_undone'));
    this.model.save();
    return this.render();
  };

  FootnotesView.prototype.onFocusLost = function() {
    return this.close();
  };

  FootnotesView.prototype.mouseEnterFootnote = function(e) {
    return this.setActiveFootnote(this.footnoteItems.index($(e.target).closest('li')));
  };

  FootnotesView.prototype.setActiveFootnote = function(footnoteItemIdx) {
    var _ref;
    if ((_ref = this.activeFootnote) != null) {
      _ref.removeClass('active');
    }
    if (footnoteItemIdx) {
      this.activeFootnote = this.footnoteItems.eq(footnoteItemIdx);
      this.activeFootnoteIdx = footnoteItemIdx;
    } else {
      this.activeFootnote = this.footnoteItems.eq(0);
      this.activeFootnoteIdx = 0;
    }
    this.activeFootnote.addClass('active');
    return this.publishEvent('footnoteActivated', this.activeFootnote.data('footnoteId'));
  };

  FootnotesView.prototype.selectNextFootnote = function() {
    if (this.activeFootnoteIdx === this.footnoteItems.size() - 1) {
      return;
    }
    return this.setActiveFootnote(this.activeFootnoteIdx + 1);
  };

  FootnotesView.prototype.selectPreviosFootnote = function() {
    if (this.activeFootnoteIdx === 0) {
      return;
    }
    return this.setActiveFootnote(this.activeFootnoteIdx - 1);
  };

  FootnotesView.prototype.editActiveFootnote = function() {
    if (this.activeFootnote.data('footnoteSpecial')) {
      return;
    }
    this.editFootnote(null, this.activeFootnote.data('footnoteId'));
    return false;
  };

  FootnotesView.prototype.dispose = function() {
    this._listenToFootnotesBinding(false);
    return FootnotesView.__super__.dispose.apply(this, arguments);
  };

  return FootnotesView;

})(View);
