var Event, Model, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Model = require('models/base/model');

mediator = require('mediator');

module.exports = Event = (function(_super) {
  __extends(Event, _super);

  function Event() {
    return Event.__super__.constructor.apply(this, arguments);
  }

  Event.prototype.defaults = function() {
    var now;
    now = moment(mediator.services.clock.getTimestamp());
    return {
      title: $.t('tasks:calendar.new_event'),
      description: '',
      done: false,
      allday: true,
      start: now.startOf('day').unix(),
      end: now.endOf('day').unix()
    };
  };

  Event.prototype.validation = {
    title: {
      required: true
    }
  };

  return Event;

})(Model);
