var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "  <div class=\"authentication\">\n    <form>\n      <a class=\"logo\" href=\"http://gradepro.org\" target=\"_blank\"><img src=\""
    + escapeExpression(((helper = (helper = helpers.logoUrl || (depth0 != null ? depth0.logoUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"logoUrl","hash":{},"data":data}) : helper)))
    + "\"></a>\n      <h1 class=\"title\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "password_recover_title", {"name":"i18n","hash":{},"data":data})))
    + "</h1>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.success : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.program(4, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </form>\n    <div class=\"footer\">\n      <button class=\"back-to-login-switch\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "log_in", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n    </div>\n  </div>\n";
  stack1 = this.invokePartial(partials.copyrightNotice, '  ', 'copyrightNotice', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          <div class=\"info\">\n            <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "password_reset_success", {"name":"i18n","hash":{},"data":data})))
    + "</p>\n            <p><a class=\"return\" href=\"\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "return_to_login", {"name":"i18n","hash":{},"data":data})))
    + "</a></p>\n          </div>\n";
},"4":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "          <div class=\"info\"><center>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "enter_login_or_email", {"name":"i18n","hash":{},"data":data})))
    + "</center></div>\n          <div class=\"error-shared\"></div>\n          <label>\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "email_or_username", {"name":"i18n","hash":{},"data":data})))
    + "\n            <input type=\"text\" class=\"login-or-email\" autofocus value=\""
    + escapeExpression(((helper = (helper = helpers.loginOrEmail || (depth0 != null ? depth0.loginOrEmail : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"loginOrEmail","hash":{},"data":data}) : helper)))
    + "\">\n          </label>\n\n          <div class=\"error login-or-email-error\"></div>\n          <input type=\"submit\" class=\"btn btn-primary btn-block\"\n            value=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/actions.send_instructions", {"name":"i18n","hash":{},"data":data})))
    + "\">\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "registration", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}