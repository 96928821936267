var Chaplin, GdtRouter, mediator,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Chaplin = require('chaplin');

mediator = require('mediator');

module.exports = GdtRouter = (function(_super) {
  __extends(GdtRouter, _super);

  function GdtRouter() {
    this.submodule = __bind(this.submodule, this);
    this.projectRoute = __bind(this.projectRoute, this);
    this.moduleChanged = __bind(this.moduleChanged, this);
    GdtRouter.__super__.constructor.apply(this, arguments);
    mediator.subscribe('startupController', this.moduleChanged);
    this.subscribeEvent('matchRoute', this._trackPageview);
  }

  GdtRouter.prototype._trackPageview = function() {
    var url;
    url = Backbone.history.getFragment();
    return ga('send', {
      hitType: 'pageview',
      page: "/" + url
    });
  };

  GdtRouter.prototype.moduleChanged = function(info) {
    var module, params, submodule;
    params = info.params;
    module = params.module;
    if (module !== this.currentModule) {
      mediator.publish('moduleChanged', module);
    }
    this.currentModule = module;
    submodule = params.submodule;
    if (submodule !== this.currentSubmodule) {
      mediator.publish('submoduleChanged', submodule, params);
    }
    return this.currentSubmodule = submodule;
  };

  GdtRouter.prototype.projectRoute = function(module, path, target, options) {
    var action, controller, submodule, url, _i, _len, _ref, _ref1, _ref2, _ref3;
    if (path == null) {
      path = '';
    }
    if (target == null) {
      target = '#index';
    }
    if (options == null) {
      options = {};
    }
    url = 'projects/:projectId';
    if (module) {
      url += "/" + (this.urlizeModuleName(module));
    }
    url += path;
    _ref = target.split('#'), controller = _ref[0], action = _ref[1];
    if (!controller) {
      controller = module;
    }
    options.params = _((_ref1 = options.params) != null ? _ref1 : {}).defaults({
      module: module
    });
    _ref3 = (_ref2 = options.submodules) != null ? _ref2 : [];
    for (_i = 0, _len = _ref3.length; _i < _len; _i++) {
      submodule = _ref3[_i];
      submodule(url, options);
    }
    return this.match(url, "" + controller + "#" + action, options);
  };

  GdtRouter.prototype.submodule = function(name, options) {
    return (function(_this) {
      return function(parentUrl, moduleOptions) {
        var action, controller, url, _ref;
        url = "" + parentUrl + "/" + (_this.urlizeModuleName(name));
        if (options.path) {
          url += options.path;
        }
        controller = "" + moduleOptions.params.module + "_" + name;
        action = 'dispatch';
        options.params = _((_ref = options.params) != null ? _ref : {}).defaults(moduleOptions.params, {
          submodule: name
        });
        _this.match(url, "" + controller + "#" + action, options);
        if (options.optional) {
          return _this.match(url + options.optional, "" + controller + "#" + action, options);
        }
      };
    })(this);
  };

  GdtRouter.prototype.routeForModule = function(module, params) {
    module = this.urlizeModuleName(module);
    if (params.projectId) {
      return "projects/" + params.projectId + "/" + module;
    } else {
      return '';
    }
  };

  GdtRouter.prototype.routeForSubmodule = function(module, submodule) {
    var _ref;
    module = this.urlizeModuleName(module);
    if (((_ref = mediator.project) != null ? _ref.id : void 0) && mediator.currentQuestionId) {
      return "projects/" + mediator.project.id + "/" + module + "/" + mediator.currentQuestionId + "/" + submodule;
    } else {
      return '';
    }
  };

  GdtRouter.prototype.urlizeModuleName = function(module) {
    return module != null ? module.replace(/_/g, '-') : void 0;
  };

  GdtRouter.prototype.getProjectRelativeUrl = function(url) {
    var _ref;
    return "/projects/" + ((_ref = mediator.project) != null ? _ref.id : void 0) + url;
  };

  return GdtRouter;

})(Chaplin.Router);
