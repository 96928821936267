var DiagnosticAbsoluteDifferenceView, View, formatters, su, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

template = require('views/templates/evidence_syntheses/diagnostic/absolute_difference');

su = require('lib/statistical_utils');

formatters = require('lib/formatters');

module.exports = DiagnosticAbsoluteDifferenceView = (function(_super) {
  __extends(DiagnosticAbsoluteDifferenceView, _super);

  function DiagnosticAbsoluteDifferenceView() {
    return DiagnosticAbsoluteDifferenceView.__super__.constructor.apply(this, arguments);
  }

  DiagnosticAbsoluteDifferenceView.prototype.template = template;

  DiagnosticAbsoluteDifferenceView.prototype.tagName = 'tr';

  DiagnosticAbsoluteDifferenceView.prototype.className = 'absolute-difference outcome-row';

  DiagnosticAbsoluteDifferenceView.prototype.fieldsToObserve = ['sourceOfDxData', 'pooledSensitivity', 'pooledSensitivityFrom', 'pooledSensitivityTo', 'pooledSpecificity', 'pooledSpecificityFrom', 'pooledSpecificityTo', 'pooledSensitivityComparison', 'pooledSensitivityComparisonFrom', 'pooledSensitivityComparisonTo', 'pooledSpecificityComparison', 'pooledSpecificityComparisonFrom', 'pooledSpecificityComparisonTo', 'prevalence1', 'prevalence2', 'prevalence3', 'pooledSensitivityRangeFrom', 'pooledSensitivityRangeTo', 'pooledSpecificityRangeFrom', 'pooledSpecificityRangeTo', 'pooledSensitivityComparisonRangeFrom', 'pooledSensitivityComparisonRangeTo', 'pooledSpecificityComparisonRangeFrom', 'pooledSpecificityComparisonRangeTo'];

  DiagnosticAbsoluteDifferenceView.prototype.bindings = {
    'td.prevalence-1 span.difference-value': {
      observe: DiagnosticAbsoluteDifferenceView.prototype.fieldsToObserve,
      onGet: function() {
        return this.getAbsoluteDifference(1);
      }
    },
    'td.prevalence-2 span.difference-value': {
      observe: DiagnosticAbsoluteDifferenceView.prototype.fieldsToObserve,
      onGet: function() {
        return this.getAbsoluteDifference(2);
      }
    },
    'td.prevalence-3 span.difference-value': {
      observe: DiagnosticAbsoluteDifferenceView.prototype.fieldsToObserve,
      onGet: function() {
        return this.getAbsoluteDifference(3);
      }
    }
  };

  DiagnosticAbsoluteDifferenceView.prototype.getAbsoluteDifference = function(prevalenceNo) {
    return formatters.outcome.diagnosticAbsoluteDifference(this.qm.toJSON(), this.model.get('name'), prevalenceNo);
  };

  DiagnosticAbsoluteDifferenceView.prototype.initialize = function() {
    DiagnosticAbsoluteDifferenceView.__super__.initialize.apply(this, arguments);
    return this.qm = this.options.questionModel;
  };

  DiagnosticAbsoluteDifferenceView.prototype.render = function() {
    DiagnosticAbsoluteDifferenceView.__super__.render.apply(this, arguments);
    return this.stickit(this.options.questionModel, this.bindings);
  };

  DiagnosticAbsoluteDifferenceView.prototype.getTemplateData = function() {
    var data;
    data = DiagnosticAbsoluteDifferenceView.__super__.getTemplateData.apply(this, arguments);
    data.differences = [1, 2, 3];
    return data;
  };

  DiagnosticAbsoluteDifferenceView.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    this.unstickit(this.options.questionModel, this.bindings);
    return DiagnosticAbsoluteDifferenceView.__super__.dispose.apply(this, arguments);
  };

  return DiagnosticAbsoluteDifferenceView;

})(View);
