var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.loading : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.program(4, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <div class=\"centered\">\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/messages.please_wait", {"name":"i18n","hash":{},"data":data})))
    + "\n        </div>\n";
},"4":function(depth0,helpers,partials,data) {
  var stack1, buffer = "        <table class=\"standard-table\">\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.isMenuMode : depth0), {"name":"unless","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </table>\n";
},"5":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "                <thead>\n                <tr>\n                    <th></th>\n                    <th class=\"sort nr\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "order_number", {"name":"i18n","hash":{},"data":data})))
    + "\n                        <button class=\"sort\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "sort_by_number", {"name":"i18n","hash":{},"data":data})))
    + "' tabindex=\"-1\"/>\n                    </th>\n                    <th class=\"sort name\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "name", {"name":"i18n","hash":{},"data":data})))
    + "\n                        <button class=\"sort\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "sort_by_name", {"name":"i18n","hash":{},"data":data})))
    + "' tabindex=\"-1\"/>\n                    </th>\n                    <th class=\"sort customer\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "customer", {"name":"i18n","hash":{},"data":data})))
    + "\n                        <button class=\"sort\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "sort_by_customer", {"name":"i18n","hash":{},"data":data})))
    + "' tabindex=\"-1\"/>\n                    </th>\n                    <th class=\"sort date\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isActive : depth0), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.program(8, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                        <button class=\"sort\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "sort_by_date", {"name":"i18n","hash":{},"data":data})))
    + "' tabindex=\"-1\"/>\n                    </th>\n                    <th></th>\n                </tr>\n                </thead>\n";
},"6":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "edited_on", {"name":"i18n","hash":{},"data":data})))
    + "\n";
},"8":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "archived_on", {"name":"i18n","hash":{},"data":data})))
    + "\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "projects:list", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}