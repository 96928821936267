var Shortcuts, UserView, View, mediator, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

Shortcuts = require('base/lib/traits/shortcuts');

template = require('./templates/user');

mediator = require('mediator');

module.exports = UserView = (function(_super) {
  __extends(UserView, _super);

  function UserView() {
    this._checkLoginStatus = __bind(this._checkLoginStatus, this);
    return UserView.__super__.constructor.apply(this, arguments);
  }

  UserView.prototype.className = 'user-view';

  UserView.prototype.container = 'body';

  UserView.prototype.template = template;

  UserView.prototype.autoRender = true;

  UserView.prototype.shortcuts = {
    'esc': 'dispose'
  };

  UserView.prototype.initialize = function() {
    UserView.__super__.initialize.apply(this, arguments);
    this.enable(Shortcuts);
    this.delegate('click', 'button.log-out', function() {
      return this.options.authenticationService.logout().then(function() {
        return mediator.publish('!reloadApplication');
      });
    });
    this.delegate('click', this.gainFocus);
    this.subscribeEvent('replication.statusChanged', this._checkLoginStatus);
    return mediator.setFocus(this);
  };

  UserView.prototype._checkLoginStatus = function() {
    return this.options.authenticationService.getLoginStatus().then((function(_this) {
      return function(status) {
        _this.loginStatus = status;
        _this.render();
        return mediator.setFocus(_this);
      };
    })(this), function(error) {
      return mediator.dialogs.fatalError(error);
    });
  };

  UserView.prototype.onFocusLost = function() {
    return this.dispose();
  };

  UserView.prototype.getTemplateData = function() {
    return {
      login: this.model.get('name'),
      centralLogin: this.loginStatus ? this.loginStatus.status === 'central' : this.options.centralConnection,
      online: this.options.replicationService.isOnline(),
      timeAgo: this.options.lastBackup ? moment(this.options.lastBackup).fromNow() : ''
    };
  };

  return UserView;

})(View);
