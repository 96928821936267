var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "messages.disconnected_info", {"name":"i18n","hash":{},"data":data})))
    + "<br><br>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "messages.disconnected_info_chrome", {"name":"i18n","hash":{},"data":data})))
    + "\">\n";
},"3":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "messages.disconnected_info", {"name":"i18n","hash":{},"data":data})))
    + "\">\n";
},"5":function(depth0,helpers,partials,data) {
  return "          <button class=\"connection\"></button>\n";
  },"7":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "      <a href=\"#\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "header.projects_menu", {"name":"i18n","hash":{},"data":data})))
    + "'>"
    + escapeExpression(((helper = (helper = helpers.currentProject || (depth0 != null ? depth0.currentProject : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"currentProject","hash":{},"data":data}) : helper)))
    + "</a>\n";
},"9":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                    <div class=\"col-4\">\n                        <button class=\"btn btn-block project-import\"\n                                title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "projects:import_title", {"name":"i18n","hash":{},"data":data})))
    + "'>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "projects:import", {"name":"i18n","hash":{},"data":data})))
    + "\n                        </button>\n                    </div>\n";
},"11":function(depth0,helpers,partials,data) {
  return "            <div class=\"new-projects-list\"></div>\n";
  },"13":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "            <ol class=\"standard-list\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.projects : depth0), {"name":"each","hash":{},"fn":this.program(14, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </ol>\n";
},"14":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, helperMissing=helpers.helperMissing, functionType="function", escapeExpression=this.escapeExpression, buffer = "                    <li ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0._id : depth0), (depths[1] != null ? depths[1].currentProjectId : depths[1]), {"name":"is","hash":{},"fn":this.program(15, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n                        <a class='caption' href='/projects/"
    + escapeExpression(((helper = (helper = helpers._id || (depth0 != null ? depth0._id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"_id","hash":{},"data":data}) : helper)))
    + "'>"
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "</a>\n                    </li>\n";
},"15":function(depth0,helpers,partials,data) {
  return "class='current'";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "<div id=\"header-message\">\n  <div id=\"header-update-info\" class=\"info\">";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "messages.cache_updated", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n  <div id=\"header-disconnected-warning\" class=\"warning\">\n    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "messages.disconnected_title", {"name":"i18n","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "messages.disconnected_title_pt2", {"name":"i18n","hash":{},"data":data})))
    + "\n    <span class=\"question-mark-sign\"\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isChrome : depth0), {"name":"if","hash":{},"fn":this.program(1, data, depths),"inverse":this.program(3, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </span>\n  </div>\n  <div id=\"header-connection-timeout-warning\" class=\"warning\">\n      ";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "messages.connection_timeout", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n  </div>\n</div>\n<div class=\"wrapper\">\n    <button class=\"user-info\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "header.user_info_title", {"name":"i18n","hash":{},"data":data})))
    + "\">"
    + escapeExpression(((helper = (helper = helpers.userEmail || (depth0 != null ? depth0.userEmail : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"userEmail","hash":{},"data":data}) : helper)))
    + "</button>\n    <menu type=\"toolbar\" class=\"right\">\n      <button title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "header.settings", {"name":"i18n","hash":{},"data":data})))
    + "' class=\"settings\"></button>\n      <button class=\"feedback\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "header.feedback", {"name":"i18n","hash":{},"data":data})))
    + "\"></button>\n      <button class=\"help\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "header.help", {"name":"i18n","hash":{},"data":data})))
    + "\"></button>\n";
  stack1 = ((helpers.switchOn || (depth0 && depth0.switchOn) || helperMissing).call(depth0, "connection-indicator", {"name":"switchOn","hash":{},"fn":this.program(5, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </menu>\n    <h1 class=\"logo\">\n      <a href='/' title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "header.go_home", {"name":"i18n","hash":{},"data":data})))
    + "'>GDT</a>\n    </h1>\n    <div class=\"update-installed-info\">\n      <span class=\"info\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "messages.update_installed", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n      <a class=\"whats-new-link\" target=\"_blank\" href=\""
    + escapeExpression(((helper = (helper = helpers.whatsNewLink || (depth0 != null ? depth0.whatsNewLink : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"whatsNewLink","hash":{},"data":data}) : helper)))
    + "\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "messages.see_what_new", {"name":"i18n","hash":{},"data":data})))
    + "\n      </a>\n    </div>\n    <nav class=\"projects\">\n      <div class=\"title\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.currentProject : depth0), {"name":"if","hash":{},"fn":this.program(7, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "      </div>\n      <div class=\"projects-menu\">\n        <span class=\"dot\"></span>\n        <div>\n          <div class=\"row\">\n            <div class=\"col-1\">\n              <label>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "header.project_actions", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n            </div>\n            <div class=\"col-11\">\n              <div class=\"row\">\n                <div class=\"col-4\">\n                  <button\n                    class=\"btn btn-block project-create\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "projects:add_new", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n                </div>\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.newProjectsList : depth0), {"name":"unless","hash":{},"fn":this.program(9, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "                <div class=\"col-4\">\n                  <a class=\"btn btn-block projects-list\" href='#projects'\n                    title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "projects:overview_title", {"name":"i18n","hash":{},"data":data})))
    + "'>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "projects:overview", {"name":"i18n","hash":{},"data":data})))
    + "</a>\n                </div>\n              </div>\n            </div>\n          </div>\n        </div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.newProjectsList : depth0), {"name":"if","hash":{},"fn":this.program(11, data, depths),"inverse":this.program(13, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </div>\n    </nav>\n</div>\n";
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}