var CollectionView, DraftQuestion, DraftQuestionView, Focusable, NextStepButton, QuestionsModerationView, Scrolling, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

CollectionView = require('base/views/collection_view');

DraftQuestion = require('base/models/draft_question');

DraftQuestionView = require('views/scope/draft_question_view');

NextStepButton = require('views/scope/next_step_button_view');

Focusable = require('base/lib/traits/focusable');

Scrolling = require('base/lib/traits/scrolling');

template = require('views/templates/scope/moderation');

mediator = require('mediator');

module.exports = QuestionsModerationView = (function(_super) {
  __extends(QuestionsModerationView, _super);

  function QuestionsModerationView() {
    return QuestionsModerationView.__super__.constructor.apply(this, arguments);
  }

  QuestionsModerationView.prototype.className = 'questions-moderation';

  QuestionsModerationView.prototype.container = '#page-container';

  QuestionsModerationView.prototype.template = template;

  QuestionsModerationView.prototype.itemView = DraftQuestionView;

  QuestionsModerationView.prototype.listSelector = 'ol';

  QuestionsModerationView.prototype.initialize = function() {
    this.itemViewOptions = {
      preview: this.options.preview
    };
    QuestionsModerationView.__super__.initialize.apply(this, arguments);
    this.enable(Scrolling, {
      fixedElements: '.information, .bottom-bar'
    });
    this.enable(Focusable);
    this.delegate('click', 'button.add-management-question', this._addQuestion('management'));
    return this.delegate('click', 'button.add-diagnostic-question', this._addQuestion('diagnostic'));
  };

  QuestionsModerationView.prototype._addQuestion = function(type) {
    return (function(_this) {
      return function() {
        return mediator.setFocus(_this, function() {
          return _this.collection.add(new DraftQuestion({
            type: type,
            source: 'moderation'
          }));
        });
      };
    })(this);
  };

  QuestionsModerationView.prototype.getTemplateData = function() {
    return _(QuestionsModerationView.__super__.getTemplateData.apply(this, arguments)).extend({
      preview: this.options.preview
    });
  };

  QuestionsModerationView.prototype.afterRender = function() {
    QuestionsModerationView.__super__.afterRender.apply(this, arguments);
    return this.subview('next-step', new NextStepButton({
      step: 'questions.moderation',
      container: this.$('.bottom-bar'),
      getItemsToValidate: (function(_this) {
        return function() {
          return _this.getItemViews();
        };
      })(this)
    }));
  };

  return QuestionsModerationView;

})(CollectionView);
