module.exports = {
  $exports: {
    $ref: 'service'
  },
  service: {
    create: {
      module: 'lib/project_migrations/project_migration_service',
      args: [
        [
          {
            module: 'lib/project_migrations/team_members_as_separate_collection'
          }, {
            module: 'lib/project_migrations/team_members_separate_names'
          }, {
            module: 'lib/project_migrations/diagnostic_pooled_values_as_decimals'
          }, {
            module: 'lib/project_migrations/comparator_test_as_parameter'
          }, {
            module: 'lib/project_migrations/other_quality_modifiers_none_to_no'
          }, {
            module: 'lib/project_migrations/sensitivity_specificity_zero_to_null'
          }, {
            module: 'lib/project_migrations/diagnostic_question_to_pooled_source_of_data'
          }, {
            module: 'lib/project_migrations/sof_title_to_table_title'
          }, {
            module: 'lib/project_migrations/prognostic_module'
          }, {
            module: 'lib/project_migrations/administration_module'
          }, {
            module: 'lib/project_migrations/etd_renderer_data_model'
          }, {
            module: 'lib/project_migrations/presentation_submodule'
          }
        ]
      ]
    }
  }
};
