RadioInput = require './radio_input'

RadioWithLabel = React.createClass
  displayName: 'RadioWithLabel'

  render: ->
    <label>
      <RadioInput {...@props} />
      <span>{@props.label}</span>
    </label>

module.exports = RadioWithLabel
