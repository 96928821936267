var DbepPublicationNotificationView, ModalView, W, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModalView = require('base/views/modal_view');

W = require('when');

template = require('views/templates/dissemination/dbep_publication_notification');

mediator = require('mediator');

module.exports = DbepPublicationNotificationView = (function(_super) {
  __extends(DbepPublicationNotificationView, _super);

  function DbepPublicationNotificationView() {
    return DbepPublicationNotificationView.__super__.constructor.apply(this, arguments);
  }

  DbepPublicationNotificationView.prototype.className = 'dbep-publication-notification';

  DbepPublicationNotificationView.prototype.template = template;

  DbepPublicationNotificationView.prototype.initialize = function() {
    this.options.position = 'top';
    this.options.offsetY = '30%';
    this.options.width = 650;
    DbepPublicationNotificationView.__super__.initialize.apply(this, arguments);
    this._deferred = W.defer();
    this.delegate('click', '[data-role="ok"]', this._close);
    return this.delegate('click', '[data-role="cancel"]', this.dispose);
  };

  DbepPublicationNotificationView.prototype.promise = function() {
    return this._deferred.promise;
  };

  DbepPublicationNotificationView.prototype.dispose = function() {
    if (this._deferred.promise.inspect().state === 'pending') {
      this._deferred.reject('cancelled');
    }
    return DbepPublicationNotificationView.__super__.dispose.apply(this, arguments);
  };

  DbepPublicationNotificationView.prototype._close = function() {
    return this._deferred.resolve(true).then(this.dispose);
  };

  return DbepPublicationNotificationView;

})(ModalView);
