Translation = require 'components/mixins/translation'
ConclusionsSection = require 'components/etd/conclusions_section'
PureRenderMixin = React.addons.PureRenderMixin

Conclusions = React.createClass
  displayName: 'ConclusionsSectionsContainer'
  mixins: [PureRenderMixin, Translation('es:recommendations')]

  render: ->
    if @props.sections
      <table className="standard-table recommendation-table-static">
        <caption>
          <h1>{@i18n 'conclusions'}</h1>
        </caption>
        <caption>
          <h2>
            {@props.questionName}
          </h2>
        </caption>
        <tbody>
        {@props.sections.map (section, key) =>
            <ConclusionsSection
              key={key}
              sectionKey={key}
              section={section}
              currentEditable={@props.currentEditable}
            />
          .toList()
          }
        </tbody>
      </table>
    else
      null

module.exports = Conclusions
