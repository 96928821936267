var EditableItemView, ProjectSharingView, ProjectView, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

EditableItemView = require('base/views/editable_item_view');

ProjectSharingView = require('views/project_sharing_view');

template = require('views/templates/project');

mediator = require('mediator');

module.exports = ProjectView = (function(_super) {
  __extends(ProjectView, _super);

  function ProjectView() {
    return ProjectView.__super__.constructor.apply(this, arguments);
  }

  ProjectView.prototype.template = template;

  ProjectView.prototype.tagName = 'li';

  ProjectView.prototype.bindings = {
    '.caption a': 'name',
    '#name': 'name'
  };

  ProjectView.prototype.initialize = function() {
    ProjectView.__super__.initialize.apply(this, arguments);
    return this.delegate('click', 'button.share', this.shareProject);
  };

  ProjectView.prototype.shareProject = function() {
    return this.parentView.subview('projectSharing', new ProjectSharingView({
      model: this.model
    }));
  };

  ProjectView.prototype["delete"] = function() {
    return mediator.dialogs.confirm({
      yesClass: 'success',
      message: $.t('projects:delete_confirmation')
    }, (function(_this) {
      return function(confirmed) {
        if (!confirmed) {
          return;
        }
        _this._close();
        return mediator.user.moveProjectToTrash(_this.model.id);
      };
    })(this));
  };

  return ProjectView;

})(EditableItemView);
