var Model, OutcomeViewType, User, W, mediator, sequence, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Model = require('models/base/model');

mediator = require('mediator');

sequence = require('when/sequence');

OutcomeViewType = require('models/outcome/view_types');

W = require('when');

utils = require('base/lib/utils');

module.exports = User = (function(_super) {
  __extends(User, _super);

  function User() {
    this._updateStatusAndRemoveProject = __bind(this._updateStatusAndRemoveProject, this);
    return User.__super__.constructor.apply(this, arguments);
  }

  User.prototype.maxLogSize = 10;

  User.prototype.defaults = function() {
    return _.defaults({
      forceFootnotes: true,
      showDropQuestionGroupArea: true,
      settings: {
        timezone: 'auto'
      },
      tosAcceptedVersion: null,
      topAccepted: null,
      projects: [],
      narrativeInfoShownForQuestion: {}
    }, mediator.services.esView.getDefaultViewAttrs());
  };

  User.prototype._supportOldFormatOfProjects = function() {
    if (_.isString(this.get('projects')[0])) {
      return this.set('projects', _(this.get('projects')).map(function(project) {
        return {
          name: project,
          status: 'new'
        };
      }));
    }
  };

  User.prototype.initialize = function() {
    User.__super__.initialize.apply(this, arguments);
    this._supportOldFormatOfProjects();
    this.urlRoot = this.id;
    return this.on('change:projects', this._projectsChanged);
  };

  User.prototype._projectsChanged = function(user, newProjects) {
    this._duplicateProjectsIfNeeded();
    return this._replicateToCentralIfNeeded(newProjects, user.previousAttributes().projects);
  };

  User.prototype._replicateToCentralIfNeeded = function(projects, previousProjects) {
    var prevProj, project, _i, _len, _results;
    _results = [];
    for (_i = 0, _len = projects.length; _i < _len; _i++) {
      project = projects[_i];
      prevProj = _(previousProjects).findWhere({
        name: project.name
      });
      if (project.status === 'replication_on' && prevProj && prevProj.status !== 'replication_on') {
        _results.push(mediator.services.replication.sendLocalChanges([project.name]));
      } else {
        _results.push(void 0);
      }
    }
    return _results;
  };

  User.prototype._duplicateProjectsIfNeeded = function() {
    var duplicate, entry, login;
    login = this.get('name');
    duplicate = (function(_this) {
      return function(entry) {
        return function() {
          return mediator.services.projectDb.duplicate(login, entry.name).then(function(newName) {
            var projects;
            projects = _this.get('projects');
            _(projects).findWhere({
              name: entry.name
            }).status = 'broken';
            _(projects).push(_this._newProject(newName));
            return _this.save('projects', projects);
          }, function(error) {
            return typeof Raven !== "undefined" && Raven !== null ? Raven.captureException(error, {
              extra: {
                duplicated_project: entry.name
              }
            }) : void 0;
          });
        };
      };
    })(this);
    return sequence((function() {
      var _i, _len, _ref, _results;
      _ref = _(this.get('projects')).where({
        status: 'duplication_needed'
      });
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        entry = _ref[_i];
        _results.push(duplicate(entry));
      }
      return _results;
    }).call(this));
  };

  User.prototype._newProject = function(projectDbName) {
    return {
      name: projectDbName,
      status: 'new'
    };
  };

  User.prototype.addProject = function(projectDbName) {
    this.get('projects').push(this._newProject(projectDbName));
    return this.save();
  };

  User.prototype.markAsAccessRevoked = function(projectDbs) {
    return this._updateStatusAndRemoveProjects('access_revoked', projectDbs, 'projects:access_revoked', 'projects:access_revoked_many');
  };

  User.prototype.markProjectsAsDeleted = function(projectDbs) {
    return this._updateStatusAndRemoveProjects('deleted', projectDbs, 'projects:deleted', 'projects:deleted_many');
  };

  User.prototype._updateStatusAndRemoveProjects = function(newStatus, projectDbs, warningMsgKey, warningManyMsgKey) {
    return W.map(projectDbs, this._updateStatusAndRemoveProject(newStatus)).then(function(projects) {
      if (projects.length === 1) {
        return mediator.dialogs.warning($.t(warningMsgKey, {
          projectName: projects[0]
        }));
      } else if (projects.length > 1) {
        return mediator.dialogs.warning($.t(warningManyMsgKey) + '<br>' + projects.join('<br>'));
      }
    });
  };

  User.prototype._updateStatusAndRemoveProject = function(newStatus) {
    return (function(_this) {
      return function(projectDbName) {
        var projectName;
        _this._getProjectData(projectDbName).status = newStatus;
        projectName = mediator.projects.get(projectDbName).get('name');
        return _this.save().then(function() {
          return mediator.projects.removeProject(projectDbName);
        }).then(function() {
          return projectName;
        });
      };
    })(this);
  };

  User.prototype.moveProjectToTrash = function(projectDbName) {
    this._getProjectData(projectDbName).inTrash = true;
    return this.save().then(function() {
      return mediator.projects.removeProject(projectDbName);
    });
  };

  User.prototype.restoreProjectFromTrash = function(projectDbName) {
    delete this._getProjectData(projectDbName).inTrash;
    return this.save();
  };

  User.prototype.irreversiblyDestroyProjectAndAllItsData = function(projectDbName) {
    this._getProjectData(projectDbName).status = 'deleted';
    return this.save().then(function() {
      return mediator.services.projectDb.destroy(projectDbName);
    }).then(function() {
      return mediator.projects.removeProject(projectDbName);
    });
  };

  User.prototype.getReplicatedProjects = function() {
    return _(this.get('projects')).chain().where({
      status: 'replication_on'
    }).pluck('name').value();
  };

  User.prototype.getProjectsFromTrash = function() {
    var inTrash;
    inTrash = _(this.get('projects')).chain().where({
      inTrash: true
    });
    return inTrash.reject(function(item) {
      return item.status === 'deleted';
    }).pluck('name').value();
  };

  User.prototype._getProjectsData = function() {
    return _(this.get('projects')).reject(function(p) {
      var _ref;
      return ((_ref = p.status) === 'deleted' || _ref === 'broken' || _ref === 'access_revoked') || p.inTrash;
    });
  };

  User.prototype.getProjects = function() {
    return _(this._getProjectsData()).pluck('name');
  };

  User.prototype.getProjectsWithOrderNumbers = function() {
    var projectsWithOrderNumbers;
    projectsWithOrderNumbers = {};
    _(this._getProjectsData()).each(function(p) {
      return projectsWithOrderNumbers[p.name] = p.orderNumber;
    });
    return projectsWithOrderNumbers;
  };

  User.prototype.getProjectsMaxOrderNumber = function() {
    var ns;
    ns = _(this._getProjectsData()).chain().pluck('orderNumber').filter(function(n) {
      return _.isNumber(n);
    }).value();
    ns.push(0);
    return _.max(ns);
  };

  User.prototype._getProjectData = function(projectId) {
    return _(this.get('projects')).findWhere({
      name: projectId
    });
  };

  User.prototype.setProjectOrderNumber = function(projectId, orderNumber) {
    return this._getProjectData(projectId).orderNumber = orderNumber;
  };

  User.prototype.getProjectOrderNumber = function(projectId) {
    var projectData;
    projectData = this._getProjectData(projectId);
    if (!projectData) {
      return null;
    }
    return projectData.orderNumber;
  };

  User.prototype._narrativeInfoForQuestionKey = function(question) {
    return "" + mediator.project.id + "/" + question.id;
  };

  User.prototype.wasNarrativeInfoShownForQuestion = function(question) {
    return this.get('narrativeInfoShownForQuestion')[this._narrativeInfoForQuestionKey(question)];
  };

  User.prototype.narrativeInfoShownForQuestion = function(question) {
    var infos;
    infos = this.get('narrativeInfoShownForQuestion');
    infos[this._narrativeInfoForQuestionKey(question)] = true;
    this.set('narrativeInfoShownForQuestion', infos);
    return this.save().otherwise(mediator.dialogs.fatalError);
  };

  User.prototype.getRequests = function() {
    return this.requests;
  };

  User.prototype.getSessions = function() {
    return this.sessions;
  };

  User.prototype.getShareProjects = function() {
    return this.shareProjects;
  };

  User.prototype.addSessionInfo = function(loginStatus) {
    var log, timestampLogin, _ref;
    log = (_ref = this.sessions.get('log')) != null ? _ref : {};
    timestampLogin = {};
    timestampLogin[mediator.services.clock.getTimestamp()] = {
      status: loginStatus,
      platformInfo: platform.description
    };
    utils.addToObjectWithMaxSize(log, this.maxLogSize, timestampLogin, function(a, b) {
      return parseInt(a) - parseInt(b);
    });
    return this.sessions.save({
      log: log
    });
  };

  return User;

})(Model);
