var consoleGroup;

consoleGroup = require('when/monitor/logger/consoleGroup');

module.exports = function(rejections) {
  var rejection, _i, _len, _results;
  consoleGroup(rejections);
  if (typeof Raven === "undefined" || Raven === null) {
    return;
  }
  _results = [];
  for (_i = 0, _len = rejections.length; _i < _len; _i++) {
    rejection = rejections[_i];
    _results.push(Raven.captureException(rejection.reason));
  }
  return _results;
};
