var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "  <div class=\"content\">\n";
  stack1 = ((helpers.inStringArray || (depth0 && depth0.inStringArray) || helperMissing).call(depth0, "PrintoutSOF", (depth0 != null ? depth0.viewTypeName : depth0), {"name":"inStringArray","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.viewTypeName : depth0), "PrintoutOneRow", {"name":"is","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.inStringArray || (depth0 && depth0.inStringArray) || helperMissing).call(depth0, "PrintoutGRADEACCP, PrintoutSOFACCP", (depth0 != null ? depth0.viewTypeName : depth0), {"name":"inStringArray","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.viewTypeName : depth0), "PrintoutSOFv3", {"name":"is","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.viewTypeName : depth0), "PrintoutPrognosis", {"name":"is","hash":{},"fn":this.program(13, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n  </div>\n";
},"2":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0['short'] : depth0), {"name":"unless","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "        <div>\n          <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.printout.patient_or_population", {"name":"i18n","hash":{},"data":data})))
    + "</b>:\n          <label id=\"healthProblemOrPopulation\">\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.questionFormat : depth0), "FOR_HP", {"name":"is","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            "
    + escapeExpression(((helper = (helper = helpers.healthProblemOrPopulation || (depth0 != null ? depth0.healthProblemOrPopulation : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"healthProblemOrPopulation","hash":{},"data":data}) : helper)))
    + "\n          </label>\n          <span class=\"sup\"></span>\n        </div>\n        <div>\n          <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:question.setting", {"name":"i18n","hash":{},"data":data})))
    + "</b>:\n          <label for=\"settings\">"
    + escapeExpression(((helper = (helper = helpers.settings || (depth0 != null ? depth0.settings : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"settings","hash":{},"data":data}) : helper)))
    + "</label>\n          <span class=\"sup\"></span>\n        </div>\n        <div>\n          <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.intervention", {"name":"i18n","hash":{},"data":data})))
    + "</b>:\n          <label id=\"intervention\">"
    + escapeExpression(((helper = (helper = helpers.intervention || (depth0 != null ? depth0.intervention : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"intervention","hash":{},"data":data}) : helper)))
    + "</label>\n        </div>\n        <div>\n          <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.comparison", {"name":"i18n","hash":{},"data":data})))
    + "</b>:\n          <label id=\"comparison\">"
    + escapeExpression(((helper = (helper = helpers.comparison || (depth0 != null ? depth0.comparison : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparison","hash":{},"data":data}) : helper)))
    + "</label>\n        </div>\n";
},"4":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "              "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.printout.patients_with", {"name":"i18n","hash":{},"data":data})))
    + "\n";
},"6":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "      <div>\n        <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:question.authors", {"name":"i18n","hash":{},"data":data})))
    + "</b>: <label for=\"authors\">"
    + escapeExpression(((helper = (helper = helpers.authors || (depth0 != null ? depth0.authors : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"authors","hash":{},"data":data}) : helper)))
    + "</label>\n        <span class=\"sup\"></span>\n      </div>\n      <div>\n        <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:question.date", {"name":"i18n","hash":{},"data":data})))
    + "</b>: "
    + escapeExpression(((helper = (helper = helpers.date || (depth0 != null ? depth0.date : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"date","hash":{},"data":data}) : helper)))
    + "\n      </div>\n      <div>\n        <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:question.question", {"name":"i18n","hash":{},"data":data})))
    + "</b>:\n        <label for=\"question\">"
    + escapeExpression(((helper = (helper = helpers.tableTitle || (depth0 != null ? depth0.tableTitle : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"tableTitle","hash":{},"data":data}) : helper)))
    + "</label>\n        <span class=\"sup\"></span>\n      </div>\n      <div>\n        <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:question.setting", {"name":"i18n","hash":{},"data":data})))
    + "</b>: <label for=\"settings\">"
    + escapeExpression(((helper = (helper = helpers.settings || (depth0 != null ? depth0.settings : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"settings","hash":{},"data":data}) : helper)))
    + "</label>\n        <span class=\"sup\"></span>\n      </div>\n      <div>\n        <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:question.bibliography", {"name":"i18n","hash":{},"data":data})))
    + "</b>:\n        <label for=\"bibliography\">"
    + escapeExpression(((helper = (helper = helpers.bibliography || (depth0 != null ? depth0.bibliography : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"bibliography","hash":{},"data":data}) : helper)))
    + "</label><span class=\"sup\"></span>\n      </div>\n";
},"8":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "      <div>\n        <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:question.bibliography", {"name":"i18n","hash":{},"data":data})))
    + "</b>:\n        <label for=\"bibliography\">"
    + escapeExpression(((helper = (helper = helpers.bibliography || (depth0 != null ? depth0.bibliography : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"bibliography","hash":{},"data":data}) : helper)))
    + "</label><span class=\"sup\"></span><br>\n      </div>\n";
},"10":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "      <div>\n        <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.printout.patient_or_population", {"name":"i18n","hash":{},"data":data})))
    + "</b>:\n        <label id=\"healthProblemOrPopulation\">\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.questionFormat : depth0), "FOR_HP", {"name":"is","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "          "
    + escapeExpression(((helper = (helper = helpers.healthProblemOrPopulation || (depth0 != null ? depth0.healthProblemOrPopulation : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"healthProblemOrPopulation","hash":{},"data":data}) : helper)))
    + "\n        </label>\n        <span class=\"sup\"></span>\n      </div>\n      <div>\n        <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:question.setting", {"name":"i18n","hash":{},"data":data})))
    + "</b>: <label for=\"settings\">"
    + escapeExpression(((helper = (helper = helpers.settings || (depth0 != null ? depth0.settings : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"settings","hash":{},"data":data}) : helper)))
    + "</label>\n        <span class=\"sup\"></span><br>\n      </div>\n      <div>\n        <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.intervention", {"name":"i18n","hash":{},"data":data})))
    + "</b>:\n        <label id=\"intervention\">"
    + escapeExpression(((helper = (helper = helpers.intervention || (depth0 != null ? depth0.intervention : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"intervention","hash":{},"data":data}) : helper)))
    + "</label>\n      </div>\n      <div>\n        <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.comparison", {"name":"i18n","hash":{},"data":data})))
    + "</b>:\n        <label id=\"comparison\">"
    + escapeExpression(((helper = (helper = helpers.comparison || (depth0 != null ? depth0.comparison : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparison","hash":{},"data":data}) : helper)))
    + "</label><br>\n      </div>\n";
},"11":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.printout.patients_with", {"name":"i18n","hash":{},"data":data})))
    + "\n";
},"13":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "      <div class=\"title\">\n        <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:question.question", {"name":"i18n","hash":{},"data":data})))
    + "</b>: <label for=\"question\">"
    + escapeExpression(((helper = (helper = helpers.tableTitle || (depth0 != null ? depth0.tableTitle : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"tableTitle","hash":{},"data":data}) : helper)))
    + "</label>\n        <span class=\"sup\"></span>\n      </div>\n      <div class=\"setting\">\n        <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:question.setting", {"name":"i18n","hash":{},"data":data})))
    + "</b>: <label for=\"settings\">"
    + escapeExpression(((helper = (helper = helpers.settings || (depth0 != null ? depth0.settings : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"settings","hash":{},"data":data}) : helper)))
    + "</label>\n        <span class=\"sup\"></span>\n      </div>\n      <div class=\"bibliography\">\n        <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:question.bibliography", {"name":"i18n","hash":{},"data":data})))
    + "</b>:\n        <label for=\"bibliography\">"
    + escapeExpression(((helper = (helper = helpers.bibliography || (depth0 != null ? depth0.bibliography : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"bibliography","hash":{},"data":data}) : helper)))
    + "</label>\n        <span class=\"sup\"></span>\n      </div>\n      <div class=\"authors\">\n        <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:question.authors", {"name":"i18n","hash":{},"data":data})))
    + "</b>: <label for=\"authors\">"
    + escapeExpression(((helper = (helper = helpers.authors || (depth0 != null ? depth0.authors : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"authors","hash":{},"data":data}) : helper)))
    + "</label>\n        <span class=\"sup\"></span>\n      </div>\n";
},"15":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:question", {"name":"i18nNamespace","hash":{},"fn":this.program(16, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"16":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.editing : depth0), {"name":"if","hash":{},"fn":this.program(17, data),"inverse":this.program(32, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"17":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "    <div class=\"side-buttons\">\n      <button class=\"save\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/actions.save_changes_title", {"name":"i18n","hash":{},"data":data})))
    + "'></button>\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.tableTitle : depth0), {"name":"unless","hash":{},"fn":this.program(18, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </div>\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "diagnostic", {"name":"is","hash":{},"fn":this.program(20, data),"inverse":this.program(22, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "    <div class=\"details\">\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "diagnostic", {"name":"is","hash":{},"fn":this.program(27, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "      <fieldset class=\"row form-group bibliography\">\n        <label for=\"bibliography\" class=\"col-2\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "bibliography", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n        <div class=\"col-10\">\n          <textarea name=\"bibliography\" id=\"bibliography\" class=\"form-control\">\n            "
    + escapeExpression(((helper = (helper = helpers.bibliography || (depth0 != null ? depth0.bibliography : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"bibliography","hash":{},"data":data}) : helper)))
    + "\n          </textarea>\n        </div>\n        <span class=\"sup\"></span>\n      </fieldset>\n      <fieldset class=\"row form-group authors\">\n        <label for=\"authors\" class=\"col-2\">\n          <span class=\"question-mark-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "authors_info", {"name":"i18n","hash":{},"data":data})))
    + "\"></span>\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "question_authors", {"name":"i18n","hash":{},"data":data})))
    + "\n        </label>\n        <div class=\"col-10\">\n          <input name=\"authors\" id=\"authors\" class=\"form-control\"/>\n        </div>\n      <span class=\"sup\"></span>\n      </fieldset>\n      <div class=\"row\">\n        <div class=\"col-12 text-right\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.lastUpdate : depth0), {"name":"if","hash":{},"fn":this.program(30, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </div>\n      </div>\n    </div>\n";
},"18":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          <button class=\"duplicate large\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/actions.duplicate", {"name":"i18n","hash":{},"data":data})))
    + "'/>\n          <button class=\"delete\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "delete", {"name":"i18n","hash":{},"data":data})))
    + "'></button>\n";
},"20":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <div class=\"separated diagnostic\">"
    + escapeExpression(((helper = (helper = helpers.diagnosticQuestionHeader || (depth0 != null ? depth0.diagnosticQuestionHeader : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"diagnosticQuestionHeader","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"22":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "prognostic", {"name":"is","hash":{},"fn":this.program(23, data),"inverse":this.program(25, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"23":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          <div class=\"separated\">"
    + escapeExpression(((helper = (helper = helpers.prognosticQuestionHeader || (depth0 != null ? depth0.prognosticQuestionHeader : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prognosticQuestionHeader","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"25":function(depth0,helpers,partials,data) {
  return "          <div class=\"rand-question row\">\n            <div class=\"col-12\">\n              <input type=\"text\" name=\"question\" id=\"question\">\n            </div>\n          </div>\n";
  },"27":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "          <fieldset class=\"row form-group edit-input-block index-test-cut-off\">\n            <label for=\"indexTestCutOff\" class=\"col-2\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "index_test_cut_off", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n            <div class=\"col-10\">\n              <input type=\"text\" name=\"indexTestCutOff\" id=\"indexTestCutOff\" class=\"form-control\">\n            </div>\n            <span class=\"sup\"></span>\n          </fieldset>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.comparatorTestPresent : depth0), {"name":"if","hash":{},"fn":this.program(28, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "          <fieldset class=\"row form-group edit-input-block reference-test\">\n            <label for=\"referenceTest\" class=\"col-2\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "reference_test", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n            <div class=\"col-10\">\n              <textarea name=\"referenceTest\" id=\"referenceTest\" class=\"form-control\"></textarea>\n            </div>\n            <span class=\"sup\"></span>\n          </fieldset>\n          <fieldset class=\"row form-group edit-input-block reference-test-short-name\">\n            <label for=\"referenceTestShortName\" class=\"col-2\">\n              <span class=\"question-mark-sign\"\n                title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "reference_test_short_name_info", {"name":"i18n","hash":{},"data":data})))
    + "\"></span>\n                "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "reference_test_short_name", {"name":"i18n","hash":{},"data":data})))
    + "\n            </label>\n            <div class=\"col-10\">\n              <input type=\"text\" name=\"referenceTestShortName\" id=\"referenceTestShortName\"\n                class=\"form-control\">\n            </div>\n            <span class=\"sup\"></span>\n          </fieldset>\n          <fieldset class=\"row form-group edit-input-block reference-test-threshold\">\n            <label for=\"referenceTestThreshold\" class=\"col-2\">\n              "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "reference_test_threshold", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n            <div class=\"col-10\">\n              <input type=\"text\" name=\"referenceTestThreshold\" id=\"referenceTestThreshold\"\n                class=\"form-control\">\n            </div>\n            <span class=\"sup\"></span>\n          </fieldset>\n";
},"28":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "              <fieldset class=\"row form-group edit-input-block comparator-test-cut-off\">\n                <label for=\"comparatorTestCutOff\" class=\"col-2\">\n                  "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "comparator_test_cut_off", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n                <div class=\"col-10\">\n                  <input type=\"text\" name=\"comparatorTestCutOff\" id=\"comparatorTestCutOff\"\n                    class=\"form-control\">\n                </div>\n                <span class=\"sup\"></span>\n              </fieldset>\n";
},"30":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <div class=\"last-update\">\n                "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "last_update", {"name":"i18n","hash":{},"data":data})))
    + ":&nbsp;"
    + escapeExpression(((helpers.displayDate || (depth0 && depth0.displayDate) || helperMissing).call(depth0, (depth0 != null ? depth0.lastUpdate : depth0), {"name":"displayDate","hash":{},"data":data})))
    + "\n            </div>\n";
},"32":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    <button class=\"edit\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "edit", {"name":"i18n","hash":{},"data":data})))
    + "'></button>\n    <div class='caption'>\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.tableTitle : depth0), {"name":"unless","hash":{},"fn":this.program(33, data),"inverse":this.program(38, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      <span class=\"question-footnotes-attr\"></span>\n    </div>\n";
},"33":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "prognostic", {"name":"is","hash":{},"fn":this.program(34, data),"inverse":this.program(36, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"34":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "          <a href='"
    + escapeExpression(((helpers.projectRelUrl || (depth0 && depth0.projectRelUrl) || helperMissing).call(depth0, "/prognosis", {"name":"projectRelUrl","hash":{},"data":data})))
    + "/"
    + escapeExpression(((helper = (helper = helpers._id || (depth0 != null ? depth0._id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"_id","hash":{},"data":data}) : helper)))
    + "'></a>\n";
},"36":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "          <a href='"
    + escapeExpression(((helpers.projectRelUrl || (depth0 && depth0.projectRelUrl) || helperMissing).call(depth0, "/evidence-syntheses", {"name":"projectRelUrl","hash":{},"data":data})))
    + "/"
    + escapeExpression(((helper = (helper = helpers._id || (depth0 != null ? depth0._id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"_id","hash":{},"data":data}) : helper)))
    + "'></a>\n";
},"38":function(depth0,helpers,partials,data) {
  return "          <a></a>\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isPrintout : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(15, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}