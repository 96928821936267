var AdaptiveLayout, ChangelogView, HandbookView, LearnView, ModalView, NewProjectView, NewUserView, ProjectsListView, StartNewProjectView, TabsView, UserGuideView, View, WelcomeCapabilitiesListView, WelcomeView, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

ModalView = require('base/views/modal_view');

AdaptiveLayout = require('base/lib/traits/adaptive_layout');

ChangelogView = require('./changelog_view');

TabsView = require('views/tabs_view');

NewProjectView = require('views/new_project_view');

HandbookView = require('views/handbook_view');

UserGuideView = require('views/user_guide_view');

WelcomeCapabilitiesListView = require('views/welcome_capabilities_view');

NewUserView = require('views/new_user_view');

template = require('./templates/welcome');

mediator = require('mediator');

ProjectsListView = require('views/projects_list_view');

module.exports = WelcomeView = (function(_super) {
  __extends(WelcomeView, _super);

  function WelcomeView() {
    return WelcomeView.__super__.constructor.apply(this, arguments);
  }

  WelcomeView.prototype.container = '#page-container';

  WelcomeView.prototype.autoRender = true;

  WelcomeView.prototype.className = 'welcome-view';

  WelcomeView.prototype.template = template;

  WelcomeView.prototype.initialize = function() {
    var newProjectsTabsView, tabsView;
    WelcomeView.__super__.initialize.apply(this, arguments);
    mediator.publish('projectChanged', null);
    this.enable(AdaptiveLayout);
    this.delegate('click', 'button.continue', (function(_this) {
      return function() {
        var lastRoute;
        lastRoute = mediator.user.getSessions().getLastRoute();
        if (lastRoute) {
          return _this.publishEvent('matchRoute', lastRoute.route, lastRoute.params, lastRoute.options);
        } else {
          return _this.publishEvent('!router:route', '/projects');
        }
      };
    })(this));
    tabsView = new TabsView({
      container: '.tabs-container',
      tabViews: [
        {
          view: ChangelogView,
          title: $.t('projects:welcome.my_projects')
        }, {
          view: StartNewProjectView,
          title: $.t('projects:welcome.start_new')
        }, {
          view: LearnView,
          title: $.t('projects:welcome.learn')
        }
      ]
    });
    newProjectsTabsView = new TabsView({
      container: '.tabs-container',
      tabViews: [
        {
          view: ProjectsListView,
          title: $.t('projects:welcome.active_projects'),
          options: {
            isActive: true,
            isMenuMode: false,
            collection: this.collection
          }
        }, {
          view: StartNewProjectView,
          title: $.t('projects:welcome.start_new')
        }, {
          view: ProjectsListView,
          title: $.t('projects:welcome.archived_projects'),
          options: {
            isActive: false,
            isMenuMode: false,
            collection: this.options.archivedProjects
          }
        }
      ]
    });
    return this.subview('tabs', mediator.services.switches.isOn('new_projects_list') ? newProjectsTabsView : tabsView);
  };

  WelcomeView.prototype.afterRender = function() {
    WelcomeView.__super__.afterRender.apply(this, arguments);
    $('.outer-container').addClass('welcome-screen-container');
    this.subview('tabs').render();
    return $('#topbar').css('visibility', 'hidden');
  };

  WelcomeView.prototype.dispose = function() {
    $('.outer-container').removeClass('welcome-screen-container');
    $('#topbar').css('visibility', 'inherit');
    return WelcomeView.__super__.dispose.apply(this, arguments);
  };

  return WelcomeView;

})(View);

StartNewProjectView = (function(_super) {
  __extends(StartNewProjectView, _super);

  function StartNewProjectView() {
    return StartNewProjectView.__super__.constructor.apply(this, arguments);
  }

  StartNewProjectView.prototype.template = require('./templates/welcome_start_new');

  StartNewProjectView.prototype.className = 'welcome-start-new';

  StartNewProjectView.prototype.initialize = function() {
    StartNewProjectView.__super__.initialize.apply(this, arguments);
    return this.delegate('click', '.project-types button', (function(_this) {
      return function(e) {
        var projectType;
        projectType = $(e.target).data('type');
        return _this.subview('newProject', new NewProjectView({
          projectType: projectType
        }));
      };
    })(this));
  };

  StartNewProjectView.prototype.getTemplateData = function() {
    return {
      evidenceTableTypes: ['grade_ep', 'sof_table', 'etd'],
      guidelineTypes: ['full_guideline']
    };
  };

  return StartNewProjectView;

})(View);

LearnView = (function(_super) {
  __extends(LearnView, _super);

  function LearnView() {
    return LearnView.__super__.constructor.apply(this, arguments);
  }

  LearnView.prototype.template = require('./templates/welcome_learn');

  LearnView.prototype.className = 'welcome-learn-view';

  LearnView.prototype.initialize = function() {
    LearnView.__super__.initialize.apply(this, arguments);
    this.delegate('click', 'button.tutorials', function() {
      return window.open($.t('projects:welcome.learn_section.tutorials_homepage'), '_blank');
    });
    this.delegate('click', 'button.guide', (function(_this) {
      return function() {
        return _this.subview('userGuide', new UserGuideView());
      };
    })(this));
    this.delegate('click', 'button.handbook', (function(_this) {
      return function() {
        return _this.subview('handbook', new HandbookView());
      };
    })(this));
    return this.delegate('click', 'button.diagram', function() {
      var maxHeight;
      maxHeight = $(document).height() - 150;
      return mediator.dialogs.infoBox(null, {
        title: $.t('projects:welcome.learn_section.diagram'),
        message: "<img src='images/process-diagram.png' style='max-height: " + maxHeight + "px'>",
        position: 'top',
        offsetY: 0,
        hideOnClick: false
      });
    });
  };

  LearnView.prototype.afterRender = function() {
    LearnView.__super__.afterRender.apply(this, arguments);
    this.subview('capabilities', new WelcomeCapabilitiesListView({
      container: this.$('#capabilities')
    }));
    return this.subview('capabilities').render();
  };

  return LearnView;

})(View);
