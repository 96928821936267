var EvidenceSynthesesController, GdtRouter, ModuleController, PresentationsWithToolbarView, QuestionView, QuestionWithOutcomesView, QuestionsView, RecommendationsWithToolbarView, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModuleController = require('controllers/base/module_controller');

QuestionsView = require('views/evidence_syntheses/questions_view');

QuestionView = require('views/evidence_syntheses/question_view');

QuestionWithOutcomesView = require('views/evidence_syntheses/question_with_outcomes_view');

RecommendationsWithToolbarView = require('views/evidence_syntheses/recommendations_with_toolbar_view');

PresentationsWithToolbarView = require('views/evidence_syntheses/presentations_with_toolbar_view');

GdtRouter = require('router');

mediator = require('mediator');

module.exports = EvidenceSynthesesController = (function(_super) {
  __extends(EvidenceSynthesesController, _super);

  function EvidenceSynthesesController() {
    return EvidenceSynthesesController.__super__.constructor.apply(this, arguments);
  }

  EvidenceSynthesesController.prototype.defaultSubmodule = 'recommendations';

  EvidenceSynthesesController.prototype.viewsForSubmodules = {
    'quality-of-evidence': QuestionWithOutcomesView,
    'recommendations': RecommendationsWithToolbarView,
    'presentations': PresentationsWithToolbarView
  };

  EvidenceSynthesesController.prototype.index = function(params) {
    this.view = new QuestionsView({
      moduleName: this.moduleName,
      baseUrl: this.historyURL(params),
      collection: mediator.questions,
      groupsPromise: mediator.project.getDocFor('comparisons_question_groups')
    });
    return mediator.publish('questionChanged', null);
  };

  EvidenceSynthesesController.prototype.question = function(params) {
    if (mediator.questions.get(params.id)) {
      return this.redirectTo(GdtRouter.prototype.routeForModule(this.moduleName, params) + ("/" + params.id + "/" + this.defaultSubmodule));
    } else {
      return this.redirectTo(GdtRouter.prototype.routeForModule(this.moduleName, params));
    }
  };

  EvidenceSynthesesController.prototype._viewParams = function(params) {
    var question;
    question = mediator.questions.get(params.id);
    mediator.publish('questionChanged', question.id);
    return {
      model: question
    };
  };

  EvidenceSynthesesController.prototype._submoduleNotFound = function(params) {
    EvidenceSynthesesController.__super__._submoduleNotFound.apply(this, arguments);
    return this.question(params);
  };

  return EvidenceSynthesesController;

})(ModuleController);
