var CoiInputView, CoiMemberView, View, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

template = require('views/templates/team/coi_member');

CoiInputView = require('views/team/coi_input_view');

mediator = require('mediator');

module.exports = CoiMemberView = (function(_super) {
  __extends(CoiMemberView, _super);

  function CoiMemberView() {
    return CoiMemberView.__super__.constructor.apply(this, arguments);
  }

  CoiMemberView.prototype.template = template;

  CoiMemberView.prototype.tagName = 'li';

  CoiMemberView.prototype.initialize = function() {
    CoiMemberView.__super__.initialize.apply(this, arguments);
    return this.delegate('click', '.member-name', this.showCoiInputView);
  };

  CoiMemberView.prototype.showCoiInputView = function() {
    if (this.model.getCoiFormStatus() === 'completed') {
      return this.subview('inputView', new CoiInputView({
        collection: mediator.questions,
        member: this.model,
        coiFormType: mediator.project.get('coiFormType')
      }));
    } else if (this.model.getCoiFormStatus() === 'connection-error') {
      return mediator.dialogs.warning($.t('team:coi.form_connection_error'));
    } else {
      return mediator.dialogs.warning($.t('team:coi.form_not_completed'));
    }
  };

  CoiMemberView.prototype.bindings = function() {
    return {
      '.member-name': {
        observe: ['givenNames', 'lastName'],
        onGet: function(values) {
          return "" + values[0] + " " + values[1];
        },
        attributes: [
          {
            name: 'title',
            observe: 'coiFormStatus',
            onGet: (function(_this) {
              return function(val) {
                return $.t("team:coi.status." + (_this.model.getCoiFormStatus()));
              };
            })(this)
          }
        ]
      },
      '.member-status': {
        attributes: [
          {
            name: 'class',
            observe: 'coiFormStatus',
            onGet: (function(_this) {
              return function(val) {
                return _this.model.getCoiFormStatus();
              };
            })(this)
          }
        ]
      }
    };
  };

  CoiMemberView.prototype.render = function() {
    CoiMemberView.__super__.render.apply(this, arguments);
    this.stickit(this.model, this.bindings());
    return this._addTooltips('info', this.$('.member-name'));
  };

  CoiMemberView.prototype.dispose = function() {
    this.unstickit(this.model);
    return CoiMemberView.__super__.dispose.apply(this, arguments);
  };

  return CoiMemberView;

})(View);
