var CellFootnotesView, Ellipsis, EvidenceSynthesesToolbarView, Focusable, HelpBoxView, HelpResources, HtmlExport, OutcomeViewType, QuestionExportView, Scrolling, SelectionView, View, mediator, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

OutcomeViewType = require('models/outcome/view_types');

CellFootnotesView = require('views/evidence_syntheses/cell_footnotes_view');

SelectionView = require('base/views/selection_view');

Scrolling = require('base/lib/traits/scrolling');

Ellipsis = require('base/lib/traits/ellipsis');

Focusable = require('base/lib/traits/focusable');

template = require('views/templates/evidence_syntheses_toolbar');

mediator = require('mediator');

HelpBoxView = require('views/help_box_view');

HelpResources = require('lib/help_resources');

HtmlExport = require('lib/html_export');

QuestionExportView = require('views/question_export_view');

module.exports = EvidenceSynthesesToolbarView = (function(_super) {
  __extends(EvidenceSynthesesToolbarView, _super);

  function EvidenceSynthesesToolbarView() {
    this.hideViewsListBox = __bind(this.hideViewsListBox, this);
    this.changeOutcomeView = __bind(this.changeOutcomeView, this);
    this.showContextHelp = __bind(this.showContextHelp, this);
    this.showFootnotesBox = __bind(this.showFootnotesBox, this);
    this.deactivatedProperty = __bind(this.deactivatedProperty, this);
    this.activatedProperty = __bind(this.activatedProperty, this);
    return EvidenceSynthesesToolbarView.__super__.constructor.apply(this, arguments);
  }

  EvidenceSynthesesToolbarView.prototype.template = template;

  EvidenceSynthesesToolbarView.prototype.container = '#toolbar-container';

  EvidenceSynthesesToolbarView.prototype.className = 'evidence-syntheses-toolbar';

  EvidenceSynthesesToolbarView.prototype.autoRender = true;

  EvidenceSynthesesToolbarView.prototype.listenToParentKeyEvents = true;

  EvidenceSynthesesToolbarView.prototype.shortcuts = {
    'alt+v': 'showViewsList'
  };

  EvidenceSynthesesToolbarView.prototype.initialize = function() {
    EvidenceSynthesesToolbarView.__super__.initialize.apply(this, arguments);
    this.listItems = this.getListItems();
    this.exportListItems = [
      {
        value: 'ToRevMan',
        text: $.t('toolbar.export_question.to_rev_man')
      }, {
        value: 'ToHtml',
        text: $.t('toolbar.export_question.to_html')
      }
    ];
    this.delegate('click', 'button.outcome-view', this.showViewsList);
    this.viewType = this.options.viewType;
    this.delegate('click', '.export', this.exportTable);
    this.delegate('click', '.questions > a', this.questionsListClicked);
    this.delegate('click', 'button.footnotes', this.showFootnotesBox);
    this.delegate('click', 'button.context-help', this.showContextHelp);
    this.questions = this.options.moduleName === 'prognosis' ? mediator.prognosticQuestions : mediator.questions;
    this.menuOpened = false;
    this.enable(Ellipsis);
    this.enable(Focusable);
    this.subscribeEvent('activatedProperty', this.activatedProperty);
    this.subscribeEvent('deactivatedProperty', this.deactivatedProperty);
    return mediator.questions.on('add remove sync', this.render);
  };

  EvidenceSynthesesToolbarView.prototype.activatedProperty = function(property, activeElement, question, outcome, editView) {
    if (editView == null) {
      editView = false;
    }
    this.activeProperty = property;
    this.activeElement = activeElement;
    this.activeQuestion = question;
    this.activeOutcome = outcome;
    this.isEditView = editView;
    return this._toggleContextMenu();
  };

  EvidenceSynthesesToolbarView.prototype.deactivatedProperty = function() {
    this.activeProperty = this.activeElement = this.activeQuestion = this.activeOutcome = null;
    return this._toggleContextMenu();
  };

  EvidenceSynthesesToolbarView.prototype._toggleContextMenu = function() {
    var $menuButtons, modelType;
    $menuButtons = this.$el.find('menu[type="toolbar"].context-buttons');
    modelType = this.activeOutcome != null ? 'outcome' : 'question';
    if (this.activeProperty) {
      $menuButtons.find('button.footnotes').prop('disabled', !(this.isEditView || CellFootnotesView.prototype.footnotesEnabled(modelType, this.activeProperty)));
      return $menuButtons.find('button.context-help').prop('disabled', !HelpResources.prototype.helpEnabled(this.activeProperty));
    } else {
      return this.$el.find('menu[type="toolbar"].context-buttons button').prop('disabled', true);
    }
  };

  EvidenceSynthesesToolbarView.prototype.showFootnotesBox = function(e) {
    var footnotesView, footnotesViewOptions, _ref;
    if (!this.activeProperty || mediator.currentFocus instanceof CellFootnotesView) {
      return;
    }
    footnotesView = new CellFootnotesView({
      questionModel: this.activeQuestion,
      footnotesBindingModel: (_ref = this.activeOutcome) != null ? _ref : this.activeQuestion,
      property: this.activeProperty,
      propertyView: mediator.currentFocus
    });
    footnotesViewOptions = {
      container: $('#page-container'),
      cell: this.activeElement
    };
    if (this.options.parentView) {
      return this.options.parentView.showFootnotesSubview(footnotesView, footnotesViewOptions);
    } else {
      this.subview('footnotes', footnotesView);
      return footnotesView.show(footnotesViewOptions);
    }
  };

  EvidenceSynthesesToolbarView.prototype.showContextHelp = function() {
    var helpBoxView;
    helpBoxView = new HelpBoxView({
      parentView: mediator.currentFocus,
      property: this.activeProperty
    });
    this.subview('helpBoxView', helpBoxView);
    return helpBoxView.show(this.activeElement.offset());
  };

  EvidenceSynthesesToolbarView.prototype.getListItems = function() {
    var _ref;
    if (this.options.submodule === 'recommendations') {
      return [
        {
          value: 'initial',
          text: $.t('es:recommendations.view_type.initial')
        }, {
          value: 'static',
          text: $.t('es:recommendations.view_type.static')
        }
      ];
    } else if ((_ref = this.model) != null ? _ref.isDiagnostic() : void 0) {
      return [
        {
          value: 'LayerOne',
          text: $.t('es:outcome_view_type.layer_one')
        }, {
          value: 'LayerTwo',
          text: $.t('es:outcome_view_type.layer_two')
        }, {
          value: 'LayerOneSof',
          text: $.t('es:outcome_view_type.layer_one_sof')
        }, {
          value: 'LayerTwoSof',
          text: $.t('es:outcome_view_type.layer_two_sof')
        }, {
          value: 'Dx_iSoF',
          text: $.t('es:outcome_view_type.isof')
        }
      ];
    } else {
      return [
        {
          value: 'OneRow',
          text: $.t('es:outcome_view_type.grade_one_row')
        }, {
          value: 'SOF',
          text: $.t('es:outcome_view_type.sof_table')
        }, {
          value: 'ACCP',
          text: $.t('es:outcome_view_type.grade_v2')
        }, {
          value: 'SOF_ACCP',
          text: $.t('es:outcome_view_type.sof_table_v2')
        }, {
          value: 'SOF_v3',
          text: $.t('es:outcome_view_type.sof_table_v3')
        }
      ];
    }
  };

  EvidenceSynthesesToolbarView.prototype.afterRender = function() {
    var _ref, _ref1;
    EvidenceSynthesesToolbarView.__super__.afterRender.apply(this, arguments);
    if ((_ref = this.model) != null ? _ref.isPrognostic() : void 0) {
      this.$('button.outcome-view').prop('disabled', true);
    }
    if ((_ref1 = this.model) != null ? _ref1.get('switchableRecommendationsView') : void 0) {
      this.$('button.outcome-view').prop('disabled', false);
    }
    if (_.result(this.options, 'disableExportButton')) {
      this.$('button.export').prop('disabled', true);
    }
    return this._toggleContextMenu();
  };

  EvidenceSynthesesToolbarView.prototype.questionsListClicked = function() {
    this.menuOpened = !this.menuOpened;
    this.$('.questions').toggleClass('opened', this.menuOpened);
    if (this.menuOpened) {
      mediator.setFocus(this);
      if (!this.scrollingEnabled) {
        this.scrollingEnabled = this.enable(Scrolling, {
          content: 'ol'
        });
        this.off('addedToDOM');
      }
      this.fixLayout();
    } else {
      mediator.setFocus();
    }
    return false;
  };

  EvidenceSynthesesToolbarView.prototype.onFocusLost = function() {
    if (this.disposed) {
      return;
    }
    return this.$('.questions').removeClass('opened');
  };

  EvidenceSynthesesToolbarView.prototype.exportTable = function() {
    var view;
    return view = this.subview('questionExportView', new QuestionExportView({
      model: this.model,
      submodule: this.options.submodule,
      closeBtn: true
    }));
  };

  EvidenceSynthesesToolbarView.prototype.changeOutcomeView = function(viewTypeName) {
    var previousViewType;
    previousViewType = this.viewType;
    if (this.options.submodule === 'recommendations') {
      mediator.services.esView.savePreference(mediator.project, 'recommendations', viewTypeName, true);
    } else {
      this.viewType = OutcomeViewType.get(viewTypeName);
      mediator.services.esView.savePreference(mediator.project, this.model.get('type'), this.viewType.name);
      this.options.parentView.showOutcomesSubview(this.viewType, previousViewType);
      this.$('button.export').prop('disabled', Boolean(this.viewType.isof));
    }
    return this.hideViewsListBox();
  };

  EvidenceSynthesesToolbarView.prototype.showViewsList = function(e) {
    var activeItem, offset, selectionView, theEye;
    e.preventDefault();
    e.stopPropagation();
    theEye = this.$('button.outcome-view');
    selectionView = new SelectionView({
      listItems: this.listItems,
      acceptHandler: (function(_this) {
        return function() {
          return _this.changeOutcomeView(selectionView.getSelectedItemValue());
        };
      })(this),
      abortHandler: this.hideViewsListBox
    });
    this.subview('selectionView', selectionView);
    offset = theEye.offset();
    offset.top = offset.top + theEye.height();
    activeItem = this.options.submodule === 'recommendations' ? mediator.services.esView.getPreferredType(mediator.project, 'recommendations') : this.viewType.name;
    return this.subview('selectionView').show(offset, activeItem);
  };

  EvidenceSynthesesToolbarView.prototype.hideViewsListBox = function() {
    var _ref;
    return (_ref = this.subview('selectionView')) != null ? _ref.hide() : void 0;
  };

  EvidenceSynthesesToolbarView.prototype.getTemplateData = function() {
    var data, outcome, question, _i, _len, _ref, _ref1, _ref2, _ref3, _ref4;
    data = EvidenceSynthesesToolbarView.__super__.getTemplateData.apply(this, arguments);
    _(data).extend({
      viewType: (_ref = this.viewType) != null ? _ref.name : void 0,
      baseUrl: this.options.baseUrl,
      module: _.string.dasherize(this.options.moduleName),
      submodule: this.options.submodule,
      questions: (function() {
        var _i, _len, _ref1, _results;
        _ref1 = this.questions.models;
        _results = [];
        for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
          question = _ref1[_i];
          _results.push(question.toJSON());
        }
        return _results;
      }).call(this),
      currentQuestion: (_ref1 = (_ref2 = this.model) != null ? _ref2.get('question') : void 0) != null ? _ref1 : $.t('toolbar.no_question_selected'),
      currentQuestionId: (_ref3 = this.model) != null ? _ref3.id : void 0
    });
    if (data.outcomes) {
      _ref4 = data.outcomes;
      for (_i = 0, _len = _ref4.length; _i < _len; _i++) {
        outcome = _ref4[_i];
        outcome.cellsWithNotes = [];
      }
    }
    return data;
  };

  EvidenceSynthesesToolbarView.prototype.dispose = function() {
    mediator.questions.off('add remove sync', this.render);
    return EvidenceSynthesesToolbarView.__super__.dispose.apply(this, arguments);
  };

  return EvidenceSynthesesToolbarView;

})(View);
