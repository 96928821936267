HorizontalRadios = require './horizontal_radios'
EtdActions = require 'actions/etd_actions'

GeneralQuestions = React.createClass
  displayName: 'DetailedJudgementsGeneralQuestions'

  propTypes:
    sectionId: React.PropTypes.string.isRequired
    groupPrefix: React.PropTypes.string.isRequired
    questions: React.PropTypes.object.isRequired

  changeRadioOption: (questionIdx) -> (value) =>
    EtdActions.updateDetailedGeneralQuestion @props.sectionId, questionIdx, value

  render: ->
    <div>
      {@props.questions.map (question, idx) =>
        <div key={idx}>
          <span>{question.get 'question'}</span>
          <HorizontalRadios
            groupName={"#{@props.groupPrefix}_general_quesitons_#{idx}"}
            options={question.get 'options'}
            additionalOptions={question.get 'additionalOptions'}
            selectedOption={question.get 'selectedOption'}
            onChange={@changeRadioOption idx}
          />
        </div>
      }
    </div>

module.exports = GeneralQuestions
