var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <li>"
    + escapeExpression(((helper = (helper = helpers.note || (depth0 != null ? depth0.note : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"note","hash":{},"data":data}) : helper)))
    + "</li>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<!doctype html>\n<html class=\"no-js\" lang=\"en\">\n<head>\n  <meta charset=\"utf-8\">\n  <meta http-equiv=\"X-UA-Compatible\" content=\"IE=edge,chrome=1\">\n  <title>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "printout_title", {"name":"i18n","hash":{},"data":data})))
    + "</title>\n  <meta name=\"viewport\" content=\"width=device-width,initial-scale=1\">\n  <style media=\"screen,print\" type=\"text/css\">\n";
  stack1 = this.invokePartial(partials.printoutStylesHead, '    ', 'printoutStylesHead', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n    body {\n       -webkit-print-color-adjust: exact;\n      font: normal 7pt 'Arial Narrow', Arial, sans-serif;\n      line-height:7pt;\n      letter-spacing: 0px;\n      font-stretch: condensed;\n      color: black;\n    }\n\n    table {\n      border: none;\n      width:100%;\n      border-collapse: collapse;\n      font: normal 8pt Verdana, 'Arial Narrow', Arial, sans-serif;\n    }\n\n    table th,\n    table td{\n      vertical-align: top;\n    }\n\n    tr,\n    th,\n    td {\n      border: 1px solid #000;\n    }\n\n    tr.table-title, tr.table-title th {\n      border: none;\n    }\n    thead, thead tr, thead tr th\n    {\n      background: #dddddd;\n    }\n\n    th.outcome-label-row {\n      background: #ededed;\n    }\n\n    tr.table-header th{\n      text-align: center;\n    }\n\n    sup span.underline {\n      margin: 0;\n      padding: 0;\n      text-decoration: underline;\n    }\n\n    p {\n      margin: 0;\n      padding: 0;\n    }\n\n    table, tr, td, th {\n      text-align: left;\n      border-spacing: 0;\n      border-collapse: collapse;\n      vertical-align: top;\n    }\n\n    th.quality-legend {\n      font-weight: normal\n    }\n\n    ol {\n      padding-left:15px;\n    }\n\n    td.quality-legend,\n    td.asterix-text {\n      padding: 4px 5px;\n      margin: 5px 0px;\n    }\n\n    span.bold,\n    td.quality span.cell {\n      font-weight: bold;\n    }\n\n    span.cell-title,\n    span.block {\n      display: block;\n    }\n\n    .quality-sign {\n        font-family: 'GRADE-quality', Cambria, Helvetica, Arial;\n        font-size: 14px;\n    }\n\n  </style>\n  </head>\n  <body>\n\n\n\n    <ol>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.notes : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </ol>\n  </body>\n</html>\n";
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}