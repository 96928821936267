var Translation;

Translation = function(namespace) {
  if (namespace == null) {
    namespace = '';
  }
  return {
    _i18n: function(key, params) {
      var keyWithNamespace, levelsToGoUp, namespaceTree, normalizedKey;
      if (params == null) {
        params = {};
      }
      keyWithNamespace = _.string.startsWith(key, '/') ? key.slice(1) : _.string.startsWith(key, '../') ? (namespaceTree = namespace.split('.'), namespaceTree.length === 1 ? key : (levelsToGoUp = key.match(/\.\.\//g).length, normalizedKey = key.replace(/\.\.\//g, ''), namespaceTree.slice(0, namespaceTree.length - levelsToGoUp).concat([normalizedKey]).join('.'))) : namespace.length ? "" + namespace + "." + key : key;
      return $.t(keyWithNamespace, params);
    },
    i18n: function(key, params, componentParams) {
      var component, fragments, paramKey, replaceWithComponent, string;
      if (params == null) {
        params = {};
      }
      if (componentParams == null) {
        componentParams = null;
      }
      string = this._i18n(key, params);
      if (componentParams) {
        fragments = {};
        replaceWithComponent = function(string, key, component) {
          var splitted;
          splitted = string.split("__" + paramKey + "__");
          fragments["text_" + paramKey] = splitted[0];
          fragments["comp_" + paramKey] = component;
          return splitted[1];
        };
        for (paramKey in componentParams) {
          component = componentParams[paramKey];
          string = replaceWithComponent(string, paramKey, component);
        }
        fragments['last'] = string;
        return React.addons.createFragment(fragments);
      } else {
        return string;
      }
    }
  };
};

module.exports = Translation;
