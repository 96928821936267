var ActiveProjects, ArchivedProjects, Controller, Exceptions, GdtRouter, LoginView, Projects, RegistrationView, SessionController, W, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Controller = require('controllers/base/controller');

Projects = require('models/projects');

RegistrationView = require('views/registration_view');

LoginView = require('views/login_view');

Exceptions = require('lib/exceptions');

GdtRouter = require('router');

W = require('when');

mediator = require('mediator');

ActiveProjects = require('models/active_projects');

ArchivedProjects = require('models/archived_projects');

module.exports = SessionController = (function(_super) {
  __extends(SessionController, _super);

  function SessionController() {
    return SessionController.__super__.constructor.apply(this, arguments);
  }

  SessionController.prototype.initialize = function(_arg) {
    this.service = _arg.authenticationService, this.udbService = _arg.udbService, this.container = _arg.container;
    SessionController.__super__.initialize.apply(this, arguments);
    this.subscribeEvent('replication.unauthorized', this._showLoginWindow);
    this.subscribeEvent('logout', this.logout);
    this.subscribeEvent('!setLanguage', this._setLanguage);
    this.subscribeEvent('centralUdbFetched', function() {
      return mediator.user.addSessionInfo('central');
    });
    window.onstorage = function(e) {
      if (e.key === 'active_user' && !e.newValue) {
        return mediator.publish('!reloadApplication');
      }
    };
    return this._checkLogin();
  };

  SessionController.prototype._showLoginWindow = function() {
    this.view = new LoginView({
      model: mediator.user,
      authenticationService: this.service,
      loginOnUnauthorized: true
    });
    return this.subscribeEvent('login', (function(_this) {
      return function(username, loginStatus) {
        return mediator.services.cache.checkCache().then(function(result) {
          if (result === 'updateready') {
            return _this._reloadApplication();
          } else {
            return _this._handleLogin()(username, loginStatus).then(function() {
              return mediator.user.addSessionInfo(loginStatus);
            });
          }
        });
      };
    })(this));
  };

  SessionController.prototype._setLanguage = function(newLng) {
    if ($.i18n.lng() === newLng) {
      return;
    }
    return window.gdt.storage.set('gdtLanguage', newLng).then(function() {
      if (mediator.user) {
        return mediator.user.save({
          language: newLng
        });
      } else {
        return W.resolve();
      }
    }).then((function(_this) {
      return function() {
        return $.i18n.setLng(newLng, _this._reloadApplication);
      };
    })(this));
  };

  SessionController.prototype._updateUserLanguageSettings = function() {
    return window.gdt.storage.get('gdtLanguage').then(function(lng) {
      if (mediator.user.get('language') !== lng) {
        return mediator.user.save({
          language: lng
        });
      }
    });
  };

  SessionController.prototype._checkLogin = function() {
    return this.service.getLoginStatus().then((function(_this) {
      return function(info) {
        if (info.status === 'no') {
          return _this._openRegistrationView().then(function(_arg) {
            var loginStatus, username;
            username = _arg[0], loginStatus = _arg[1];
            return username;
          });
        } else {
          return info.login;
        }
      };
    })(this)).then((function(_this) {
      return function(login) {
        if (typeof Raven !== "undefined" && Raven !== null) {
          Raven.setUser({
            id: login
          });
        }
        ga('set', 'userId', login);
        Backbone.sync = mediator.services.persistenceAdapter.sync({
          author: login,
          errorHandler: function() {
            return mediator.dialogs.error({
              message: $.t('messages.sync_error')
            });
          }
        });
        return _this.udbService.loadUserData(login);
      };
    })(this)).otherwise((function(_this) {
      return function(error) {
        if (typeof Raven !== "undefined" && Raven !== null) {
          Raven.captureException(error);
        }
        return _this.service.logout().then(function() {
          throw new Exceptions.authentication.cannot_load_local_data;
        });
      };
    })(this)).then((function(_this) {
      return function(userData) {
        mediator.user = userData;
        _this._updateUserLanguageSettings();
        if (typeof Raven !== "undefined" && Raven !== null) {
          Raven.setUser({
            id: mediator.user.id,
            email: mediator.user.get('email')
          });
        }
        if (mediator.services.switches.isOn('new_projects_list')) {
          mediator.activeProjects = new ActiveProjects();
          mediator.user.on('change:projects', function() {
            return mediator.activeProjects.fetch();
          });
          mediator.activeProjects.fetch();
          mediator.archivedProjects = new ArchivedProjects();
          mediator.user.on('change:projects', function() {
            return mediator.archivedProjects.fetch();
          });
          mediator.archivedProjects.fetch();
        }
        mediator.projects = new Projects();
        mediator.user.on('change:projects', function() {
          return mediator.projects.fetch();
        });
        return mediator.projects.fetch();
      };
    })(this)).then((function(_this) {
      return function() {
        var cacheService;
        cacheService = mediator.services.cache;
        return cacheService.checkCache().then(function(result) {
          if (result === 'updateready') {
            return _this._reloadApplication();
          } else {
            return mediator.publish('sessionStarted');
          }
        });
      };
    })(this), function(error) {
      return mediator.dialogs.fatalError(error);
    });
  };

  SessionController.prototype._reloadApplication = function() {
    return window.location.reload();
  };

  SessionController.prototype._openRegistrationView = function() {
    var deferred;
    deferred = W.defer();
    this.container.empty();
    this.view = new RegistrationView({
      authenticationService: this.service,
      loginOnUnauthorized: false,
      allowSwitchView: true
    });
    this.subscribeEvent('login', this._handleLogin(deferred));
    return deferred.promise;
  };

  SessionController.prototype._handleLogin = function(deferred) {
    return (function(_this) {
      return function(username, loginStatus) {
        mediator.unsubscribe('login', null, _this);
        _this.view.dispose();
        return (deferred != null ? deferred : W.defer()).resolve([username, loginStatus]);
      };
    })(this);
  };

  SessionController.prototype.logout = function() {
    return mediator.services.authentication.logout().then(function() {
      return mediator.publish('!reloadApplication');
    }).ensure(function() {
      return GdtRouter.prototype.changeURL('');
    });
  };

  return SessionController;

})(Controller);
