HorizontalRadios = require './horizontal_radios'
EtdActions = require 'actions/etd_actions'
Translation = require 'components/mixins/translation'

OutcomesQuestions = React.createClass
  displayName: 'DetailedJudgementsOutcomesQuestions'

  mixins: [Translation('es:recommendations.table')]

  propTypes:
    groupPrefix: React.PropTypes.string.isRequired
    sectionId: React.PropTypes.string.isRequired
    questions: React.PropTypes.object.isRequired
    criticalOutcomes: React.PropTypes.object.isRequired

  changeRadioOption: (questionIdx, outcomeId) -> (value) =>
    EtdActions.updateDetailedOutcomesQuestion @props.sectionId, questionIdx, outcomeId, value

  render: ->
    <div className="outcomes-questions">
      {@props.questions.map (question, questionIdx) =>
        <div key={questionIdx} className="judgements-table-container">
          <span>{question.get 'question'}</span>
          <table className="judgements-table">
            <thead className="judgements-table-header">
              <tr className="judgement-table-row">
                <th>{@i18n 'main_outcomes'}</th>
                <th>{@i18n 'judgements'}</th>
              </tr>
            </thead>
            <tbody>
              {@props.criticalOutcomes.map (outcome, outcomeIdx) =>
                <tr key={outcomeIdx} className="judgement-table-row">
                  <td>{outcome.get 'name'}</td>
                  <td>
                    <HorizontalRadios
                      groupName={"#{@props.groupPrefix}_outcomes_quesitons_#{outcomeIdx}"}
                      options={question.get('options')}
                      additionalOptions={question.get('additionalOptions')}
                      selectedOption={question.getIn(['selectedOptions', outcome.get('_id')])}
                      onChange={@changeRadioOption questionIdx, outcome.get('_id')}
                    />
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      }
    </div>

module.exports = OutcomesQuestions
