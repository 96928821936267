EditableItem = require 'components/common/editable_item'
InputWithValidation = require 'components/common/input_with_validation'
Translation = require 'components/mixins/translation'
MembersActions = require 'actions/members_actions'

FormGroup = React.createClass
  handleChange: (e) ->
    @props.updateProp @props.attr, e.target.value

  render: ->
    <div className="form-group">
      <label className="col-2" htmlFor={@props.attr}>{@props.label}</label>
      <div className="col-10">
        <InputWithValidation
          id={@props.attr}
          type="text"
          className="form-control"
          onChange={@handleChange}
          errorMsg={@props.errorMsg}
          value={@props.value}/>
      </div>
    </div>

Member = React.createClass
  mixins: [Translation('team:member')]

  displayName: 'Member'

  getName: (member) ->
    "#{member.get('givenNames') ? ''} #{member.get('lastName') ? ''}"

  updateProp: (attr, value) ->
    MembersActions.updateMember @props.itemKey, attr, value

  render: ->
    <EditableItem.Item {...@props}>
      <EditableItem.Static>
        <a
          title={@i18n 'send_email', to: @getName(@props.item)}
          href={"mailto:#{@props.item.get('email')}"}>
          {@getName(@props.item)}
        </a>
      </EditableItem.Static>
      <EditableItem.Editing>
        <div className="form-horizontal">
          <FormGroup
            attr='givenNames'
            errorMsg={@props.itemValidationErrors?.get('givenNames')}
            updateProp={@updateProp}
            label={@i18n 'given_names'}
            value={@props.item.get('givenNames')}/>
          <FormGroup
            attr='lastName'
            errorMsg={@props.itemValidationErrors?.get('lastName')}
            updateProp={@updateProp}
            label={@i18n 'last_name'}
            value={@props.item.get('lastName')}/>
          <FormGroup
            attr='email'
            errorMsg={@props.itemValidationErrors?.get('email')}
            updateProp={@updateProp}
            label={@i18n 'email'}
            value={@props.item.get('email')}/>
        </div>
      </EditableItem.Editing>
    </EditableItem.Item>

module.exports = Member
