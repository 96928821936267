var HandbookView, HelpView, Shortcuts, UserGuideView, View, WelcomeCapabilitiesListView, mediator, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

template = require('views/templates/help');

mediator = require('mediator');

Shortcuts = require('base/lib/traits/shortcuts');

WelcomeCapabilitiesListView = require('views/welcome_capabilities_view');

HandbookView = require('views/handbook_view');

UserGuideView = require('views/user_guide_view');

module.exports = HelpView = (function(_super) {
  __extends(HelpView, _super);

  function HelpView() {
    this.onFocusLost = __bind(this.onFocusLost, this);
    return HelpView.__super__.constructor.apply(this, arguments);
  }

  HelpView.prototype.template = template;

  HelpView.prototype.className = 'popup-menu help-popup';

  HelpView.prototype.initialize = function() {
    HelpView.__super__.initialize.apply(this, arguments);
    this.enable(Shortcuts);
    this.delegate('click', function(e) {
      return e.stopPropagation();
    });
    this.supportItems = [
      {
        value: 'walkthrough',
        text: $.t('help.walkthrough')
      }, {
        value: 'guide',
        text: $.t('help.guide')
      }, {
        value: 'tutorials',
        text: $.t('help.tutorials')
      }
    ];
    this.learnItems = [
      {
        value: 'handbook',
        text: $.t('help.handbook')
      }, {
        value: 'diagram',
        text: $.t('help.diagram')
      }
    ];
    return this.otherItems = [
      {
        value: 'termsOfService',
        text: $.t('terms_of_service')
      }
    ];
  };

  HelpView.prototype.getTemplateData = function() {
    return {
      supportItems: this.supportItems,
      learnItems: this.learnItems,
      otherItems: this.otherItems
    };
  };

  HelpView.prototype.show = function(offset, gainFocus) {
    var _self;
    if (gainFocus == null) {
      gainFocus = true;
    }
    _self = this;
    return mediator.services.cache.isUpToDate().then((function(_this) {
      return function(upToDate) {
        var showFn;
        _self.otherItems.push(upToDate ? {
          value: 'upToDate',
          text: $.t('messages.up_to_date', {
            link: window.gdt.versionUpdateLink
          })
        } : {
          value: 'updateAvailable',
          text: $.t('messages.update_available')
        });
        showFn = function() {
          _this._renderAndDisplay(offset);
          return _this._delegateEvents();
        };
        if (gainFocus) {
          return mediator.setFocus(_this, showFn);
        } else {
          return showFn();
        }
      };
    })(this));
  };

  HelpView.prototype.acceptHandler = function(e) {
    return this._processSelection($(e.target).closest('li').data('listValue'));
  };

  HelpView.prototype._processSelection = function(action) {
    switch (action) {
      case 'walkthrough':
        return mediator.services.walkthrough.startTour();
      case 'guide':
        return new UserGuideView();
      case 'tutorials':
        return window.open($.t('projects:welcome.learn_section.tutorials_homepage'), '_blank');
      case 'handbook':
        return new HandbookView();
      case 'diagram':
        return this._showDiagram();
      case 'updateAvailable':
        return window.location.reload();
      case 'termsOfService':
        return window.open($.t('terms_of_service_url'), '_blank');
    }
  };

  HelpView.prototype._delegateEvents = function() {
    return this.delegate('click', '.list-box-list li:not(.disabled)', this.acceptHandler);
  };

  HelpView.prototype._renderAndDisplay = function(offset) {
    if (!offset.right) {
      offset.right = $(window).width() - offset.left - 45;
    }
    this.render();
    $('body').append(this.$el);
    return this.$el.css({
      right: offset.right,
      top: offset.top
    });
  };

  HelpView.prototype._showDiagram = function() {
    var maxHeight;
    maxHeight = $(document).height() - 150;
    return mediator.dialogs.infoBox(null, {
      title: $.t('projects:welcome.learn_section.diagram'),
      message: "<img src='images/process-diagram.png' style='max-height: " + maxHeight + "px'>",
      position: 'top',
      offsetY: 0,
      hideOnClick: false
    });
  };

  HelpView.prototype.hide = function() {
    if (!this.hidden) {
      this.$el.remove();
      this.hidden = true;
      return this.dispose();
    }
  };

  HelpView.prototype.onFocusLost = function() {
    return this.hide();
  };

  HelpView.prototype.afterRender = function() {
    HelpView.__super__.afterRender.apply(this, arguments);
    this.subview('capabilities', new WelcomeCapabilitiesListView({
      container: this.$('#capabilities')
    }));
    return this.subview('capabilities').render();
  };

  return HelpView;

})(View);
