var caseControl, clearOption, compoundTemplate, contentEditablePartial, copyrightNotice, diagnosticMeta, diagnosticPrintoutSofSettings, effect, followUpLength, inputBlockTemplate, languageSelect, noOfParticipantsStudiesEmbedded, obsStudiesSelection, pooledSensitivitySpecificity, printoutStylesHead, questionGroupElement, recommendationDxEvidenceTable, recommendationRadiosTable, recommendationsAppendices, relativeImportanceMainOutcomesTable, rm5TableFooter, selectableAttr, selectionListTemplate, sofShortTable, testAccuracySummary;

obsStudiesSelection = require('views/templates/outcome_edit/partials/obs_studies_selection');

clearOption = require('views/templates/outcome_edit/partials/clear_option');

noOfParticipantsStudiesEmbedded = require('views/templates/outcome_edit/partials/no_of_participants_studies_embedded');

selectionListTemplate = require('views/templates/outcome_edit/selection_template');

compoundTemplate = require('views/templates/outcome_edit/compound_template');

inputBlockTemplate = require('views/templates/outcome_edit/input_blocks/input_block_template');

selectableAttr = require('views/templates/outcome_edit/partials/selectable_attr');

contentEditablePartial = require('views/templates/evidence_syntheses/partials/content_editable');

questionGroupElement = require('views/templates/evidence_syntheses/partials/question_group_element');

languageSelect = require('views/templates/language_select');

copyrightNotice = require('views/templates/copyright_notice');

pooledSensitivitySpecificity = require('views/templates/evidence_syntheses/pooled_sensitivity_specificity');

recommendationRadiosTable = require('views/templates/evidence_syntheses/partials/recommendation_radios_table');

recommendationDxEvidenceTable = require('views/templates/evidence_syntheses/partials/recommendation_dx_evidence_table');

printoutStylesHead = require('views/templates/printouts/styles_head');

diagnosticMeta = require('views/templates/evidence_syntheses/partials/diagnostic_meta');

rm5TableFooter = require('views/templates/export/partials/rm5_table_footer');

followUpLength = require('views/templates/outcome_edit/partials/follow_up_length');

effect = require('views/templates/outcome_edit/partials/effect');

caseControl = require('views/templates/outcome_edit/partials/case_control');

diagnosticPrintoutSofSettings = require('views/templates/printouts/diagnostic/sof_settings');

sofShortTable = require('views/templates/evidence_syntheses/partials/sof_short');

relativeImportanceMainOutcomesTable = require('views/templates/evidence_syntheses/partials/relative_importance_main_outcomes_table');

testAccuracySummary = require('views/templates/evidence_syntheses/partials/test_accuracy');

recommendationsAppendices = require('views/templates/evidence_syntheses/partials/recommendations_appendices');

Handlebars.registerPartial("obsStudiesSelection", obsStudiesSelection);

Handlebars.registerPartial("clearOption", clearOption);

Handlebars.registerPartial("noOfParticipantsStudiesEmbedded", noOfParticipantsStudiesEmbedded);

Handlebars.registerPartial("selectionListTemplate", selectionListTemplate);

Handlebars.registerPartial("compoundTemplate", compoundTemplate);

Handlebars.registerPartial("inputBlockTemplate", inputBlockTemplate);

Handlebars.registerPartial("selectableAttrTemplate", selectableAttr);

Handlebars.registerPartial("contentEditablePartial", contentEditablePartial);

Handlebars.registerPartial('addQuestionsButtons', require('views/templates/evidence_syntheses/partials/add_questions_buttons'));

Handlebars.registerPartial('outcomeButtons', require('views/templates/evidence_syntheses/partials/outcome_buttons'));

Handlebars.registerPartial('questionGroupElement', questionGroupElement);

Handlebars.registerPartial('languageSelect', languageSelect);

Handlebars.registerPartial('copyrightNotice', copyrightNotice);

Handlebars.registerPartial('pooledSensitivitySpecificity', pooledSensitivitySpecificity);

Handlebars.registerPartial('recommendationRadiosTable', recommendationRadiosTable);

Handlebars.registerPartial('recommendationDxEvidenceTable', recommendationDxEvidenceTable);

Handlebars.registerPartial('printoutStylesHead', printoutStylesHead);

Handlebars.registerPartial('diagnosticMeta', diagnosticMeta);

Handlebars.registerPartial('rm5TableFooter', rm5TableFooter);

Handlebars.registerPartial('followUpLength', followUpLength);

Handlebars.registerPartial('effect', effect);

Handlebars.registerPartial('diagnosticPrintoutSofSettings', diagnosticPrintoutSofSettings);

Handlebars.registerPartial('riskDifferenceWithInterventionEditPartial', require('views/templates/outcome_edit/partials/risk_difference_with_intervention_edit_partial'));

Handlebars.registerPartial('sofShortTable', sofShortTable);

Handlebars.registerPartial('relativeImportanceMainOutcomesTable', relativeImportanceMainOutcomesTable);

Handlebars.registerPartial('testAccuracySummary', testAccuracySummary);

Handlebars.registerPartial('caseControl', caseControl);

Handlebars.registerPartial('recommendationsAppendices', recommendationsAppendices);
