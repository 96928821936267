var DiagnosticQuestionExporter, DiagnosticRecommendationsExporter, JSONLDExportDiagnosticQuestionsService, lift, utils;

DiagnosticQuestionExporter = require('./diagnostic_question_exporter');

DiagnosticRecommendationsExporter = require('./diagnostic_recommendations_exporter');

utils = require('./exporter_utils');

lift = (require('when/function')).lift;

module.exports = JSONLDExportDiagnosticQuestionsService = (function() {
  function JSONLDExportDiagnosticQuestionsService() {}

  JSONLDExportDiagnosticQuestionsService.prototype._export = function(originalQuestion, etdVersion) {
    var exportedEvidenceSummary, exportedExplanations, exportedOutcomes, exportedPrevalence, exportedQuestion, exportedRecommendations, exportedTests, implementationConsiderations, questionExporter, recommendationsExporter, subgroupConsiderations;
    if (etdVersion == null) {
      etdVersion = 'v2';
    }
    questionExporter = new DiagnosticQuestionExporter(originalQuestion);
    exportedOutcomes = questionExporter.exportOutcomes();
    exportedExplanations = questionExporter.exportExplanations();
    exportedTests = questionExporter.exportTests();
    exportedPrevalence = questionExporter.exportPrevalence();
    exportedEvidenceSummary = questionExporter.exportEvidenceSummary();
    exportedQuestion = {
      '@context': utils.diagnosticQuestionContext(),
      '@type': 'DiagnosticEvidenceProfile',
      'version': (originalQuestion['_rev'].split('-'))[0],
      'modificationTime': utils.timestampToISOWithTimeZone(originalQuestion['$timestamp']),
      'author': (originalQuestion['authors'] != null) && originalQuestion['authors'] !== '' ? {
        '@type': 'Person',
        'name': originalQuestion['authors']
      } : void 0,
      'title': originalQuestion['question'],
      'sofTitle': originalQuestion['questionShort'],
      'question': {
        '@id': "questions/" + originalQuestion['_id'],
        '@type': 'PCTO',
        'targetCondition': {
          '@type': 'MedicalCondition',
          'name': originalQuestion['targetCondition']
        },
        'healthProblemOrPopulation': {
          '@type': 'HealthProblemOrPopulation',
          'name': originalQuestion['healthProblemOrPopulation'],
          'setting': originalQuestion['settings']
        },
        'diagnosticTest': exportedTests,
        'outcome': exportedOutcomes
      },
      'explanation': exportedExplanations,
      'prevalence': exportedPrevalence,
      'evidenceSummary': exportedEvidenceSummary
    };
    recommendationsExporter = new DiagnosticRecommendationsExporter(originalQuestion, etdVersion);
    implementationConsiderations = recommendationsExporter.exportImplementationConsiderations();
    subgroupConsiderations = recommendationsExporter.exportSubgroupConsiderations();
    exportedRecommendations = {
      '@context': utils.recommendationsContext(),
      '@type': ['Recommendation', 'MedicalGuidelineRecommendation'],
      'version': (originalQuestion['_rev'].split('-'))[0],
      'modificationTime': utils.timestampToISOWithTimeZone(originalQuestion['$timestamp']),
      'title': originalQuestion['question'],
      'guidelineSubject': {
        '@id': "questions/" + originalQuestion['_id']
      },
      'section': recommendationsExporter.exportSections(),
      'consequencesBalance': recommendationsExporter.exportConsequencesBalance(),
      'recommendationType': recommendationsExporter.exportRecommendationType(),
      'recommendationText': recommendationsExporter.exportRecommendations(),
      'recommendationJustification': recommendationsExporter.exportJustification(),
      'implementationConsiderations': implementationConsiderations,
      'monitoringAndEvaluation': recommendationsExporter.exportMonitoringAndEvaluation(),
      'researchPriorities': recommendationsExporter.exportResearchPriorities(),
      'header': recommendationsExporter.exportHeader()
    };
    switch (etdVersion) {
      case 'v1':
        exportedRecommendations['panelDecisions'] = subgroupConsiderations;
        break;
      case 'v2':
        exportedRecommendations['subgroupConsiderations'] = subgroupConsiderations;
    }
    utils.removeFalseValuesFromObject(exportedRecommendations);
    if ((originalQuestion['authors'] != null) && originalQuestion['authors'] !== '') {
      exportedRecommendations['author'] = {
        '@type': 'Person',
        'name': originalQuestion['authors']
      };
    }
    return [exportedQuestion, exportedRecommendations];
  };

  JSONLDExportDiagnosticQuestionsService.prototype["export"] = function(originalQuestion, etdVersion) {
    if (etdVersion == null) {
      etdVersion = 'v2';
    }
    return (lift(this._export, originalQuestion, etdVersion))();
  };

  return JSONLDExportDiagnosticQuestionsService;

})();
