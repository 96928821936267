var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<iframe id=\"webpage-content\" src=\"handbook/handbook.html\"></iframe>\n<div class=\"buttons-block row\">\n  <div class=\"col-6\">\n    <a class=\"btn btn-block\"\n      href=\"handbook/handbook.html\" target=\"_blank\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "help.open_in_new_window", {"name":"i18n","hash":{},"data":data})))
    + "</a>\n  </div>\n  <div class=\"col-6\">\n    <a class=\"btn btn-block close\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "actions.close", {"name":"i18n","hash":{},"data":data})))
    + "</a>\n  </div>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}