var ContentEditableElements, InsertView, ViewTrait, W, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

ViewTrait = require('base/lib/traits/view_trait');

mediator = require('mediator');

InsertView = require('views/insert_view');

W = require('when');

module.exports = ContentEditableElements = (function(_super) {
  __extends(ContentEditableElements, _super);

  function ContentEditableElements() {
    return ContentEditableElements.__super__.constructor.apply(this, arguments);
  }

  ContentEditableElements.prototype.apply = function(view) {
    var ImageInsertion;
    ContentEditableElements.__super__.apply.apply(this, arguments);
    if (__indexOf.call(view.traits, 'ImageInsertion') < 0) {
      ImageInsertion = require('lib/traits/image_insertion');
      view.enable(ImageInsertion, {
        container: view.el
      });
    }
    this._addFunction(view, '_editContentShow');
    this._addFunction(view, '_editContentHide');
    this._addFunction(view, '_editContentApply');
    this._addFunction(view, '_editContentCancel');
    this._addFunction(view, '_editContentInsert');
    this._addFunction(view, '_insertTable');
    this._addFunction(view, '_insertSof');
    this._addFunction(view, '_insertPicture');
    this._addFunction(view, '_updateContentEditable');
    this._addFunction(view, '_checkIfShowEditInfo');
    this._addFunction(view, 'afterRender');
    this._addFunction(view, 'dispose');
    view.delegate('click', '.editor-container > .static-content', view._editContentShow.bind(view));
    view.delegate('click', 'button.cancel', view._editContentCancel.bind(view));
    view.delegate('click', 'button.apply', view._editContentApply.bind(view));
    return view.delegate('click', 'button.insert', view._editContentInsert.bind(view));
  };

  ContentEditableElements.prototype._moveCaretToEnd = function(el) {
    var children, e, range, sel;
    sel = window.getSelection();
    try {
      return sel.modify('move', 'right', 'documentboundary');
    } catch (_error) {
      e = _error;
      children = el.children().toArray();
      if (_(children).isEmpty()) {
        return;
      }
      range = document.createRange();
      range.setStart(children[children.length - 1], 0);
      range.collapse;
      sel.removeAllRanges();
      return sel.addRange(range);
    }
  };

  ContentEditableElements.prototype._editContentShow = function(originalFunction, trait, e) {
    var $target;
    this._editContentApply();
    $target = $(e.target).closest('.content');
    $target.closest('div.editor-container').addClass('edit-content');
    this.currentStaticContent = $target;
    this.currentEditableContent = $target.siblings('.editable-content');
    this.currentEditableContent.focus();
    trait._moveCaretToEnd(this.currentEditableContent);
    this.oldContentVal = this.currentEditableContent.html();
    this.currentEditableContent.on('input', this._checkIfShowEditInfo);
    return e.stopPropagation();
  };

  ContentEditableElements.prototype._editContentHide = function(originalFunction, trait) {
    if (!this.currentEditableContent) {
      return;
    }
    this.currentEditableContent.closest('div.editor-container').removeClass('edit-content');
    this.currentEditableContent.off('input', this._checkIfShowEditInfo);
    return this.currentEditableContent = null;
  };

  ContentEditableElements.prototype._checkIfShowEditInfo = function() {
    var dontShowAgain, newVal, oldVal;
    dontShowAgain = mediator.user.get('dontShowEditOptionsInfo');
    if (!this.editOptionsInfoShown && !dontShowAgain) {
      oldVal = this.oldContentVal;
      newVal = this.currentEditableContent.html();
      if (Math.abs(oldVal.length - newVal.length) >= 4) {
        this.editOptionsInfoShown = true;
        this.currentEditableContent.off('input', this._checkIfShowEditInfo);
        return this.trigger('editNotify', ".editable-content[name=" + (this.currentEditableContent.attr('name')) + "]");
      }
    }
  };

  ContentEditableElements.prototype._editContentInsert = function(originalFunction, trait) {
    var insertView;
    if (!this.currentEditableContent) {
      return;
    }
    this.subview('insertView', insertView = new InsertView({
      isDiagnostic: this.model.isDiagnostic()
    }));
    return insertView.promise().then((function(_this) {
      return function(userInput) {
        var file, selectedOption;
        selectedOption = userInput.selectedOption, file = userInput.file;
        switch (selectedOption) {
          case 'sof_short':
          case 'test_accuracy':
          case 'evidence_short':
          case 'relative_importance':
            return _this._insertTable(selectedOption);
          case 'sof_v1':
          case 'sof_v2':
          case 'sof_v3':
          case 'l1-sof':
          case 'l2-sof':
            return _this._insertSof(selectedOption);
          case 'picture':
            return _this._insertPicture(file);
        }
      };
    })(this));
  };

  ContentEditableElements.prototype._insertTable = function(originalFunction, trait, tableName) {
    var tableTemplate, tablesMap, templateData;
    tablesMap = {
      'sof_short': Handlebars.partials.sofShortTable,
      'test_accuracy': Handlebars.partials.testAccuracySummary,
      'evidence_short': Handlebars.partials.recommendationDxEvidenceTable,
      'relative_importance': Handlebars.partials.relativeImportanceMainOutcomesTable
    };
    tableTemplate = tablesMap[tableName];
    templateData = _.clone(this.getTemplateData());
    return this._updateContentEditable(tableTemplate(templateData));
  };

  ContentEditableElements.prototype._insertSof = function(originalFunction, trait, sofType) {
    var fakeDoc;
    fakeDoc = trait._prepareSofDoc(sofType, this);
    return rasterizeHTML.drawDocument(fakeDoc, {
      width: 480
    }).then((function(_this) {
      return function(result) {
        var config, imageBlob, svg;
        svg = trait._normalizeSvg(result.svg);
        imageBlob = new Blob([svg], {
          type: 'image/svg+xml'
        });
        config = {
          dbAttr: _this.currentEditableContent.attr('name'),
          fileName: "" + sofType + ".svg"
        };
        return _this.saveImage(imageBlob, config);
      };
    })(this));
  };

  ContentEditableElements.prototype._insertPicture = function(originalFunction, trait, file) {
    var config;
    config = {
      dbAttr: this.currentEditableContent.attr('name')
    };
    return this.saveImage(file, config);
  };

  ContentEditableElements.prototype._editContentApply = function(originalFunction, trait) {
    if (!this.currentEditableContent) {
      return;
    }
    this.currentStaticContent.html(this.currentEditableContent.html());
    this.model.save();
    this.runDelayedActions();
    return this._editContentHide();
  };

  ContentEditableElements.prototype._editContentCancel = function(originalFunction, trait, e) {
    if (!this.currentEditableContent) {
      return;
    }
    this.currentEditableContent.html(this.oldContentVal);
    this.currentEditableContent.trigger('change');
    this.flushDelayedActions();
    return this._editContentHide();
  };

  ContentEditableElements.prototype._updateContentEditable = function(originalFunction, trait, newHtml) {
    var emptyParagraph;
    emptyParagraph = document.createElement('p');
    emptyParagraph.innerHTML = '<br>';
    this.currentEditableContent.append(trait._ensureHtmlElement(newHtml)).append(emptyParagraph);
    this.currentEditableContent.trigger('paste');
    this.currentEditableContent.focus();
    return trait._moveCaretToEnd(this.currentEditableContent);
  };

  ContentEditableElements.prototype._ensureHtmlElement = function(html) {
    var div;
    if (_(html).isString()) {
      div = document.createElement('div');
      div.innerHTML = html;
      return div;
    } else {
      return html;
    }
  };

  ContentEditableElements.prototype._prepareSofDoc = function(sofType, view) {
    var HtmlExport, fakeDoc, sofHtml, sofTypesMap;
    HtmlExport = require('lib/html_export');
    sofTypesMap = {
      'sof_v1': 'sof',
      'sof_v2': 'ACCP_sof',
      'sof_v3': 'SOF_v3',
      'l1-sof': 'layerOneSof',
      'l2-sof': 'layerTwoSof'
    };
    sofHtml = new HtmlExport(view.model, sofTypesMap[sofType], {
      short: true
    }).getSource();
    fakeDoc = document.implementation.createHTMLDocument();
    fakeDoc.documentElement.innerHTML = sofHtml.replace(/<\/?html>/g, '');
    return fakeDoc;
  };

  ContentEditableElements.prototype._normalizeSvg = function(svg) {
    var regExp, replacer;
    regExp = /^<svg[\S\s]+width="([\d]+)"[\S\s]+><foreignObject[\S\s]+width="([\d]+)"/;
    replacer = function(match, width1, width2) {
      return match.replace(width1, '100%').replace(width2, '100%');
    };
    return svg.replace(regExp, replacer);
  };

  ContentEditableElements.prototype.afterRender = function(originalFunction, trait) {
    originalFunction.apply(this);
    if (!this.options.printout) {
      return _(this.$('.editor-container > .static-content')).each(function(staticContent) {
        var $editableContent, $staticContent;
        $staticContent = $(staticContent);
        $editableContent = $staticContent.siblings('.editable-content');
        return $staticContent.html($editableContent.html());
      });
    }
  };

  ContentEditableElements.prototype.dispose = function(originalFunction, trait) {
    if (this.model.isDirty()) {
      this._editContentApply();
      mediator.dialogs.success($.t('messages.changes_saved'));
    }
    return originalFunction.apply(this);
  };

  return ContentEditableElements;

})(ViewTrait);
