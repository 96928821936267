var EvidenceSynthesesToolbarView, PresentationsToolbarView, PresentationsView, PresentationsWithToolbarView, View,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

PresentationsView = require('views/evidence_syntheses/presentations_view');

PresentationsToolbarView = require('views/presentations_toolbar_view');

EvidenceSynthesesToolbarView = require('views/evidence_syntheses_toolbar_view');

module.exports = PresentationsWithToolbarView = (function(_super) {
  __extends(PresentationsWithToolbarView, _super);

  function PresentationsWithToolbarView() {
    return PresentationsWithToolbarView.__super__.constructor.apply(this, arguments);
  }

  PresentationsWithToolbarView.prototype.id = 'presentations';

  PresentationsWithToolbarView.prototype.autorender = false;

  PresentationsWithToolbarView.prototype.initialize = function() {
    PresentationsWithToolbarView.__super__.initialize.apply(this, arguments);
    this.subview("PresentationsView", new PresentationsView({
      model: this.model,
      parentView: this,
      autoRender: true,
      container: '#page-container'
    }));
    return this.subview('toolbar', new PresentationsToolbarView({
      moduleName: this.options.moduleName,
      parentView: this,
      baseUrl: this.options.baseUrl,
      model: this.model,
      submodule: 'presentations'
    }));
  };

  return PresentationsWithToolbarView;

})(View);
