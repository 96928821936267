var EtdV2DiagnosticRecommendationsExporter, RecommendationsExporter,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

RecommendationsExporter = require('./recommendations_exporter');

module.exports = EtdV2DiagnosticRecommendationsExporter = (function(_super) {
  __extends(EtdV2DiagnosticRecommendationsExporter, _super);

  function EtdV2DiagnosticRecommendationsExporter(_originalQuestion) {
    this.exportHeader = __bind(this.exportHeader, this);
    this.exportResearchPriorities = __bind(this.exportResearchPriorities, this);
    this.exportMonitoringAndEvaluation = __bind(this.exportMonitoringAndEvaluation, this);
    this.exportImplementationConsiderations = __bind(this.exportImplementationConsiderations, this);
    this.exportSubgroupConsiderations = __bind(this.exportSubgroupConsiderations, this);
    this.exportJustification = __bind(this.exportJustification, this);
    this.exportRecommendations = __bind(this.exportRecommendations, this);
    this.exportRecommendationType = __bind(this.exportRecommendationType, this);
    EtdV2DiagnosticRecommendationsExporter.__super__.constructor.call(this, _originalQuestion);
  }

  EtdV2DiagnosticRecommendationsExporter.prototype._exportProblemSection = function() {
    var priorityCriterion;
    priorityCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('problem', 'is_priority'), this._v2standardOptions, this._getNameForOptionLabel(this._originalQuestion['priorityProblemJudgement']));
    return this._makeSection('ProblemSection', this._getTitleForSectionLabel('problem'), priorityCriterion, this._originalQuestion['problemResearchEvidence'], this._originalQuestion['problemAdditionalConsiderations']);
  };

  EtdV2DiagnosticRecommendationsExporter.prototype._exportTestAccuracySection = function() {
    var accuracyCriterion;
    accuracyCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('diagnostic_test_accuracy', 'how_accurate_is_the_test'), this._v2accuracyOptions, this._getNameForOptionLabel(this._originalQuestion['diagnosticTestAccuracyJudgement']));
    return this._makeSection('TestAccuracySection', this._getTitleForSectionLabel('diagnostic_test_accuracy'), accuracyCriterion, this._originalQuestion['diagnosticTestAccuracyEvidence'], this._originalQuestion['diagnosticTestAccuracyAdditionalConsiderations']);
  };

  EtdV2DiagnosticRecommendationsExporter.prototype._exportDesirableEffectsSection = function() {
    var desirableEffectsCriterion;
    desirableEffectsCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('desirable_undesirable_effects', 'how_substantial_desirable_effects'), this._v2desirableOptions, this._getNameForOptionLabel(this._originalQuestion['desirableEffectsJudgement']), this._originalQuestion['desirableEffectsEvidence'], this._originalQuestion['desirableEffectsAdditionalConsiderations']);
    return this._makeSection('DesirableEffectsSection', this._getTitleForSectionLabel('desirable_undesirable_effects', 'desirable_effects'), desirableEffectsCriterion);
  };

  EtdV2DiagnosticRecommendationsExporter.prototype._exportUndesirableEffectsSection = function() {
    var undesirableEffectsCriterion;
    undesirableEffectsCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('desirable_undesirable_effects', 'how_substantial_undesirable_effects'), this._v2undesirableOptions, this._getNameForOptionLabel(this._originalQuestion['undesirableEffectsJudgement']), this._originalQuestion['undesirableEffectsEvidence'], this._originalQuestion['undesirableEffectsAdditionalConsiderations']);
    return this._makeSection('UndesirableEffectsSection', this._getTitleForSectionLabel('desirable_undesirable_effects', 'undesirable_effects'), undesirableEffectsCriterion);
  };

  EtdV2DiagnosticRecommendationsExporter.prototype._exportTestAccuracyCertaintyOfEvidenceSection = function() {
    var testAccuracyCertaintyOfEvidenceCriterion;
    testAccuracyCertaintyOfEvidenceCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('evidence_certainty', 'overall_test_accuracy_certainty'), this._v2certaintyOptions, this._getNameForOptionLabel(this._originalQuestion['testAccuracyEvidenceCertaintyJudgement']), this._originalQuestion['accuracyEvidenceCertaintyEvidence'], this._originalQuestion['testAccuracyEvidenceCertaintyAdditionalConsiderations']);
    return this._makeSection('TestAccuracyCertaintyOfEvidenceSection', this._getTitleForSectionLabel('evidence_certainty', 'test_accuracy_certainty'), testAccuracyCertaintyOfEvidenceCriterion);
  };

  EtdV2DiagnosticRecommendationsExporter.prototype._exportTestEffectsCertaintyOfEvidenceSection = function() {
    var testEffectsCertaintyOfEvidenceCriterion;
    testEffectsCertaintyOfEvidenceCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('evidence_certainty', 'overall_certainty_of_evidence'), this._v2certaintyOptions, this._getNameForOptionLabel(this._originalQuestion['evidenceTestsEffectsCertaintyJudgement']), this._originalQuestion['evidenceTestsEffectsCertaintyEvidence'], this._originalQuestion['evidenceTestsEffectsCertaintyAdditionalConsiderations']);
    return this._makeSection('TestEffectsCertaintyOfEvidenceSection', this._getTitleForSectionLabel('evidence_certainty', 'evidence_tests_effects_certainty'), testEffectsCertaintyOfEvidenceCriterion);
  };

  EtdV2DiagnosticRecommendationsExporter.prototype._exportManagementEffectsCertaintyOfEvidenceSection = function() {
    var managementEffectsCertaintyOfEvidenceCriterion;
    managementEffectsCertaintyOfEvidenceCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('evidence_certainty', 'overall_certainty_of_managements_effects'), this._v2certaintyOptions, this._getNameForOptionLabel(this._originalQuestion['evidenceManagementsEffectsCertaintyJudgement']), this._originalQuestion['evidenceManagementsEffectsCertaintyEvidence'], this._originalQuestion['evidenceManagementsEffectsCertaintyAdditionalConsiderations']);
    return this._makeSection('ManagementEffectsCertaintyOfEvidenceSection', this._getTitleForSectionLabel('evidence_certainty', 'evidence_managements_effects_certainty'), managementEffectsCertaintyOfEvidenceCriterion);
  };

  EtdV2DiagnosticRecommendationsExporter.prototype._exportTestResultManagementEffectsCertaintyOfEvidenceSection = function() {
    var testResultManagementEffectsCertaintyOfEvidenceCriterion;
    testResultManagementEffectsCertaintyOfEvidenceCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('evidence_certainty', 'decisions_link_certainty'), this._v2certaintyOptions, this._getNameForOptionLabel(this._originalQuestion['decisionsLinkCertaintyJudgement']), this._originalQuestion['decisionsLinkCertaintyEvidence'], this._originalQuestion['decisionsLinkCertaintyAdditionalConsiderations']);
    return this._makeSection('TestResultManagementEffectsCertaintyOfEvidenceSection', this._getTitleForSectionLabel('evidence_certainty', 'test_result_management_link_certainty'), testResultManagementEffectsCertaintyOfEvidenceCriterion);
  };

  EtdV2DiagnosticRecommendationsExporter.prototype._exportEffectsCertaintyOfEvidenceSection = function() {
    var effectsCertaintyOfEvidenceCriterion;
    effectsCertaintyOfEvidenceCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('evidence_certainty', 'overall_test_effects_certainty'), this._v2certaintyOptions, this._getNameForOptionLabel(this._originalQuestion['overallEffectsCertaintyJudgement']), this._originalQuestion['overallEffectsCertaintyEvidence'], this._originalQuestion['overallEffectsCertaintyAdditionalConsiderations']);
    return this._makeSection('EffectsCertaintyOfEvidenceSection', this._getTitleForSectionLabel('evidence_certainty', 'effects_certainty'), effectsCertaintyOfEvidenceCriterion);
  };

  EtdV2DiagnosticRecommendationsExporter.prototype._exportValuesSection = function() {
    var outcomesValuesCertaintyCriterion;
    outcomesValuesCertaintyCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('values', 'main_outcomes_value_certainty'), this._uncertaintyOptions, this._getNameForOptionLabel(this._originalQuestion['outcomesValuesCertaintyJudgement']), this._originalQuestion['outcomesValuesCertaintyEvidence'], this._originalQuestion['outcomesValuesCertaintyAdditionalConsiderations']);
    return this._makeSection('ValuesSection', this._getTitleForSectionLabel('values', 'values'), outcomesValuesCertaintyCriterion);
  };

  EtdV2DiagnosticRecommendationsExporter.prototype._exportBalanceOfEffectsSection = function() {
    var effectsBalanceCriterion;
    effectsBalanceCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('effects_balance', 'does_effects_balance_favor_test'), this._uncertaintyOptions, this._getNameForOptionLabel(this._originalQuestion['effectsBalanceJudgement']), this._originalQuestion['effectsBalanceEffectsEvidence'], this._originalQuestion['effectsBalanceEffectsAdditionalConsiderations']);
    return this._makeSection('BalanceOfEffectsSection', this._getTitleForSectionLabel('effects_balance', 'effects_balance'), effectsBalanceCriterion);
  };

  EtdV2DiagnosticRecommendationsExporter.prototype._exportResourcesRequiredSection = function() {
    var resourcesRequiredCriterion;
    resourcesRequiredCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('resources', 'resource_requirements_costs'), this._resourcesRequiredOptions, this._getNameForOptionLabel(this._originalQuestion['resourcesRequirementsJudgement']), this._originalQuestion['resourcesRequirementsEvidence'], this._originalQuestion['resourcesRequirementsAdditionalConsiderations']);
    return this._makeSection('ResourcesRequiredSection', this._getTitleForSectionLabel('resources', 'resource_required'), resourcesRequiredCriterion);
  };

  EtdV2DiagnosticRecommendationsExporter.prototype._exportResourcesRequiredCertaintyOfEvidenceSection = function() {
    var judgementLabel, resourcesRequiredCertaintyOfEvidenceCriterion;
    judgementLabel = 'resourceRequirementsEvidenceCertaintyJudgement';
    resourcesRequiredCertaintyOfEvidenceCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('resources', 'whats_certainty_of_resource_requirements'), this._v2certaintyOptions, this._getNameForOptionLabel(this._originalQuestion[judgementLabel]), this._originalQuestion['resourceRequirementsCertaintyEvidence'], this._originalQuestion['resourceRequirementsEvidenceCertaintyAdditionalConsiderations']);
    return this._makeSection('ResourcesRequiredCertaintyOfEvidenceSection', this._getTitleForSectionLabel('resources', 'required_costs_evidence_certainty'), resourcesRequiredCertaintyOfEvidenceCriterion);
  };

  EtdV2DiagnosticRecommendationsExporter.prototype._exportCostEffectivenessSection = function() {
    var resourcesRequiredCertaintyOfEvidenceCriterion;
    resourcesRequiredCertaintyOfEvidenceCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('resources', 'does_cost_favor_test_or_comparison'), this._v2balanceOptions, this._getNameForOptionLabel(this._originalQuestion['costEffectivenessJudgement']), this._originalQuestion['costEffectivenessEvidence'], this._originalQuestion['costEffectivenessAdditionalConsiderations']);
    return this._makeSection('CostEffectivenessSection', this._getTitleForSectionLabel('resources', 'cost_effectiveness'), resourcesRequiredCertaintyOfEvidenceCriterion);
  };

  EtdV2DiagnosticRecommendationsExporter.prototype._exportEquitySection = function() {
    var inequitiesCriterion;
    inequitiesCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('equity', 'what_would_be_health_equity_impact'), this._v2inequitiesOptions, this._getNameForOptionLabel(this._originalQuestion['healthEquityJudgement']));
    return this._makeSection('EquitySection', this._getTitleForSectionLabel('equity'), inequitiesCriterion, this._originalQuestion['healthEquityEvidence'], this._originalQuestion['healthEquityAdditionalConsiderations']);
  };

  EtdV2DiagnosticRecommendationsExporter.prototype._exportAcceptabilitySection = function() {
    var acceptabilityCriterion;
    acceptabilityCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('acceptability', 'is_test_acceptable'), this._v2standardOptions, this._getNameForOptionLabel(this._originalQuestion['isTestAcceptableJudgement']));
    return this._makeSection('AcceptabilitySection', this._getTitleForSectionLabel('acceptability'), acceptabilityCriterion, this._originalQuestion['isTestAcceptableEvidence'], this._originalQuestion['isTestAcceptableAdditionalConsiderations']);
  };

  EtdV2DiagnosticRecommendationsExporter.prototype._exportFeasibilitySection = function() {
    var feasibilityCriterion;
    feasibilityCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('feasibility', 'is_test_feasible'), this._v2standardOptions, this._getNameForOptionLabel(this._originalQuestion['isTestFeasibleJudgement']));
    return this._makeSection('FeasibilitySection', this._getTitleForSectionLabel('feasibility'), feasibilityCriterion, this._originalQuestion['isTestFeasibleEvidence'], this._originalQuestion['isTestFeasibleAdditionalConsiderations']);
  };

  EtdV2DiagnosticRecommendationsExporter.prototype.exportSections = function() {
    return [this._exportProblemSection(), this._exportTestAccuracySection(), this._exportDesirableEffectsSection(), this._exportUndesirableEffectsSection(), this._exportTestAccuracyCertaintyOfEvidenceSection(), this._exportTestEffectsCertaintyOfEvidenceSection(), this._exportManagementEffectsCertaintyOfEvidenceSection(), this._exportTestResultManagementEffectsCertaintyOfEvidenceSection(), this._exportEffectsCertaintyOfEvidenceSection(), this._exportValuesSection(), this._exportBalanceOfEffectsSection(), this._exportResourcesRequiredSection(), this._exportResourcesRequiredCertaintyOfEvidenceSection(), this._exportCostEffectivenessSection(), this._exportEquitySection(), this._exportAcceptabilitySection(), this._exportFeasibilitySection()];
  };

  EtdV2DiagnosticRecommendationsExporter.prototype.exportRecommendationType = function() {
    var recommendationType;
    recommendationType = this._originalQuestion['consequencesBalance'];
    if (recommendationType) {
      return $.t("es:recommendations.table.final_recommendations_judgements." + recommendationType);
    } else {
      return '';
    }
  };

  EtdV2DiagnosticRecommendationsExporter.prototype.exportConsequencesBalance = function() {
    return null;
  };

  EtdV2DiagnosticRecommendationsExporter.prototype.exportRecommendations = function() {
    var _ref;
    return (_ref = this._originalQuestion['recommendationText']) != null ? _ref : '';
  };

  EtdV2DiagnosticRecommendationsExporter.prototype.exportJustification = function() {
    var _ref;
    return (_ref = this._originalQuestion['recommendationJustification']) != null ? _ref : '';
  };

  EtdV2DiagnosticRecommendationsExporter.prototype.exportSubgroupConsiderations = function() {
    var _ref;
    return (_ref = this._originalQuestion['subgroupConsiderationsText']) != null ? _ref : '';
  };

  EtdV2DiagnosticRecommendationsExporter.prototype.exportImplementationConsiderations = function() {
    var _ref;
    return (_ref = this._originalQuestion['implementationConsiderations']) != null ? _ref : '';
  };

  EtdV2DiagnosticRecommendationsExporter.prototype.exportMonitoringAndEvaluation = function() {
    var _ref;
    return (_ref = this._originalQuestion['monitoringAndEvaluation']) != null ? _ref : '';
  };

  EtdV2DiagnosticRecommendationsExporter.prototype.exportResearchPriorities = function() {
    var _ref;
    return (_ref = this._originalQuestion['researchPriorities']) != null ? _ref : '';
  };

  EtdV2DiagnosticRecommendationsExporter.prototype.exportHeader = function() {
    var header;
    header = {};
    if (this._fieldExistsAndIsNotEmpty(this._originalQuestion['headerPatientsField'])) {
      header['patients'] = this._originalQuestion['headerPatientsField'];
    }
    if (this._fieldExistsAndIsNotEmpty(this._originalQuestion['headerPurposeField'])) {
      header['purpose'] = this._originalQuestion['headerPurposeField'];
    }
    if (this._fieldExistsAndIsNotEmpty(this._originalQuestion['headerLinkedTreatmentField'])) {
      header['linkedTreatment'] = this._originalQuestion['headerLinkedTreatmentField'];
    }
    if (this._fieldExistsAndIsNotEmpty(this._originalQuestion['headerAnticipatedOutcomes'])) {
      header['anticipatedOutcomes'] = this._originalQuestion['headerAnticipatedOutcomes'];
    }
    if (this._fieldExistsAndIsNotEmpty(this._originalQuestion['headerSettingField'])) {
      header['setting'] = this._originalQuestion['headerSettingField'];
    }
    if (this._fieldExistsAndIsNotEmpty(this._originalQuestion['headerPerspectiveField'])) {
      header['perspective'] = this._originalQuestion['headerPerspectiveField'];
    }
    if (this._fieldExistsAndIsNotEmpty(this._originalQuestion['headerBackgroundField'])) {
      header['background'] = this._originalQuestion['headerBackgroundField'];
    }
    return header;
  };

  return EtdV2DiagnosticRecommendationsExporter;

})(RecommendationsExporter);
