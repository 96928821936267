var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isDiagnostic : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.program(4, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    <li class=\"edit-option\" tabindex=\"1\"\n      ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.fullName : stack1), "ObsStudies.cross_sectional_cohort_type", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.cross_sectional_cohort_type", {"name":"i18n","hash":{},"data":data})))
    + "\n    </li>\n    <li class=\"edit-option\" tabindex=\"2\"\n      ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.fullName : stack1), "ObsStudies.case_control_type_accuracy", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.case_control_type_accuracy", {"name":"i18n","hash":{},"data":data})))
    + "\n    </li>\n    <li class=\"edit-option\" tabindex=\"3\"\n      ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.fullName : stack1), "ObsStudies.cohort_and_case_control_type", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.cohort_and_case_control_type", {"name":"i18n","hash":{},"data":data})))
    + "\n    </li>\n";
},"4":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isPrognostic : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.program(7, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "      <li class=\"edit-option\" tabinxed=\"0\"\n        ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.name : stack1), "RandTrials", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.randomised_trials", {"name":"i18n","hash":{},"data":data})))
    + "\n      </li>\n      <li class=\"edit-option\" tabindex=\"1\"\n        ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.fullName : stack1), "ObsStudies.cohort_studies", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.cohort_studies", {"name":"i18n","hash":{},"data":data})))
    + "\n      </li>\n      <li class=\"edit-option\" tabindex=\"2\"\n      ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.fullName : stack1), "ObsStudies.single_arm_studies", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.single_arm_studies", {"name":"i18n","hash":{},"data":data})))
    + "\n      </li>\n";
},"7":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "      <li class=\"edit-option\" tabinxed=\"0\"  ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.name : stack1), "RandTrials", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.randomised_trials", {"name":"i18n","hash":{},"data":data})))
    + "</li>\n      <ul name=\"designStudies\" class=\"inner-options-list\">\n        <li class=\"inactive-option\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.observational_studies", {"name":"i18n","hash":{},"data":data})))
    + "</li>\n        <li class=\"edit-option\" tabindex=\"1\"\n          ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.fullName : stack1), "ObsStudies.interrupted_time_series", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.interrupted_time_series", {"name":"i18n","hash":{},"data":data})))
    + "\n        </li>\n        <li class=\"edit-option\" tabindex=\"2\"\n          ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.fullName : stack1), "ObsStudies.before_after_studies", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.before_after_studies", {"name":"i18n","hash":{},"data":data})))
    + "\n        </li>\n        <li class=\"edit-option\" tabindex=\"3\"\n          ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.fullName : stack1), "ObsStudies.cohort_studies", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.cohort_studies", {"name":"i18n","hash":{},"data":data})))
    + "\n        </li>\n        <li class=\"edit-option\" tabindex=\"4\"\n          ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.fullName : stack1), "ObsStudies.case_control_studies", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.case_control_studies", {"name":"i18n","hash":{},"data":data})))
    + "\n        </li>\n        <li class=\"edit-option\" tabindex=\"5\"\n          ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.fullName : stack1), "ObsStudies.cross_sectional_studies", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.cross_sectional_studies", {"name":"i18n","hash":{},"data":data})))
    + "\n        </li>\n        <li class=\"edit-option\" tabindex=\"6\"\n          ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.fullName : stack1), "ObsStudies.case_series", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.case_series", {"name":"i18n","hash":{},"data":data})))
    + "\n        </li>\n        <li class=\"edit-option\" tabindex=\"7\"\n          ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.fullName : stack1), "ObsStudies.case_reports", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.case_reports", {"name":"i18n","hash":{},"data":data})))
    + "\n        </li>\n        <li class=\"edit-option\" tabindex=\"8\"\n          ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.fullName : stack1), "ObsStudies.case_control_and_other_combined", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.case_control_and_other_combined", {"name":"i18n","hash":{},"data":data})))
    + "\n        </li>\n        <li class=\"edit-option\" tabindex=\"9\"\n          ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.fullName : stack1), "ObsStudies.other_design", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.other_design", {"name":"i18n","hash":{},"data":data})))
    + "\n        </li>\n";
  stack1 = this.invokePartial(partials.clearOption, '        ', 'clearOption', ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.name : stack1), undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </ul>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.selectionList || (depth0 && depth0.selectionList) || helperMissing).call(depth0, "designStudies", "study_design", {"name":"selectionList","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}