var PouchSource, QuestionModel, QuestionsActions, QuestionsStore, alt, immutable, mediator, _getDataForEvidenceShort, _getDataForSofShort, _prepareOutcomes;

alt = require('alt');

immutable = require('stores/utils/immutable');

mediator = require('mediator');

PouchSource = require('stores/sources/pouch_source');

QuestionsActions = require('actions/questions_actions');

QuestionModel = require('models/question');

_getDataForSofShort = function(state) {
  var data;
  return data = state.filter(function(v, k) {
    return ['comparison', 'intervention', 'outcomes'].indexOf(k) > -1;
  }).update('outcomes', function(outcomes) {
    return _prepareOutcomes(outcomes);
  }).mapKeys(function(k) {
    if (k === 'outcomes') {
      return 'includedOutcomes';
    } else {
      return k;
    }
  });
};

_prepareOutcomes = function(outcomes) {
  var CONTROL_ATTRIBUTES;
  CONTROL_ATTRIBUTES = ['calculatedControl', 'lowControl', 'moderateControl', 'highControl'];
  return outcomes.filter(function(outcome) {
    return outcome.get('importance') > 3;
  }).map(function(outcome) {
    var enabledRisks;
    enabledRisks = CONTROL_ATTRIBUTES.filter(function(attrName) {
      return outcome.get(attrName);
    });
    return outcome.set('headControl', enabledRisks[0]).set('tailControls', enabledRisks.slice(1)).set('rowspan', enabledRisks.length);
  });
};

_getDataForEvidenceShort = function(state) {
  return state.set('dxL1Outcomes', state.get('outcomes').toJS());
};

QuestionsStore = (function() {
  function QuestionsStore() {
    this.state = Immutable.Map({
      question: {},
      questionModel: {}
    });
    this.exportPublicMethods({
      getDataForTable: this.getDataForTable,
      getQuestionModel: this.getQuestionModel,
      getQuestion: this.getQuestion,
      getQuestionType: this.getQuestionType,
      getCriticalOutcomes: this.getCriticalOutcomes,
      getQuestionName: this.getQuestionName,
      isFetching: this.isFetching
    });
    this.bindActions(QuestionsActions);
    this.registerAsync(PouchSource(QuestionsActions));
  }

  QuestionsStore.prototype.getQuestionType = function() {
    var type;
    type = this.state.getIn(['question', 'type']);
    if (type === 'diagnostic') {
      return 'dx';
    } else {
      return 'tx';
    }
  };

  QuestionsStore.prototype.getQuestionName = function() {
    return this.state.getIn(['question', 'question']);
  };

  QuestionsStore.prototype.onFetch = function(params) {
    var dbId, docId;
    this.setState(this.state.setIn(['_meta', 'fetching'], true));
    dbId = params.dbId, docId = params.docId;
    if (dbId && docId) {
      return this.getInstance().fetch(dbId, docId);
    } else if (docId) {
      return this.getInstance().fetch(mediator.project.id, docId);
    } else {
      throw new Error('docId was not supplied!');
    }
  };

  QuestionsStore.prototype.onPouchFetchSuccess = function(data) {
    return this.setState(this.state.set('question', Immutable.fromJS(data)).set('questionModel', new QuestionModel(data)).setIn(['_meta', 'fetching'], false));
  };

  QuestionsStore.prototype.onPouchFetchFailure = function(e) {
    this.setState(this.state.setIn(['_meta', 'fetching'], false));
    throw e;
  };

  QuestionsStore.prototype.getQuestion = function() {
    return this.state.get('question');
  };

  QuestionsStore.prototype.getQuestionModel = function() {
    return this.state.get('questionModel');
  };

  QuestionsStore.prototype.getDataForTable = function(tableName) {
    switch (tableName) {
      case 'sof_short':
      case 'relative_importance':
        return _getDataForSofShort(this.state.get('question'));
      case 'evidence_short':
        return _getDataForEvidenceShort(this.state.get('question'));
      default:
        return this.state.get('question');
    }
  };

  QuestionsStore.prototype.getCriticalOutcomes = function() {
    var _ref;
    return (_ref = this.state.getIn(['question', 'outcomes'])) != null ? _ref.filter(function(outcome) {
      var importance;
      importance = outcome.get('importance');
      return importance >= 7 && importance <= 9;
    }) : void 0;
  };

  QuestionsStore.prototype.isFetching = function() {
    return this.state.getIn(['_meta', 'fetching']);
  };

  return QuestionsStore;

})();

module.exports = alt.createStore(immutable(QuestionsStore), 'QuestionsStore');
