ContentEditable = require './content_editable'
Button = require './button'
PureRenderMixin = React.addons.PureRenderMixin
Translation = require 'components/mixins/translation'

ContentEditableWithButtons = React.createClass
  mixins: [Translation(''), PureRenderMixin]
  displayName: "ContentEditableWithButtons"

  propTypes:
    actions: React.PropTypes.object.isRequired
    # need to be able to identify the content editable
    id: React.PropTypes.string.isRequired
    # need to know how to render the contentEditable
    currentEditable: React.PropTypes.string.isRequired

  componentDidMount: ->
    @initialHTML = @props.content

  componentWillReceiveProps: (nextProps) ->
    # below ensures that once we switch to another editable we update the value of @initialHTML,
    # otherwise if we come back to this editable and during edit press cancel we will get the
    # very first HTML back and not the one which was applied when we moved to another editable.
    # Also we trigger content saving to DB
    if @_activeEditable() and not @_activeEditable(nextProps) and not @cancelled
      currentHTML = @refs.contentEditable.getDOMNode().innerHTML
      contentWasUpdated = @initialHTML isnt currentHTML
      if contentWasUpdated
        @initialHTML = currentHTML
        @props.actions.saveContentEditable()
    else
      @cancelled = false

  focus: ->
    if @_activeEditable()
      @refs.contentEditable._focus()

  componentWillUnmount: ->
    @_close() if @props.isActiveEditable

  onClick: ->
    @props.actions.enableContentEditable @props.id

  handleInput: (e) ->
    @props.actions.updateContentEditableContent @props.id, e.target.innerHTML

  cancel: ->
    @props.actions.updateContentEditableContent @props.id, @initialHTML
    @cancelled = true
    @_close()

  apply: ->
    @_close()

  _close: ->
    @props.actions.disableContentEditable()

  _activeEditable: (props = @props) ->
    props.currentEditable is props.id

  render: ->
    <div className="editor-container">
      {if @_activeEditable()
        <div>
          {if @props.topButtons
            <div className="top-buttons row">
              {@props.topButtons}
            </div>
          }
          <ContentEditable
            ref="contentEditable"
            html={@props.content}
            onInput={@handleInput}
          />
          <div className="row buttons-react">
            <div className="col-6">
              <Button
                className="cancel btn btn-block"
                label={@i18n 'actions.cancel'}
                onMouseDown={@cancel}/>
            </div>
            <div className="col-6">
              <Button
                className="apply btn btn-block btn-success"
                label={@i18n 'actions.apply'}
                onMouseDown={@apply}/>
            </div>
          </div>
        </div>
      else
        <ContentEditable
          contentEditable={false}
          onClick={@onClick}
          html={@props.content}
        />
      }
    </div>

module.exports = ContentEditableWithButtons
