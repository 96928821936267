var Controller, GdtRouter, ProjectController, Projects, ProjectsOverview, TasksController, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Projects = require('models/projects');

ProjectsOverview = require('views/projects_overview_view');

Controller = require('controllers/base/controller');

TasksController = require('./tasks_controller');

GdtRouter = require('router');

mediator = require('mediator');

module.exports = ProjectController = (function(_super) {
  __extends(ProjectController, _super);

  function ProjectController() {
    return ProjectController.__super__.constructor.apply(this, arguments);
  }

  ProjectController.prototype.historyURL = 'projects';

  ProjectController.prototype.index = function(params) {
    var showWelcome;
    showWelcome = _.isEmpty(mediator.user.getProjects());
    return this.view = new ProjectsOverview({
      collection: mediator.projects,
      welcome: showWelcome
    });
  };

  ProjectController.prototype.start = function(params) {
    return this.redirectTo(GdtRouter.prototype.routeForModule('evidence-syntheses', params));
  };

  ProjectController.prototype.mostRecentChange = function() {
    return alert('implement me');
  };

  ProjectController.prototype.myMostRecentChange = function() {
    return alert('implement me');
  };

  return ProjectController;

})(Controller);
