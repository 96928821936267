var Ellipsis, OutcomeEditRowView, OutcomeLabelRowView, SelectableAttrList, formatters, mediator, outcomeCellTypes, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

OutcomeEditRowView = require('views/evidence_syntheses/outcome_edit_row_view');

Ellipsis = require('base/lib/traits/ellipsis');

SelectableAttrList = require('lib/traits/selectable_attr_list');

template = require('views/templates/evidence_syntheses/outcome_label_row');

mediator = require('mediator');

formatters = require('lib/formatters');

outcomeCellTypes = require('models/outcome/outcome_cell_types');

module.exports = OutcomeLabelRowView = (function(_super) {
  __extends(OutcomeLabelRowView, _super);

  function OutcomeLabelRowView() {
    this.hideEditCallback = __bind(this.hideEditCallback, this);
    this.toggleFollowUpTimeUnit = __bind(this.toggleFollowUpTimeUnit, this);
    return OutcomeLabelRowView.__super__.constructor.apply(this, arguments);
  }

  OutcomeLabelRowView.prototype.template = template;

  OutcomeLabelRowView.prototype.footnotesBindings = (function() {
    var bindings, footAttr, _i, _len, _ref;
    bindings = {};
    _ref = outcomeCellTypes.oneRowOutcomeLabel.footnotesAttrs.common;
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      footAttr = _ref[_i];
      bindings[footAttr] = [
        {
          selector: ".footnote-" + footAttr,
          method: 'after'
        }, {
          selector: '.outcome-label span.outcome-name',
          method: 'after'
        }
      ];
    }
    return bindings;
  })();

  OutcomeLabelRowView.prototype.highlightFootnoteBingings = OutcomeLabelRowView.prototype.footnotesBindings;

  OutcomeLabelRowView.prototype.initialize = function() {
    var outcomeLabelBinding;
    OutcomeLabelRowView.__super__.initialize.apply(this, arguments);
    outcomeLabelBinding = {
      '.outcome-label .name-block': {
        observe: 'name',
        updateMethod: 'html',
        onGet: function(val) {
          return formatters.outcome.oneRowLabel(this.model.attributes);
        }
      }
    };
    _(this.bindings).extend(outcomeLabelBinding);
    this.delegate('click', '.outcome-label button.edit', this.showOutcomeEditView);
    this.delegate('change', '#followUpTimeUnit', function(e) {
      return this.toggleOthersInputField(e, 'input[name=othersFollowUpTimeUnit]');
    });
    this.delegate('change', '#followUpToTimeUnit', function(e) {
      return this.toggleOthersInputField(e, 'input[name=othersFollowUpToTimeUnit]');
    });
    this.delegate('change', '#followUpType', this.toggleFollowUpTimeUnit);
    this.enable(Ellipsis, {
      selector: '.outcome-label .outcome-name',
      row: 1
    });
    return this.enable(SelectableAttrList);
  };

  OutcomeLabelRowView.prototype.toggleOthersInputField = function(e, selector) {
    var val;
    val = this.$(e.target).val();
    if (val === 'others' || val === 'other') {
      this.$(selector).show();
      return this.$(selector).closest('div').addClass('other');
    } else {
      this.$(selector).hide();
      return this.$(selector).closest('div').removeClass('other');
    }
  };

  OutcomeLabelRowView.prototype.toggleFollowUpTimeUnit = function(e) {
    var $target;
    $target = $(e.target);
    if ($target.val() === 'range') {
      this.$('#range-to').show();
      return this.$('#range-to').parents('#follow-up-length-row').addClass('ranged');
    } else {
      this.$('#range-to').hide();
      return this.$('#range-to').parents('#follow-up-length-row').removeClass('ranged');
    }
  };

  OutcomeLabelRowView.prototype.delegateFootnotesEvents = function() {
    this.delegate('focus click', 'input[data-property="name"],\ninput[data-property="shortName"],\ninput[data-property="measuredWith"],\ninput[data-property="type"],\ninput[data-property="metaType"]', function(e) {
      var $target;
      $target = $(e.target);
      return this._setPropertyAndPublishActivatedPropertyEvent($target.data('property'), $target);
    });
    return this.delegate('focus click', 'input[data-property="followUpLength"],\ninput[data-property="followUpType"],\ninput[data-property="followUpTimeUnit"],\ninput[data-property="othersFollowUpTimeUnit"]', function(e) {
      return this._setPropertyAndPublishActivatedPropertyEvent('followUpLength', $(e.target));
    });
  };

  OutcomeLabelRowView.prototype.hideEditCallback = function() {
    if (this.disposed) {
      return;
    }
    this.render();
    this.$el.removeClass('edit-label-state');
    this.$('.outcome-edit-block').hide();
    return this.$('.outcome-label').show();
  };

  OutcomeLabelRowView.prototype.showOutcomeEditView = function(e) {
    if (e != null) {
      e.preventDefault();
    }
    if (e != null) {
      e.stopPropagation();
    }
    return mediator.setFocus(this, (function(_this) {
      return function() {
        _this._assignCurrentOutcomeEditView();
        _this.$('.outcome-label-row').addClass('edit-label-state');
        _this.$('.outcome-label').hide();
        _this.$('.outcome-edit-block').show();
        _this.toggleTimingOfExposure();
        return _this.$('.outcome-toggleable-editor').slideDown(function() {
          setTimeout((function() {
            return _this.$('.label-input input').focus();
          }), 0);
          return _this.parentView.trigger('!fixLayout');
        });
      };
    })(this));
  };

  OutcomeLabelRowView.prototype.toggleTimingOfExposure = function() {
    var _ref;
    if (((_ref = this.model.get('designStudies')) != null ? _ref.value : void 0) === 'case_control_studies') {
      this.$('.timing-of-exposure-label').show();
      this.$('.timing-of-exposure').show();
      return this.$('.follow-up-label').hide();
    } else {
      this.$('.timing-of-exposure-label').hide();
      this.$('.timing-of-exposure').hide();
      return this.$('.follow-up-label').show();
    }
  };

  OutcomeLabelRowView.prototype.render = function() {
    OutcomeLabelRowView.__super__.render.apply(this, arguments);
    return _.defer((function(_this) {
      return function() {
        return _this.trigger('!fixLayout');
      };
    })(this));
  };

  return OutcomeLabelRowView;

})(OutcomeEditRowView);
