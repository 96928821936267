var Project, Question, Questions, gdtBackup, mediator, sequence;

Project = require('models/project');

Question = require('models/question');

Questions = require('models/questions');

mediator = require('mediator');

sequence = require('when/sequence');

gdtBackup = {
  _parseFileContent: function(fileContent) {
    var projectsJSON;
    fileContent = fileContent.replace(/"id":/g, '"decide_id":');
    return projectsJSON = $.parseJSON(fileContent);
  },
  createProjects: function(fileContent) {
    var parsedFile, projectWithQuesions;
    parsedFile = this._parseFileContent(fileContent);
    return sequence((function() {
      var _i, _len, _ref, _results;
      _ref = parsedFile.projects;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        projectWithQuesions = _ref[_i];
        _results.push(this._createProjectWithQuestions(projectWithQuesions));
      }
      return _results;
    }).call(this));
  },
  _createProjectWithQuestions: function(projectWithQuesions) {
    return (function(_this) {
      return function() {
        var p;
        p = new Project(projectWithQuesions['project']);
        return mediator.projects.create(p, {
          modifyTime: false
        }).then(function(project) {
          var qs, question, _i, _len, _ref;
          qs = new Questions(null, {
            url: project.id
          });
          _ref = projectWithQuesions['questions'];
          for (_i = 0, _len = _ref.length; _i < _len; _i++) {
            question = _ref[_i];
            _this._renameFootnotesAttr(question);
            qs.create(question, {
              modifyTime: false,
              error: function(err) {
                throw new Error("Creating questions failed. Reason: " + err.message);
              }
            });
          }
          return project;
        }, function(err) {
          throw new Error("Creating project failed. Reason: " + err.message);
        });
      };
    })(this);
  },
  _renameFootnotesAttr: function(question) {
    question.footnotesList = question.footnotes;
    return delete question.footnotes;
  }
};

module.exports = gdtBackup;
