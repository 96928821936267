var NextStepButton, View, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

mediator = require('mediator');

template = require('views/templates/scope/next_step_button_view');

module.exports = NextStepButton = (function(_super) {
  __extends(NextStepButton, _super);

  function NextStepButton() {
    return NextStepButton.__super__.constructor.apply(this, arguments);
  }

  NextStepButton.prototype.tagName = 'div';

  NextStepButton.prototype.className = 'col-12';

  NextStepButton.prototype.template = template;

  NextStepButton.prototype.autoRender = true;

  NextStepButton.prototype.initialize = function() {
    NextStepButton.__super__.initialize.apply(this, arguments);
    if (!this.options.container) {
      throw new Error('container must be defined');
    }
    if (!this.options.step) {
      throw new Error('step must be defined');
    }
    return this.delegate('click', 'button', (function(_this) {
      return function() {
        if (_this.validate()) {
          return mediator.dialogs.confirm($.t('scope:questions.confirm_next_step'), function(confirmed) {
            if (confirmed) {
              return mediator.publish('stepFinished', _this.options.step);
            }
          });
        }
      };
    })(this));
  };

  NextStepButton.prototype.getTemplateData = function() {
    return {
      text: $.t("scope:" + this.options.step + ".next_step")
    };
  };

  NextStepButton.prototype.validate = function() {
    var valid;
    valid = true;
    if (this.options.getItemsToValidate) {
      _.each(this.options.getItemsToValidate(), function(itemView) {
        if (itemView.isAbandoningChanges()) {
          return valid = false;
        }
      });
      if (!valid) {
        mediator.dialogs.warning({
          message: $.t('messages.unsaved_questions')
        });
      }
    }
    return valid;
  };

  return NextStepButton;

})(View);
