var sections;

sections = {
  question: {
    docId: "",
    type: "",
    sectionsOrder: [],
    sections: {
      population: {
        name: 'Population',
        content: ""
      },
      intervention: {
        name: 'Intervention',
        content: ""
      },
      comparison: {
        name: 'Comparison',
        content: ""
      },
      mainOutcomes: {
        name: 'Main outcomes',
        content: ""
      },
      setting: {
        name: 'Setting',
        content: ""
      },
      perspective: {
        name: 'Perspective',
        content: ""
      },
      background: {
        name: 'Background',
        content: ""
      },
      problem: {
        name: "Problem",
        content: ""
      },
      option: {
        name: "Option",
        content: ""
      },
      purpose: {
        name: "Purpose",
        content: ""
      },
      linkedTreatments: {
        name: "Linked treatments",
        content: ""
      },
      anticipatedOutcomes: {
        name: "Anticipated outcomes",
        content: ""
      },
      draftRecommendation: {
        name: "Draft recommendation",
        content: ""
      }
    }
  },
  assessment: {
    sectionsOrder: [],
    sections: {
      problem: {
        name: 'Problem',
        criterionId: 'problem',
        researchEvidenceId: 'problem',
        additionalConsiderationId: 'problem'
      },
      desirableEffects: {
        name: "Desirable Effects",
        criterionId: "desirableEffects",
        researchEvidenceId: "effects",
        additionalConsiderationId: "desirableEffects"
      },
      undesirableEffects: {
        name: "Undesirable Effects",
        criterionId: "undesirableEffects",
        researchEvidenceId: "effects",
        additionalConsiderationId: "undesirableEffects"
      },
      certaintyOfEvidence: {
        name: "Certainty of evidence",
        criterionId: 'certaintyOfEvidence',
        researchEvidenceId: "certaintyOfEvidence",
        additionalConsiderationId: "certaintyOfEvidence"
      },
      values: {
        name: "Values",
        criterionId: "values",
        researchEvidenceId: "values",
        additionalConsiderationId: "values"
      },
      balanceOfEffects: {
        name: "Balance of effects",
        criterionId: "balanceOfEffects",
        researchEvidenceId: "balanceOfEffects",
        additionalConsiderationId: "balanceOfEffects"
      },
      resourcesRequired: {
        name: "Resources required",
        criterionId: "resourcesRequired",
        researchEvidenceId: "resourcesRequired",
        additionalConsiderationId: "resourcesRequired"
      },
      certaintyOfEvidenceOfRequiredResources: {
        name: "Certainty of evidence of required resources",
        criterionId: "certaintyOfEvidenceOfRequiredResources",
        researchEvidenceId: "certaintyOfEvidenceOfRequiredResources",
        additionalConsiderationId: "certaintyOfEvidenceOfRequiredResources"
      },
      costEffectiveness: {
        name: "Cost effectiveness",
        criterionId: "costEffectiveness",
        researchEvidenceId: "costEffectiveness",
        additionalConsiderationId: "costEffectiveness"
      },
      equity: {
        name: "Equity",
        criterionId: "equity",
        researchEvidenceId: "equity",
        additionalConsiderationId: "equity"
      },
      acceptability: {
        name: "Acceptability",
        criterionId: "acceptability",
        researchEvidenceId: "acceptability",
        additionalConsiderationId: "acceptability"
      },
      feasibility: {
        name: "Feasibility",
        criterionId: "feasibility",
        researchEvidenceId: "feasibility",
        additionalConsiderationId: "feasibility"
      },
      testAccuracy: {
        name: "Test accuracy",
        criterionId: "testAccuracy",
        researchEvidenceId: "testAccuracy",
        additionalConsiderationId: "testAccuracy"
      },
      certaintyOfEvidenceOfTestAccuracy: {
        name: "Certainty of the evidence of test accuracy",
        criterionId: "certaintyOfEvidenceOfTestAccuracy",
        researchEvidenceId: "certaintyOfEvidenceOfTestAccuracy",
        additionalConsiderationId: "certaintyOfEvidenceOfTestAccuracy"
      },
      certaintyOfEvidenceOfTestEffects: {
        name: "Certainty of the evidence of test's effects",
        criterionId: "certaintyOfEvidenceOfTestEffects",
        researchEvidenceId: "certaintyOfEvidenceOfTestEffects",
        additionalConsiderationId: "certaintyOfEvidenceOfTestEffects"
      },
      certaintyOfEvidenceOfManagementEffects: {
        name: "Certainty of the evidence of management's effects",
        criterionId: "certaintyOfEvidenceOfManagementEffects",
        researchEvidenceId: "certaintyOfEvidenceOfManagementEffects",
        additionalConsiderationId: "certaintyOfEvidenceOfManagementEffects"
      },
      certaintyOfEvidenceOfTestResult: {
        name: "Certainty of the evidence of test result/management",
        criterionId: "certaintyOfEvidenceOfTestResult",
        researchEvidenceId: "certaintyOfEvidenceOfTestResult",
        additionalConsiderationId: "certaintyOfEvidenceOfTestResult"
      },
      certaintyOfEffects: {
        name: "Certainty of effects",
        criterionId: "certaintyOfEffects",
        researchEvidenceId: "certaintyOfEffects",
        additionalConsiderationId: "certaintyOfEffects"
      },
      problemRand: {
        name: "Problem",
        criterionId: "problemRand",
        researchEvidenceId: "problem",
        additionalConsiderationId: "problem"
      },
      desirableEffectsRand: {
        name: "Desirable effects",
        criterionId: "desirableEffectsRand",
        researchEvidenceId: "effects",
        additionalConsiderationId: "desirableEffects"
      },
      undesirableEffectsRand: {
        name: "Undesirable effects",
        criterionId: "undesirableEffectsRand",
        researchEvidenceId: "effects",
        additionalConsiderationId: "undesirableEffects"
      },
      certaintyOfEvidenceRand: {
        name: "Certainty of evidence",
        criterionId: 'certaintyOfEvidenceRand',
        researchEvidenceId: "certaintyOfEvidence",
        additionalConsiderationId: "certaintyOfEvidence"
      },
      balanceOfEffectsRand: {
        name: "Balance of effects",
        criterionId: "balanceOfEffectsRand",
        researchEvidenceId: "balanceOfEffects",
        additionalConsiderationId: "balanceOfEffects"
      },
      resourcesRequiredRand: {
        name: "Resources required",
        criterionId: "resourcesRequiredRand",
        researchEvidenceId: "resourcesRequired",
        additionalConsiderationId: "resourcesRequired"
      },
      certaintyOfEvidenceOfRequiredResourcesRand: {
        name: "Certainty of evidence of required resources",
        criterionId: "certaintyOfEvidenceOfRequiredResourcesRand",
        researchEvidenceId: "certaintyOfEvidenceOfRequiredResources",
        additionalConsiderationId: "certaintyOfEvidenceOfRequiredResources"
      },
      costEffectivenessRand: {
        name: "Cost effectiveness",
        criterionId: "costEffectivenessRand",
        researchEvidenceId: "costEffectiveness",
        additionalConsiderationId: "costEffectiveness"
      },
      valuesRand: {
        name: "Values",
        criterionId: "valuesRand",
        researchEvidenceId: "values",
        additionalConsiderationId: "values"
      },
      equityRand: {
        name: "Equity",
        criterionId: "equityRand",
        researchEvidenceId: "equity",
        additionalConsiderationId: "equity"
      },
      acceptabilityRand: {
        name: "Acceptability",
        criterionId: "acceptabilityRand",
        researchEvidenceId: "acceptability",
        additionalConsiderationId: "acceptability"
      },
      feasibilityRand: {
        name: "Feasibility",
        criterionId: "feasibilityRand",
        researchEvidenceId: "feasibility",
        additionalConsiderationId: "feasibility"
      }
    },
    criteria: {
      problem: {
        description: "Is the problem a priority?",
        info: "The more serious or urgent a problem is, the more likely it is that an option that addresses the problem will be a priority",
        options: [
          {
            text: "No",
            value: "no"
          }, {
            text: "Probably no",
            value: "probably_no"
          }, {
            text: "Probably yes",
            value: "probably_yes"
          }, {
            text: "Yes",
            value: "yes"
          }
        ],
        additionalOptions: [
          {
            text: "Varies",
            value: "varies"
          }, {
            text: "Don't know",
            value: "dont_know"
          }
        ],
        selectedOption: "",
        decision: {
          selectedOption: "",
          comments: ""
        },
        details: {
          panelDiscussion: "",
          questionsType: "general",
          questions: [
            {
              question: "Are the consequences of the problem serious (i.e. severe or important in terms of the potential benefits or savings)?",
              options: [
                {
                  text: "No",
                  value: "no"
                }, {
                  text: "Probably no",
                  value: "probably_no"
                }, {
                  text: "Probably yes",
                  value: "probably_yes"
                }, {
                  text: "Yes",
                  value: "yes"
                }
              ],
              additionalOptions: [
                {
                  text: "Varies",
                  value: "varies"
                }, {
                  text: "Don't know",
                  value: "dont_know"
                }
              ],
              selectedOption: ""
            }, {
              question: "Is the problem urgent?",
              options: [
                {
                  text: "No",
                  value: "no"
                }, {
                  text: "Probably no",
                  value: "probably_no"
                }, {
                  text: "Probably yes",
                  value: "probably_yes"
                }, {
                  text: "Yes",
                  value: "yes"
                }
              ],
              additionalOptions: [
                {
                  text: "Varies",
                  value: "varies"
                }, {
                  text: "Don't know",
                  value: "dont_know"
                }
              ],
              selectedOption: ""
            }, {
              question: "Is it a recognized priority (e.g. based on a political or policy decision)?",
              options: [
                {
                  text: "No",
                  value: "no"
                }, {
                  text: "Probably no",
                  value: "probably_no"
                }, {
                  text: "Probably yes",
                  value: "probably_yes"
                }, {
                  text: "Yes",
                  value: "yes"
                }
              ],
              additionalOptions: [
                {
                  text: "Varies",
                  value: "varies"
                }, {
                  text: "Don't know",
                  value: "dont_know"
                }
              ],
              selectedOption: ""
            }
          ]
        }
      },
      desirableEffects: {
        description: "How substantial are the desirable anticipated effects?",
        info: "How large are the desirable effects of the intervention taking into account the importance of the outcomes (how much they are valued), and the size of the effect (the likelihood of experiencing a benefit or how much of an improvement individuals would be likely to experience)?",
        options: [
          {
            text: "Trivial",
            value: "trivial"
          }, {
            text: "Small",
            value: "small"
          }, {
            text: "Moderate",
            value: "moderate"
          }, {
            text: "Large",
            value: "large"
          }
        ],
        additionalOptions: [
          {
            text: "Varies",
            value: "varies"
          }, {
            text: "Don't know",
            value: "dont_know"
          }
        ],
        selectedOption: "",
        decision: {
          selectedOption: "",
          comments: ""
        },
        details: {
          panelDiscussion: "",
          questionsType: 'outcomes',
          questions: [
            {
              question: "How substantial is the anticipated effect (difference) for each main outcome for which there is a desirable effect?",
              options: [
                {
                  text: "Trivial",
                  value: "trivial"
                }, {
                  text: "Small",
                  value: "small"
                }, {
                  text: "Moderate",
                  value: "moderate"
                }, {
                  text: "Large",
                  value: "large"
                }
              ],
              additionalOptions: [
                {
                  text: "Varies",
                  value: "varies"
                }, {
                  text: "Don't know",
                  value: "dont_know"
                }
              ],
              selectedOptions: {}
            }
          ]
        }
      },
      undesirableEffects: {
        description: "How substantial are the undesirable anticipated effects?",
        info: "How large are the undesirable effects of the intervention taking into account the importance of the outcomes (how much they are valued), and the size of the effect (the likelihood of experiencing a benefit or how much of an improvement individuals would be likely to experience)?",
        options: [
          {
            text: "Large",
            value: "large"
          }, {
            text: "Moderate",
            value: "moderate"
          }, {
            text: "Small",
            value: "small"
          }, {
            text: "Trivial",
            value: "trivial"
          }
        ],
        additionalOptions: [
          {
            text: "Varies",
            value: "varies"
          }, {
            text: "Don't know",
            value: "dont_know"
          }
        ],
        selectedOption: "",
        decision: {
          selectedOption: "",
          comments: ""
        },
        details: {
          panelDiscussion: "",
          questionsType: "outcomes",
          questions: [
            {
              question: "How substantial is the anticipated effect (difference) for each main outcome for which there is an undesirable effect?",
              options: [
                {
                  text: "Large",
                  value: "large"
                }, {
                  text: "Moderate",
                  value: "moderate"
                }, {
                  text: "Small",
                  value: "small"
                }, {
                  text: "Trivial",
                  value: "trivial"
                }
              ],
              additionalOptions: [
                {
                  text: "Varies",
                  value: "varies"
                }, {
                  text: "Don't know",
                  value: "dont_know"
                }
              ],
              selectedOptions: {}
            }
          ]
        }
      },
      certaintyOfEvidence: {
        description: "What is the overall certainty of the evidence of effects?",
        info: "How good an indication does the research provide of the likely effects across all of the critical outcomes; i.e. the likelihood that the effects will be different enough from what the research found that it might affect a decision about the intervention?",
        options: [
          {
            text: "Very low",
            value: "very_low"
          }, {
            text: "Low",
            value: "low"
          }, {
            text: "Moderate",
            value: "moderate"
          }, {
            text: "High",
            value: "high"
          }
        ],
        additionalOptions: [
          {
            text: "No included studies",
            value: "no_included_studies"
          }
        ],
        selectedOption: "",
        decision: {
          selectedOption: "",
          comments: ""
        },
        details: {
          panelDiscussion: ""
        }
      },
      values: {
        description: "Is there important uncertainty about or variability in how much people value the main outcomes?",
        info: "How much do individuals value each of the main outcomes? Is uncertainty about how much they value each of the outcomes or variability in how much different individuals value the outcomes large enough that it could lead to different decisions?",
        options: [
          {
            text: "Important uncertainty or variability",
            value: "important_uncertainty"
          }, {
            text: "Possibly important uncertainty or variability",
            value: "possible_important"
          }, {
            text: "Probably no important uncertainty or variability",
            value: "probably_no_important"
          }, {
            text: "No important uncertainty or variability",
            value: "no_important"
          }
        ],
        additionalOptions: [
          {
            text: "No known undesirable outcomes",
            value: "no_known"
          }
        ],
        selectedOption: "",
        decision: {
          selectedOption: "",
          comments: ""
        },
        details: {
          panelDiscussion: "",
          questionsType: "outcomes",
          questions: [
            {
              question: "Is there important uncertainty about how much people value the main outcomes?",
              options: [
                {
                  text: "Important uncertainty",
                  value: "important"
                }, {
                  text: "Possibly important uncertainty",
                  value: "probably_important"
                }, {
                  text: "Probably no important uncertainty",
                  value: "probably_no_important"
                }, {
                  text: "No important uncertainty",
                  value: "no_important"
                }
              ],
              additionalOptions: [
                {
                  text: "No known undesirable outcomes",
                  value: "no_known"
                }
              ],
              selectedOptions: {}
            }
          ]
        }
      },
      balanceOfEffects: {
        description: "Does the balance between desirable and undesirable effects favor the intervention or the comparison?",
        info: "What is the balance between the desirable and undesirable effects, taking into account how much individuals value the main outcomes, how substantial the desirable and undesirable effects are, the certainty of those estimates, discount rates, risk aversion and risk seeking?",
        options: [
          {
            text: "Favors the comparison",
            value: "favors_comparison"
          }, {
            text: "Probably favors the comparison",
            value: "probably_favors_comparison"
          }, {
            text: "Does not favor either the intervention or the comparison",
            value: "doesnt_favor_any"
          }, {
            text: "Probably favors the intervention",
            value: "probably_favors_intervention"
          }, {
            text: "Favors the intervention",
            value: "favors_intervention"
          }
        ],
        additionalOptions: [
          {
            text: "Varies",
            value: "varies"
          }, {
            text: "Don't know",
            value: "dont_know"
          }
        ],
        selectedOption: "",
        decision: {
          selectedOption: "",
          comments: ""
        },
        details: {
          panelDiscussion: "",
          additionalInfos: [
            {
              description: "Detailed judgements for this criterion include judgements regarding each of the four preceding criteria:",
              criteriaAnswers: [
                {
                  criterionId: "values"
                }, {
                  criterionId: "certaintyOfEvidence"
                }, {
                  criterionId: "desirableEffects"
                }, {
                  criterionId: "undesirableEffects"
                }
              ]
            }, {
              description: "In addition, panels might want to consider (and, if relevant document) the extent to which the following considerations might influence the balance between the desirable and undesirable effects:",
              additions: ["How much less people value outcomes that are in the future compared to outcomes that occur now (their discount rates)?", "People’s attitudes towards undesirable effects (how risk averse they are).", "People’s attitudes towards desirable effects (how risk seeking they are)."]
            }
          ]
        }
      },
      resourcesRequired: {
        description: "How large are the resource requirements (costs)?",
        info: "How large is the cost of the difference in resource use between the intervention and comparison?",
        options: [
          {
            text: "Large costs",
            value: "large_costs"
          }, {
            text: "Moderate costs",
            value: "moderate_costs"
          }, {
            text: "Negligible costs and savings",
            value: "negligible_costs_savings"
          }, {
            text: "Moderate savings",
            value: "moderate_savings"
          }, {
            text: "Large savings",
            value: "large_savings"
          }
        ],
        additionalOptions: [
          {
            text: "Varies",
            value: "varies"
          }, {
            text: "Don't know",
            value: "dont_know"
          }
        ],
        selectedOption: "",
        decision: {
          selectedOption: "",
          comments: ""
        },
        details: {
          panelDiscussion: "",
          questionsType: "resources",
          questions: [
            {
              question: "How large is the difference in each item of resource for which fewer resources are required?",
              options: [
                {
                  text: "Trivial",
                  value: "trivial"
                }, {
                  text: "Small",
                  value: "small"
                }, {
                  text: "Moderate",
                  value: "moderate"
                }, {
                  text: "Large",
                  value: "large"
                }
              ],
              additionalOptions: [
                {
                  text: "Varies",
                  value: "varies"
                }, {
                  text: "Don't know",
                  value: "dont_know"
                }
              ],
              resources: []
            }, {
              question: "How large is the difference in each item of resource use for which more resources are required?",
              options: [
                {
                  text: "Large",
                  value: "large"
                }, {
                  text: "Moderate",
                  value: "moderate"
                }, {
                  text: "Small",
                  value: "small"
                }, {
                  text: "Trivial",
                  value: "trivial"
                }
              ],
              additionalOptions: [
                {
                  text: "Varies",
                  value: "varies"
                }, {
                  text: "Don't know",
                  value: "dont_know"
                }
              ],
              resources: []
            }
          ]
        }
      },
      certaintyOfEvidenceOfRequiredResources: {
        description: "What is the certainty of the evidence of resource requirements (costs)?",
        info: "How certain is the evidence of a difference for each type of resource use (e.g. drugs, hospitalisations) and the cost of resources?",
        options: [
          {
            text: "Very low",
            value: "very_low"
          }, {
            text: "Low",
            value: "low"
          }, {
            text: "Moderate",
            value: "moderate"
          }, {
            text: "High",
            value: "high"
          }
        ],
        additionalOptions: [
          {
            text: "No included studies",
            value: "no_included_studies"
          }
        ],
        selectedOption: "",
        decision: {
          selectedOption: "",
          comments: ""
        },
        details: {
          panelDiscussion: "",
          questionsType: "resources",
          questions: [
            {
              question: "Have all important items of resource use that may differ between the options being considered been identified?",
              options: [
                {
                  text: "No",
                  value: "no"
                }, {
                  text: "Probably no",
                  value: "probably_no"
                }, {
                  text: "Probably yes",
                  value: "probably_yes"
                }, {
                  text: "Yes",
                  value: "yes"
                }
              ],
              additionalOptions: [
                {
                  text: "Varies",
                  value: "varies"
                }, {
                  text: "Don't know",
                  value: "dont_know"
                }
              ],
              resources: []
            }, {
              question: "How certain is the cost of the items of resource use that differ between the options being considered?",
              options: [
                {
                  text: "Important uncertainty",
                  value: "important"
                }, {
                  text: "Possibly important uncertainty",
                  value: "probably_important"
                }, {
                  text: "Probably no important uncertainty",
                  value: "probably_no_important"
                }, {
                  text: "No important uncertainty",
                  value: "no_important"
                }
              ],
              additionalOptions: [
                {
                  text: "Don't know",
                  value: "dont_know"
                }
              ],
              resources: []
            }, {
              question: "Is there important variability in the cost of the items of resource use that differ between the options being considered?",
              options: [
                {
                  text: "Important variability",
                  value: "important"
                }, {
                  text: "Possibly important variability",
                  value: "probably_important"
                }, {
                  text: "Probably no important variability",
                  value: "probably_no_important"
                }, {
                  text: "No important variability",
                  value: "no_important"
                }
              ],
              additionalOptions: [
                {
                  text: "Don't know",
                  value: "dont_know"
                }
              ],
              resources: []
            }
          ]
        }
      },
      costEffectiveness: {
        description: "Does the cost-effectiveness of the intervention favor the intervention or the comparison?",
        info: "Is the intervention cost-effective, taking into account uncertainty about or variability in the costs, uncertainty about or variability in the net benefit, sensitivity analyses, and the reliability and applicability of the economic evaluation?",
        options: [
          {
            text: "Favors the comparison",
            value: "favors_comparison"
          }, {
            text: "Probably favors the comparison",
            value: "probably_favors_comparison"
          }, {
            text: "Does not favor either the intervention or the comparison",
            value: "doesnt_favor_any"
          }, {
            text: "Probably favors the intervention",
            value: "probably_favors_intervention"
          }, {
            text: "Favors the intervention",
            value: "favors_intervention"
          }
        ],
        additionalOptions: [
          {
            text: "Varies",
            value: "varies"
          }, {
            text: "No included studies",
            value: "no_included_studies"
          }
        ],
        selectedOption: "",
        decision: {
          selectedOption: "",
          comments: ""
        },
        details: {
          panelDiscussion: "",
          questionsType: "general",
          questions: [
            {
              question: "Is the cost-effectiveness ratio sensitive to one-way sensitivity analyses?",
              options: [
                {
                  text: "No",
                  value: "no"
                }, {
                  text: "Probably no",
                  value: "probably_no"
                }, {
                  text: "Probably yes",
                  value: "probably_yes"
                }, {
                  text: "Yes",
                  value: "yes"
                }
              ],
              additionalOptions: [
                {
                  text: "No included studies",
                  value: "no_included_studies"
                }
              ],
              selectedOption: ""
            }, {
              question: "Is the cost-effectiveness ratio sensitive to multi-variable sensitivity analysis?",
              options: [
                {
                  text: "No",
                  value: "no"
                }, {
                  text: "Probably no",
                  value: "probably_no"
                }, {
                  text: "Probably yes",
                  value: "probably_yes"
                }, {
                  text: "Yes",
                  value: "yes"
                }
              ],
              additionalOptions: [
                {
                  text: "No included studies",
                  value: "no_included_studies"
                }
              ],
              selectedOption: ""
            }, {
              question: "Is the economic evaluation on which the cost-effectiveness estimate is based reliable?",
              options: [
                {
                  text: "No",
                  value: "no"
                }, {
                  text: "Very serious limitations",
                  value: "very_serious"
                }, {
                  text: "Serious limitations",
                  value: "serious"
                }, {
                  text: "No serious limitations",
                  value: "no_serious"
                }
              ],
              additionalOptions: [
                {
                  text: "No included studies",
                  value: "no_included_studies"
                }
              ],
              selectedOption: ""
            }, {
              question: "Is the economic evaluation on which the cost-effectiveness estimate is based applicable to the setting(s) of interest?",
              options: [
                {
                  text: "No",
                  value: "no"
                }, {
                  text: "Very serious limitations",
                  value: "very_serious"
                }, {
                  text: "Serious limitations",
                  value: "serious"
                }, {
                  text: "No serious limitations",
                  value: "no_serious"
                }
              ],
              additionalOptions: [
                {
                  text: "No included studies",
                  value: "no_included_studies"
                }
              ],
              selectedOption: ""
            }
          ]
        }
      },
      equity: {
        description: "What would be the impact on health equity?",
        info: "Are there plausible reasons for anticipating differences in the relative effectiveness of the intervention for disadvantaged subgroups or different baseline conditions across disadvantaged subgroups that affect the absolute effectiveness of the intervention or the importance of the problem?",
        options: [
          {
            text: "Reduced",
            value: "reduced"
          }, {
            text: "Probably reduced",
            value: "probably_reduced"
          }, {
            text: "Probably no impact",
            value: "probably_no_impact"
          }, {
            text: "Probably increased",
            value: "probably_increased"
          }, {
            text: "Increased",
            value: "increased"
          }
        ],
        additionalOptions: [
          {
            text: "Varies",
            value: "varies"
          }, {
            text: "Don't know",
            value: "dont_know"
          }
        ],
        selectedOption: "",
        decision: {
          selectedOption: "",
          comments: ""
        },
        details: {
          panelDiscussion: "",
          questionsType: "general",
          questions: [
            {
              question: "Are there groups or settings that might be disadvantaged in relation to the problem or options that are considered?",
              options: [
                {
                  text: "Yes",
                  value: "yes"
                }, {
                  text: "Probably yes",
                  value: "probably_yes"
                }, {
                  text: "Probably no",
                  value: "probably_no"
                }, {
                  text: "No",
                  value: "no"
                }
              ],
              additionalOptions: [
                {
                  text: "Don't know",
                  value: "dont_know"
                }
              ],
              selectedOption: ""
            }, {
              question: "Are there plausible reasons for anticipating differences in the relative effectiveness of the option for disadvantaged groups or settings?",
              options: [
                {
                  text: "Yes",
                  value: "yes"
                }, {
                  text: "Probably yes",
                  value: "probably_yes"
                }, {
                  text: "Probably no",
                  value: "probably_no"
                }, {
                  text: "No",
                  value: "no"
                }
              ],
              additionalOptions: [
                {
                  text: "Don't know",
                  value: "dont_know"
                }
              ],
              selectedOption: ""
            }, {
              question: "Are there different baseline conditions across groups or settings that affect the absolute effectiveness of the option or the importance of the problem for disadvantaged groups or settings?",
              options: [
                {
                  text: "Yes",
                  value: "yes"
                }, {
                  text: "Probably yes",
                  value: "probably_yes"
                }, {
                  text: "Probably no",
                  value: "probably_no"
                }, {
                  text: "No",
                  value: "no"
                }
              ],
              additionalOptions: [
                {
                  text: "Don't know",
                  value: "dont_know"
                }
              ],
              selectedOption: ""
            }, {
              question: "Are there important considerations that should be made when implementing the intervention (option) in order to ensure that inequities are reduced, if possible, and that they are not increased?",
              options: [
                {
                  text: "Yes",
                  value: "yes"
                }, {
                  text: "Probably yes",
                  value: "probably_yes"
                }, {
                  text: "Probably no",
                  value: "probably_no"
                }, {
                  text: "No",
                  value: "no"
                }
              ],
              additionalOptions: [
                {
                  text: "Don't know",
                  value: "dont_know"
                }
              ],
              selectedOption: ""
            }
          ]
        }
      },
      acceptability: {
        description: "Is the intervention acceptable to key stakeholders?",
        info: "Are key stakeholders likely not to accept the distribution of the benefits, harms and costs; or the costs or undesirable effects in the short term for desirable effects (benefits) in the future? Are they likely to disagree with the values attached to the desirable or undesirable effects, or not to accept the diagnostic intervention because of ethical concerns?",
        options: [
          {
            text: "No",
            value: "no"
          }, {
            text: "Probably no",
            value: "probably_no"
          }, {
            text: "Probably yes",
            value: "probably_yes"
          }, {
            text: "Yes",
            value: "yes"
          }
        ],
        additionalOptions: [
          {
            text: "Varies",
            value: "varies"
          }, {
            text: "Don't know",
            value: "dont_know"
          }
        ],
        selectedOption: "",
        decision: {
          selectedOption: "",
          comments: ""
        },
        details: {
          panelDiscussion: "",
          questionsType: "general",
          questions: [
            {
              question: "Are there key stakeholders that would not accept the distribution of the benefits, harms and costs?",
              options: [
                {
                  text: "Yes",
                  value: "yes"
                }, {
                  text: "Probably yes",
                  value: "probably_yes"
                }, {
                  text: "Probably no",
                  value: "probably_no"
                }, {
                  text: "No",
                  value: "no"
                }
              ],
              additionalOptions: [
                {
                  text: "Don't know",
                  value: "dont_know"
                }
              ],
              selectedOption: ""
            }, {
              question: "Are there key stakeholders that would not accept the costs or undesirable effects in the short term for desirable effects (benefits) in the future?",
              options: [
                {
                  text: "Yes",
                  value: "yes"
                }, {
                  text: "Probably yes",
                  value: "probably_yes"
                }, {
                  text: "Probably no",
                  value: "probably_no"
                }, {
                  text: "No",
                  value: "no"
                }
              ],
              additionalOptions: [
                {
                  text: "Don't know",
                  value: "dont_know"
                }
              ],
              selectedOption: ""
            }, {
              question: "Are there key stakeholders that would not agree with the values attached to the desirable or undesirable effects (because of how they might be affected personally or because of their perceptions of the relative importance of the effects for others)?",
              options: [
                {
                  text: "Yes",
                  value: "yes"
                }, {
                  text: "Probably yes",
                  value: "probably_yes"
                }, {
                  text: "Probably no",
                  value: "probably_no"
                }, {
                  text: "No",
                  value: "no"
                }
              ],
              additionalOptions: [
                {
                  text: "Don't know",
                  value: "dont_know"
                }
              ],
              selectedOption: ""
            }, {
              question: "Would the intervention (option) adversely affect people’s autonomy?",
              options: [
                {
                  text: "Yes",
                  value: "yes"
                }, {
                  text: "Probably yes",
                  value: "probably_yes"
                }, {
                  text: "Probably no",
                  value: "probably_no"
                }, {
                  text: "No",
                  value: "no"
                }
              ],
              additionalOptions: [
                {
                  text: "Don't know",
                  value: "dont_know"
                }
              ],
              selectedOption: ""
            }, {
              question: "Are there key stakeholders that would disapprove of the intervention (option) morally, for reasons other than its effects on people’s autonomy (i.e. in relationship to ethical principles such as non-maleficence, beneficence or justice)?",
              options: [
                {
                  text: "Yes",
                  value: "yes"
                }, {
                  text: "Probably yes",
                  value: "probably_yes"
                }, {
                  text: "Probably no",
                  value: "probably_no"
                }, {
                  text: "No",
                  value: "no"
                }
              ],
              additionalOptions: [
                {
                  text: "Don't know",
                  value: "dont_know"
                }
              ],
              selectedOption: ""
            }
          ]
        }
      },
      feasibility: {
        description: "Is the intervention feasible to implement?",
        info: "Is it feasible to sustain use of the diagnostic intervention and to address potential barriers to using it?",
        options: [
          {
            text: "No",
            value: "no"
          }, {
            text: "Probably no",
            value: "probably_no"
          }, {
            text: "Probably yes",
            value: "probably_yes"
          }, {
            text: "Yes",
            value: "yes"
          }
        ],
        additionalOptions: [
          {
            text: "Varies",
            value: "varies"
          }, {
            text: "Don't know",
            value: "dont_know"
          }
        ],
        selectedOption: "",
        decision: {
          selectedOption: "",
          comments: ""
        },
        details: {
          panelDiscussion: "",
          questionsType: "general",
          questions: [
            {
              question: "Is the intervention (option) sustainable?",
              options: [
                {
                  text: "No",
                  value: "no"
                }, {
                  text: "Probably no",
                  value: "probably_no"
                }, {
                  text: "Probably yes",
                  value: "probably_yes"
                }, {
                  text: "Yes",
                  value: "yes"
                }
              ],
              additionalOptions: [
                {
                  text: "Varies",
                  value: "varies"
                }, {
                  text: "Don't know",
                  value: "dont_know"
                }
              ],
              selectedOption: ""
            }, {
              question: "Are there important barriers that are likely to limit the feasibility of implementing the intervention (option) or require consideration when implementing it?",
              options: [
                {
                  text: "No",
                  value: "no"
                }, {
                  text: "Probably no",
                  value: "probably_no"
                }, {
                  text: "Probably yes",
                  value: "probably_yes"
                }, {
                  text: "Yes",
                  value: "yes"
                }
              ],
              additionalOptions: [
                {
                  text: "Varies",
                  value: "varies"
                }, {
                  text: "Don't know",
                  value: "dont_know"
                }
              ],
              selectedOption: ""
            }
          ]
        }
      },
      testAccuracy: {
        description: "How accurate is the test?",
        options: [
          {
            text: "Very inaccurate",
            value: "very_inaccurate"
          }, {
            text: "Inaccurate",
            value: "inaccurate"
          }, {
            text: "Accurate",
            value: "accurate"
          }, {
            text: "Very accurate",
            value: "very_accurate"
          }
        ],
        additionalOptions: [
          {
            text: "Varies",
            value: "varies"
          }, {
            text: "Don't know",
            value: "dont_know"
          }
        ],
        selectedOption: "",
        decision: {
          selectedOption: "",
          comments: ""
        },
        details: {
          panelDiscussion: ""
        }
      },
      certaintyOfEvidenceOfTestAccuracy: {
        description: "What is the overall certainty of the evidence of test accuracy?",
        options: [
          {
            text: "Very low",
            value: "very_low"
          }, {
            text: "Low",
            value: "low"
          }, {
            text: "Moderate",
            value: "moderate"
          }, {
            text: "High",
            value: "high"
          }
        ],
        additionalOptions: [
          {
            text: "No included studies",
            value: "no_included_studies"
          }
        ],
        selectedOption: "",
        decision: {
          selectedOption: "",
          comments: ""
        },
        details: {
          panelDiscussion: ""
        }
      },
      certaintyOfEvidenceOfTestEffects: {
        description: "What is the overall certainty of the evidence for any critical or important direct benefits, adverse effects or burden of the test?",
        options: [
          {
            text: "Very low",
            value: "very_low"
          }, {
            text: "Low",
            value: "low"
          }, {
            text: "Moderate",
            value: "moderate"
          }, {
            text: "High",
            value: "high"
          }
        ],
        additionalOptions: [
          {
            text: "No included studies",
            value: "no_included_studies"
          }
        ],
        selectedOption: "",
        decision: {
          selectedOption: "",
          comments: ""
        },
        details: {
          panelDiscussion: ""
        }
      },
      certaintyOfEvidenceOfManagementEffects: {
        description: "What is the overall certainty if the evidence of effects of the management that is guided by the test results?",
        options: [
          {
            text: "Very low",
            value: "very_low"
          }, {
            text: "Low",
            value: "low"
          }, {
            text: "Moderate",
            value: "moderate"
          }, {
            text: "High",
            value: "high"
          }
        ],
        additionalOptions: [
          {
            text: "No included studies",
            value: "no_included_studies"
          }
        ],
        selectedOption: "",
        decision: {
          selectedOption: "",
          comments: ""
        },
        details: {
          panelDiscussion: ""
        }
      },
      certaintyOfEvidenceOfTestResult: {
        description: "How certain is the link between test results and management decisions?",
        options: [
          {
            text: "Very low",
            value: "very_low"
          }, {
            text: "Low",
            value: "low"
          }, {
            text: "Moderate",
            value: "moderate"
          }, {
            text: "High",
            value: "high"
          }
        ],
        additionalOptions: [
          {
            text: "No included studies",
            value: "no_included_studies"
          }
        ],
        selectedOption: "",
        decision: {
          selectedOption: "",
          comments: ""
        },
        details: {
          panelDiscussion: ""
        }
      },
      certaintyOfEffects: {
        description: "What is the overall certainty of the evidence of effects of the test?",
        info: "How good an indication does the research provide of the likely effects across all of the critical outcomes; i.e. the likelihood that the effects will be different enough from what the research found that it might affect a decision about the diagnostic intervention?",
        options: [
          {
            text: "Very low",
            value: "very_low"
          }, {
            text: "Low",
            value: "low"
          }, {
            text: "Moderate",
            value: "moderate"
          }, {
            text: "High",
            value: "high"
          }
        ],
        additionalOptions: [
          {
            text: "No included studies",
            value: "no_included_studies"
          }
        ],
        selectedOption: "",
        decision: {
          selectedOption: "",
          comments: ""
        },
        details: {
          panelDiscussion: ""
        }
      },
      problemRand: {
        description: "Is the problem a priority?",
        optionGroups: {
          upperBound: "Extremely yes",
          lowerBound: "Extremely no",
          groups: [
            {
              groupName: "Yes",
              options: [9, 8, 7]
            }, {
              groupName: "Uncertain",
              options: [6, 5, 4]
            }, {
              groupName: "No",
              options: [3, 2, 1]
            }
          ]
        }
      },
      desirableEffectsRand: {
        description: "How substantial are the desirable anticipated effects?",
        optionGroups: {
          upperBound: "Extremely large",
          lowerBound: "Extremely trivial",
          groups: [
            {
              groupName: "Large",
              options: [9, 8, 7]
            }, {
              groupName: "Uncertain",
              options: [6, 5, 4]
            }, {
              groupName: "Trivial",
              options: [3, 2, 1]
            }
          ]
        }
      },
      undesirableEffectsRand: {
        description: "How substantial are the undesirable anticipated effects?",
        optionGroups: {
          upperBound: "Extremely trivial",
          lowerBound: "Extremely large",
          groups: [
            {
              groupName: "Trivial",
              options: [9, 8, 7]
            }, {
              groupName: "Uncertain",
              options: [6, 5, 4]
            }, {
              groupName: "Large",
              options: [3, 2, 1]
            }
          ]
        }
      },
      certaintyOfEvidenceRand: {
        description: "What is the overall certainty of the evidence of effects?",
        options: [
          {
            text: "High",
            value: "high"
          }, {
            text: "Moderate",
            value: "moderate"
          }, {
            text: "Low",
            value: "low"
          }, {
            text: "Very low",
            value: "very_low"
          }
        ],
        additionalOptions: [
          {
            text: "No included studies",
            value: "no_included_studies"
          }
        ]
      },
      balanceOfEffectsRand: {
        description: "Does the balance between desirable and undesirable effects favor the intervention or the comparison?",
        optionGroups: {
          upperBound: "Extremely favorable balance for intervention",
          lowerBound: "Extremely unfavorable balance for intervention",
          groups: [
            {
              groupName: "Favorable for intervention",
              options: [9, 8, 7]
            }, {
              groupName: "Uncertain",
              options: [6, 5, 4]
            }, {
              groupName: "Unfavorable for intervention",
              options: [3, 2, 1]
            }
          ]
        }
      },
      resourcesRequiredRand: {
        description: "How large are the resource requirements (costs)?",
        optionGroups: {
          upperBound: "Extremely low costs",
          lowerBound: "Extremely large costs",
          groups: [
            {
              groupName: "Low costs",
              options: [9, 8, 7]
            }, {
              groupName: "Uncertain",
              options: [6, 5, 4]
            }, {
              groupName: "Large costs",
              options: [3, 2, 1]
            }
          ]
        }
      },
      certaintyOfEvidenceOfRequiredResourcesRand: {
        description: "What is the certainty of the evidence of resource requirements (costs)?",
        options: [
          {
            text: "High",
            value: "high"
          }, {
            text: "Moderate",
            value: "moderate"
          }, {
            text: "Low",
            value: "low"
          }, {
            text: "Very low",
            value: "very_low"
          }
        ],
        additionalOptions: [
          {
            text: "No included studies",
            value: "no_included_studies"
          }
        ]
      },
      costEffectivenessRand: {
        description: "Does the cost-effectiveness of the intervention favor the intervention or the comparison?",
        optionGroups: {
          upperBound: "Extremely favorable balance for intervention",
          lowerBound: "Extremely unfavorable balance for intervention",
          groups: [
            {
              groupName: "Favorable for intervention",
              options: [9, 8, 7]
            }, {
              groupName: "Uncertain",
              options: [6, 5, 4]
            }, {
              groupName: "Unfavorable for intervention",
              options: [3, 2, 1]
            }
          ]
        }
      },
      valuesRand: {
        description: "Is there important uncertainty about or variability in how much people value the main outcomes?",
        optionGroups: {
          upperBound: "Extremely certain about invariability in values",
          lowerBound: "Extremely concerned about invariability in values",
          groups: [
            {
              groupName: "Certain",
              options: [9, 8, 7]
            }, {
              groupName: "Uncertain",
              options: [6, 5, 4]
            }, {
              groupName: "Concerned",
              options: [3, 2, 1]
            }
          ]
        }
      },
      equityRand: {
        description: "What would be the impact on health equity?",
        optionGroups: {
          upperBound: "Extremely certain about equity",
          lowerBound: "Extremely concerned about equity",
          groups: [
            {
              groupName: "Certain",
              options: [9, 8, 7]
            }, {
              groupName: "Uncertain",
              options: [6, 5, 4]
            }, {
              groupName: "Concerned",
              options: [3, 2, 1]
            }
          ]
        }
      },
      acceptabilityRand: {
        description: "Is the intervention acceptable to key stakeholders?",
        optionGroups: {
          upperBound: "Extremely certain about acceptability",
          lowerBound: "Extremely concerned about acceptability",
          groups: [
            {
              groupName: "Certain",
              options: [9, 8, 7]
            }, {
              groupName: "Uncertain",
              options: [6, 5, 4]
            }, {
              groupName: "Concerned",
              options: [3, 2, 1]
            }
          ]
        }
      },
      feasibilityRand: {
        description: "Is the intervention feasible to implement?",
        optionGroups: {
          upperBound: "Extremely certain about feasibility",
          lowerBound: "Extremely concerned about feasibility",
          groups: [
            {
              groupName: "Certain",
              options: [9, 8, 7]
            }, {
              groupName: "Uncertain",
              options: [6, 5, 4]
            }, {
              groupName: "Concerned",
              options: [3, 2, 1]
            }
          ]
        }
      }
    },
    researchEvidences: {
      problem: {
        content: ""
      },
      effects: {
        content: ""
      },
      desirableEffects: {
        content: ""
      },
      undesirableEffects: {
        content: ""
      },
      certaintyOfEvidence: {
        content: ""
      },
      values: {
        content: ""
      },
      balanceOfEffects: {
        content: ""
      },
      resourcesRequired: {
        content: ""
      },
      certaintyOfEvidenceOfRequiredResources: {
        content: ""
      },
      costEffectiveness: {
        content: ""
      },
      equity: {
        content: ""
      },
      acceptability: {
        content: ""
      },
      feasibility: {
        content: ""
      },
      testAccuracy: {
        content: ""
      },
      certaintyOfEvidenceOfTestAccuracy: {
        content: ""
      },
      certaintyOfEvidenceOfTestEffects: {
        content: ""
      },
      certaintyOfEvidenceOfManagementEffects: {
        content: ""
      },
      certaintyOfEvidenceOfTestResult: {
        content: ""
      },
      certaintyOfEffects: {
        content: ""
      }
    },
    additionalConsiderations: {
      problem: {
        content: ""
      },
      desirableEffects: {
        content: ""
      },
      undesirableEffects: {
        content: ""
      },
      certaintyOfEvidence: {
        content: ""
      },
      values: {
        content: ""
      },
      balanceOfEffects: {
        content: ""
      },
      resourcesRequired: {
        content: ""
      },
      certaintyOfEvidenceOfRequiredResources: {
        content: ""
      },
      costEffectiveness: {
        content: ""
      },
      equity: {
        content: ""
      },
      acceptability: {
        content: ""
      },
      feasibility: {
        content: ""
      },
      testAccuracy: {
        content: ""
      },
      certaintyOfEvidenceOfTestAccuracy: {
        content: ""
      },
      certaintyOfEvidenceOfTestEffects: {
        content: ""
      },
      certaintyOfEvidenceOfManagementEffects: {
        content: ""
      },
      certaintyOfEvidenceOfTestResult: {
        content: ""
      },
      certaintyOfEffects: {
        content: ""
      }
    }
  },
  conclusions: {
    sections: {
      recommendationTypeIntervention: {
        description: "Type of recommendation",
        options: [
          {
            text: "Strong recommendation against the intervention",
            value: "recommend_against"
          }, {
            text: "Conditional recommendation against the intervention",
            value: "suggest_against"
          }, {
            text: "Conditional recommendation for either the intervention or the comparison",
            value: "suggest_either"
          }, {
            text: "Conditional recommendation for the intervention",
            value: "suggest"
          }, {
            text: "Strong recommendation for the intervention",
            value: "recommend"
          }
        ],
        selectedOption: "",
        content: ""
      },
      recommendationTypeOption: {
        description: "Type of recommendation",
        options: [
          {
            text: "Strong recommendation against the option",
            value: "recommend_against"
          }, {
            text: "Conditional recommendation against the option",
            value: "suggest_against"
          }, {
            text: "Conditional recommendation for either the option or the comparison",
            value: "suggest_either"
          }, {
            text: "Conditional recommendation for the option",
            value: "suggest"
          }, {
            text: "Strong recommendation for the option",
            value: "recommend"
          }
        ],
        selectedOption: "",
        content: ""
      },
      decisionTypeCoverage: {
        description: "Type of decision",
        options: [
          {
            text: "Do not cover",
            value: "do_not_cover"
          }, {
            text: "Cover with evidence development",
            value: "cover_with_evidence"
          }, {
            text: "Cover with price negotiation",
            value: "cover_with_price"
          }, {
            text: "Restricted coverage",
            value: "restricted_coverage"
          }, {
            text: "Cover",
            value: "cover"
          }
        ],
        selectedOption: "",
        content: ""
      },
      decisionTypeImplementation: {
        description: "Type of decision",
        options: [
          {
            text: "Do not implement the option",
            value: "do_not_implement"
          }, {
            text: "Postpone the decision",
            value: "postpone"
          }, {
            text: "Conduct a pilot study of the option",
            value: "conduct_pilot_study"
          }, {
            text: "Implement the option with an impact evaluation",
            value: "implement_with_evaluation"
          }, {
            text: "Implement the option",
            value: "implement"
          }
        ],
        selectedOption: "",
        content: ""
      },
      recommendationTypeRand: {
        description: "Type of recommendation",
        optionGroups: {
          upperBound: "Extremely agree on recommendation",
          lowerBound: "Extremely disagree on recommendation",
          groups: [
            {
              groupName: "We recommend for",
              options: [9, 8, 7]
            }, {
              groupName: "We are uncertain",
              options: [6, 5, 4]
            }, {
              groupName: "We recommend against",
              options: [3, 2, 1]
            }
          ]
        }
      },
      recommendation: {
        description: "Recommendation",
        content: ""
      },
      decision: {
        description: "Decision",
        content: "",
        options: [
          {
            text: "Full implementation",
            value: "full_implementation"
          }, {
            text: "Impact evaluation",
            value: "impact_evaluation"
          }, {
            text: "Pilot Study",
            value: "pilot_study"
          }, {
            text: "Postpone",
            value: "postpone"
          }, {
            text: "Do not implement",
            value: "do_not_implement"
          }
        ]
      },
      justification: {
        description: "Justification",
        content: "",
        details: {
          description: "Detailed justification",
          content: ""
        }
      },
      subgroupConsiderations: {
        description: "Subgroup considerations",
        content: ""
      },
      restrictions: {
        description: "Restrictions",
        content: ""
      },
      implementationConsiderations: {
        description: "Implementation considerations",
        content: "",
        details: {
          description: "Detailed implementation",
          content: ""
        }
      },
      monitoringAndEvaluation: {
        description: "Monitoring and evaluation",
        content: "",
        details: {
          description: "Detailed monitoring and evaluation",
          content: ""
        }
      },
      researchPriorities: {
        description: "Research priorities",
        content: ""
      }
    }
  },
  presentations: {
    sectionsOrder: [],
    sections: {
      clinicians: {
        name: 'Clinicians',
        sectionsOrder: [],
        sections: {
          background: {
            name: "Background"
          },
          detailedJustification: {
            name: "Detailed justification"
          },
          justification: {
            name: "Justification"
          },
          subgroupConsiderations: {
            name: "Subgroup considerations"
          },
          summaryOfFindings: {
            name: "Summary of findings"
          }
        }
      },
      patients: {
        name: "Patients",
        sectionsOrder: [],
        sections: {
          justification: {
            name: "Justification"
          },
          whatItMeansForYou: {
            name: "What it means for you",
            content: ""
          },
          whoIsThisFor: {
            name: "Who is this for",
            content: ""
          }
        }
      },
      policymakers: {
        name: "Policymakers",
        sectionsOrder: [],
        sections: {
          assessment: {
            name: "Assessment"
          },
          background: {
            name: "Background",
            description: "Question details for recommendation",
            details: {
              name: "About this decision",
              sections: {
                setting: {
                  description: "Setting",
                  content: ""
                },
                perspective: {
                  description: "Perspective",
                  content: ""
                },
                decisionMakers: {
                  description: "Decision makers",
                  content: ""
                },
                date: {
                  description: "Date",
                  content: ""
                }
              },
              sectionsOrder: ['setting', 'perspective', 'decisionMakers', 'date']
            }
          },
          decision: {
            name: "Decision",
            content: "",
            selectedOption: "",
            options: [
              {
                text: "Full implementation",
                value: "full_implementation"
              }, {
                text: "Impact evaluation",
                value: "impact_evaluation"
              }, {
                text: "Pilot Study",
                value: "pilot_study"
              }, {
                text: "Postpone",
                value: "postpone"
              }, {
                text: "Do not implement",
                value: "do_not_implement"
              }
            ]
          },
          implementation: {
            name: "Implementation"
          },
          justification: {
            name: "Justification"
          },
          monitoringAndEvaluation: {
            name: "Monitoring & Evaluation"
          },
          recommendation: {
            name: "Recommendation"
          }
        }
      }
    }
  }
};

module.exports = sections;
