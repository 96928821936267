List = React.createClass
  displayName: 'List'

  render: ->
    if @props.ordered
      <ol className="standard-list">
        {@props.children}
      </ol>
    else
      <ul className="standard-list">
        {@props.children}
      </ul>

module.exports = List
