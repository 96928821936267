var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "cont", {"name":"is","hash":{},"fn":this.program(2, data),"inverse":this.program(4, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <div class=\"form-group row mt-10\">\n      "
    + escapeExpression(((helpers.inputBlock || (depth0 && depth0.inputBlock) || helperMissing).call(depth0, "change_score_in_controls", "changeScoreInControlsValue", {"name":"inputBlock","hash":{},"data":data})))
    + "\n    </div>\n    <div class=\"form-group row mt-10\">\n      "
    + escapeExpression(((helpers.inputBlock || (depth0 && depth0.inputBlock) || helperMissing).call(depth0, "units", "units", {"name":"inputBlock","hash":{},"data":data})))
    + "\n    </div>\n";
},"4":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <div class=\"form-group row mt-10\">\n      "
    + escapeExpression(((helpers.inputBlock || (depth0 && depth0.inputBlock) || helperMissing).call(depth0, "control_count", {"name":"inputBlock","hash":{},"data":data})))
    + "\n    </div>\n    <div class=\"form-group row mt-10\">\n      "
    + escapeExpression(((helpers.inputBlock || (depth0 && depth0.inputBlock) || helperMissing).call(depth0, "control_total", {"name":"inputBlock","hash":{},"data":data})))
    + "\n    </div>\n    <div class=\"form-group row mt-10\">\n      "
    + escapeExpression(((helpers.inputBlock || (depth0 && depth0.inputBlock) || helperMissing).call(depth0, "calculated_control", "calculatedControlValue", {"name":"inputBlock","hash":{},"data":data})))
    + "\n    </div>\n    <div class=\"row form-group mt-10\">\n      <div id=\"absDenominator\" class=\"edit-input-block\" data-property=\"absDenominator\">\n        <label>\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.denominator", {"name":"i18n","hash":{},"data":data})))
    + " <span class=\"text-right\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "per", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n        </label>\n        <div class=\"inline v-top\">\n          "
    + escapeExpression(((helpers.absDenominatorSelectableAttr || (depth0 && depth0.absDenominatorSelectableAttr) || helperMissing).call(depth0, (depth0 != null ? depth0.absDenominator : depth0), {"name":"absDenominatorSelectableAttr","hash":{},"data":data})))
    + "\n        </div>\n        <div class=\"sup\"></div>\n      </div>\n    </div>\n    <div class=\"form-group row mt-10\">\n      "
    + escapeExpression(((helpers.inputBlock || (depth0 && depth0.inputBlock) || helperMissing).call(depth0, "low_control_risk", "lowControlRisk", {"name":"inputBlock","hash":{},"data":data})))
    + "\n    </div>\n    <div class=\"form-group row mt-10\">\n      "
    + escapeExpression(((helpers.inputBlock || (depth0 && depth0.inputBlock) || helperMissing).call(depth0, "moderate_control_risk", "moderateControlRisk", {"name":"inputBlock","hash":{},"data":data})))
    + "\n    </div>\n    <div class=\"form-group row mt-10\">\n      "
    + escapeExpression(((helpers.inputBlock || (depth0 && depth0.inputBlock) || helperMissing).call(depth0, "high_control_risk", "highControlRisk", {"name":"inputBlock","hash":{},"data":data})))
    + "\n    </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compoundEdit || (depth0 && depth0.compoundEdit) || helperMissing).call(depth0, "controlRisk", "control_risk", {"name":"compoundEdit","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}