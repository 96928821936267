var Chaplin, Focusable, Shortcuts, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Chaplin = require('chaplin');

Focusable = require('./focusable');

mediator = require('mediator');

module.exports = Shortcuts = (function(_super) {
  __extends(Shortcuts, _super);

  function Shortcuts() {
    return Shortcuts.__super__.constructor.apply(this, arguments);
  }

  Shortcuts.prototype.apply = function(view) {
    var appliedScope;
    Shortcuts.__super__.apply.apply(this, arguments);
    key.filter = function() {
      return true;
    };
    view.defaultScopes = [];
    appliedScope = function(subviewFunction, trait, name, view) {
      if (view) {
        view._scope = "" + (this._scope || this.cid) + "." + view.cid;
        view.defaultScopes = [];
        key.deleteScope("" + view.cid);
        trait._addFunction(view, '_enableShortcuts');
        trait._addFunction(view, 'disableShortcuts');
        trait.delegateShortcuts(view, "" + view._scope);
        trait._addFunction(view, 'subview', appliedScope);
        trait._addFunction(view, 'dispose');
        trait._addFunction(view, 'setDefaultScope');
        trait._addFunction(view, 'unsetDefaultScope');
      }
      return subviewFunction.call(this, name, view);
    };
    this._addFunction(view, 'subview', appliedScope);
    this._addFunction(view, 'dispose');
    this._addFunction(view, 'setDefaultScope');
    this._addFunction(view, 'unsetDefaultScope');
    this._addFunction(view, '_enableShortcuts');
    this._addFunction(view, 'disableShortcuts');
    return this.delegateShortcuts(view, view.cid);
  };

  Shortcuts.prototype.delegateShortcuts = function(view, scope) {
    var callback, defaultScope, keyScope, method, scopeMatch, shortcut, shortcutKey;
    if (!view.shortcuts) {
      return;
    }
    view._shortcutTriples = (function() {
      var _ref, _results;
      _ref = view.shortcuts;
      _results = [];
      for (shortcut in _ref) {
        callback = _ref[shortcut];
        method = view[callback];
        if (!(method && _(method).isFunction())) {
          throw new Error("Method " + callback + " does not exist");
        }
        scopeMatch = shortcut.match(/^([^\.]+)\.?(.*)$/);
        shortcutKey = scopeMatch[1];
        keyScope = scopeMatch[2] === "" ? scope : (defaultScope = "" + view.cid + "_" + scopeMatch[2], view.defaultScopes.push(defaultScope), defaultScope);
        method = _.bind(method, view);
        _results.push({
          shortcutKey: shortcutKey,
          keyScope: keyScope,
          method: method
        });
      }
      return _results;
    })();
    return view._enableShortcuts();
  };

  Shortcuts.prototype._enableShortcuts = function(originalFunction, trait) {
    var keyScope, method, shortcutKey, _i, _len, _ref, _ref1, _results;
    _ref = this._shortcutTriples;
    _results = [];
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      _ref1 = _ref[_i], shortcutKey = _ref1.shortcutKey, keyScope = _ref1.keyScope, method = _ref1.method;
      _results.push(key(shortcutKey, keyScope, method, {
        dispatchAllScopes: this.listenToParentKeyEvents
      }));
    }
    return _results;
  };

  Shortcuts.prototype.disableShortcuts = function(originalFunction, trait) {
    var defaultScope, _i, _len, _ref, _results;
    key.deleteScope(this.cid);
    if (this.defaultScopes) {
      _ref = this.defaultScopes;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        defaultScope = _ref[_i];
        _results.push(key.deleteScope(defaultScope));
      }
      return _results;
    }
  };

  Shortcuts.prototype.dispose = function(originalFunction) {
    Shortcuts.__super__.dispose.apply(this, arguments);
    return this.disableShortcuts();
  };

  Shortcuts.prototype.setDefaultScope = function(originalFunction, trait, defaultScope, scope) {
    if (scope == null) {
      scope = this.cid;
    }
    return key.setScope("" + this.cid + "_" + defaultScope);
  };

  Shortcuts.prototype.unsetDefaultScope = function(originalFunction, trait, scope) {
    if (scope == null) {
      scope = this.cid;
    }
    return key.setScope(scope);
  };

  Shortcuts.prototype.onFocusLost = function() {
    key.setScope(null);
    return Shortcuts.__super__.onFocusLost.apply(this, arguments);
  };

  Shortcuts.prototype._focusGained = function(originalFunction, trait) {
    key.setScope("" + this.cid);
    return Shortcuts.__super__._focusGained.apply(this, arguments);
  };

  return Shortcuts;

})(Focusable);
