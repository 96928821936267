var CollectionView, Scrolling, Shortcuts, TeamMember, TeamMemberView, TeamMembersView, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

CollectionView = require('base/views/collection_view');

TeamMember = require('models/team_member');

TeamMemberView = require('views/team/member_view');

Shortcuts = require('base/lib/traits/shortcuts');

Scrolling = require('base/lib/traits/scrolling');

template = require('views/templates/team/members');

mediator = require('mediator');

module.exports = TeamMembersView = (function(_super) {
  __extends(TeamMembersView, _super);

  function TeamMembersView() {
    return TeamMembersView.__super__.constructor.apply(this, arguments);
  }

  TeamMembersView.prototype.container = '#page-container';

  TeamMembersView.prototype.className = 'team-members-view';

  TeamMembersView.prototype.template = template;

  TeamMembersView.prototype.addCollectionSizeValidation = true;

  TeamMembersView.prototype.itemView = TeamMemberView;

  TeamMembersView.prototype.animationDuration = 10;

  TeamMembersView.prototype.listSelector = 'ol';

  TeamMembersView.prototype.shortcuts = {
    'a': 'addMember'
  };

  TeamMembersView.prototype.initialize = function(options) {
    this.enable(Scrolling, {
      fixedElements: 'menu.buttons'
    });
    this.enable(Shortcuts);
    TeamMembersView.__super__.initialize.apply(this, arguments);
    return this.delegate('click', 'button.add', this.addMember);
  };

  TeamMembersView.prototype.addMember = function() {
    return mediator.setFocus(this, (function(_this) {
      return function() {
        return _this.collection.add(new TeamMember());
      };
    })(this));
  };

  return TeamMembersView;

})(CollectionView);
