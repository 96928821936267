var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    "
    + escapeExpression(((helpers.makeXmlRows || (depth0 && depth0.makeXmlRows) || helperMissing).call(depth0, (depth0 != null ? depth0.rows : depth0), {"name":"makeXmlRows","hash":{},"data":data})))
    + "\n";
},"3":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "      <P><SUP>"
    + escapeExpression(((helpers.increment || (depth0 && depth0.increment) || helperMissing).call(depth0, (data && data.index), {"name":"increment","hash":{},"data":data})))
    + "</SUP> "
    + escapeExpression(((helper = (helper = helpers.note || (depth0 != null ? depth0.note : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"note","hash":{},"data":data}) : helper)))
    + "</P>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<?xml version=\"1.0\" encoding=\"utf-8\"?>\n<SOF_TABLE ID=\"SOF-"
    + escapeExpression(((helper = (helper = helpers.sofTableNo || (depth0 != null ? depth0.sofTableNo : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"sofTableNo","hash":{},"data":data}) : helper)))
    + "\" MODIFIED=\""
    + escapeExpression(((helper = (helper = helpers.modificationDate || (depth0 != null ? depth0.modificationDate : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"modificationDate","hash":{},"data":data}) : helper)))
    + "\" MODIFIED_BY=\"Grade Profiler\" NO=\"1\">\n  <TITLE MODIFIED=\""
    + escapeExpression(((helper = (helper = helpers.modificationDate || (depth0 != null ? depth0.modificationDate : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"modificationDate","hash":{},"data":data}) : helper)))
    + "\" MODIFIED_BY=\"GDT\">"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</TITLE>\n  <TABLE COLS=\""
    + escapeExpression(((helper = (helper = helpers.colsNo || (depth0 != null ? depth0.colsNo : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"colsNo","hash":{},"data":data}) : helper)))
    + "\" ROWS=\""
    + escapeExpression(((helper = (helper = helpers.rowsNo || (depth0 != null ? depth0.rowsNo : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"rowsNo","hash":{},"data":data}) : helper)))
    + "\">\n  "
    + escapeExpression(((helpers.makeXmlRows || (depth0 && depth0.makeXmlRows) || helperMissing).call(depth0, (depth0 != null ? depth0.tableHeader : depth0), {"name":"makeXmlRows","hash":{},"data":data})))
    + "\n\n  "
    + escapeExpression(((helpers.makeXmlRows || (depth0 && depth0.makeXmlRows) || helperMissing).call(depth0, (depth0 != null ? depth0.thead : depth0), {"name":"makeXmlRows","hash":{},"data":data})))
    + "\n\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.outcomes : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = this.invokePartial(partials.rm5TableFooter, '  ', 'rm5TableFooter', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "  </TABLE>\n  <FOOTNOTES>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.footnotesList : depth0), {"name":"each","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </FOOTNOTES>\n</SOF_TABLE>\n";
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}