Button = require 'components/common/button'
Translation = require 'components/mixins/translation'
PureRenderMixin = React.addons.PureRenderMixin

HeaderTopRows = React.createClass
  displayName: 'HeaderTopRows'
  mixins: [Translation('es:question'), PureRenderMixin]

  render: ->
    headerClasses = classNames
      expanded: @props.expanded
      collapsed: not @props.expanded

    rowSpan = @props.noOfSectionsRows + 2

    <tr className="title">
      <td colSpan="4">
        <h1 className={headerClasses} onClick={@props.toggleExpand}>{@i18n "question"}</h1>
      </td>
      {
        if @props.expanded
          <td className="right-button" rowSpan={rowSpan}>
            {
              if @props.editing
                <Button
                  className="save"
                  title={@i18n "/actions.save_changes_title"}
                  onClick={@props.onSave}
                />
              else
                <Button className="edit" title={@i18n "edit"} onClick={@props.onEdit} />
            }
          </td>
      }
    </tr>

module.exports = HeaderTopRows
