var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.editing : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.program(4, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "\n      <div class=\"form-horizontal\">\n        <div class=\"form-group\">\n          <label class=\"col-2\" for=\"givenNames\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "given_names", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n          <div class=\"col-10\">\n            <input type=\"text\" class=\"form-control\" name=\"givenNames\" id=\"givenNames\" value=\"\">\n          </div>\n        </div>\n        <div class=\"form-group\">\n          <label class=\"col-2\" for=\"lastName\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "last_name", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n          <div class=\"col-10\">\n            <input type=\"text\" class=\"form-control\" name=\"lastName\" id=\"lastName\" value=\"\">\n          </div>\n        </div>\n        <div class=\"form-group\">\n          <label class=\"col-2\" for=\"email\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "email", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n          <div class=\"col-10\">\n            <input type=\"text\" class=\"form-control\" name=\"email\" id=\"email\" value=\"\">\n          </div>\n        </div>\n      </div>\n      <div class=\"side-buttons\">\n        <button class=\"save\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/actions.save_changes_title", {"name":"i18n","hash":{},"data":data})))
    + "'></button>\n        <button class=\"delete\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "delete", {"name":"i18n","hash":{},"data":data})))
    + "'></button>\n      </div>\n";
},"4":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "      <button class=\"edit\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "edit", {"name":"i18n","hash":{},"data":data})))
    + "'></button>\n      <div class='caption'>\n        <a title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "send_email", {"name":"i18n","hash":{
    'to': ((depth0 != null ? depth0.memberFullName : depth0))
  },"data":data})))
    + "\" href='mailto:"
    + escapeExpression(((helper = (helper = helpers.email || (depth0 != null ? depth0.email : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"email","hash":{},"data":data}) : helper)))
    + "'></a>\n      </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "team:member", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}