Translation = require 'components/mixins/translation'
Header = require 'components/etd/etd_header'
Assessment = require 'components/etd/assessment'
Conclusions = require 'components/etd/conclusions'
EtdStore = require 'stores/etd_store'
QuestionsStore = require 'stores/questions_store'
PureRenderMixin = React.addons.PureRenderMixin
CreateStoreMixin = require '../mixins/create_store'
mediator = require 'mediator'
Router = require 'router'
FetchingSpinner = require 'components/mixins/fetching_spinner'
SummaryOfJudgements = require 'components/etd/summary_of_judgements'
TogglePanel = require 'components/common/toggle_panel'

Recommendations = React.createClass
  displayName: 'RecommendationsTable'
  mixins: [
    Translation('es:recommendations.table')
    PureRenderMixin
    CreateStoreMixin(EtdStore, QuestionsStore)
    FetchingSpinner
  ]

  getStateFromStores: ->
    questionData: QuestionsStore.getQuestion()
    headerSections: EtdStore.getHeaderSections()
    currentEditable: EtdStore.getActiveContentEditable()
    templateUndefined: EtdStore.isTemplateUndefined()
    attachments: EtdStore.getAttachments()
    assessmentSections: EtdStore.getAssessmentSections()
    criterions: EtdStore.getCriterions()
    researchEvidences: EtdStore.getResearchEvidences()
    additionalConsiderations: EtdStore.getAdditionalConsiderations()
    conclusionsSections: EtdStore.getConclusionsSections()
    questionType: QuestionsStore.getQuestionType()
    questionName: QuestionsStore.getQuestionName()
    criticalOutcomes: QuestionsStore.getCriticalOutcomes()
    isFetching: EtdStore.isFetching() or QuestionsStore.isFetching()

  routeToAdministrationModule: (e) ->
    e.preventDefault()
    mediator.publish '!router:route', Router::getProjectRelativeUrl("/administration")

  renderFetched: ->
    if @state.templateUndefined
      <div>
        {@i18n 'template_undefined', {},
          link: <a href='#' onClick=@routeToAdministrationModule>{@i18n 'etd_templates'}</a>
        }
      </div>
    else
      <div>
        <Header
          question={@state.questionData}
          criticalOutcomes={@state.criticalOutcomes}
          questionType={@state.questionType}
          headerSections={@state.headerSections}
        />
        <Assessment
          currentEditable={@state.currentEditable}
          attachments={@state.attachments}
          sections={@state.assessmentSections}
          criterions={@state.criterions}
          researchEvidences={@state.researchEvidences}
          additionalConsiderations={@state.additionalConsiderations}
          questionType={@state.questionType}
          criticalOutcomes={@state.criticalOutcomes}
        />
        <Conclusions
          sections={@state.conclusionsSections}
          questionName={@state.questionName}
          currentEditable={@state.currentEditable}
        />
      </div>

module.exports = Recommendations
