var QuestionsActions, alt;

alt = require('alt');

QuestionsActions = (function() {
  function QuestionsActions() {
    this.generateActions('pouchFetchSuccess', 'fetch');
  }

  QuestionsActions.prototype.pouchFetchError = function(error) {
    throw error;
  };

  return QuestionsActions;

})();

module.exports = alt.createActions(QuestionsActions);
