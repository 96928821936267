var RecommendationsExporter;

module.exports = RecommendationsExporter = (function() {
  function RecommendationsExporter(_originalQuestion) {
    this._originalQuestion = _originalQuestion;
    this._standardOptions = [this._getNameForOptionLabel('no'), this._getNameForOptionLabel('probably_no'), this._getNameForOptionLabel('uncertain'), this._getNameForOptionLabel('probably_yes'), this._getNameForOptionLabel('yes'), this._getNameForOptionLabel('varies')];
    this._v2standardOptions = [this._getNameForOptionLabel('no'), this._getNameForOptionLabel('probably_no'), this._getNameForOptionLabel('probably_yes'), this._getNameForOptionLabel('yes'), this._getNameForOptionLabel('varies'), this._getNameForOptionLabel('dont_know')];
    this._v2desirableOptions = [this._getNameForOptionLabel('trival'), this._getNameForOptionLabel('small'), this._getNameForOptionLabel('moderate'), this._getNameForOptionLabel('large'), this._getNameForOptionLabel('varies'), this._getNameForOptionLabel('dont_know')];
    this._v2undesirableOptions = [this._getNameForOptionLabel('large'), this._getNameForOptionLabel('moderate'), this._getNameForOptionLabel('small'), this._getNameForOptionLabel('trival'), this._getNameForOptionLabel('varies'), this._getNameForOptionLabel('dont_know')];
    this._accuracyOptions = [this._getNameForOptionLabel('very_inaccurate'), this._getNameForOptionLabel('inaccurate'), this._getNameForOptionLabel('uncertain'), this._getNameForOptionLabel('accurate'), this._getNameForOptionLabel('very_accurate'), this._getNameForOptionLabel('varies')];
    this._v2accuracyOptions = [this._getNameForOptionLabel('very_inaccurate'), this._getNameForOptionLabel('inaccurate'), this._getNameForOptionLabel('uncertain'), this._getNameForOptionLabel('accurate'), this._getNameForOptionLabel('very_accurate'), this._getNameForOptionLabel('varies'), this._getNameForOptionLabel('dont_know')];
    this._similarityOptions = [this._getNameForOptionLabel('similar'), this._getNameForOptionLabel('probably_similar'), this._getNameForOptionLabel('uncertain'), this._getNameForOptionLabel('probably_not_similar'), this._getNameForOptionLabel('not_similar'), this._getNameForOptionLabel('varies')];
    this._certaintyOptions = [this._getNameForOptionLabel('very_low'), this._getNameForOptionLabel('low'), this._getNameForOptionLabel('moderate'), this._getNameForOptionLabel('high')];
    this._certaintyMgmtOptions = [this._getNameForOptionLabel('no_included_studies'), this._getNameForOptionLabel('very_low'), this._getNameForOptionLabel('low'), this._getNameForOptionLabel('moderate'), this._getNameForOptionLabel('high')];
    this._v2certaintyOptions = [this._getNameForOptionLabel('very_low'), this._getNameForOptionLabel('low'), this._getNameForOptionLabel('moderate'), this._getNameForOptionLabel('high'), this._getNameForOptionLabel('no_included_studies')];
    this._uncertaintyOptions = [this._getNameForOptionLabel('important_uncertainty'), this._getNameForOptionLabel('possible_important'), this._getNameForOptionLabel('probably_no_important'), this._getNameForOptionLabel('no_important'), this._getNameForOptionLabel('no_known')];
    this._inequitiesOptions = [this._getNameForOptionLabel('increased'), this._getNameForOptionLabel('probably_increased'), this._getNameForOptionLabel('uncertain'), this._getNameForOptionLabel('probably_reduced'), this._getNameForOptionLabel('reduced'), this._getNameForOptionLabel('varies')];
    this._v2inequitiesOptions = [this._getNameForOptionLabel('reduced'), this._getNameForOptionLabel('probably_reduced'), this._getNameForOptionLabel('probably_no_impact'), this._getNameForOptionLabel('probably_increased'), this._getNameForOptionLabel('increased'), this._getNameForOptionLabel('varies'), this._getNameForOptionLabel('dont_know')];
    this._balanceOptions = [this._getNameForOptionLabel('favors_comparison'), this._getNameForOptionLabel('probably_favors_comparison'), this._getNameForOptionLabel('doesnt_favor_any'), this._getNameForOptionLabel('probably_favors_intervention'), this._getNameForOptionLabel('favors_intervention')];
    this._v2balanceOptions = [this._getNameForOptionLabel('favors_comparison'), this._getNameForOptionLabel('probably_favors_comparison'), this._getNameForOptionLabel('doesnt_favor_any'), this._getNameForOptionLabel('probably_favors_intervention'), this._getNameForOptionLabel('favors_intervention'), this._getNameForOptionLabel('varies'), this._getNameForOptionLabel('dont_know')];
    this._v2costEffectivenessOptions = [this._getNameForOptionLabel('favors_comparison'), this._getNameForOptionLabel('probably_favors_comparison'), this._getNameForOptionLabel('doesnt_favor_any'), this._getNameForOptionLabel('probably_favors_intervention'), this._getNameForOptionLabel('favors_intervention'), this._getNameForOptionLabel('varies'), this._getNameForOptionLabel('no_included_studies')];
    this._resourcesRequiredOptions = [this._getNameForOptionLabel('large_costs'), this._getNameForOptionLabel('moderate_costs'), this._getNameForOptionLabel('negligible_costs_savings'), this._getNameForOptionLabel('moderate_savings'), this._getNameForOptionLabel('large_savings'), this._getNameForOptionLabel('varies'), this._getNameForOptionLabel('dont_know')];
  }

  RecommendationsExporter.prototype._makeCriterion = function(type, description, options, judgement, evidence, considerations) {
    var criterion;
    criterion = {};
    if (type != null) {
      criterion['@type'] = type;
    }
    if (description != null) {
      criterion['description'] = description;
    }
    if (options != null) {
      criterion['options'] = options;
    }
    if (judgement != null) {
      criterion['judgement'] = judgement;
    }
    if (evidence != null) {
      criterion['researchEvidence'] = evidence;
    }
    if (considerations != null) {
      criterion['additionalConsiderations'] = considerations;
    }
    return criterion;
  };

  RecommendationsExporter.prototype._makeSection = function(type, name, criteria, evidence, considerations) {
    var section;
    section = {
      '@type': type,
      'name': name,
      'criterion': criteria
    };
    if (evidence != null) {
      section['researchEvidence'] = evidence;
    }
    if (considerations != null) {
      section['additionalConsiderations'] = considerations;
    }
    return section;
  };

  RecommendationsExporter.prototype._getNameForOptionLabel = function(optionsLabel) {
    if (optionsLabel != null) {
      return $.t("es:recommendations.table.radio_values." + optionsLabel);
    } else {
      return '';
    }
  };

  RecommendationsExporter.prototype._getTitleForSectionLabel = function(sectionLabel, titleLabelName) {
    if (titleLabelName == null) {
      titleLabelName = 'title';
    }
    return $.t("es:recommendations.table." + sectionLabel + "." + titleLabelName);
  };

  RecommendationsExporter.prototype._getQuestionForSectionAndQuestionLabels = function(sectionLabel, questionLabel) {
    return $.t("es:recommendations.table." + sectionLabel + "." + questionLabel);
  };

  RecommendationsExporter.prototype._fieldExistsAndIsNotEmpty = function(field) {
    return (field != null) && field !== '';
  };

  return RecommendationsExporter;

})();
