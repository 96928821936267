Criterion = require 'components/etd/criterion'
Judgement = require 'components/etd/judgement'
ResearchEvidence = require 'components/etd/research_evidence'
AdditionalConsideration = require 'components/etd/additional_consideration'
PureRenderMixin = React.addons.PureRenderMixin

AssessmentSection = React.createClass
  displayName: 'AssessmentSection'
  mixins: [PureRenderMixin]

  sectionNameToHeight: ->
    if @props.sectionId in ['balanceOfEffects', 'values', 'costEffectiveness', 'resourcesRequired']
      'h360'
    else
      l = @props.sectionName.length
      if l < 30
        'h200'
      else if l < 40
        'h360'
      else
        'h490'

  render: ->
    rotatedClass = classNames 'rotated', @sectionNameToHeight()
    <tr className={_.string.underscored @props.sectionId}>
      <td className={rotatedClass}>
        <span>{@props.sectionName}</span>
      </td>
      <Criterion
        content={@props.criterion.get 'description'}
        info={@props.criterion.get 'info'}
      />

      <Judgement
        sectionId={@props.sectionId}
        sectionName={@props.sectionName}
        criterionDescription={@props.criterion.get 'description'}
        options={@props.criterion.get 'options'}
        additionalOptions={@props.criterion.get 'additionalOptions'}
        optionGroups={@props.criterion.get 'optionGroups'}
        selectedOption={@props.criterion.get 'selectedOption'}
        criticalOutcomes={@props.criticalOutcomes}
        details={@props.criterion.get 'details'}
        questionType={@props.questionType}
      />

      {
        if @props.researchEvidence.get('domainSection') is @props.sectionId
          <ResearchEvidence
            attachments={@props.attachments}
            currentEditable={@props.currentEditable}
            rowSpan={@props.researchEvidence.get 'rowSpan'}
            questionType={@props.questionType}
            cellId=
              {"#{@props.sectionData.get('researchEvidenceId')}#researchEvidences"}
            researchEvidence={@props.researchEvidence}
          />
      }
      {
        if @props.additionalConsiderations.get('domainSection') is @props.sectionId
          <AdditionalConsideration
            currentEditable={@props.currentEditable}
            rowSpan={@props.additionalConsiderations.get 'rowSpan'}
            cellId=
              {"#{@props.sectionData.get('additionalConsiderationId')}#additionalConsiderations"}
            additionalConsiderations={@props.additionalConsiderations}
          />
      }
    </tr>

module.exports = AssessmentSection
