var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"authentication\">\n  <form>\n    <a class=\"logo\" href=\"http://gradepro.org\" target=\"_blank\"><img src=\""
    + escapeExpression(((helper = (helper = helpers.logoUrl || (depth0 != null ? depth0.logoUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"logoUrl","hash":{},"data":data}) : helper)))
    + "\"></a>\n    <h1 class=\"title\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "new_account", {"name":"i18n","hash":{},"data":data})))
    + "</h1>\n    <div class=\"error-shared\"></div>\n    <label>\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "email", {"name":"i18n","hash":{},"data":data})))
    + "\n      <input class=\"email\" placeholder=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "email_placeholder", {"name":"i18n","hash":{},"data":data})))
    + "\" autofocus>\n    </label>\n    <div class=\"error email\"></div>\n    <label>\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "password", {"name":"i18n","hash":{},"data":data})))
    + "\n      <input class=\"password\" type=\"password\">\n    </label>\n    <div class=\"error password\"></div>\n    <label>\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "repeat_password", {"name":"i18n","hash":{},"data":data})))
    + "\n      <input class=\"repeat-password\" type=\"password\">\n    </label>\n    <div class=\"error repeat-password\"></div>\n    <label class=\"tos-acceptance\">\n      <input class=\"tos\" type=\"checkbox\"/>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "i_agree_to", {"name":"i18n","hash":{},"data":data})))
    + "\n      <a href=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/terms_of_service_url", {"name":"i18n","hash":{},"data":data})))
    + "\" target=\"_blank\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/terms_of_service", {"name":"i18n","hash":{},"data":data})))
    + "</a>\n    </label>\n    <div class=\"error tos\"></div>\n    <input type=\"submit\" class=\"btn btn-info btn-block sign-up\" value=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "create_account", {"name":"i18n","hash":{},"data":data})))
    + "\">\n  </form>\n  <div class=\"footer\">\n    <button class=\"login-switch\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "already_account", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n";
  stack1 = this.invokePartial(partials.languageSelect, '    ', 'languageSelect', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "  </div>\n</div>\n";
  stack1 = this.invokePartial(partials.copyrightNotice, '', 'copyrightNotice', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "registration", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}