var Exceptions, Outcome, Project, Question, Questions, W, importProject, mediator, utils;

Project = require('models/project');

Outcome = require('models/outcome');

Question = require('models/question');

Questions = require('models/questions');

utils = require('base/lib/utils');

mediator = require('mediator');

Exceptions = require('lib/exceptions');

W = require('when/when');

importProject = {
  parseFile: function(file) {
    var parsingFile, reader;
    parsingFile = W.defer();
    reader = new FileReader();
    reader.onload = (function(_this) {
      return function(loadEvent) {
        var error;
        try {
          return parsingFile.resolve(_this._parse(loadEvent.target.result, file.name));
        } catch (_error) {
          error = _error;
          return parsingFile.reject(error);
        }
      };
    })(this);
    utils.detectRm5Encoding(file).done(function(encoding) {
      return reader.readAsText(file, encoding);
    }).fail(mediator.dialogs.fatalError);
    return parsingFile.promise;
  },
  _parse: function(content, fileName) {
    var $xml, contact, contactAddress, contactPersonNode, coverSheet, creators, filteredOutcomes, initials, outcome, parseQuestions, project, projectName, qAuthor, question, questionBibliography, questions, questionsFormat, reviewType, _i, _j, _k, _l, _len, _len1, _len2, _len3, _ref;
    $xml = $($.parseXML(content));
    reviewType = $xml.find('COCHRANE_REVIEW').attr('TYPE');
    parseQuestions = (function() {
      if (reviewType === 'INTERVENTION') {
        return require('./rm5_intervention_questions');
      } else if (reviewType === 'DIAGNOSTIC') {
        return require('./rm5_diagnostic_questions');
      } else {
        throw Exceptions["import"].unsupported_review_type(reviewType);
      }
    })();
    coverSheet = $xml.find('COVER_SHEET');
    projectName = coverSheet.find('TITLE').text();
    questionsFormat = this.determineQuestionsFormat(projectName);
    contactPersonNode = coverSheet.find('CONTACT > PERSON');
    contact = this.parsePerson(contactPersonNode);
    contactAddress = this.parseAddress(contactPersonNode.find('ADDRESS'));
    creators = [];
    coverSheet.find('CREATORS > PERSON').each((function(_this) {
      return function(idx, item) {
        var creator;
        creator = _this.parsePerson($(item));
        creator.address = _this.parseAddress($(item).find('ADDRESS'));
        return creators.push(creator);
      };
    })(this));
    questionBibliography = [];
    for (_i = 0, _len = creators.length; _i < _len; _i++) {
      qAuthor = creators[_i];
      initials = _(qAuthor.firstName.split(' ')).map(function(fn) {
        return fn.charAt(0);
      }).join('');
      questionBibliography.push("" + qAuthor.lastName + " " + initials + qAuthor.middleInitials);
    }
    project = new Project({
      'rm5ReviewType': reviewType,
      'name': projectName,
      'creators': creators,
      'contact': contact,
      'contactAddress': contactAddress,
      'importedFileName': fileName,
      'importedFileContent': content
    });
    questions = parseQuestions($xml, questionsFormat, projectName);
    for (_j = 0, _len1 = questions.length; _j < _len1; _j++) {
      question = questions[_j];
      question.set('bibliography', ("" + (questionBibliography.join(', ')) + ". " + projectName + ". ") + ("" + ($.t('projects:bibliography_postfix')) + "."));
    }
    for (_k = 0, _len2 = questions.length; _k < _len2; _k++) {
      question = questions[_k];
      filteredOutcomes = [];
      _ref = question.get('outcomes').models;
      for (_l = 0, _len3 = _ref.length; _l < _len3; _l++) {
        outcome = _ref[_l];
        if (!outcome.disposed) {
          filteredOutcomes.push(outcome);
        }
      }
      question.set('outcomes', filteredOutcomes);
    }
    return [[project, questions]];
  },
  parsePerson: function(personNode) {
    var person;
    person = {
      id: personNode.attr('ID'),
      firstName: personNode.find('FIRST_NAME').text(),
      middleInitials: personNode.find('MIDDLE_INITIALS').text(),
      lastName: personNode.find('LAST_NAME').text()
    };
    return person;
  },
  parseAddress: function(addressNode) {
    var address;
    address = {
      department: addressNode.find('DEPARTMENT').text(),
      city: addressNode.find('CITY').text(),
      zip: addressNode.find('ZIP').text(),
      region: addressNode.find('REGION').text(),
      country: addressNode.find('COUNTRY').text()
    };
    return address;
  },
  determineQuestionsFormat: function(projectName) {
    if (projectName.toLowerCase().indexOf(' for ') !== -1) {
      return 'FOR_HP';
    } else if (projectName.toLowerCase().indexOf(' in ') !== -1) {
      return 'IN_POP';
    } else {
      return 'FOR_HP';
    }
  }
};

module.exports = importProject;
