var __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

_.namespace(module, function(require) {
  var W, mediator, _modules, _optional, _presets;
  mediator = require('mediator');
  W = require('when');
  _modules = [
    {
      id: 'administration',
      submodules: [
        {
          id: 'etd_templates'
        }, {
          id: 'voting'
        }
      ]
    }, {
      id: 'tasks'
    }, {
      id: 'team',
      submodules: [
        {
          id: 'members'
        }, {
          id: 'coi'
        }
      ]
    }, {
      id: 'scope',
      submodules: [
        {
          id: 'general'
        }, {
          id: 'questions'
        }, {
          id: 'outcomes',
          featureSwitch: 'outcomeGeneration'
        }
      ]
    }, {
      id: 'document_sections'
    }, {
      id: 'prognosis'
    }, {
      id: 'evidence_syntheses',
      submodules: [
        {
          id: 'outcomes',
          missing: true
        }, {
          id: 'searching',
          missing: true
        }, {
          id: 'screening',
          missing: true
        }, {
          id: 'data_extraction',
          missing: true
        }, {
          id: 'risk_of_bias',
          missing: true
        }, {
          id: 'analyses',
          missing: true
        }, {
          id: 'quality_of_evidence',
          mandatory: true
        }, {
          id: 'recommendations'
        }, {
          id: 'presentations',
          missing: true
        }
      ]
    }, {
      id: 'dissemination',
      submodules: [
        {
          id: 'preview',
          missing: true
        }, {
          id: 'publish'
        }
      ]
    }
  ];
  _optional = ['administration', 'tasks', 'team', 'scope', 'document_sections', 'prognosis', 'evidence_syntheses#recommendations', 'evidence_syntheses#presentations', 'dissemination'];
  _presets = {
    grade_ep: ['dissemination#publish'],
    sof_table: ['dissemination#publish'],
    etd: ['administration', 'evidence_syntheses#recommendations', 'dissemination#publish'],
    full_guideline: _optional
  };
  return {
    _getModulesToEnable: function(moduleList) {
      return _(moduleList).chain().map(function(entry) {
        var module, submodule, _ref;
        if (__indexOf.call(entry, '#') >= 0) {
          _ref = entry.split('#'), module = _ref[0], submodule = _ref[1];
          return {
            module: module,
            submodule: submodule
          };
        } else {
          return {
            module: entry,
            submodule: '*'
          };
        }
      }).groupBy('module').map(function(v, k) {
        return [k, _(v).chain().pluck('submodule').compact().value()];
      }).object().value();
    },
    _getSubmoduleTree: function(submodules, submodulesToEnable) {
      return _(submodules).map(function(submodule) {
        var disabled, markEnabled, _ref;
        markEnabled = (_ref = submodule.id, __indexOf.call(submodulesToEnable, _ref) >= 0);
        disabled = submodule.missing || !(submodule.mandatory || markEnabled);
        return _(submodule).chain().omit('mandatory').extend({
          disabled: disabled
        }).value();
      });
    },
    _getModuleTree: function(modules, moduleList) {
      var modulesToEnable;
      modulesToEnable = this._getModulesToEnable(moduleList);
      return _(modules).map((function(_this) {
        return function(module) {
          var anySubmoduleEnabled, disabled, enabledSubmodules, markEnabled, submodules, toEnable, _ref;
          toEnable = (_ref = modulesToEnable[module.id]) != null ? _ref : [];
          enabledSubmodules = __indexOf.call(toEnable, '*') >= 0 ? _(module.submodules).pluck('id') : toEnable;
          submodules = _this._getSubmoduleTree(module.submodules, enabledSubmodules);
          anySubmoduleEnabled = _(submodules).findWhere({
            disabled: false
          });
          markEnabled = module.id in modulesToEnable || anySubmoduleEnabled;
          disabled = module.missing || !(module.mandatory || markEnabled);
          return _(module).chain().omit('mandatory').extend({
            submodules: submodules,
            disabled: disabled
          }).value();
        };
      })(this));
    },
    _getAllModules: function() {
      return this._getModuleTree(_modules, _optional);
    },
    enableModulesForPreset: function(preset, project) {
      if (!preset) {
        return W.resolve(project);
      }
      return this._setPresetSpecificSettings(preset, project).then((function(_this) {
        return function() {
          return project.save('modules', _this._getModuleTree(_modules, _presets[preset]));
        };
      })(this));
    },
    _setPresetSpecificSettings: function(preset, project) {
      var dxView, txView, _ref;
      if (preset !== 'grade_ep' && preset !== 'sof_table' && preset !== 'full_guideline') {
        return W.resolve();
      }
      _ref = preset === 'grade_ep' || preset === 'full_guideline' ? ['OneRow', 'LayerOne'] : preset === 'sof_table' ? ['SOF', 'LayerOneSof'] : void 0, txView = _ref[0], dxView = _ref[1];
      return W.map([[project, false, txView, true], [project, 'diagnostic', dxView, true]], function(args) {
        var _ref1;
        return (_ref1 = mediator.services.esView).savePreference.apply(_ref1, args);
      });
    },
    getDefaultAttributes: function() {
      return {
        modules: '*'
      };
    },
    getEnabledModules: function(project) {
      if (project.get('modules') === '*') {
        return this._getAllModules();
      } else {
        return JSON.parse(JSON.stringify(project.get('modules')));
      }
    },
    getSwitchableModules: function() {
      return _(_optional).clone();
    },
    isEnabled: function(query, project) {
      var module, moduleId, submoduleId, _ref, _ref1;
      _ref = query.split('#'), moduleId = _ref[0], submoduleId = _ref[1];
      module = _(this.getEnabledModules(project)).findWhere({
        id: moduleId
      });
      if (!module) {
        return false;
      }
      if (submoduleId) {
        return !((_ref1 = _(module.submodules).findWhere({
          id: submoduleId
        })) != null ? _ref1.disabled : void 0);
      } else {
        return !module.disabled;
      }
    },
    "switch": function(query, project) {
      var module, moduleId, newModules, submodule, submoduleId, _ref;
      _ref = query.split('#'), moduleId = _ref[0], submoduleId = _ref[1];
      newModules = this.getEnabledModules(project);
      module = _(newModules).findWhere({
        id: moduleId
      });
      if (submoduleId) {
        submodule = _(module != null ? module.submodules : void 0).findWhere({
          id: submoduleId
        });
        submodule.disabled = !submodule.disabled;
      } else {
        module.disabled = !module.disabled;
        if (!module.disabled) {
          module.submodules = this._getSubmoduleTree(module.submodules, _(module.submodules).pluck('id'));
        }
      }
      return project.save('modules', newModules).then(function() {
        return mediator.publish('modulesChanged');
      });
    }
  };
});
