FavoursEditor = require 'components/etd/soj_favours_editor'
Confirmation = require 'components/common/confirmation'
DialogBoxMixin = require 'components/mixins/dialog_box_mixin'
EtdActions = require 'actions/etd_actions'
Translation = require 'components/mixins/translation'

Favours = React.createClass
  mixins: [DialogBoxMixin, Translation('es:recommendations.table')]

  cancelCbk: ->
    @closeDialogBox()

  applyCbk: (newValue) ->
    @closeDialogBox()
    EtdActions.changeFavours @props.sectionKey, newValue

  edit: (e) ->
    props =
      cancelCbk: @cancelCbk
      applyCbk: @applyCbk
      value: @props.value
    @showInDialogBox e, FavoursEditor, props, 'soj-favours-editor-dialog'

  confirmCbk: ->
    @closeDialogBox()
    EtdActions.clearFavours()

  clearAll: (e)->
    props =
      cancelCbk: @cancelCbk
      confirmCbk: @confirmCbk
      question: @i18n 'confirm_implications_clear'
    @showInDialogBox e, Confirmation, props, 'soj-clear-dialog'

    e.preventDefault()

  render: ->
    <td colSpan="2"
      className={ classNames "favours-container", @props.importance }
      onClick={@edit}
      onContextMenu={@clearAll}>
        <span className={ classNames "favour favour-double-left",
          ('active' if @props.value is 'comparison') }>&#x21c7;</span>
        <span className={ classNames "favour favour-left",
          ('active' if @props.value is 'probably_comparison') }>&#x2190;</span>
        <span className={ classNames "favour favour-left-right",
          ('active' if @props.value is 'not_favours') }>&#x2194;</span>
        <span className={ classNames "favour favour-right",
          ('active' if @props.value is 'probably_intervention') }>&#x2192;</span>
        <span className={ classNames "favour favour-double-right",
          ('active' if @props.value is 'intervention') }>&#x21c9;</span>
    </td>

module.exports = Favours
