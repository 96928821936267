var Exceptions, GrdImport, IEView, ImportView, Outcomes, Questions, Rm5Import, Rm5ImportVerificationView, Shortcuts, W, mediator, sequence, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

IEView = require('views/import_export_view');

Rm5Import = require('lib/rm5_import');

GrdImport = require('lib/grd_import');

template = require('views/templates/import');

mediator = require('mediator');

Shortcuts = require('base/lib/traits/shortcuts');

Questions = require('models/questions');

Outcomes = require('models/outcomes');

W = require('when');

sequence = require('when/sequence');

Rm5ImportVerificationView = require('views/rm5_import_verification_view');

Exceptions = require('lib/exceptions');

module.exports = ImportView = (function(_super) {
  __extends(ImportView, _super);

  function ImportView() {
    this._handleImportError = __bind(this._handleImportError, this);
    this._createAndSwitchToProject = __bind(this._createAndSwitchToProject, this);
    return ImportView.__super__.constructor.apply(this, arguments);
  }

  ImportView.prototype.template = template;

  ImportView.prototype.id = 'import-dialog';

  ImportView.prototype.importQuestionsOnly = false;

  ImportView.prototype.importOutcomesOnly = false;

  ImportView.prototype.shortcuts = {
    'enter': 'importFile',
    'esc': 'dispose'
  };

  ImportView.prototype.initialize = function() {
    this.importQuestionsOnly = Boolean(this.options.importQuestionsOnly);
    this.importOutcomesOnly = Boolean(this.options.importOutcomesOnly);
    this.title = $.t(this.importOutcomesOnly ? 'projects:import_dialog.title_outcomes_only' : this.importQuestionsOnly ? 'projects:import_dialog.title_questions_only' : 'projects:import_dialog.title');
    ImportView.__super__.initialize.apply(this, arguments);
    this.delegate('change', '#file', this.fileChanged);
    this.delegate('change', 'input[type="checkbox"]', this.toggleSelected);
    this.delegate('click', 'button.import', this.importFile);
    this.delegate('click', 'button.cancel', this.dispose);
    this.delegate('click', 'button.file', function() {
      return this.$('#file').click();
    });
    this.delegate('mouseenter', 'button.file', function(e) {
      var button, height, width;
      if (!this.currentFile) {
        return;
      }
      button = this.$(e.target);
      width = button.outerWidth();
      height = button.outerHeight();
      return button.text($.t('projects:import_dialog.change_file')).width(width).height(height);
    });
    return this.delegate('mouseleave', 'button.file', this.setFileButtonCaption);
  };

  ImportView.prototype.fileChanged = function(e) {
    var file, valid;
    file = e.target.files[0];
    valid = this.isValidFile(file);
    this.render();
    if (valid) {
      this.currentFile = file;
      if (this.importOutcomesOnly || this.importQuestionsOnly) {
        return this._parseAndRenderQuestionsList();
      } else {
        this.setFileButtonCaption();
        return this.$('button.import').prop('disabled', false);
      }
    }
  };

  ImportView.prototype.setFileButtonCaption = function() {
    if (!this.currentFile) {
      return;
    }
    return this.$('button.file').text(this.currentFile.name).width('auto');
  };

  ImportView.prototype._parseAndRenderQuestionsList = function() {
    if (!this.currentFile) {
      return;
    }
    this.$('button.import').addClass('loading');
    return _.defer((function(_this) {
      return function() {
        var importingFile;
        importingFile = _this._parseFile();
        return W(importingFile).then(function(parsedProjects) {
          _this.questions = _(parsedProjects).chain().pluck(1).flatten().value();
          if (!_this.questions.length && _this.currentFile.name.split('.').pop() === 'rm5') {
            _this._noQuestionsIncluded(parsedProjects[0][0].get('rm5ReviewType'));
          }
          _this.render();
          return _this.setFileButtonCaption();
        }, _this._handleImportError).ensure(function() {
          return _this.$('button.import').removeClass('loading');
        });
      };
    })(this));
  };

  ImportView.prototype.getTemplateData = function() {
    var data, i, outcome, qjson, question, questionsAttributes;
    if (this.questions) {
      questionsAttributes = (function() {
        var _i, _j, _len, _len1, _ref, _ref1, _results;
        _ref = this.questions;
        _results = [];
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          question = _ref[_i];
          qjson = question.toJSON();
          _ref1 = qjson.outcomes;
          for (i = _j = 0, _len1 = _ref1.length; _j < _len1; i = ++_j) {
            outcome = _ref1[i];
            outcome.cid = question.get('outcomes').at(i).cid;
          }
          _results.push(qjson);
        }
        return _results;
      }).call(this);
    }
    data = {
      error: this.validationError,
      questions: questionsAttributes,
      importError: this.importError,
      importQuestionsOnly: this.importQuestionsOnly,
      importOutcomesOnly: this.importOutcomesOnly
    };
    return data;
  };

  ImportView.prototype.importFile = function() {
    var error;
    try {
      if (this.importOutcomesOnly) {
        return this._importSelectedOutcomes();
      } else if (this.importQuestionsOnly) {
        return this._importSelectedQuestions();
      } else {
        return this._importWholeFile();
      }
    } catch (_error) {
      error = _error;
      return this._handleImportError(error);
    }
  };

  ImportView.prototype._parseFile = function() {
    var fileExt, importingFile;
    if (!this.currentFile) {
      return;
    }
    fileExt = this.currentFile.name.split('.').pop();
    this.$('button.import').addClass('loading').forceRedraw();
    importingFile = fileExt === 'rm5' ? Rm5Import.parseFile(this.currentFile) : GrdImport.parseFile(this.currentFile);
    return W(importingFile).ensure((function(_this) {
      return function() {
        return _this.$('button.import').removeClass('loading');
      };
    })(this));
  };

  ImportView.prototype._importWholeFile = function() {
    return this._parseFile().then(this._createAndSwitchToProject).otherwise(this._handleImportError);
  };

  ImportView.prototype._importSelectedOutcomes = function() {
    var checkedOutcomes, outcome, outcomesArray, _i, _len;
    this.$('button.import').addClass('loading');
    outcomesArray = _(this.questions).chain().pluck('attributes').pluck('outcomes').pluck('models').flatten().value();
    checkedOutcomes = outcomesArray.filter((function(_this) {
      return function(o) {
        return _this.$el.find("input#" + o.cid + ":checked").length > 0;
      };
    })(this));
    for (_i = 0, _len = checkedOutcomes.length; _i < _len; _i++) {
      outcome = checkedOutcomes[_i];
      outcome.collection = null;
      outcome.set(Outcomes.prototype.comparatorAttribute, null);
      this.model.get('outcomes').add(outcome, {
        external: true
      });
    }
    this.model.save();
    mediator.dialogs.success($.t('es:import_dialog.outcomes_success'));
    return this.dispose();
  };

  ImportView.prototype._importSelectedQuestions = function() {
    var checkedQuestions, comparatorAttribute, i, question, questionsInProject, _i, _len;
    this.$('button.import').addClass('loading');
    questionsInProject = mediator.questions.length;
    checkedQuestions = this.questions.filter((function(_this) {
      return function(q) {
        return _this.$el.find("input#" + (q.get('questionNo')) + ":checked").length > 0;
      };
    })(this));
    comparatorAttribute = mediator.questions.comparatorAttribute;
    for (i = _i = 0, _len = checkedQuestions.length; _i < _len; i = ++_i) {
      question = checkedQuestions[i];
      question.set('questionNo', i + 1 + questionsInProject);
      question.set(comparatorAttribute, i + 1 + mediator.questions.getMaxOrderNumber());
      mediator.questions.create(question, {
        wait: true
      });
    }
    mediator.dialogs.success($.t('es:import_dialog.questions_success'));
    return this.dispose();
  };

  ImportView.prototype._createAndSwitchToProject = function(parsedProjects) {
    var creatingProjects, importView, project, questions;
    importView = this;
    creatingProjects = (function() {
      var _i, _len, _ref, _results;
      _results = [];
      for (_i = 0, _len = parsedProjects.length; _i < _len; _i++) {
        _ref = parsedProjects[_i], project = _ref[0], questions = _ref[1];
        _results.push(this._createProjectWithQuestions(project, questions));
      }
      return _results;
    }).call(this);
    if (!parsedProjects.length) {
      throw new Exceptions["import"].no_projects_in_file;
    }
    return sequence(creatingProjects).then((function(_this) {
      return function(projects) {
        return _this.verifyRm5Import(parsedProjects).then(function() {
          if (_(parsedProjects).size() === 1) {
            return mediator.publish('!router:route', "/projects/" + project.id, (function(_this) {
              return function(routed) {
                if (routed) {
                  mediator.dialogs.success($.t('projects:import_dialog.import_successful'));
                } else {
                  Raven.captureMessage('Switching to the newly imported project was unsuccessful');
                }
                return importView.dispose();
              };
            })(this));
          } else {
            return mediator.services.replication.sendLocalChanges(_(projects).pluck('id')).then(function() {
              mediator.dialogs.success($.t('projects:import_dialog.import_several_successful'));
              return importView.dispose();
            });
          }
        }, function(err) {
          if (err !== 'cancelled') {
            throw err;
          }
        });
      };
    })(this), function(err) {
      throw new Error("Import failed. Reason: " + err.message);
    });
  };

  ImportView.prototype.verifyRm5Import = function(parsedProjects) {
    var questions, verificationNeeded, _p, _ref;
    _ref = parsedProjects[0], _p = _ref[0], questions = _ref[1];
    verificationNeeded = false;
    verificationNeeded = _(questions).any(function(q) {
      return _(q.get('outcomes').models).any(function(o) {
        return o.rm5importVerification;
      });
    });
    if (verificationNeeded) {
      return new Rm5ImportVerificationView({
        questions: questions
      }).promise();
    } else {
      return W.resolve();
    }
  };

  ImportView.prototype._createProjectWithQuestions = function(project, questions) {
    return function() {
      return W(mediator.projects.create(project)).then(function(project) {
        var qs;
        qs = new Questions(null, {
          url: project.id
        });
        return W.map(questions, function(question) {
          return W.promise(function(resolve, reject) {
            return qs.create(question, {
              success: resolve,
              error: reject
            });
          });
        }).then(function() {
          return project;
        });
      });
    };
  };

  ImportView.prototype._handleImportError = function(error) {
    var _ref;
    if (typeof Raven !== "undefined" && Raven !== null) {
      Raven.captureException(error);
    }
    this.importError = (_ref = error.message) != null ? _ref : error;
    return this.render();
  };

  ImportView.prototype.isValidFile = function(f) {
    var allowedExtensions, errorMessage, fileExt, _ref;
    this.validationError = null;
    fileExt = f.name.split('.').pop();
    _ref = [['rm5', 'grd'], 'projects:import_dialog.invalid_format'], allowedExtensions = _ref[0], errorMessage = _ref[1];
    if (__indexOf.call(allowedExtensions, fileExt) < 0) {
      this.validationError = $.t(errorMessage);
      return false;
    }
    return true;
  };

  ImportView.prototype._noQuestionsIncluded = function(type) {
    var errorMessage;
    errorMessage = "projects:import_dialog.no_questions_included_" + (type.toLowerCase());
    return this.validationError = $.t(errorMessage);
  };

  ImportView.prototype.afterRender = function() {
    ImportView.__super__.afterRender.apply(this, arguments);
    return this.markSelected();
  };

  ImportView.prototype.clearFileInput = function() {
    var fieldSpan;
    fieldSpan = $('#file-input');
    return fieldSpan.html(fieldSpan.html());
  };

  return ImportView;

})(IEView);
