var Outcome, Question, Questions, interventionQuestions, utils,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

Outcome = require('models/outcome');

Question = require('models/question');

Questions = require('models/questions');

utils = require('base/lib/utils');

interventionQuestions = {
  measuresMap: {
    'risk ratio': 'RR',
    'odds ratio': 'OR',
    'risk difference': 'RD',
    'hazard ratio': 'HR',
    'std. mean difference': 'SMD',
    'mean difference': 'MD',
    'rate ratio': 'rateRatio'
  },
  dichMeasures: ['RR', 'OR', 'RD', 'HR', 'PETO_OR', 'rateRatio'],
  contMeasures: ['SMD', 'MD'],
  parseQuestions: function($xml, questionsFormat, projectName) {
    var healthProblemOrPopulation, questions, questionsNode;
    healthProblemOrPopulation = projectName.split(/\ (for|in)\ (.*)/)[2];
    questionsNode = $xml.find('ANALYSES_AND_DATA');
    questions = [];
    questionsNode.find('COMPARISON').each((function(_this) {
      return function(idx, item) {
        var interventionAndComparision, question, questionText, _ref, _ref1;
        questionText = $(item).find('NAME:first').text();
        interventionAndComparision = questionText.split(/\ vs\.?\ |\ versus\ /i);
        question = new Question({
          'questionNo': idx + 1,
          'revManId': $(item).attr('ID'),
          'questionFormat': questionsFormat,
          'intervention': (_ref = interventionAndComparision[0]) != null ? _ref : '[intervention]',
          'comparison': (_ref1 = interventionAndComparision[1]) != null ? _ref1 : 'placebo',
          'healthProblemOrPopulation': healthProblemOrPopulation != null ? healthProblemOrPopulation : 'health problem or population',
          'outcomes': _this.parseOutcomes($(item))
        });
        question.set(question.calculateTitles());
        return questions.push(question);
      };
    })(this));
    return questions;
  },
  parseOutcomes: function(questionNode) {
    var outcome, outcomes, _i, _len;
    outcomes = [];
    questionNode.children().each((function(_this) {
      return function(idx, item) {
        switch (item.nodeName) {
          case 'DICH_OUTCOME':
            return outcomes = outcomes.concat(_this.parseOutcomesByRmType($(item), 'dich'));
          case 'CONT_OUTCOME':
            return outcomes = outcomes.concat(_this.parseOutcomesByRmType($(item), 'cont'));
          case 'IV_OUTCOME':
            return outcomes = outcomes.concat(_this.parseOutcomesByRmType($(item), 'iv'));
          case 'IPD_OUTCOME':
            return outcomes = outcomes.concat(_this.parseOutcomesByRmType($(item), 'ipd'));
        }
      };
    })(this));
    for (_i = 0, _len = outcomes.length; _i < _len; _i++) {
      outcome = outcomes[_i];
      outcome.recalculateOutcomeData();
    }
    return outcomes;
  },
  parseOutcomesByRmType: function(outcomeNode, rmOutcomeType) {
    var outcome, outcomes;
    outcomes = [];
    outcome = this._parseOutcome(outcomeNode, rmOutcomeType);
    if (outcomeNode.attr('TOTALS') === 'SUB') {

    } else {
      outcomes.push(outcome);
    }
    outcomeNode.find(rmOutcomeType.toUpperCase() + '_SUBGROUP').each((function(_this) {
      return function(idx, item) {
        var subgroup;
        subgroup = $(item);
        return outcomes.push(_this._parseOutcome(subgroup, rmOutcomeType, outcome));
      };
    })(this));
    this._handleRiskDifference(outcomes);
    return outcomes;
  },
  _handleRiskDifference: function(outcomes) {
    var outcome, _i, _len, _results;
    _results = [];
    for (_i = 0, _len = outcomes.length; _i < _len; _i++) {
      outcome = outcomes[_i];
      if (outcome.get('effectMeasure') === 'RD') {
        _results.push(this.convertToAbsoluteEstimate(outcome));
      }
    }
    return _results;
  },
  convertToAbsoluteEstimate: function(outcome) {
    var denominator;
    denominator = outcome.get('absDenominator');
    outcome.set({
      absEffectAutoCalc: false,
      absEffectSize: outcome.get('effectSize') * denominator,
      absEffectFrom: outcome.get('confidenceIntervalFrom') * denominator,
      absEffectTo: outcome.get('confidenceIntervalTo') * denominator
    });
    return outcome.set({
      effectMeasure: '',
      effectSize: null,
      confidenceIntervalFrom: null,
      confidenceIntervalTo: null
    });
  },
  convertToRelativeEstimate: function(outcome) {
    var effectMeasure, otherMeasure, _ref;
    effectMeasure = (_ref = this.measuresMap[outcome.get('originalEffectMeasure').toLowerCase()]) != null ? _ref : 'other';
    otherMeasure = effectMeasure === 'other' ? outcome.get('originalEffectMeasure') : '';
    outcome.set({
      effectMeasure: effectMeasure,
      otherMeasure: otherMeasure,
      effectSize: outcome.get('originalEffectSize'),
      confidenceIntervalFrom: outcome.get('originalConfidenceIntervalFrom'),
      confidenceIntervalTo: outcome.get('originalConfidenceIntervalTo')
    });
    return outcome.set({
      absEffectAutoCalc: true,
      absEffectSize: null,
      absEffectFrom: null,
      absEffectTo: null
    });
  },
  _parseOutcome: function(outcomeNode, rmOutcomeType, parentOutcome) {
    var outcome, outcomeType, parentMeasure, riskMedian, _ref, _ref1;
    outcome = new Outcome({
      'name': outcomeNode.find('NAME:first').text(),
      'noOfStudies': (_ref = outcomeNode.attr('STUDIES')) != null ? _ref : outcomeNode.find(rmOutcomeType.toUpperCase() + '_DATA').length,
      'interventionCount': outcomeNode.attr('EVENTS_1'),
      'controlCount': outcomeNode.attr('EVENTS_2'),
      'interventionTotal': outcomeNode.attr('TOTAL_1'),
      'controlTotal': outcomeNode.attr('TOTAL_2'),
      'confidenceIntervalFrom': utils.parseFixedFloat(outcomeNode.attr('CI_START')),
      'confidenceIntervalTo': utils.parseFixedFloat(outcomeNode.attr('CI_END')),
      'effectMeasure': this._getOutcomeEffectMeasure(outcomeNode, rmOutcomeType),
      'effectSize': utils.parseFixedFloat(outcomeNode.attr('EFFECT_SIZE'))
    });
    outcomeType = this._getOutcomeType(outcome, rmOutcomeType, parentOutcome);
    if (outcomeType) {
      outcome.set('type', outcomeType);
    }
    this._setOutcomeMetaType(outcome, outcomeNode);
    this._setEffectForNotEstimableOutcome(outcome, parentOutcome, outcomeNode);
    riskMedian = this._getBaselineRiskMedian(outcomeNode, outcomeType, rmOutcomeType);
    if (riskMedian) {
      this._setBaselineRiskMedian(outcome, riskMedian);
    }
    if (parentOutcome) {
      outcome.set('name', "" + (parentOutcome.get('name')) + " - " + (outcome.get('name')));
      parentMeasure = parentOutcome.get('effectMeasure') === 'other' ? parentOutcome.get('otherMeasure') : parentOutcome.get('effectMeasure');
      outcome.set('effectMeasure', "" + parentMeasure);
    }
    if (outcome.get('effectMeasure') === 'PETO_OR') {
      outcome.set('effectMeasure', 'OR');
    }
    outcome.set('originalEffectMeasure', outcome.get('effectMeasure'));
    outcome.set('originalEffectSize', outcome.get('effectSize'));
    outcome.set('originalConfidenceIntervalFrom', outcome.get('confidenceIntervalFrom'));
    outcome.set('originalConfidenceIntervalTo', outcome.get('confidenceIntervalTo'));
    if (_ref1 = outcome.get('effectMeasure'), __indexOf.call(_([this.dichMeasures, this.contMeasures]).flatten(), _ref1) < 0) {
      this._handleUnrecognizedOutcomeMeasure(outcome);
    }
    return outcome;
  },
  _getBaselineRiskMedian: function(outcomeNode, outcomeType, rmOutcomeType) {
    var baselineRisks;
    if (outcomeType !== 'dich') {
      return;
    }
    baselineRisks = [];
    outcomeNode.find("" + (rmOutcomeType.toUpperCase()) + "_DATA").each(function(idx, item) {
      var $item, baselineRisk;
      $item = $(item);
      baselineRisk = 100 * ($item.attr('EVENTS_2') / $item.attr('TOTAL_2'));
      return baselineRisks.push(baselineRisk);
    });
    return utils.arrayMedian(baselineRisks);
  },
  _setBaselineRiskMedian: function(outcome, riskMedian) {
    return outcome.set({
      moderateControl: true,
      moderateControlRisk: utils.parseFixedFloat(riskMedian)
    });
  },
  _handleUnrecognizedOutcomeMeasure: function(outcome) {
    outcome.set('type', 'dich', {
      silent: true
    });
    this._detectEstimateTypeBasedOnEffectMeasure(outcome);
    return outcome.rm5importVerification = true;
  },
  _detectEstimateTypeBasedOnEffectMeasure: function(outcome) {
    var effectMeasureLc;
    effectMeasureLc = outcome.get('effectMeasure').toLowerCase();
    if (effectMeasureLc.indexOf('difference') > -1) {
      return this.convertToAbsoluteEstimate(outcome);
    } else {
      outcome.set('otherMeasure', outcome.get('effectMeasure'));
      return outcome.set('effectMeasure', 'other');
    }
  },
  _getOutcomeType: function(outcome, rmOutcomeType, parentOutcome) {
    var em, _ref;
    switch (rmOutcomeType) {
      case 'dich':
      case 'cont':
        return rmOutcomeType;
      case 'iv':
      case 'ipd':
        em = (_ref = parentOutcome != null ? parentOutcome.get('effectMeasure') : void 0) != null ? _ref : outcome.get('effectMeasure');
        if (__indexOf.call(this.dichMeasures, em) >= 0) {
          return 'dich';
        } else if (__indexOf.call(this.contMeasures, em) >= 0) {
          return 'cont';
        }
    }
  },
  _setOutcomeMetaType: function(outcome, outcomeNode) {
    var metaType;
    metaType = 'POOLED';
    if (outcomeNode.attr('TOTALS') === 'NO' || outcomeNode.attr('ESTIMABLE') === 'NO') {
      metaType = 'NOT_POOLED';
    }
    if (outcome.get('noOfStudies') === 1) {
      metaType = 'SINGLE_STUDY';
    }
    return outcome.set('metaType', metaType);
  },
  _setEffectForNotEstimableOutcome: function(outcome, parentOutcome, outcomeNode) {
    var dataNode, dataNodes, upperType;
    if (outcomeNode.attr('ESTIMABLE') !== 'NO') {
      return;
    }
    upperType = outcome.get('type').toUpperCase();
    dataNode = null;
    dataNodes = [];
    if (parentOutcome) {
      dataNodes = outcomeNode.find("" + upperType + "_DATA");
    } else {
      if (outcomeNode.find("" + upperType + "_SUBGROUP").length) {
        dataNodes = outcomeNode.find("" + upperType + "_SUBGROUP:first").find("" + upperType + "_DATA");
      } else {
        dataNodes = outcomeNode.find("" + upperType + "_DATA");
      }
    }
    if (dataNodes.length === 1) {
      dataNode = dataNodes.eq(0);
    }
    if (dataNode) {
      outcome.set('effectSize', utils.parseFixedFloat(dataNode.attr('EFFECT_SIZE')));
      outcome.set('confidenceIntervalFrom', utils.parseFixedFloat(dataNode.attr('CI_START')));
      return outcome.set('confidenceIntervalTo', utils.parseFixedFloat(dataNode.attr('CI_END')));
    }
  },
  _getOutcomeEffectMeasure: function(outcomeNode, outcomeType) {
    var effectMeasure, measureName, _ref;
    switch (outcomeType) {
      case 'dich':
      case 'cont':
        return outcomeNode.attr('EFFECT_MEASURE');
      case 'ipd':
      case 'iv':
        measureName = outcomeNode.find('EFFECT_MEASURE').text();
        effectMeasure = (_ref = this.measuresMap[measureName.toLowerCase()]) != null ? _ref : measureName;
        return effectMeasure;
    }
  }
};

module.exports = interventionQuestions.parseQuestions.bind(interventionQuestions);
