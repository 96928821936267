var ProjectsListItemQuestionsView, View, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

template = require('views/templates/projects_list_item_questions');

module.exports = ProjectsListItemQuestionsView = (function(_super) {
  __extends(ProjectsListItemQuestionsView, _super);

  function ProjectsListItemQuestionsView() {
    return ProjectsListItemQuestionsView.__super__.constructor.apply(this, arguments);
  }

  ProjectsListItemQuestionsView.prototype.template = template;

  ProjectsListItemQuestionsView.prototype.className = 'standard-list';

  ProjectsListItemQuestionsView.prototype.autoRender = true;

  ProjectsListItemQuestionsView.prototype.tagName = 'ul';

  ProjectsListItemQuestionsView.prototype.getTemplateData = function() {
    return {
      questions: this.options.questions.models,
      projectId: this.options.projectId
    };
  };

  return ProjectsListItemQuestionsView;

})(View);
