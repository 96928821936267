var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "<strong>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "sof_comparison", {"name":"i18n","hash":{},"data":data})))
    + "</strong>: "
    + escapeExpression(((helper = (helper = helpers.sofTitle || (depth0 != null ? depth0.sofTitle : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"sofTitle","hash":{},"data":data}) : helper)))
    + "\n<table class=\"standard-table research-evidence-sof\" width=\"100%\">\n  <tr>\n    <th>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/es:outcome.outcome", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.$tableMode : depth0), "narrative", {"name":"is","hash":{},"fn":this.program(2, data, depths),"inverse":this.program(4, data, depths),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "  </tr>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.includedOutcomes : depth0), {"name":"each","hash":{},"fn":this.program(6, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</table>\n";
},"2":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <th colspan=\"4\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/es:outcome.impact", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n";
},"4":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "      <th>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "with", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.comparison || (depth0 != null ? depth0.comparison : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparison","hash":{},"data":data}) : helper)))
    + "</th>\n      <th>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "with", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.intervention || (depth0 != null ? depth0.intervention : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"intervention","hash":{},"data":data}) : helper)))
    + "</th>\n      <th class=\"with-info\">\n        <span class=\"info-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "../tooltips.confidence_interval", {"name":"i18n","hash":{},"data":data})))
    + "\">\n        </span>\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "difference", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "confidence_interval", {"name":"i18n","hash":{},"data":data})))
    + "\n      </th>\n      <th class=\"with-info\">\n        <span class=\"info-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "../tooltips.relative_effect_sof", {"name":"i18n","hash":{},"data":data})))
    + "\">\n        </span>\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "relative_effect", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "confidence_interval", {"name":"i18n","hash":{},"data":data})))
    + "\n      </th>\n";
},"6":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "  <tr>\n    <td rowspan=\""
    + escapeExpression(((helper = (helper = helpers.rowspan || (depth0 != null ? depth0.rowspan : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"rowspan","hash":{},"data":data}) : helper)))
    + "\">";
  stack1 = ((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</td>\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "narrative", {"name":"is","hash":{},"fn":this.program(7, data, depths),"inverse":this.program(9, data, depths),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "  </tr>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.tailControls : depth0), {"name":"each","hash":{},"fn":this.program(11, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"7":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <td colspan=\"4\">"
    + escapeExpression(((helper = (helper = helpers.narrativeDesc || (depth0 != null ? depth0.narrativeDesc : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"narrativeDesc","hash":{},"data":data}) : helper)))
    + "</td>\n";
},"9":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, functionType="function", escapeExpression=this.escapeExpression, buffer = "      <td rowspan=\"1\">";
  stack1 = ((helpers.displaySofControl || (depth0 && depth0.displaySofControl) || helperMissing).call(depth0, depth0, (depth0 != null ? depth0.headControl : depth0), {"name":"displaySofControl","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</td>\n      <td rowspan=\"1\">";
  stack1 = ((helpers.displayCorrespondingRisk || (depth0 && depth0.displayCorrespondingRisk) || helperMissing).call(depth0, depth0, (depth0 != null ? depth0.headControl : depth0), {"name":"displayCorrespondingRisk","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</td>\n      <td rowspan=\"1\">";
  stack1 = ((helpers.displayAbsoluteEffect || (depth0 && depth0.displayAbsoluteEffect) || helperMissing).call(depth0, depth0, (depth0 != null ? depth0.headControl : depth0), {"name":"displayAbsoluteEffect","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</td>\n      <td rowspan=\""
    + escapeExpression(((helper = (helper = helpers.rowspan || (depth0 != null ? depth0.rowspan : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"rowspan","hash":{},"data":data}) : helper)))
    + "\">";
  stack1 = ((helpers.displayRelativeEffect || (depth0 && depth0.displayRelativeEffect) || helperMissing).call(depth0, depth0, {"name":"displayRelativeEffect","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</td>\n";
},"11":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "    <tr>\n      <td>";
  stack1 = ((helpers.displaySofControl || (depth0 && depth0.displaySofControl) || helperMissing).call(depth0, depths[1], depth0, {"name":"displaySofControl","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</td>\n      <td>";
  stack1 = ((helpers.displayCorrespondingRisk || (depth0 && depth0.displayCorrespondingRisk) || helperMissing).call(depth0, depths[1], depth0, {"name":"displayCorrespondingRisk","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</td>\n      <td>";
  stack1 = ((helpers.displayAbsoluteEffect || (depth0 && depth0.displayAbsoluteEffect) || helperMissing).call(depth0, depths[1], depth0, {"name":"displayAbsoluteEffect","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</td>\n    </tr>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "/es:recommendations.table.benefits_harms", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}