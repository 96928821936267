var ChangelogView, View, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

template = require('./templates/changelog');

mediator = require('mediator');

module.exports = ChangelogView = (function(_super) {
  __extends(ChangelogView, _super);

  function ChangelogView() {
    return ChangelogView.__super__.constructor.apply(this, arguments);
  }

  ChangelogView.prototype.className = 'changelog-view';

  ChangelogView.prototype.template = template;

  ChangelogView.prototype.initialize = function() {
    var fetchChanges;
    ChangelogView.__super__.initialize.apply(this, arguments);
    this.delegate('click', 'button.projects-list', (function(_this) {
      return function() {
        return _this.publishEvent('!router:route', '/projects');
      };
    })(this));
    fetchChanges = (function(_this) {
      return function() {
        _this.loading = true;
        return mediator.services.projectChangelog.getChangelog().then(function(changes) {
          _this.changes = changes;
          _this.loading = false;
          return _this.render();
        });
      };
    })(this);
    this.model = mediator.projects;
    this.modelBind('add', fetchChanges);
    return fetchChanges();
  };

  ChangelogView.prototype.getTemplateData = function() {
    return {
      changes: this.changes,
      loading: this.loading
    };
  };

  return ChangelogView;

})(View);
