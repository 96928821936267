var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <li>"
    + escapeExpression(((helper = (helper = helpers.note || (depth0 != null ? depth0.note : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"note","hash":{},"data":data}) : helper)))
    + "</li>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "<!doctype html>\n<html class=\"no-js\" lang=\"en\">\n<head>\n  <meta charset=\"utf-8\">\n  <meta http-equiv=\"X-UA-Compatible\" content=\"IE=edge,chrome=1\">\n  <title>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "printout_title", {"name":"i18n","hash":{},"data":data})))
    + "</title>\n  <meta name=\"viewport\" content=\"width=device-width,initial-scale=1\">\n  <style media=\"screen,print\" type=\"text/css\">\n";
  stack1 = this.invokePartial(partials.printoutStylesHead, '    ', 'printoutStylesHead', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n    body {\n      width:100%;\n      height:100%;\n      -webkit-print-color-adjust: exact;\n      font: normal 8pt 'Arial Narrow', Arial, sans-serif;\n      font-stretch: condensed;\n      color: black;\n      margin: 0;\n      padding: 0;\n    }\n\n    table {\n      border-spacing: 0px;\n      border-collapse: collapse;\n      border: 1px solid #bfbfbf;\n      font-family: Arial;\n      font-size: 8pt;\n      width: 100%;\n    }\n\n    td {\n      vertical-align: top;\n      text-align: left;\n    }\n\n    th {\n      background: #dddddd;\n      text-align: center;\n      font-weight: normal;\n    }\n\n    td, th {\n      border: solid 1px #bfbfbf;\n      padding: 5px;\n    }\n\n    thead,\n    tbody {\n      border-bottom: solid 1px #bfbfbf;\n    }\n\n    table.diagnostic-meta {\n      margin-bottom: 15px;\n    }\n\n    table.diagnostic-meta,\n    table.diagnostic-meta > tbody,\n    table.diagnostic-meta > tbody > tr,\n    table.diagnostic-meta > tbody > tr > td {\n      border: 0px solid #ffffff;\n    }\n\n    .no-border {\n      border: 0px;\n    }\n\n    table.diagnostic-meta > tbody > tr > td {\n      margin: 0px;\n      padding: 0px;\n    }\n\n    table.diagnostic-meta td {\n      vertical-align: middle ;\n    }\n\n    td.with-info,\n    td.label-cell {\n      background-color: #e9e9e9;\n    }\n\n    td.quality div {\n      min-width: 86px;\n    }\n\n    table.diagnostic-prevalences .value-cell {\n      width: 50px;\n      text-align: center;\n    }\n\n    .diagnostic-outcome-label {\n      background: #dddddd;\n    }\n\n    .cell-content {\n      margin-top: 0.5em 0.1em;\n    }\n\n    .not-editable-cell,\n    .normal-font {\n      font-weight: normal;\n    }\n\n    .not-editable-cell {\n      background-color: #dddddd;\n    }\n\n    .quality-sign {\n      font-family: 'GRADE-quality', Cambria, Helvetica, Arial;\n      font-size: 14px;\n    }\n\n  </style>\n</head>\n<body>\n  <p>\n    <b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:question.question", {"name":"i18n","hash":{},"data":data})))
    + "</b>: "
    + escapeExpression(((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"question","hash":{},"data":data}) : helper)))
    + "<br>\n  </p>\n\n  <div class=\"outcomes-container\">\n\n  </div>\n\n  <ol>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.notes : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </ol>\n</body>\n</html>\n";
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}