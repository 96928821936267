var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"questions-view\">\n  <ul class=\"standard-list\"></ul>\n</div>\n<div class=\"outcomes-table-container\">\n  <table class=\"standard-table outcomes-table\">\n    <thead>\n      <tr class=\"outcome-accp-row\">\n        <th rowspan=\"2\" class=\"participants\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "participants", {"name":"i18n","hash":{},"data":data})))
    + " <br>\n          ("
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "studies", {"name":"i18n","hash":{},"data":data})))
    + ")\n        </th>\n        <th rowspan=\"2\" class=\"risk-of-bias\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "risk_of_bias", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th rowspan=\"2\" class=\"inconsistency\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "inconsistency", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th rowspan=\"2\" class=\"indirectness\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "indirectness", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th rowspan=\"2\" class=\"imprecision\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "imprecision", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th rowspan=\"2\" class=\"other-considerations\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "publication_bias", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th rowspan=\"2\" class=\"quality\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "overall_quality_of_evidence", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th colspan=\"2\" class=\"regular-header\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "study_event_rates", {"name":"i18n","hash":{},"data":data})))
    + " (%)</th>\n        <th rowspan=\"2\" class=\"relative-effect regular-header\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "relative_effect", {"name":"i18n","hash":{},"data":data})))
    + " (95% CI)\n        </th>\n        <th colspan=\"2\" class=\"regular-header\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "anticipated_absolute_effects", {"name":"i18n","hash":{},"data":data})))
    + "\n        </th>\n        <th colspan=\"2\" rowspan=\"2\" class=\"narrative-header\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "impact", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th rowspan=\"2\" class=\"table-mode\">\n          <button class=\"table-mode\"></button>\n        </th>\n      </tr>\n      <tr class=\"outcome-accp-row\">\n        <th class=\"event-rates-with-control regular-header\">\n        </th>\n        <th class=\"event-rates-with-intervention regular-header\">\n        </th>\n        <th class=\"risk-with-control regular-header\">\n        </th>\n        <th class=\"risk-difference-with-intervention regular-header\">\n        </th>\n      </tr>\n    </thead>\n\n    <tbody></tbody>\n\n  </table>\n  <div class=\"row mt-10\">\n";
  stack1 = this.invokePartial(partials.outcomeButtons, '    ', 'outcomeButtons', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </div>\n</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}