Translation = require 'components/mixins/translation'

FavoursEditor = React.createClass
  mixins: [Translation('es:recommendations.table')]

  getInitialState: ->
    selectedInput: @props.value

  select: (value) -> =>
    @setState selectedInput: value

  apply: ->
    @props.applyCbk @state.selectedInput

  favoursInput: (i18nKey, className, value, sign) ->
    <label>
      {@i18n i18nKey}
      <div className={classNames 'favour', className,'active'}>{sign}</div>
      <input
        name="favours"
        type="radio"
        onChange={@select(value)}
        checked={value is @state.selectedInput}
        value={value}/>
    </label>

  render: ->
    <div className="soj-editor">
      <div className="main-question">
        <div className="label">{@i18n 'cost_effectivenes'}:</div>
        <div className="question">{@i18n 'cost_effectivenes_question'}</div>
      </div>
      <div className="evaluation-question">{@i18n 'cost_effectivenes_evaluation_question'}</div>
      <div className="favours-selection favours-container">
        {@favoursInput 'favours_comparison', 'favour-double-left', 'comparison', '⇇'}
        {@favoursInput 'probably_favours_comparison', 'favour-left', 'probably_comparison', '←'}
        {@favoursInput 'not_favours', 'favour-left-right', 'not_favours', '↔'}
        {@favoursInput 'probably_favours_intervention', 'favour-right',
          'probably_intervention', '→'}
        {@favoursInput 'favours_comparison', 'favour-double-right', 'intervention', '⇉'}
        {@favoursInput 'favours_unselected', 'favour-empty', 'none', '\u200B'}
      </div>
      <div className="buttons">
        <button className="btn" onClick={@props.cancelCbk}>Cancel</button>
        <button className="btn apply" onClick={@apply}>Apply</button>
      </div>
    </div>

module.exports = FavoursEditor
