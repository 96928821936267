var AdministrationController, EtdTemplatesView, ModuleController, VotingView,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModuleController = require('controllers/base/module_controller');

EtdTemplatesView = require('views/administration/etd_templates_view');

VotingView = require('views/administration/voting_view');

module.exports = AdministrationController = (function(_super) {
  __extends(AdministrationController, _super);

  function AdministrationController() {
    return AdministrationController.__super__.constructor.apply(this, arguments);
  }

  AdministrationController.prototype.defaultSubmodule = 'etd-templates';

  AdministrationController.prototype.viewsForSubmodules = {
    'etd-templates': EtdTemplatesView,
    'voting': VotingView
  };

  return AdministrationController;

})(ModuleController);
