var ModalView, Shortcuts, View, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('./view');

mediator = require('mediator');

Shortcuts = require('base/lib/traits/shortcuts');

module.exports = ModalView = (function(_super) {
  __extends(ModalView, _super);

  function ModalView() {
    return ModalView.__super__.constructor.apply(this, arguments);
  }

  ModalView.prototype.autoRender = true;

  ModalView.prototype.shortcuts = {
    'esc': 'dispose'
  };

  ModalView.prototype.initialize = function() {
    ModalView.__super__.initialize.apply(this, arguments);
    this.enable(Shortcuts);
    mediator.setFocus(this);
    return this.delegate('click', this.gainFocus);
  };

  ModalView.prototype.onFocusGained = function() {
    if (this.autoRender) {
      return this.render();
    }
  };

  ModalView.prototype.afterRender = function() {
    ModalView.__super__.afterRender.apply(this, arguments);
    if (this.dialog == null) {
      this.dialog = mediator.dialogs.modal(this.title, this.el, this.options);
    }
    return this.dialog.$el.find('button.notifier-close').on('click', this.dispose);
  };

  ModalView.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    return this.dialog.destroy((function(_this) {
      return function() {
        return ModalView.__super__.dispose.apply(_this, arguments);
      };
    })(this));
  };

  return ModalView;

})(View);
