templatesDefinitions = require 'lib/etd_templates/templates_definitions'
Translation = require 'components/mixins/translation'
PureRenderMixin = React.addons.PureRenderMixin
Select = require 'components/common/select'

BaseTemplateSelection = React.createClass
  displayName: 'BaseTemplateSelection'

  mixins: [PureRenderMixin, Translation('administration:etd_templates')]

  getTemplatesOptions: ->
    filter = (questionType) -> (templ) ->
      _.string.startsWith templ.id, questionType
    mapper = (templ) ->
      {value: templ.id, text: templ.name}

    initialOptions = if @props.showEmptyValue
      ['']
    else
      []

    txTemplates = _(templatesDefinitions).filter(filter('tx')).map(mapper)
    dxTemplates = _(templatesDefinitions).filter(filter('dx')).map(mapper)
    if @props.listTxTemplates and @props.listDxTemplates
      initialOptions
      .concat txTemplates
      .concat [{text: '────────', disabled: true}]
      .concat dxTemplates
    else if @props.listTxTemplates
      initialOptions.concat txTemplates
    else if @props.listDxTemplates
      initialOptions.concat dxTemplates

  getLabel: ->
    if @props.listTxTemplates and @props.listDxTemplates
      @i18n 'select_base_template'
    else if @props.listTxTemplates
      @i18n 'select_base_tx_template'
    else if @props.listDxTemplates
      @i18n 'select_base_dx_template'

  render: ->
    @templateOptions = @getTemplatesOptions()
    if not @props.listTxTemplates and not @props.listDxTemplates
      null
    else
      <p>
        <span className='template-select-text'>{@getLabel()}</span>
        <Select
          options={@templateOptions}
          selected={@props.selectedTemplate}
          onChange={@props.changeBaseTemplate} />
      </p>

module.exports = BaseTemplateSelection
