var CharactersLimit, ViewTrait,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ViewTrait = require('base/lib/traits/view_trait');

module.exports = CharactersLimit = (function(_super) {
  __extends(CharactersLimit, _super);

  function CharactersLimit() {
    return CharactersLimit.__super__.constructor.apply(this, arguments);
  }

  CharactersLimit.prototype.limits = [];

  CharactersLimit.prototype.apply = function(view) {
    CharactersLimit.__super__.apply.apply(this, arguments);
    this._addFunction(view, '_addCharactersLimitInfo');
    this._addFunction(view, 'afterRender');
    return this._addFunction(view, 'dispose');
  };

  CharactersLimit.prototype._limitInfoEl = function(limit) {
    var $limitInfo;
    $limitInfo = $('<span class="inner-text"></span>');
    $limitInfo.text($.t('messages.characters_limit', {
      charsLeft: limit
    }));
    return $limitInfo;
  };

  CharactersLimit.prototype._modelDataInvalid = function(model, limitInfo) {
    var limit, modelAttr;
    limit = limitInfo.limit, modelAttr = limitInfo.modelAttr;
    return modelAttr && model.get(modelAttr).length > limit;
  };

  CharactersLimit.prototype._wrapTextEl = function($textEl, $limitInfo) {
    $textEl.wrap($('<span class="text-with-limit"></span>'));
    return $textEl.parent().append($limitInfo);
  };

  CharactersLimit.prototype._addListener = function($textEl, $limitInfo, limit) {
    return $textEl.on('keyup.charactersLimit', function() {
      var charsLeft;
      charsLeft = limit - $textEl.val().length;
      if (charsLeft < 0) {
        $textEl.val($textEl.val().slice(0, limit));
        charsLeft = 0;
      }
      return $limitInfo.text($.t('messages.characters_limit', {
        charsLeft: charsLeft
      }));
    });
  };

  CharactersLimit.prototype._addCharactersLimitInfo = function(originalFunction, trait) {
    var $limitInfo, $textEl, limitInfo, _i, _len, _ref, _results;
    _ref = trait.limits;
    _results = [];
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      limitInfo = _ref[_i];
      $textEl = this.$(limitInfo.selector);
      if ($textEl.parent().hasClass('text-with-limit') || !$textEl.length || trait._modelDataInvalid(this.model, limitInfo)) {
        continue;
      }
      $limitInfo = trait._limitInfoEl(limitInfo.limit - $textEl.val().length);
      trait._wrapTextEl($textEl, $limitInfo);
      _results.push(trait._addListener($textEl, $limitInfo, limitInfo.limit));
    }
    return _results;
  };

  CharactersLimit.prototype.afterRender = function(originalFunction, trait) {
    originalFunction.apply(this);
    return this._addCharactersLimitInfo();
  };

  CharactersLimit.prototype.dispose = function(originalFunction, trait) {
    var limitInfo, _i, _len, _ref;
    if (this.disposed) {
      return;
    }
    _ref = trait.limits;
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      limitInfo = _ref[_i];
      this.$(".text-with-limit " + limitInfo.selector).off('keyup.charactersLimit');
    }
    return originalFunction.apply(this);
  };

  return CharactersLimit;

})(ViewTrait);
