ContentEditable = require 'components/common/content_editable'
PureRenderMixin = React.addons.PureRenderMixin

EtdHeaderEditableCell = React.createClass
  displayName: 'EtdHeaderEditableCell'
  mixins: [PureRenderMixin]

  withEditor: ->
    @props.sectionId in ['mainOutcomes', 'anticipatedOutcomes', 'background']

  handleUserInput: (e) ->
    content = e.target.innerHTML
    sectionId = @props.sectionId
    @props.handleUserInput {content, sectionId}

  getEditModeElement: ->
    if @props.sectionId in ['intervention', 'comparison']
      <input ref="editableField" type="text" value={@props.content} disabled />
    else
      editableClassNames = classNames
        "rows-1": @props.sectionId in ['purpose', 'linkedTreatments', 'tableTitle']
        "rows-2": @props.sectionId in ['population', 'setting', 'perspective']
        "rows-6": @props.sectionId in ['anticipatedOutcomes', 'mainOutcomes', 'draftRecommendation']
        "rows-max": @props.sectionId is 'background'

      <ContentEditable
        className=editableClassNames
        ref="editableField"
        html={@props.content}
        withEditor={@withEditor()}
        onInput={@handleUserInput}
      />

  render: ->
    if @props.isEditing
      <td rowSpan={@props.rowSpan}>
        <span>
          {@getEditModeElement()}
        </span>
      </td>
    else
      <td rowSpan={@props.rowSpan}>
        <div dangerouslySetInnerHTML={{__html: @props.content}}/>
      </td>

module.exports = EtdHeaderEditableCell
