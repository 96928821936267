var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"questions-view\">\n  <ul class=\"standard-list\"></ul>\n</div>\n<div class=\"outcomes-table-container\">\n  <table class=\"standard-table outcomes-table\">\n    <thead>\n      <tr class=\"prognostic-table\">\n        <th class=\"no-of-studies\" rowspan=\"2\">";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "no_of_studies", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</th>\n        <th colspan=\"6\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "quality_assessment", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th colspan=\"3\" class=\"regular-header\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "effect", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th colspan=\"3\" rowspan=\"2\" class=\"impact narrative-header\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "impact", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th rowspan=\"2\" class=\"quality\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "quality", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th colspan=\"1\" rowspan=\"2\" class=\"importance\">\n          <span class=\"table-menu\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "importance", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n          <div class=\"table-menu\">\n            <button class=\"table-mode\"></button>\n          </div>\n        </th>\n      </tr>\n      <tr class=\"prognostic-table\">\n        <th class=\"design-studies\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "study_design", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th class=\"risk-of-bias\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "risk_of_bias", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th class=\"inconsistency\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "inconsistency", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th class=\"indirectness\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "indirectness", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th class=\"imprecision\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "imprecision", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th class=\"other-considerations\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "other_considerations", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th class=\"events regular-header\">";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "no_of_events", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</th>\n        <th class=\"individuals regular-header\">";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "no_of_individuals", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</th>\n        <th class=\"rate regular-header\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "rate", {"name":"i18n","hash":{},"data":data})))
    + "<br>(95% CI)</th>\n      </tr>\n    </thead>\n    <tbody></tbody>\n  </table>\n  <div class=\"row mt-10\">\n";
  stack1 = this.invokePartial(partials.outcomeButtons, '    ', 'outcomeButtons', depth0, {
    'prognostic': ("true")
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </div>\n</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}