var Validation, utils,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

utils = require('base/lib/utils');

module.exports = Validation = (function(_super) {
  var defaultOptions, format, formatLabel, messages, patterns, validators;

  __extends(Validation, _super);

  function Validation() {
    return Validation.__super__.constructor.apply(this, arguments);
  }

  defaultOptions = {
    forceUpdate: false,
    selector: 'name',
    labelFormatter: 'sentenceCase',
    valid: Function.prototype,
    invalid: Function.prototype
  };

  messages = _.extend(Backbone.Validation.messages, {
    required: $.t('errors:model.required'),
    acceptance: $.t('errors:model.acceptance'),
    min: $.t('errors:model.min'),
    max: $.t('errors:model.max'),
    range: $.t('errors:model.range'),
    length: $.t('errors:model.length'),
    minLength: $.t('errors:model.minLength'),
    maxLength: $.t('errors:model.maxLength'),
    rangeLength: $.t('errors:model.rangeLength'),
    oneOf: $.t('errors:model.oneOf'),
    equalTo: $.t('errors:model.equalTo'),
    pattern: $.t('errors:model.pattern')
  });

  format = function() {
    var args, text;
    args = Array.prototype.slice.call(arguments);
    text = args.shift();
    return text.replace(/\{(\d+)\}/g, function(match, number) {
      if (typeof args[number] !== 'undefined') {
        return args[number];
      } else {
        return match;
      }
    });
  };

  formatLabel = function(attrName, model) {
    return Validation.labelFormatters[defaultOptions.labelFormatter](attrName, model);
  };

  validators = _.extend(Backbone.Validation.validators, {
    pattern: function(value, attr, pattern, model) {
      if (!utils.hasValue(value)) {
        value = '';
      }
      if (!value.toString().match(Validation.patterns[pattern] || pattern)) {
        return format(Validation.messages.pattern, formatLabel(attr, model), utils.camelcaseToSentence(pattern));
      }
    },
    gt: function(value, attr, customValue, model, computedState) {
      if (parseFloat(computedState[attr]) <= parseFloat(customValue)) {
        return $.t('errors:model.greater_than', {
          value: value
        });
      }
    },
    atLeastOneRequired: function(value, attr, customValue, model, computedState) {
      var attrs, values, withValue, _i, _len;
      attrs = _(customValue.split(',')).map(function(a) {
        return _.string.trim(a);
      });
      values = [];
      for (_i = 0, _len = attrs.length; _i < _len; _i++) {
        attr = attrs[_i];
        values.push(computedState[attr]);
      }
      withValue = (_(values).filter(function(v) {
        return utils.hasValue(v);
      })).length;
      if (withValue === 0) {
        return $.t('errors:model.as_least_one_required', {
          fields: customValue
        });
      }
    },
    lt: function(value, attr, customValue, model, computedState) {
      var cmpValue;
      cmpValue = parseFloat(computedState[customValue]);
      if (parseFloat(computedState[attr]) >= cmpValue) {
        return $.t('errors:model.less_than', {
          cmpValue: cmpValue
        });
      }
    }
  });

  patterns = _.extend(Backbone.Validation.patterns, {
    numberOrEmpty: /^$|^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/,
    numberOrRangeOrEmpty: /^$|^-?\d+(\.\d{1,2})?(?:\s*(?:-|to)\s*-?\d+(\.\d{1,2})?)?$/
  });

  Validation.isValidEmail = function(email) {
    return patterns.email.test(email);
  };

  Validation.isValidGdtLogin = function(login) {
    return /^[a-z0-9_-]+$/.test(login) && login[0] !== '_';
  };

  return Validation;

})(Backbone.Validation);
