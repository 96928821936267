Handlebars.registerHelper('diagnosticQuestionHeader', function() {
  var i18nTempl, params;
  params = {
    indexTest: "<span class='input-with-button'> <input type='text' name='indexTest' id='indexTest'> <button class='input-button add-comparator-test add' title='" + ($.t('es:question.add_comparator_test')) + "'></button> </span>",
    comparatorTest: "<span class='input-with-button'> <input type='text' name='comparatorTest' id='comparatorTest'> <button class='input-button remove-comparator-test remove' title='" + ($.t('es:question.remove_comparator_test')) + "'></button> </span>",
    targetCondition: '<input type="text" name="targetCondition" id="targetCondition">',
    healthProblemOrPopulation: '<input type="text" name="healthProblemOrPopulation" id="healthProblemOrPopulation">'
  };
  i18nTempl = this.comparatorTestPresent ? 'es:question.templates.diagnostic_vs' : 'es:question.templates.diagnostic';
  return new Handlebars.SafeString($.t(i18nTempl, params));
});

Handlebars.registerHelper('managementQuestionHeader', function() {
  var params;
  params = {
    intervention: '<input type="text" name="intervention" id="intervention">',
    comparison: '<input type="text" name="comparison" id="comparison">',
    forOrIn: '<select name="questionFormat" id="questionFormat"></select>',
    healthProblemOrPopulation: '<input type="text" name="healthProblemOrPopulation" id="healthProblemOrPopulation">'
  };
  return new Handlebars.SafeString($.t('es:question.templates.general', params));
});

Handlebars.registerHelper('prognosticQuestionHeader', function() {
  var params;
  params = {
    healthCondition: '<input type="text" name="healthCondition" id="healthCondition">',
    population: '<select name="population" id="population"></select> <input type="text" name="populationOther" id="populationOther">',
    overTime: '<input type="text" name="overTime" id="overTime">'
  };
  return new Handlebars.SafeString($.t('prognosis:question.templates.general', params));
});
