var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<td "
    + escapeExpression(((helper = (helper = helpers.colNo || (depth0 != null ? depth0.colNo : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"colNo","hash":{},"data":data}) : helper)))
    + " data-property=\"importance\" class=\"importance\">\n  <div class=\"cell-content\">\n    <span>"
    + escapeExpression(((helper = (helper = helpers.importanceLabel || (depth0 != null ? depth0.importanceLabel : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"importanceLabel","hash":{},"data":data}) : helper)))
    + "</span>\n  </div>\n</td>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}