var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<div class=\"download-button\">\n  <div id=\"downloadify-wrapper\"></div>\n  <button class=\"finish\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "actions.save_to_disk", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n</div>\n<div class=\"notifier-btns\">\n  <button class=\"cancel\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "actions.cancel", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}