var CollectionView, ProjectsListItemView, ProjectsListView, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

CollectionView = require('base/views/collection_view');

ProjectsListItemView = require('views/projects_list_item_view');

template = require('views/templates/projects_list');

mediator = require('mediator');

module.exports = ProjectsListView = (function(_super) {
  __extends(ProjectsListView, _super);

  function ProjectsListView() {
    return ProjectsListView.__super__.constructor.apply(this, arguments);
  }

  ProjectsListView.prototype.template = template;

  ProjectsListView.prototype.listSelector = 'table';

  ProjectsListView.prototype.className = 'projects-list';

  ProjectsListView.prototype.itemView = ProjectsListItemView;

  ProjectsListView.prototype.initialize = function() {
    ProjectsListView.__super__.initialize.apply(this, arguments);
    this.delegate('click', '.sort.nr', this._sortByNr);
    this.delegate('click', '.sort.name', this._sortByName);
    this.delegate('click', '.sort.customer', this._sortByCustomer);
    this.delegate('click', '.sort.date', this._sortByDate);
    return this.options.isMenuMode = this.options.isMenuMode || false;
  };

  ProjectsListView.prototype.getTemplateData = function() {
    return {
      projects: this.collection,
      isActive: this.options.isActive,
      isMenuMode: this.options.isMenuMode
    };
  };

  ProjectsListView.prototype._sort = function(order, sortFn) {
    var sorted;
    sorted = _.sortBy(this.collection.models, sortFn);
    if (order === this.lastOrder) {
      this.collection.models = sorted.reverse();
      this.lastOrder = null;
    } else {
      this.collection.models = sorted;
      this.lastOrder = order;
    }
    return this.renderAllItems();
  };

  ProjectsListView.prototype._sortByNr = function() {
    return this._sort('nr', function(project) {
      return project.orderNumber;
    });
  };

  ProjectsListView.prototype._sortByName = function() {
    return this._sort('name', function(project) {
      return project.attributes.name;
    });
  };

  ProjectsListView.prototype._sortByCustomer = function() {
    return this._sort('customer', function(project) {
      return project.attributes.customer;
    });
  };

  ProjectsListView.prototype._sortByDate = function() {
    return this._sort('date', function(project) {
      return project.timestamp;
    });
  };

  return ProjectsListView;

})(CollectionView);
