MembersStore = require 'stores/members_store'
VotingStore = require 'stores/voting_store'
VotingActions = require 'actions/voting_actions'
MembersActions = require 'actions/members_actions'
PureRenderMixin = React.addons.PureRenderMixin
FetchingSpinner = require 'components/mixins/fetching_spinner'
CreateStoreMixin = require '../mixins/create_store'
Translation = require 'components/mixins/translation'
Tabs = epReactComponents.common.Tabs
Button = require 'components/common/button'
SpinnerButton = require 'components/common/spinner_button'
ContentEditable = require 'components/common/content_editable'
mediator = require 'mediator'
Router = require 'router'
votingHelpers = require 'stores/utils/rand_voting_helpers'
Modal = require 'components/common/modal'
votingExport = require 'lib/voting_export'

PanelCheckboxes = React.createClass
  displayName: 'VotingPanelCheckboxes'
  mixins: [
    PureRenderMixin
    Translation('administration:voting')
  ]

  propTypes:
    members: React.PropTypes.object.isRequired
    selectedMemberIds: React.PropTypes.object

  getMemberText: (member) ->
    "#{member.get 'givenNames'} #{member.get 'lastName'} (#{member.get 'email'})"

  redirectToMembers: (e) ->
    e.preventDefault()
    mediator.publish '!router:route', Router::getProjectRelativeUrl("/team")

  redirectToMembersInfo: ->
    <div>
      {@i18n 'goto_members', {},
        members_link: <a href='#' onClick={@redirectToMembers}>{@i18n 'members_section'}</a>}
    </div>

  memberChanged: (member) -> =>
    MembersActions.toggleMemberForVoting member.get '_id'

  toggleAll: ->
    if @areAllSelected()
      MembersActions.selectMembersForVoting Immutable.List()
    else
      MembersActions.selectMembersForVoting @props.members.keySeq()

  renderCheckboxWithLabel: (text, checked, onChange) ->
    <label className='checkbox-with-text'>
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span>{text}</span>
    </label>

  isMemberSelected: (member) ->
    @props.selectedMemberIds?.includes member.get('_id')

  areAllSelected: ->
    @props.members.keySeq().isSubset (@props.selectedMemberIds ? Immutable.List())

  render: ->
    <div className='members-list'>
      <div>{@renderCheckboxWithLabel @i18n('select_all'), @areAllSelected(), @toggleAll}</div>
      <hr />
      {if @props.members.size
        @props.members.map (member) =>
          <div key={member.get '_id'} className='member-item'>
            {@renderCheckboxWithLabel(@getMemberText(member), @isMemberSelected(member),
              @memberChanged(member))}
          </div>
        .toList()
      else
        @redirectToMembersInfo() if @props.showRedirectToMembersInfo
      }
    </div>

Voting = React.createClass
  displayName: 'Voting'
  mixins: [
    PureRenderMixin
    CreateStoreMixin(MembersStore, VotingStore)
    Translation('administration:voting')
    FetchingSpinner
  ]

  getInitialState: ->
    activeTab: null
    mailMessage: null
    messageType: null
    showNoLinkError: false

  componentDidMount: ->
    @checkConnection()

  getStateFromStores: ->
    members: MembersStore.getMembers()
    selectedMemberIds: MembersStore.getSelectedMemberIdsForVoting()
    invitationMailMessage: VotingStore.getMailMessage 'invitationMailMessage'
    notRepliedMailMessage: VotingStore.getMailMessage 'notRepliedMailMessage'
    votingStatuses: VotingStore.getVotingStatuses()
    isSendingMail: VotingStore.isSendingMail()
    isRefreshingVotingStatuses: VotingStore.isRefreshingVotingStatuses()
    isFetchingVotingResults: VotingStore.isFetchingVotingResults()
    votingResults: VotingStore.getVotingResults()
    isFetching: VotingStore.isFetching() or MembersStore.isFetching()

  componentWillUpdate: (nextProps, nextState) ->
    if @state.isFetchingVotingResults and not nextState.isFetchingVotingResults
      console.log nextState.votingResults.toJS()
      @downloadExcel nextState.votingResults

  downloadExcel: (votingResults) ->
    votingExport.downloadExcel votingResults

  handleMessageUpdate: (messageType) -> (e) =>
    value = e.currentTarget.innerHTML
    @_debouncedHandleMessageUpdate ?= _.debounce(VotingActions.updateMailMessage, 500)
    @_debouncedHandleMessageUpdate messageType, value

  _filterMembersByStatus: (status) ->
    @state.members.filter (member) =>
      if status
        @state.votingStatuses.get(votingHelpers.getVotingDocId member) is status
      else
        true

  getInvitationDefaultMessage: ->
    @i18n 'invitation_default_message',
      project_name: mediator.project.get('name')

  getNotRepliedDefaultMessage: ->
    @i18n 'not_replied_default_message',
      project_name: mediator.project.get('name')

  validateMessage: ->
    _.string.contains @getMailMessage(), '#link#'

  checkConnection: ->
    if mediator.services.replication.isConnected()
      true
    else
      @setState showNoConnectionModal: true
      false

  sendMessage: ->
    if @validateMessage()
      if @checkConnection()
        VotingActions.sendMailMessage @state.messageType, @getMailMessage(),
          @getFilteredMembers().filter (member) =>
            @state.selectedMemberIds.includes member.get('_id')
    else
      @setState showNoLinkError: true

  refreshStatuses: ->
    if @checkConnection()
      VotingActions.refreshVotingStatuses @state.members

  exportVoting: ->
    VotingActions.fetchVotingResults @getFilteredMembers()

  getMailMessage: ->
    switch @state.messageType
      when 'invitationMailMessage'
        @state.invitationMailMessage ? @getInvitationDefaultMessage()
      when 'notRepliedMailMessage'
        @state.notRepliedMailMessage ? @getNotRepliedDefaultMessage()
      else
        null

  tabsMapping:
    0: 'allMembers'
    1: 'unsent'
    2: 'new'
    3: 'completed'

  tabChanged: (obj) ->
    activeTabName = @tabsMapping[obj.to]
    @setState switch activeTabName
      when 'unsent'
        messageType: 'invitationMailMessage'
      when 'new'
        messageType: 'notRepliedMailMessage'
      else
        messageType: null
    @setState {activeTabName}

  getMessagePanel: ->
    if @state.messageType
      <div className='message-panel'>
        <div className='message-title'>{@i18n 'message'}</div>
        <div className='message-container'>
          <ContentEditable
            className=''
            html={@getMailMessage()}
            withEditor={true}
            onInput={@handleMessageUpdate @state.messageType}
          />
        </div>
      </div>

  getFilteredMembers: ->
    if @state.activeTabName is 'allMembers'
      @state.members
    else
      @_filterMembersByStatus @state.activeTabName

  renderFetched: ->
    filteredMembers = @getFilteredMembers()
    <div>
      <div className='upper-block'>
        <span className='info'>{@i18n 'info'}</span>
        <div className='send-refresh-buttons'>
          {if @state.messageType
            <SpinnerButton
              className='btn'
              label={@i18n 'buttons.send'}
              loading={@state.isSendingMail}
              onClick={@sendMessage}/>
          }
          {if @state.activeTabName is 'completed'
              <SpinnerButton
                className='btn'
                label={@i18n 'buttons.export_voting'}
                loading={@state.isFetchingVotingResults}
                onClick={@exportVoting}/>
          }
          <SpinnerButton
            className='btn'
            label={@i18n 'buttons.refresh'}
            loading={@state.isRefreshingVotingStatuses}
            onClick={@refreshStatuses}/>
        </div>
      </div>
      <Tabs.List tabChanged={@tabChanged}>
        <Tabs.Tab caption={@i18n 'tabs.all'}>
          <PanelCheckboxes
            members={@state.members}
            selectedMemberIds={@state.selectedMemberIds}
            showRedirectToMembersInfo={@state.members.size is 0}/>
        </Tabs.Tab>
        <Tabs.Tab caption={@i18n 'tabs.unsent'}>
          <PanelCheckboxes
            members={filteredMembers}
            selectedMemberIds={@state.selectedMemberIds}/>
        </Tabs.Tab>
        <Tabs.Tab caption={@i18n 'tabs.not_replied'}>
          <PanelCheckboxes
            members={filteredMembers}
            selectedMemberIds={@state.selectedMemberIds}/>
        </Tabs.Tab>
        <Tabs.Tab caption={@i18n 'tabs.completed_voting'}>
          <PanelCheckboxes
            members={filteredMembers}
            selectedMemberIds={@state.selectedMemberIds}/>
        </Tabs.Tab>
      </Tabs.List>
      {@getMessagePanel()}
      <Modal
          className='voting-modal'
          isOpen={@state.showNoLinkError}
          closeButton={false} >
          <div className='sending-modal-body'>
            <div className='error-message'>{@i18n 'no_link_in_message'}</div>
            <Button
              className='btn'
              label={@i18n '/actions.ok'}
              onClick={=> @setState showNoLinkError: false}/>
          </div>
      </Modal>
      <Modal
          className='voting-modal'
          isOpen={@state.showNoConnectionModal}
          closeButton={false} >
          <div>
            <div className='error-message'>{@i18n 'no_connection_message'}</div>
            <Button
              className='btn'
              label={@i18n '/actions.ok'}
              onClick={=> @setState showNoConnectionModal: false}/>
          </div>
      </Modal>
    </div>

module.exports = Voting
