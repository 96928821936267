var Exceptions, ProjectChangelogService, W, mediator,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

Exceptions = require('lib/exceptions');

mediator = require('mediator');

W = require('when');

module.exports = ProjectChangelogService = (function() {
  function ProjectChangelogService() {
    this._recordChange = __bind(this._recordChange, this);
    mediator.subscribe('dbWrite', this._recordChange);
    this._timestampCache = {};
  }

  ProjectChangelogService.prototype.destroy = function() {
    return mediator.unsubscribe('dbWrite', this._recordChange);
  };

  ProjectChangelogService.prototype._changeWithinAccuracy = function(project, timestamp) {
    var previousTimestamp, _ref;
    previousTimestamp = (_ref = this._timestampCache[project]) != null ? _ref : 0;
    return timestamp - previousTimestamp > this.timestampAccuracy;
  };

  ProjectChangelogService.prototype._recordChange = function(dbName, eventName) {
    var project, timestamp;
    if (!this.projectDbService.isProjectDb(dbName)) {
      return W.resolve();
    }
    timestamp = this.clock.getTimestamp();
    if (!this._changeWithinAccuracy(dbName, timestamp)) {
      return W.resolve();
    }
    project = mediator.projects.get(dbName);
    if (!project) {
      return W.resolve();
    }
    return project.getDocFor('latestChange').then((function(_this) {
      return function(doc) {
        _this._timestampCache[dbName] = timestamp;
        return doc.save('timestamp', timestamp);
      };
    })(this)).otherwise(function(e) {
      if (e.status !== 409) {
        throw e;
      }
    });
  };

  ProjectChangelogService.prototype.getChangelog = function() {
    return W.map(mediator.projects.models, function(project) {
      var projectDetails;
      projectDetails = {
        id: project.id,
        name: project.get('name')
      };
      return project.getDocFor('latestChange', false).then(function(changeDoc) {
        var timestamp;
        timestamp = changeDoc.get('timestamp');
        return _(projectDetails).extend({
          timestamp: timestamp,
          changeTime: moment(timestamp).format('MMM Do, YYYY')
        });
      }, function(e) {
        if (e instanceof Exceptions.document_missing) {
          return _(projectDetails).extend({
            changeTime: ''
          });
        } else {
          throw e;
        }
      });
    }).then(function(changelog) {
      var firstFive, _ref;
      firstFive = _(changelog).chain().sortBy(function(v) {
        var _ref;
        return -((_ref = v.timestamp) != null ? _ref : 0);
      }).take(5).value();
      if (((_ref = firstFive[0]) != null ? _ref.timestamp : void 0) != null) {
        return _(firstFive).filter(function(v) {
          return v.timestamp != null;
        });
      } else {
        return firstFive;
      }
    });
  };

  return ProjectChangelogService;

})();
