var EtdRendererDataModel, Migration, W, etdTemplateHelper,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

W = require('when');

etdTemplateHelper = require('stores/utils/etd_template_helper');

module.exports = EtdRendererDataModel = (function(_super) {
  __extends(EtdRendererDataModel, _super);

  EtdRendererDataModel.prototype.staticRecommendationAttributes = [
    {
      attr: 'headerBackgroundField',
      type: 'text',
      templPath: ['question', 'sections', 'background']
    }, {
      attr: 'headerMainOutcomes',
      qType: 'tx',
      type: 'text',
      templPath: ['question', 'sections', 'mainOutcomes']
    }, {
      attr: 'headerPatientsField',
      type: 'text',
      templPath: ['question', 'sections', 'population']
    }, {
      attr: 'headerSettingField',
      type: 'text',
      templPath: ['question', 'sections', 'setting']
    }, {
      attr: 'headerLinkedTreatmentField',
      qType: 'dx',
      templPath: ['question', 'sections', 'linkedTreatments']
    }, {
      attr: 'headerPurposeField',
      qType: 'dx',
      templPath: ['question', 'sections', 'purpose']
    }, {
      attr: 'headerAnticipatedOutcomes',
      qType: 'dx',
      templPath: ['question', 'sections', 'anticipatedOutcomes']
    }, {
      attr: 'priorityProblemJudgement',
      type: 'option',
      templPath: ['assessment', 'criteria', 'problem']
    }, {
      attr: 'desirableEffectsJudgement',
      type: 'option',
      templPath: ['assessment', 'criteria', 'desirableEffects']
    }, {
      attr: 'undesirableEffectsJudgement',
      type: 'option',
      templPath: ['assessment', 'criteria', 'undesirableEffects']
    }, {
      attr: 'testAccuracyEvidenceCertaintyJudgement',
      qType: 'tx',
      type: 'option',
      templPath: ['assessment', 'criteria', 'certaintyOfEvidence']
    }, {
      attr: 'testAccuracyEvidenceCertaintyJudgement',
      qType: 'dx',
      type: 'option',
      templPath: ['assessment', 'criteria', 'certaintyOfEvidenceOfTestAccuracy']
    }, {
      attr: 'outcomesValuesCertaintyJudgement',
      type: 'option',
      templPath: ['assessment', 'criteria', 'values']
    }, {
      attr: 'effectsBalanceJudgement',
      type: 'option',
      templPath: ['assessment', 'criteria', 'balanceOfEffects']
    }, {
      attr: 'resourcesRequirementsJudgement',
      type: 'option',
      templPath: ['assessment', 'criteria', 'resourcesRequired']
    }, {
      attr: 'resourceRequirementsEvidenceCertaintyJudgement',
      type: 'option',
      templPath: ['assessment', 'criteria', 'certaintyOfEvidenceOfRequiredResources']
    }, {
      attr: 'costEffectivenessJudgement',
      type: 'option',
      templPath: ['assessment', 'criteria', 'costEffectiveness']
    }, {
      attr: 'healthEquityJudgement',
      type: 'option',
      templPath: ['assessment', 'criteria', 'equity']
    }, {
      attr: 'isTestAcceptableJudgement',
      type: 'option',
      templPath: ['assessment', 'criteria', 'acceptability']
    }, {
      attr: 'isTestFeasibleJudgement',
      type: 'option',
      templPath: ['assessment', 'criteria', 'feasibility']
    }, {
      attr: 'problemPriorityJudgement',
      type: 'option',
      templPath: ['assessment', 'criteria', 'problem']
    }, {
      attr: 'diagnosticTestAccuracyJudgement',
      type: 'option',
      templPath: ['assessment', 'criteria', 'testAccuracy']
    }, {
      attr: 'evidenceTestsEffectsCertaintyJudgement',
      type: 'option',
      templPath: ['assessment', 'criteria', 'certaintyOfEvidenceOfTestEffects']
    }, {
      attr: 'evidenceManagementsEffectsCertaintyJudgement',
      type: 'option',
      templPath: ['assessment', 'criteria', 'certaintyOfEvidenceOfManagementEffects']
    }, {
      attr: 'decisionsLinkCertaintyJudgement',
      type: 'option',
      templPath: ['assessment', 'criteria', 'certaintyOfEvidenceOfTestResult']
    }, {
      attr: 'overallEffectsCertaintyJudgement',
      type: 'option',
      templPath: ['assessment', 'criteria', 'certaintyOfEffects']
    }, {
      attr: 'priorityProblemResearchResearchEvidence',
      type: 'text',
      templPath: ['assessment', 'researchEvidences', 'problem']
    }, {
      attr: 'priorityProblemAdditionalConsiderations',
      type: 'text',
      templPath: ['assessment', 'additionalConsiderations', 'problem']
    }, {
      attr: 'benefitsHarmsResearchEvidence',
      type: 'text',
      templPath: ['assessment', 'researchEvidences', 'effects']
    }, {
      attr: 'desirableEffectsAdditionalConsiderations',
      type: 'text',
      templPath: ['assessment', 'additionalConsiderations', 'desirableEffects']
    }, {
      attr: 'undesirableEffectsAdditionalConsiderations',
      type: 'text',
      templPath: ['assessment', 'additionalConsiderations', 'undesirableEffects']
    }, {
      attr: 'effectsEvidenceAdditionalConsiderations',
      type: 'text',
      templPath: ['assessment', 'additionalConsiderations', 'certaintyOfEvidence']
    }, {
      attr: 'outcomesValuesCertaintyEvidence',
      type: 'text',
      templPath: ['assessment', 'researchEvidences', 'values']
    }, {
      attr: 'outcomesValuesCertaintyAdditionalConsiderations',
      type: 'text',
      templPath: ['assessment', 'additionalConsiderations', 'values']
    }, {
      attr: 'effectsBalanceEffectsEvidence',
      type: 'text',
      templPath: ['assessment', 'researchEvidences', 'balanceOfEffects']
    }, {
      attr: 'effectsBalanceEffectsAdditionalConsiderations',
      type: 'text',
      templPath: ['assessment', 'additionalConsiderations', 'balanceOfEffects']
    }, {
      attr: 'resourcesRequirementsEvidence',
      type: 'text',
      templPath: ['assessment', 'researchEvidences', 'resourcesRequired']
    }, {
      attr: 'resourcesRequirementsAdditionalConsiderations',
      type: 'text',
      templPath: ['assessment', 'additionalConsiderations', 'resourcesRequired']
    }, {
      attr: 'resourceRequirementsCertaintyEvidence',
      type: 'text',
      templPath: ['assessment', 'researchEvidences', 'certaintyOfEvidenceOfRequiredResources']
    }, {
      attr: 'resourceRequirementsEvidenceCertaintyAdditionalConsiderations',
      type: 'text',
      templPath: ['assessment', 'additionalConsiderations', 'certaintyOfEvidenceOfRequiredResources']
    }, {
      attr: 'costEffectivenessEvidence',
      type: 'text',
      templPath: ['assessment', 'researchEvidences', 'costEffectiveness']
    }, {
      attr: 'costEffectivenessAdditionalConsiderations',
      type: 'text',
      templPath: ['assessment', 'additionalConsiderations', 'costEffectiveness']
    }, {
      attr: 'healthEquityEvidence',
      type: 'text',
      templPath: ['assessment', 'researchEvidences', 'equity']
    }, {
      attr: 'healthEquityAdditionalConsiderations',
      type: 'text',
      templPath: ['assessment', 'additionalConsiderations', 'equity']
    }, {
      attr: 'isTestAcceptableEvidence',
      type: 'text',
      templPath: ['assessment', 'researchEvidences', 'acceptability']
    }, {
      attr: 'isTestAcceptableAdditionalConsiderations',
      type: 'text',
      templPath: ['assessment', 'additionalConsiderations', 'acceptability']
    }, {
      attr: 'isTestFeasibleEvidence',
      type: 'text',
      templPath: ['assessment', 'researchEvidences', 'feasibility']
    }, {
      attr: 'isTestFeasibleAdditionalConsiderations',
      type: 'text',
      templPath: ['assessment', 'additionalConsiderations', 'feasibility']
    }, {
      attr: 'problemResearchEvidence',
      type: 'text',
      templPath: ['assessment', 'researchEvidences', 'problem']
    }, {
      attr: 'problemAdditionalConsiderations',
      type: 'text',
      templPath: ['assessment', 'additionalConsiderations', 'problem']
    }, {
      attr: 'diagnosticTestAccuracyEvidence',
      type: 'text',
      templPath: ['assessment', 'researchEvidences', 'testAccuracy']
    }, {
      attr: 'diagnosticTestAccuracyAdditionalConsiderations',
      type: 'text',
      templPath: ['assessment', 'additionalConsiderations', 'testAccuracy']
    }, {
      attr: 'desirableEffectsEvidence',
      qType: 'dx',
      type: 'text',
      templPath: ['assessment', 'researchEvidences', 'effects']
    }, {
      attr: 'undesirableEffectsEvidence',
      qType: 'dx',
      type: 'text',
      templPath: ['assessment', 'researchEvidences', 'effects']
    }, {
      attr: 'accuracyEvidenceCertaintyEvidence',
      type: 'text',
      templPath: ['assessment', 'researchEvidences', 'certaintyOfEvidenceOfTestAccuracy']
    }, {
      attr: 'testAccuracyAdditionalConsiderations',
      type: 'text',
      templPath: ['assessment', 'additionalConsiderations', 'certaintyOfEvidenceOfTestAccuracy']
    }, {
      attr: 'evidenceTestsEffectsCertaintyEvidence',
      type: 'text',
      templPath: ['assessment', 'researchEvidences', 'certaintyOfEvidenceOfTestEffects']
    }, {
      attr: 'evidenceTestsEffectsCertaintyAdditionalConsiderations',
      type: 'text',
      templPath: ['assessment', 'additionalConsiderations', 'certaintyOfEvidenceOfTestEffects']
    }, {
      attr: 'evidenceManagementsEffectsCertaintyEvidence',
      type: 'text',
      templPath: ['assessment', 'researchEvidences', 'certaintyOfEvidenceOfManagementEffects']
    }, {
      attr: 'evidenceManagementsEffectsCertaintyAdditionalConsiderations',
      type: 'text',
      templPath: ['assessment', 'additionalConsiderations', 'certaintyOfEvidenceOfManagementEffects']
    }, {
      attr: 'decisionsLinkCertaintyEvidence',
      type: 'text',
      templPath: ['assessment', 'researchEvidences', 'certaintyOfEvidenceOfTestResult']
    }, {
      attr: 'decisionsLinkCertaintyAdditionalConsiderations',
      type: 'text',
      templPath: ['assessment', 'additionalConsiderations', 'certaintyOfEvidenceOfTestResult']
    }, {
      attr: 'overallEffectsCertaintyEvidence',
      type: 'text',
      templPath: ['assessment', 'researchEvidences', 'certaintyOfEffects']
    }, {
      attr: 'overallEffectsCertaintyAdditionalConsiderations',
      type: 'text',
      templPath: ['assessment', 'additionalConsiderations', 'certaintyOfEffects']
    }, {
      attr: 'consequencesBalance',
      type: 'option',
      templPath: ['conclusions', 'sections', 'recommendationTypeIntervention']
    }, {
      attr: 'recommendationText',
      type: 'text',
      templPath: ['conclusions', 'sections', 'recommendation']
    }, {
      attr: 'justificationText',
      type: 'text',
      templPath: ['conclusions', 'sections', 'justification']
    }, {
      attr: 'subgroupConsiderationsText',
      type: 'text',
      templPath: ['conclusions', 'sections', 'subgroupConsiderations']
    }, {
      attr: 'implementationConsiderations',
      type: 'text',
      templPath: ['conclusions', 'sections', 'implementationConsiderations']
    }, {
      attr: 'monitoringAndEvaluation',
      type: 'text',
      templPath: ['conclusions', 'sections', 'monitoringAndEvaluation']
    }, {
      attr: 'researchPriorities',
      type: 'text',
      templPath: ['conclusions', 'sections', 'researchPriorities']
    }
  ];

  function EtdRendererDataModel() {
    EtdRendererDataModel.__super__.constructor.call(this, false);
  }

  EtdRendererDataModel.prototype.up = function(project, colls) {
    var db, etdAttrNames, getBaseTemplateForQuestion, getQuestionType, isAtLeastOneAttrFilled, isAttributeForQuestionType, isTemplateDefined, migrateAttachments, migrateEtdData, replaceImageIds;
    EtdRendererDataModel.__super__.up.apply(this, arguments);
    db = PouchDB(project.id);
    isTemplateDefined = function(templateDocId) {
      return db.get(templateDocId).then(function(res) {
        return true;
      })["catch"](function(e) {
        if (e.status === 404) {
          return false;
        } else {
          throw e;
        }
      });
    };
    etdAttrNames = _(this.staticRecommendationAttributes).map(function(el) {
      return el.attr;
    });
    isAtLeastOneAttrFilled = function(question) {
      return _(etdAttrNames).some(function(attrName) {
        return question.get(attrName);
      });
    };
    getQuestionType = function(question) {
      if (question.get('type') === 'diagnostic') {
        return 'dx';
      } else {
        return 'tx';
      }
    };
    getBaseTemplateForQuestion = function(question, txTemplate, dxTemplate) {
      switch (getQuestionType(question)) {
        case 'tx':
          return txTemplate;
        case 'dx':
          return dxTemplate;
      }
    };
    isAttributeForQuestionType = function(question, recAttrObj) {
      var qType;
      if (!recAttrObj.qType) {
        return true;
      }
      qType = question.isDiagnostic() ? 'dx' : 'tx';
      return recAttrObj.qType === qType;
    };
    migrateAttachments = function(template, question, sectionName, oldAttrName) {
      var newPrefix, r;
      r = RegExp(oldAttrName, 'g');
      newPrefix = "" + sectionName + "#researchEvidences";
      template = template.withMutations(function(template) {
        var attachment, key, _ref, _results;
        _ref = question.get('_attachments');
        _results = [];
        for (key in _ref) {
          attachment = _ref[key];
          if (key.match(r)) {
            _results.push(template.setIn(['_attachments', key.replace(r, newPrefix).replace(/\s/g, '_')], attachment));
          }
        }
        return _results;
      });
      return template;
    };
    replaceImageIds = function(sectionName, oldAttrName, value) {
      var newSectionValue, r, replaceFn;
      r = RegExp('db-image="(' + oldAttrName + ')([^"]*)"');
      newSectionValue = "" + sectionName + "#researchEvidences";
      replaceFn = function(imageString, sectionPart, imageNamePart) {
        return imageString.replace(sectionPart, newSectionValue).replace(imageNamePart, imageNamePart.replace(/\s/g, '_')) + ' src=""';
      };
      return value.replace(r, replaceFn);
    };
    migrateEtdData = (function(_this) {
      return function(question, baseTemplate) {
        var template;
        if (!isAtLeastOneAttrFilled(question)) {
          return;
        }
        template = baseTemplate.withMutations(function(template) {
          var currentValue, recAttrObj, sectionName, templPath, templateData, value, _i, _len, _ref;
          _ref = _this.staticRecommendationAttributes;
          for (_i = 0, _len = _ref.length; _i < _len; _i++) {
            recAttrObj = _ref[_i];
            value = question.get(recAttrObj.attr);
            if (!(value && isAttributeForQuestionType(question, recAttrObj))) {
              continue;
            }
            if (value === 'trival') {
              value = 'trivial';
            }
            templPath = _.clone(recAttrObj.templPath);
            if (recAttrObj.type === 'option') {
              templPath.push('selectedOption');
            } else {
              templPath.push('content');
            }
            if (_(templPath).contains('researchEvidences')) {
              sectionName = templPath[2];
              value = replaceImageIds(sectionName, recAttrObj.attr, value);
              migrateAttachments(template, question, sectionName, recAttrObj.attr);
            }
            templateData = template.get('templateData');
            currentValue = templateData.getIn(templPath);
            if (currentValue) {
              value = currentValue + value;
            }
            template.set('templateData', templateData.setIn(templPath, value)).setIn(['templateData', 'question', 'docId'], question.id);
          }
          return template;
        });
        return db.post(template.toJS()).then(function(res) {
          return question.save('recommendationIds', [res.id]);
        }).then(function(question) {
          return getQuestionType(question);
        });
      };
    })(this);
    return W.all([isTemplateDefined('etd-tx-template'), isTemplateDefined('etd-dx-template')]).then(function(res) {
      var dxTemplate, dxTemplateDefined, question, txTemplate, txTemplateDefined;
      txTemplateDefined = res[0], dxTemplateDefined = res[1];
      if (txTemplateDefined || dxTemplateDefined) {
        return;
      }
      txTemplate = etdTemplateHelper.prepareBaseTemplate('tx-crPp');
      dxTemplate = etdTemplateHelper.prepareBaseTemplate('dx-testCrPp');
      return W.all((function() {
        var _i, _len, _ref, _results;
        _ref = colls.questions.models;
        _results = [];
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          question = _ref[_i];
          _results.push(migrateEtdData(question, getBaseTemplateForQuestion(question, txTemplate, dxTemplate)));
        }
        return _results;
      })()).then(function(questionTypes) {
        var templateToSave, templatesToSave, uniqTypes;
        uniqTypes = _(questionTypes).uniq();
        templatesToSave = [];
        if (_(uniqTypes).contains('tx')) {
          templatesToSave.push(txTemplate.set('_id', 'etd-tx-template').toJS());
        }
        if (_(uniqTypes).contains('dx')) {
          templatesToSave.push(dxTemplate.set('_id', 'etd-dx-template').toJS());
        }
        if (templatesToSave.length) {
          return W.all((function() {
            var _i, _len, _results;
            _results = [];
            for (_i = 0, _len = templatesToSave.length; _i < _len; _i++) {
              templateToSave = templatesToSave[_i];
              _results.push(db.put(templateToSave));
            }
            return _results;
          })());
        } else {
          return true;
        }
      });
    });
  };

  return EtdRendererDataModel;

})(Migration);
