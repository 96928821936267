var ActiveProjects, FullProjects, OrderedCollection, Project, Set, User, W, mediator, sequence,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

OrderedCollection = require('models/base/ordered_collection');

Project = require('models/project');

FullProjects = require('models/full_projects');

User = require('models/user');

Set = require('base/lib/set');

sequence = require('when/sequence');

mediator = require('mediator');

W = require('when');

module.exports = ActiveProjects = (function(_super) {
  __extends(ActiveProjects, _super);

  function ActiveProjects() {
    return ActiveProjects.__super__.constructor.apply(this, arguments);
  }

  ActiveProjects.prototype.model = Project;

  ActiveProjects.prototype._redirects = {};

  ActiveProjects.prototype.setOrderOnAdd = false;

  ActiveProjects.prototype.fetch = function(options) {
    var projectDBs;
    projectDBs = mediator.user.getProjects();
    return this._getProjectsData(projectDBs).then((function(_this) {
      return function(map) {
        _this.additionalProjectsData = map;
        return _this._fetchProjects(projectDBs);
      };
    })(this));
  };

  return ActiveProjects;

})(FullProjects);
