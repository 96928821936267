module.exports = {
  udb: {
    create: 'lib/services/udb_service',
    properties: {
      retryTimeout: 500,
      adapter: {
        $ref: 'persistenceAdapter'
      }
    }
  },
  projectDb: {
    create: 'lib/services/project_db_service'
  },
  authentication: {
    create: 'lib/services/authentication_service',
    properties: {
      udbService: {
        $ref: 'udb'
      },
      emailService: {
        $ref: 'email'
      },
      tosService: {
        $ref: 'tosCheck'
      }
    }
  },
  replication: {
    create: 'lib/services/replication_service',
    properties: {
      udbService: {
        $ref: 'udb'
      },
      authentication: {
        $ref: 'authentication'
      },
      clock: {
        $ref: 'clock'
      },
      window: $(window),
      retryTimeout: 1000,
      bgSyncPeriod: 120000,
      udbSyncPeriod: 10000,
      connectionTimeout: 40000
    },
    destroy: 'destroy'
  },
  projectSharing: {
    create: 'lib/services/project_sharing_service'
  },
  publication: {
    create: 'lib/services/publication_service'
  },
  switches: {
    create: 'lib/services/switches_service'
  },
  projectMigration: {
    wire: 'lib/project_migrations/context'
  },
  walkthrough: {
    create: 'lib/services/walkthrough_service'
  },
  mobilePublication: {
    create: 'lib/services/mobile_publication_service'
  },
  pdfExport: {
    module: 'lib/services/pdf_export_service'
  },
  email: {
    module: 'lib/services/email_service'
  },
  persistenceAdapter: {
    module: 'lib/pouch_adapter'
  },
  storePersistenceAdapter: {
    module: 'actions/utils/pouch_adapter'
  },
  presentationService: {
    module: 'lib/services/presentation_service'
  },
  cache: {
    create: 'lib/services/cache_service'
  },
  tosCheck: {
    create: 'lib/services/tos_check_service'
  },
  modules: {
    module: 'lib/services/module_service'
  },
  startup: {
    create: 'lib/services/startup_service',
    properties: {
      tosService: {
        $ref: 'tosCheck'
      },
      walkthroughService: {
        $ref: 'walkthrough'
      },
      switchesService: {
        $ref: 'switches'
      }
    },
    destroy: 'destroy'
  },
  esView: {
    module: 'lib/services/es_view_service'
  },
  clock: {
    module: 'lib/services/clock_service',
    init: 'initialize'
  },
  projectChangelog: {
    create: 'lib/services/project_changelog_service',
    properties: {
      projectDbService: {
        $ref: 'projectDb'
      },
      clock: {
        $ref: 'clock'
      },
      timestampAccuracy: 5 * 60 * 1000
    },
    destroy: 'destroy'
  },
  jsonldDiagnosticExport: {
    create: 'lib/services/jsonld_exporter/jsonld_export_diagnostic_question_service'
  },
  jsonldManagementExport: {
    create: 'lib/services/jsonld_exporter/jsonld_export_management_question_service'
  },
  externalAccounts: {
    module: 'lib/services/external_accounts_service'
  }
};
