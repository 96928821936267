var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"recommendations-header\"></div>\n\n<table class=\"standard-table judgement-table\" width=\"100%\">\n  <colgroup>\n    <col class=\"blank\" span=\"1\">\n    <col class=\"criteria\" span=\"1\">\n    <col class=\"judgements\" span=\"1\">\n    <col class=\"research-evidences\" span=\"1\">\n    <col class=\"additional-considerations\" span=\"1\">\n  </colgroup>\n  <thead>\n    <tr>\n      <th colspan=\"5\">\n        <h1>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "assessment", {"name":"i18n","hash":{},"data":data})))
    + "</h1>\n      </th>\n    </tr>\n    <tr class=\"headers\">\n      <th class=\"blank\"></th>\n      <th class=\"criteria with-info\">\n        <span class=\"info-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "tooltips.criteria", {"name":"i18n","hash":{},"data":data})))
    + "\"></span>\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "criteria", {"name":"i18n","hash":{},"data":data})))
    + "\n      </th>\n      <th class=\"with-info\">\n        <span class=\"info-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "tooltips.judgements", {"name":"i18n","hash":{},"data":data})))
    + "\"></span>\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "judgements", {"name":"i18n","hash":{},"data":data})))
    + "\n      </th>\n      <th class=\"with-info\">\n        <span class=\"info-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "tooltips.research_evidence", {"name":"i18n","hash":{},"data":data})))
    + "\"></span>\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "research_evidence", {"name":"i18n","hash":{},"data":data})))
    + "\n      </th>\n      <th class=\"with-info\">\n        <span class=\"info-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "tooltips.additional_considerations", {"name":"i18n","hash":{},"data":data})))
    + "\"></span>\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "additional_considerations", {"name":"i18n","hash":{},"data":data})))
    + "\n      </th>\n    </tr>\n  </thead>\n  <tbody>\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "problem", {"name":"i18nNamespace","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "benefits_harms", {"name":"i18nNamespace","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "resource_use", {"name":"i18nNamespace","hash":{},"fn":this.program(22, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "equity", {"name":"i18nNamespace","hash":{},"fn":this.program(29, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "acceptability", {"name":"i18nNamespace","hash":{},"fn":this.program(33, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "feasibility", {"name":"i18nNamespace","hash":{},"fn":this.program(37, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n  </tbody>\n</table>\n";
},"2":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    <tr class=\"problem\">\n      <td class=\"rotated h130\"><span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "title", {"name":"i18n","hash":{},"data":data})))
    + "</span></td>\n      <td class=\"criteria-cell with-info\">\n        <span class=\"info-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "../tooltips.problem_priority", {"name":"i18n","hash":{},"data":data})))
    + "\"></span>\n        <strong>"
    + escapeExpression(((helpers.i18nWithBRs || (depth0 && depth0.i18nWithBRs) || helperMissing).call(depth0, "criteria", {"name":"i18nWithBRs","hash":{},"data":data})))
    + "</strong>\n      </td>\n      <td class=\"judgement radio-group hopscotch-target\">\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "../radio_values", {"name":"i18nNamespace","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </td>\n      <td class=\"recommendations-editor\">\n        "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "priorityProblemResearchResearchEvidence", "with_insert", {"name":"contentEditable","hash":{},"data":data})))
    + "\n      </td>\n      <td class=\"recommendations-editor\">\n        "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "priorityProblemAdditionalConsiderations", {"name":"contentEditable","hash":{},"data":data})))
    + "\n      </td>\n    </tr>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.forList || (depth0 && depth0.forList) || helperMissing).call(depth0, "no", "probably_no", "uncertain", "probably_yes", "yes", "varies", {"name":"forList","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"4":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          "
    + escapeExpression(((helpers.recommendationJudgementOption || (depth0 && depth0.recommendationJudgementOption) || helperMissing).call(depth0, (depth0 != null ? depth0.it : depth0), "priorityProblemJudgement", {"name":"recommendationJudgementOption","hash":{},"data":data})))
    + "\n";
},"6":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    <tr class=\"benefits-harms\">\n      <td class=\"rotated h710\" rowspan=\"5\"><span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "title", {"name":"i18n","hash":{},"data":data})))
    + "</span></td>\n      <td class=\"criteria-cell with-info\">\n        <span class=\"info-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "../tooltips.overall_certainty", {"name":"i18n","hash":{},"data":data})))
    + "\"></span>\n        <strong>"
    + escapeExpression(((helpers.i18nWithBRs || (depth0 && depth0.i18nWithBRs) || helperMissing).call(depth0, "criteria_1", {"name":"i18nWithBRs","hash":{},"data":data})))
    + "</strong>\n      </td>\n      <td class=\"judgement radio-group\">\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "../radio_values", {"name":"i18nNamespace","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "      </td>\n      <td rowspan=\"5\" class=\"benefits-harms-table\">\n        "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "benefitsHarmsResearchEvidence", "with_insert", {"name":"contentEditable","hash":{},"data":data})))
    + "\n      </td>\n      <td  class=\"recommendations-editor\" rowspan=\"5\">\n        "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "benefitsHarmsAdditionalConsiderations", {"name":"contentEditable","hash":{},"data":data})))
    + "\n      </td>\n    </tr>\n\n    <tr>\n      <td class=\"criteria-cell with-info\">\n        <span class=\"info-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "../tooltips.uncertainty_about_main_outcomes", {"name":"i18n","hash":{},"data":data})))
    + "\">\n        </span>\n        <strong>"
    + escapeExpression(((helpers.i18nWithBRs || (depth0 && depth0.i18nWithBRs) || helperMissing).call(depth0, "criteria_2", {"name":"i18nWithBRs","hash":{},"data":data})))
    + "</strong>\n      </td>\n      <td class=\"judgement radio-group\">\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "../radio_values", {"name":"i18nNamespace","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "      </td>\n    </tr>\n\n    <tr>\n      <td class=\"criteria-cell with-info\">\n        <span class=\"info-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "../tooltips.desirable_anticipated_effects_large", {"name":"i18n","hash":{},"data":data})))
    + "\">\n        </span>\n        <strong>"
    + escapeExpression(((helpers.i18nWithBRs || (depth0 && depth0.i18nWithBRs) || helperMissing).call(depth0, "criteria_3", {"name":"i18nWithBRs","hash":{},"data":data})))
    + "</strong>\n      </td>\n      <td class=\"judgement radio-group\">\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "../radio_values", {"name":"i18nNamespace","hash":{},"fn":this.program(13, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "      </td>\n    </tr>\n\n    <tr>\n      <td class=\"criteria-cell with-info\">\n       <span class=\"info-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "../tooltips.undesirable_anticipated_effects_small", {"name":"i18n","hash":{},"data":data})))
    + "\">\n        </span>\n        <strong>"
    + escapeExpression(((helpers.i18nWithBRs || (depth0 && depth0.i18nWithBRs) || helperMissing).call(depth0, "criteria_4", {"name":"i18nWithBRs","hash":{},"data":data})))
    + "</strong>\n      </td>\n      <td class=\"judgement radio-group\">\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "../radio_values", {"name":"i18nNamespace","hash":{},"fn":this.program(16, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "      </td>\n    </tr>\n\n    <tr>\n      <td class=\"criteria-cell with-info\">\n        <span class=\"info-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "../tooltips.desirable_to_undesirable_effects", {"name":"i18n","hash":{},"data":data})))
    + "\">\n        </span>\n        <strong>"
    + escapeExpression(((helpers.i18nWithBRs || (depth0 && depth0.i18nWithBRs) || helperMissing).call(depth0, "criteria_5", {"name":"i18nWithBRs","hash":{},"data":data})))
    + "</strong>\n      </td>\n      <td class=\"judgement radio-group\">\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "../radio_values", {"name":"i18nNamespace","hash":{},"fn":this.program(19, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </td>\n    </tr>\n";
},"7":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.forList || (depth0 && depth0.forList) || helperMissing).call(depth0, "no_included_studies", "very_low", "low", "moderate", "high", {"name":"forList","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            "
    + escapeExpression(((helpers.recommendationJudgementOption || (depth0 && depth0.recommendationJudgementOption) || helperMissing).call(depth0, (depth0 != null ? depth0.it : depth0), "overallCertaintyJudgement", {"name":"recommendationJudgementOption","hash":{},"data":data})))
    + "\n";
},"10":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.forList || (depth0 && depth0.forList) || helperMissing).call(depth0, "important_uncertainty", "possible_important", "probably_no_important", "no_important", "no_known", {"name":"forList","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"11":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            "
    + escapeExpression(((helpers.recommendationJudgementOption || (depth0 && depth0.recommendationJudgementOption) || helperMissing).call(depth0, (depth0 != null ? depth0.it : depth0), "uncertaintyAboutMainOutcomesJudgement", {"name":"recommendationJudgementOption","hash":{},"data":data})))
    + "\n";
},"13":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.forList || (depth0 && depth0.forList) || helperMissing).call(depth0, "no", "probably_no", "uncertain", "probably_yes", "yes", "varies", {"name":"forList","hash":{},"fn":this.program(14, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"14":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            "
    + escapeExpression(((helpers.recommendationJudgementOption || (depth0 && depth0.recommendationJudgementOption) || helperMissing).call(depth0, (depth0 != null ? depth0.it : depth0), "desirableAnticipatedEffectsLargeJudgement", {"name":"recommendationJudgementOption","hash":{},"data":data})))
    + "\n";
},"16":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.forList || (depth0 && depth0.forList) || helperMissing).call(depth0, "no", "probably_no", "uncertain", "probably_yes", "yes", "varies", {"name":"forList","hash":{},"fn":this.program(17, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"17":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            "
    + escapeExpression(((helpers.recommendationJudgementOption || (depth0 && depth0.recommendationJudgementOption) || helperMissing).call(depth0, (depth0 != null ? depth0.it : depth0), "undesirableAnticipatedEffectsSmallJudgement", {"name":"recommendationJudgementOption","hash":{},"data":data})))
    + "\n";
},"19":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.forList || (depth0 && depth0.forList) || helperMissing).call(depth0, "no", "probably_no", "uncertain", "probably_yes", "yes", "varies", {"name":"forList","hash":{},"fn":this.program(20, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"20":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            "
    + escapeExpression(((helpers.recommendationJudgementOption || (depth0 && depth0.recommendationJudgementOption) || helperMissing).call(depth0, (depth0 != null ? depth0.it : depth0), "desirableToUndesirableEffectsJudgement", {"name":"recommendationJudgementOption","hash":{},"data":data})))
    + "\n";
},"22":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    <tr class=\"resource-use\">\n      <td class=\"rotated h270\" rowspan=\"2\"><span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "title", {"name":"i18n","hash":{},"data":data})))
    + "</span></td>\n      <td class=\"criteria-cell with-info\">\n        <span class=\"info-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "../tooltips.resources_required_small", {"name":"i18n","hash":{},"data":data})))
    + "\">\n        </span>\n        <strong>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "criteria_1", {"name":"i18n","hash":{},"data":data})))
    + "</strong>\n      </td>\n      <td class=\"judgement radio-group\">\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "../radio_values", {"name":"i18nNamespace","hash":{},"fn":this.program(23, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "      </td>\n      <td class=\"recommendations-editor\">\n        "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "resourcesRequiredSmallResearchEvidence", "with_insert", {"name":"contentEditable","hash":{},"data":data})))
    + "\n      </td>\n      <td class=\"recommendations-editor\">\n        "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "resourcesRequiredSmallAdditionalConsiderations", {"name":"contentEditable","hash":{},"data":data})))
    + "\n      </td>\n    </tr>\n\n    <tr>\n      <td class=\"criteria-cell with-info\">\n        <span class=\"info-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "../tooltips.incremental_cost_to_net_benefits", {"name":"i18n","hash":{},"data":data})))
    + "\">\n        </span>\n        <strong>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "criteria_2", {"name":"i18n","hash":{},"data":data})))
    + "</strong>\n      </td>\n      <td class=\"judgement radio-group\">\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "../radio_values", {"name":"i18nNamespace","hash":{},"fn":this.program(26, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </td>\n      <td class=\"recommendations-editor\">\n        "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "incrementalCostToNetBenefitsResearchEvidence", "with_insert", {"name":"contentEditable","hash":{},"data":data})))
    + "\n      </td>\n      <td class=\"recommendations-editor\">\n        "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "incrementalCostToNetBenefitsAdditionalConsiderations", {"name":"contentEditable","hash":{},"data":data})))
    + "\n      </td>\n    </tr>\n";
},"23":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.forList || (depth0 && depth0.forList) || helperMissing).call(depth0, "no", "probably_no", "uncertain", "probably_yes", "yes", "varies", {"name":"forList","hash":{},"fn":this.program(24, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"24":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            "
    + escapeExpression(((helpers.recommendationJudgementOption || (depth0 && depth0.recommendationJudgementOption) || helperMissing).call(depth0, (depth0 != null ? depth0.it : depth0), "resourcesRequiredSmallJudgement", {"name":"recommendationJudgementOption","hash":{},"data":data})))
    + "\n";
},"26":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.forList || (depth0 && depth0.forList) || helperMissing).call(depth0, "no", "probably_no", "uncertain", "probably_yes", "yes", "varies", {"name":"forList","hash":{},"fn":this.program(27, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"27":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            "
    + escapeExpression(((helpers.recommendationJudgementOption || (depth0 && depth0.recommendationJudgementOption) || helperMissing).call(depth0, (depth0 != null ? depth0.it : depth0), "incrementalCostToNetBenefitsJudgement", {"name":"recommendationJudgementOption","hash":{},"data":data})))
    + "\n";
},"29":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    <tr class=\"equity\">\n      <td class=\"rotated h130\"><span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "title", {"name":"i18n","hash":{},"data":data})))
    + "</span></td>\n      <td class=\"criteria-cell with-info\">\n        <span class=\"info-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "../tooltips.equity", {"name":"i18n","hash":{},"data":data})))
    + "\">\n        </span>\n        <strong>"
    + escapeExpression(((helpers.i18nWithBRs || (depth0 && depth0.i18nWithBRs) || helperMissing).call(depth0, "criteria", {"name":"i18nWithBRs","hash":{},"data":data})))
    + "</strong>\n      </td>\n      <td class=\"judgement radio-group\">\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "../radio_values", {"name":"i18nNamespace","hash":{},"fn":this.program(30, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </td>\n      <td class=\"recommendations-editor\">\n        "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "equityResearchEvidence", "with_insert", {"name":"contentEditable","hash":{},"data":data})))
    + "\n      </td>\n      <td class=\"recommendations-editor\">\n        "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "equityAdditionalConsiderations", {"name":"contentEditable","hash":{},"data":data})))
    + "\n      </td>\n    </tr>\n";
},"30":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.forList || (depth0 && depth0.forList) || helperMissing).call(depth0, "increased", "probably_increased", "uncertain", "probably_reduced", "reduced", "varies", {"name":"forList","hash":{},"fn":this.program(31, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"31":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            "
    + escapeExpression(((helpers.recommendationJudgementOption || (depth0 && depth0.recommendationJudgementOption) || helperMissing).call(depth0, (depth0 != null ? depth0.it : depth0), "equityJudgement", {"name":"recommendationJudgementOption","hash":{},"data":data})))
    + "\n";
},"33":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    <tr class=\"acceptability\">\n      <td class=\"rotated h130\"><span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "title", {"name":"i18n","hash":{},"data":data})))
    + "</span></td>\n      <td class=\"criteria-cell with-info\">\n        <span class=\"info-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "../tooltips.acceptability", {"name":"i18n","hash":{},"data":data})))
    + "\">\n        </span>\n        <strong>"
    + escapeExpression(((helpers.i18nWithBRs || (depth0 && depth0.i18nWithBRs) || helperMissing).call(depth0, "is_option_acceptable", {"name":"i18nWithBRs","hash":{},"data":data})))
    + "</strong>\n      </td>\n      <td class=\"judgement radio-group\">\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "../radio_values", {"name":"i18nNamespace","hash":{},"fn":this.program(34, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </td>\n      <td class=\"recommendations-editor\">\n        "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "acceptabilityResearchEvidence", "with_insert", {"name":"contentEditable","hash":{},"data":data})))
    + "\n      </td>\n      <td class=\"recommendations-editor\">\n        "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "acceptabilityAdditionalConsiderations", {"name":"contentEditable","hash":{},"data":data})))
    + "\n      </td>\n    </tr>\n";
},"34":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.forList || (depth0 && depth0.forList) || helperMissing).call(depth0, "no", "probably_no", "uncertain", "probably_yes", "yes", "varies", {"name":"forList","hash":{},"fn":this.program(35, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"35":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            "
    + escapeExpression(((helpers.recommendationJudgementOption || (depth0 && depth0.recommendationJudgementOption) || helperMissing).call(depth0, (depth0 != null ? depth0.it : depth0), "acceptabilityJudgement", {"name":"recommendationJudgementOption","hash":{},"data":data})))
    + "\n";
},"37":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    <tr class=\"feasibility\">\n      <td class=\"rotated h130\"><span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "title", {"name":"i18n","hash":{},"data":data})))
    + "</span></td>\n      <td class=\"criteria-cell with-info\">\n        <span class=\"info-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "../tooltips.feasibility", {"name":"i18n","hash":{},"data":data})))
    + "\">\n        </span>\n        <strong>"
    + escapeExpression(((helpers.i18nWithBRs || (depth0 && depth0.i18nWithBRs) || helperMissing).call(depth0, "is_option_feasible", {"name":"i18nWithBRs","hash":{},"data":data})))
    + "</strong>\n      </td>\n      <td class=\"judgement radio-group\">\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "../radio_values", {"name":"i18nNamespace","hash":{},"fn":this.program(38, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </td>\n      <td class=\"recommendations-editor\">\n        "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "feasibilityResearchEvidence", "with_insert", {"name":"contentEditable","hash":{},"data":data})))
    + "\n      </td>\n      <td class=\"recommendations-editor\">\n        "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "feasibilityAdditionalConsiderations", {"name":"contentEditable","hash":{},"data":data})))
    + "\n      </td>\n    </tr>\n";
},"38":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.forList || (depth0 && depth0.forList) || helperMissing).call(depth0, "no", "probably_no", "uncertain", "probably_yes", "yes", "varies", {"name":"forList","hash":{},"fn":this.program(39, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"39":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            "
    + escapeExpression(((helpers.recommendationJudgementOption || (depth0 && depth0.recommendationJudgementOption) || helperMissing).call(depth0, (depth0 != null ? depth0.it : depth0), "feasibilityJudgement", {"name":"recommendationJudgementOption","hash":{},"data":data})))
    + "\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:recommendations.table", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "<br>\n"
    + escapeExpression(((helpers.recommendationTable || (depth0 && depth0.recommendationTable) || helperMissing).call(depth0, depth0, {"name":"recommendationTable","hash":{},"data":data})))
    + "\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}