var DraftQuestion, Model, utils,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Model = require('base/models/model');

utils = require('base/lib/utils');

module.exports = DraftQuestion = (function(_super) {
  __extends(DraftQuestion, _super);

  function DraftQuestion() {
    return DraftQuestion.__super__.constructor.apply(this, arguments);
  }

  DraftQuestion.QUESTION_FORMATS = [
    {
      value: 'FOR_HP',
      label: $.t('es:question.for_hp_label')
    }, {
      value: 'IN_POP',
      label: $.t('es:question.in_pop_label')
    }
  ];

  DraftQuestion.prototype.defaults = {
    healthProblemOrPopulation: utils.bracketize($.t('es:question.healthProblemOrPopulation'))
  };

  DraftQuestion.prototype.managementDefaults = {
    intervention: utils.bracketize($.t('es:question.intervention')),
    comparison: utils.bracketize($.t('es:question.comparison')),
    questionFormat: DraftQuestion.QUESTION_FORMATS[0].value
  };

  DraftQuestion.prototype.diagnosticDefaults = {
    indexTest: utils.bracketize($.t('es:question.index_test')),
    comparatorTest: utils.bracketize($.t('es:question.comparator_test')),
    targetCondition: utils.bracketize($.t('es:question.target_condition')),
    comparatorTestPresent: false
  };

  DraftQuestion.prototype.isDiagnostic = function() {
    return this.get('type') === 'diagnostic';
  };

  DraftQuestion.prototype._updateDiagnosticQuestion = function() {
    if (!this.isDiagnostic()) {
      return;
    }
    if (this.get('indexTest') == null) {
      this.set('indexTest', this.get('intervention'));
    }
    if (this.get('comparatorTest') == null) {
      return this.set('comparatorTest', this.get('comparison'));
    }
  };

  DraftQuestion.prototype.initialize = function() {
    var additionalDefaults;
    if (!this.get('type')) {
      throw new Error('No question type specified');
    }
    this._updateDiagnosticQuestion();
    DraftQuestion.__super__.initialize.apply(this, arguments);
    additionalDefaults = (function() {
      switch (this.get('type')) {
        case 'management':
          return this.managementDefaults;
        case 'diagnostic':
          return this.diagnosticDefaults;
        default:
          throw new Error('Invalid question type');
      }
    }).call(this);
    _(this.attributes).defaults(additionalDefaults);
    this.titleAttributes = _.union(_(this.defaults).keys(), _(additionalDefaults).keys());
    this.on((_(this.titleAttributes).map(function(prop) {
      return "change:" + prop;
    })).join(' '), function() {
      return this.set(this._calculateTitles());
    });
    this.set(this._calculateTitles());
    return this._dirty = false;
  };

  DraftQuestion.prototype.parse = function(response) {
    response = DraftQuestion.__super__.parse.apply(this, arguments);
    return _(response).extend(this._calculateTitles(response));
  };

  DraftQuestion.prototype._calculateTitles = function(attributes) {
    var isVs, params, question, questionFormat, questionShort, templateKey, titles, _ref;
    if (attributes == null) {
      attributes = this.attributes;
    }
    params = _(attributes).pick(this.titleAttributes);
    questionFormat = attributes.questionFormat;
    _ref = attributes.type === 'diagnostic' ? (isVs = attributes.comparatorTestPresent, templateKey = isVs ? 'diagnostic_vs' : 'diagnostic', [$.t("es:question.templates." + templateKey, params), $.t("es:question.templates." + templateKey + "_short", params)]) : [$.t("es:question.templates." + questionFormat, params), $.t("es:question.templates." + questionFormat + "_short", params)], question = _ref[0], questionShort = _ref[1];
    titles = {
      question: question,
      questionShort: questionShort
    };
    return titles;
  };

  DraftQuestion.prototype.duplicate = function() {
    var attrs;
    attrs = this.parse(_(this.toJSON()).omit('id', 'orderNumber'));
    return new DraftQuestion(attrs);
  };

  return DraftQuestion;

})(Model);
