var W, mediator, templatesDefinitions, templatesSections;

templatesDefinitions = require('lib/etd_templates/templates_definitions');

templatesSections = require('lib/etd_templates/templates_sections');

mediator = require('mediator');

W = require('when/when');

_.namespace(module, function(require) {
  return {
    _getSection: function(templateData, blockName, sectionId) {
      var _ref;
      return (_ref = templateData.getIn([blockName, 'sections', sectionId])) != null ? _ref : Immutable.fromJS(templatesSections[blockName]['sections'][sectionId]);
    },
    _getAssessmentRef: function(templateData, refName, refId) {
      var _ref;
      return (_ref = templateData.getIn(['assessment', refName, refId])) != null ? _ref : Immutable.fromJS(templatesSections['assessment'][refName][refId]);
    },
    prepareBaseTemplate: function(templateId) {
      return this.prepareTemplate(Immutable.fromJS(_(templatesDefinitions).findWhere({
        id: templateId
      })), Immutable.fromJS({}), true);
    },
    prepareTemplate: function(templateDef, templateData, forCustomization, preserveCurrentSections) {
      var template;
      if (forCustomization == null) {
        forCustomization = false;
      }
      template = Immutable.Map({
        templateDef: templateDef,
        templateData: Immutable.Map()
      });
      return template.withMutations((function(_this) {
        return function(template) {
          _this._addSections(template, templateData, 'question', forCustomization, preserveCurrentSections);
          _this._addSections(template, templateData, 'assessment', forCustomization, preserveCurrentSections);
          _this._addSections(template, templateData, 'conclusions', forCustomization, preserveCurrentSections);
          return _this._addSections(template, templateData, 'presentations', forCustomization, preserveCurrentSections);
        };
      })(this));
    },
    updateTemplate: function(currentTemplate, newTemplate) {
      var template;
      template = this.prepareTemplate(newTemplate.get('templateDef'), currentTemplate.get('templateData'), false, true);
      return template.withMutations(function(template) {
        return template.set('_id', currentTemplate.get('_id')).set('_rev', currentTemplate.get('_rev')).setIn(['templateData', 'question', 'docId'], currentTemplate.getIn(['templateData', 'question', 'docId']));
      });
    },
    _addSections: function(template, templateData, blockName, forCustomization, preserveCurrentSections) {
      var sectionIds;
      sectionIds = template.getIn(['templateDef', blockName]).filter(function(sectionObj) {
        return forCustomization || (sectionObj.get('required') || sectionObj.get('checked'));
      }).map(function(sectionObj) {
        return sectionObj.get('id');
      });
      template = template.setIn(['templateData', blockName, 'sectionsOrder'], sectionIds);
      sectionIds = preserveCurrentSections ? templateData.getIn([blockName, 'sections']).keySeq().concat(sectionIds).toOrderedSet() : sectionIds;
      return template.withMutations((function(_this) {
        return function(template) {
          return sectionIds.forEach(function(sectionId) {
            return _this._addSection(template, templateData, blockName, sectionId);
          });
        };
      })(this));
    },
    _addSection: function(template, templateData, blockName, sectionId) {
      template = template.setIn(['templateData', blockName, 'sections', sectionId], this._getSection(templateData, blockName, sectionId));
      switch (blockName) {
        case 'assessment':
          return this._addAssessmentReferences(template, templateData, sectionId);
        case 'presentations':
          return this._addPresentationsSections(template, templateData, sectionId);
        default:
          return template;
      }
    },
    _addAssessmentReferences: function(template, templateData, sectionId) {
      return template.withMutations((function(_this) {
        return function(template) {
          _this._addAssessmentReference(template, templateData, sectionId, 'criteria', 'criterionId');
          _this._addAssessmentReference(template, templateData, sectionId, 'researchEvidences', 'researchEvidenceId');
          return _this._addAssessmentReference(template, templateData, sectionId, 'additionalConsiderations', 'additionalConsiderationId');
        };
      })(this));
    },
    _addAssessmentReference: function(template, templateData, sectionId, refName, refIdAttr) {
      var ref, refId, section;
      section = template.getIn(['templateData', 'assessment', 'sections', sectionId]);
      refId = section.get(refIdAttr);
      ref = template.getIn(['templateData', 'assessment', refName, refId]);
      if (ref) {
        return template;
      } else {
        return template.withMutations((function(_this) {
          return function(template) {
            return template.setIn(['templateData', 'assessment', refName, refId], _this._getAssessmentRef(templateData, refName, refId));
          };
        })(this));
      }
    },
    _addPresentationsSections: function(template, templateData, sectionId) {
      var sectionIds;
      sectionIds = template.getIn(['templateDef', 'presentations']).find(function(presentationGroup) {
        return presentationGroup.get('id') === sectionId;
      }).get('sections').filter(function(sectionObj) {
        return sectionObj.get('required') || sectionObj.get('checked');
      }).map(function(sectionObj) {
        return sectionObj.get('id');
      });
      return template.setIn(['templateData', 'presentations', 'sections', sectionId, 'sectionsOrder'], sectionIds);
    },
    updateTemplatesInQuestions: function(dbName, newTemplate, templateType) {
      var filteredQuestions, recIds;
      filteredQuestions = mediator.questions.filter(function(q) {
        if (templateType === 'dx') {
          return q.isDiagnostic();
        } else {
          return !q.isDiagnostic();
        }
      });
      recIds = _(filteredQuestions).chain().pluck('attributes').pluck('recommendationIds').flatten().uniq().value();
      return mediator.services.storePersistenceAdapter.fetch(dbName, recIds).then((function(_this) {
        return function(res) {
          var etdDoc, etdDocs, saveToDb, updatedDocs;
          etdDocs = _(res.rows).pluck('doc');
          updatedDocs = Immutable.List((function() {
            var _i, _len, _results;
            _results = [];
            for (_i = 0, _len = etdDocs.length; _i < _len; _i++) {
              etdDoc = etdDocs[_i];
              _results.push(this.updateTemplate(Immutable.fromJS(etdDoc), newTemplate));
            }
            return _results;
          }).call(_this));
          saveToDb = mediator.services.storePersistenceAdapter.save;
          return W.all((updatedDocs.map(function(doc) {
            return saveToDb(dbName, doc.toJS());
          })).toJS())["catch"](function(e) {
            return typeof Raven !== "undefined" && Raven !== null ? Raven.captureException(e) : void 0;
          });
        };
      })(this));
    }
  };
});
