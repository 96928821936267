var DiagnosticRecommendationsExporter, EtdV1DiagnosticRecommendationsExporter, EtdV2DiagnosticRecommendationsExporter;

EtdV1DiagnosticRecommendationsExporter = require('./etdv1_diagnostic_recommendations_exporter');

EtdV2DiagnosticRecommendationsExporter = require('./etdv2_diagnostic_recommendations_exporter');

module.exports = DiagnosticRecommendationsExporter = (function() {
  function DiagnosticRecommendationsExporter(_originalQuestion, etdVersion) {
    switch (etdVersion) {
      case "v1":
        _(this).extend(new EtdV1DiagnosticRecommendationsExporter(_originalQuestion));
        break;
      case "v2":
        _(this).extend(new EtdV2DiagnosticRecommendationsExporter(_originalQuestion));
        break;
      default:
        throw new Error("Unknown ETD version");
    }
  }

  return DiagnosticRecommendationsExporter;

})();
