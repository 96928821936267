var CacheService, W, mediator,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

mediator = require('mediator');

W = require('when');

module.exports = CacheService = (function() {
  function CacheService() {
    this._cacheDownloaded = __bind(this._cacheDownloaded, this);
    this._cacheDownloading = __bind(this._cacheDownloading, this);
    this._cacheNoUpdate = __bind(this._cacheNoUpdate, this);
    this._showLoadingInfo = __bind(this._showLoadingInfo, this);
  }

  CacheService.prototype.loadingInfo = null;

  CacheService.prototype.$progressMsg = null;

  CacheService.prototype._showLoadingInfo = function(msg) {
    var _ref;
    if ((_ref = this.loadingInfo) != null) {
      _ref.destroy();
    }
    return this.loadingInfo = mediator.dialogs.loading({
      message: msg
    });
  };

  CacheService.prototype._destroyLoadingInfo = function() {
    var _ref;
    return (_ref = this.loadingInfo) != null ? _ref.destroy() : void 0;
  };

  CacheService.prototype._addEvents = function(events, handler) {
    if (handler == null) {
      handler = null;
    }
    return $(window.applicationCache).on(events, handler);
  };

  CacheService.prototype._removeEvents = function(events) {
    return $(window.applicationCache).off(events);
  };

  CacheService.prototype._cacheChecking = function(deferred) {
    this._showLoadingInfo($.t('messages.cache_checking'));
    this._addEvents('noupdate obsolete error', (function(_this) {
      return function() {
        return _this._cacheNoUpdate(deferred);
      };
    })(this));
    return this._addEvents('downloading', (function(_this) {
      return function() {
        return _this._cacheDownloading(deferred);
      };
    })(this));
  };

  CacheService.prototype._cacheNoUpdate = function(deferred) {
    this._removeEvents('noupdate obsolete error');
    this._destroyLoadingInfo();
    return deferred.resolve('noupdate');
  };

  CacheService.prototype._cacheDownloading = function(deferred) {
    this._removeEvents('noupdate obsolete error');
    this._showLoadingInfo($.t('messages.cache_downloading', {
      percent: 0
    }));
    this._addEvents('progress', (function(_this) {
      return function(e) {
        return _this._monitorCacheProgress(e.originalEvent);
      };
    })(this));
    return this._addEvents('error cached updateready', (function(_this) {
      return function() {
        return _this._cacheDownloaded(deferred);
      };
    })(this));
  };

  CacheService.prototype._cacheDownloaded = function(deferred) {
    this._removeEvents('error cached updateready progress downloading');
    this._destroyLoadingInfo();
    return deferred.resolve('updateready');
  };

  CacheService.prototype._monitorCacheProgress = function(e) {
    var percent;
    if (this.$progressMsg == null) {
      this.$progressMsg = this.loadingInfo.$el.find('.notifier-message');
    }
    percent = parseInt(100 * (e.loaded / e.total));
    return this.$progressMsg.text($.t('messages.cache_downloading', {
      percent: percent
    }));
  };

  CacheService.prototype.getVersionTimestamp = function() {
    return window.gdt.storage.get('versionTimestamp').then(function(timestamp) {
      return Number(timestamp);
    });
  };

  CacheService.prototype.updateVersionTimestamp = function(timestamp) {
    return window.gdt.storage.set('versionTimestamp', timestamp);
  };

  CacheService.prototype.isUpToDate = function() {
    return this.getVersionTimestamp().then(function(timestamp) {
      return timestamp === window.gdt.versionTimestamp;
    });
  };

  CacheService.prototype.checkCache = function() {
    var appCache, deferred, e;
    deferred = W.defer();
    if (window.gdt.debugMode) {
      deferred.resolve('noupdate');
    } else {
      appCache = window.applicationCache;
      try {
        appCache.update();
        switch (appCache.status) {
          case appCache.CHECKING:
            this._cacheChecking(deferred);
            break;
          case appCache.DOWNLOADING:
            this._cacheDownloading(deferred);
            break;
          default:
            deferred.resolve('noupdate');
        }
      } catch (_error) {
        e = _error;
        if (e.name === 'InvalidStateError') {
          deferred.resolve('nocache');
        } else {
          throw e;
        }
      }
    }
    return deferred.promise.then((function(_this) {
      return function(result) {
        if (result === 'noupdate') {
          _this.isUpToDate().then(function(upToDate) {
            if (!upToDate) {
              window.gdt.updateInstalled = true;
              mediator.publish('updateInstalled');
              return _this.updateVersionTimestamp(window.gdt.versionTimestamp);
            }
          });
        }
        mediator.publish('cacheChecked');
        return result;
      };
    })(this));
  };

  return CacheService;

})();
