var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return " col-4 ";
  },"3":function(depth0,helpers,partials,data) {
  return " col-6 ";
  },"5":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <div class=\"col-4\">\n      <button\n        class=\"btn btn-block import-question\"\n        title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/import.questions_title", {"name":"i18n","hash":{},"data":data})))
    + "\"\n        disabled=true>\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/import.questions", {"name":"i18n","hash":{},"data":data})))
    + "\n      </button>\n    </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"row mt-10\">\n  <div class=\"";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.enableImport : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n    <button class=\"btn btn-block add-management-question\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/es:question.add_title", {"name":"i18n","hash":{},"data":data})))
    + "\n    </button>\n  </div>\n  <div class=\"";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.enableImport : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n    <button class=\"btn btn-block add-diagnostic-question\" disabled=true>\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/es:question.add_diagnostic_title", {"name":"i18n","hash":{},"data":data})))
    + "\n    </button>\n  </div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.enableImport : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}