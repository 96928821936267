var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "  <p><br></p>\n  <table class=\"standard-table dx-inner-table\">\n    <thead>\n      <tr>\n        <th rowspan=\"2\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "outcome", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th rowspan=\"2\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "study_design", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th rowspan=\"2\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "diagnostic.dta_qoe", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.prevalence1 : depth0), {"name":"if","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.prevalence2 : depth0), {"name":"if","hash":{},"fn":this.program(5, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.prevalence3 : depth0), {"name":"if","hash":{},"fn":this.program(7, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        <th rowspan=\"2\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "importance", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n      </tr>\n      <tr>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.prevalence1 : depth0), {"name":"if","hash":{},"fn":this.program(9, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.prevalence2 : depth0), {"name":"if","hash":{},"fn":this.program(9, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.prevalence3 : depth0), {"name":"if","hash":{},"fn":this.program(9, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "      </tr>\n    </thead>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.dxL1Outcomes : depth0), {"name":"each","hash":{},"fn":this.program(12, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </table>\n";
},"2":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "        <th ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.comparatorTestPresent : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n          ";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "diagnostic.effect_per_1000_patients_for_pre_test", {"name":"i18n","hash":{
    'prob': ((depth0 != null ? depth0.prevalence1 : depth0))
  },"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n        </th>\n";
},"3":function(depth0,helpers,partials,data) {
  return "colspan=\"2\"";
  },"5":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "        <th ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.comparatorTestPresent : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n          ";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "diagnostic.effect_per_1000_patients_for_pre_test", {"name":"i18n","hash":{
    'prob': ((depth0 != null ? depth0.prevalence2 : depth0))
  },"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n        </th>\n";
},"7":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "        <th ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.comparatorTestPresent : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n          ";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "diagnostic.effect_per_1000_patients_for_pre_test", {"name":"i18n","hash":{
    'prob': ((depth0 != null ? depth0.prevalence3 : depth0))
  },"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n        </th>\n";
},"9":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "          <th>"
    + escapeExpression(lambda((depths[1] != null ? depths[1].indexTest : depths[1]), depth0))
    + "</th>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.comparatorTestPresent : depth0), {"name":"if","hash":{},"fn":this.program(10, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"10":function(depth0,helpers,partials,data,depths) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "            <th>"
    + escapeExpression(lambda((depths[2] != null ? depths[2].comparatorTest : depths[2]), depth0))
    + "</th>\n";
},"12":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.inStringArray || (depth0 && depth0.inStringArray) || helperMissing).call(depth0, "TP, FN, TN, FP", (depth0 != null ? depth0.name : depth0), {"name":"inStringArray","hash":{},"fn":this.program(13, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"13":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "      <tr>\n        <td>"
    + escapeExpression(((helpers.i18nWithNamespace || (depth0 && depth0.i18nWithNamespace) || helperMissing).call(depth0, "es:outcome.diagnostic.", (depth0 != null ? depth0.name : depth0), {"name":"i18nWithNamespace","hash":{},"data":data})))
    + "</td>\n";
  stack1 = ((helpers.inStringArray || (depth0 && depth0.inStringArray) || helperMissing).call(depth0, "TP, TN", (depth0 != null ? depth0.name : depth0), {"name":"inStringArray","hash":{},"fn":this.program(14, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depths[2] != null ? depths[2].prevalence1 : depths[2]), {"name":"if","hash":{},"fn":this.program(19, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depths[2] != null ? depths[2].prevalence2 : depths[2]), {"name":"if","hash":{},"fn":this.program(22, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depths[2] != null ? depths[2].prevalence3 : depths[2]), {"name":"if","hash":{},"fn":this.program(25, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        <td>"
    + escapeExpression(((helper = (helper = helpers.importanceLabel || (depth0 != null ? depth0.importanceLabel : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"importanceLabel","hash":{},"data":data}) : helper)))
    + "</td>\n      </tr>\n";
  stack1 = helpers['if'].call(depth0, (depths[2] != null ? depths[2].comparatorTestPresent : depths[2]), {"name":"if","hash":{},"fn":this.program(28, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"14":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "          <td rowspan=\"";
  stack1 = helpers['if'].call(depth0, (depths[3] != null ? depths[3].comparatorTestPresent : depths[3]), {"name":"if","hash":{},"fn":this.program(15, data, depths),"inverse":this.program(17, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n            "
    + escapeExpression(((helpers.displayQualityModifier || (depth0 && depth0.displayQualityModifier) || helperMissing).call(depth0, (depth0 != null ? depth0.designStudies : depth0), {"name":"displayQualityModifier","hash":{},"data":data})))
    + "\n          </td>\n          <td rowspan=\"";
  stack1 = helpers['if'].call(depth0, (depths[3] != null ? depths[3].comparatorTestPresent : depths[3]), {"name":"if","hash":{},"fn":this.program(15, data, depths),"inverse":this.program(17, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n            "
    + escapeExpression(((helpers.displayQuality || (depth0 && depth0.displayQuality) || helperMissing).call(depth0, (depth0 != null ? depth0.quality : depth0), {"name":"displayQuality","hash":{},"data":data})))
    + "\n          </td>\n";
},"15":function(depth0,helpers,partials,data) {
  return "4";
  },"17":function(depth0,helpers,partials,data) {
  return "2";
  },"19":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "          <td>"
    + escapeExpression(((helpers.displayDiagnosticEffect || (depth0 && depth0.displayDiagnosticEffect) || helperMissing).call(depth0, depths[3], (depth0 != null ? depth0.name : depth0), 1, false, {"name":"displayDiagnosticEffect","hash":{},"data":data})))
    + "</td>\n";
  stack1 = helpers['if'].call(depth0, (depths[3] != null ? depths[3].comparatorTestPresent : depths[3]), {"name":"if","hash":{},"fn":this.program(20, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"20":function(depth0,helpers,partials,data,depths) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <td>"
    + escapeExpression(((helpers.displayDiagnosticEffect || (depth0 && depth0.displayDiagnosticEffect) || helperMissing).call(depth0, depths[4], (depth0 != null ? depth0.name : depth0), 1, true, {"name":"displayDiagnosticEffect","hash":{},"data":data})))
    + "</td>\n";
},"22":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "          <td>"
    + escapeExpression(((helpers.displayDiagnosticEffect || (depth0 && depth0.displayDiagnosticEffect) || helperMissing).call(depth0, depths[3], (depth0 != null ? depth0.name : depth0), 2, false, {"name":"displayDiagnosticEffect","hash":{},"data":data})))
    + "</td>\n";
  stack1 = helpers['if'].call(depth0, (depths[3] != null ? depths[3].comparatorTestPresent : depths[3]), {"name":"if","hash":{},"fn":this.program(23, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"23":function(depth0,helpers,partials,data,depths) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <td>"
    + escapeExpression(((helpers.displayDiagnosticEffect || (depth0 && depth0.displayDiagnosticEffect) || helperMissing).call(depth0, depths[4], (depth0 != null ? depth0.name : depth0), 2, true, {"name":"displayDiagnosticEffect","hash":{},"data":data})))
    + "</td>\n";
},"25":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "          <td>"
    + escapeExpression(((helpers.displayDiagnosticEffect || (depth0 && depth0.displayDiagnosticEffect) || helperMissing).call(depth0, depths[3], (depth0 != null ? depth0.name : depth0), 3, false, {"name":"displayDiagnosticEffect","hash":{},"data":data})))
    + "</td>\n";
  stack1 = helpers['if'].call(depth0, (depths[3] != null ? depths[3].comparatorTestPresent : depths[3]), {"name":"if","hash":{},"fn":this.program(26, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"26":function(depth0,helpers,partials,data,depths) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <td>"
    + escapeExpression(((helpers.displayDiagnosticEffect || (depth0 && depth0.displayDiagnosticEffect) || helperMissing).call(depth0, depths[4], (depth0 != null ? depth0.name : depth0), 3, true, {"name":"displayDiagnosticEffect","hash":{},"data":data})))
    + "</td>\n";
},"28":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = "      <tr class=\"abs-difference-row\">\n        <td><b>"
    + escapeExpression(lambda((depths[1] != null ? depths[1].name : depths[1]), depth0))
    + " "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.absolute_difference", {"name":"i18n","hash":{},"data":data})))
    + "</b></td>\n";
  stack1 = helpers['if'].call(depth0, (depths[3] != null ? depths[3].prevalence1 : depths[3]), {"name":"if","hash":{},"fn":this.program(29, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depths[3] != null ? depths[3].prevalence2 : depths[3]), {"name":"if","hash":{},"fn":this.program(31, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depths[3] != null ? depths[3].prevalence3 : depths[3]), {"name":"if","hash":{},"fn":this.program(33, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        <td></td>\n      </tr>\n";
},"29":function(depth0,helpers,partials,data,depths) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          <td colspan=\"2\">"
    + escapeExpression(((helpers.displayDiagnosticAbsoluteDifference || (depth0 && depth0.displayDiagnosticAbsoluteDifference) || helperMissing).call(depth0, depths[4], (depths[1] != null ? depths[1].name : depths[1]), 1, {"name":"displayDiagnosticAbsoluteDifference","hash":{},"data":data})))
    + "</td>\n";
},"31":function(depth0,helpers,partials,data,depths) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          <td colspan=\"2\">"
    + escapeExpression(((helpers.displayDiagnosticAbsoluteDifference || (depth0 && depth0.displayDiagnosticAbsoluteDifference) || helperMissing).call(depth0, depths[4], (depths[1] != null ? depths[1].name : depths[1]), 2, {"name":"displayDiagnosticAbsoluteDifference","hash":{},"data":data})))
    + "</td>\n";
},"33":function(depth0,helpers,partials,data,depths) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          <td colspan=\"2\">"
    + escapeExpression(((helpers.displayDiagnosticAbsoluteDifference || (depth0 && depth0.displayDiagnosticAbsoluteDifference) || helperMissing).call(depth0, depths[4], (depths[1] != null ? depths[1].name : depths[1]), 3, {"name":"displayDiagnosticAbsoluteDifference","hash":{},"data":data})))
    + "</td>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "/es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}