var AbstractQuestion, FootnotesList, Model, Outcomes,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

Model = require('models/base/model');

Outcomes = require('models/outcomes');

FootnotesList = require('lib/traits/footnotes_list');

module.exports = AbstractQuestion = (function(_super) {
  __extends(AbstractQuestion, _super);

  function AbstractQuestion() {
    return AbstractQuestion.__super__.constructor.apply(this, arguments);
  }

  AbstractQuestion.prototype.initialize = function() {
    AbstractQuestion.__super__.initialize.apply(this, arguments);
    if (this.constructor === AbstractQuestion) {
      throw new Error("Can't instantiate abstract class!");
    }
    return this.enable(FootnotesList);
  };

  AbstractQuestion.prototype.parse = function(response) {
    response = AbstractQuestion.__super__.parse.apply(this, arguments);
    if (response.questionFormat) {
      _(response).extend(this.calculateTitles(response));
    }
    return response;
  };

  AbstractQuestion.prototype.nestedCollections = {
    'outcomes': Outcomes
  };

  AbstractQuestion.prototype.areAllOutcomesNarrative = function() {
    var narrOutcomes, o;
    narrOutcomes = (function() {
      var _i, _len, _ref, _results;
      _ref = this.get('outcomes').models;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        o = _ref[_i];
        if (o.get('type') === 'narrative') {
          _results.push(o);
        }
      }
      return _results;
    }).call(this);
    return narrOutcomes.length === this.get('outcomes').models.length;
  };

  AbstractQuestion.prototype.isDiagnostic = function() {
    return this.get('type') === 'diagnostic';
  };

  AbstractQuestion.prototype.isPrognostic = function() {
    return this.get('type') === 'prognostic';
  };

  AbstractQuestion.prototype.getQuestionFootnotesIds = function(questionFootnotesAttrArray) {
    var attr, ids, _i, _len;
    if (questionFootnotesAttrArray) {
      ids = [];
      for (_i = 0, _len = questionFootnotesAttrArray.length; _i < _len; _i++) {
        attr = questionFootnotesAttrArray[_i];
        ids.push(this.get('footnotes')[attr]);
      }
      return _(ids).chain().flatten().uniq().value();
    } else {
      return _(this.get('footnotes')).chain().values().flatten().uniq().value();
    }
  };

  AbstractQuestion.prototype.getFootnotesArray = function(outcomeIdsArray, questionFootnotesAttrArray) {
    var footnotesList, outcome, outcomesFootnotes, questionFootnotes;
    if (questionFootnotesAttrArray == null) {
      questionFootnotesAttrArray = null;
    }
    footnotesList = this.get('footnotesList').getNotesList();
    questionFootnotes = this.getQuestionFootnotesIds(questionFootnotesAttrArray);
    if (outcomeIdsArray) {
      outcomesFootnotes = _((function() {
        var _i, _len, _ref, _ref1, _results;
        _ref = this.get('outcomes').models;
        _results = [];
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          outcome = _ref[_i];
          if (_ref1 = outcome.id, __indexOf.call(outcomeIdsArray, _ref1) >= 0) {
            _results.push(outcome.getFootnotesIds());
          }
        }
        return _results;
      }).call(this)).chain().flatten().uniq().value();
      footnotesList = _(footnotesList).filter(function(footnote) {
        var _ref;
        return _ref = footnote.id, __indexOf.call(outcomesFootnotes.concat(questionFootnotes), _ref) >= 0;
      });
      _(footnotesList).each(function(f, idx) {
        return f.idx = idx;
      });
    } else if (!this.specialFootnoteBounded) {
      footnotesList = _(footnotesList).filter(function(footnote) {
        return !footnote.special;
      });
      _(footnotesList).each(function(f) {
        return f.idx = f.idx - 1;
      });
    }
    return footnotesList;
  };

  return AbstractQuestion;

})(Model);
