var PopupMenuView, Shortcuts, View, mediator, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

mediator = require('mediator');

template = require('./templates/popup_menu');

Shortcuts = require('base/lib/traits/shortcuts');

module.exports = PopupMenuView = (function(_super) {
  __extends(PopupMenuView, _super);

  function PopupMenuView() {
    this.onFocusLost = __bind(this.onFocusLost, this);
    return PopupMenuView.__super__.constructor.apply(this, arguments);
  }

  PopupMenuView.prototype.className = 'popup-menu';

  PopupMenuView.prototype.template = template;

  PopupMenuView.prototype._validateMenuItems = function() {
    var item, _i, _len, _ref, _results;
    if (!_.isArray(this.menuItems)) {
      throw new Error('`menuItems` arg is not an array');
    }
    _ref = this.menuItems;
    _results = [];
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      item = _ref[_i];
      if (item.type === 'submenu') {
        if (!item.items) {
          throw new Error('No `items` specified for `submenu` item');
        }
        if (!item.subId) {
          throw new Error('No `subId` specified for `submenu` item');
        } else {
          _results.push(void 0);
        }
      } else {
        _results.push(void 0);
      }
    }
    return _results;
  };

  PopupMenuView.prototype.initialize = function() {
    PopupMenuView.__super__.initialize.apply(this, arguments);
    this.showDot = true;
    this.menuItems = this.options.menuItems;
    this._validateMenuItems();
    this.enable(Shortcuts);
    return this.delegate('click', function(e) {
      return e.stopPropagation();
    });
  };

  PopupMenuView.prototype.getTemplateData = function() {
    var _ref;
    return {
      menuItems: this.menuItems,
      showDot: this.showDot,
      addClass: (_ref = this.options.addClass) != null ? _ref : ''
    };
  };

  PopupMenuView.prototype._renderAndDisplay = function(offset) {
    this.render();
    $('body').append(this.$el);
    return this.$el.css({
      display: 'block',
      left: offset.left,
      top: offset.top
    });
  };

  PopupMenuView.prototype._callHandler = function(e) {
    var itemHandler, itemId, _ref;
    itemId = $(e.target).closest('.item').data('itemId');
    itemHandler = (_ref = _(this.menuItems).findWhere({
      id: itemId
    })) != null ? _ref.handler : void 0;
    return itemHandler != null ? itemHandler.apply() : void 0;
  };

  PopupMenuView.prototype._delegateEvents = function() {
    this.delegate('mouseenter', '.submenu', this.showSubmenu);
    this.delegate('mouseenter', '.item:not(.submenu)', this.hideSubmenu);
    this.delegate('change', 'input[type="checkbox"]', this._callHandler);
    this.delegate('click', '.link', this._callHandler);
    return this.delegate('click', '.text', this._callHandler);
  };

  PopupMenuView.prototype.show = function(offset, gainFocus) {
    var showFn;
    if (gainFocus == null) {
      gainFocus = true;
    }
    showFn = (function(_this) {
      return function() {
        _this._renderAndDisplay(offset);
        return _this._delegateEvents();
      };
    })(this);
    if (gainFocus) {
      return mediator.setFocus(this, showFn);
    } else {
      return showFn();
    }
  };

  PopupMenuView.prototype.showSubmenu = function(e) {
    var offset, subEl, subId, submenuItems, submenuView;
    subEl = $(e.target).closest('.submenu');
    subId = subEl.data('subId');
    submenuItems = _.result(_(this.menuItems).findWhere({
      subId: subId
    }), 'items');
    submenuView = new PopupMenuView({
      menuItems: submenuItems,
      addClass: 'submenu-list'
    });
    submenuView.showDot = false;
    this.subview('submenuView', submenuView);
    offset = subEl.offset();
    offset.left = this.$el.offset().left + this.$el.width();
    return submenuView.show(offset, false);
  };

  PopupMenuView.prototype.hideSubmenu = function() {
    var _ref;
    return (_ref = this.subview('submenuView')) != null ? _ref.dispose() : void 0;
  };

  PopupMenuView.prototype.hide = function() {
    if (!this.hidden) {
      this.$el.remove();
      this.hidden = true;
      return this.dispose();
    }
  };

  PopupMenuView.prototype.onFocusLost = function() {
    return this.hide();
  };

  return PopupMenuView;

})(View);
