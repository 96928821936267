var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"authentication\">\n  <form>\n    <a class=\"logo\" href=\"http://gradepro.org\" target=\"_blank\"></a>\n    <h1 class=\"title\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "sign_in", {"name":"i18n","hash":{},"data":data})))
    + "</h1>\n    <div class=\"error-shared\"></div>\n    <div class=\"info\"></div>\n    <label>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.loginOnUnauthorized : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.program(4, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "      <input class=\"login\" name=\"username\" autofocus>\n    </label>\n    <div class=\"error login\"></div>\n    <label>\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "password", {"name":"i18n","hash":{},"data":data})))
    + "\n      <input class=\"password\" name=\"password\" type=\"password\">\n    </label>\n    <div class=\"error password\"></div>\n    <input type=\"submit\" class=\"btn btn-info btn-block log-in\" value=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "log_in", {"name":"i18n","hash":{},"data":data})))
    + "\">\n    <button class=\"forgot-password\" type=\"button\" tabindex=\"-1\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "forgot_password", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n  </form>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.loginOnUnauthorized : depth0), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.program(8, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n";
  stack1 = this.invokePartial(partials.copyrightNotice, '', 'copyrightNotice', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "email", {"name":"i18n","hash":{},"data":data})))
    + "\n";
},"4":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "email_or_username", {"name":"i18n","hash":{},"data":data})))
    + "\n";
},"6":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <button class=\"user-switch\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "change_user", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n";
},"8":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    <div class=\"footer\">\n      <span class=\"new-to-gdt\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "new_to_gdt", {"name":"i18n","hash":{},"data":data})))
    + "&nbsp;</span>\n      <button class=\"registration-view-switch\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "new_account", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n";
  stack1 = this.invokePartial(partials.languageSelect, '      ', 'languageSelect', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "registration", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}