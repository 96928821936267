var FormsApiHelper, ScopeWorkflowStatusCheck, ViewTrait, W, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ViewTrait = require('./view_trait');

W = require('when');

mediator = require('mediator');

FormsApiHelper = require('base/lib/forms_api_helper').questionGeneration;

module.exports = ScopeWorkflowStatusCheck = (function(_super) {
  __extends(ScopeWorkflowStatusCheck, _super);

  function ScopeWorkflowStatusCheck() {
    return ScopeWorkflowStatusCheck.__super__.constructor.apply(this, arguments);
  }

  ScopeWorkflowStatusCheck.prototype.documentId = null;

  ScopeWorkflowStatusCheck.prototype.apply = function(view) {
    ScopeWorkflowStatusCheck.__super__.apply.apply(this, arguments);
    if (!this.documentId) {
      throw new Error('documentId must be defined');
    }
    this._addFunction(view, 'isBrainstormingClosed');
    this._addFunction(view, 'isRatingClosed');
    return this._addFunction(view, 'isApprovalClosed');
  };

  ScopeWorkflowStatusCheck.prototype.isBrainstormingClosed = function(originalFunction, trait) {
    var id, message;
    id = trait.documentId;
    message = $.t('es:question.brainstorming.closed');
    return trait._isDocumentClosed(id, message);
  };

  ScopeWorkflowStatusCheck.prototype.isRatingClosed = function(originalFunction, trait) {
    var id, message;
    id = trait.documentId;
    message = $.t('es:question.rating.closed');
    return trait._isDocumentClosed(id, message);
  };

  ScopeWorkflowStatusCheck.prototype.isApprovalClosed = function(originalFunction, trait) {
    var id, message;
    id = trait.documentId;
    message = $.t('es:question.approval.closed');
    return trait._isDocumentClosed(id, message);
  };

  ScopeWorkflowStatusCheck.prototype._isDocumentClosed = function(id, message) {
    return FormsApiHelper.getFormDoc(id).then(function(doc) {
      if (doc.status === 'closed') {
        mediator.dialogs.warning({
          modal: true,
          message: message
        });
        return true;
      } else {
        return false;
      }
    });
  };

  return ScopeWorkflowStatusCheck;

})(ViewTrait);
