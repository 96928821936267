var DraftQuestionsView, QuestionsScopeWorkflowService, ScopeToolbarView, View, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

ScopeToolbarView = require('views/scope/toolbar_view');

QuestionsScopeWorkflowService = require('lib/services/questions_scope_workflow_service');

mediator = require('mediator');

module.exports = DraftQuestionsView = (function(_super) {
  __extends(DraftQuestionsView, _super);

  function DraftQuestionsView() {
    return DraftQuestionsView.__super__.constructor.apply(this, arguments);
  }

  DraftQuestionsView.prototype._VIEWS = {
    'questions.initial': 'views/scope/questions_initial_draft_view',
    'questions.brainstorming': 'views/scope/questions_brainstorming_view',
    'questions.moderation': 'views/scope/questions_moderation_view',
    'questions.rating': 'views/scope/questions_rating_view',
    'questions.proposal': 'views/scope/questions_proposal_view',
    'questions.approval': 'views/scope/questions_approval_view',
    'questions.finished': 'views/scope/questions_finish_view'
  };

  DraftQuestionsView.prototype.initialize = function() {
    var currentStep;
    DraftQuestionsView.__super__.initialize.apply(this, arguments);
    this.service = new QuestionsScopeWorkflowService({
      project: this.model
    });
    currentStep = this.service.getCurrentStep();
    this.subview('toolbar', new ScopeToolbarView({
      steps: this.service.getStepsInOrder(),
      currentStep: this.service.getCurrentStep()
    }));
    this.subview('content', this._getViewForStep(currentStep));
    this.subscribeEvent('stepFinished', (function(_this) {
      return function(step) {
        var loadingIndicator;
        loadingIndicator = mediator.dialogs.loading($.t('messages.working'));
        return _this.service.completeStep(step).done(function() {
          return _this.publishEvent('stepChanged', _this.service.getCurrentStep());
        }).otherwise(function(error) {
          return mediator.dialogs.error(error);
        }).ensure(function() {
          return loadingIndicator.destroy();
        });
      };
    })(this));
    return this.subscribeEvent('stepChanged', (function(_this) {
      return function(step, options) {
        return _this.subview('content', _this._getViewForStep(step, options));
      };
    })(this));
  };

  DraftQuestionsView.prototype._getViewForStep = function(step, options) {
    if (options == null) {
      options = {};
    }
    options = _(options).extend({
      collection: this.model.get('draftQuestions'),
      service: this.service
    });
    return new (require(this._VIEWS[step]))(options);
  };

  return DraftQuestionsView;

})(View);
