var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "<label for=\"changeScoreInControls\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "change_score_in_controls", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n<div class=\"inline\">\n  "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "changeScoreInControls", (depth0 != null ? depth0.changeScoreInControls : depth0), "range: measure.range, mean: measure.mean, median: measure.median", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n</div>\n<input type=\"text\" name=\"changeScoreInControlsValue\" id=\"changeScoreInControlsValue\"\n  class=\"number\" value=\""
    + escapeExpression(((helper = (helper = helpers.changeScoreInControlsValue || (depth0 != null ? depth0.changeScoreInControlsValue : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"changeScoreInControlsValue","hash":{},"data":data}) : helper)))
    + "\">\n<div class=\"sup\"></div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}