var EtdActions, QuestionsActions, alt, mediator;

alt = require('alt');

mediator = require('mediator');

QuestionsActions = require('actions/questions_actions');

EtdActions = (function() {
  function EtdActions() {
    this.generateActions('add', 'fetch', 'save', 'pouchSaveSuccess', 'templateUndefined', 'pouchPutAttachmentSuccess', 'disableContentEditable', 'enableContentEditable', 'saveContentEditable', 'insertContent', 'updateHeaderFieldContent', 'saveHeaderData', 'updateBackground', 'updatePresentationType', 'saveBackground', 'changeAndSaveDecision');
  }

  EtdActions.prototype.pouchFetchError = function(error) {
    throw error;
  };

  EtdActions.prototype.pouchPutAttachmentError = function(error) {
    throw error;
  };

  EtdActions.prototype.pouchFetchSuccess = function(data) {
    var etdDoc, _ref;
    etdDoc = (_ref = data.rows[0]) != null ? _ref.doc : void 0;
    if (etdDoc) {
      QuestionsActions.fetch({
        docId: etdDoc.templateData.question.docId
      });
      return this.dispatch(etdDoc);
    } else {
      return this.actions.templateUndefined();
    }
  };

  EtdActions.prototype.pouchSaveError = function(error) {
    throw error;
  };

  EtdActions.prototype.changeFavours = function(sectionKey, newFavours) {
    this.dispatch({
      section: sectionKey,
      value: newFavours
    });
    return this.actions.save();
  };

  EtdActions.prototype.clearFavours = function() {
    this.dispatch();
    return this.actions.save();
  };

  EtdActions.prototype.changeImportance = function(sectionKey, newImportance) {
    this.dispatch({
      section: sectionKey,
      value: newImportance
    });
    return this.actions.save();
  };

  EtdActions.prototype.clearImportance = function() {
    this.dispatch();
    return this.actions.save();
  };

  EtdActions.prototype.changeJudgement = function(sectionId, newJudgement) {
    return this.dispatch({
      sectionId: sectionId,
      value: newJudgement
    });
  };

  EtdActions.prototype.changeAndSaveJudgement = function(sectionId, newJudgement) {
    return this.dispatch({
      sectionId: sectionId,
      value: newJudgement
    });
  };

  EtdActions.prototype.updateAndSaveConclusionsOption = function(conclusionsSection, value) {
    return this.dispatch({
      section: conclusionsSection,
      value: value
    });
  };

  EtdActions.prototype.updatePresentationType = function(type) {
    return this.dispatch({
      type: type
    });
  };

  EtdActions.prototype.updateContentEditableContent = function(contentEditableId, content) {
    return this.dispatch({
      contentEditableId: contentEditableId,
      content: content
    });
  };

  EtdActions.prototype.updatePanelDiscussion = function(sectionId, value) {
    return this.dispatch({
      sectionId: sectionId,
      value: value
    });
  };

  EtdActions.prototype.updateDetailedGeneralQuestion = function(sectionId, questionIdx, value) {
    return this.dispatch({
      sectionId: sectionId,
      questionIdx: questionIdx,
      value: value
    });
  };

  EtdActions.prototype.updateDetailedOutcomesQuestion = function(sectionId, questionIdx, outcomeId, value) {
    return this.dispatch({
      sectionId: sectionId,
      questionIdx: questionIdx,
      outcomeId: outcomeId,
      value: value
    });
  };

  EtdActions.prototype.changeDetailedResourceName = function(sectionId, questionIdx, resourceIdx, value) {
    return this.dispatch({
      sectionId: sectionId,
      questionIdx: questionIdx,
      resourceIdx: resourceIdx,
      value: value
    });
  };

  EtdActions.prototype.updateDetailedResourcesQuestion = function(sectionId, questionIdx, resourceIdx, value) {
    return this.dispatch({
      sectionId: sectionId,
      questionIdx: questionIdx,
      resourceIdx: resourceIdx,
      value: value
    });
  };

  EtdActions.prototype.addDetailedResource = function(sectionId, questionIdx) {
    return this.dispatch({
      sectionId: sectionId,
      questionIdx: questionIdx
    });
  };

  EtdActions.prototype.removeDetailedResource = function(sectionId, questionIdx, resourceIdx) {
    return this.dispatch({
      sectionId: sectionId,
      questionIdx: questionIdx,
      resourceIdx: resourceIdx
    });
  };

  EtdActions.prototype.changeAndSaveCriteriaDecision = function(sectionId, newJudgement) {
    return this.dispatch({
      sectionId: sectionId,
      value: newJudgement
    });
  };

  return EtdActions;

})();

module.exports = alt.createActions(EtdActions);
