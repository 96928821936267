var templatesDefinitions;

templatesDefinitions = [
  {
    id: 'tx-crIp',
    name: 'Clinical recommendation - Individual perspective',
    question: [
      {
        id: 'population',
        required: true
      }, {
        id: 'intervention',
        required: true
      }, {
        id: 'comparison',
        checked: true
      }, {
        id: 'mainOutcomes',
        checked: true
      }, {
        id: 'setting',
        checked: true
      }, {
        id: 'perspective',
        checked: true
      }, {
        id: 'background',
        checked: true
      }
    ],
    assessment: [
      {
        id: 'problem',
        checked: true
      }, {
        id: 'desirableEffects',
        checked: true
      }, {
        id: 'undesirableEffects',
        checked: true
      }, {
        id: 'certaintyOfEvidence',
        checked: true
      }, {
        id: 'values',
        checked: true
      }, {
        id: 'balanceOfEffects',
        checked: true
      }, {
        id: 'acceptability',
        checked: true
      }, {
        id: 'feasibility',
        checked: true
      }
    ],
    conclusions: [
      {
        id: 'recommendationTypeIntervention',
        checked: true
      }, {
        id: 'recommendation',
        checked: true
      }, {
        id: 'justification',
        checked: true
      }, {
        id: 'subgroupConsiderations',
        checked: true
      }, {
        id: 'implementationConsiderations',
        checked: true
      }, {
        id: 'monitoringAndEvaluation',
        checked: true
      }, {
        id: 'researchPriorities',
        checked: true
      }
    ],
    presentations: [
      {
        id: 'clinicians',
        checked: true,
        sections: [
          {
            id: 'background',
            checked: true
          }, {
            id: 'subgroupConsiderations',
            checked: true
          }, {
            id: 'justification',
            checked: true
          }, {
            id: 'detailedJustification',
            checked: true
          }, {
            id: 'summaryOfFindings',
            checked: true
          }
        ]
      }, {
        id: 'patients',
        checked: true,
        sections: [
          {
            id: 'justification',
            checked: true
          }, {
            id: 'whatItMeansForYou',
            checked: true
          }, {
            id: 'whoIsThisFor',
            checked: true
          }
        ]
      }, {
        id: 'policymakers',
        checked: true,
        sections: [
          {
            id: 'assessment',
            checked: true
          }, {
            id: 'background',
            checked: true
          }, {
            id: 'decision',
            checked: true
          }, {
            id: 'implementation',
            checked: true
          }, {
            id: 'justification',
            checked: true
          }, {
            id: 'monitoringAndEvaluation',
            checked: true
          }, {
            id: 'recommendation',
            checked: true
          }
        ]
      }
    ]
  }, {
    id: 'tx-crPp',
    name: 'Clinical recommendation - Population perspective',
    question: [
      {
        id: 'population',
        required: true
      }, {
        id: 'intervention',
        required: true
      }, {
        id: 'comparison',
        checked: true
      }, {
        id: 'mainOutcomes',
        checked: true
      }, {
        id: 'setting',
        checked: true
      }, {
        id: 'perspective',
        checked: true
      }, {
        id: 'background',
        checked: true
      }
    ],
    assessment: [
      {
        id: 'problem',
        checked: true
      }, {
        id: 'desirableEffects',
        checked: true
      }, {
        id: 'undesirableEffects',
        checked: true
      }, {
        id: 'certaintyOfEvidence',
        checked: true
      }, {
        id: 'values',
        checked: true
      }, {
        id: 'balanceOfEffects',
        checked: true
      }, {
        id: 'resourcesRequired',
        checked: true
      }, {
        id: 'certaintyOfEvidenceOfRequiredResources',
        checked: true
      }, {
        id: 'costEffectiveness',
        checked: true
      }, {
        id: 'equity',
        checked: true
      }, {
        id: 'acceptability',
        checked: true
      }, {
        id: 'feasibility',
        checked: true
      }
    ],
    conclusions: [
      {
        id: 'recommendationTypeIntervention',
        checked: true
      }, {
        id: 'recommendation',
        checked: true
      }, {
        id: 'justification',
        checked: true
      }, {
        id: 'subgroupConsiderations',
        checked: true
      }, {
        id: 'implementationConsiderations',
        checked: true
      }, {
        id: 'monitoringAndEvaluation',
        checked: true
      }, {
        id: 'researchPriorities',
        checked: true
      }
    ],
    presentations: [
      {
        id: 'clinicians',
        checked: true,
        sections: [
          {
            id: 'background',
            checked: true
          }, {
            id: 'subgroupConsiderations',
            checked: true
          }, {
            id: 'justification',
            checked: true
          }, {
            id: 'detailedJustification',
            checked: true
          }, {
            id: 'summaryOfFindings',
            checked: true
          }
        ]
      }, {
        id: 'patients',
        checked: true,
        sections: [
          {
            id: 'justification',
            checked: true
          }, {
            id: 'whatItMeansForYou',
            checked: true
          }, {
            id: 'whoIsThisFor',
            checked: true
          }
        ]
      }, {
        id: 'policymakers',
        checked: true,
        sections: [
          {
            id: 'assessment',
            checked: true
          }, {
            id: 'background',
            checked: true
          }, {
            id: 'decision',
            checked: true
          }, {
            id: 'implementation',
            checked: true
          }, {
            id: 'justification',
            checked: true
          }, {
            id: 'monitoringAndEvaluation',
            checked: true
          }, {
            id: 'recommendation',
            checked: true
          }
        ]
      }
    ]
  }, {
    id: 'tx-cd',
    name: 'Coverage Decision',
    question: [
      {
        id: 'population',
        required: true
      }, {
        id: 'intervention',
        required: true
      }, {
        id: 'comparison',
        checked: true
      }, {
        id: 'mainOutcomes',
        checked: true
      }, {
        id: 'setting',
        checked: true
      }, {
        id: 'perspective',
        checked: true
      }, {
        id: 'background',
        checked: true
      }
    ],
    assessment: [
      {
        id: 'problem',
        checked: true
      }, {
        id: 'desirableEffects',
        checked: true
      }, {
        id: 'undesirableEffects',
        checked: true
      }, {
        id: 'certaintyOfEvidence',
        checked: true
      }, {
        id: 'values',
        checked: true
      }, {
        id: 'balanceOfEffects',
        checked: true
      }, {
        id: 'resourcesRequired',
        checked: true
      }, {
        id: 'certaintyOfEvidenceOfRequiredResources',
        checked: true
      }, {
        id: 'costEffectiveness',
        checked: true
      }, {
        id: 'equity',
        checked: true
      }, {
        id: 'acceptability',
        checked: true
      }, {
        id: 'feasibility',
        checked: true
      }
    ],
    conclusions: [
      {
        id: 'decisionTypeCoverage',
        checked: true
      }, {
        id: 'decision',
        checked: true
      }, {
        id: 'justification',
        checked: true
      }, {
        id: 'restrictions',
        checked: true
      }, {
        id: 'implementationConsiderations',
        checked: true
      }, {
        id: 'monitoringAndEvaluation',
        checked: true
      }, {
        id: 'researchPriorities',
        checked: true
      }
    ],
    presentations: [
      {
        id: 'clinicians',
        checked: true,
        sections: [
          {
            id: 'background',
            checked: true
          }, {
            id: 'detailedJustification',
            checked: true
          }, {
            id: 'justification',
            checked: true
          }, {
            id: 'summaryOfFindings',
            checked: true
          }
        ]
      }, {
        id: 'patients',
        checked: true,
        sections: [
          {
            id: 'justification',
            checked: true
          }, {
            id: 'whatItMeansForYou',
            checked: true
          }, {
            id: 'whoIsThisFor',
            checked: true
          }
        ]
      }, {
        id: 'policymakers',
        checked: true,
        sections: [
          {
            id: 'assessment',
            checked: true
          }, {
            id: 'background',
            checked: true
          }, {
            id: 'decision',
            checked: true
          }, {
            id: 'implementation',
            checked: true
          }, {
            id: 'justification',
            checked: true
          }, {
            id: 'monitoringAndEvaluation',
            checked: true
          }, {
            id: 'recommendation',
            checked: true
          }
        ]
      }
    ]
  }, {
    id: 'tx-hsPhr',
    name: 'Health system and public health recommendation',
    question: [
      {
        id: 'problem',
        required: true
      }, {
        id: 'option',
        required: true
      }, {
        id: 'comparison',
        checked: true
      }, {
        id: 'mainOutcomes',
        checked: true
      }, {
        id: 'setting',
        checked: true
      }, {
        id: 'perspective',
        checked: true
      }, {
        id: 'background',
        checked: true
      }
    ],
    assessment: [
      {
        id: 'problem',
        checked: true
      }, {
        id: 'desirableEffects',
        checked: true
      }, {
        id: 'undesirableEffects',
        checked: true
      }, {
        id: 'certaintyOfEvidence',
        checked: true
      }, {
        id: 'values',
        checked: true
      }, {
        id: 'balanceOfEffects',
        checked: true
      }, {
        id: 'resourcesRequired',
        checked: true
      }, {
        id: 'certaintyOfEvidenceOfRequiredResources',
        checked: true
      }, {
        id: 'costEffectiveness',
        checked: true
      }, {
        id: 'equity',
        checked: true
      }, {
        id: 'acceptability',
        checked: true
      }, {
        id: 'feasibility',
        checked: true
      }
    ],
    conclusions: [
      {
        id: 'recommendationTypeOption',
        checked: true
      }, {
        id: 'recommendation',
        checked: true
      }, {
        id: 'justification',
        checked: true
      }, {
        id: 'subgroupConsiderations',
        checked: true
      }, {
        id: 'implementationConsiderations',
        checked: true
      }, {
        id: 'monitoringAndEvaluation',
        checked: true
      }, {
        id: 'researchPriorities',
        checked: true
      }
    ],
    presentations: [
      {
        id: 'clinicians',
        checked: true,
        sections: [
          {
            id: 'background',
            checked: true
          }, {
            id: 'subgroupConsiderations',
            checked: true
          }, {
            id: 'justification',
            checked: true
          }, {
            id: 'detailedJustification',
            checked: true
          }, {
            id: 'summaryOfFindings',
            checked: true
          }
        ]
      }, {
        id: 'patients',
        checked: true,
        sections: [
          {
            id: 'justification',
            checked: true
          }, {
            id: 'whatItMeansForYou',
            checked: true
          }, {
            id: 'whoIsThisFor',
            checked: true
          }
        ]
      }, {
        id: 'policymakers',
        checked: true,
        sections: [
          {
            id: 'assessment',
            checked: true
          }, {
            id: 'background',
            checked: true
          }, {
            id: 'decision',
            checked: true
          }, {
            id: 'implementation',
            checked: true
          }, {
            id: 'justification',
            checked: true
          }, {
            id: 'monitoringAndEvaluation',
            checked: true
          }, {
            id: 'recommendation',
            checked: true
          }
        ]
      }
    ]
  }, {
    id: 'tx-hsPhd',
    name: 'Health system and public health decision',
    question: [
      {
        id: 'problem',
        required: true
      }, {
        id: 'option',
        required: true
      }, {
        id: 'comparison',
        checked: true
      }, {
        id: 'mainOutcomes',
        checked: true
      }, {
        id: 'setting',
        checked: true
      }, {
        id: 'perspective',
        checked: true
      }, {
        id: 'background',
        checked: true
      }
    ],
    assessment: [
      {
        id: 'problem',
        checked: true
      }, {
        id: 'desirableEffects',
        checked: true
      }, {
        id: 'undesirableEffects',
        checked: true
      }, {
        id: 'certaintyOfEvidence',
        checked: true
      }, {
        id: 'values',
        checked: true
      }, {
        id: 'balanceOfEffects',
        checked: true
      }, {
        id: 'resourcesRequired',
        checked: true
      }, {
        id: 'certaintyOfEvidenceOfRequiredResources',
        checked: true
      }, {
        id: 'costEffectiveness',
        checked: true
      }, {
        id: 'equity',
        checked: true
      }, {
        id: 'acceptability',
        checked: true
      }, {
        id: 'feasibility',
        checked: true
      }
    ],
    conclusions: [
      {
        id: 'decisionTypeImplementation',
        checked: true
      }, {
        id: 'decision',
        checked: true
      }, {
        id: 'justification',
        checked: true
      }, {
        id: 'subgroupConsiderations',
        checked: true
      }, {
        id: 'implementationConsiderations',
        checked: true
      }, {
        id: 'monitoringAndEvaluation',
        checked: true
      }, {
        id: 'researchPriorities',
        checked: true
      }
    ],
    presentations: [
      {
        id: 'clinicians',
        checked: true,
        sections: [
          {
            id: 'background',
            checked: true
          }, {
            id: 'subgroupConsiderations',
            checked: true
          }, {
            id: 'justification',
            checked: true
          }, {
            id: 'detailedJustification',
            checked: true
          }, {
            id: 'summaryOfFindings',
            checked: true
          }
        ]
      }, {
        id: 'patients',
        checked: true,
        sections: [
          {
            id: 'justification',
            checked: true
          }, {
            id: 'whatItMeansForYou',
            checked: true
          }, {
            id: 'whoIsThisFor',
            checked: true
          }
        ]
      }, {
        id: 'policymakers',
        checked: true,
        sections: [
          {
            id: 'assessment',
            checked: true
          }, {
            id: 'background',
            checked: true
          }, {
            id: 'decision',
            checked: true
          }, {
            id: 'implementation',
            checked: true
          }, {
            id: 'justification',
            checked: true
          }, {
            id: 'monitoringAndEvaluation',
            checked: true
          }, {
            id: 'recommendation',
            checked: true
          }
        ]
      }
    ]
  }, {
    id: 'dx-testCrIp',
    name: 'Tests - Clinical recommendation - Individual Perspective',
    question: [
      {
        id: 'population',
        required: true
      }, {
        id: 'intervention',
        required: true
      }, {
        id: 'comparison',
        checked: true
      }, {
        id: 'purpose',
        checked: true
      }, {
        id: 'linkedTreatments',
        checked: true
      }, {
        id: 'anticipatedOutcomes',
        checked: true
      }, {
        id: 'setting',
        checked: true
      }, {
        id: 'perspective',
        checked: true
      }, {
        id: 'background',
        checked: true
      }
    ],
    assessment: [
      {
        id: 'problem',
        checked: true
      }, {
        id: 'testAccuracy',
        checked: true
      }, {
        id: 'desirableEffects',
        checked: true
      }, {
        id: 'undesirableEffects',
        checked: true
      }, {
        id: 'certaintyOfEvidenceOfTestAccuracy',
        checked: true
      }, {
        id: 'certaintyOfEvidenceOfTestEffects',
        checked: true
      }, {
        id: 'certaintyOfEvidenceOfManagementEffects',
        checked: true
      }, {
        id: 'certaintyOfEvidenceOfTestResult',
        checked: true
      }, {
        id: 'certaintyOfEffects',
        checked: true
      }, {
        id: 'values',
        checked: true
      }, {
        id: 'balanceOfEffects',
        checked: true
      }, {
        id: 'resourcesRequired',
        checked: true
      }, {
        id: 'certaintyOfEvidenceOfRequiredResources',
        checked: true
      }, {
        id: 'costEffectiveness',
        checked: true
      }, {
        id: 'equity',
        checked: true
      }, {
        id: 'acceptability',
        checked: true
      }, {
        id: 'feasibility',
        checked: true
      }
    ],
    conclusions: [
      {
        id: 'recommendationTypeIntervention',
        checked: true
      }, {
        id: 'recommendation',
        checked: true
      }, {
        id: 'justification',
        checked: true
      }, {
        id: 'subgroupConsiderations',
        checked: true
      }, {
        id: 'implementationConsiderations',
        checked: true
      }, {
        id: 'monitoringAndEvaluation',
        checked: true
      }, {
        id: 'researchPriorities',
        checked: true
      }
    ],
    presentations: [
      {
        id: 'clinicians',
        checked: true,
        sections: [
          {
            id: 'background',
            checked: true
          }, {
            id: 'subgroupConsiderations',
            checked: true
          }, {
            id: 'justification',
            checked: true
          }, {
            id: 'detailedJustification',
            checked: true
          }, {
            id: 'summaryOfFindings',
            checked: true
          }
        ]
      }, {
        id: 'patients',
        checked: true,
        sections: [
          {
            id: 'justification',
            checked: true
          }, {
            id: 'whatItMeansForYou',
            checked: true
          }, {
            id: 'whoIsThisFor',
            checked: true
          }
        ]
      }, {
        id: 'policymakers',
        checked: true,
        sections: [
          {
            id: 'assessment',
            checked: true
          }, {
            id: 'background',
            checked: true
          }, {
            id: 'decision',
            checked: true
          }, {
            id: 'implementation',
            checked: true
          }, {
            id: 'justification',
            checked: true
          }, {
            id: 'monitoringAndEvaluation',
            checked: true
          }, {
            id: 'recommendation',
            checked: true
          }
        ]
      }
    ]
  }, {
    id: 'dx-testCrPp',
    name: 'Tests - Clinical recommendation - Population Perspective',
    question: [
      {
        id: 'population',
        required: true
      }, {
        id: 'intervention',
        required: true
      }, {
        id: 'comparison',
        checked: true
      }, {
        id: 'purpose',
        checked: true
      }, {
        id: 'linkedTreatments',
        checked: true
      }, {
        id: 'anticipatedOutcomes',
        checked: true
      }, {
        id: 'setting',
        checked: true
      }, {
        id: 'perspective',
        checked: true
      }, {
        id: 'background',
        checked: true
      }
    ],
    assessment: [
      {
        id: 'problem',
        checked: true
      }, {
        id: 'testAccuracy',
        checked: true
      }, {
        id: 'desirableEffects',
        checked: true
      }, {
        id: 'undesirableEffects',
        checked: true
      }, {
        id: 'certaintyOfEvidenceOfTestAccuracy',
        checked: true
      }, {
        id: 'certaintyOfEvidenceOfTestEffects',
        checked: true
      }, {
        id: 'certaintyOfEvidenceOfManagementEffects',
        checked: true
      }, {
        id: 'certaintyOfEvidenceOfTestResult',
        checked: true
      }, {
        id: 'certaintyOfEffects',
        checked: true
      }, {
        id: 'values',
        checked: true
      }, {
        id: 'balanceOfEffects',
        checked: true
      }, {
        id: 'resourcesRequired',
        checked: true
      }, {
        id: 'certaintyOfEvidenceOfRequiredResources',
        checked: true
      }, {
        id: 'costEffectiveness',
        checked: true
      }, {
        id: 'equity',
        checked: true
      }, {
        id: 'acceptability',
        checked: true
      }, {
        id: 'feasibility',
        checked: true
      }
    ],
    conclusions: [
      {
        id: 'recommendationTypeIntervention',
        checked: true
      }, {
        id: 'recommendation',
        checked: true
      }, {
        id: 'justification',
        checked: true
      }, {
        id: 'subgroupConsiderations',
        checked: true
      }, {
        id: 'implementationConsiderations',
        checked: true
      }, {
        id: 'monitoringAndEvaluation',
        checked: true
      }, {
        id: 'researchPriorities',
        checked: true
      }
    ],
    presentations: [
      {
        id: 'clinicians',
        checked: true,
        sections: [
          {
            id: 'background',
            checked: true
          }, {
            id: 'subgroupConsiderations',
            checked: true
          }, {
            id: 'justification',
            checked: true
          }, {
            id: 'detailedJustification',
            checked: true
          }, {
            id: 'summaryOfFindings',
            checked: true
          }
        ]
      }, {
        id: 'patients',
        checked: true,
        sections: [
          {
            id: 'justification',
            checked: true
          }, {
            id: 'whatItMeansForYou',
            checked: true
          }, {
            id: 'whoIsThisFor',
            checked: true
          }
        ]
      }, {
        id: 'policymakers',
        checked: true,
        sections: [
          {
            id: 'assessment',
            checked: true
          }, {
            id: 'background',
            checked: true
          }, {
            id: 'decision',
            checked: true
          }, {
            id: 'implementation',
            checked: true
          }, {
            id: 'justification',
            checked: true
          }, {
            id: 'monitoringAndEvaluation',
            checked: true
          }, {
            id: 'recommendation',
            checked: true
          }
        ]
      }
    ]
  }, {
    id: 'dx-testCd',
    name: 'Tests - Coverage decision',
    question: [
      {
        id: 'population',
        required: true
      }, {
        id: 'intervention',
        required: true
      }, {
        id: 'comparison',
        checked: true
      }, {
        id: 'purpose',
        checked: true
      }, {
        id: 'linkedTreatments',
        checked: true
      }, {
        id: 'anticipatedOutcomes',
        checked: true
      }, {
        id: 'setting',
        checked: true
      }, {
        id: 'perspective',
        checked: true
      }, {
        id: 'background',
        checked: true
      }
    ],
    assessment: [
      {
        id: 'problem',
        checked: true
      }, {
        id: 'testAccuracy',
        checked: true
      }, {
        id: 'desirableEffects',
        checked: true
      }, {
        id: 'undesirableEffects',
        checked: true
      }, {
        id: 'certaintyOfEvidenceOfTestAccuracy',
        checked: true
      }, {
        id: 'certaintyOfEvidenceOfTestEffects',
        checked: true
      }, {
        id: 'certaintyOfEvidenceOfManagementEffects',
        checked: true
      }, {
        id: 'certaintyOfEvidenceOfTestResult',
        checked: true
      }, {
        id: 'certaintyOfEffects',
        checked: true
      }, {
        id: 'values',
        checked: true
      }, {
        id: 'balanceOfEffects',
        checked: true
      }, {
        id: 'resourcesRequired',
        checked: true
      }, {
        id: 'certaintyOfEvidenceOfRequiredResources',
        checked: true
      }, {
        id: 'costEffectiveness',
        checked: true
      }, {
        id: 'equity',
        checked: true
      }, {
        id: 'acceptability',
        checked: true
      }, {
        id: 'feasibility',
        checked: true
      }
    ],
    conclusions: [
      {
        id: 'decisionTypeCoverage',
        checked: true
      }, {
        id: 'decision',
        checked: true
      }, {
        id: 'justification',
        checked: true
      }, {
        id: 'subgroupConsiderations',
        checked: true
      }, {
        id: 'implementationConsiderations',
        checked: true
      }, {
        id: 'monitoringAndEvaluation',
        checked: true
      }, {
        id: 'researchPriorities',
        checked: true
      }
    ],
    presentations: [
      {
        id: 'clinicians',
        checked: true,
        sections: [
          {
            id: 'background',
            checked: true
          }, {
            id: 'subgroupConsiderations',
            checked: true
          }, {
            id: 'justification',
            checked: true
          }, {
            id: 'detailedJustification',
            checked: true
          }, {
            id: 'summaryOfFindings',
            checked: true
          }
        ]
      }, {
        id: 'patients',
        checked: true,
        sections: [
          {
            id: 'justification',
            checked: true
          }, {
            id: 'whatItMeansForYou',
            checked: true
          }, {
            id: 'whoIsThisFor',
            checked: true
          }
        ]
      }, {
        id: 'policymakers',
        checked: true,
        sections: [
          {
            id: 'assessment',
            checked: true
          }, {
            id: 'background',
            checked: true
          }, {
            id: 'decision',
            checked: true
          }, {
            id: 'implementation',
            checked: true
          }, {
            id: 'justification',
            checked: true
          }, {
            id: 'monitoringAndEvaluation',
            checked: true
          }, {
            id: 'recommendation',
            checked: true
          }
        ]
      }
    ]
  }, {
    id: 'tx-rand',
    name: 'RAND',
    question: [
      {
        id: 'draftRecommendation',
        required: true
      }
    ],
    assessment: [
      {
        id: 'problemRand',
        checked: true
      }, {
        id: 'desirableEffectsRand',
        checked: true
      }, {
        id: 'undesirableEffectsRand',
        checked: true
      }, {
        id: 'certaintyOfEvidenceRand',
        checked: true
      }, {
        id: 'balanceOfEffectsRand',
        checked: true
      }, {
        id: 'resourcesRequiredRand',
        checked: true
      }, {
        id: 'certaintyOfEvidenceOfRequiredResourcesRand',
        checked: true
      }, {
        id: 'costEffectivenessRand',
        checked: true
      }, {
        id: 'valuesRand',
        checked: true
      }, {
        id: 'equityRand',
        checked: true
      }, {
        id: 'acceptabilityRand',
        checked: true
      }, {
        id: 'feasibilityRand',
        checked: true
      }
    ],
    conclusions: [
      {
        id: 'recommendationTypeRand',
        checked: true
      }, {
        id: 'recommendation',
        checked: true
      }, {
        id: 'justification',
        checked: true
      }, {
        id: 'subgroupConsiderations',
        checked: true
      }, {
        id: 'implementationConsiderations',
        checked: true
      }, {
        id: 'monitoringAndEvaluation',
        checked: true
      }, {
        id: 'researchPriorities',
        checked: true
      }
    ],
    presentations: [
      {
        id: 'clinicians',
        checked: true,
        sections: [
          {
            id: 'background',
            checked: true
          }, {
            id: 'subgroupConsiderations',
            checked: true
          }, {
            id: 'justification',
            checked: true
          }, {
            id: 'detailedJustification',
            checked: true
          }, {
            id: 'summaryOfFindings',
            checked: true
          }
        ]
      }, {
        id: 'patients',
        checked: true,
        sections: [
          {
            id: 'justification',
            checked: true
          }, {
            id: 'whatItMeansForYou',
            checked: true
          }, {
            id: 'whoIsThisFor',
            checked: true
          }
        ]
      }, {
        id: 'policymakers',
        checked: true,
        sections: [
          {
            id: 'assessment',
            checked: true
          }, {
            id: 'background',
            checked: true
          }, {
            id: 'decision',
            checked: true
          }, {
            id: 'implementation',
            checked: true
          }, {
            id: 'justification',
            checked: true
          }, {
            id: 'monitoringAndEvaluation',
            checked: true
          }, {
            id: 'recommendation',
            checked: true
          }
        ]
      }
    ]
  }
];

module.exports = templatesDefinitions;
