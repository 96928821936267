var DO, HTML, Router, firstRowForControl, formatters, getJQueryAsString, getPreFix, mediator, prepareData, statisticalUtils, utils, viewHelperUtils, _prepareSelectableListItems, _selectableAttr;

mediator = require('mediator');

Router = require('router');

utils = require('base/lib/utils');

DO = require('models/outcome/diagnostic_outcomes');

statisticalUtils = require('lib/statistical_utils');

formatters = require('lib/formatters');

viewHelperUtils = require('lib/view_helper_utils');

require('base/lib/view_helper');

require('lib/cell_helper');

Handlebars.registerHelper('if_logged_in', function(options) {
  if (mediator.user) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});

Handlebars.registerHelper('times', function(n, options) {
  var i;
  return ((function() {
    var _i, _results;
    _results = [];
    for (i = _i = 0; 0 <= n ? _i < n : _i > n; i = 0 <= n ? ++_i : --_i) {
      _results.push(options.fn(i));
    }
    return _results;
  })()).join('');
});

Handlebars.registerHelper('with', function(context, options) {
  if (!context || Handlebars.Utils.isEmpty(context)) {
    return options.inverse(this);
  } else {
    return options.fn(context);
  }
});

Handlebars.registerHelper('without', function(context, options) {
  var inverse;
  inverse = options.inverse;
  options.inverse = options.fn;
  options.fn = inverse;
  return Handlebars.helpers["with"].call(this, context, options);
});

Handlebars.registerHelper('with_user', function(options) {
  var context;
  context = mediator.user || {};
  return Handlebars.helpers["with"].call(this, context, options);
});

Handlebars.registerHelper('switchOn', function(feature, options) {
  if (!feature || mediator.services.switches.isOn(feature)) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});

Handlebars.registerHelper('isConnected', function(options) {
  if (mediator.services.replication.isConnected()) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});

Handlebars.registerHelper('i18n', function(str, params) {
  return viewHelperUtils.i18n(this._i18nNamespace, str, params);
});

Handlebars.registerHelper('i18nWithNamespace', function(namespace, str, params) {
  return viewHelperUtils.i18n(namespace, str, params);
});

Handlebars.registerHelper('i18nNoEsc', _.compose((function(str) {
  return new Handlebars.SafeString(str);
}), Handlebars.helpers.i18n));

Handlebars.registerHelper('i18nWithBRs', _.compose(viewHelperUtils.newLineToBr, Handlebars.helpers.i18n));

Handlebars.registerHelper('i18nNamespace', function(namespace, options) {
  var data, independantNamespace, newNamespace;
  independantNamespace = namespace[0] === '/';
  if (!independantNamespace) {
    newNamespace = viewHelperUtils.absoluteKey(this._i18nNamespace, namespace);
  } else {
    newNamespace = namespace.slice(1);
  }
  if (namespace !== '/') {
    newNamespace += '.';
  }
  data = _.isString(this) ? new String(this) : _.isNumber(this) ? new Number(this) : _.clone(this);
  data._i18nNamespace = newNamespace;
  return options.fn(data);
});

Handlebars.registerHelper('displayDate', function(date) {
  var _ref, _ref1;
  return (_ref = (_ref1 = moment(date)) != null ? _ref1.format($.t('time_format')) : void 0) != null ? _ref : '';
});

Handlebars.registerHelper('projectRelUrl', function(module) {
  return Router.prototype.getProjectRelativeUrl(module);
});

Handlebars.registerHelper('moduleLink', function(module, selected, disabled, missing) {
  var classes, href, hrefPart, label, title, urlized;
  urlized = Router.prototype.urlizeModuleName(module);
  href = Router.prototype.getProjectRelativeUrl("/" + urlized);
  if (!disabled) {
    hrefPart = "href='" + href + "'";
  }
  classes = urlized + (selected ? ' selected' : '') + (disabled ? ' disabled' : '');
  label = $.t("nav." + module);
  title = $.t(missing ? 'nav_tooltips.missing' : disabled ? 'nav_tooltips.disabled' : "nav_tooltips." + module);
  return new Handlebars.SafeString("<a " + (hrefPart != null ? hrefPart : '') + " class='" + classes + "' title='" + title + "'>" + label + "</a>");
});

Handlebars.registerHelper('submoduleLink', function(module, submodule, questionId, selected, disabled, missing) {
  var classes, href, hrefPart, label, moduleUrlized, nonEmptyParts, submoduleUrlized, title;
  moduleUrlized = Router.prototype.urlizeModuleName(module);
  submoduleUrlized = Router.prototype.urlizeModuleName(submodule);
  nonEmptyParts = _([moduleUrlized, questionId, submoduleUrlized]).compact();
  href = Router.prototype.getProjectRelativeUrl('/' + nonEmptyParts.join('/'));
  if (!disabled) {
    hrefPart = "href='" + href + "'";
  }
  classes = submoduleUrlized + (disabled ? ' disabled' : '') + (selected ? ' current' : '');
  label = $.t("nav." + module + "_submodules." + submodule);
  title = $.t(missing ? 'nav_tooltips.missing' : disabled ? 'nav_tooltips.disabled' : "nav_tooltips." + module + "_submodules." + submodule);
  return new Handlebars.SafeString("<a " + (hrefPart != null ? hrefPart : '') + " class='" + classes + "' title='" + title + "'>" + label + "</a>");
});

Handlebars.registerHelper('outcomeLabel', function(viewType) {
  var label;
  label = formatters.outcome.label(this, viewType);
  return new Handlebars.SafeString(label);
});

Handlebars.registerHelper('controlRatio', function() {
  return formatters.outcome.controlRatio(this);
});

Handlebars.registerHelper('displayIntervention', function() {
  return formatters.outcome.intervention(this);
});

Handlebars.registerHelper('displaySofControl', function(attributes, controlRisk) {
  var formatterName, prefix, value;
  if (!controlRisk) {
    return;
  }
  attributes._viewTypeName = 'SOF';
  value = attributes.type === 'cont' ? formatters.outcome.control(attributes) : (prefix = viewHelperUtils.controlRiskFormatterSuffix(controlRisk).toLowerCase(), formatterName = prefix ? "" + prefix + "ControlRisk" : 'control', formatters.outcome[formatterName](attributes));
  return new Handlebars.SafeString(value);
});

Handlebars.registerHelper('displayQuality', function() {
  return new Handlebars.SafeString(formatters.outcome.quality(this));
});

Handlebars.registerHelper('displayQualityModifier', function(qualityModifier) {
  if (qualityModifier) {
    return $.t("es:outcome." + (qualityModifier != null ? qualityModifier.label : void 0));
  }
});

Handlebars.registerHelper('displayOtherConsiderations', function(attributes) {
  var value;
  value = formatters.outcome.otherConsiderations(attributes);
  return new Handlebars.SafeString(value);
});

Handlebars.registerHelper('displayAbsoluteEffect', function(attributes, controlRisk) {
  var formatterName;
  if (!controlRisk) {
    return;
  }
  formatterName = 'absoluteEffect';
  if (attributes.type === 'dich' && typeof controlRisk === 'string') {
    formatterName += viewHelperUtils.controlRiskFormatterSuffix(controlRisk);
  }
  return new Handlebars.SafeString(formatters.outcome[formatterName](attributes));
});

Handlebars.registerHelper('displayCorrespondingRisk', function(attributes, controlRisk) {
  var formatterName, value;
  if (!controlRisk) {
    return;
  }
  value = attributes.type === 'cont' ? formatters.outcome.correspondingRisk(attributes) : (formatterName = "correspondingRisk" + (viewHelperUtils.controlRiskFormatterSuffix(controlRisk)), formatters.outcome[formatterName](attributes));
  return new Handlebars.SafeString(value);
});

Handlebars.registerHelper('displayDichCorrespondingRiskEditInfo', function(attributes, controlRisk) {
  if (!controlRisk) {
    return;
  }
  return new Handlebars.SafeString(formatters.outcome.dichCorrespondingRiskEditInfo(attributes, controlRisk));
});

Handlebars.registerHelper('displayRiskLabel', function(attributes, controlRisk) {
  var value;
  value = (function() {
    switch (controlRisk) {
      case 'calculatedControl':
        return $.t('es:outcome.printout.study_population');
      default:
        return attributes["" + controlRisk + "Label"];
    }
  })();
  return new Handlebars.SafeString(value);
});

Handlebars.registerHelper('displayRelativeEffect', function(attributes) {
  var value;
  value = formatters.outcome.relativeEffect(attributes);
  return new Handlebars.SafeString(value);
});

Handlebars.registerHelper('displayCaseControl', function(attributes) {
  return new Handlebars.SafeString(formatters.outcome.caseControl(attributes));
});

Handlebars.registerHelper('displayNoOfParticipantsStudies', function(attributes) {
  return new Handlebars.SafeString(formatters.outcome.noOfParticipantsStudies(attributes));
});

Handlebars.registerHelper('displayDiagnosticEffect', function(attributes, outcomeName, prevalenceNo, sensitivity, forComparator) {
  if (sensitivity == null) {
    sensitivity = true;
  }
  if (forComparator == null) {
    forComparator = false;
  }
  return new Handlebars.SafeString(formatters.outcome.diagnosticEffect(attributes, outcomeName, prevalenceNo, sensitivity, forComparator));
});

Handlebars.registerHelper('displayDiagnosticAbsoluteDifference', function(attributes, outcomeName, prevalenceNo) {
  return new Handlebars.SafeString(formatters.outcome.diagnosticAbsoluteDifference(attributes, outcomeName, prevalenceNo));
});

Handlebars.registerHelper('sensitivity', function(data, comparatorTest) {
  if (comparatorTest == null) {
    comparatorTest = false;
  }
  return formatters.question.sensitivitySpecificity(data, true, comparatorTest);
});

Handlebars.registerHelper('specificity', function(data, comparatorTest) {
  if (comparatorTest == null) {
    comparatorTest = false;
  }
  return formatters.question.sensitivitySpecificity(data, false, comparatorTest);
});

Handlebars.registerHelper('selectedIfEq', function(prop, val) {
  return ("value='" + val + "'") + (prop === val ? "selected='true'" : "");
});

Handlebars.registerHelper('selectedIfLt', function(prop, comparison, val) {
  return ("value='" + val + "'") + (prop < comparison ? "selected='true'" : "");
});

Handlebars.registerHelper('selectedIfGte', function(prop, comparison, val) {
  return ("value='" + val + "'") + (prop >= comparison ? "selected='true'" : "");
});

Handlebars.registerHelper('selectedIfStartsWith', function(prop, val) {
  return ("value='" + val + "'") + (prop && prop.indexOf(val) === 0 ? "selected='true'" : "");
});

Handlebars.registerHelper('checkedIfEq', function(prop, val) {
  val = ("value='" + val + "'") + (prop === val ? "checked=checked" : "");
  return new Handlebars.SafeString(val);
});

Handlebars.registerHelper('currentIfEq', function(prop, val) {
  return ("modifier='" + val + "'") + (prop === val ? " current=true " : "");
});

Handlebars.registerHelper('addStringAsFloat', function(value, addition) {
  return parseFloat(value) + parseFloat(addition);
});

Handlebars.registerHelper('multiply', function(value, factor) {
  return value * factor;
});

Handlebars.registerHelper('i18nCapitalize', function(value) {
  return _.string.capitalize(viewHelperUtils.i18n(this._i18nNamespace, value));
});

Handlebars.registerHelper('abs', function(value) {
  return Math.abs(value);
});

getJQueryAsString = function(jQueryEl) {
  if (!jQueryEl) {
    return '';
  }
  return $('<div>').append(jQueryEl.clone()).html();
};

HTML = {};

HTML.parseAttributes = function(hash, ommit) {
  return Object.keys(hash).map(function(key) {
    if (!_.contains(ommit, key)) {
      return "" + key + "=\"" + hash[key] + "\"";
    }
  }).join(' ');
};

Handlebars.registerHelper('select', function(context, options) {
  var placeholder, select;
  select = "<select " + (HTML.parseAttributes(options.hash, ['selected', 'placeholder'])) + ">";
  if (placeholder = options.hash.placeholder) {
    select += "<option value=''>" + placeholder + "</option>";
  }
  select += context.map(function(item) {
    var text, value;
    if (typeof item === 'object') {
      value = item.value;
      text = item.text;
    } else {
      value = text = item;
    }
    return "<option " + (options.hash.selected === value ? 'selected=true' : '') + " value='" + value + "'>" + text + "</option>";
  }).join('\n') + "</select>";
  return new Handlebars.SafeString(select);
});

Handlebars.registerHelper('capTag', function(htmlOrLink, options) {
  var capitalizedHtml, rawHtml, s;
  s = htmlOrLink.split('html: ');
  rawHtml = s.length === 1 ? s[0] : $.t(s[1]);
  capitalizedHtml = rawHtml.replace(/(\w+>)/g, function(match, p1) {
    return match.replace(p1, p1.toUpperCase()).replace(/br\s?\/?/gi, 'BR /');
  });
  return new Handlebars.SafeString(capitalizedHtml);
});

Handlebars.registerHelper('forList', function() {
  var body, element;
  body = _(arguments).last().fn;
  return ((function() {
    var _i, _len, _ref, _results;
    _ref = _(arguments).initial();
    _results = [];
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      element = _ref[_i];
      _results.push(body(_(this).extend({
        it: element
      })));
    }
    return _results;
  }).apply(this, arguments)).join('');
});

Handlebars.registerHelper('actionLink', function(actionName) {
  var localizedCaption;
  localizedCaption = viewHelperUtils.i18n(this._i18nNamespace, "actions." + actionName);
  return new Handlebars.SafeString("<a href='#' title='" + localizedCaption + "' class='" + actionName + "'>" + localizedCaption + "</a>");
});

Handlebars.registerHelper('inStringArray', function(stringArray, value, options) {
  if (_(stringArray.split(',')).map(function(v) {
    return v.trim();
  }).indexOf(value) !== -1) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});

Handlebars.registerHelper('ifNotPrintout', function(string, options) {
  if (string.toLowerCase().indexOf('printout') === -1) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});

prepareData = function(name, data, params) {
  var k, v;
  for (k in params) {
    v = params[k];
    data[k] = v;
  }
  return data;
};

getPreFix = function(data) {
  var controlTypes, index, preFix;
  controlTypes = ['main', 'low', 'moderate', 'high'];
  index = _([data.calculatedControl, data.lowControl, data.moderateControl, data.highControl]).indexOf(true);
  return preFix = index === -1 ? controlTypes[0] : controlTypes[index];
};

Handlebars.registerHelper('riskWithControlFirst', function(name, data, params) {
  return firstRowForControl('risk_with_control', name, data, params);
});

Handlebars.registerHelper('controlFirst', function(name, data, params) {
  return firstRowForControl('control', name, data, params);
});

firstRowForControl = function(cellName, name, data, params) {
  var colSpan, isCaseControl, labelFirst, notMeasuredArray, partial, partialName, preFix, template, viewTypeName, _ref;
  data = data === void 0 ? {} : _(data).clone();
  preFix = getPreFix(data);
  viewTypeName = data.viewTypeName;
  labelFirst = viewTypeName === 'SOF' || viewTypeName === 'SOF_ACCP' || viewTypeName === 'PrintoutSOF' || viewTypeName === 'PrintoutSOFACCP' || viewTypeName === 'ACCP' || viewTypeName === 'PrintoutGRADEACCP' || viewTypeName === 'SOF_v3' || viewTypeName === 'PrintoutSOFv3';
  notMeasuredArray = (_ref = data.metaType) === 'NOT_REPORTED' || _ref === 'NOT_MEASURED';
  isCaseControl = data.isCaseControl;
  template = cellName === 'risk_with_control' ? 'accp/risk_with_control' : 'control';
  if (labelFirst && data.type === 'dich' && !notMeasuredArray) {
    colSpan = viewTypeName === 'SOF_v3' || viewTypeName === 'PrintoutSOFv3' ? 3 : 2;
    partial = "views/templates/evidence_syntheses/cells/control_label";
    partialName = "" + preFix + "Label";
  } else {
    partial = "views/templates/evidence_syntheses/cells/" + template;
    partialName = "" + cellName + "_" + preFix + "Value";
  }
  if (_(params).isString()) {
    params = JSON.parse(params);
    data = prepareData(name, data, params);
    data.colNo = "data-col-no=" + params.colNo;
    data.preFix = preFix;
    if (labelFirst && data.type === 'dich') {
      data.colSpan = colSpan;
      data.controlLabel = preFix;
    }
  }
  return viewHelperUtils.regPartial(partialName, partial, data);
};

Handlebars.registerHelper('absoluteEffectFirst', function(name, data, params) {
  var partial, partialName, preFix;
  data = data === void 0 ? {} : _(data).clone();
  preFix = getPreFix(data);
  partial = "views/templates/evidence_syntheses/cells/absolute_effect";
  partialName = "abs_" + preFix + "Value";
  if (_(params).isString()) {
    params = JSON.parse(params);
    data = prepareData(name, data, params);
    data.colNo = "data-col-no=" + params.colNo;
    data.preFix = preFix;
  }
  return viewHelperUtils.regPartial(partialName, partial, data);
});

Handlebars.registerHelper('cell', function(name, data, params) {
  var partial, _ref;
  partial = "views/templates/evidence_syntheses/cells/" + name;
  data = data === void 0 ? {} : _(data).clone();
  if (_(params).isString()) {
    params = JSON.parse(params);
    data = prepareData(name, data, params);
    data.colNo = "data-col-no=" + params.colNo;
    if (name === 'control_label') {
      data.colSpan = (_ref = data.viewTypeName) === 'SOF_v3' || _ref === 'PrintoutSOFv3' ? 3 : 2;
    }
  }
  return viewHelperUtils.regPartial(name, partial, data);
});

Handlebars.registerHelper('notEditableCell', function(itemData, dataColNo, params) {
  var data, k, partial, v;
  partial = "views/templates/evidence_syntheses/cells/not_editable";
  data = itemData === void 0 ? {} : _(itemData).clone();
  data.colspan = null;
  data.dataColNo = _.isFinite(dataColNo) ? "data-col-no=" + dataColNo : dataColNo;
  if (_(params).isString()) {
    params = JSON.parse(params);
    for (k in params) {
      v = params[k];
      data[k] = v;
    }
    data.className = typeof params.className === 'string' ? params.className : '';
    data.value = typeof params.messageKey === 'string' ? viewHelperUtils.i18n(this._i18nNamespace, "" + params.messageKey) : '-';
  }
  return viewHelperUtils.regPartial(name, partial, data);
});

Handlebars.registerHelper('directnessTableRadio', function(radioName, radioValue) {
  var data, partial;
  partial = "views/templates/outcome_edit/partials/directness_table_radios";
  data = {
    'radioName': radioName,
    'radioValue': radioValue
  };
  return viewHelperUtils.regPartial('directnessTableRadio', partial, data);
});

Handlebars.registerHelper('recommendationJudgementOption', function(option, attr) {
  var caption, partial;
  partial = require('views/templates/evidence_syntheses/partials/recommendation_judgement_option');
  caption = viewHelperUtils.i18n(this._i18nNamespace, option);
  return new Handlebars.SafeString(partial({
    option: option,
    caption: caption,
    title: viewHelperUtils.i18n(this._i18nNamespace, "" + option + "_title", {
      defaultValue: caption
    }),
    attr: attr
  }));
});

Handlebars.registerHelper('recommendationTable', function(context) {
  var partial;
  partial = "views/templates/evidence_syntheses/partials/recommendation_table";
  return viewHelperUtils.regPartial("recommendationTable", partial, context);
});

Handlebars.registerHelper('recommendationDxTable', function(context, type) {
  var partial;
  partial = "views/templates/evidence_syntheses/partials/recommendation_dx_table";
  return viewHelperUtils.regPartial("recommendationDxTable", partial, context);
});

Handlebars.registerHelper('recommendationDenominator', function(context) {
  return new Handlebars.SafeString("<span class=\"denominator\">\n" + (viewHelperUtils.i18n(this._i18nNamespace, "denominator", {
    value: this.effectDenominatorForRecommendation
  })) + "\n</span>");
});

Handlebars.registerHelper('recommendationControl', function(outcome, denominator) {
  return formatters.outcome.recommendationControl(outcome, denominator);
});

Handlebars.registerHelper('recommendationIntervention', function(outcome, denominator) {
  return new Handlebars.SafeString(formatters.outcome.recommendationIntervention(outcome, denominator));
});

Handlebars.registerHelper('selectionList', function(property, labelKey, options) {
  var data, selectionListTemplate;
  selectionListTemplate = Handlebars.partials.selectionListTemplate;
  data = _.clone(this);
  data.label = $.t("es:outcome." + labelKey);
  data.editOptions = new Handlebars.SafeString(options.fn(this));
  data.property = property;
  return new Handlebars.SafeString(selectionListTemplate(data));
});

_prepareSelectableListItems = function(optionsList, options) {
  var keyValue, listOption, text, value, _i, _len, _ref, _results;
  _ref = optionsList.split(',');
  _results = [];
  for (_i = 0, _len = _ref.length; _i < _len; _i++) {
    listOption = _ref[_i];
    keyValue = listOption.split(':');
    value = keyValue[0].trim();
    text = keyValue[1].trim();
    _results.push({
      value: options.number ? 1 * value : value,
      text: options.number || !isNaN(text) ? text : viewHelperUtils.i18n(this._i18nNamespace, text)
    });
  }
  return _results;
};

_selectableAttr = function(name, val, optionsList, options) {
  var data, disabled, listItem, listItems, optionsTagList, partial, selectedListItem, valText, _ref;
  options = options || {};
  listItems = _prepareSelectableListItems.call(this, optionsList, options);
  selectedListItem = _(listItems).findWhere({
    value: val
  });
  valText = (_ref = selectedListItem != null ? selectedListItem.text : void 0) != null ? _ref : '';
  disabled = options.disabled === true;
  optionsTagList = (function() {
    var _i, _len, _results;
    _results = [];
    for (_i = 0, _len = listItems.length; _i < _len; _i++) {
      listItem = listItems[_i];
      _results.push("<option value='" + listItem.value + "'>" + listItem.text + "</option>");
    }
    return _results;
  })();
  partial = Handlebars.partials.selectableAttrTemplate;
  data = {
    name: name,
    val: val,
    optionsTagList: optionsTagList,
    valText: valText,
    disabled: disabled
  };
  return new Handlebars.SafeString(partial(data));
};

Handlebars.registerHelper('unitSelectableAttr', function(attributes) {
  var optionsList;
  optionsList = attributes.effectSize >= 0 ? "countable: higher, uncountable: more" : "countable: lower, uncountable: fewer";
  return _selectableAttr.call(this, 'unitsType', attributes.unitsType, optionsList);
});

Handlebars.registerHelper('selectableAttr', function(name, val, optionsList, disabled) {
  return _selectableAttr.call(this, name, val, optionsList, {
    disabled: disabled
  });
});

Handlebars.registerHelper('selectableNumberAttr', function(name, val, optionsList, disabled) {
  return _selectableAttr.call(this, name, val, optionsList, {
    number: true,
    disabled: disabled
  });
});

Handlebars.registerHelper('absDenominatorSelectableAttr', function(val) {
  return _selectableAttr.call(this, 'absDenominator', val, '100: 100, 1000: 1000, 100000: 100000, 1000000: 1000000', {
    number: true
  });
});

Handlebars.registerHelper('contentEditable', function(name, enableInsert, titleMsg) {
  var data, partial, title, withInsert;
  partial = Handlebars.partials.contentEditablePartial;
  withInsert = enableInsert === 'with_insert';
  title = _(titleMsg).isString() || (withInsert === false && _.isString(enableInsert)) ? viewHelperUtils.i18n(this._i18nNamespace, titleMsg || enableInsert) : void 0;
  data = {
    name: name,
    enableInsert: withInsert,
    title: title,
    printout: this.printout,
    value: this[name]
  };
  return new Handlebars.SafeString(partial(data));
});

Handlebars.registerHelper('compoundEdit', function(property, labelKey, options) {
  var compoundTemplate, data;
  compoundTemplate = Handlebars.partials.compoundTemplate;
  data = _.clone(this);
  data.label = $.t("es:outcome." + labelKey);
  data.compoundData = new Handlebars.SafeString(options.fn(this));
  data.property = property;
  return new Handlebars.SafeString(compoundTemplate(data));
});

Handlebars.registerHelper('inputBlock', function(name, property) {
  var inputBlockContent, partial;
  partial = "views/templates/outcome_edit/input_blocks/" + name;
  inputBlockContent = viewHelperUtils.regPartial("inputBlock-" + name, partial, _(this).clone());
  property = typeof property === 'string' ? property : null;
  return new Handlebars.SafeString(Handlebars.partials.inputBlockTemplate({
    content: inputBlockContent,
    property: property
  }));
});

Handlebars.registerHelper('displayTick', function(value) {
  if (value) {
    return new Handlebars.SafeString('<img src="images/checkbox.png">');
  }
});

Handlebars.registerHelper('recommendationRadiosTable', function(valuesList, attrName) {
  valuesList = _(valuesList.split(',')).map(function(v) {
    return _.string.trim(v);
  });
  return new Handlebars.SafeString(Handlebars.partials.recommendationRadiosTable({
    valuesList: valuesList,
    attrName: attrName
  }));
});

Handlebars.registerHelper('rm5footnotes', function(cellName, outcome, controlRisk) {
  var attr, attrs, cellOptions, footnotesNumbers, outcomeCellTypes, viewType, _i, _j, _len, _len1, _pushNumbers, _ref, _ref1;
  outcomeCellTypes = require('models/outcome/outcome_cell_types');
  footnotesNumbers = [];
  _pushNumbers = function(propertyFootnotesIds) {
    var footnoteId, idx, _i, _len, _ref, _results;
    if (propertyFootnotesIds == null) {
      propertyFootnotesIds = [];
    }
    if (!propertyFootnotesIds.length) {
      return;
    }
    _results = [];
    for (_i = 0, _len = propertyFootnotesIds.length; _i < _len; _i++) {
      footnoteId = propertyFootnotesIds[_i];
      idx = (_ref = _.findWhere(outcome.footnotesList, {
        id: footnoteId
      })) != null ? _ref.idx : void 0;
      if (idx != null) {
        _results.push(footnotesNumbers.push(idx + 1));
      } else {
        _results.push(void 0);
      }
    }
    return _results;
  };
  cellOptions = typeof controlRisk === 'string' ? _(outcomeCellTypes[cellName].otherFormatters).findWhere({
    contentDefaultClass: _.string.rtrim(controlRisk, 'Control') + '-value'
  }) : outcomeCellTypes[cellName];
  if (cellOptions.footnotesAttrs) {
    _ref = cellOptions.footnotesAttrs;
    for (viewType in _ref) {
      attrs = _ref[viewType];
      if (viewType === 'common' || viewType === 'SOF') {
        for (_i = 0, _len = attrs.length; _i < _len; _i++) {
          attr = attrs[_i];
          _pushNumbers(outcome.footnotes[attr]);
        }
      }
    }
  } else if (cellOptions.formatterAttrsObserve) {
    _ref1 = cellOptions.formatterAttrsObserve;
    for (_j = 0, _len1 = _ref1.length; _j < _len1; _j++) {
      attr = _ref1[_j];
      _pushNumbers(outcome.footnotes[attr]);
    }
  } else {
    _pushNumbers(outcome.footnotes[cellName]);
  }
  return new Handlebars.SafeString(_(footnotesNumbers).chain().compact().uniq().value().sort().join(','));
});

Handlebars.registerHelper('sofPrintoutColspan', function($tableMode, shortTable) {
  var colspan;
  if (shortTable == null) {
    shortTable = false;
  }
  colspan = $tableMode === 'narrative' ? 6 : 7;
  if (shortTable && _(shortTable).isBoolean()) {
    colspan -= 1;
  }
  return new Handlebars.SafeString("colspan=\"" + colspan + "\"");
});

Handlebars.registerHelper('makeXmlCell', function(cellData) {
  var string, tag;
  tag = cellData.type.toUpperCase();
  string = "\n\t<" + tag + " ROWSPAN=\"" + cellData.rowspan + "\" COLSPAN=\"" + cellData.colspan + "\" ALIGN=\"" + cellData.align + "\" VALIGN=\"" + cellData.valign + "\">\n\t\t <P>" + cellData.textContent + " <SUP>" + cellData.sup + "</SUP></P> \n\t</" + tag + ">";
  return new Handlebars.SafeString(string);
});

Handlebars.registerHelper('makeXmlRows', function(rowsArray) {
  var _str;
  _str = '';
  _(rowsArray).each(function(row) {
    _str += '\n<TR>';
    _(row.cells).each(function(cell) {
      return _str += Handlebars.helpers.makeXmlCell(cell);
    });
    return _str += '\n</TR>';
  });
  return new Handlebars.SafeString(_str);
});

Handlebars.registerHelper('increment', function(val) {
  return val + 1;
});
