var CellFootnotesView, CharactersLimit, ContextMenuView, DS, DirectnessTableView, Draggable, EditView, Footnotes, HelpBoxView, Outcome, OutcomeCellEditView, Shortcuts, Validation, WysiwygFootnotes, formatters, mediator, obsStudiesSelection, statisticalUtils, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

EditView = require('base/views/edit_view');

Outcome = require('models/outcome');

mediator = require('mediator');

Validation = require('base/validation');

Shortcuts = require('base/lib/traits/shortcuts');

statisticalUtils = require('lib/statistical_utils');

obsStudiesSelection = require('views/templates/outcome_edit/partials/obs_studies_selection');

DirectnessTableView = require('views/directness_table_view');

formatters = require('lib/formatters');

DS = require('models/outcome/design_studies');

CellFootnotesView = require('views/evidence_syntheses/cell_footnotes_view');

WysiwygFootnotes = require('lib/traits/wysiwyg_footnotes');

Draggable = require('base/lib/traits/draggable');

ContextMenuView = require('views/evidence_syntheses/context_menu_view');

HelpBoxView = require('views/help_box_view');

utils = require('base/lib/utils');

Footnotes = require('models/evidence_syntheses/footnotes');

CharactersLimit = require('lib/traits/characters_limit');

module.exports = OutcomeCellEditView = (function(_super) {
  __extends(OutcomeCellEditView, _super);

  function OutcomeCellEditView() {
    this.beforeFocusLost = __bind(this.beforeFocusLost, this);
    this.onFocusLost = __bind(this.onFocusLost, this);
    this.toggleAbsEffectAutoCalculation = __bind(this.toggleAbsEffectAutoCalculation, this);
    this.toggleFollowUpTimeUnit = __bind(this.toggleFollowUpTimeUnit, this);
    this.toggleControlCheckbox = __bind(this.toggleControlCheckbox, this);
    this.restoreOriginalEffectValues = __bind(this.restoreOriginalEffectValues, this);
    this.toggleObservationalStudiesList = __bind(this.toggleObservationalStudiesList, this);
    this.toggleUnitsTypeSelectList = __bind(this.toggleUnitsTypeSelectList, this);
    this.recalculateDichotomousRelativeEffect = __bind(this.recalculateDichotomousRelativeEffect, this);
    this.compoundFormSelectionChanged = __bind(this.compoundFormSelectionChanged, this);
    this._updateEffectSign = __bind(this._updateEffectSign, this);
    this.bindModelEvents = __bind(this.bindModelEvents, this);
    this.initialize = __bind(this.initialize, this);
    return OutcomeCellEditView.__super__.constructor.apply(this, arguments);
  }

  OutcomeCellEditView.prototype._riskLevelSuffix = function() {
    var cellContent;
    cellContent = this.target.find('.cell-content');
    if (cellContent.hasClass('low-value')) {
      return 'Low';
    } else if (cellContent.hasClass('moderate-value')) {
      return 'Moderate';
    } else if (cellContent.hasClass('high-value')) {
      return 'High';
    } else {
      return '';
    }
  };

  OutcomeCellEditView.prototype._controlRiskLevel = function() {
    var cellContent;
    cellContent = this.target.find('.cell-content');
    if (cellContent.hasClass('low-value')) {
      return 'lowControl';
    } else if (cellContent.hasClass('moderate-value')) {
      return 'moderateControl';
    } else if (cellContent.hasClass('high-value')) {
      return 'highControl';
    } else {
      return 'calculatedControl';
    }
  };

  OutcomeCellEditView.prototype.bindings = function() {
    var suffix;
    suffix = this._riskLevelSuffix();
    return {
      '#controlCount': 'controlCount',
      '#controlTotal': 'controlTotal',
      'input[name=calculatedControlValue]': {
        observe: ['controlCount', 'controlTotal'],
        onGet: function(values) {
          return formatters.outcome.controlRatio({
            'controlCount': values[0],
            'controlTotal': values[1]
          });
        }
      },
      'input[name=followUpTimeUnit]': {
        observe: 'followUpTimeUnit'
      },
      'input[name=followUpToTimeUnit]': {
        observe: 'followUpToTimeUnit'
      },
      'input[name=perPatients]': {
        observe: 'perPatients'
      },
      'input[name=perTimeFrame]': {
        observe: 'perTimeFrame'
      },
      'input#absEffectAutoCalc': {
        observe: "absEffectAutoCalc" + suffix,
        onSet: (function(_this) {
          return function(value) {
            _this.model.calculateAbsoluteEffect(value);
            return value;
          };
        })(this)
      },
      'input#absEffectSize': {
        observe: "absEffectSize" + suffix,
        onGet: function(val) {
          if (utils.isNumeric(val)) {
            return Math.abs(val);
          } else {
            return "";
          }
        }
      },
      'input#absEffectFrom': {
        observe: "absEffectFrom" + suffix,
        onGet: function(val) {
          if (utils.isNumeric(val)) {
            return Math.abs(val);
          } else {
            return "";
          }
        }
      },
      'input#absEffectTo': {
        observe: "absEffectTo" + suffix,
        onGet: function(val) {
          if (utils.isNumeric(val)) {
            return Math.abs(val);
          } else {
            return "";
          }
        }
      },
      'input#absDenominator': {
        observe: 'absDenominator',
        onSet: function(value) {
          var _ref;
          this.oldAbsDenominatorVal = (_ref = this.oldAbsDenominatorVal) != null ? _ref : this.model.get('absDenominator');
          return value;
        }
      },
      'input[name=effectMeasure]': 'effectMeasure',
      'input[name=unitsType]': 'unitsType',
      'input[name=effectSize]': 'effectSize',
      'input[name=effectCount]': 'effectCount',
      'input[name=effectTotal]': 'effectTotal',
      'input[name=effectRate]': 'effectRate',
      'input[name=confidenceIntervalFrom]': 'confidenceIntervalFrom',
      'input[name=confidenceIntervalTo]': 'confidenceIntervalTo',
      'input[name=effectDenominator]': 'effectDenominator',
      'input[name=effectDuration]': 'effectDuration'
    };
  };

  OutcomeCellEditView.prototype._getEditInputBlockProperty = function($inputBlock) {
    var _ref;
    return (_ref = $inputBlock.data('property')) != null ? _ref : $inputBlock.find('select, input, textarea').attr('name');
  };

  OutcomeCellEditView.prototype.footnotesBindings = function() {
    var bindings, idx, inputBlock, property, _i, _len, _ref;
    bindings = {};
    _ref = this.$el.find('.edit-input-block');
    for (idx = _i = 0, _len = _ref.length; _i < _len; idx = ++_i) {
      inputBlock = _ref[idx];
      property = this._getEditInputBlockProperty($(inputBlock));
      if (bindings[property] == null) {
        bindings[property] = [];
      }
      bindings[property].push({
        selector: ".edit-input-block:eq(" + idx + ") .sup",
        method: 'append'
      });
    }
    return bindings;
  };

  OutcomeCellEditView.prototype.initialize = function() {
    OutcomeCellEditView.__super__.initialize.apply(this, arguments);
    this.delegate('change', '.compound-edit .edit-input-block input.selection-input', this.compoundFormSelectionChanged);
    this.delegate('change', '.compound-edit .edit-input-block input.control-checkbox', this.toggleControlCheckbox);
    this.delegate('change', '.compound-edit .edit-input-block input#absEffectAutoCalc', this.toggleAbsEffectAutoCalculation);
    this.delegate('change', '.compound-edit .edit-input-block input#effectSize', this.toggleUnitsTypeSelectList);
    this.delegate('contextmenu', '.edit-input-block', this.showEditInputBlockContextMenu);
    this.delegate('change', '.compound-edit .edit-input-block input#followUpType', this.toggleFollowUpTimeUnit);
    this.on('compoundSelectionIndexChanged', this.compoundSelectionIndexChanged);
    this.on('compoundItemFocused', this.compoundItemFocused);
    this.on('opened', this._opened);
    this.property = this.options.property;
    this.questionModel = this.options.questionModel;
    if (this.options.viewTypeName === 'SOF') {
      this.textLimits = [
        {
          selector: '#comments',
          limit: 200,
          modelAttr: 'comments'
        }
      ];
    } else {
      this.textLimits = [
        {
          selector: '#comments',
          limit: 200,
          modelAttr: 'comments'
        }, {
          selector: '#prevalence1Characteristics',
          limit: 200
        }, {
          selector: '#prevalence2Characteristics',
          limit: 200
        }, {
          selector: '#prevalence3Characteristics',
          limit: 200
        }
      ];
    }
    this.enable(WysiwygFootnotes);
    this.enable(Draggable);
    return this.enable(CharactersLimit, {
      limits: this.textLimits
    });
  };

  OutcomeCellEditView.prototype._opened = function() {
    this.stickFootnotes(this.questionModel);
    if (this.editType === EditView.TYPES.SELECTION) {
      this.publishEvent('activatedProperty', this.property, this.target, this.questionModel, this.model);
    }
    return this.makeDraggable(this.box);
  };

  OutcomeCellEditView.prototype._forceAbsEffectFootnote = function(model, val) {
    var options, _ref;
    if (val) {
      return;
    }
    if (mediator.user.get('forceFootnotes') && !((_ref = this.model.get('footnotes').absEffectAutoCalc) != null ? _ref.length : void 0)) {
      this.property = 'absEffectAutoCalc';
      this.subview('footnotes', new CellFootnotesView({
        questionModel: this.questionModel,
        footnotesBindingModel: this.model,
        property: this.property
      }));
      options = {
        cell: this.$('input#absEffectAutoCalc'),
        container: $('#page-container'),
        forceAutoCalculationFootnote: true
      };
      return _.delay(this.subview('footnotes').show, 200, options);
    }
  };

  OutcomeCellEditView.prototype.bindModelEvents = function() {
    this.modelBind('change:followUpTimeUnit', this.toggleOthersInputField('.follow-up-time-unit-input'));
    this.modelBind('change:followUpToTimeUnit', this.toggleOthersInputField('.follow-up-to-time-unit-input'));
    this.modelBind('change:effectMeasure', this.toggleOthersInputField('input[name=otherMeasure]'));
    this.modelBind('change:perPatients', this.toggleOthersInputField('input[name=perPatientsOther]'));
    this.modelBind('change:perTimeFrame', this.toggleOthersInputField('input[name=perTimeFrameOther]'));
    this.modelBind('change:effectDenominator', this.toggleOthersInputField('input[name=effectOtherDenominator]'));
    this.modelBind('change:effectDuration', this.toggleOthersInputField('input[name=effectOtherDuration]'));
    this.modelBind('change:absEffectAutoCalc', this._forceAbsEffectFootnote);
    return this.modelBind('change:effectMeasure', this.recalculateDichotomousRelativeEffect);
  };

  OutcomeCellEditView.prototype.displayWarning = function() {
    var addWarning;
    addWarning = (function(_this) {
      return function(text, afterSelector) {
        return $('<div></div>').insertAfter(_this.$(afterSelector)).addClass('warning').html(text);
      };
    })(this);
    switch (this.target.data('warning')) {
      case 'empty_intervention':
        return addWarning($.t('es:outcome.edit_warnings.empty_intervention'), 'legend:first');
      case 'empty_study_design':
        return addWarning($.t('es:outcome.messages.sof_quality_study_design_not_defined'), 'legend:first');
    }
  };

  OutcomeCellEditView.prototype.afterRender = function() {
    OutcomeCellEditView.__super__.afterRender.apply(this, arguments);
    this.stickit(this.model, this.bindings());
    this.toggleInterventionControlLabels();
    return this.displayWarning();
  };

  OutcomeCellEditView.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    this.unstickit(this.model);
    this.unstickFootnotes();
    return OutcomeCellEditView.__super__.dispose.apply(this, arguments);
  };

  OutcomeCellEditView.prototype.showEditInputBlockContextMenu = function(e) {
    mediator.setFocus(this, (function(_this) {
      return function() {
        var $editInputBlock, editInputBlockContextMenu;
        _this.activateEditInputBlock(e);
        $editInputBlock = $(e.target).closest('.edit-input-block');
        editInputBlockContextMenu = new ContextMenuView({
          questionModel: _this.options.questionModel,
          footnotesBindingModel: _this.model,
          property: _this.property,
          propertyView: _this,
          cell: $editInputBlock,
          abortHandler: function() {
            return mediator.setFocus(_this);
          }
        });
        _this.subview('editInputBlockContextMenu', editInputBlockContextMenu);
        return editInputBlockContextMenu.show(utils.getEventCoordinates(e));
      };
    })(this));
    return false;
  };

  OutcomeCellEditView.prototype.isDirectnessTableChosen = function() {
    var $listElement;
    $listElement = this.$('li.edit-option').eq(this.selectionIndex);
    return $listElement.parent().attr('name') === 'indirectness' && $listElement.attr('modifier') === 'assess';
  };

  OutcomeCellEditView.prototype._updateEffectSign = function(attr, fewerMoreSelector) {
    var attrValue, fewerMore;
    attrValue = Math.abs(this.model.get(attr));
    fewerMore = this.$(fewerMoreSelector).val();
    if (fewerMore === 'more') {
      return this.model.set(attr, -1 * attrValue);
    }
  };

  OutcomeCellEditView.prototype._propagateEffectSignsUpdate = function() {
    var suffix;
    if (this.editType === EditView.TYPES.COMPOUND && this.$('div.compound-edit[name="absoluteEffect"]')) {
      suffix = this._riskLevelSuffix();
      this._updateEffectSign("absEffectSize" + suffix, "#fewerMore" + suffix);
      this._updateEffectSign("absEffectFrom" + suffix, "#fewerMoreFrom" + suffix);
      return this._updateEffectSign("absEffectTo" + suffix, "#fewerMoreTo" + suffix);
    }
  };

  OutcomeCellEditView.prototype._checkQualityCalculation = function() {
    var qm, qms, qualityModifiersSet, _ref;
    if (_ref = this.options.property, __indexOf.call(_.union(Outcome.QUALITY_MODIFIERS, ['quality', 'otherConsiderations', 'designStudies']), _ref) >= 0) {
      qms = (function() {
        var _i, _len, _ref1, _results;
        _ref1 = Outcome.QUALITY_MODIFIERS;
        _results = [];
        for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
          qm = _ref1[_i];
          _results.push(this.model.get(qm));
        }
        return _results;
      }).call(this);
      qualityModifiersSet = Outcome.areQualityModifiersSet(qms);
      if (qualityModifiersSet && !this.model.get('designStudies')) {
        this.defineStudyDesignSof = true;
        this.model.save().otherwise(function(err) {
          if (!(err instanceof Exceptions.validation)) {
            throw err;
          }
        });
        return this.parentView.highlightCellsWithWarnings();
      } else {
        return this.parentView.removeStudyDesignWarningHighlightForCell();
      }
    }
  };

  OutcomeCellEditView.prototype._checkCompoundFormFootnotes = function() {
    var attr, attributesToCheck, attributesToHighlight, condition, editViewAttributes;
    this.closeOnAccept = true;
    this.footnotesNotBounded = false;
    if (!(this.options.type === OutcomeCellEditView.TYPES.COMPOUND || this.options.external)) {
      return;
    }
    editViewAttributes = _(this.$('.edit-input-block')).map((function(_this) {
      return function(ib) {
        return _this._getEditInputBlockProperty($(ib));
      };
    })(this));
    attributesToCheck = _.pick(this.model.attributes, _.intersection(Outcome.QUALITY_MODIFIERS, editViewAttributes));
    condition = (function(_this) {
      return function(attr) {
        return _this.model.isQualityDowngradedWithoutExplanation(attr, attributesToCheck);
      };
    })(this);
    attributesToHighlight = (function() {
      var _i, _len, _ref, _results;
      _ref = _(attributesToCheck).keys();
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        attr = _ref[_i];
        if (condition(attr)) {
          _results.push(attr);
        }
      }
      return _results;
    })();
    if (attributesToHighlight.length) {
      this.closeOnAccept = false;
      this.footnotesNotBounded = true;
    }
    if (this.footnotesNotBounded) {
      return this._qualityDowngradedDialog(attributesToHighlight);
    }
  };

  OutcomeCellEditView.prototype._qualityDowngradedDialog = function(attributesToHighlight) {
    this.$el.find('.footnote-validation').removeClass('footnote-validation');
    return mediator.dialogs.confirm({
      message: $.t('messages.quality_downgraded'),
      buttons: [
        {
          'data-role': 'no',
          text: $.t('actions.skip')
        }, {
          'data-role': 'yes',
          text: $.t('actions.add_explanations')
        }
      ]
    }, (function(_this) {
      return function(add_footnotes) {
        var changedAttr, _i, _j, _len, _len1, _results;
        if (!add_footnotes) {
          for (_i = 0, _len = attributesToHighlight.length; _i < _len; _i++) {
            changedAttr = attributesToHighlight[_i];
            _this.model.bindFootnote(Footnotes.prototype.noExplanationProvided, changedAttr);
          }
          return _this.hide(true);
        } else {
          _results = [];
          for (_j = 0, _len1 = attributesToHighlight.length; _j < _len1; _j++) {
            changedAttr = attributesToHighlight[_j];
            _results.push(_this.$el.find("span[name='" + changedAttr + "']").closest('.edit-input-block').find('label').addClass('footnote-validation'));
          }
          return _results;
        }
      };
    })(this));
  };

  OutcomeCellEditView.prototype.isReadyToSave = function() {
    return this.model.isValid(true) && !this.footnotesNotBounded;
  };

  OutcomeCellEditView.prototype.accept = function() {
    if (this.editType === EditView.TYPES.SELECTION && this.isDirectnessTableChosen()) {
      this.parentView.subview('directnessTable', new DirectnessTableView({
        model: this.model,
        questionModel: this.questionModel
      }));
      return false;
    } else {
      return OutcomeCellEditView.__super__.accept.apply(this, arguments);
    }
  };

  OutcomeCellEditView.prototype.afterChangesPropagation = function() {
    if (mediator.user.get('forceFootnotes')) {
      this._checkCompoundFormFootnotes();
    }
    this._propagateEffectSignsUpdate();
    this._propagateAbsDenominatorChange();
    return this._checkQualityCalculation();
  };

  OutcomeCellEditView.prototype._propagateAbsDenominatorChange = function() {
    var newDenominator;
    newDenominator = this.model.get('absDenominator');
    if (this.oldAbsDenominatorVal && this.oldAbsDenominatorVal !== this.model.get('absDenominator')) {
      return mediator.dialogs.confirm({
        yesClass: 'danger',
        message: $.t('es:outcome.messages.abs_denominator_propagate_change', {
          from: _.string.numberFormat(this.oldAbsDenominatorVal),
          to: _.string.numberFormat(newDenominator)
        })
      }, (function(_this) {
        return function(confirmed) {
          var outcome, _i, _len, _ref;
          if (!confirmed) {
            return;
          }
          _ref = _this.questionModel.get('outcomes').models;
          for (_i = 0, _len = _ref.length; _i < _len; _i++) {
            outcome = _ref[_i];
            outcome.set('absDenominator', newDenominator);
          }
          return _this.questionModel.save().otherwise(mediator.dialogs.fatalError);
        };
      })(this));
    }
  };

  OutcomeCellEditView.prototype.compoundFormSelectionChanged = function(e) {
    return this.toggleObservationalStudiesList(e);
  };

  OutcomeCellEditView.prototype.recalculateDichotomousRelativeEffect = function(model, val, options) {
    var fromMeasure, toMeasure, _ref;
    if (options.external) {
      return;
    }
    fromMeasure = this.model.previousAttributes()['effectMeasure'];
    toMeasure = val;
    if (((_ref = this.model.get('originalEffectMeasure')) === 'RR' || _ref === 'OR' || _ref === 'HR') && toMeasure === this.model.get('originalEffectMeasure')) {
      return mediator.dialogs.confirm($.t('es:outcome.messages.recalculation.revert_to_original_value', {
        'value_type': toMeasure
      }), (function(_this) {
        return function(confirmed) {
          if (confirmed) {
            return _this.restoreOriginalEffectValues();
          }
        };
      })(this));
    } else if ((fromMeasure === 'HR' || fromMeasure === 'OR') && toMeasure === 'RR') {
      return mediator.dialogs.confirm($.t('es:outcome.messages.recalculation.convert_to_rr', {
        'value_type': fromMeasure
      }), (function(_this) {
        return function(confirmed) {
          if (confirmed) {
            return _this.model.recalculateRatio(fromMeasure, toMeasure);
          }
        };
      })(this));
    }
  };

  OutcomeCellEditView.prototype.toggleUnitsTypeSelectList = function(e) {
    var $el, countable, effectSize, index, uncountable, unitTypes;
    $el = this.$el.find('.countable-uncountable-select');
    effectSize = this.model.get('effectSize');
    unitTypes = {
      countable: [$.t('es:outcome.higher'), $.t('es:outcome.lower')],
      uncountable: [$.t('es:outcome.more'), $.t('es:outcome.fewer')]
    };
    index = effectSize >= 0 ? 0 : 1;
    countable = unitTypes.countable[index];
    uncountable = unitTypes.uncountable[index];
    $el.find('.selection-list-options option[value="countable"]').html(countable);
    $el.find('.selection-list-options option[value="uncountable"]').html(uncountable);
    if (!this.model.get('unitsType')) {
      this.model.set('unitsType', 'countable');
    }
    $el.find('.selection-text').html(unitTypes[this.model.get('unitsType')][index]);
    return this.refreshInputs();
  };

  OutcomeCellEditView.prototype.toggleObservationalStudiesList = function(e) {
    var $target, _ref;
    $target = $(e.target);
    if (this.property !== 'designStudies') {
      return;
    }
    this.toggleInterventionControlLabels($target.val());
    if ((_ref = $target.val()) === '' || _ref === 'RandTrials') {
      this.$el.find('.observational-studies-list').empty();
      return this.refreshInputs();
    } else if ($target.val() === 'ObsStudies') {
      this.$el.find('.observational-studies-list').html(obsStudiesSelection(this.getTemplateData()));
      return this.refreshInputs();
    }
  };

  OutcomeCellEditView.prototype.toggleInterventionControlLabels = function(val) {
    if (val == null) {
      val = null;
    }
    if (!val) {
      val = this.model.get('designStudies') && this.model.get('designStudies').name !== 'RandTrials' ? this.model.get('designStudies').value : void 0;
    }
    val = val != null ? val.replace('ObsStudies.', '') : void 0;
    if (val === 'case_control_studies' || val === 'case_control_and_other_combined') {
      this.$('.control-block').hide();
      this.$('.case-control-block').show();
      if (val === 'case_control_and_other_combined') {
        this.$('.case-control-other').show();
      } else {
        this.$('.case-control-other').hide();
      }
    } else {
      this.$('.case-control-block').hide();
      this.$('.control-block').show();
    }
    if (this.options.viewTypeName === "ACCP") {
      this.$('.case-control-block').hide();
    }
    return this.refreshInputs();
  };

  OutcomeCellEditView.prototype.toggleOthersInputField = function(selector) {
    return function(outcome, val) {
      if (val === 'others' || val === 'other') {
        this.$(selector).show();
        this.$(selector).closest('div').addClass('other');
      } else {
        this.$(selector).hide();
        this.$(selector).closest('div').removeClass('other');
      }
      return this.refreshInputs();
    };
  };

  OutcomeCellEditView.prototype.restoreOriginalEffectValues = function() {
    var originalValues;
    originalValues = {
      'effectMeasure': this.model.get('originalEffectMeasure'),
      'effectSize': this.model.get('originalEffectSize'),
      'confidenceIntervalFrom': this.model.get('originalConfidenceIntervalFrom'),
      'confidenceIntervalTo': this.model.get('originalConfidenceIntervalTo')
    };
    return this.model.set(originalValues);
  };

  OutcomeCellEditView.prototype.toggleControlCheckbox = function(e) {
    var $target, targetId;
    $target = $(e.target);
    targetId = $target.attr('id');
    this.$("input[name=" + targetId + "Label]").prop('disabled', !$target.is(':checked'));
    return this.$("input[name=" + targetId + "Risk]").prop('disabled', !$target.is(':checked'));
  };

  OutcomeCellEditView.prototype.toggleFollowUpTimeUnit = function(e) {
    var $target;
    $target = $(e.target);
    if ($target.val() === 'range') {
      return this.$('#range-to').show();
    } else {
      return this.$('#range-to').hide();
    }
  };

  OutcomeCellEditView.prototype.toggleAbsEffectAutoCalculation = function(e) {
    var isTargetChecked, suffix;
    suffix = this._riskLevelSuffix();
    isTargetChecked = $(e.target).is(':checked');
    this.$("#absEffectSize, #absEffectFrom, #absEffectTo").prop('disabled', isTargetChecked);
    return this.$("span[name=fewerMore" + suffix + "], span[name=fewerMoreFrom" + suffix + "], span[name=fewerMoreTo" + suffix + "]").attr('disabled', isTargetChecked);
  };

  OutcomeCellEditView.prototype.resetInputValues = function() {
    var $input, attrName, input, _i, _len, _ref, _results;
    _ref = this.inputs;
    _results = [];
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      input = _ref[_i];
      $input = $(input);
      attrName = $input.attr('name');
      if (attrName) {
        _results.push(this._setInputValue($input, this.model.get(attrName)));
      } else {
        _results.push(void 0);
      }
    }
    return _results;
  };

  OutcomeCellEditView.prototype.acceptChangesConfirmation = function(acceptChangesDeferred) {
    var $listElement, attrName, attrValue, optionsList, splittedValue, studyTypeLabel;
    if (this.editType === OutcomeCellEditView.TYPES.SELECTION) {
      $listElement = this.getSelectedElement();
      optionsList = $listElement.parent();
      attrName = optionsList.attr('name');
      attrValue = $listElement.attr('modifier');
      if (this.model.get('type') === 'cont' && attrName === 'designStudies' && (attrValue === 'ObsStudies.case_control_studies' || attrValue === 'ObsStudies.case_control_and_other_combined')) {
        splittedValue = attrValue.split('.');
        studyTypeLabel = DS.get(splittedValue[0], splittedValue[1]).label;
        return mediator.dialogs.confirm($.t("es:outcome.design_study_changes.to_case_control", {
          'study_type': $.t("es:outcome." + studyTypeLabel)
        }), function(confirmed) {
          return acceptChangesDeferred.resolve(confirmed);
        });
      } else {
        return acceptChangesDeferred.resolve(true);
      }
    } else {
      return acceptChangesDeferred.resolve(true);
    }
  };

  OutcomeCellEditView.prototype.conditionalAccept = function() {
    var save;
    if (this.accepting) {
      return;
    }
    if (this.editType === OutcomeCellEditView.TYPES.SELECTION) {
      save = this.subview('footnotes') && !this.subview('footnotes').disposed;
      return this.hide(save);
    } else {
      return this.accept();
    }
  };

  OutcomeCellEditView.prototype.onFocusLost = function(focusTo) {
    if (!this.changingFocusToSubview(focusTo)) {
      return this.conditionalAccept();
    }
  };

  OutcomeCellEditView.prototype.beforeFocusLost = function(deferred, focusTo) {
    if (this.changingFocusToSubview(focusTo)) {
      return deferred.resolve(true);
    } else {
      if (this.model.isValid(true) && !this.defineStudyDesignSof) {
        return deferred.resolve();
      } else {
        return deferred.reject();
      }
    }
  };

  OutcomeCellEditView.prototype._prepareTemplateDataForAbsoluteEffect = function(data) {
    var cellContent, fewerMoreFn, overrideEffectValues, riskLevelSuffix;
    if (this.property !== 'absoluteEffect') {
      return;
    }
    riskLevelSuffix = this._riskLevelSuffix();
    data.riskLevelSuffix = riskLevelSuffix;
    cellContent = this.target.find('.cell-content');
    fewerMoreFn = (function(_this) {
      return function(effectAttr, suffix) {
        if (_this.model.get("" + effectAttr + suffix) >= 0) {
          return 'fewer';
        } else {
          return 'more';
        }
      };
    })(this);
    overrideEffectValues = (function(_this) {
      return function(riskLevelSuffix) {
        data.absEffectSize = _this.model.get("absEffectSize" + riskLevelSuffix);
        data.absEffectFrom = _this.model.get("absEffectFrom" + riskLevelSuffix);
        data.absEffectTo = _this.model.get("absEffectTo" + riskLevelSuffix);
        data.absEffectAutoCalc = _this.model.get("absEffectAutoCalc" + riskLevelSuffix);
        data.fewerMore = fewerMoreFn('absEffectSize', riskLevelSuffix);
        data.fewerMoreFrom = fewerMoreFn('absEffectFrom', riskLevelSuffix);
        data.fewerMoreTo = fewerMoreFn('absEffectTo', riskLevelSuffix);
        data.fewerMoreName = "fewerMore" + riskLevelSuffix;
        data.fewerMoreFromName = "fewerMoreFrom" + riskLevelSuffix;
        return data.fewerMoreToName = "fewerMoreTo" + riskLevelSuffix;
      };
    })(this);
    return overrideEffectValues(riskLevelSuffix);
  };

  OutcomeCellEditView.prototype.getTemplateData = function() {
    var checkForNaN, data, key, _base, _i, _len;
    data = OutcomeCellEditView.__super__.getTemplateData.apply(this, arguments);
    checkForNaN = ['absEffectSize', 'absEffectFrom', 'absEffectTo'];
    for (_i = 0, _len = checkForNaN.length; _i < _len; _i++) {
      key = checkForNaN[_i];
      if (isNaN(data[key])) {
        data[key] = '';
      }
    }
    _(data).extend({
      isCaseControl: typeof (_base = this.model).isCaseControl === "function" ? _base.isCaseControl() : void 0,
      viewTypeName: this.options.viewTypeName,
      isPopup: true,
      defineStudyDesignSof: this.defineStudyDesignSof,
      controlRiskLevel: this._controlRiskLevel()
    });
    this._prepareTemplateDataForAbsoluteEffect(data);
    data.isPrognostic = this.questionModel.isPrognostic();
    return data;
  };

  OutcomeCellEditView.prototype.compoundItemFocused = function(inputElement) {
    var editInputBlock, editView, _ref;
    editInputBlock = inputElement.closest('.edit-input-block');
    this.property = (_ref = editInputBlock.data('property')) != null ? _ref : editInputBlock.find('input, textarea').attr('name');
    return this.publishEvent('activatedProperty', this.property, inputElement, this.questionModel, this.model, editView = true);
  };

  OutcomeCellEditView.prototype.compoundSelectionIndexChanged = function(index) {
    var editInputBlock;
    editInputBlock = this.inputs.eq(index).closest('.edit-input-block');
    if (editInputBlock.find(this.inputsSelector).length > 1 && !editInputBlock.data('property')) {
      throw new Error('No property set for input block with more then one input');
    }
  };

  return OutcomeCellEditView;

})(EditView);
