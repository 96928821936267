ContentEditableWithButtons = require 'components/common/content_editable_with_buttons'
EtdActions = require 'actions/etd_actions'
PureRenderMixin = React.addons.PureRenderMixin

AdditionalConsideration = React.createClass
  displayName: 'AdditionalConsiderationComponent'
  mixins: [PureRenderMixin]

  render: ->
    <td rowSpan={@props.rowSpan}>
      <ContentEditableWithButtons
        actions={EtdActions}
        id={@props.cellId}
        content={@props.additionalConsiderations.get 'content'}
        currentEditable= {@props.currentEditable}
        ref="contentEditable"
      />
    </td>

module.exports = AdditionalConsideration
