var EtdTemplatesActions, alt, etdTemplateHelper, getEtdTemplateDocId, getEtdTemplateType, mediator;

alt = require('alt');

mediator = require('mediator');

etdTemplateHelper = require('stores/utils/etd_template_helper');

getEtdTemplateType = function(template) {
  var templateDefId;
  templateDefId = 'toJS' in template ? template.getIn(['templateDef', 'id']) : template.templateDef.id;
  if (_.string.startsWith(templateDefId, 'tx')) {
    return 'tx';
  } else {
    return 'dx';
  }
};

getEtdTemplateDocId = function(templateType) {
  return "etd-" + templateType + "-template";
};

EtdTemplatesActions = (function() {
  function EtdTemplatesActions() {
    this.generateActions('fetch', 'pouchFetchError', 'pouchSaveError', 'revertChanges', 'cancelEdit');
  }

  EtdTemplatesActions.prototype.pouchFetchSuccess = function(results) {
    var getTemplateDoc;
    getTemplateDoc = function(results, templateType) {
      var template;
      template = _(results.rows).findWhere({
        key: getEtdTemplateDocId(templateType)
      });
      if (template.error) {
        return null;
      } else {
        return template.doc;
      }
    };
    return this.dispatch({
      txTemplate: getTemplateDoc(results, 'tx'),
      dxTemplate: getTemplateDoc(results, 'dx')
    });
  };

  EtdTemplatesActions.prototype.pouchSaveSuccess = function(doc) {
    return this.dispatch({
      templateDoc: doc,
      templateType: getEtdTemplateType(doc)
    });
  };

  EtdTemplatesActions.prototype.prepareTemplate = function(templateOrTemplateId) {
    if (_.isString(templateOrTemplateId)) {
      return this.dispatch(etdTemplateHelper.prepareBaseTemplate(templateOrTemplateId));
    } else {
      return etdTemplateHelper.prepareTemplate(templateOrTemplateId.get('templateDef'), templateOrTemplateId.get('templateData'), true).withMutations(function(template) {
        template.set('_id', templateOrTemplateId.get('_id'));
        return template.set('_rev', templateOrTemplateId.get('_rev'));
      });
    }
  };

  EtdTemplatesActions.prototype.toggleSection = function(blockName, sectionId) {
    return this.dispatch({
      sectionId: sectionId,
      blockName: blockName
    });
  };

  EtdTemplatesActions.prototype.togglePresentationSection = function(presentationGroup, sectionId) {
    return this.dispatch({
      presentationGroup: presentationGroup,
      sectionId: sectionId
    });
  };

  EtdTemplatesActions.prototype.updateName = function(name) {
    return this.dispatch({
      name: name
    });
  };

  EtdTemplatesActions.prototype.useTemplate = function(templateDef, templateData) {
    var projectDbName, template;
    projectDbName = mediator.project.id;
    template = etdTemplateHelper.prepareTemplate(templateDef, templateData);
    template = template.set('_id', getEtdTemplateDocId(getEtdTemplateType(template)));
    return this.actions.save(projectDbName, template);
  };

  EtdTemplatesActions.prototype.editTemplate = function(templateType) {
    return this.dispatch({
      templateType: templateType
    });
  };

  EtdTemplatesActions.prototype.saveTemplate = function(template, templateType) {
    var projectDbName, templateData, templateDef;
    projectDbName = mediator.project.id;
    templateDef = template.get('templateDef');
    templateData = template.get('templateData');
    return mediator.services.storePersistenceAdapter.fetch(projectDbName, getEtdTemplateDocId(templateType)).then((function(_this) {
      return function(doc) {
        template = etdTemplateHelper.prepareTemplate(templateDef, templateData).withMutations(function(template) {
          template.set('_id', doc._id);
          return template.set('_rev', doc._rev);
        });
        return _this.actions.save(projectDbName, template);
      };
    })(this));
  };

  EtdTemplatesActions.prototype.save = function(projectDbName, template) {
    etdTemplateHelper.updateTemplatesInQuestions(projectDbName, template, getEtdTemplateType(template));
    return this.dispatch({
      projectDbName: projectDbName,
      template: template.toJS()
    });
  };

  return EtdTemplatesActions;

})();

module.exports = alt.createActions(EtdTemplatesActions);
