var W, getDb;

W = require('when');

getDb = function(cache, dbName) {
  var _ref;
  return (_ref = cache[dbName]) != null ? _ref : cache[dbName] = Pouch(dbName, {
    size: INITIAL_POUCHDB_SIZE
  });
};

_.namespace(module, function(require) {
  var clock, dbCache;
  clock = require('lib/services/clock_service');
  dbCache = {};
  return {
    save: function(dbName, doc) {
      var db, method;
      db = getDb(dbCache, dbName);
      method = doc._id ? 'put' : 'post';
      return db[method](doc).then(function(result) {
        doc._id = result.id;
        doc._rev = result.rev;
        return doc;
      })["catch"]((function(_this) {
        return function(e) {
          if (e.status === 409) {
            return _this.fetch(dbName, doc._id).then(function(res) {
              doc._rev = res._rev;
              return _this.save(dbName, doc);
            });
          } else {
            throw e;
          }
        };
      })(this));
    },
    fetch: function(dbName, ids, options) {
      var db;
      if (options == null) {
        options = {};
      }
      if (!(dbName && ids)) {
        throw new Error('Both dbName and doc ID(s) are required!');
      }
      db = getDb(dbCache, dbName);
      if (_(ids).isArray()) {
        _(options).extend({
          include_docs: true,
          keys: ids
        });
        return db.allDocs(options);
      } else {
        return db.get(ids, options);
      }
    },
    setupListening: function(dbName, ids, options, changeListener) {
      var db;
      db = getDb(dbCache, dbName);
      return db.changes({
        since: 'now',
        include_docs: true,
        continuous: true
      }).on('change', changeListener).on('error', function(error) {
        return console.error(error);
      });
    },
    putAttachment: function(dbName, docId, attachmentName) {},
    getAttachment: function(dbName, docId, attachmentName) {},
    removeAttachment: function(dbName, docId, attachmentName) {
      var db;
      db = getDb(dbCache, dbName);
      return db.get(docId).then(function(doc) {
        return db.removeAttachment(docId, attachmentName, doc._rev);
      });
    }
  };
});
