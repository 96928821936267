var SwitchesService, mediator,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

mediator = require('mediator');

module.exports = SwitchesService = (function() {
  SwitchesService.prototype._cache = {};

  function SwitchesService() {
    mediator.subscribe('sessionStarted', (function(_this) {
      return function() {
        return _this._cache = {};
      };
    })(this));
  }

  SwitchesService.prototype._checkSwitch = function(name) {
    var switches, _ref;
    if (_(this._cache).has(name)) {
      return this._cache[name];
    } else {
      switches = (_ref = mediator.user.get('switches')) != null ? _ref : [];
      return this._cache[name] = __indexOf.call(switches, '*') >= 0 || __indexOf.call(switches, name) >= 0;
    }
  };

  SwitchesService.prototype.isOn = function(name) {
    if (mediator.user != null) {
      return this._checkSwitch(name);
    } else {
      return false;
    }
  };

  return SwitchesService;

})();
