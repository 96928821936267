var DO, Outcome, Qualities, formatters, statisticalUtils, utils;

Qualities = require('models/outcome/qualities');

statisticalUtils = require('lib/statistical_utils');

DO = require('models/outcome/diagnostic_outcomes');

utils = require('base/lib/utils');

Outcome = require('models/outcome');

formatters = {
  _formatNulls: function(value) {
    if (!utils.isNumeric(value)) {
      return '--';
    } else {
      return value;
    }
  },
  _toFixedNumber: function(value, scale) {
    if (scale == null) {
      scale = 2;
    }
    return value = utils.isNumeric(value) ? utils.parseFixedFloat(value, scale) : value;
  },
  _getDesignStudy: function(ds) {
    var designStudy;
    if (!ds) {
      return '';
    }
    designStudy = ds.label !== 'randomised_trials' ? ds.value : 'randomised_trials';
    return designStudy = designStudy != null ? designStudy.replace('ObsStudies.', '') : void 0;
  },
  outcome: {
    _formatCerIfNaN: function(cer) {
      if (isNaN(cer)) {
        return 0;
      } else {
        return cer;
      }
    },
    qualityModifier: function(val) {
      if (val.label) {
        return $.t("es:outcome." + val.label);
      } else {
        return '';
      }
    },
    publicationBias: function(val) {
      if (!val) {
        return;
      }
      if ((val != null ? val.name : void 0) === 'Serious') {
        return $.t('es:outcome.publication_bias_strongly_suspected');
      } else {
        return $.t("es:outcome." + val.label);
      }
    },
    designStudies: function(_arg) {
      var count, designStudies, noOfStudies;
      designStudies = _arg.designStudies, noOfStudies = _arg.noOfStudies;
      count = !noOfStudies ? 0 : noOfStudies;
      if (designStudies.label) {
        return $.t("es:outcome." + designStudies.label, {
          count: count
        });
      } else {
        return '';
      }
    },
    importanceLabel: function(attributes) {
      return attributes[_(attributes).keys()[0]];
    },
    _followUpOrTimingWithLabel: function(attributes) {
      var followUp, _ref;
      if (!attributes.followUpLength || attributes.followUpLength === '0') {
        return;
      }
      followUp = '';
      if (((_ref = attributes.designStudies) != null ? _ref.value : void 0) === 'case_control_studies') {
        followUp = $.t("es:outcome.timing_of_exposure").toLowerCase() + ": ";
      } else {
        followUp = $.t("es:outcome.follow_up").toLowerCase() + ": ";
      }
      return followUp += this._followUpOrTiming(attributes);
    },
    _followUpOrTiming: function(attributes) {
      var followUp;
      if (!attributes.followUpLength) {
        return;
      }
      followUp = '';
      if (attributes.followUpType) {
        followUp += $.t("es:outcome." + attributes.followUpType);
      }
      followUp += " " + attributes.followUpLength;
      if (attributes.followUpTimeUnit === 'others') {
        if (attributes.othersFollowUpTimeUnit) {
          followUp += " " + attributes.othersFollowUpTimeUnit;
        }
      } else if (attributes.followUpTimeUnit) {
        followUp += " " + ($.t("es:outcome." + (_.string.underscored(attributes.followUpTimeUnit))));
      }
      if (attributes.followUpType !== 'range') {
        return followUp;
      }
      followUp += " " + ($.t("es:outcome.to"));
      if (attributes.followUpLengthTo) {
        followUp += " " + attributes.followUpLengthTo;
      }
      if (attributes.followUpToTimeUnit === 'others') {
        if (attributes.othersFollowUpToTimeUnit) {
          followUp += " " + attributes.othersFollowUpToTimeUnit;
        }
      } else if (attributes.followUpToTimeUnit) {
        followUp += " " + ($.t("es:outcome." + (_.string.underscored(attributes.followUpToTimeUnit))));
      }
      return followUp;
    },
    _scaleRange: function(attributes, viewType) {
      var scale;
      if (attributes.type !== 'cont') {
        return;
      }
      if (attributes.scaleFrom && attributes.scaleTo) {
        scale = '';
        if (viewType === 'one_row') {
          scale += "" + ($.t('es:outcome.range_of_scores').toLowerCase());
        } else {
          scale += "" + ($.t('es:outcome.scale'));
        }
        scale += " " + ($.t('es:outcome.from')) + ": " + attributes.scaleFrom + " " + ($.t('es:outcome.to')) + " " + attributes.scaleTo;
      }
      return scale;
    },
    _assessedWith: function(attributes) {
      if (attributes.measuredWith) {
        return "" + ($.t('es:outcome.assessed_with')) + ": " + attributes.measuredWith;
      } else {
        return '';
      }
    },
    label: function(attributes, viewType) {
      var assessedWith, defaultRows, followUp, inParenthesis, label, noOfParticipantsStudies, rows, scale, _ref;
      label = "" + attributes.name;
      if ((_ref = attributes._viewTypeName != null) === 'PrintoutSOF' || _ref === 'PrintoutSOFACCP') {
        label = "<b>" + label + "</b>";
      }
      if (attributes.metaType === 'NOT_MEASURED') {
        label += " - " + ($.t('es:outcome.not_measured'));
      } else if (attributes.metaType === 'NOT_REPORTED') {
        label += " - " + ($.t('es:outcome.not_reported'));
      } else {
        assessedWith = this._assessedWith(attributes);
        scale = this._scaleRange(attributes);
        followUp = this._followUpOrTimingWithLabel(attributes);
        defaultRows = [assessedWith, scale, followUp];
        if (viewType === 'one_row') {
          inParenthesis = _([followUp, assessedWith, scale]).filter(function(v) {
            return v && v.length;
          }).join('; ');
          if (inParenthesis) {
            label += " (" + inParenthesis + ")";
          }
        } else if (viewType === 'sof') {
          rows = _(defaultRows).filter(function(v) {
            return v && v.length;
          }).join('<br>');
          if (attributes.shortName) {
            label += " <span class='short-name'>(" + attributes.shortName + ")</span>";
          }
          if (rows.length) {
            label += "<br>" + rows;
          }
        } else if (viewType === 'sof_v3') {
          noOfParticipantsStudies = "" + ($.t('es:outcome.no_of_participants')) + ": " + (this.noOfParticipantsStudies(attributes));
          defaultRows.push(noOfParticipantsStudies);
          rows = _(defaultRows).filter(function(v) {
            return v && v.length;
          }).join('<br>');
          if (attributes.shortName) {
            label += " <span class='short-name'>(" + attributes.shortName + ")</span>";
          }
          if (rows.length) {
            label += "<br>" + rows;
          }
        } else {
          rows = _(defaultRows).filter(function(v) {
            return v && v.length;
          }).join('<br>');
          if (rows.length) {
            label += "<br>" + rows;
          }
        }
      }
      return "<span class='label outcome-name'>" + label + "</span>";
    },
    sofLabel: function(attributes) {
      return this.label(attributes, 'sof');
    },
    sofV3Label: function(attributes) {
      return this.label(attributes, 'sof_v3');
    },
    oneRowLabel: function(attributes) {
      return this.label(attributes, 'one_row');
    },
    sofRm5Label: function(attributes) {
      var sofLabel;
      sofLabel = this.sofLabel(attributes);
      return sofLabel.replace(/(<\/?\w+\b)(?:[^<>]*)(>)/g, function(m0, m1, m2) {
        if (m1.indexOf('<br') >= 0) {
          return m1.toUpperCase() + '/' + m2;
        } else {
          return '';
        }
      });
    },
    diagnosticLabel: function(attributes) {
      var diagnosticOutcome, info, label, _ref;
      diagnosticOutcome = DO.get(attributes.name);
      if ((_ref = diagnosticOutcome != null ? diagnosticOutcome.name : void 0) === 'TP' || _ref === 'FN' || _ref === 'TN' || _ref === 'FP') {
        label = $.t("es:outcome.diagnostic." + diagnosticOutcome.label);
        info = $.t("es:outcome.diagnostic." + (attributes.name.toLowerCase()) + "_label", {
          condition: attributes.targetCondition
        });
        return "<b>" + label + "</b> <br>(" + info + ")";
      } else if (diagnosticOutcome) {
        return label = $.t("es:outcome.diagnostic." + (DO.get(attributes.name).label));
      } else {
        return '';
      }
    },
    diagnosticEffect: function(attributes, name, prevalenceNo, forComparator) {
      var attrName, diagEffectFn, effect, effectFrom, effectTo, prevalence, rangeFrom, rangeTo, _ref, _ref1;
      if (forComparator == null) {
        forComparator = false;
      }
      if (name !== 'TP' && name !== 'FN' && name !== 'TN' && name !== 'FP') {
        return '';
      }
      diagEffectFn = statisticalUtils["diagnosticEffect" + name].bind(statisticalUtils);
      attrName = name === 'TP' || name === 'FN' ? 'pooledSensitivity' : 'pooledSpecificity';
      if (forComparator) {
        attrName += 'Comparison';
      }
      prevalence = attributes["prevalence" + prevalenceNo];
      if (attributes.sourceOfDxData === 'rangeFromStudies') {
        rangeFrom = diagEffectFn(attributes["" + attrName + "RangeFrom"], prevalence);
        rangeTo = diagEffectFn(attributes["" + attrName + "RangeTo"], prevalence);
        if (rangeFrom > rangeTo) {
          _ref = [rangeTo, rangeFrom], rangeFrom = _ref[0], rangeTo = _ref[1];
        }
        return "" + rangeFrom + " " + ($.t('es:outcome.to')) + " " + rangeTo;
      } else {
        effect = diagEffectFn(attributes["" + attrName], prevalence);
        effectFrom = diagEffectFn(attributes["" + attrName + "From"], prevalence);
        effectTo = diagEffectFn(attributes["" + attrName + "To"], prevalence);
        if (effectFrom > effectTo) {
          _ref1 = [effectTo, effectFrom], effectFrom = _ref1[0], effectTo = _ref1[1];
        }
        return "" + effect + " (" + effectFrom + " " + ($.t('es:outcome.to')) + " " + effectTo + ")";
      }
    },
    _fewerMoreText: function(value) {
      if (value < 0) {
        return $.t('es:outcome.more');
      } else {
        return $.t('es:outcome.fewer');
      }
    },
    _diagDiff: function(attributes, diagEffectFn, prevalence, attrNames) {
      var comparatorEffect, indexEffect;
      indexEffect = diagEffectFn(attributes[attrNames[1]], prevalence);
      comparatorEffect = diagEffectFn(attributes[attrNames[0]], prevalence);
      return indexEffect - comparatorEffect;
    },
    diagnosticAbsoluteDifference: function(attributes, name, prevalenceNo) {
      var diagEffectFn, diffAttrNames, diffFromAttrNames, diffToAttrNames, difference, differenceFrom, differenceTo, params, prevalence, sensitivityOrSpecificity;
      prevalence = attributes["prevalence" + prevalenceNo];
      if (name !== 'TP' && name !== 'FN' && name !== 'TN' && name !== 'FP') {
        return '';
      }
      sensitivityOrSpecificity = (function() {
        switch (name) {
          case 'TP':
          case 'FN':
            return 'Sensitivity';
          case 'TN':
          case 'FP':
            return 'Specificity';
        }
      })();
      diagEffectFn = statisticalUtils["diagnosticEffect" + name].bind(statisticalUtils);
      if (attributes.sourceOfDxData === 'rangeFromStudies') {
        diffFromAttrNames = ["pooled" + sensitivityOrSpecificity + "RangeFrom", "pooled" + sensitivityOrSpecificity + "ComparisonRangeFrom"];
        diffToAttrNames = ["pooled" + sensitivityOrSpecificity + "RangeTo", "pooled" + sensitivityOrSpecificity + "ComparisonRangeTo"];
        differenceFrom = this._diagDiff(attributes, diagEffectFn, prevalence, diffFromAttrNames);
        differenceTo = this._diagDiff(attributes, diagEffectFn, prevalence, diffToAttrNames);
        params = {
          outcome: $.t("es:outcome.diagnostic." + name + "_short"),
          testName: attributes.indexTest,
          differenceFromValue: Math.abs(differenceFrom),
          differenceToValue: Math.abs(differenceTo),
          fewerMoreFrom: this._fewerMoreText(differenceFrom),
          fewerMoreTo: this._fewerMoreText(differenceTo)
        };
        return $.t('es:outcome.diagnostic.absolute_difference_range_template', params);
      } else {
        diffAttrNames = ["pooled" + sensitivityOrSpecificity, "pooled" + sensitivityOrSpecificity + "Comparison"];
        difference = this._diagDiff(attributes, diagEffectFn, prevalence, diffAttrNames);
        params = {
          outcome: $.t("es:outcome.diagnostic." + name + "_short"),
          testName: attributes.indexTest,
          differenceValue: Math.abs(difference),
          fewerMore: this._fewerMoreText(difference)
        };
        return $.t('es:outcome.diagnostic.absolute_difference_template', params);
      }
    },
    quality: function(attributes) {
      var circleWithPlus, circles, emptyCircle, isPrintout, qm, qms, qualityVal, sd, translKey;
      qualityVal = '';
      if (attributes.quality) {
        if (attributes.rm5export) {
          circleWithPlus = '&#8853;';
          emptyCircle = '&#8861;';
        } else {
          circleWithPlus = '<span class="quality-sign">&#x2a01;</span>';
          emptyCircle = '<span class="quality-sign">&#x25ef;</span>';
        }
        circles = _.string.repeat(circleWithPlus, attributes.quality) + _.string.repeat(emptyCircle, 4 - attributes.quality);
        if (circles) {
          translKey = _.string.underscored(Qualities[attributes.quality].toLowerCase());
          qualityVal = circles + '<br>' + ($.t("es:outcome.qualities." + translKey)).toUpperCase();
        }
      } else {
        qms = (function() {
          var _i, _len, _ref, _results;
          _ref = Outcome.QUALITY_MODIFIERS;
          _results = [];
          for (_i = 0, _len = _ref.length; _i < _len; _i++) {
            qm = _ref[_i];
            _results.push(attributes[qm]);
          }
          return _results;
        })();
        isPrintout = function() {
          var _ref;
          return ((_ref = attributes._viewTypeName) != null ? _ref.toLowerCase().indexOf('printout') : void 0) !== -1;
        };
        if (Outcome.areQualityModifiersSet(qms) && !isPrintout()) {
          sd = ($.t('es:outcome.study_design')).replace(' ', '&nbsp;');
          sd = "<span class='salmon-bg'>" + sd + "</span>";
          qualityVal = $.t('es:outcome.messages.quality_parameter_not_defined', {
            param: sd
          });
        }
      }
      return qualityVal;
    },
    controlRatio: function(attributes) {
      var ratio;
      ratio = (attributes.controlCount / attributes.controlTotal) * 100;
      if (isNaN(ratio)) {
        return '-';
      } else {
        return ratio.toFixed(1);
      }
    },
    _numberOrDash: function(value) {
      if (_(value).isNumber()) {
        return value;
      } else {
        return '-';
      }
    },
    _interventionsAndComparisions: function(attributes, interventionOrControl) {
      var icCount, icTotal, interventionsOrComparisions, percent;
      icCount = attributes["" + interventionOrControl + "Count"];
      icTotal = attributes["" + interventionOrControl + "Total"];
      interventionsOrComparisions = "";
      switch (attributes.type) {
        case 'dich':
        case 'narrative':
          interventionsOrComparisions += _(icCount).isNumber() || _(icTotal).isNumber() ? "" + (this._numberOrDash(icCount)) + "/" + (this._numberOrDash(icTotal)) : '';
          if (icTotal > 0 && _(icCount).isNumber()) {
            percent = ((icCount / icTotal) * 100).toFixed(1);
            if (attributes.effectMeasure !== 'rateRatio') {
              interventionsOrComparisions += " (" + percent + "%) ";
            }
          }
          break;
        case 'cont':
          interventionsOrComparisions += "" + icTotal;
      }
      return interventionsOrComparisions;
    },
    intervention: function(attributes) {
      return this._interventionsAndComparisions(attributes, 'intervention');
    },
    calculatedControlLabel: function(attributes) {
      return $.t('es:outcome.printout.study_population');
    },
    lowControlLabel: function(attributes) {
      return attributes.lowControlLabel;
    },
    moderateControlLabel: function(attributes) {
      return attributes.moderateControlLabel;
    },
    highControlLabel: function(attributes) {
      return attributes.highControlLabel;
    },
    control: function(attributes) {
      var _ref, _ref1;
      if ((_ref = (_ref1 = attributes._viewTypeName) != null ? _ref1.toLowerCase() : void 0) === 'sof' || _ref === 'accp' || _ref === "sof_v3" || _ref === 'printoutsofv3' || _ref === 'printoutsof' || _ref === 'sof_accp' || _ref === 'printoutsofaccp' || _ref === 'printoutgradeaccp') {
        return this.sofControl(attributes);
      } else {
        return this._interventionsAndComparisions(attributes, 'control');
      }
    },
    _formatAbsoluteDenominator: function(value) {
      if ($.isNumeric(value)) {
        return value;
      } else {
        return '--';
      }
    },
    _formatDichSofControl: function(risk, absDenominator, outputDenominator) {
      var value;
      if (outputDenominator == null) {
        outputDenominator = true;
      }
      if (isNaN(risk)) {
        risk = 0;
      }
      value = $.isNumeric(absDenominator) ? (risk * absDenominator).toFixed(0) : this._formatAbsoluteDenominator(absDenominator);
      if (outputDenominator) {
        return value += " " + ($.t('es:outcome.per')) + " " + (this._formatAbsoluteDenominator(absDenominator));
      }
    },
    _dichSofControl: function(attributes, outputDenominator) {
      var risk;
      if (outputDenominator == null) {
        outputDenominator = true;
      }
      risk = attributes.controlCount / attributes.controlTotal;
      return this._formatDichSofControl(risk, attributes.absDenominator, outputDenominator);
    },
    _dichSofControlPercent: function(attributes, outputDenominator) {
      var risk;
      if (outputDenominator == null) {
        outputDenominator = false;
      }
      risk = attributes.controlCount / attributes.controlTotal;
      if (isNaN(risk)) {
        risk = 0;
      }
      return this.percentValue(this._convertToPercentValue(risk));
    },
    _contSofControl: function(attributes) {
      var contSofControl, _ref;
      contSofControl = "" + ($.t('es:outcome.the_mean')) + " " + (utils.uncapitalizeExceptAllCaps(attributes.name)) + " ";
      if (((_ref = attributes._viewTypeName) != null ? _ref.toLowerCase() : void 0) === "sof_v3") {
        contSofControl += ("" + ($.t('es:outcome.without'))).toLowerCase();
        contSofControl += (" " + attributes.intervention + " ").toLowerCase();
      }
      if (attributes.changeScoreInControls === 'range') {
        contSofControl += "" + ($.t('es:outcome.ranged')) + " " + ($.t('es:outcome.from')) + " ";
      } else {
        contSofControl += "" + ($.t('es:outcome.was')) + " ";
      }
      contSofControl += "<b>";
      if (attributes.changeScoreInControlsValue) {
        contSofControl += "" + attributes.changeScoreInControlsValue;
      } else {
        contSofControl += 0;
      }
      contSofControl += "</b>";
      if (attributes.units) {
        contSofControl += " " + attributes.units;
      }
      return "<span class='cell-value pt8'>" + contSofControl + "</span>";
    },
    lowControlRisk: function(attributes) {
      var risk, value, _ref, _ref1;
      value = '';
      if ((_ref = (_ref1 = attributes._viewTypeName) != null ? _ref1.toLowerCase() : void 0) === 'sof' || _ref === 'accp' || _ref === 'printoutsof' || _ref === 'sof_accp' || _ref === 'printoutsofaccp' || _ref === 'printoutgradeaccp') {
        risk = attributes.lowControlRisk / 100;
        return value += this._formatDichSofControl(risk, attributes.absDenominator);
      } else {
        return this.percentValue(attributes.lowControlRisk);
      }
    },
    moderateControlRisk: function(attributes) {
      var risk, value, _ref, _ref1;
      value = '';
      if ((_ref = (_ref1 = attributes._viewTypeName) != null ? _ref1.toLowerCase() : void 0) === 'sof' || _ref === 'accp' || _ref === 'printoutsof' || _ref === 'sof_accp' || _ref === 'printoutsofaccp' || _ref === 'printoutgradeaccp') {
        risk = attributes.moderateControlRisk / 100;
        return value += this._formatDichSofControl(risk, attributes.absDenominator);
      } else {
        return this.percentValue(attributes.moderateControlRisk);
      }
    },
    highControlRisk: function(attributes) {
      var risk, value, _ref, _ref1;
      value = '';
      if ((_ref = (_ref1 = attributes._viewTypeName) != null ? _ref1.toLowerCase() : void 0) === 'sof' || _ref === 'accp' || _ref === 'printoutsof' || _ref === 'sof_accp' || _ref === 'printoutsofaccp' || _ref === 'printoutgradeaccp') {
        risk = attributes.highControlRisk / 100;
        return value += this._formatDichSofControl(risk, attributes.absDenominator);
      } else {
        return this.percentValue(attributes.highControlRisk);
      }
    },
    sofControl: function(attributes) {
      var _ref;
      if (attributes.type === 'dich') {
        if (((_ref = attributes._viewTypeName) != null ? _ref.toLowerCase() : void 0) === "sof_v3") {
          return this._dichSofControlPercent(attributes);
        } else {
          return this._dichSofControl(attributes);
        }
      } else if (attributes.type === 'cont') {
        return this._contSofControl(attributes);
      }
    },
    recommendationControl: function(attributes, denominator) {
      var _ref;
      if ((_ref = attributes.metaType) === 'NOT_MEASURED' || _ref === 'NOT_REPORTED') {
        return '-';
      } else if (attributes.type !== 'cont' && denominator) {
        return this._dichSofControl(_.extend(_(attributes).clone(), {
          absDenominator: denominator
        }), false);
      } else {
        return this.sofControl(attributes);
      }
    },
    recommendationIntervention: function(attributes, denominator) {
      var a, cer, _ref;
      if ((_ref = attributes.metaType) === 'NOT_MEASURED' || _ref === 'NOT_REPORTED') {
        return '-';
      } else if (attributes.type !== 'cont' && denominator) {
        cer = this._formatCerIfNaN(attributes.controlCount / attributes.controlTotal);
        a = attributes;
        return this._dichCorrespondingRisk(cer, a.effectSize, a.effectMeasure, a.confidenceIntervalFrom, a.confidenceIntervalTo, denominator, false);
      } else {
        return this.correspondingRisk(attributes);
      }
    },
    otherConsiderations: function(attributes) {
      var value, values, _ref, _ref1, _ref2, _ref3, _ref4, _ref5, _ref6;
      values = [];
      if (((_ref = attributes.publicationBias) != null ? _ref.name : void 0) === 'Serious') {
        values.push($.t('es:outcome.publication_bias_strongly_suspected'));
      }
      if (((_ref1 = attributes.largeEffect) != null ? _ref1.score : void 0) > 0) {
        values.push($.t("es:outcome." + attributes.largeEffect.label));
      }
      if ((_ref2 = (_ref3 = (_ref4 = attributes.plausibleConfounding) != null ? _ref4.name : void 0) != null ? _ref3 : 'None') !== 'None' && _ref2 !== 'No') {
        values.push($.t("es:outcome." + attributes.plausibleConfounding.label + "_long"));
      }
      if ((_ref5 = (_ref6 = attributes.doseResponseGradient) != null ? _ref6.name : void 0) === 'Large' || _ref5 === 'Yes') {
        values.push($.t('es:outcome.dose_response_gradient'));
      }
      value = values.join('<br>');
      if (attributes.publicationBias && !value) {
        value = $.t('es:outcome.none');
      }
      return value.toLowerCase();
    },
    _isRelativeEffectEstimable: function(attributes) {
      return attributes.effectMeasure && (attributes.effectSize || attributes.confidenceIntervalFrom || attributes.confidenceIntervalTo);
    },
    _getFixedScaleForRelativeEffect: function(es, cif, cit) {
      var scales;
      scales = _(["" + es, "" + cif, "" + cit]).map(function(v) {
        var parts;
        parts = v.split('.');
        if (parts[1]) {
          return parts[1].length;
        } else {
          return 0;
        }
      });
      return Math.max.apply(Math, scales);
    },
    relativeEffect: function(attributes) {
      var cif, cit, effectM, es, isRangeOfEffects, relEffect, scale, translatedEM;
      isRangeOfEffects = attributes.metaType === 'RANGE_OF_EFFECTS';
      es = parseFloat(attributes.effectSize);
      cif = parseFloat(attributes.confidenceIntervalFrom);
      cit = parseFloat(attributes.confidenceIntervalTo);
      scale = this._getFixedScaleForRelativeEffect(es, cif, cit);
      relEffect = '-';
      if (attributes.type === 'dich') {
        if (!this._isRelativeEffectEstimable(attributes)) {
          relEffect = $.t('es:outcome.not_estimable');
        } else if (attributes.metaType === 'NOT_POOLED') {
          relEffect = $.t('es:outcome.not_pooled');
        } else {
          relEffect = "<span class='block'>";
          relEffect += "<b>";
          effectM = attributes.effectMeasure;
          translatedEM = $.t("es:outcome.measure." + (_.string.underscored(effectM)));
          relEffect += "" + (effectM === 'other' ? attributes.otherMeasure : translatedEM) + " ";
          if (isRangeOfEffects) {
            relEffect += "" + ($.t('es:outcome.ranged')) + " " + ($.t('es:outcome.from')) + " ";
          } else {
            relEffect += formatters._toFixedNumber(formatters._formatNulls(es), scale);
          }
          relEffect += '</b></span><br>';
          if (!isRangeOfEffects) {
            relEffect += '(';
          }
          relEffect += formatters._toFixedNumber(formatters._formatNulls(cif), scale);
          relEffect += " " + ($.t("es:outcome.to")) + " ";
          relEffect += formatters._toFixedNumber(formatters._formatNulls(cit), scale);
          if (!isRangeOfEffects) {
            relEffect += ')';
          }
        }
      }
      return "<span class='cell'>" + relEffect + "</span>";
    },
    percentValue: function(value) {
      value = formatters._toFixedNumber(value, 1);
      return "" + value + "%";
    },
    _convertToPercentValue: function(value) {
      return formatters._toFixedNumber((value *= 100), 1);
    },
    _attrWithOtherValue: function(attributes, attrName, otherAttrName) {
      var _ref;
      if ((_ref = attributes[attrName]) === 'other' || _ref === 'others') {
        return attributes[otherAttrName];
      } else {
        return attributes[attrName];
      }
    },
    _formatFewerMore: function(value, percent, scale) {
      var fewerMore;
      if (percent == null) {
        percent = false;
      }
      if (scale == null) {
        scale = 0;
      }
      if ($.isNumeric(value)) {
        fewerMore = value < 0 ? $.t('es:outcome.more') : $.t('es:outcome.fewer');
        value = formatters._toFixedNumber(Math.abs(value), scale);
        if (percent) {
          value = this.percentValue(value);
        }
        return "" + value + " " + fewerMore;
      } else {
        return formatters._formatNulls(value);
      }
    },
    _dichAbsoluteEffect: function(attributes, defaultCerName) {
      var perValue, value;
      if (defaultCerName == null) {
        defaultCerName = '';
      }
      perValue = (function(_this) {
        return function(attributes) {
          if (attributes.effectMeasure === 'rateRatio') {
            return "" + ($.t("es:outcome.per")) + " " + (_this._attrWithOtherValue(attributes, 'perPatients', 'perPatientsOther')) + " " + ($.t("es:outcome.patient_s")) + " " + ($.t("es:outcome.per")) + " " + (_this._attrWithOtherValue(attributes, 'perTimeFrame', 'perTimeFrameOther')) + " ";
          } else {
            return "" + ($.t("es:outcome.per")) + " " + (_this._formatAbsoluteDenominator(attributes.absDenominator));
          }
        };
      })(this);
      if (isNaN(attributes["absEffectSize" + defaultCerName])) {
        return '--';
      } else if (this._isRelativeEffectEstimable(attributes) || (!attributes.absEffectAutoCalc && !defaultCerName)) {
        value = this._formatFewerMore(attributes["absEffectSize" + defaultCerName]);
        value += ' ';
        value += perValue(attributes);
        value += " (" + ($.t("es:outcome.from")) + " ";
        value += this._formatFewerMore(attributes["absEffectFrom" + defaultCerName]);
        value += " " + ($.t("es:outcome.to")) + " ";
        value += this._formatFewerMore(attributes["absEffectTo" + defaultCerName]);
        return value += ')';
      } else {
        return '';
      }
    },
    _contAbsoluteEffect: function(attributes) {
      var cif, cit, countable, effectMeasure, effectSize, value;
      effectSize = attributes.effectSize;
      cif = attributes.confidenceIntervalFrom;
      cit = attributes.confidenceIntervalTo;
      effectMeasure = attributes.effectMeasure;
      countable = attributes.unitsType === "countable";
      value = "<span class='cell-value'>";
      value += "" + (this._attrWithOtherValue(attributes, 'effectMeasure', 'otherMeasure')) + " ";
      value += "<b>" + (Math.abs(effectSize)) + " ";
      if (attributes.units !== '') {
        value += "" + attributes.units + " ";
      }
      value += this._translateCountableUnitsType(attributes.effectSize, countable);
      value += "</b><br>(" + (Math.abs(cif)) + " ";
      value += this._translateCountableUnitsType(cif, countable);
      value += ' ';
      value += $.t("es:outcome.to");
      value += " " + (Math.abs(cit)) + " ";
      value += this._translateCountableUnitsType(cit, countable);
      value += ')';
      value += '</span>';
      return value;
    },
    _translateCountableUnitsType: function(value, countable) {
      var translation;
      if (!value) {
        return '';
      }
      translation = countable ? value >= 0 ? 'higher' : 'lower' : value >= 0 ? 'more' : 'fewer';
      return $.t("es:outcome." + translation);
    },
    absoluteEffect: function(attributes) {
      if (attributes.type === 'dich') {
        return this._dichAbsoluteEffect(attributes);
      } else {
        return this._contAbsoluteEffect(attributes);
      }
    },
    absoluteEffectLow: function(attributes) {
      return this._dichAbsoluteEffect(attributes, 'Low');
    },
    absoluteEffectModerate: function(attributes) {
      return this._dichAbsoluteEffect(attributes, 'Moderate');
    },
    absoluteEffectHigh: function(attributes) {
      return this._dichAbsoluteEffect(attributes, 'High');
    },
    caseControl: function(attributes) {
      var caseControl;
      caseControl = "" + attributes.withOutcome + " " + ($.t('es:outcome.cases')) + " " + attributes.withoutOutcome + " " + ($.t('es:outcome.controls'));
      if (attributes.designStudies.value === 'case_control_and_other_combined') {
        caseControl += " " + attributes.interventionCount + "/" + attributes.interventionTotal + " " + ($.t('es:outcome.exposed')) + " " + attributes.controlCount + "/" + attributes.controlTotal + " " + ($.t('es:outcome.unexposed'));
      }
      return caseControl;
    },
    noOfParticipantsStudies: function(attributes) {
      var participants, studies;
      studies = this._formatDesignStudiesForACCPandSoF(attributes.noOfStudies, attributes.designStudies);
      participants = this._noOfParticipantsStudies(attributes);
      return _([participants, studies]).compact().join('<br>');
    },
    _noOfParticipantsStudies: function(attributes) {
      var designStudy, participants;
      designStudy = formatters._getDesignStudy(attributes.designStudies);
      if (designStudy === 'case_control_studies' || designStudy === 'case_control_and_other_combined') {
        return participants = this.caseControl(attributes);
      } else {
        return participants = Number(attributes.interventionTotal) + Number(attributes.controlTotal);
      }
    },
    noOfStudiesPatients: function(attributes) {
      var value;
      value = "" + attributes.noOfStudies + " " + ($.t('es:outcome.studies'));
      return value += "<br>" + attributes.noOfPatients + " " + ($.t('es:outcome.patients'));
    },
    noOfParticipantsStudiesDiagnostic: function(attributes) {
      var noOfStudies, _ref;
      if ((_ref = attributes.noOfStudies) != null ? _ref.toString().length : void 0) {
        noOfStudies = "(" + attributes.noOfStudies + ")";
      }
      return _([attributes.noOfPatients, noOfStudies]).compact().join('<br>');
    },
    _multiplyByDenominator: function(cr, absDenominator) {
      if ($.isNumeric(absDenominator)) {
        return (cr * absDenominator).toFixed(0);
      } else {
        return '-';
      }
    },
    _dichCorrespondingRisk: function(cer, effectSize, effectMeasure, confidenceIntervalFrom, confidenceIntervalTo, absDenominator, outputDenominator) {
      var cr;
      if (outputDenominator == null) {
        outputDenominator = true;
      }
      cr = ' <b>';
      cr += this._multiplyByDenominator(statisticalUtils.calculateCorrespondingRisk(cer, effectSize, effectMeasure), absDenominator);
      if (outputDenominator) {
        cr += " " + ($.t('es:outcome.per')) + " " + (this._formatAbsoluteDenominator(absDenominator));
      }
      cr += '</b><br>(';
      cr += this._multiplyByDenominator(statisticalUtils.calculateCorrespondingRisk(cer, confidenceIntervalFrom, effectMeasure), absDenominator);
      cr += " " + ($.t('es:outcome.to')) + " ";
      cr += this._multiplyByDenominator(statisticalUtils.calculateCorrespondingRisk(cer, confidenceIntervalTo, effectMeasure), absDenominator);
      cr += ')';
      return "<span class='cell-value'>" + cr + "</span>";
    },
    _dichCorrespondingRiskPercent: function(cer, effectSize, effectMeasure, confidenceIntervalFrom, confidenceIntervalTo, absDenominator) {
      var cr;
      cr = ' <b>';
      cr += this.percentValue(this._convertToPercentValue(statisticalUtils.calculateCorrespondingRisk(cer, effectSize, effectMeasure), absDenominator));
      cr += '</b><br>(';
      cr += this._convertToPercentValue(statisticalUtils.calculateCorrespondingRisk(cer, confidenceIntervalFrom, effectMeasure), absDenominator);
      cr += " " + ($.t('es:outcome.to')) + " ";
      cr += this._convertToPercentValue(statisticalUtils.calculateCorrespondingRisk(cer, confidenceIntervalTo, effectMeasure), absDenominator);
      cr += ')';
      return "<span class='cell-value'>" + cr + "</span>";
    },
    _translateEffectMeasure: function(effectMeasure, otherMeasure) {
      if (effectMeasure === 'other') {
        return otherMeasure;
      } else {
        switch (effectMeasure) {
          case 'SMD':
            return $.t('es:outcome.measure.translated.SMD');
          default:
            return '';
        }
      }
    },
    _contCorrespondingRisk: function(attributes) {
      var cif, cit, countable, effectSize, value;
      effectSize = attributes.effectSize;
      cif = attributes.confidenceIntervalFrom;
      cit = attributes.confidenceIntervalTo;
      countable = attributes.unitsType === "countable";
      value = "" + ($.t('es:outcome.the_mean')) + " " + (utils.uncapitalizeExceptAllCaps(attributes.name)) + " ";
      value += "" + ($.t('es:outcome.in_intervention_group_was')) + " ";
      value += "" + (Math.abs(effectSize)) + " ";
      value += "" + (this._translateEffectMeasure(attributes.effectMeasure, attributes.otherMeasure)) + " ";
      if (attributes.units !== "") {
        value += " " + attributes.units + " ";
      }
      value += this._translateCountableUnitsType(effectSize, countable);
      value += " (" + (Math.abs(cif)) + " ";
      value += this._translateCountableUnitsType(cif, countable);
      value += ' ';
      value += $.t("es:outcome.to");
      value += " " + (Math.abs(cit)) + " ";
      value += this._translateCountableUnitsType(cit, countable);
      value += ')';
      return "<span class='cell-value pt8'>" + value + "</span>";
    },
    dichCorrespondingRiskEditInfo: function(attributes, controlRiskLevel) {
      var a, cer, cr, from, to, val, value, _ref;
      a = attributes;
      cer = this._formatCerIfNaN((function() {
        switch (controlRiskLevel) {
          case 'calculatedControl':
            return statisticalUtils.getControlEventRate(attributes);
          default:
            return a["" + controlRiskLevel + "Risk"] / 100;
        }
      })());
      _ref = (function() {
        var _i, _len, _ref, _results;
        _ref = [a.effectSize, a.confidenceIntervalFrom, a.confidenceIntervalTo];
        _results = [];
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          val = _ref[_i];
          _results.push(this._multiplyByDenominator(statisticalUtils.calculateCorrespondingRisk(cer, val, a.effectMeasure), a.absDenominator));
        }
        return _results;
      }).call(this), cr = _ref[0], from = _ref[1], to = _ref[2];
      value = _.string.capitalize($.t('es:outcome.corresponding_risk'));
      if (a.viewTypeName === "SOF" && a.metaType === "RANGE_OF_EFFECTS") {
        return value += " " + this._crRangeOfEffectSof(cer, a.effectSize, a.effectMeasure, a.confidenceIntervalFrom, a.confidenceIntervalTo, a.absDenominator);
      } else {
        return value += (" <b>" + cr + "</b> " + ($.t('es:outcome.per')) + " <b>" + a.absDenominator + "</b> " + ($.t('es:outcome.with')) + " <b>" + from + "</b> " + ($.t('es:outcome.to')) + " <b>" + to + "</b> ").toLowerCase() + $.t('es:outcome.confidence_interval');
      }
    },
    _crRangeOfEffectSof: function(cer, effectSize, effectMeasure, confidenceIntervalFrom, confidenceIntervalTo, absDenominator) {
      var cr;
      cr = "" + ($.t('es:outcome.ranged')) + " " + ($.t('es:outcome.from')) + " ";
      cr += this._multiplyByDenominator(statisticalUtils.calculateCorrespondingRisk(cer, confidenceIntervalFrom, effectMeasure), absDenominator);
      cr += " " + ($.t('es:outcome.to')) + " ";
      cr += this._multiplyByDenominator(statisticalUtils.calculateCorrespondingRisk(cer, confidenceIntervalTo, effectMeasure), absDenominator);
      return "<span class='cell-value'>" + cr + "</span>";
    },
    correspondingRisk: function(attributes) {
      var a, cer;
      if (attributes.type === 'dich') {
        cer = this._formatCerIfNaN(attributes.controlCount / attributes.controlTotal);
        a = attributes;
        if (a._viewTypeName.toLowerCase() === "sof" && a.metaType === "RANGE_OF_EFFECTS") {
          return this._crRangeOfEffectSof(cer, a.effectSize, a.effectMeasure, a.confidenceIntervalFrom, a.confidenceIntervalTo, a.absDenominator);
        } else if (a._viewTypeName.toLowerCase() === "sof_v3") {
          return this._dichCorrespondingRiskPercent(cer, a.effectSize, a.effectMeasure, a.confidenceIntervalFrom, a.confidenceIntervalTo, a.absDenominator);
        } else {
          return this._dichCorrespondingRisk(cer, a.effectSize, a.effectMeasure, a.confidenceIntervalFrom, a.confidenceIntervalTo, a.absDenominator);
        }
      } else {
        return this._contCorrespondingRisk(attributes);
      }
    },
    _dichCorrespondingRiskForLevel: function(riskLevelValue, attributes) {
      var a, cer;
      a = attributes;
      cer = riskLevelValue / 100;
      if (a._viewTypeName.toLowerCase() === "sof" && a.metaType === "RANGE_OF_EFFECTS") {
        return this._crRangeOfEffectSof(cer, a.effectSize, a.effectMeasure, a.confidenceIntervalFrom, a.confidenceIntervalTo, a.absDenominator);
      } else if (a._viewTypeName.toLowerCase() === "sof_v3") {
        return this._dichCorrespondingRiskPercent(cer, a.effectSize, a.effectMeasure, a.confidenceIntervalFrom, a.confidenceIntervalTo, a.absDenominator);
      } else {
        return this._dichCorrespondingRisk(cer, a.effectSize, a.effectMeasure, a.confidenceIntervalFrom, a.confidenceIntervalTo, a.absDenominator);
      }
    },
    correspondingRiskLow: function(attributes) {
      var value;
      value = this._dichCorrespondingRiskForLevel(attributes.lowControlRisk, attributes);
      return value = "" + value;
    },
    correspondingRiskModerate: function(attributes) {
      var value;
      value = this._dichCorrespondingRiskForLevel(attributes.moderateControlRisk, attributes);
      return value = "" + value;
    },
    correspondingRiskHigh: function(attributes) {
      var value;
      value = this._dichCorrespondingRiskForLevel(attributes.highControlRisk, attributes);
      return value = "" + value;
    },
    _dichRiskDifference: function(cer, effectSize, effectMeasure, confidenceIntervalFrom, confidenceIntervalTo, absDenominator) {
      var rd;
      rd = '<b>';
      rd += this._formatFewerMore(this._multiplyByDenominator(statisticalUtils.calculateRiskDifference(cer, effectSize, effectMeasure), absDenominator));
      rd += " " + ($.t('es:outcome.per'));
      rd += " " + (this._formatAbsoluteDenominator(absDenominator));
      rd += '</b><br>(';
      rd += this._formatFewerMore(this._multiplyByDenominator(statisticalUtils.calculateRiskDifference(cer, confidenceIntervalFrom, effectMeasure), absDenominator));
      rd += " " + ($.t('es:outcome.to')) + " ";
      rd += this._formatFewerMore(this._multiplyByDenominator(statisticalUtils.calculateRiskDifference(cer, confidenceIntervalTo, effectMeasure), absDenominator));
      rd += ')';
      return "<span class='cell-value'>" + rd + "</span>";
    },
    _dichRiskDifferencePercent: function(cer, effectSize, effectMeasure, confidenceIntervalFrom, confidenceIntervalTo) {
      var rd, scale;
      scale = 1;
      rd = '<b>';
      rd += this._formatFewerMore(this._convertToPercentValue(statisticalUtils.calculateRiskDifference(cer, effectSize, effectMeasure)), true, scale);
      rd += '</b><br>(';
      rd += this._formatFewerMore(this._convertToPercentValue(statisticalUtils.calculateRiskDifference(cer, confidenceIntervalFrom, effectMeasure)), false, scale);
      rd += " " + ($.t('es:outcome.to')) + " ";
      rd += this._formatFewerMore(this._convertToPercentValue(statisticalUtils.calculateRiskDifference(cer, confidenceIntervalTo, effectMeasure)), false, scale);
      rd += ')';
      return "<span class='cell-value'>" + rd + "</span>";
    },
    riskDifference: function(attributes) {
      var a, cer, _ref;
      if (attributes.type === 'dich') {
        cer = this._formatCerIfNaN(attributes.controlCount / attributes.controlTotal);
        a = attributes;
        if (((_ref = attributes._viewTypeName) != null ? _ref.toLowerCase() : void 0) === 'sof_v3') {
          return this._dichRiskDifferencePercent(cer, a.effectSize, a.effectMeasure, a.confidenceIntervalFrom, a.confidenceIntervalTo);
        } else {
          return this._dichRiskDifference(cer, a.effectSize, a.effectMeasure, a.confidenceIntervalFrom, a.confidenceIntervalTo, a.absDenominator);
        }
      } else {
        return this._contAbsoluteEffect(attributes);
      }
    },
    _dichRiskDifferenceForLevel: function(riskLevelValue, attributes) {
      var a, cer;
      a = attributes;
      cer = riskLevelValue / 100;
      if (a._viewTypeName.toLowerCase() === "sof_v3") {
        return this._dichRiskDifferencePercent(cer, a.effectSize, a.effectMeasure, a.confidenceIntervalFrom, a.confidenceIntervalTo);
      } else {
        return this._dichRiskDifference(cer, a.effectSize, a.effectMeasure, a.confidenceIntervalFrom, a.confidenceIntervalTo, a.absDenominator);
      }
    },
    riskDifferenceLow: function(attributes) {
      var value;
      value = this._dichRiskDifferenceForLevel(attributes.lowControlRisk, attributes);
      return "" + value;
    },
    riskDifferenceModerate: function(attributes) {
      var value;
      value = this._dichRiskDifferenceForLevel(attributes.moderateControlRisk, attributes);
      return "" + value;
    },
    riskDifferenceHigh: function(attributes) {
      var value;
      value = this._dichRiskDifferenceForLevel(attributes.highControlRisk, attributes);
      return "" + value;
    },
    noOfParticipantsStudiesFollowUp: function(attributes) {
      var designStudy, participants, studies;
      designStudy = formatters._getDesignStudy(attributes.designStudies);
      studies = this._formatDesignStudiesForACCPandSoF(attributes.noOfStudies, attributes.designStudies);
      participants = attributes._viewTypeName === 'ACCP' && (designStudy === 'case_control_studies' || designStudy === 'case_control_and_other_combined') ? '' : "" + (this._noOfParticipantsStudies(attributes)) + "<br>";
      participants += studies;
      if (attributes._viewTypeName !== "ACCP") {
        return participants;
      }
      if (attributes.followUpLength && attributes.followUpTimeUnit) {
        participants += "<br>" + (this._followUpOrTiming(attributes));
      }
      return participants;
    },
    _formatDesignStudiesForACCPandSoF: function(noOfStudies, designStudies) {
      var count, label, labelKey;
      if (designStudies == null) {
        designStudies = '';
      }
      labelKey = !designStudies.name ? 'study' : designStudies.name === 'RandTrials' ? 'rct' : designStudies.name === 'ObsStudies' ? 'observational_studies' : void 0;
      count = !noOfStudies ? 0 : noOfStudies;
      label = $.t('es:outcome.' + labelKey, {
        count: count
      });
      return "(" + noOfStudies + " " + label + ")";
    },
    eventRatesWithControl: function(attributes) {
      return this._interventionsAndComparisions(attributes, 'control');
    },
    eventRatesWithIntervention: function(attributes) {
      return this._interventionsAndComparisions(attributes, 'intervention');
    },
    effectCount: function(attributes) {
      return attributes.effectCount;
    },
    effectTotal: function(attributes) {
      return attributes.effectTotal;
    },
    effectRate: function(attributes) {
      if (!attributes.effectRate) {
        return "";
      }
      if (attributes.type === 'dich') {
        return this._dichEffectRate(attributes);
      } else if (attributes.type === 'cont') {
        return this._contEffectRate(attributes);
      }
    },
    _contEffectRate: function(attributes) {
      var val;
      if (!attributes.effectMeasure) {
        return "";
      }
      val = "" + (this._attrWithOtherValue(attributes, 'effectMeasure', 'otherMeasure')) + " ";
      val += attributes.effectRate;
      val += " " + attributes.units;
      val += " (";
      val += formatters._formatNulls(attributes.confidenceIntervalFrom);
      val += " " + ($.t('es:outcome.to')) + " ";
      val += formatters._formatNulls(attributes.confidenceIntervalTo);
      return val += ")";
    },
    _dichEffectRate: function(attr) {
      var val, _getEffectOtherValues;
      _getEffectOtherValues = function(effect, other) {
        if (!effect) {
          return "";
        }
        if (effect === 'other') {
          return other;
        } else {
          if ($.isNumeric(effect)) {
            return effect;
          } else {
            return $.t("es:outcome." + effect);
          }
        }
      };
      val = "" + ($.t('es:outcome.event_rate')) + "<br>";
      if (!attr.effectDenominator) {
        val += "" + (this.percentValue(attr.effectRate)) + " ";
      } else {
        val += "" + attr.effectRate + " ";
        val += "" + ($.t('es:outcome.per')) + " ";
        val += "" + (_getEffectOtherValues(attr.effectDenominator, attr.effectOtherDenominator));
        val += " " + (_getEffectOtherValues(attr.effectDuration, attr.effectOtherDuration)) + " ";
      }
      val += " (";
      val += formatters._formatNulls(attr.confidenceIntervalFrom);
      val += " " + ($.t('es:outcome.to')) + " ";
      val += formatters._formatNulls(attr.confidenceIntervalTo);
      return val += ")";
    }
  },
  question: {
    _pooledSenSpec: function(isRange, val, valFrom, valTo) {
      var format;
      format = function(v) {
        return formatters._toFixedNumber(formatters._formatNulls(v));
      };
      if (isRange) {
        return "" + (format(valFrom)) + " " + ($.t('es:outcome.to')) + " " + (format(valTo));
      } else {
        return "" + (format(val)) + " (95% CI: " + (format(valFrom)) + " " + ($.t('es:outcome.to')) + " " + (format(valTo)) + ")";
      }
    },
    _sensSpecAttrName: function(attrSuffix, sensitivity, comparatorTest) {
      var comparison, sensSpec;
      sensSpec = sensitivity ? 'Sensitivity' : 'Specificity';
      comparison = comparatorTest ? 'Comparison' : '';
      return "pooled" + sensSpec + comparison + attrSuffix;
    },
    sensitivitySpecificity: function(data, sensitivity, comparatorTest) {
      var isRange, val, valFrom, valTo;
      isRange = data.sourceOfDxData === 'rangeFromStudies';
      if (isRange) {
        valFrom = data[this._sensSpecAttrName('RangeFrom', sensitivity, comparatorTest)];
        valTo = data[this._sensSpecAttrName('RangeTo', sensitivity, comparatorTest)];
      } else {
        val = data[this._sensSpecAttrName('', sensitivity, comparatorTest)];
        valFrom = data[this._sensSpecAttrName('From', sensitivity, comparatorTest)];
        valTo = data[this._sensSpecAttrName('To', sensitivity, comparatorTest)];
      }
      return this._pooledSenSpec(isRange, val, valFrom, valTo);
    }
  }
};

module.exports = formatters;
