var GdtOpentip, Tooltips,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

GdtOpentip = (function(_super) {
  __extends(GdtOpentip, _super);

  GdtOpentip.visibleTooltips = {};

  function GdtOpentip() {
    GdtOpentip.__super__.constructor.apply(this, arguments);
    GdtOpentip.tips.length = 0;
    Opentip.lastZIndex = 10010;
  }

  GdtOpentip.prototype.getPosition = function(tipJoint, targetJoint, stem) {
    var position, yPosition;
    position = GdtOpentip.__super__.getPosition.apply(this, arguments);
    if (!this.options.target && this.options.targetY) {
      this.options.target = this.triggerElement;
      yPosition = GdtOpentip.__super__.getPosition.apply(this, arguments);
      position.top = yPosition.top;
      this.options.target = false;
    }
    return position;
  };

  GdtOpentip.prototype.show = function() {
    GdtOpentip.__super__.show.apply(this, arguments);
    return GdtOpentip.visibleTooltips[this.id] = this;
  };

  GdtOpentip.prototype.hide = function() {
    GdtOpentip.__super__.hide.apply(this, arguments);
    if (this.options.target) {
      this.options.target.removeData('tooltip-id');
    }
    return delete GdtOpentip.visibleTooltips[this.id];
  };

  return GdtOpentip;

})(Opentip);

module.exports = Tooltips = (function() {
  function Tooltips() {
    GdtOpentip.styles.info = {
      "extends": 'alert',
      fixed: true,
      targetY: true,
      targetJoint: 'bottom',
      tipJoint: 'top center',
      background: 'rgba(0, 0, 0, 0.9)',
      borderColor: 'black',
      hideEffect: null,
      hideTriggers: ['trigger', 'trigger'],
      hideOn: ['mouseout', 'click'],
      shadow: false,
      removeElementsOnHide: true,
      delay: 0.5
    };
    GdtOpentip.styles.questionMark = {
      "extends": 'info',
      showOn: 'click',
      delay: 0
    };
    GdtOpentip.styles.loginInfo = {
      "extends": 'info',
      showOn: 'creation',
      hideOn: 'focusout',
      target: true,
      targetJoint: 'top left',
      tipJoint: 'right bottom',
      delay: 0
    };
    GdtOpentip.styles.error = {
      "extends": 'info',
      background: '#ce1717',
      borderColor: '#ce1717',
      delay: 0
    };
    GdtOpentip.styles.validationError = {
      "extends": 'error',
      showOn: 'creation',
      target: true,
      targetJoint: 'middle right',
      tipJoint: 'left',
      hideOn: 'never',
      showEffect: null
    };
  }

  Tooltips.prototype._getOptionsFromData = function($elem) {
    var _ref;
    return {
      escapeContent: !((_ref = $elem.data('tooltip-html')) != null ? _ref : true)
    };
  };

  Tooltips.prototype._addTooltips = function(jqueryObject, options, content) {
    var $elem, elem, title, tooltip, _i, _len, _results;
    this.hideTooltip(jqueryObject);
    _results = [];
    for (_i = 0, _len = jqueryObject.length; _i < _len; _i++) {
      elem = jqueryObject[_i];
      $elem = $(elem);
      title = content;
      if (!title) {
        title = $elem.prop('title');
        $elem.removeAttr('title');
        $elem.data('original-title', title);
      }
      if (title) {
        tooltip = new GdtOpentip($elem, title, _(options).extend(this._getOptionsFromData($elem)));
        _results.push($elem.data('tooltip-id', tooltip.id));
      } else {
        _results.push(void 0);
      }
    }
    return _results;
  };

  Tooltips.prototype.info = function(jqueryObject, options, content) {
    if (options == null) {
      options = {};
    }
    options.style = 'info';
    return this._addTooltips(jqueryObject, options, content);
  };

  Tooltips.prototype.questionMark = function(jqueryObject, options, content) {
    if (options == null) {
      options = {};
    }
    options.style = 'questionMark';
    return this._addTooltips(jqueryObject, options, content);
  };

  Tooltips.prototype.loginInfo = function(jqueryObject, content) {
    var options;
    options = {
      style: 'loginInfo'
    };
    return this._addTooltips(jqueryObject, options, content);
  };

  Tooltips.prototype.dynamicInfo = function(jqueryObject, contentFunction) {
    return this._addTooltips(jqueryObject, {
      style: 'info',
      cache: false
    }, contentFunction);
  };

  Tooltips.prototype.error = function(jqueryObject) {
    return this._addTooltips(jqueryObject, {
      style: 'error'
    });
  };

  Tooltips.prototype.validationError = function(jqueryObject, error) {
    var $elem, elem, options, targetPosition, tipPosition, _i, _len;
    options = {};
    for (_i = 0, _len = jqueryObject.length; _i < _len; _i++) {
      elem = jqueryObject[_i];
      $elem = $(elem);
      tipPosition = $elem.data('tip-position');
      if (tipPosition) {
        _(options).extend({
          tipJoint: tipPosition
        });
      }
      targetPosition = $elem.data('target-position');
      if (targetPosition) {
        _(options).extend({
          targetJoint: targetPosition
        });
      }
    }
    _(options).extend({
      style: 'validationError'
    });
    return this._addTooltips(jqueryObject, options, error);
  };

  Tooltips.prototype.hideTooltip = function(jqueryObject) {
    var $elem, elem, tooltip, tooltipId, _i, _len, _results;
    _results = [];
    for (_i = 0, _len = jqueryObject.length; _i < _len; _i++) {
      elem = jqueryObject[_i];
      $elem = $(elem);
      tooltipId = $elem.data('tooltip-id');
      if (!tooltipId) {
        continue;
      }
      tooltip = GdtOpentip.visibleTooltips[tooltipId];
      _results.push(tooltip != null ? tooltip.hide() : void 0);
    }
    return _results;
  };

  return Tooltips;

})();
