var DiagnosticPrevalencesView, EvidenceSynthesesToolbarView, FootnotesService, FootnotesView, ISoFView, OutcomeViewType, OutcomesView, PooledSensitivitySpecificityView, Question, QuestionWithOutcomesView, Shortcuts, SourceOfDxDataView, TableScrolling, View, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

Question = require('models/question');

OutcomesView = require('views/evidence_syntheses/outcomes_view');

OutcomeViewType = require('models/outcome/view_types');

ISoFView = require('views/evidence_syntheses/isof_view');

EvidenceSynthesesToolbarView = require('views/evidence_syntheses_toolbar_view');

Shortcuts = require('base/lib/traits/shortcuts');

template = require('views/templates/evidence_syntheses/question_with_outcomes');

mediator = require('mediator');

PooledSensitivitySpecificityView = require('views/evidence_syntheses/pooled_sensitivity_specificity_view');

SourceOfDxDataView = require('views/evidence_syntheses/source_of_dx_data_view');

DiagnosticPrevalencesView = require('views/evidence_syntheses/diagnostic_prevalences_view');

FootnotesView = require('views/footnotes_view');

TableScrolling = require('base/lib/traits/table_scrolling');

FootnotesService = require('lib/services/footnotes_service');

module.exports = QuestionWithOutcomesView = (function(_super) {
  __extends(QuestionWithOutcomesView, _super);

  function QuestionWithOutcomesView() {
    return QuestionWithOutcomesView.__super__.constructor.apply(this, arguments);
  }

  QuestionWithOutcomesView.prototype.container = '#page-container';

  QuestionWithOutcomesView.prototype.template = template;

  QuestionWithOutcomesView.prototype.autoRender = true;

  QuestionWithOutcomesView.prototype.initialize = function(options) {
    QuestionWithOutcomesView.__super__.initialize.apply(this, arguments);
    this.moduleName = options.moduleName;
    this.enable(Shortcuts);
    this.enable(TableScrolling, {
      content: '.outcomes-table-container',
      fixedElements: '.outcomes thead, .footnotes-container, .questions-view, .diagnostic-meta',
      fixedMaxHeight: true
    });
    this.viewType = mediator.services.esView.getPreferredType(mediator.project, this.model.get('type'));
    this.subview('toolbar', new EvidenceSynthesesToolbarView({
      baseUrl: options.baseUrl,
      moduleName: this.moduleName,
      model: this.model,
      submodule: this.moduleName === 'prognosis' ? 'prognosis-outcomes' : 'quality-of-evidence',
      parentView: this,
      viewType: this.viewType,
      disableExportButton: (function(_this) {
        return function() {
          return _this.viewType.isof;
        };
      })(this)
    }));
    return this.subscribeEvent('footnotesToggled', this.fixLayout);
  };

  QuestionWithOutcomesView.prototype.showOutcomesSubview = function(viewType, previousViewType) {
    var outcomesObject, outcomesView;
    this.viewType = viewType;
    outcomesObject = this.getOutcomesObject();
    _(outcomesObject).extend({
      parentView: this,
      previousViewType: previousViewType
    });
    if (viewType.isof) {
      this.removeSubview('footnotesBottomPane');
    } else if (previousViewType != null ? previousViewType.isof : void 0) {
      this.showFootnotesBottomPane();
    }
    outcomesView = viewType.isof ? new ISoFView(outcomesObject) : new OutcomesView(outcomesObject);
    this.subview('outcomes', outcomesView);
    outcomesView.render();
    if (this.model.isDiagnostic() && !viewType.isof) {
      this.subview('sourceOfDxData', new SourceOfDxDataView({
        model: this.model
      }));
      this.subview('pooledValues', new PooledSensitivitySpecificityView({
        model: this.model,
        outcomesView: outcomesView
      }));
      this.subview('prevalences', new DiagnosticPrevalencesView({
        model: this.model
      }));
    }
    this.trigger('!fixLayout');
    this.reorderFootnotes();
    return outcomesView;
  };

  QuestionWithOutcomesView.prototype.getCurrentEditView = function() {
    if (this.subview('outcomes').currentEditView && !this.subview('outcomes').currentEditView.disposed) {
      return this.subview('outcomes').currentEditView;
    }
  };

  QuestionWithOutcomesView.prototype.showFootnotesSubview = function(view, options) {
    if (this.getCurrentEditView() && !options.forceQualityFootnote) {
      this.subview('outcomes').currentEditView.subview('footnotes', view);
    } else {
      this.subview('footnotes', view);
    }
    view.show(options);
    return view;
  };

  QuestionWithOutcomesView.prototype.showFootnotesBottomPane = function() {
    var view;
    this.subview('footnotesBottomPane', view = new FootnotesView({
      model: this.model
    }));
    return view.render();
  };

  QuestionWithOutcomesView.prototype.getOutcomesObject = function() {
    return {
      container: this.$('.outcomes-container'),
      viewType: this.viewType,
      questionModel: this.model
    };
  };

  QuestionWithOutcomesView.prototype.reorderFootnotes = function() {
    var fs;
    fs = new FootnotesService({
      model: this.model
    });
    return fs.automaticallySortFootnotes();
  };

  QuestionWithOutcomesView.prototype.afterRender = function() {
    $('#page-container').css({
      'overflow-y': 'hidden'
    });
    this.$el.height($('#page-container').height());
    this.$el.css({
      'overflow-y': 'auto'
    });
    QuestionWithOutcomesView.__super__.afterRender.apply(this, arguments);
    this.showFootnotesBottomPane();
    this.showOutcomesSubview(this.viewType);
    return this.fixLayout();
  };

  QuestionWithOutcomesView.prototype.dispose = function() {
    $('#page-container').css({
      'overflow-y': 'auto'
    });
    return QuestionWithOutcomesView.__super__.dispose.apply(this, arguments);
  };

  return QuestionWithOutcomesView;

})(View);
