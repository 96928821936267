var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "  <div class=\"import-type-line\">\n    <div class=\"error-message\">"
    + escapeExpression(((helper = (helper = helpers.error || (depth0 != null ? depth0.error : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"error","hash":{},"data":data}) : helper)))
    + "</div>\n  </div>\n";
},"3":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "    <div class=\"error-message\">\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "errors:import.error_during_import", {"name":"i18n","hash":{},"data":data})))
    + "<br>\n      "
    + escapeExpression(((helper = (helper = helpers.importError || (depth0 != null ? depth0.importError : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"importError","hash":{},"data":data}) : helper)))
    + "\n    </div>\n";
},"5":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <li>\n        <input name=\"importQuestion\" type=\"checkbox\" id=\""
    + escapeExpression(((helper = (helper = helpers.questionNo || (depth0 != null ? depth0.questionNo : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"questionNo","hash":{},"data":data}) : helper)))
    + "\" checked>\n        <label for=\""
    + escapeExpression(((helper = (helper = helpers.questionNo || (depth0 != null ? depth0.questionNo : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"questionNo","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(((helper = (helper = helpers.questionShort || (depth0 != null ? depth0.questionShort : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"questionShort","hash":{},"data":data}) : helper)))
    + "</label>\n      </li>\n";
},"7":function(depth0,helpers,partials,data) {
  return "disabled";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<fieldset>\n  <div class=\"import-source\">\n    <div class=\"from-disk\">\n      <input id=\"file\" type=\"file\">\n      <button class=\"file\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "projects:import_dialog.choose_file", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n    </div>\n  </div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.importError : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</fieldset>\n<div class=\"questions-to-import\">\n  <ul>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.questions : depth0), {"name":"each","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "  </ul>\n</div>\n<div class=\"notifier-btns\">\n  <button class=\"cancel\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "actions.cancel", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n  <button class=\"open finish\" ";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.questions : depth0), {"name":"unless","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "projects:open_from_file_dialog.open", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}