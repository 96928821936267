PureRenderMixin = React.addons.PureRenderMixin
BlockSections = require 'components/administration/block_sections'
PresentationsBlockSections = require 'components/administration/presentations_block_sections'
TemplateName = require 'components/administration/template_name'
TemplateButtons = require 'components/administration/template_buttons'

TemplateSectionsSelection = React.createClass
  displayName: 'TemplateSectionsSelection'

  mixins: [PureRenderMixin]

  render: ->
    templateDef = @props.template?.get 'templateDef'
    templateData = @props.template?.get 'templateData'
    <div className='sections-selection'>
      <TemplateName
        nameEditable={@props.nameEditable}
        templateName={@props.templateName}
        templateChanged={@props.templateChanged}
        autoUpdateName={@props.autoUpdateName} />
      <BlockSections
        blockName='question'
        blockDef={templateDef?.get('question')}
        blockData={templateData?.get('question')}
      />
      <BlockSections
        blockName='assessment'
        className='assessment-sections-block'
        blockDef={templateDef?.get('assessment')}
        blockData={templateData?.get('assessment')}
        descriptionKey='criteria'
      />
      <BlockSections
        blockName='conclusions'
        blockDef={templateDef?.get('conclusions')}
        blockData={templateData?.get('conclusions')}
      />
      <PresentationsBlockSections
        blockName='presentations'
        blockDef={templateDef?.get('presentations')}
        blockData={templateData?.get('presentations')}
      />
      <TemplateButtons
        templateInUse={@props.templateInUse}
        useCurrentTemplate={@props.useCurrentTemplate}
        templateInUse={@props.templateInUse}
        saveTemplateChanges={@props.saveTemplateChanges}
        templateChanged={@props.templateChanged} />
    </div>

module.exports = TemplateSectionsSelection
