var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "  <div class=\"edit-input-block row mt-10\">\n    <div class=\"col-11\">\n      <textarea name=\"complicationsPrevalencesDesc\"\n        class=\"cell-edit-textarea\">"
    + escapeExpression(((helper = (helper = helpers.complicationsPrevalencesDesc || (depth0 != null ? depth0.complicationsPrevalencesDesc : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"complicationsPrevalencesDesc","hash":{},"data":data}) : helper)))
    + "</textarea>\n    </div>\n    <div class=\"col-1 sup\"></div>\n  </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compoundEdit || (depth0 && depth0.compoundEdit) || helperMissing).call(depth0, "complicationsPrevalencesDesc", "complications_prevalences_desc", {"name":"compoundEdit","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}