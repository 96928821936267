var DisseminationController, ModuleController, PublicationService, PublishView, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModuleController = require('controllers/base/module_controller');

PublishView = require('views/dissemination/publish_view');

PublicationService = require('lib/services/publication_service');

mediator = require('mediator');

module.exports = DisseminationController = (function(_super) {
  __extends(DisseminationController, _super);

  function DisseminationController() {
    return DisseminationController.__super__.constructor.apply(this, arguments);
  }

  DisseminationController.prototype.defaultSubmodule = 'publish';

  DisseminationController.prototype.viewsForSubmodules = {
    'publish': PublishView
  };

  DisseminationController.prototype._viewParams = function(params) {
    return {
      model: mediator.project,
      service: mediator.services.publication
    };
  };

  return DisseminationController;

})(ModuleController);
