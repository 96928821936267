Translation = require 'components/mixins/translation'
Summary = require 'components/etd/soj_summary'
Favours = require 'components/etd/soj_favours'
Importance = require 'components/etd/soj_importance'
EtdStore = require 'stores/etd_store'

SummaryOfJudgementsSection = React.createClass
  mixins: [Translation('es:recommendations.table')]

  render: ->
    sectionName = @props.section.get('name')
    criterion = EtdStore.getCriterion @props.section.get 'criterionId'
    favours = @props.section.get('favours')
    importance = @props.section.get('importance')
    <tr>
      <td className="section-name">{sectionName}</td>
      <Summary criterion={criterion}/>
      <Favours value={favours} sectionKey={@props.sectionKey} importance={importance}/>
      <Importance value={importance} sectionKey={@props.sectionKey}/>
    </tr>

module.exports = SummaryOfJudgementsSection
