var Ellipsis, Focusable, PresentationService, PresentationsToolbarView, Scrolling, View, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

Scrolling = require('base/lib/traits/scrolling');

Ellipsis = require('base/lib/traits/ellipsis');

Focusable = require('base/lib/traits/focusable');

template = require('views/templates/presentations_toolbar');

mediator = require('mediator');

PresentationService = require('lib/services/presentation_service');

module.exports = PresentationsToolbarView = (function(_super) {
  __extends(PresentationsToolbarView, _super);

  function PresentationsToolbarView() {
    return PresentationsToolbarView.__super__.constructor.apply(this, arguments);
  }

  PresentationsToolbarView.prototype.template = template;

  PresentationsToolbarView.prototype.container = '#toolbar-container';

  PresentationsToolbarView.prototype.className = 'presentations-toolbar';

  PresentationsToolbarView.prototype.autoRender = true;

  PresentationsToolbarView.prototype.initialize = function() {
    PresentationsToolbarView.__super__.initialize.apply(this, arguments);
    this.delegate('click', '.export', this.exportPresentations);
    this.delegate('click', '.questions > a', this.questionsListClicked);
    this.questions = mediator.questions;
    this.menuOpened = false;
    this.enable(Ellipsis);
    return this.enable(Focusable);
  };

  PresentationsToolbarView.prototype.afterRender = function() {
    var _ref;
    PresentationsToolbarView.__super__.afterRender.apply(this, arguments);
    return this.$('button.export').prop('disabled', _.result(this.options, 'disableExportButton') || !((_ref = this.model.get('recommendationIds')) != null ? _ref[0] : void 0));
  };

  PresentationsToolbarView.prototype.questionsListClicked = function() {
    this.menuOpened = !this.menuOpened;
    this.$('.questions').toggleClass('opened', this.menuOpened);
    if (this.menuOpened) {
      mediator.setFocus(this);
      if (!this.scrollingEnabled) {
        this.scrollingEnabled = this.enable(Scrolling, {
          content: 'ol'
        });
        this.off('addedToDOM');
      }
      this.fixLayout();
    } else {
      mediator.setFocus();
    }
    return false;
  };

  PresentationsToolbarView.prototype.onFocusLost = function() {
    if (this.disposed) {
      return;
    }
    return this.$('.questions').removeClass('opened');
  };

  PresentationsToolbarView.prototype.exportPresentations = function() {
    var presentationData, recommendationId, _ref;
    presentationData = {
      type: $('#presentation-type').val(),
      text: $('#presentation-type option:selected').text(),
      question: this.model.get('question')
    };
    if (!presentationData.type) {
      return;
    }
    recommendationId = (_ref = this.model.get('recommendationIds')) != null ? _ref[0] : void 0;
    return mediator.services.presentationService.exportPresentation(recommendationId, presentationData);
  };

  PresentationsToolbarView.prototype.getTemplateData = function() {
    var data, question, _ref, _ref1, _ref2;
    data = PresentationsToolbarView.__super__.getTemplateData.apply(this, arguments);
    _(data).extend({
      baseUrl: this.options.baseUrl,
      module: _.string.dasherize(this.options.moduleName),
      submodule: this.options.submodule,
      questions: (function() {
        var _i, _len, _ref, _results;
        _ref = this.questions.models;
        _results = [];
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          question = _ref[_i];
          _results.push(question.toJSON());
        }
        return _results;
      }).call(this),
      currentQuestion: (_ref = (_ref1 = this.model) != null ? _ref1.get('question') : void 0) != null ? _ref : $.t('toolbar.no_question_selected'),
      currentQuestionId: (_ref2 = this.model) != null ? _ref2.id : void 0
    });
    return data;
  };

  return PresentationsToolbarView;

})(View);
