var CollectionView, ImportView, NewProjectView, ProjectView, ProjectsOverview, ProjectsToolbarView, Scrolling, Shortcuts, Sortable, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

CollectionView = require('base/views/collection_view');

ProjectView = require('views/project_view');

ProjectsToolbarView = require('views/projects_toolbar_view');

ImportView = require('views/import_view');

NewProjectView = require('views/new_project_view');

Scrolling = require('base/lib/traits/scrolling');

Shortcuts = require('base/lib/traits/shortcuts');

template = require('views/templates/projects_overview');

mediator = require('mediator');

Sortable = require('base/lib/traits/sortable');

module.exports = ProjectsOverview = (function(_super) {
  __extends(ProjectsOverview, _super);

  function ProjectsOverview() {
    return ProjectsOverview.__super__.constructor.apply(this, arguments);
  }

  ProjectsOverview.prototype.container = '#page-container';

  ProjectsOverview.prototype.className = 'projects-overview';

  ProjectsOverview.prototype.template = template;

  ProjectsOverview.prototype.itemView = ProjectView;

  ProjectsOverview.prototype.animationDuration = 10;

  ProjectsOverview.prototype.listSelector = 'ol';

  ProjectsOverview.prototype.initialize = function() {
    this.enable(Scrolling, {
      fixedElements: 'menu.buttons'
    });
    this.enable(Shortcuts);
    this.enable(Sortable, {
      containerSelector: 'ol'
    });
    ProjectsOverview.__super__.initialize.apply(this, arguments);
    mediator.publish('projectChanged', null);
    this.subview('toolbar', new ProjectsToolbarView());
    this.delegate('click', 'button.create', this.newProject);
    this.delegate('click', 'button.import', this.importProject);
    return this.modelBind('add', function() {
      return this.$el.find('.welcome-message').remove();
    });
  };

  ProjectsOverview.prototype.newProject = function(e) {
    e.stopPropagation();
    return this.subview('newProject', new NewProjectView());
  };

  ProjectsOverview.prototype.importProject = function(e) {
    e.stopPropagation();
    return this.subview('importView', new ImportView());
  };

  ProjectsOverview.prototype.getTemplateData = function() {
    return _(ProjectsOverview.__super__.getTemplateData.apply(this, arguments)).extend({
      welcome: this.options.welcome
    });
  };

  return ProjectsOverview;

})(CollectionView);
