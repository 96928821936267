var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<td><span class=\"caption\">"
    + escapeExpression(((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"question","hash":{},"data":data}) : helper)))
    + "</span></td>\n<td>"
    + escapeExpression(((helper = (helper = helpers.medianRating || (depth0 != null ? depth0.medianRating : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"medianRating","hash":{},"data":data}) : helper)))
    + "</td>\n<td><label><input type=\"radio\" name=\""
    + escapeExpression(((helper = (helper = helpers.statusGroup || (depth0 != null ? depth0.statusGroup : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"statusGroup","hash":{},"data":data}) : helper)))
    + "\" value=\"accepted\"></label></td>\n<td><label><input type=\"radio\" name=\""
    + escapeExpression(((helper = (helper = helpers.statusGroup || (depth0 != null ? depth0.statusGroup : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"statusGroup","hash":{},"data":data}) : helper)))
    + "\" value=\"mentioned\"></label></td>\n<td><label><input type=\"radio\" name=\""
    + escapeExpression(((helper = (helper = helpers.statusGroup || (depth0 != null ? depth0.statusGroup : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"statusGroup","hash":{},"data":data}) : helper)))
    + "\" value=\"rejected\"></label></td>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}