var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "cont", {"name":"is","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "dich", {"name":"is","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "narrative", {"name":"is","hash":{},"fn":this.program(17, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.inStringArray || (depth0 && depth0.inStringArray) || helperMissing).call(depth0, "SOF, SOF_ACCP", (depth0 != null ? depth0.viewTypeName : depth0), {"name":"inStringArray","hash":{},"fn":this.program(3, data),"inverse":this.program(5, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <div class=\"edit-input-block row mt-10\">\n        "
    + escapeExpression(((helpers.inputBlock || (depth0 && depth0.inputBlock) || helperMissing).call(depth0, "change_score_in_controls", "changeScoreInControlsValue", {"name":"inputBlock","hash":{},"data":data})))
    + "\n      </div>\n      <div class=\"edit-input-block row mt-10\">\n        "
    + escapeExpression(((helpers.inputBlock || (depth0 && depth0.inputBlock) || helperMissing).call(depth0, "units", "units", {"name":"inputBlock","hash":{},"data":data})))
    + "\n      </div>\n";
},"5":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <div class=\"edit-input-block row mt-10\">\n        "
    + escapeExpression(((helpers.inputBlock || (depth0 && depth0.inputBlock) || helperMissing).call(depth0, "control_total", {"name":"inputBlock","hash":{},"data":data})))
    + "\n      </div>\n";
},"7":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.viewTypeName : depth0), "ACCP", {"name":"is","hash":{},"fn":this.program(8, data),"inverse":this.program(10, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <div class=\"edit-input-block row mt-10\">\n        "
    + escapeExpression(((helpers.inputBlock || (depth0 && depth0.inputBlock) || helperMissing).call(depth0, "control_count", {"name":"inputBlock","hash":{},"data":data})))
    + "\n      </div>\n      <div class=\"edit-input-block row mt-10\">\n        "
    + escapeExpression(((helpers.inputBlock || (depth0 && depth0.inputBlock) || helperMissing).call(depth0, "control_total", {"name":"inputBlock","hash":{},"data":data})))
    + "\n      </div>\n";
},"10":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.isCaseControl : depth0), {"name":"unless","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.inStringArray || (depth0 && depth0.inStringArray) || helperMissing).call(depth0, "SOF, SOF_v3", (depth0 != null ? depth0.viewTypeName : depth0), {"name":"inStringArray","hash":{},"fn":this.program(13, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.isCaseControl : depth0), {"name":"unless","hash":{},"fn":this.program(15, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      <div class=\"edit-input-block row mt-10\">\n        "
    + escapeExpression(((helpers.inputBlock || (depth0 && depth0.inputBlock) || helperMissing).call(depth0, "low_control_risk", "lowControlRisk", {"name":"inputBlock","hash":{},"data":data})))
    + "\n      </div>\n      <div class=\"edit-input-block row mt-10\">\n        "
    + escapeExpression(((helpers.inputBlock || (depth0 && depth0.inputBlock) || helperMissing).call(depth0, "moderate_control_risk", "moderateControlRisk", {"name":"inputBlock","hash":{},"data":data})))
    + "\n      </div>\n      <div class=\"edit-input-block row mt-10\">\n        "
    + escapeExpression(((helpers.inputBlock || (depth0 && depth0.inputBlock) || helperMissing).call(depth0, "high_control_risk", "highControlRisk", {"name":"inputBlock","hash":{},"data":data})))
    + "\n      </div>\n";
},"11":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <div class=\"edit-input-block row mt-10\">\n          "
    + escapeExpression(((helpers.inputBlock || (depth0 && depth0.inputBlock) || helperMissing).call(depth0, "control_count", {"name":"inputBlock","hash":{},"data":data})))
    + "\n        </div>\n        <div class=\"edit-input-block row mt-10\">\n          "
    + escapeExpression(((helpers.inputBlock || (depth0 && depth0.inputBlock) || helperMissing).call(depth0, "control_total", {"name":"inputBlock","hash":{},"data":data})))
    + "\n        </div>\n        <div class=\"edit-input-block row mt-10\">\n          "
    + escapeExpression(((helpers.inputBlock || (depth0 && depth0.inputBlock) || helperMissing).call(depth0, "calculated_control", "calculatedControlValue", {"name":"inputBlock","hash":{},"data":data})))
    + "\n        </div>\n";
},"13":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <div class=\"edit-input-block mt-10\" data-property=\"absDenominator\">\n          <div id=\"absDenominator\" >\n            <label>\n              "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.denominator", {"name":"i18n","hash":{},"data":data})))
    + "\n              <span class=\"text-right\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "per", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n            </label>\n            <div class=\"inline\">\n              "
    + escapeExpression(((helpers.absDenominatorSelectableAttr || (depth0 && depth0.absDenominatorSelectableAttr) || helperMissing).call(depth0, (depth0 != null ? depth0.absDenominator : depth0), {"name":"absDenominatorSelectableAttr","hash":{},"data":data})))
    + "\n            </div>\n            <div class=\"sup\"></div>\n          </div>\n        </div>\n";
},"15":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <div class=\"row mt-10\">\n          <label class=\"legend\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.control_risk", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n        </div>\n";
},"17":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <div class=\"edit-input-block row mt-10\">\n      "
    + escapeExpression(((helpers.inputBlock || (depth0 && depth0.inputBlock) || helperMissing).call(depth0, "control_count", {"name":"inputBlock","hash":{},"data":data})))
    + "\n    </div>\n    <div class=\"edit-input-block row mt-10\">\n      "
    + escapeExpression(((helpers.inputBlock || (depth0 && depth0.inputBlock) || helperMissing).call(depth0, "control_total", {"name":"inputBlock","hash":{},"data":data})))
    + "\n    </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compoundEdit || (depth0 && depth0.compoundEdit) || helperMissing).call(depth0, "control", "control", {"name":"compoundEdit","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}