var AccountView, ActiveProjects, Ellipsis, FeedbackView, Focusable, HandbookView, HeaderView, HelpView, ImportView, NewProjectView, PopupMenuView, ProjectsListView, Scrolling, SelectionView, UserView, View, mediator, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

ImportView = require('views/import_view');

NewProjectView = require('views/new_project_view');

FeedbackView = require('views/feedback_view');

HandbookView = require('views/handbook_view');

UserView = require('views/user_view');

AccountView = require('views/account_view');

Scrolling = require('base/lib/traits/scrolling');

Ellipsis = require('base/lib/traits/ellipsis');

Focusable = require('base/lib/traits/focusable');

template = require('./templates/header');

mediator = require('mediator');

SelectionView = require('base/views/selection_view');

PopupMenuView = require('base/views/popup_menu_view');

HelpView = require('views/help_view');

ActiveProjects = require('models/active_projects');

ProjectsListView = require('views/projects_list_view');

module.exports = HeaderView = (function(_super) {
  __extends(HeaderView, _super);

  function HeaderView() {
    this.hideSettings = __bind(this.hideSettings, this);
    return HeaderView.__super__.constructor.apply(this, arguments);
  }

  HeaderView.prototype.template = template;

  HeaderView.prototype.id = 'header';

  HeaderView.prototype.container = '#header-container';

  HeaderView.prototype.autoRender = true;

  HeaderView.prototype.messageDisplayed = {};

  HeaderView.prototype.initialize = function() {
    HeaderView.__super__.initialize.apply(this, arguments);
    this.subscribeEvent('loginStatus', this.render);
    this.subscribeEvent('projectChanged', this.render);
    this.modelBind('all', this.render);
    this.delegate('click', '.projects .title a', this.menuHeaderClicked);
    this.delegate('click', '.projects-menu label', function(e) {
      return e.stopPropagation();
    });
    this.delegate('click', 'button.project-create', this.newProject);
    this.delegate('click', 'button.project-import', this.importProject);
    this.delegate('click', 'button.settings', this.showSettings);
    this.delegate('click', 'button.feedback', this.showFeedback);
    this.delegate('click', 'button.help', this.showHelp);
    this.delegate('click', 'button.connection', this._showConnectionMenu);
    this.delegate('click', '#header-update-info a', this._reloadApplication);
    this.delegate('click', '#header-connection-timeout-warning a', this._reloadApplication);
    this.delegate('click', '.user-info', this._showUserMenu);
    this.menuOpened = false;
    this.enable(Ellipsis);
    this.enable(Focusable);
    this._setupConnectionStates();
    this.subscribeEvent('updateAvailable', this._updateAvailable);
    return this.subscribeEvent('updateInstalled', this._displayUpdateInfos);
  };

  HeaderView.prototype._reloadApplication = function(e) {
    e.preventDefault();
    return window.location.reload();
  };

  HeaderView.prototype._updateAvailable = function() {
    return this._showHeaderMessage('update-info');
  };

  HeaderView.prototype._disconnected = function() {
    this._hideHeaderMessage('connection-timeout-warning');
    return this._showHeaderMessage('disconnected-warning');
  };

  HeaderView.prototype._connected = function() {
    this._hideHeaderMessage('disconnected-warning');
    return this._hideHeaderMessage('connection-timeout-warning');
  };

  HeaderView.prototype._connectionTimeout = function() {
    this._hideHeaderMessage('disconnected-warning');
    return this._showHeaderMessage('connection-timeout-warning');
  };

  HeaderView.prototype._showHeaderMessage = function(type) {
    var $header, $headerMessage;
    $headerMessage = $("#header-" + type);
    $headerMessage.show();
    $header = $('#header');
    if (!this.messageDisplayed[type]) {
      $header.height($headerMessage.height() + $header.height());
      $('.outer-container').css('top', $('.outer-container').position().top + $headerMessage.height());
      return this.messageDisplayed[type] = true;
    }
  };

  HeaderView.prototype._hideHeaderMessage = function(type) {
    var $header, $headerMessage;
    $headerMessage = $("#header-" + type);
    $headerMessage.hide();
    $header = $('#header');
    if (this.messageDisplayed[type]) {
      $header.height($header.height() - $headerMessage.height());
      $('.outer-container').css('top', $('.outer-container').position().top - $headerMessage.height());
      return this.messageDisplayed[type] = false;
    }
  };

  HeaderView.prototype._showUserMenu = function(e) {
    var hideMenu, listItems, offset, userMenuView;
    e.preventDefault();
    e.stopPropagation();
    listItems = [
      {
        value: 'logOut',
        text: $.t('user.log_out')
      }
    ];
    if (mediator.services.switches.isOn('archie')) {
      listItems.unshift({
        value: 'account',
        text: $.t('user.account')
      }, {
        type: 'hline'
      });
    }
    hideMenu = (function(_this) {
      return function() {
        var _ref;
        return (_ref = _this.subview('userMenuView')) != null ? _ref.hide() : void 0;
      };
    })(this);
    userMenuView = new SelectionView({
      listItems: listItems,
      acceptHandler: (function(_this) {
        return function() {
          switch (_this.subview('userMenuView').getSelectedItemValue()) {
            case 'account':
              _this.subview('account', new AccountView({
                service: mediator.services.externalAccounts
              }));
              break;
            case 'logOut':
              mediator.publish('logout');
          }
          return hideMenu();
        };
      })(this),
      abortHandler: hideMenu
    });
    this.subview('userMenuView', userMenuView);
    offset = this.$('.user-info').offset();
    offset.right = 10;
    offset.top = offset.top + this.$('.user-info').height();
    return userMenuView.show(offset);
  };

  HeaderView.prototype.getTemplateData = function() {
    var _ref, _ref1;
    return {
      currentProject: (_ref = mediator.project) != null ? _ref.get('name') : void 0,
      currentProjectId: (_ref1 = mediator.project) != null ? _ref1.id : void 0,
      userEmail: mediator.user.get('email'),
      projects: HeaderView.__super__.getTemplateData.call(this).items,
      whatsNewLink: window.gdt.versionUpdateLink,
      isChrome: /google/i.test(window.navigator.vendor),
      newProjectsList: mediator.services.switches.isOn('new_projects_list')
    };
  };

  HeaderView.prototype.afterRender = function() {
    var lastState;
    HeaderView.__super__.afterRender.apply(this, arguments);
    lastState = this._updateConnectionState.lastState || mediator.services.replication.state;
    this._updateConnectionState(lastState, true);
    this._displayUpdateInfos();
    if (mediator.services.switches.isOn('new_projects_list')) {
      return this.subview('menuProjectsList', new ProjectsListView({
        container: this.$el.find('.new-projects-list')[0],
        isActive: true,
        isMenuMode: true,
        collection: mediator.activeProjects
      }));
    }
  };

  HeaderView.prototype._displayUpdateInfos = function() {
    var updateInfo;
    if (window.applicationCache.status === window.applicationCache.UPDATEREADY) {
      this._updateAvailable();
    }
    if (window.gdt.updateInstalled && !this.updateInfoDisplayed) {
      updateInfo = this.$('.update-installed-info');
      updateInfo.show();
      this.updateInfoDisplayed = true;
      return _.delay((function() {
        return updateInfo.hide();
      }), 15000);
    }
  };

  HeaderView.prototype.menuHeaderClicked = function() {
    this.menuOpened = !this.menuOpened;
    this.$('.projects').toggleClass('opened', this.menuOpened);
    if (this.menuOpened) {
      mediator.setFocus(this);
      if (!this.scrollingEnabled) {
        this.scrollingEnabled = this.enable(Scrolling, {
          content: 'ol',
          wrapped: false
        });
        this.off('addedToDOM');
      }
      this.fixLayout();
    } else {
      mediator.setFocus();
    }
    return false;
  };

  HeaderView.prototype.onFocusLost = function() {
    if (this.disposed) {
      return;
    }
    this.menuOpened = false;
    return this.$('.projects').removeClass('opened');
  };

  HeaderView.prototype.newProject = function() {
    return this.subview('newProject', new NewProjectView());
  };

  HeaderView.prototype.importProject = function() {
    return this.subview('importView', new ImportView());
  };

  HeaderView.prototype._getSettingsListItems = function() {
    var user;
    user = mediator.user;
    return [
      mediator.project ? {
        text: $.t('settings.modules_enabling'),
        type: 'submenu',
        subId: 'modules',
        items: function() {
          return _(mediator.services.modules.getSwitchableModules()).map(function(id) {
            return {
              id: id,
              type: 'check',
              value: mediator.services.modules.isEnabled(id, mediator.project),
              text: $.t("modules_captions." + id),
              handler: function() {
                return mediator.services.modules["switch"](id, mediator.project);
              }
            };
          });
        }
      } : void 0, {
        id: 'forceFootnotes',
        value: user.get('forceFootnotes'),
        text: $.t('settings.force_footnotes'),
        type: 'check',
        handler: function() {
          return user.save('forceFootnotes', !user.get('forceFootnotes'));
        }
      }, {
        id: 'showDropQuestionGroupArea',
        value: user.get('showDropQuestionGroupArea'),
        text: $.t('settings.show_question_drop_area'),
        type: 'check',
        handler: function() {
          return user.save('showDropQuestionGroupArea', !user.get('showDropQuestionGroupArea'));
        }
      }, {
        text: $.t('language'),
        type: 'submenu',
        subId: 'language',
        items: _(window.gdt.languages).map(function(lng) {
          return {
            id: lng.value,
            value: user.get('language') === lng.value,
            active: user.get('language') === lng.value,
            text: lng.text,
            type: 'link',
            handler: function() {
              return mediator.publish('!setLanguage', lng.value);
            }
          };
        })
      }
    ];
  };

  HeaderView.prototype.showSettings = function(e) {
    var listItems, offset, settingsView;
    e.preventDefault();
    e.stopPropagation();
    listItems = _(this._getSettingsListItems()).compact();
    settingsView = new PopupMenuView({
      menuItems: listItems,
      addClass: 'settings'
    });
    this.subview('settingsView', settingsView);
    offset = this.$('button.settings').offset();
    offset.top = offset.top + this.$('button.settings').height();
    offset.left -= 255;
    return settingsView.show(offset);
  };

  HeaderView.prototype.showHelp = function() {
    var helpListItem, hideMenu;
    hideMenu = (function(_this) {
      return function() {
        var _ref;
        return (_ref = _this.subview('help')) != null ? _ref.hide() : void 0;
      };
    })(this);
    helpListItem = [
      {
        value: 'handbook',
        text: $.t('help.handbook')
      }, {
        value: 'walkthrough',
        text: $.t('help.walkthrough')
      }, {
        type: 'hline'
      }
    ];
    return mediator.services.cache.isUpToDate().then((function(_this) {
      return function(upToDate) {
        var $helpButton, menu, offset;
        helpListItem.push(upToDate ? {
          value: 'upToDate',
          text: $.t('messages.up_to_date', {
            link: window.gdt.versionUpdateLink
          })
        } : {
          value: 'updateAvailable',
          text: $.t('messages.update_available')
        });
        helpListItem.push({
          value: 'termsOfService',
          text: $.t('terms_of_service')
        });
        helpListItem.push({
          value: 'termsOfPublication',
          text: $.t('terms_of_publication')
        });
        menu = new SelectionView({
          listItems: helpListItem,
          acceptHandler: function() {
            switch (_this.subview('help').getSelectedItemValue()) {
              case 'handbook':
                _this.showHandbook();
                break;
              case 'walkthrough':
                mediator.services.walkthrough.startTour();
                break;
              case 'updateAvailable':
                window.location.reload();
                break;
              case 'termsOfService':
                window.open($.t('terms_of_service_url'), '_blank');
                break;
              case 'termsOfPublication':
                window.open($.t('terms_of_publication_url'), '_blank');
            }
            return hideMenu();
          },
          abortHandler: hideMenu
        });
        menu = mediator.services.switches.isOn('new_projects_list') ? new HelpView : menu;
        _this.subview('help', menu);
        $helpButton = _this.$('button.help');
        offset = $helpButton.offset();
        offset.top = offset.top + $helpButton.height();
        return menu.show(offset);
      };
    })(this));
  };

  HeaderView.prototype.showHandbook = function() {
    return this.subview('handbook', new HandbookView());
  };

  HeaderView.prototype.showFeedback = function() {
    return this.subview('feedback', new FeedbackView());
  };

  HeaderView.prototype.hideSettings = function() {
    var sv;
    sv = this.subview('settingsView');
    sv.unstickit();
    return sv.hide();
  };

  HeaderView.prototype._showConnectionMenu = function() {
    return this.subview('user', new UserView({
      model: mediator.user,
      lastBackup: this.lastBackup,
      centralConnection: this._updateConnectionState.lastState === 'connected',
      authenticationService: mediator.services.authentication,
      replicationService: mediator.services.replication
    }));
  };

  HeaderView.prototype._setupConnectionStates = function() {
    this.subscribeEvent('replication.connected', function() {
      return this._updateConnectionState('connected');
    });
    this.subscribeEvent('replication.disconnected', function() {
      return this._updateConnectionState('disconnected');
    });
    this.subscribeEvent('replication.unauthorized', function() {
      return this._updateConnectionState('unauthorized');
    });
    this.subscribeEvent('replication.serverError', function() {
      return this._updateConnectionState('serverError');
    });
    this.subscribeEvent('replication.connectionTimeout', function() {
      return this._updateConnectionState('connectionTimeout');
    });
    return this.subscribeEvent('replication.backedUp', function(_arg) {
      var timestamp;
      timestamp = _arg.timestamp;
      return this.lastBackup = timestamp;
    });
  };

  HeaderView.prototype._updateConnectionState = function(state, addTooltip) {
    if (addTooltip == null) {
      addTooltip = false;
    }
    this._updateConnectionIndicator(state, addTooltip);
    this._updateConnectionState.lastState = state;
    if (state === 'connectionTimeout') {
      return this._connectionTimeout();
    } else if (state === 'disconnected' || state === 'serverError') {
      return this._disconnected();
    } else {
      return this._connected();
    }
  };

  HeaderView.prototype._updateConnectionIndicator = function(state, addTooltip) {
    var indicator;
    if (addTooltip == null) {
      addTooltip = false;
    }
    indicator = this.$('button.connection');
    indicator.prop('class', "connection " + state);
    if (addTooltip) {
      return this._addTooltips('dynamicInfo', indicator, (function(_this) {
        return function() {
          return $.t("connection." + _this._updateConnectionState.lastState);
        };
      })(this));
    }
  };

  return HeaderView;

})(View);
