var QuestionExporter, qualities,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

qualities = require('models/outcome/qualities');

module.exports = QuestionExporter = (function() {
  function QuestionExporter(_originalQuestion) {
    this._originalQuestion = _originalQuestion;
    this.exportExplanations = __bind(this.exportExplanations, this);
    this._outcomesTypes = {
      'TP': 'TruePositive',
      'TN': 'TrueNegative',
      'FP': 'FalsePositive',
      'FN': 'FalseNegative',
      'Inconclusive': 'InconclusiveTestResult',
      'Complications': 'Complication'
    };
    this._outcomesNameConversion = {
      'TP': $.t('es:outcome.diagnostic.TP'),
      'TN': $.t('es:outcome.diagnostic.TN'),
      'FP': $.t('es:outcome.diagnostic.FP'),
      'FN': $.t('es:outcome.diagnostic.FN')
    };
  }

  QuestionExporter.prototype._convertOutcomeName = function(originalName) {
    if (originalName in this._outcomesNameConversion) {
      return this._outcomesNameConversion[originalName];
    } else {
      return originalName;
    }
  };

  QuestionExporter.prototype._processOutcome = function(outcome, i) {
    var name, originalName, types;
    originalName = outcome['name'];
    name = this._convertOutcomeName(originalName);
    types = ['Outcome'];
    if (originalName in this._outcomesTypes) {
      types.push(this._outcomesTypes[originalName]);
    }
    return {
      '@id': "_:o" + (i + 1),
      '@type': types,
      'name': name
    };
  };

  QuestionExporter.prototype.exportOutcomes = function() {
    var exportedOutcomes, i, originalOutcomes, outcome, _i, _len;
    originalOutcomes = this._originalQuestion['outcomes'];
    exportedOutcomes = (function() {
      var _i, _len, _results;
      _results = [];
      for (i = _i = 0, _len = originalOutcomes.length; _i < _len; i = ++_i) {
        outcome = originalOutcomes[i];
        _results.push(this._processOutcome(outcome, i));
      }
      return _results;
    }).call(this);
    this._outcomes = {};
    for (i = _i = 0, _len = originalOutcomes.length; _i < _len; i = ++_i) {
      outcome = originalOutcomes[i];
      this._outcomes[outcome['name']] = "_:o" + (i + 1);
    }
    return exportedOutcomes;
  };

  QuestionExporter.prototype._processExplanation = function(explanation, i) {
    var exportedExplanation;
    exportedExplanation = {
      '@id': "_:e" + i,
      'text': explanation
    };
    return exportedExplanation;
  };

  QuestionExporter.prototype.exportExplanations = function() {
    var explanation, exportedExplanations, footnotes, i;
    exportedExplanations = [this._processExplanation('no_explanation_provided', 0)];
    footnotes = this._originalQuestion['footnotesList']['notes'];
    for (i in footnotes) {
      explanation = footnotes[i];
      exportedExplanations.push(this._processExplanation(explanation, i));
    }
    this._explanations = {
      'no_explanation_provided': '_:e0'
    };
    for (i in footnotes) {
      this._explanations[i] = '_:e' + i;
    }
    return exportedExplanations;
  };

  QuestionExporter.prototype._findExplanations = function(explanations, key, score) {
    var id, _i, _len, _ref, _results;
    if (explanations[key] == null) {
      if (score >= 0) {
        return [];
      } else {
        return [
          {
            '@id': '_:e0'
          }
        ];
      }
    } else {
      _ref = explanations[key];
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        id = _ref[_i];
        _results.push({
          '@id': this._explanations[id.toString()]
        });
      }
      return _results;
    }
  };

  QuestionExporter.prototype._qualityNameForValue = function(qualityValue) {
    var quality;
    if (qualityValue > 4) {
      qualityValue = 4;
    }
    if (qualityValue < 1) {
      qualityValue = 1;
    }
    quality = qualities["" + qualityValue].toLowerCase().replace(/\ /g, "_");
    return $.t("es:outcome.qualities." + quality).toUpperCase();
  };

  QuestionExporter.prototype._getQualityOptionForLabel = function(qualityLabel, field) {
    if (field == null) {
      field = '';
    }
    if (qualityLabel === '') {
      return '';
    } else {
      switch (field) {
        case 'publicationBias':
          if (qualityLabel === 'not_serious') {
            return $.t('es:outcome.undetected');
          } else {
            return $.t('es:outcome.publication_bias_strongly_suspected_short');
          }
          break;
        default:
          return $.t("es:outcome." + qualityLabel);
      }
    }
  };

  QuestionExporter.prototype._qualityLabelForScore = function(score) {
    switch (score) {
      case 0:
        return 'NoChange';
      case -1:
        return 'DowngradeOneLevel';
      case -2:
        return 'DowngradeTwoLevels';
      case 1:
        return 'UpgradeOneLevel';
      case 2:
        return 'UpgradeTwoLevels';
      default:
        return null;
    }
  };

  return QuestionExporter;

})();
