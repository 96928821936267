var DS, Footnotes, FootnotesBinding, Model, Outcome, PouchMemento, QS, mediator, su,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

Model = require('models/base/model');

QS = require('models/outcome/quality_scores');

DS = require('models/outcome/design_studies');

su = require('lib/statistical_utils');

FootnotesBinding = require('lib/traits/footnotes_binding');

PouchMemento = require('lib/traits/pouch_memento');

mediator = require('mediator');

Footnotes = require('models/evidence_syntheses/footnotes');

module.exports = Outcome = (function(_super) {
  __extends(Outcome, _super);

  function Outcome() {
    return Outcome.__super__.constructor.apply(this, arguments);
  }

  Outcome.QUALITY_MODIFIERS = ['riskOfBias', 'inconsistency', 'indirectness', 'imprecision', 'publicationBias', 'largeEffect', 'plausibleConfounding', 'doseResponseGradient'];

  Outcome.DEPENDENT_PROPERTIES = {
    importanceLabel: 'updateImportance',
    quality: 'updateQuality'
  };

  Outcome.areQualityModifiersSet = function(qualityModifierValues) {
    return _(qualityModifierValues).compact().length === Outcome.QUALITY_MODIFIERS.length;
  };

  Outcome.prototype.defaults = {
    name: $.t('es:outcome.new_outcome'),
    type: 'dich',
    noOfStudies: '',
    designStudies: void 0,
    observationalStudy: void 0,
    riskOfBias: QS.NoSerious,
    inconsistency: QS.NoSerious,
    indirectness: QS.NoSerious,
    imprecision: QS.NoSerious,
    publicationBias: void 0,
    largeEffect: void 0,
    plausibleConfounding: void 0,
    doseResponseGradient: void 0,
    effectMeasure: '',
    effectRate: '',
    effectDenominator: '',
    effectOtherDenominator: '',
    effectDuration: '',
    effectOtherDuration: '',
    effectCount: '',
    effectTotal: '',
    interventionCount: '',
    interventionTotal: '',
    controlCount: '',
    controlTotal: '',
    calculatedControl: true,
    lowControl: false,
    moderateControl: false,
    highControl: false,
    lowControlRisk: 0,
    moderateControlRisk: 0,
    highControlRisk: 0,
    lowControlLabel: $.t("es:outcome.low_control_label"),
    moderateControlLabel: $.t("es:outcome.moderate_control_label"),
    highControlLabel: $.t("es:outcome.high_control_label"),
    effectSize: null,
    confidenceIntervalFrom: null,
    confidenceIntervalTo: null,
    followUpLength: 0,
    followUpLengthTo: 0,
    absDenominator: 1000,
    perPatients: 1000,
    perTimeFrame: 'years',
    perPatientsOther: null,
    perTimeFrameOther: null,
    absEffectSize: null,
    absEffectFrom: null,
    absEffectTo: null,
    absEffectAutoCalc: true,
    absEffectSizeLow: null,
    absEffectFromLow: null,
    absEffectToLow: null,
    absEffectAutoCalcLow: true,
    absEffectSizeModerate: null,
    absEffectFromModerate: null,
    absEffectToModerate: null,
    absEffectAutoCalcModerate: true,
    absEffectSizeHigh: null,
    absEffectFromHigh: null,
    absEffectToHigh: null,
    absEffectAutoCalcHigh: true,
    comments: '',
    populationDirectnessDesc: '',
    interventionDirectnessDesc: '',
    comparisonDirectnessDesc: '',
    direct_comparisonDirectnessDesc: '',
    outcomeDirectnessDesc: '',
    populationDirectnessRadio: '',
    interventionDirectnessRadio: '',
    comparisonDirectnessRadio: '',
    direct_comparisonDirectnessRadio: '',
    outcomeDirectnessRadio: '',
    withOutcome: 0,
    withoutOutcome: 0
  };

  Outcome.prototype.constraints = {
    importance: {
      values: [1, 2, 3, 4, 5, 6, 7, 8, 9]
    }
  };

  Outcome.prototype.validation = {
    noOfStudies: {
      pattern: 'number',
      min: 0,
      required: false
    },
    interventionCount: {
      pattern: 'number',
      min: 0,
      required: false
    },
    interventionTotal: {
      pattern: 'number',
      min: 0,
      required: false
    },
    controlCount: {
      pattern: 'number',
      min: 0,
      required: false
    },
    controlTotal: {
      pattern: 'number',
      min: 0,
      required: false
    },
    effectSize: {
      pattern: 'number',
      required: false
    },
    confidenceIntervalFrom: {
      pattern: 'number',
      required: false
    },
    confidenceIntervalTo: {
      pattern: 'number',
      required: false
    },
    absEffectSize: {
      pattern: 'numberOrEmpty',
      required: false
    },
    absEffectFrom: {
      pattern: 'numberOrEmpty',
      required: false
    },
    absEffectTo: {
      pattern: 'numberOrEmpty',
      required: false
    },
    effectTotal: {
      pattern: 'numberOrEmpty',
      required: false
    },
    effectCount: {
      pattern: 'numberOrEmpty',
      required: false
    },
    effectRate: {
      required: false
    },
    lowControlRisk: {
      pattern: 'number'
    },
    moderateControlRisk: {
      pattern: 'number'
    },
    highControlRisk: {
      pattern: 'number'
    },
    withOutcome: {
      pattern: 'number'
    },
    noOfPatients: {
      pattern: 'number',
      min: 0,
      required: false
    },
    highControl: function(emptyValue, customAttr, computedState) {
      var checkedControls;
      checkedControls = [computedState['calculatedControl'], computedState['lowControl'], computedState['moderateControl'], computedState['highControl']];
      checkedControls = (_(checkedControls).filter(function(checked) {
        return checked;
      })).length;
      if (checkedControls === 0) {
        return $.t('errors:model.control_risk_not_set');
      } else if (checkedControls === 4) {
        return $.t('errors:model.control_risk_set_max_3');
      }
    }
  };

  Outcome.prototype.effectMeasures = {
    cont: ['MD', 'SMD', 'mean', 'median', 'other'],
    dich: ['RR', 'OR', 'HR', 'rateRatio', 'other']
  };

  Outcome.prototype.initialize = function() {
    Outcome.__super__.initialize.apply(this, arguments);
    this.enable(FootnotesBinding);
    this.on('change:importance', this.updateImportance);
    this.on((Outcome.QUALITY_MODIFIERS.map(function(prop) {
      return "change:" + prop;
    })).join(' '), this.updateQuality);
    this.on('change:designStudies', this.updateQuality);
    this.on('change:designStudies', this.updateControlValues);
    this.on('change:designStudies', this.updateOtherConsideration);
    this.on('change:type', this.updateEffectMeasure);
    this.on('change:noOfStudies', this.noOfStudiesChanged);
    this.on('change:absDenominator', this.absDenominatorUpdated);
    this.initDependentProperties();
    return this.enable(PouchMemento);
  };

  Outcome.prototype.initDependentProperties = function() {
    var method, property, _ref, _results;
    _ref = Outcome.DEPENDENT_PROPERTIES;
    _results = [];
    for (property in _ref) {
      method = _ref[property];
      if (!(this.get(property))) {
        _results.push(this[method]());
      }
    }
    return _results;
  };

  Outcome.prototype.isImportant = function() {
    return this.get('importance') > 3;
  };

  Outcome.prototype.isCritical = function() {
    return this.get('importance') > 6;
  };

  Outcome.prototype.isCaseControl = function() {
    var _ref, _ref1;
    return (_ref = (_ref1 = this.get('designStudies')) != null ? _ref1.value : void 0) === 'case_control_studies' || _ref === 'case_control_and_other_combined';
  };

  Outcome.prototype.updateEffectMeasure = function() {
    var _ref, _ref1;
    if (this.get('effectMeasure') === '') {
      return;
    }
    if (this.get('type') === 'dich' && (_ref = this.get('effectMeasure'), __indexOf.call(this.effectMeasures.dich, _ref) < 0)) {
      return this.set('effectMeasure', this.effectMeasures.dich[0]);
    } else if (this.get('type') === 'cont' && (_ref1 = this.get('effectMeasure'), __indexOf.call(this.effectMeasures.cont, _ref1) < 0)) {
      return this.set('effectMeasure', this.effectMeasures.cont[0]);
    }
  };

  Outcome.prototype.updateImportance = function() {
    var label;
    if (this.get('importance')) {
      label = this.isImportant() ? $.t("es:outcome.important") : $.t("es:outcome.not_important");
      if (this.isCritical()) {
        label = $.t("es:outcome.critical");
      }
      return this.set('importanceLabel', label.toUpperCase());
    } else {
      return this.set('importanceLabel', '');
    }
  };

  Outcome.prototype.updateQuality = function() {
    var baseScore, score, _ref;
    baseScore = (_ref = this.get('designStudies')) != null ? _ref.baseScore : void 0;
    if (baseScore) {
      score = Outcome.QUALITY_MODIFIERS.reduce((function(_this) {
        return function(acc, prop) {
          if (_this.get(prop)) {
            return acc += _this.get(prop).score;
          }
        };
      })(this), baseScore);
      return this.set('quality', Math.max(1, Math.min(4, score)));
    } else {
      return this.set('quality', null);
    }
  };

  Outcome.prototype.updateControlValues = function() {
    var riskControls;
    if (this.isCaseControl()) {
      this.set('calculatedControl', false);
      riskControls = [this.get('lowControl'), this.get('moderateControl'), this.get('highControl')];
      if (!(_(riskControls).filter(function(control) {
        return control;
      })).length) {
        return this.set('lowControl', true);
      }
    }
  };

  Outcome.prototype.getControlAttributes = function() {
    return ['calculatedControl', 'lowControl', 'moderateControl', 'highControl'];
  };

  Outcome.prototype.getControlChecks = function() {
    return {
      'calculatedControl': this.get('calculatedControl'),
      'lowControl': this.get('lowControl'),
      'moderateControl': this.get('moderateControl'),
      'highControl': this.get('highControl')
    };
  };

  Outcome.prototype.getActiveControlChecks = function() {
    var controlCheck, result, value, _ref;
    result = [];
    _ref = this.getControlChecks();
    for (controlCheck in _ref) {
      value = _ref[controlCheck];
      if (value) {
        result.push(controlCheck);
      }
    }
    return result;
  };

  Outcome.prototype.getControlChecksSize = function() {
    return (_(_(this.getControlChecks()).values()).filter(function(checked) {
      return checked;
    })).length;
  };

  Outcome.prototype.set = function(key, value, options) {
    if (__indexOf.call(Outcome.QUALITY_MODIFIERS, key) >= 0) {
      value = QS.get(value);
    }
    if (key === 'designStudies') {
      if (value && value.indexOf('.') !== -1) {
        value = DS.get(value.substring(0, value.indexOf('.')), value.substring(value.indexOf('.') + 1));
      } else {
        value = DS.get(value);
      }
    }
    if (key === 'absDenominator') {
      value = parseInt(value);
    }
    return Outcome.__super__.set.call(this, key, value, options);
  };

  Outcome.prototype.save = function() {
    this.calculateAbsoluteEffect();
    if (this.get('type') === 'narrative' && !this.get('metaType')) {
      this.set('metaType', 'NOT_POOLED', {
        auto: true
      });
    }
    return Outcome.__super__.save.apply(this, arguments);
  };

  Outcome.prototype.setFixed = function(key, value, scale) {
    var v;
    if (scale == null) {
      scale = 2;
    }
    v = value === null || isNaN(value) ? '' : value.toFixed(scale);
    return this.set(key, v);
  };

  Outcome.prototype.recalculateRatio = function(fromMeasure, toMeasure) {
    var cer, fromMeasureString, measureStringMapping, recalculateFunction, toMeasureString;
    cer = su.getControlEventRate(this.get('controlCount'), this.get('controlTotal'));
    measureStringMapping = {
      'RR': 'risk',
      'OR': 'odds',
      'HR': 'hazard',
      'RD': 'difference'
    };
    fromMeasureString = measureStringMapping[fromMeasure];
    toMeasureString = measureStringMapping[toMeasure];
    recalculateFunction = function() {};
    if (fromMeasure === 'RD') {
      recalculateFunction = su.riskRatioFromRiskDifference;
    } else if (fromMeasure === 'RR' || toMeasure === 'RR') {
      recalculateFunction = su["" + toMeasureString + "RatioFrom" + (_.string.capitalize(fromMeasureString)) + "Ratio"];
    } else {
      recalculateFunction = (function(_this) {
        return function(cer, effectSize) {
          return su["" + toMeasureString + "RatioFromRiskRatio"](cer, su["riskRatioFrom" + (_.string.capitalize(fromMeasureString)) + "Ratio"](cer, effectSize));
        };
      })(this);
    }
    this.setFixed('effectSize', recalculateFunction(cer, parseFloat(this.get('effectSize'))));
    this.setFixed('confidenceIntervalFrom', recalculateFunction(cer, parseFloat(this.get('confidenceIntervalFrom'))));
    this.setFixed('confidenceIntervalTo', recalculateFunction(cer, parseFloat(this.get('confidenceIntervalTo'))));
    return this.set('effectMeasure', toMeasure);
  };

  Outcome.prototype.absDenominatorUpdated = function() {
    var from, q, to, valueName, _i, _len, _ref, _ref1, _results;
    this.calculateAbsoluteEffect();
    from = this.previous('absDenominator');
    to = this.get('absDenominator');
    if (!this.get('absEffectAutoCalc') && !isNaN(to)) {
      q = to / from;
      _ref = ['absEffectSize', 'absEffectFrom', 'absEffectTo'];
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        valueName = _ref[_i];
        _results.push(this.set(valueName, ((_ref1 = this.get(valueName)) != null ? _ref1 : 0 * q).toFixed(2)));
      }
      return _results;
    }
  };

  Outcome.prototype.calculateAbsoluteEffect = function(forceCalculation) {
    var lvl, _i, _len, _ref, _results;
    if (forceCalculation == null) {
      forceCalculation = false;
    }
    if (this.get('type') !== 'dich') {
      return;
    }
    _ref = ['', 'Low', 'Moderate', 'High'];
    _results = [];
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      lvl = _ref[_i];
      if (this.get("absEffectAutoCalc" + lvl) || forceCalculation) {
        _results.push(this._calculateAbsoluteEffect(lvl.toLowerCase()));
      } else {
        _results.push(void 0);
      }
    }
    return _results;
  };

  Outcome.prototype._calculateAbsoluteEffect = function(defaultCerName) {
    var absInterval, cer;
    if (defaultCerName) {
      cer = (this.get("" + defaultCerName + "ControlRisk")) / 100;
    }
    defaultCerName = defaultCerName ? _.string.capitalize(defaultCerName) : '';
    this.set("absEffectSize" + defaultCerName, su.calculateAbsoluteEffectSize(this.attributes, cer));
    absInterval = su.calculateAbsoluteEffectInterval(this.attributes, cer);
    this.set("absEffectFrom" + defaultCerName, absInterval[0]);
    return this.set("absEffectTo" + defaultCerName, absInterval[1]);
  };

  Outcome.prototype.recalculateOutcomeData = function() {
    return this.calculateAbsoluteEffect();
  };

  Outcome.prototype.getFootnotesIds = function() {
    return _(this.get('footnotes')).chain().flatten().uniq().value();
  };

  Outcome.prototype.noOfStudiesChanged = function(outcome, newValue, options) {
    var outcomeType;
    outcomeType = this.get('type');
    if (options.external || newValue === 0 || outcomeType === 'narrative') {
      return;
    }
    if (newValue === 1) {
      return this.set('metaType', 'SINGLE_STUDY', {
        auto: true
      });
    } else {
      if (this.get('metaType') !== 'NOT_POOLED') {
        return this.set('metaType', 'POOLED', {
          auto: true
        });
      }
    }
  };

  Outcome.prototype.isQualityDowngradedWithoutExplanation = function(attribute, changedAttributes) {
    var _ref, _ref1;
    if (changedAttributes == null) {
      changedAttributes = this.changedAttributes();
    }
    return ((_ref = changedAttributes[attribute]) != null ? _ref.score : void 0) < 0 && !((_ref1 = this.get('footnotes')[attribute]) != null ? _ref1.length : void 0);
  };

  Outcome.prototype.updateOtherConsideration = function() {
    var _ref;
    if (((_ref = this.get('designStudies')) != null ? _ref.name : void 0) !== 'RandTrials') {
      return;
    }
    if (!this.get('largeEffect')) {
      this.set('largeEffect', "No");
    }
    if (!this.get('doseResponseGradient')) {
      this.set('doseResponseGradient', "No");
    }
    if (!this.get('plausibleConfounding')) {
      return this.set('plausibleConfounding', "No");
    }
  };

  return Outcome;

})(Model);
