var GeneralScopeActions, GeneralScopeStore, PouchSource, alt, immutable, mediator;

alt = require('alt');

mediator = require('mediator');

GeneralScopeActions = require('actions/general_scope_actions');

PouchSource = require('stores/sources/pouch_source');

immutable = require('stores/utils/immutable');

GeneralScopeStore = (function() {
  function GeneralScopeStore() {
    var _meta;
    _meta = {
      activeContentEditableId: ''
    };
    this.state = Immutable.Map({
      scope: {},
      _meta: Immutable.Map(_meta)
    });
    this.exportPublicMethods({
      getActiveContentEditable: this.getActiveContentEditable,
      isFetching: this.isFetching
    });
    this.bindActions(GeneralScopeActions);
    this.registerAsync(PouchSource(GeneralScopeActions));
  }

  GeneralScopeStore.prototype.onFetch = function(data) {
    this.setState(this.state.setIn(['_meta', 'fetching'], true));
    return this.getInstance().fetch(data.projectId, data.projectId);
  };

  GeneralScopeStore.prototype.onPouchFetchSuccess = function(projectDoc) {
    var _ref;
    return this.setState(this.state.set('scope', Immutable.fromJS((_ref = projectDoc.scope) != null ? _ref : {})).setIn(['_meta', 'fetching'], false));
  };

  GeneralScopeStore.prototype.onPouchFetchFailure = function(e) {
    this.setState(this.state.setIn(['_meta', 'fetching'], false));
    return console.error(e);
  };

  GeneralScopeStore.prototype.onPouchSaveSuccess = function(projectDoc) {
    return this.setState(this.state.set('scope', Immutable.fromJS(projectDoc.scope)));
  };

  GeneralScopeStore.prototype.onEnableContentEditable = function(contentEditableId) {
    return this.setState(this.state.setIn(['_meta', 'activeContentEditableId'], contentEditableId));
  };

  GeneralScopeStore.prototype.onDisableContentEditable = function() {
    return this.setState(this.state.setIn(['_meta', 'activeContentEditableId'], ''));
  };

  GeneralScopeStore.prototype.onSaveContentEditable = function() {
    var doc;
    doc = mediator.project.toJSON();
    doc.scope = this.state.get('scope').toJS();
    return this.getInstance().save(mediator.project.id, doc);
  };

  GeneralScopeStore.prototype.onUpdateContentEditableContent = function(data) {
    var content, fieldName;
    fieldName = data.fieldName, content = data.content;
    return this.setState(this.state.setIn(['scope', fieldName], content));
  };

  GeneralScopeStore.prototype.isFetching = function() {
    return this.state.getIn(['_meta', 'fetching']);
  };

  GeneralScopeStore.prototype.getActiveContentEditable = function() {
    return this.state.getIn(['_meta', 'activeContentEditableId']);
  };

  return GeneralScopeStore;

})();

module.exports = alt.createStore(immutable(GeneralScopeStore), 'GeneralScopeStore');
