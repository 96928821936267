var AdaptiveLayout, FileSaver, HtmlExport, IEView, QuestionExportView, Rm5Export, mediator, template, utils,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

IEView = require('views/import_export_view');

template = require('views/templates/question_export');

mediator = require('mediator');

AdaptiveLayout = require('base/lib/traits/adaptive_layout');

HtmlExport = require('lib/html_export');

Rm5Export = require('lib/rm5_export');

FileSaver = require('lib/file_saver');

utils = require('base/lib/utils');

module.exports = QuestionExportView = (function(_super) {
  __extends(QuestionExportView, _super);

  function QuestionExportView() {
    return QuestionExportView.__super__.constructor.apply(this, arguments);
  }

  QuestionExportView.prototype.id = 'outcomes-export-dialog';

  QuestionExportView.prototype.template = template;

  QuestionExportView.prototype.outcomesExportSize = 7;

  QuestionExportView.prototype.exceedExportSize = false;

  QuestionExportView.prototype.shortcuts = {
    'esc': 'dispose'
  };

  QuestionExportView.prototype.typeToi18nKeyMap = {
    OneRow: 'grade_one_row',
    SOF: 'sof_table',
    ACCP: 'grade_v2',
    SOF_ACCP: 'sof_table_v2',
    SOF_v3: 'sof_table_v3',
    LayerOne: 'diag_sof-l1',
    LayerTwo: 'diag_sof-l2',
    LayerOneSof: 'diag_sof',
    LayerTwoSof: 'diag_sof-l2',
    Prognosis: 'prognosis',
    recommendations: 'recommendations'
  };

  QuestionExportView.prototype.typeToPrinoutMap = {
    OneRow: 'oneRow',
    SOF: 'sof',
    ACCP: 'ACCP_grade',
    SOF_ACCP: 'ACCP_sof',
    SOF_v3: 'SOF_v3',
    LayerOne: 'layerOne',
    LayerTwo: 'layerTwo',
    LayerOneSof: 'layerOneSof',
    LayerTwoSof: 'layerTwoSof',
    Prognosis: 'prognosis',
    recommendations: 'recommendations-v1'
  };

  QuestionExportView.prototype.offsetY = 50;

  QuestionExportView.prototype.initialize = function() {
    this.options.position = 'top';
    this.options.offsetY = this.offsetY;
    QuestionExportView.__super__.initialize.apply(this, arguments);
    this.delegate('click', 'button.cancel', this.dispose);
    this.delegate('click', 'button.download', this.download);
    this.delegate('change', '.select-all', this.toggleAll);
    this.delegate('change', 'input[type="checkbox"]', this.toggleSelected);
    this.delegate('change', 'input[name="exportOutcomes"]', this.outcomeToggled);
    this.delegate('change', 'input[name="formatType"]', this._formatSelected);
    return this.enable(AdaptiveLayout);
  };

  QuestionExportView.prototype.fixLayout = function() {
    var availableHeight, notifierContainerHeight, otherElementsHeight, outcomesList, outcomesListHeight;
    availableHeight = $(window).height() - this.offsetY;
    notifierContainerHeight = this.$el.parents('.notifier-inner').outerHeight(true);
    outcomesList = this.$('.outcomes-to-export');
    otherElementsHeight = notifierContainerHeight - outcomesList.height();
    if (notifierContainerHeight > availableHeight) {
      outcomesListHeight = availableHeight - otherElementsHeight;
      return outcomesList.height(outcomesListHeight);
    }
  };

  QuestionExportView.prototype._getCheckedDownloadFormat = function() {
    return this.$el.find('input[name="formatType"]:checked').val();
  };

  QuestionExportView.prototype._getCheckedOutcomesNumber = function() {
    return this.$("input[name=exportOutcomes]:checked").length;
  };

  QuestionExportView.prototype.outcomeToggled = function(e) {
    this.$lastToggledOutcome = $(e.target);
    this.toggleDownloadButton();
    if (this._getCheckedOutcomesNumber() > this.outcomesExportSize) {
      return this.outcomesExportSizeExceeded((function(_this) {
        return function(confirmed) {
          if (!confirmed) {
            _this.$lastToggledOutcome.prop('checked', false);
          }
          if (confirmed) {
            return _this.exceedExportSize = true;
          }
        };
      })(this));
    }
  };

  QuestionExportView.prototype.outcomesExportSizeExceeded = function(cb) {
    var message;
    if (this.exceedExportSize) {
      return;
    }
    message = $.t('projects:export_dialog.outcomes_size_exceeded', {
      outcomesExportSize: this.outcomesExportSize
    });
    return mediator.dialogs.confirm({
      yesClass: 'danger',
      message: message
    }, cb);
  };

  QuestionExportView.prototype._isRecommendationOrDiagnosticExport = function() {
    return this.model.isDiagnostic() || this.options.submodule === 'recommendations';
  };

  QuestionExportView.prototype.toggleDownloadButton = function() {
    var buttonEnabled;
    buttonEnabled = (this._isRecommendationOrDiagnosticExport() || this._getCheckedOutcomesNumber() > 0) && this._getCheckedDownloadFormat();
    return this.$('button.download').prop('disabled', !buttonEnabled);
  };

  QuestionExportView.prototype.toggleAll = function() {
    var outcomes, selectAllInput;
    selectAllInput = this.$el.find('.select-all input')[0];
    outcomes = this.$el.find('input[name="exportOutcomes"]');
    if (selectAllInput.checked && outcomes.length > 7) {
      return this.outcomesExportSizeExceeded((function(_this) {
        return function(confirmed) {
          _this.exceedExportSize = confirmed;
          outcomes.prop('checked', confirmed);
          return $(selectAllInput).prop('checked', confirmed);
        };
      })(this));
    } else {
      return outcomes.prop('checked', selectAllInput.checked);
    }
  };

  QuestionExportView.prototype._formatSelected = function() {
    var orientationCannotBeSet;
    orientationCannotBeSet = this._getCheckedDownloadFormat() === 'rm5';
    this.$('.orientations-list input').prop('disabled', orientationCannotBeSet);
    this.$('.orientations-list label').toggleClass('label-disabled', orientationCannotBeSet);
    return this.toggleDownloadButton();
  };

  QuestionExportView.prototype._selectDefaultOrientation = function() {
    var defaultOrientation;
    defaultOrientation = this._getViewType().orientation;
    return this.$(".orientations-list input[value='" + defaultOrientation + "']").prop('checked', true);
  };

  QuestionExportView.prototype._getViewType = function() {
    if (this.options.submodule === 'recommendations') {
      return {
        name: 'recommendations',
        orientation: 'landscape',
        margins: {
          top: 12.7,
          right: 12.7,
          bottom: 12.7,
          left: 12.7
        }
      };
    } else {
      return mediator.services.esView.getPreferredType(mediator.project, this.model.get('type'));
    }
  };

  QuestionExportView.prototype._getAppendices = function() {
    var appendices, insertedElements;
    appendices = [];
    insertedElements = $('#recommendations').find('div.inserted').toArray();
    insertedElements.images = 0;
    insertedElements.recordedTableTypes = [];
    _(insertedElements).forEach(function(insertedElement) {
      var appendixType;
      appendixType = insertedElement.getAttribute('appendix-type');
      if (appendixType === 'image') {
        return appendices.push({
          id: insertedElement.getAttribute('imageId'),
          isImage: true,
          name: "Image " + (++insertedElements.images),
          section: _.str.humanize(insertedElement.getAttribute('section')).toUpperCase()
        });
      } else {
        if (__indexOf.call(insertedElements.recordedTableTypes, appendixType) < 0) {
          insertedElements.recordedTableTypes.push(appendixType);
          return appendices.push({
            id: appendixType
          });
        }
      }
    });
    return appendices;
  };

  QuestionExportView.prototype.download = function() {
    var checkedCids, docx, el, filename, htmlExport, margins, orientation, rm5Export, viewType;
    viewType = this._getViewType();
    checkedCids = viewType.name === 'recommendations' ? (function() {
      var _i, _len, _ref, _results;
      _ref = this.$("input[name=exportAppendix]:checked");
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        el = _ref[_i];
        _results.push($(el).attr('id'));
      }
      return _results;
    }).call(this) : (function() {
      var _i, _len, _ref, _results;
      _ref = this.$("input[name=exportOutcomes]:checked");
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        el = _ref[_i];
        _results.push($(el).attr('id'));
      }
      return _results;
    }).call(this);
    filename = (function(_this) {
      return function(ext) {
        return "" + (_this.model.get('question').substring(0, 100)) + "." + ext;
      };
    })(this);
    orientation = this.$('.orientations-list input:checked').val();
    switch (this._getCheckedDownloadFormat()) {
      case 'html':
        htmlExport = new HtmlExport(this.model, this.typeToPrinoutMap[viewType.name], checkedCids);
        htmlExport["export"](orientation, viewType.margins);
        return this.dispose();
      case 'doc':
        htmlExport = new HtmlExport(this.model, this.typeToPrinoutMap[viewType.name], checkedCids, {
          word: true
        });
        margins = _.object(_(viewType.margins).map(function(v, k) {
          return [k, utils.millimetersToTwips(v)];
        }));
        docx = window.htmlDocx.asBlob(htmlExport.getSource(), {
          orientation: orientation,
          margins: margins
        });
        this.dispose();
        return FileSaver.saveFile(docx, filename('docx')).then(function() {
          return mediator.dialogs.info({
            message: $.t('projects:export_dialog.export_docx_feedback'),
            ms: 10000,
            closeBtn: true
          });
        }, function(reason) {
          if (reason !== 'cancelled') {
            return mediator.dialogs.error(reason);
          }
        });
      case 'rm5':
        rm5Export = new Rm5Export(this.model, this.typeToPrinoutMap[viewType.name].toLowerCase(), checkedCids);
        rm5Export["export"]();
        return this.dispose();
      case 'pdf':
        if (!mediator.services.replication.isConnected()) {
          mediator.dialogs.warning($.t('projects:export_dialog.types.pdf_offline'));
          return;
        }
        this.$('button.download').addClass('loading').forceRedraw();
        htmlExport = new HtmlExport(this.model, this.typeToPrinoutMap[viewType.name], checkedCids);
        return mediator.services.pdfExport["export"](htmlExport.getSource(), {
          orientation: orientation,
          margins: viewType.margins
        }).then((function(_this) {
          return function(_arg) {
            var blob, destroyRequest;
            blob = _arg[0], destroyRequest = _arg[1];
            _this.dispose();
            return FileSaver.saveFile(blob, filename('pdf')).otherwise(function(reason) {
              if (reason !== 'cancelled') {
                return mediator.dialogs.error(reason);
              }
            }).then(destroyRequest);
          };
        })(this)).ensure(function() {
          return this.$('button.download').removeClass('loading');
        }).done();
    }
  };

  QuestionExportView.prototype.afterRender = function() {
    QuestionExportView.__super__.afterRender.apply(this, arguments);
    if (this.model.get('outcomes').models.length <= this.outcomesExportSize) {
      this.$('input[name=exportOutcomes]').prop('checked', true);
    }
    this._selectDefaultOrientation();
    this.toggleDownloadButton();
    this.markSelected();
    return this.fixLayout();
  };

  QuestionExportView.prototype.getTemplateData = function() {
    var appendices, outcome, outcomes, viewTypeName;
    viewTypeName = this._getViewType().name;
    if (viewTypeName === 'recommendations') {
      appendices = this._getAppendices();
    } else {
      outcomes = (function() {
        var _i, _len, _ref, _results;
        _ref = this.model.get('outcomes').models;
        _results = [];
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          outcome = _ref[_i];
          _results.push({
            id: outcome.cid,
            name: outcome.get('name'),
            importanceLabel: outcome.get('importanceLabel')
          });
        }
        return _results;
      }).call(this);
    }
    return _(QuestionExportView.__super__.getTemplateData.apply(this, arguments)).extend({
      viewTypeName: viewTypeName,
      outcomesExportSize: this.outcomesExportSize,
      outcomes: outcomes,
      appendices: (appendices != null ? appendices.length : void 0) && appendices,
      displayOutcomes: !this._isRecommendationOrDiagnosticExport(),
      title: $.t('projects:export_dialog.export_table_title', {
        viewName: $.t("es:outcome_view_type." + this.typeToi18nKeyMap[viewTypeName])
      })
    });
  };

  return QuestionExportView;

})(IEView);
