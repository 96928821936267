var DownloadifyDialog, ModalView, W, isSafari, mediator, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModalView = require('base/views/modal_view');

W = require('when');

template = require('views/templates/downloadify_dialog');

mediator = require('mediator');

DownloadifyDialog = (function(_super) {
  __extends(DownloadifyDialog, _super);

  function DownloadifyDialog() {
    this.dispose = __bind(this.dispose, this);
    return DownloadifyDialog.__super__.constructor.apply(this, arguments);
  }

  DownloadifyDialog.prototype.className = 'downloadify-dialog';

  DownloadifyDialog.prototype.template = template;

  DownloadifyDialog.prototype.autoRender = false;

  DownloadifyDialog.prototype.initialize = function() {
    var reader;
    DownloadifyDialog.__super__.initialize.apply(this, arguments);
    this._deferred = W.defer();
    this.delegate('click', 'button.cancel', this.dispose);
    reader = new FileReader();
    reader.onloadend = (function(_this) {
      return function() {
        _this.data = window.btoa(reader.result);
        return _this.render();
      };
    })(this);
    return reader.readAsBinaryString(this.options.blob);
  };

  DownloadifyDialog.prototype.promise = function() {
    return this._deferred.promise;
  };

  DownloadifyDialog.prototype._sanitizeFilename = function(filename) {
    var base, extension, _ref;
    _ref = _.str.chop(filename, filename.lastIndexOf('.')), base = _ref[0], extension = _ref[1];
    return "" + (_.str.slugify(base)) + extension;
  };

  DownloadifyDialog.prototype.afterRender = function() {
    var buttonContainer;
    DownloadifyDialog.__super__.afterRender.apply(this, arguments);
    buttonContainer = this.$('.download-button');
    return this.$('#downloadify-wrapper').downloadify({
      swf: 'downloadify.swf',
      width: buttonContainer.width(),
      height: buttonContainer.height(),
      downloadImage: 'images/downloadify.png',
      filename: this._sanitizeFilename(this.options.fileName),
      data: this.data,
      dataType: 'base64',
      transparent: true,
      onCancel: this.dispose,
      onComplete: (function(_this) {
        return function() {
          _this._deferred.resolve();
          return _this._deferred.promise.then(_this.dispose);
        };
      })(this)
    });
  };

  DownloadifyDialog.prototype.dispose = function() {
    if (this._deferred.promise.inspect().state === 'pending') {
      this._deferred.reject('cancelled');
    }
    return DownloadifyDialog.__super__.dispose.apply(this, arguments);
  };

  return DownloadifyDialog;

})(ModalView);

isSafari = function() {
  return Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
};

module.exports = {
  saveFile: function(blob, fileName) {
    var dialog;
    if (isSafari()) {
      if (swfobject.hasFlashPlayerVersion('10')) {
        dialog = new DownloadifyDialog({
          blob: blob,
          fileName: fileName
        });
        return dialog.promise();
      } else {
        return W.reject($.t('errors:general.no_flash_for_safari'));
      }
    } else {
      window.saveAs(blob, fileName);
      return W.resolve();
    }
  }
};
