var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "  "
    + escapeExpression(((helpers.relativeEffectNotEditableCell || (depth0 && depth0.relativeEffectNotEditableCell) || helperMissing).call(depth0, depth0, (depth0 != null ? depth0.colNo : depth0), "{\"className\": \"relative-effect\",\n    \"dataProperty\": \"relativeEffect\"}", {"name":"relativeEffectNotEditableCell","hash":{},"data":data})))
    + "\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.inStringArray || (depth0 && depth0.inStringArray) || helperMissing).call(depth0, "case_series, case_reports", ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.value : stack1), {"name":"inStringArray","hash":{},"fn":this.program(4, data),"inverse":this.program(6, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"4":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    "
    + escapeExpression(((helpers.relativeEffectNotEditableCell || (depth0 && depth0.relativeEffectNotEditableCell) || helperMissing).call(depth0, depth0, (depth0 != null ? depth0.colNo : depth0), "{\"className\": \"relative-effect\",\n      \"dataProperty\": \"relativeEffect\"}", {"name":"relativeEffectNotEditableCell","hash":{},"data":data})))
    + "\n";
},"6":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.inStringArray || (depth0 && depth0.inStringArray) || helperMissing).call(depth0, "NOT_POOLED, NOT_MEASURED, NOT_REPORTED", (depth0 != null ? depth0.metaType : depth0), {"name":"inStringArray","hash":{},"fn":this.program(7, data),"inverse":this.program(9, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"7":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      "
    + escapeExpression(((helpers.relativeEffectNotEditableCell || (depth0 && depth0.relativeEffectNotEditableCell) || helperMissing).call(depth0, depth0, (depth0 != null ? depth0.colNo : depth0), "{\"className\": \"relative-effect\",\n        \"dataProperty\": \"relativeEffect\"}", {"name":"relativeEffectNotEditableCell","hash":{},"data":data})))
    + "\n";
},"9":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "      <td "
    + escapeExpression(((helper = (helper = helpers.colNo || (depth0 != null ? depth0.colNo : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"colNo","hash":{},"data":data}) : helper)))
    + " data-property=\"relativeEffect\" data-property-type=\"dich\" class=\"relative-effect\"\n        ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.viewTypeName : depth0), "PrintoutSOF", {"name":"is","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n        <div class=\"cell-content\">\n          <span>"
    + escapeExpression(((helpers.displayRelativeEffect || (depth0 && depth0.displayRelativeEffect) || helperMissing).call(depth0, depth0, {"name":"displayRelativeEffect","hash":{},"data":data})))
    + "</span>\n        </div>\n      </td>\n";
},"10":function(depth0,helpers,partials,data) {
  var stack1;
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.rowspan : depth0), {"name":"if","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { return stack1; }
  else { return ''; }
  },"11":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "rowspan="
    + escapeExpression(((helper = (helper = helpers.rowspan || (depth0 != null ? depth0.rowspan : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"rowspan","hash":{},"data":data}) : helper)));
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "cont", {"name":"is","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}