PureRenderMixin = React.addons.PureRenderMixin
Tooltip = require 'components/common/tooltip'

Criterion = React.createClass
  displayName: 'CriterionComponent'
  mixins: [PureRenderMixin]

  render: ->
    <td className="criteria-cell">
      <b>{@props.content}</b>
      {if @props.info
        <Tooltip>
          <span className="info-sign" title={@props.info}></span>
        </Tooltip>
      }
    </td>

module.exports = Criterion
