ModalDialogs = require '../mixins/modal_dialogs'
ContentEditableWithButtons = require './content_editable_with_buttons'
Button = require './button'
InsertDialog = require './insert_dialog'
PureRenderMixin = React.addons.PureRenderMixin
Translation = require 'components/mixins/translation'

ContentEditableWithInsert = React.createClass
  mixins: [ModalDialogs, PureRenderMixin, Translation('es:recommendations')]
  propTypes:
    actions: React.PropTypes.object.isRequired
    attachments: React.PropTypes.object.isRequired

  handleInsert: (data) ->
    @props.actions.insertContent data

  insert: ->
    @displayModal InsertDialog,
      title: @i18n 'insert_modal.title'
      insertOptions: @props.insertOptions
      onSubmit: @handleInsert

  _setImagesSrc: ->
    regExp = /<img[^>]+db-image="([\S]+)"[^>]+src=("")/g
    srcSetter = (imageString, imageName, srcValue) =>
      imageString.replace srcValue, @_getDataURL(imageName)
    @props.content.replace regExp, srcSetter

  _getDataURL: (imageName) ->
    imageAttachment = @props.attachments.get imageName
    return "\"\"" unless imageAttachment

    {content_type, data} = imageAttachment.toJS()
    if content_type and data
      "\"data:#{content_type};base64,#{data}\""
    else
      "\"\""

  componentDidUpdate: (prevProps, prevState) ->
    @refs.contentEditable.focus()

  render: ->
    content = @_setImagesSrc()

    <ContentEditableWithButtons
      {...@props}
      content={content}
      ref="contentEditable"
      topButtons={
        <Button
          className="insert btn btn-block"
          label={$.t("translation:actions.insert")}
          onMouseDown={@insert}
        />
      }
    />

module.exports = ContentEditableWithInsert
