var ScopeToolbarView, View, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

template = require('views/templates/scope/toolbar');

module.exports = ScopeToolbarView = (function(_super) {
  __extends(ScopeToolbarView, _super);

  function ScopeToolbarView() {
    return ScopeToolbarView.__super__.constructor.apply(this, arguments);
  }

  ScopeToolbarView.prototype.container = '#toolbar-container';

  ScopeToolbarView.prototype.className = 'scope-toolbar';

  ScopeToolbarView.prototype.template = template;

  ScopeToolbarView.prototype.autoRender = true;

  ScopeToolbarView.prototype.initialize = function() {
    ScopeToolbarView.__super__.initialize.apply(this, arguments);
    if (!this.options.steps) {
      return;
    }
    this.selectedStep = this.options.currentStep ? this._getStepIndex(this.options.currentStep) : 0;
    return this.subscribeEvent('stepChanged', (function(_this) {
      return function(newStep) {
        _this.selectedStep = _this._getStepIndex(newStep);
        if (newStep === 'questions.finished') {
          _this.selectionFinished = true;
        }
        return _this.render();
      };
    })(this));
  };

  ScopeToolbarView.prototype.render = function() {
    ScopeToolbarView.__super__.render.apply(this, arguments);
    return this._turnOnStepsPreview();
  };

  ScopeToolbarView.prototype.getTemplateData = function() {
    return {
      steps: _(this.options.steps).map(function(it) {
        return {
          i18nKey: "scope:" + it + "_step",
          value: it
        };
      }),
      selectedStepIndex: this.selectedStep
    };
  };

  ScopeToolbarView.prototype._getStepIndex = function(step) {
    return _(this.options.steps).indexOf(step);
  };

  ScopeToolbarView.prototype._isSelectionFinished = function() {
    return this.options.currentStep === 'questions.finished' || this.selectionFinished;
  };

  ScopeToolbarView.prototype._turnOnStepsPreview = function() {
    if (!this._isSelectionFinished()) {
      return;
    }
    this.undelegate();
    this.$('li > span').addClass('scope-step');
    return this.delegate('click', '.scope-step', function(e) {
      return this.publishEvent('stepChanged', $(e.target).data('step'), {
        preview: true
      });
    });
  };

  return ScopeToolbarView;

})(View);
