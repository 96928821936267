var MembersActions, MembersStore, alt, immutable, storeHelpers;

alt = require('alt');

MembersActions = require('actions/members_actions');

immutable = require('stores/utils/immutable');

storeHelpers = require('stores/utils/store_helpers');

MembersStore = (function() {
  function MembersStore() {
    this.state = Immutable.Map().set('members', Immutable.OrderedMap()).set('_meta', Immutable.Map());
    this.bindActions(MembersActions);
    this.exportPublicMethods({
      getMembers: this.getMembers,
      getSelectedMemberIdsForVoting: this.getSelectedMemberIdsForVoting,
      isFetching: this.isFetching
    });
  }

  MembersStore.prototype.getMembers = function() {
    return this.state.get('members');
  };

  MembersStore.prototype.getSelectedMemberIdsForVoting = function() {
    return this.state.getIn(['_meta', 'selectedMembersForVoting']);
  };

  MembersStore.prototype.isFetching = function() {
    return false;
  };

  MembersStore.prototype.onSelectMembersForVoting = function(data) {
    var _ref;
    return this.setState(this.state.setIn(['_meta', 'selectedMembersForVoting'], (_ref = data.ids) != null ? _ref.toList() : void 0));
  };

  MembersStore.prototype.onToggleMemberForVoting = function(data) {
    var path, selectedMembersForVoting, _ref;
    path = ['_meta', 'selectedMembersForVoting'];
    selectedMembersForVoting = (_ref = this.state.getIn(path)) != null ? _ref : Immutable.List();
    selectedMembersForVoting = selectedMembersForVoting.includes(data.id) ? selectedMembersForVoting.filter(function(memberId) {
      return memberId !== data.id;
    }) : selectedMembersForVoting.push(data.id);
    return this.setState(this.state.setIn(path, selectedMembersForVoting));
  };

  MembersStore.prototype.onFetch = function(membersColl) {
    return this.setState(this.state.set('members', storeHelpers.collToOrderedMap(membersColl)));
  };

  MembersStore.prototype.onAdd = function() {
    return this.setState(this.state.withMutations(function(state) {
      return state.set('members', storeHelpers.addNewMapEntry(state.get('members'))).setIn(['_meta', 'editing'], '_new');
    }));
  };

  MembersStore.prototype.onUpdateMember = function(data) {
    return this.setState(this.state.setIn(['members', data.memberId, data.attr], data.value));
  };

  MembersStore.prototype.onSave = function(data) {
    return console.log('saving');
  };

  MembersStore.prototype.onSaveSuccess = function(memberJS) {
    var updatedMembers;
    updatedMembers = storeHelpers.replaceMapEntry(this.state.get('members'), memberJS._id, memberJS);
    return this.setState(this.state.withMutations(function(state) {
      return state.set('members', updatedMembers).deleteIn(['_meta', 'editing']);
    }));
  };

  MembersStore.prototype.onSaveFailure = function(e) {
    if (typeof Raven !== "undefined" && Raven !== null) {
      Raven.captureException(e);
    }
    return console.error(e);
  };

  MembersStore.prototype.onDelete = function(data) {
    return console.log('deleting');
  };

  MembersStore.prototype.onDeleteSuccess = function(memberId) {
    return this.setState(this.state.deleteIn(['members', memberId]));
  };

  MembersStore.prototype.onDeleteFailure = function(e) {
    if (typeof Raven !== "undefined" && Raven !== null) {
      Raven.captureException(e);
    }
    return console.error(e);
  };

  MembersStore.prototype.onEditSuccess = function(memberKey) {
    return this.setState(this.state.setIn(['_meta', 'editing'], memberKey));
  };

  MembersStore.prototype.onValidationSuccess = function(data) {
    var _ref;
    return this.setState(this.state.setIn(['_meta', 'validationErrors', (_ref = data.key) != null ? _ref : '_new'], null));
  };

  MembersStore.prototype.onValidationFailure = function(data) {
    var _ref;
    return this.setState(this.state.setIn(['_meta', 'validationErrors', (_ref = data.key) != null ? _ref : '_new'], Immutable.fromJS(data.validationErrors)));
  };

  return MembersStore;

})();

module.exports = alt.createStore(immutable(MembersStore), 'MembersStore');
