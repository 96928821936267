var Exceptions, Model, QuestionGroup, QuestionGroups, W, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Model = require('models/base/model');

QuestionGroup = require('models/question_group');

Exceptions = require('lib/exceptions');

W = require('when');

utils = require('base/lib/utils');

module.exports = QuestionGroups = (function(_super) {
  __extends(QuestionGroups, _super);

  function QuestionGroups() {
    this._constructGroupModel = __bind(this._constructGroupModel, this);
    return QuestionGroups.__super__.constructor.apply(this, arguments);
  }

  QuestionGroups.prototype._orderCache = {};

  QuestionGroups.prototype.initialize = function() {
    return this.on('change:groups', this._rebuildOrderCache);
  };

  QuestionGroups.prototype._rebuildOrderCache = function() {
    var group, idx, _i, _len, _ref, _results;
    this._orderCache = {};
    _ref = this._getGroups();
    _results = [];
    for (idx = _i = 0, _len = _ref.length; _i < _len; idx = ++_i) {
      group = _ref[idx];
      _results.push(this._orderCache[group._id] = idx);
    }
    return _results;
  };

  QuestionGroups.prototype._getOrderForId = function(id) {
    return this._orderCache[id];
  };

  QuestionGroups.prototype._getGroups = function() {
    var _ref;
    return _.clone((_ref = this.get('groups')) != null ? _ref : []);
  };

  QuestionGroups.prototype.getGroupCaptions = function() {
    return _(this._getGroups()).pluck('caption');
  };

  QuestionGroups.prototype._constructGroupModel = function(g) {
    return new QuestionGroup(g, {
      proxied: this
    });
  };

  QuestionGroups.prototype.getGroupModel = function(id) {
    return this._constructGroupModel(this._getGroups()[this._getOrderForId(id)]);
  };

  QuestionGroups.prototype.getGroupModels = function() {
    return _(this._getGroups()).map(this._constructGroupModel);
  };

  QuestionGroups.prototype.create = function(caption) {
    var groupId, groups, idx;
    if (caption == null) {
      caption = $.t('es:question.groups.new_group');
    }
    groups = this._getGroups();
    groupId = utils.generateGUID();
    idx = groups.push({
      caption: caption,
      _id: groupId
    });
    return this.save('groups', groups).then((function(_this) {
      return function() {
        _this.trigger('groupCreated', groupId, idx);
        return idx - 1;
      };
    })(this));
  };

  QuestionGroups.prototype.moveGroup = function(fromIndex, toIndex) {
    var groups, movedEntry;
    groups = this._getGroups();
    if (!((0 <= fromIndex && fromIndex < groups.length) && (0 <= toIndex && toIndex < groups.length))) {
      return W.reject(new Exceptions.data_changed);
    }
    movedEntry = groups.splice(fromIndex, 1)[0];
    groups.splice(toIndex, 0, movedEntry);
    return this.save('groups', groups);
  };

  QuestionGroups.prototype.rename = function(id, newCaption) {
    var groups, idx;
    groups = this._getGroups();
    idx = this._getOrderForId(id);
    groups[idx].caption = newCaption;
    return this.save('groups', groups);
  };

  QuestionGroups.prototype.remove = function(id) {
    var groups, idx;
    groups = this._getGroups();
    idx = this._getOrderForId(id);
    groups.splice(idx, 1);
    return this.save('groups', groups).then((function(_this) {
      return function() {
        return _this.trigger('groupRemoved', id, idx);
      };
    })(this));
  };

  QuestionGroups.prototype._getMapping = function() {
    var _ref;
    return _.clone((_ref = this.get('mapping')) != null ? _ref : {});
  };

  QuestionGroups.prototype.getGroupIndexForQuestionId = function(id) {
    var groupId;
    groupId = this._getMapping()[id];
    return this._getOrderForId(groupId);
  };

  QuestionGroups.prototype.moveQuestion = function(id, groupIdx) {
    var groups, mapping;
    groups = this._getGroups();
    if (!((0 <= groupIdx && groupIdx < groups.length))) {
      return W.reject(new Exceptions.data_changed);
    }
    mapping = this._getMapping();
    mapping[id] = groups[groupIdx]._id;
    return this.save('mapping', mapping).then((function(_this) {
      return function() {
        return _this.trigger('questionMoved', id, groupIdx);
      };
    })(this));
  };

  QuestionGroups.prototype.removeQuestionFromGroup = function(id) {
    var mapping;
    mapping = this._getMapping();
    delete mapping[id];
    return this.save('mapping', mapping);
  };

  QuestionGroups.prototype.groupsSize = function() {
    return this._getGroups().length;
  };

  return QuestionGroups;

})(Model);
