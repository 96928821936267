var ViewTrait, WysiwygFootnotes, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ViewTrait = require('base/lib/traits/view_trait');

mediator = require('mediator');

module.exports = WysiwygFootnotes = (function(_super) {
  __extends(WysiwygFootnotes, _super);

  function WysiwygFootnotes() {
    return WysiwygFootnotes.__super__.constructor.apply(this, arguments);
  }

  WysiwygFootnotes.prototype.apply = function(view) {
    WysiwygFootnotes.__super__.apply.apply(this, arguments);
    this._addFunction(view, '_showInitialFootnotes');
    this._addFunction(view, '_getFootnotesList');
    this._addFunction(view, 'stickFootnotes');
    this._addFunction(view, '_removeFootnotesModelCallbacks');
    this._addFunction(view, 'unstickFootnotes');
    this._addFunction(view, 'rerenderFootnotes');
    this._addFunction(view, '_sortFootnoteElements');
    this._addFunction(view, '_getBindingsForProperty');
    this._addFunction(view, 'showWysiwygFootnote');
    this._addFunction(view, 'showWysiwygFootnotes');
    this._addFunction(view, 'removeWysiwygFootnote');
    this._addFunction(view, 'increaseCounterIfFootnoteAlreadyPresent');
    return this._addFunction(view, 'dispose');
  };

  WysiwygFootnotes.prototype._showInitialFootnotes = function(originalFunction, trait) {
    var binding, footnoteId, property, _results;
    if (this.disposed) {
      return;
    }
    _results = [];
    for (property in this._footnotesBindings) {
      _results.push((function() {
        var _i, _len, _ref, _ref1, _results1;
        _ref1 = (_ref = this._getBindingsForProperty(property)) != null ? _ref : [];
        _results1 = [];
        for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
          binding = _ref1[_i];
          _results1.push((function() {
            var _j, _len1, _ref2, _ref3, _results2;
            _ref3 = (_ref2 = this._footnotesModel.get('footnotes')[property]) != null ? _ref2 : [];
            _results2 = [];
            for (_j = 0, _len1 = _ref3.length; _j < _len1; _j++) {
              footnoteId = _ref3[_j];
              _results2.push(this.showWysiwygFootnote(footnoteId, property, binding));
            }
            return _results2;
          }).call(this));
        }
        return _results1;
      }).call(this));
    }
    return _results;
  };

  WysiwygFootnotes.prototype._getFootnotesList = function() {
    return this._footnotesListModel.get('footnotesList');
  };

  WysiwygFootnotes.prototype.stickFootnotes = function(originalFunction, trait, footnotesListModel, footnotesModel) {
    if (footnotesModel == null) {
      footnotesModel = this.model;
    }
    if (!this.footnotesBindings) {
      throw new Error('No footnotesBindings defined!');
    }
    this._footnotesListModel = footnotesListModel;
    this._footnotesModel = footnotesModel;
    this._footnotesBindings = _(this).result('footnotesBindings');
    this._showInitialFootnotes();
    this._footnotesModel.on('footnoteBound', this.showWysiwygFootnotes);
    this._footnotesModel.on('footnoteUnbound', this.removeWysiwygFootnote);
    this.subscribeEvent('footnoteDeleted', this.rerenderFootnotes);
    this.subscribeEvent('footnoteRestored', this.rerenderFootnotes);
    this.subscribeEvent('footnotesReordered', this.rerenderFootnotes);
    this.subscribeEvent('specialFootnoteBound', this.rerenderFootnotes);
    this.subscribeEvent('specialFootnoteUnbound', this.rerenderFootnotes);
    return this._footnotesModel.on('change:footnotes', this.rerenderFootnotes);
  };

  WysiwygFootnotes.prototype._removeFootnotesModelCallbacks = function(originalFunction, trait) {
    if (this._footnotesModel) {
      this._footnotesModel.off('footnoteBound', this.showWysiwygFootnotes);
      this._footnotesModel.off('footnoteUnbound', this.removeWysiwygFootnote);
      return this._footnotesModel.off('change:footnotes', this.rerenderFootnotes);
    }
  };

  WysiwygFootnotes.prototype.unstickFootnotes = function(originalFunction, trait) {
    this._footnotesListModel = null;
    this._removeFootnotesModelCallbacks();
    this.unsubscribeEvent('footnoteDeleted', this.rerenderFootnotes);
    this.unsubscribeEvent('footnoteRestored', this.rerenderFootnotes);
    this.unsubscribeEvent('footnotesReordered', this.rerenderFootnotes);
    this.unsubscribeEvent('specialFootnoteBound', this.rerenderFootnotes);
    this.unsubscribeEvent('specialFootnoteUnbound', this.rerenderFootnotes);
    this._footnotesListModel = null;
    this._footnotesModel = null;
    return this._footnotesBindings = null;
  };

  WysiwygFootnotes.prototype.rerenderFootnotes = function(originalFunction, trait) {
    if (this.disposed) {
      return;
    }
    this.$('sup[data-footnote-id]').remove();
    return _.defer(this._showInitialFootnotes);
  };

  WysiwygFootnotes.prototype._sortFootnoteElements = function(originalFunction, trait, cellEl, method) {
    var footnoteElements, sortingFunction;
    sortingFunction = function(a, b) {
      if (parseInt(a.textContent) > parseInt(b.textContent)) {
        return 1;
      } else {
        return -1;
      }
    };
    if (method === 'after') {
      footnoteElements = cellEl.nextUntil(':not(sup[data-footnote-id])').remove();
    } else if (method === 'append') {
      footnoteElements = cellEl.find("sup[data-footnote-id]");
    }
    return cellEl[method](footnoteElements.sort(sortingFunction));
  };

  WysiwygFootnotes.prototype._getBindingsForProperty = function(originalFunction, trait, property) {
    return this._footnotesBindings[property];
  };

  WysiwygFootnotes.prototype.showWysiwygFootnote = function(originalFunction, trait, footnoteId, property, binding) {
    var el, filteredModelIds, footnoteIdx, noteWithIdPassed, notesIds, notesList, _ref;
    if (!this.$el) {
      return;
    }
    el = this.$el.find(binding.selector);
    notesIds = ((_ref = this.parentView) != null ? _ref.filterer : void 0) ? (filteredModelIds = _(this._footnotesModel.collection.filter(_(this.parentView.filterer).bind(this.parentView))).pluck('id'), _(this._footnotesListModel.getFootnotesArray(filteredModelIds)).pluck('id')) : null;
    notesList = this._getFootnotesList().getNotesList(this._footnotesListModel.specialFootnoteBounded, notesIds);
    noteWithIdPassed = _(notesList).findWhere({
      id: footnoteId
    });
    if (!noteWithIdPassed) {
      return;
    }
    footnoteIdx = noteWithIdPassed.idx + 1;
    if (this.increaseCounterIfFootnoteAlreadyPresent(footnoteId, el, binding.method)) {
      return;
    }
    el[binding.method]("<sup data-footnote-number='1'\ndata-footnote-id='" + footnoteId + "'>&nbsp;<span class=\"underline\">" + footnoteIdx + "</span></sup>");
    return this._sortFootnoteElements(el, binding.method);
  };

  WysiwygFootnotes.prototype.showWysiwygFootnotes = function(originalFunction, trait, footnoteId, property) {
    var binding, _i, _len, _ref, _results;
    if (!this._getBindingsForProperty(property)) {
      return;
    }
    _ref = this._getBindingsForProperty(property);
    _results = [];
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      binding = _ref[_i];
      _results.push(this.showWysiwygFootnote(footnoteId, property, binding));
    }
    return _results;
  };

  WysiwygFootnotes.prototype.increaseCounterIfFootnoteAlreadyPresent = function(originalFunction, trait, footnoteId, el, method) {
    var increase, present;
    increase = function(footnotes) {
      var sup, _i, _len;
      for (_i = 0, _len = footnotes.length; _i < _len; _i++) {
        sup = footnotes[_i];
        if (!(footnoteId === $(sup).data('footnoteId'))) {
          continue;
        }
        $(sup).data('footnoteNumber', $(sup).data('footnoteNumber') + 1);
        return true;
      }
      return false;
    };
    present = false;
    if (method === 'after') {
      present = increase(el.nextUntil(':not(sup)'));
    } else if (method === 'append') {
      present = increase(el.find('sup'));
    } else {
      throw new Error('Unsupported method.');
    }
    return present;
  };

  WysiwygFootnotes.prototype.removeWysiwygFootnote = function(originalFunction, trait, footnoteId, property) {
    var binding, el, removeOrDecrease, selector, _i, _len, _ref;
    removeOrDecrease = function(footnote) {
      if (footnote.data('footnoteNumber') === 1) {
        return footnote.remove();
      } else {
        return footnote.data('footnoteNumber', footnote.data('footnoteNumber') - 1);
      }
    };
    if (!this._getBindingsForProperty(property)) {
      return;
    }
    _ref = this._getBindingsForProperty(property);
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      binding = _ref[_i];
      selector = binding.selector;
      if (!this.$el) {
        return;
      }
      el = this.$el.find(selector);
      if (binding.method === 'after') {
        removeOrDecrease(el.nextAll("sup[data-footnote-id='" + footnoteId + "']").eq(0));
      } else if (binding.method === 'append') {
        removeOrDecrease(el.find("sup[data-footnote-id='" + footnoteId + "']"));
      } else {
        throw new Error('Unsupported method.');
      }
    }
  };

  WysiwygFootnotes.prototype.dispose = function(originalFunction) {
    this._removeFootnotesModelCallbacks();
    return originalFunction.apply(this);
  };

  return WysiwygFootnotes;

})(ViewTrait);
