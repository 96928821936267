var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"quality-compound-edit\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isDiagnostic : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  <div class=\"edit-input-block form-group row mt-10\">\n    <label for=\"riskOfBias\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "risk_of_bias", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n    <div class=\"inline\">\n      "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "riskOfBias", ((stack1 = (depth0 != null ? depth0.riskOfBias : depth0)) != null ? stack1.name : stack1), "None: not_serious, Serious: serious, VerySerious: very_serious", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n      </div>\n    <div class=\"sup\"></div>\n  </div>\n  <div class=\"edit-input-block form-group row mt-10\">\n    <label for=\"inconsistency\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "inconsistency", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n    <div class=\"inline\">\n      "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "inconsistency", ((stack1 = (depth0 != null ? depth0.inconsistency : depth0)) != null ? stack1.name : stack1), "None: not_serious, Serious: serious, VerySerious: very_serious", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n    </div>\n    <div class=\"sup\"></div>\n  </div>\n  <div class=\"edit-input-block form-group row mt-10\">\n      <label for=\"indirectness\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "indirectness", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n      <div class=\"inline\">\n        "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "indirectness", ((stack1 = (depth0 != null ? depth0.indirectness : depth0)) != null ? stack1.name : stack1), "None: not_serious, Serious: serious, VerySerious: very_serious", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n      </div>\n      <div class=\"sup\"></div>\n  </div>\n  <div class=\"edit-input-block form-group row mt-10\">\n      <label for=\"imprecision\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "imprecision", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n      <div class=\"inline\">\n      "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "imprecision", ((stack1 = (depth0 != null ? depth0.imprecision : depth0)) != null ? stack1.name : stack1), "None: not_serious, Serious: serious, VerySerious: very_serious", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n      </div>\n      <div class=\"sup\"></div>\n  </div>\n  <div class=\"edit-input-block form-group row mt-10\">\n    <label for=\"publicationBias\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "publication_bias", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n    <div class=\"inline\">\n      "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "publicationBias", ((stack1 = (depth0 != null ? depth0.publicationBias : depth0)) != null ? stack1.name : stack1), "None: undetected, Serious: publication_bias_strongly_suspected_short", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n    </div>\n    <div class=\"sup\"></div>\n  </div>\n  <div class=\"edit-input-block form-group row mt-10\">\n      <label for=\"largeEffect\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "large_effect", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n      <div class=\"inline\">\n        "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "largeEffect", ((stack1 = (depth0 != null ? depth0.largeEffect : depth0)) != null ? stack1.name : stack1), "No: no, Large: large, VeryLarge: very_large", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n      </div>\n      <div class=\"sup\"></div>\n  </div>\n  <div class=\"edit-input-block form-group row mt-10\">\n    <label for=\"plausibleConfounding\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "plausible_confounding", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n    <div class=\"inline\">\n      "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "plausibleConfounding", ((stack1 = (depth0 != null ? depth0.plausibleConfounding : depth0)) != null ? stack1.name : stack1), "No: no, ReducedForRR: reduced_for_RR, IncreasedForRR: increased_for_RR", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n    </div>\n    <div class=\"sup\"></div>\n  </div>\n  <div class=\"edit-input-block form-group row mt-10\">\n    <label for=\"doseResponseGradient\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "dose_response_gradient", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n    <div class=\"inline\">\n      "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "doseResponseGradient", ((stack1 = (depth0 != null ? depth0.doseResponseGradient : depth0)) != null ? stack1.name : stack1), "No: no, Large: yes", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n    </div>\n    <div class=\"sup\"></div>\n  </div>\n</div>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "  <div class=\"edit-input-block form-group row mt-10\">\n    <label for=\"designStudies\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "study_design", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n    <div class=\"inline\">\n      "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "designStudies", ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.fullName : stack1), "ObsStudies.cross_sectional_cohort_type: cross_sectional_cohort_type,\n        ObsStudies.case_control_type_accuracy: case_control_type_accuracy", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n      </div>\n    <div class=\"sup\"></div>\n  </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compoundEdit || (depth0 && depth0.compoundEdit) || helperMissing).call(depth0, "quality", "quality", {"name":"compoundEdit","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}