var OpenProjectFromFileView, ProjectsToolbarView, View, gdtBackup, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

template = require('views/templates/projects_toolbar');

mediator = require('mediator');

OpenProjectFromFileView = require('views/open_project_from_file_view');

gdtBackup = require('lib/gdt_backup');

module.exports = ProjectsToolbarView = (function(_super) {
  __extends(ProjectsToolbarView, _super);

  function ProjectsToolbarView() {
    return ProjectsToolbarView.__super__.constructor.apply(this, arguments);
  }

  ProjectsToolbarView.prototype.template = template;

  ProjectsToolbarView.prototype.container = '#toolbar-container';

  ProjectsToolbarView.prototype.autoRender = true;

  ProjectsToolbarView.prototype.initialize = function() {
    ProjectsToolbarView.__super__.initialize.apply(this, arguments);
    return this.delegate('click', 'button.open-projects-from-file', this.openProjectsFromFile);
  };

  ProjectsToolbarView.prototype.afterRender = function() {
    return ProjectsToolbarView.__super__.afterRender.apply(this, arguments);
  };

  ProjectsToolbarView.prototype.openProjectsFromFile = function(e) {
    e.stopPropagation();
    return this.subview('openProjectFromFileView', new OpenProjectFromFileView());
  };

  return ProjectsToolbarView;

})(View);
