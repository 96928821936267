var Rm5ExportTableCell, rm5ExportConfig, rm5TbodyCells, _getCellFootnotes;

rm5ExportConfig = require('lib/rm5_export_config');

rm5TbodyCells = rm5ExportConfig.tbodyCells;

Rm5ExportTableCell = rm5ExportConfig.Rm5ExportTableCell;

_getCellFootnotes = function(cell, footnotes) {
  var attribute, cellFootnotes, cellPossibleAttributes, _i, _len;
  cellPossibleAttributes = rm5ExportConfig.attributesMap[cell];
  cellFootnotes = [];
  for (_i = 0, _len = cellPossibleAttributes.length; _i < _len; _i++) {
    attribute = cellPossibleAttributes[_i];
    if (attribute in footnotes) {
      cellFootnotes = cellFootnotes.concat(footnotes[attribute]);
    }
  }
  cellFootnotes.sort(function(a, b) {
    return a - b;
  });
  if (!_(cellFootnotes).isEmpty()) {
    return _(cellFootnotes).unique().join(' ');
  } else {
    return '';
  }
};

module.exports = function(attributes) {
  var cellName, cellNotes, correspondingRiskNotes, outcomeHasFootnotes, outcomeRow, rowSchema, sofControlNotes, tableType, tableView;
  tableView = attributes.tableView;
  tableType = "" + tableView + "_regular";
  rowSchema = rm5ExportConfig.theadSchemas[tableType][0];
  outcomeRow = {
    cells: []
  };
  outcomeHasFootnotes = !_(attributes.footnotes).isEmpty();
  for (cellName in rowSchema) {
    if (cellName === 'absoluteEffect') {
      if (outcomeHasFootnotes) {
        sofControlNotes = _getCellFootnotes('contSofControl', attributes.footnotes);
        correspondingRiskNotes = _getCellFootnotes('contCorrespondingRisk', attributes.footnotes);
      }
      outcomeRow.cells.push(new Rm5ExportTableCell({
        textContent: rm5TbodyCells['contSofControl'](attributes),
        sup: sofControlNotes || ''
      }));
      switch (tableView) {
        case 'sof':
          outcomeRow.cells.push(new Rm5ExportTableCell({
            textContent: rm5TbodyCells['contCorrespondingRisk'](attributes),
            sup: correspondingRiskNotes || ''
          }));
          break;
        case 'accp_sof':
          outcomeRow.cells.push(new Rm5ExportTableCell({
            textContent: rm5TbodyCells['contRiskDifference'](attributes),
            sup: correspondingRiskNotes || ''
          }));
          break;
        case 'sof_v3':
          outcomeRow.cells.push(new Rm5ExportTableCell({
            textContent: rm5TbodyCells['contCorrespondingRisk'](attributes),
            sup: correspondingRiskNotes || ''
          }));
          outcomeRow.cells.push(new Rm5ExportTableCell({
            textContent: rm5TbodyCells['contRiskDifference'](attributes),
            sup: correspondingRiskNotes || ''
          }));
      }
    } else {
      if (outcomeHasFootnotes) {
        cellNotes = _getCellFootnotes(cellName, attributes.footnotes);
      }
      outcomeRow.cells.push(new Rm5ExportTableCell({
        textContent: rm5TbodyCells[cellName](attributes),
        sup: cellNotes || ''
      }));
    }
  }
  return outcomeRow;
};
