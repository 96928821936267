var mediator;

mediator = require('mediator');

module.exports = function(Actions) {
  return {
    fetch: {
      remote: function(state, dbName, ids, options) {
        return mediator.services.storePersistenceAdapter.fetch(dbName, ids, options);
      },
      success: Actions.pouchFetchSuccess,
      error: Actions.pouchFetchError
    },
    save: {
      remote: function(state, dbName, doc) {
        return mediator.services.storePersistenceAdapter.save(dbName, doc);
      },
      success: Actions.pouchSaveSuccess,
      error: Actions.pouchSaveError
    }
  };
};
