var AccountView, InputDialog, ModalView, exceptions, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModalView = require('base/views/modal_view');

InputDialog = require('views/input_dialog');

template = require('./templates/account');

exceptions = require('lib/exceptions');

mediator = require('mediator');

module.exports = AccountView = (function(_super) {
  __extends(AccountView, _super);

  function AccountView() {
    return AccountView.__super__.constructor.apply(this, arguments);
  }

  AccountView.prototype.className = 'account-view';

  AccountView.prototype.template = template;

  AccountView.prototype.title = $.t('account.title');

  AccountView.prototype.autoRender = false;

  AccountView.prototype.initialize = function(_arg) {
    this.service = _arg.service;
    AccountView.__super__.initialize.apply(this, arguments);
    this.delegate('click', 'button.close', this.dispose);
    this.delegate('click', 'button.connect', this._connect);
    this.delegate('click', 'button.disconnect', this._disconnect);
    this._status = {};
    return this.service.getConnectionStatus('archie', mediator.user.get('name')).then((function(_this) {
      return function(archieStatus) {
        _this._status.archie = archieStatus;
        return _this.render();
      };
    })(this));
  };

  AccountView.prototype._getProvider = function(e) {
    return $(e.target).data('provider');
  };

  AccountView.prototype._connect = function(e) {
    var provider;
    provider = this._getProvider(e);
    return new InputDialog({
      title: $.t('account.password_dialog.title'),
      message: $.t('account.password_dialog.message'),
      maskedInput: true
    }).promise().then((function(_this) {
      return function(password) {
        return _this.service.connect(provider, mediator.user.get('name'), password).then(function(account) {
          _this._status[provider] = {
            connected: true,
            account: account
          };
          return _this.render();
        }, function(err) {
          if (err instanceof exceptions.authentication.oauth_flow_failed) {
            return mediator.dialogs.error(err);
          }
        });
      };
    })(this), function(err) {
      if (err !== 'cancelled') {
        return mediator.dialogs.fatalError(err);
      }
    });
  };

  AccountView.prototype._disconnect = function(e) {
    var provider;
    provider = this._getProvider(e);
    return mediator.dialogs.confirm({
      message: $.t('account.disconnect_confirmation')
    }, (function(_this) {
      return function(confirmed) {
        if (!confirmed) {
          return;
        }
        return _this.service.disconnect(provider, mediator.user.get('name')).then(function(account) {
          _this._status[provider] = {
            connected: false
          };
          return _this.render();
        }, function(err) {
          if (err !== 'cancelled') {
            return mediator.dialogs.fatalError(err);
          }
        });
      };
    })(this));
  };

  AccountView.prototype.getTemplateData = function() {
    return {
      email: mediator.user.get('email'),
      archieConnected: this._status.archie.connected,
      archieAccountName: this._status.archie.account
    };
  };

  return AccountView;

})(ModalView);
