PureRenderMixin = React.addons.PureRenderMixin

TogglePanel = React.createClass
  displayName: 'TogglePanel'

  mixins: [PureRenderMixin]

  getInitialState: ->
    status: ''

  toggle: (e) ->
    @setState
      status: if @state.status is 'open' then '' else 'open'

  render: ->
    <div className={classNames 'toggle-panel', @props.className}>
      <div className={ classNames('header', @props.headerClass, @state.status) } onClick={@toggle}>
        <h2>{@props.title}</h2>
      </div>
      <div className={ classNames('content', @state.status)}>
        {@props.children}
      </div>
    </div>

module.exports = TogglePanel
