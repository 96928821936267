var Model, W, mocHelpers;

Model = require('models/base/model');

W = require('when/when');

mocHelpers = {
  _getModelById: function(coll, modelId) {
    return coll.get(modelId);
  },
  getOrAddModel: function(coll, modelJS) {
    var model;
    model = this._getModelById(coll, modelJS._id);
    if (!model) {
      model = coll.add(modelJS).last();
    }
    model.set(modelJS);
    return model;
  },
  save: function(moc, modelJS) {
    var model;
    if (moc instanceof Model) {
      return moc.save(modelJS, {
        silent: true
      });
    } else {
      model = this.getOrAddModel(moc, modelJS);
      return model.save(modelJS, {
        silent: true
      });
    }
  },
  "delete": function(moc, modelId) {
    var model;
    if (moc instanceof Model) {
      return moc.destroy({
        silent: true,
        wait: true
      }).then(function() {
        return modelId;
      });
    } else {
      model = this._getModelById(moc, modelId);
      if (model) {
        return model.destroy({
          silent: true,
          wait: true
        }).then(function() {
          return modelId;
        });
      } else {
        return W.resolve(modelId);
      }
    }
  },
  validate: function(modelJS, Model) {
    var model;
    model = new Model(modelJS);
    return model.validate();
  }
};

module.exports = mocHelpers;
