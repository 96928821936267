var DocumentSectionsController, DocumentSectionsView, ModuleController, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModuleController = require('controllers/base/module_controller');

DocumentSectionsView = require('views/document_sections_view');

mediator = require('mediator');

module.exports = DocumentSectionsController = (function(_super) {
  __extends(DocumentSectionsController, _super);

  function DocumentSectionsController() {
    return DocumentSectionsController.__super__.constructor.apply(this, arguments);
  }

  DocumentSectionsController.prototype.index = function() {
    return this.view = new DocumentSectionsView({
      model: mediator.project
    });
  };

  return DocumentSectionsController;

})(ModuleController);
