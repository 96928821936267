Button = require 'components/common/button'
Translation = require 'components/mixins/translation'

DeleteButton = React.createClass
  displayName: 'DeleteButton'

  mixins: [Translation()]

  propTypes:
    onClick: React.PropTypes.func.isRequired
    title: React.PropTypes.string

  render: ->
    title = @props.title ? @i18n 'actions.delete'
    classes = classNames "delete", @props.className
    <Button className={classes} title={title} onClick={@props.onClick}/>

module.exports = DeleteButton
