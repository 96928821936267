var viewHelperUtils,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

viewHelperUtils = {
  regPartial: function(name, partial, data) {
    if (data.dontDisplay && __indexOf.call(data.dontDisplay, name) >= 0) {
      return '';
    }
    if (Handlebars.partials[name] == null) {
      Handlebars.registerPartial(name, require(partial));
    }
    return new Handlebars.SafeString(Handlebars.partials[name](data));
  },
  i18n: function(namespace, str, params) {
    var key, _ref;
    if ($.i18n) {
      key = this.absoluteKey(namespace, str);
      return $.t(key, (_ref = params != null ? params.hash : void 0) != null ? _ref : params);
    } else {
      return str;
    }
  },
  absoluteKey: function(namespace, key) {
    if (key !== void 0) {
      if (key.indexOf('/') === 0) {
        return key.slice(1);
      } else if (key.indexOf('../') === 0) {
        return this.absoluteKey(namespace.slice(0, +namespace.slice(0, -1).lastIndexOf('.') + 1 || 9e9), key.slice(3));
      } else {
        return (namespace != null ? namespace : '') + key;
      }
    }
  },
  newLineToBr: function(str) {
    return new Handlebars.SafeString(str.replace(/\n/g, '<br>'));
  },
  controlRiskFormatterSuffix: function(controlRisk) {
    switch (controlRisk) {
      case 'calculatedControl':
        return '';
      case 'lowControl':
        return 'Low';
      case 'moderateControl':
        return 'Moderate';
      case 'highControl':
        return 'High';
    }
  }
};

module.exports = viewHelperUtils;
