var Ellipsis, NoneditableDraftQuestionView, View, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

template = require('views/templates/scope/noneditable_draft_question');

Ellipsis = require('base/lib/traits/ellipsis');

module.exports = NoneditableDraftQuestionView = (function(_super) {
  __extends(NoneditableDraftQuestionView, _super);

  function NoneditableDraftQuestionView() {
    this.className = __bind(this.className, this);
    return NoneditableDraftQuestionView.__super__.constructor.apply(this, arguments);
  }

  NoneditableDraftQuestionView.prototype.template = template;

  NoneditableDraftQuestionView.prototype.tagName = 'li';

  NoneditableDraftQuestionView.prototype.ellipsisSelector = '.caption > *';

  NoneditableDraftQuestionView.prototype.className = function() {
    return this.model.get('source');
  };

  NoneditableDraftQuestionView.prototype.initialize = function() {
    NoneditableDraftQuestionView.__super__.initialize.apply(this, arguments);
    return this.enable(Ellipsis, {
      selector: this.ellipsisSelector
    });
  };

  return NoneditableDraftQuestionView;

})(View);
