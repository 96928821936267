var Question, diagnosticQuestions,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

Question = require('models/question');

diagnosticQuestions = {
  parseQuestions: function($xml, questionsFormat, projectName) {
    var $analyses, healthProblemOrPopulation, questions, targetCondition, targetConditionAndPopulation;
    targetConditionAndPopulation = projectName.split(/(\ for\ |\ in\ )/);
    targetCondition = targetConditionAndPopulation[2];
    healthProblemOrPopulation = targetConditionAndPopulation.length === 5 ? targetConditionAndPopulation[4] : Question.prototype.defaults.healthProblemOrPopulation;
    $analyses = $xml.find('ANALYSES');
    questions = [];
    $analyses.find('ANALYSIS').each((function(_this) {
      return function(idx, item) {
        var $analysis, analysisName, analysisType, indexAndComparator, outcome, outcomesData, question, _i, _len, _ref, _ref1, _ref2, _ref3, _ref4;
        $analysis = $(item);
        analysisType = $analysis.attr('TYPE');
        analysisName = $analysis.find('NAME').text();
        outcomesData = _this._parseAnlysisData($xml, $analysis, analysisType);
        indexAndComparator = analysisName.split(/\ vs\.?\ |\ versus\ /i);
        question = new Question({
          type: 'diagnostic',
          questionNo: idx + 1,
          revManAnalysisNo: $analysis.attr('NO'),
          indexTest: (_ref = indexAndComparator[0]) != null ? _ref : Question.prototype.defaults.indexTest,
          comparatorTest: (_ref1 = indexAndComparator[1]) != null ? _ref1 : Question.prototype.defaults.comparatorTest,
          comparatorTestPresent: analysisType === 'MULTIPLE',
          targetCondition: targetCondition,
          healthProblemOrPopulation: healthProblemOrPopulation
        });
        _ref2 = question.get('outcomes').models;
        for (_i = 0, _len = _ref2.length; _i < _len; _i++) {
          outcome = _ref2[_i];
          if ((_ref3 = outcome.get('name')) === 'TP' || _ref3 === 'FN') {
            outcome.set('noOfPatients', outcomesData.sensitivityNoOfPatients);
            outcome.set('noOfStudies', outcomesData.noOfStudies);
          } else if ((_ref4 = outcome.get('name')) === 'TN' || _ref4 === 'FP') {
            outcome.set('noOfPatients', outcomesData.specificityNoOfPatients);
            outcome.set('noOfStudies', outcomesData.noOfStudies);
          }
        }
        question.set(question.calculateTitles());
        return questions.push(question);
      };
    })(this));
    return questions;
  },
  _parseAnlysisData: function($xml, $analysis, analysisType) {
    var $test, $tests, commonStudies, data, studyIds, td, testData, testId, testIds, testsData;
    switch (analysisType) {
      case 'SINGLE':
        testIds = [$analysis.attr('TESTS')];
        break;
      case 'MULTIPLE':
        testIds = $analysis.attr('TESTS').split(' ');
    }
    $tests = $xml.find('ANALYSES_AND_DATA > TESTS');
    testsData = (function() {
      var _i, _len, _results;
      _results = [];
      for (_i = 0, _len = testIds.length; _i < _len; _i++) {
        testId = testIds[_i];
        $test = $tests.find("TEST[ID=" + testId + "]");
        _results.push(this._getTestData($test.find('TEST_DATA')));
      }
      return _results;
    }).call(this);
    studyIds = (function() {
      var _i, _len, _results;
      _results = [];
      for (_i = 0, _len = testsData.length; _i < _len; _i++) {
        testData = testsData[_i];
        _results.push(_(testData).pluck('studyId'));
      }
      return _results;
    })();
    commonStudies = _.intersection.apply(_, studyIds);
    data = _((function() {
      var _i, _len, _results;
      _results = [];
      for (_i = 0, _len = testsData.length; _i < _len; _i++) {
        td = testsData[_i];
        _results.push(this._reduceTestData(td, commonStudies));
      }
      return _results;
    }).call(this)).reduce((function(acc, td) {
      var key, _i, _len, _ref, _ref1;
      _ref = _(td).keys();
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        key = _ref[_i];
        acc[key] = ((_ref1 = acc[key]) != null ? _ref1 : 0) + td[key];
      }
      return acc;
    }), {});
    return {
      noOfStudies: data.noOfStudies,
      sensitivityNoOfPatients: data.tp + data.fn,
      specificityNoOfPatients: data.tn + data.fp
    };
  },
  _getTestData: function($testData) {
    var $dataEntries, $entry, entry, _i, _len, _results;
    $dataEntries = $testData.find('TEST_DATA_ENTRY');
    _results = [];
    for (_i = 0, _len = $dataEntries.length; _i < _len; _i++) {
      entry = $dataEntries[_i];
      $entry = $(entry);
      _results.push({
        tp: parseInt($entry.attr('TP')),
        fn: parseInt($entry.attr('FN')),
        tn: parseInt($entry.attr('TN')),
        fp: parseInt($entry.attr('FP')),
        studyId: $entry.attr('STUDY_ID')
      });
    }
    return _results;
  },
  _reduceTestData: function(testData, commonStudies) {
    var reduceFn;
    reduceFn = function(memo, data) {
      var _ref;
      if (_ref = data.studyId, __indexOf.call(commonStudies, _ref) >= 0) {
        memo.tp += parseInt(data.tp);
        memo.tn += parseInt(data.tn);
        memo.fp += parseInt(data.fp);
        memo.fn += parseInt(data.fn);
        memo.noOfStudies++;
      }
      return memo;
    };
    return _(testData).reduce(reduceFn, {
      tp: 0,
      tn: 0,
      fp: 0,
      fn: 0,
      noOfStudies: 0
    });
  }
};

module.exports = diagnosticQuestions.parseQuestions.bind(diagnosticQuestions);
