var ISoFDx;

module.exports = ISoFDx = (function() {
  function ISoFDx(container, data) {
    this.container = container;
    this.data = data;
    angular.module('isofDxApp').value('isofData', this.data);
    angular.element(this.container).ready((function(_this) {
      return function() {
        return angular.bootstrap(_this.container, ['isofDxApp']);
      };
    })(this));
  }

  return ISoFDx;

})();
