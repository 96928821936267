var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <th rowspan=\"2\" class=\"regular-header\">\n          <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.relative_effect", {"name":"i18n","hash":{},"data":data})))
    + "<br/>(95% CI)</p>\n        </th>\n        <th colspan=\"2\" class=\"regular-header\">\n          <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.anticipated_absolute_effects", {"name":"i18n","hash":{},"data":data})))
    + "</p>\n        </th>\n";
},"3":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <th colspan=\"2\" rowspan=\"2\" class=\"narrative-header\">\n          <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.impact", {"name":"i18n","hash":{},"data":data})))
    + "</p>\n        </th>\n";
},"5":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "        <th class=\"gray-cell regular-header\">\n          <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.risk_with", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.comparison || (depth0 != null ? depth0.comparison : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparison","hash":{},"data":data}) : helper)))
    + "</p>\n        </th>\n        <th class=\"gray-cell regular-header\">\n          <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.risk_difference_with", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.intervention || (depth0 != null ? depth0.intervention : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"intervention","hash":{},"data":data}) : helper)))
    + "</p>\n        </th>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<table valign=\"top\" cellpadding=\"5\" width=\"100%\">\n  <thead>\n    <tr class=\"table-title\">\n      <th colspan=\"12\">\n        <p class=\"title\"><b>"
    + escapeExpression(((helper = (helper = helpers.tableTitle || (depth0 != null ? depth0.tableTitle : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"tableTitle","hash":{},"data":data}) : helper)))
    + "</b></p>\n        <div class=\"question-data\">\n        </div>\n      </th>\n    </tr>\n    <tr class=\"table-header\">\n      <th colspan=\"7\" class=\"quality\">\n        <p>\n          ";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.quality_assessment", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n        </p>\n      </th>\n      <th colspan=\"5\" class=\"summary\">\n        <p>\n          ";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.summary_of_findings", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n        </p>\n      </th>\n    </tr>\n  </thead>\n  <tbody class=\"printout-rows\">\n    <tr class=\"labels\">\n      <th rowspan=\"2\">\n        <p>";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.no_of_participants", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "<br/>(studies)<br/>Follow-up</p>\n      </th>\n      <th rowspan=\"2\">\n        <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.risk_of_bias", {"name":"i18n","hash":{},"data":data})))
    + "</p></th>\n      <th rowspan=\"2\">\n        <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.inconsistency", {"name":"i18n","hash":{},"data":data})))
    + "</p>\n      </th>\n      <th rowspan=\"2\">\n        <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.indirectness", {"name":"i18n","hash":{},"data":data})))
    + "</p>\n      </th>\n      <th rowspan=\"2\">\n        <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.imprecision", {"name":"i18n","hash":{},"data":data})))
    + "</p></th>\n      <th rowspan=\"2\">\n        <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.publication_bias", {"name":"i18n","hash":{},"data":data})))
    + "</p>\n      </th>\n      <th rowspan=\"2\">\n        <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.overall_quality_of_evidence", {"name":"i18n","hash":{},"data":data})))
    + "</p>\n      </th>\n      <th colspan=\"2\">\n        <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.study_event_rates", {"name":"i18n","hash":{},"data":data})))
    + " (%)</p>\n      </th>\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.$tableMode : depth0), "regular", {"name":"is","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.$tableMode : depth0), "narrative", {"name":"is","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </tr>\n    <tr class=\"labels\">\n      <th class=\"gray-cell\">\n        <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.with", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.comparison || (depth0 != null ? depth0.comparison : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparison","hash":{},"data":data}) : helper)))
    + "</p>\n      </th>\n      <th class=\"gray-cell\">\n        <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.with", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.intervention || (depth0 != null ? depth0.intervention : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"intervention","hash":{},"data":data}) : helper)))
    + "</p>\n      </th>\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.$tableMode : depth0), "regular", {"name":"is","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </tr>\n  </tbody>\n</table>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}