TogglePanel = require 'components/common/toggle_panel'
Translation = require 'components/mixins/translation'
SectionCheckbox = require 'components/administration/section_checkbox'
PureRenderMixin = React.addons.PureRenderMixin

BlockSections = React.createClass
  displayName: 'AdministrationBlockSections'

  mixins: [Translation('administration:etd_templates'), PureRenderMixin]

  render: ->
    panelClassName = classNames 'block', @props.className
    <TogglePanel title={@i18n @props.blockName} className={panelClassName}>
      <div>
        <ul className='block-sections-list'>
          {@props.blockDef.map (sectionDef) =>
            id = sectionDef.get('id')
            sectionName = @props.blockData.getIn(['sections', id, 'name']) or
              @props.blockData.getIn ['sections', id, 'description']
            sectionDesc = if @props.descriptionKey
              @props.blockData.getIn [@props.descriptionKey, id, 'description']

            <li key={id}>
              <SectionCheckbox
                blockName={@props.blockName}
                sectionDef={sectionDef}
                description={sectionDesc}
                label={sectionName} />
            </li>
          }
        </ul>
      </div>
    </TogglePanel>

module.exports = BlockSections
