var _wrapToAppendiceDIV;

_wrapToAppendiceDIV = function(html, optionalAttributes) {
  var attrName, attrValue, attrsString;
  if (optionalAttributes == null) {
    optionalAttributes = {};
  }
  attrsString = '';
  for (attrName in optionalAttributes) {
    attrValue = optionalAttributes[attrName];
    attrsString += " " + attrName + "=\"" + attrValue + "\"";
  }
  return "<div class=\"inserted\"" + attrsString + "> " + html + " </div> <p></br></p>";
};

module.exports = {
  getResearchEvidenceTable: function(tableName, tableData) {
    var tableHtml, templates;
    templates = {
      'sof_short': Handlebars.partials.sofShortTable,
      'test_accuracy': Handlebars.partials.testAccuracySummary,
      'evidence_short': Handlebars.partials.recommendationDxEvidenceTable,
      'relative_importance': Handlebars.partials.relativeImportanceMainOutcomesTable
    };
    tableHtml = templates[tableName](tableData);
    return _wrapToAppendiceDIV(tableHtml, {
      'appendix-type': tableName
    });
  },
  getSofTable: function(tableName, questionModel) {
    var HtmlExport, sofHTML, sofTypesMap;
    HtmlExport = require('lib/html_export');
    sofTypesMap = {
      'sof_v1': 'sof',
      'sof_v2': 'ACCP_sof',
      'sof_v3': 'SOF_v3',
      'l1_sof': 'layerOneSof',
      'l2_sof': 'layerTwoSof'
    };
    sofHTML = new HtmlExport(questionModel, sofTypesMap[tableName], {
      short: true
    }).getSource();
    return _wrapToAppendiceDIV(sofHTML.match(/body>([\s\S]+)<\/body/)[1], {
      'appendix-type': tableName
    });
  },
  getImageString: function(attrs) {
    var attrName, attrValue, attrsString;
    attrsString = '';
    for (attrName in attrs) {
      attrValue = attrs[attrName];
      attrsString += " " + attrName + "=\"" + attrValue + "\"";
    }
    attrsString += " src=\"\"";
    return _wrapToAppendiceDIV("<img" + attrsString + ">", {
      'appendix-type': 'image',
      imageId: attrs['db-image'],
      section: attrs.section
    });
  }
};
