var CollectionView, DataFromService, MemberRatingStatusView, NextStepButton, QuestionsRatingView, Scrolling, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

CollectionView = require('base/views/collection_view');

MemberRatingStatusView = require('views/scope/member_rating_status_view');

NextStepButton = require('views/scope/next_step_button_view');

Scrolling = require('base/lib/traits/scrolling');

DataFromService = require('base/lib/traits/data_from_service');

template = require('views/templates/scope/rating');

mediator = require('mediator');

module.exports = QuestionsRatingView = (function(_super) {
  __extends(QuestionsRatingView, _super);

  function QuestionsRatingView() {
    return QuestionsRatingView.__super__.constructor.apply(this, arguments);
  }

  QuestionsRatingView.prototype.className = 'questions-rating';

  QuestionsRatingView.prototype.template = template;

  QuestionsRatingView.prototype.container = '#page-container';

  QuestionsRatingView.prototype.itemView = MemberRatingStatusView;

  QuestionsRatingView.prototype.listSelector = 'ol';

  QuestionsRatingView.prototype.initialize = function() {
    this.collection = new Backbone.Collection();
    QuestionsRatingView.__super__.initialize.apply(this, arguments);
    this.enable(Scrolling, {
      fixedElements: '.information, .bottom-bar'
    });
    this.enable(DataFromService, {
      dataContainer: '.members',
      serviceMethods: [this.options.service.getRatingStatus]
    });
    this.delegate('click', 'button.refresh', (function(_this) {
      return function() {
        return _this.trigger('!reloadData');
      };
    })(this));
    return this.on('loadingCompleted', (function(_this) {
      return function(members) {
        _this.collection = members[0];
        return _this.render();
      };
    })(this));
  };

  QuestionsRatingView.prototype.getTemplateData = function() {
    return _(QuestionsRatingView.__super__.getTemplateData.apply(this, arguments)).extend({
      preview: this.options.preview
    });
  };

  QuestionsRatingView.prototype.afterRender = function() {
    QuestionsRatingView.__super__.afterRender.apply(this, arguments);
    return this.subview('next-step', new NextStepButton({
      step: 'questions.rating',
      container: this.$('.bottom-bar')
    }));
  };

  return QuestionsRatingView;

})(CollectionView);
