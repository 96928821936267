var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda, functionType="function", buffer = "        <li ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0._id : depth0), (depths[1] != null ? depths[1].currentQuestionId : depths[1]), {"name":"is","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n          <a class='caption'\n            href='"
    + escapeExpression(((helpers.projectRelUrl || (depth0 && depth0.projectRelUrl) || helperMissing).call(depth0, "/", {"name":"projectRelUrl","hash":{},"data":data})))
    + escapeExpression(lambda((depths[1] != null ? depths[1].module : depths[1]), depth0))
    + "/"
    + escapeExpression(((helper = (helper = helpers._id || (depth0 != null ? depth0._id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"_id","hash":{},"data":data}) : helper)))
    + "/"
    + escapeExpression(lambda((depths[1] != null ? depths[1].submodule : depths[1]), depth0))
    + "'>\n            "
    + escapeExpression(((helper = (helper = helpers.questionShort || (depth0 != null ? depth0.questionShort : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"questionShort","hash":{},"data":data}) : helper)))
    + "\n          </a>\n        </li>\n";
},"2":function(depth0,helpers,partials,data) {
  return "class='current'";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "<menu type=\"toolbar\">\n  <button title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "toolbar.export_question.export_presentations", {"name":"i18n","hash":{},"data":data})))
    + "' class='export'>\n  </button>\n</menu>\n<nav class=\"questions\">\n  <a href=\"#\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/toolbar.questions_menu", {"name":"i18n","hash":{},"data":data})))
    + "'>"
    + escapeExpression(((helper = (helper = helpers.currentQuestion || (depth0 != null ? depth0.currentQuestion : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"currentQuestion","hash":{},"data":data}) : helper)))
    + "</a>\n  <div class=\"questions-menu\">\n    <ol class=\"standard-list\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.questions : depth0), {"name":"each","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </ol>\n  </div>\n</nav>\n";
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}