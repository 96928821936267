var Chaplin, Events, GdtDispatcher, Questions, W, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Chaplin = require('chaplin');

mediator = require('mediator');

Questions = require('models/questions');

Events = require('models/events');

W = require('when');

alt = require('alt');

module.exports = GdtDispatcher = (function(_super) {
  __extends(GdtDispatcher, _super);

  function GdtDispatcher() {
    return GdtDispatcher.__super__.constructor.apply(this, arguments);
  }

  GdtDispatcher.prototype.changeProject = function(params) {
    var changingProject, decodedName, redirect, _ref;
    changingProject = $.Deferred();
    if (!params.projectId) {
      this._removePouchListeners();
      mediator.project = null;
      changingProject.resolve();
    } else if (params.projectId !== ((_ref = mediator.project) != null ? _ref.id : void 0)) {
      this._removePouchListeners();
      decodedName = decodeURI(params.projectId);
      redirect = mediator.projects.getRedirectsMap()[decodedName];
      mediator.project = mediator.projects.get(redirect != null ? redirect : decodedName);
      if (mediator.services.switches.isOn('new_projects_list') && (mediator.project == null)) {
        mediator.project = mediator.archivedProjects.get(redirect != null ? redirect : decodedName);
      }
      if (mediator.project) {
        this._loadProject(changingProject);
      } else {
        mediator.publish('!router:route', "/", function(routed) {
          return changingProject.reject(new Error($.t('messages.project_not_found')));
        });
      }
    } else {
      changingProject.resolve();
    }
    return changingProject.promise();
  };

  GdtDispatcher.prototype._removePouchListeners = function() {
    var coll, collName, _ref, _results;
    _ref = mediator.colls;
    _results = [];
    for (collName in _ref) {
      coll = _ref[collName];
      _results.push(mediator.services.persistenceAdapter.stopListening(coll));
    }
    return _results;
  };

  GdtDispatcher.prototype._loadProject = function(changingProject) {
    var coll, fetchingCollections, _;
    this.loadingInfo = mediator.dialogs.loading({
      ms: null
    });
    mediator.colls = mediator.project.getCollections();
    mediator.questions = mediator.colls.questions;
    mediator.prognosticQuestions = mediator.colls.prognosticQuestions;
    mediator.events = mediator.colls.events;
    fetchingCollections = (function() {
      var _ref, _results;
      _ref = mediator.colls;
      _results = [];
      for (_ in _ref) {
        coll = _ref[_];
        _results.push(coll.fetch());
      }
      return _results;
    })();
    return W.all(fetchingCollections).then(this._performMigrations).then(function() {
      changingProject.resolve();
      return mediator.publish('projectChanged', mediator.project);
    }, function(err) {
      return changingProject.reject(err);
    }).ensure((function(_this) {
      return function() {
        return _this.loadingInfo.destroy();
      };
    })(this));
  };

  GdtDispatcher.prototype._performMigrations = function() {
    if (!mediator.project) {
      return;
    }
    return mediator.services.projectMigration.upgrade(mediator.project, mediator.colls);
  };

  GdtDispatcher.prototype.startupController = function(controllerName, action, params, options) {
    if (action == null) {
      action = 'index';
    }
    if (params == null) {
      params = {};
    }
    if (options == null) {
      options = {};
    }
    return this.changeProject(params).then((function(_this) {
      return function() {
        var error;
        if (mediator.project) {
          mediator.user.getSessions().saveRoute(controllerName, action, params, options);
        }
        if (mediator.debug) {
          return GdtDispatcher.__super__.startupController.call(_this, controllerName, action, params, options);
        } else {
          try {
            return GdtDispatcher.__super__.startupController.call(_this, controllerName, action, params, options);
          } catch (_error) {
            error = _error;
            if (typeof Raven !== "undefined" && Raven !== null) {
              Raven.captureException(error);
            }
            return mediator.dialogs.error($.t('messages.routing_error'));
          }
        }
      };
    })(this), function(err) {
      if (typeof Raven !== "undefined" && Raven !== null) {
        Raven.captureException(err);
      }
      return mediator.dialogs.error(err);
    });
  };

  return GdtDispatcher;

})(Chaplin.Dispatcher);
