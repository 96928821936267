var DiagnosticPrevalencesView, FileSaver, FootnotesService, HtmlExport, Outcome, OutcomeViewTypes, OutcomesView, PooledSensitivitySpecificityView, QuestionView, RecommendationsView, mediator,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

mediator = require('mediator');

OutcomeViewTypes = require('models/outcome/view_types');

OutcomesView = require('views/evidence_syntheses/outcomes_view');

QuestionView = require('views/evidence_syntheses/question_view');

RecommendationsView = require('views/evidence_syntheses/recommendations_view');

PooledSensitivitySpecificityView = require('views/evidence_syntheses/pooled_sensitivity_specificity_view');

DiagnosticPrevalencesView = require('views/evidence_syntheses/diagnostic_prevalences_view');

Outcome = require('models/outcome');

FileSaver = require('lib/file_saver');

FootnotesService = require('lib/services/footnotes_service');

module.exports = HtmlExport = (function() {
  var printoutsMap;

  printoutsMap = {
    'oneRow': {
      template: 'views/templates/printouts/outcomes_one_row',
      viewType: OutcomeViewTypes.get('PrintoutOneRow'),
      spliceTag: '<div class="outcomes-container">',
      listSelector: '.printout-rows',
      name: "" + ($.t('es:outcome_view_type.grade_one_row'))
    },
    'sof': {
      template: 'views/templates/printouts/outcomes_sof',
      viewType: OutcomeViewTypes.get('PrintoutSOF'),
      spliceTag: '<body>',
      listSelector: '.printout-rows',
      name: "" + ($.t('es:outcome_view_type.sof_table'))
    },
    'ACCP_grade': {
      template: 'views/templates/printouts/outcomes_accp_grade',
      viewType: OutcomeViewTypes.get('PrintoutGRADEACCP'),
      spliceTag: '<body>',
      listSelector: '.printout-rows',
      name: "" + ($.t('es:outcome_view_type.grade_v2'))
    },
    'ACCP_sof': {
      template: 'views/templates/printouts/outcomes_accp_sof',
      viewType: OutcomeViewTypes.get('PrintoutSOFACCP'),
      spliceTag: '<body>',
      listSelector: '.printout-rows',
      name: "" + ($.t('es:outcome_view_type.sof_table_v2'))
    },
    'SOF_v3': {
      template: 'views/templates/printouts/outcomes_sof_v3',
      viewType: OutcomeViewTypes.get('PrintoutSOFv3'),
      spliceTag: '<body>',
      listSelector: '.printout-rows',
      name: "" + ($.t('es:outcome_view_type.sof_table_v3'))
    },
    'layerOne': {
      template: 'views/templates/printouts/diagnostic/layer_one',
      viewType: OutcomeViewTypes.get('PrintoutLayerOne'),
      spliceTag: '<div class="outcomes-container">',
      listSelector: 'tbody.outcomes',
      name: "" + ($.t('es:outcome_view_type.diag_sof'))
    },
    'layerTwo': {
      template: 'views/templates/printouts/diagnostic/layer_two',
      viewType: OutcomeViewTypes.get('PrintoutLayerTwo'),
      spliceTag: '<div class="outcomes-container">',
      listSelector: 'tbody.outcomes',
      name: "" + ($.t('es:outcome_view_type.diag_sof'))
    },
    'layerOneSof': {
      template: 'views/templates/printouts/diagnostic/layer_one_sof',
      viewType: OutcomeViewTypes.get('PrintoutLayerOneSof'),
      spliceTag: '<div class="outcomes-container">',
      listSelector: 'tbody.outcomes',
      name: "" + ($.t('es:outcome_view_type.diag_sof'))
    },
    'layerTwoSof': {
      template: 'views/templates/printouts/diagnostic/layer_two_sof',
      viewType: OutcomeViewTypes.get('PrintoutLayerTwoSof'),
      spliceTag: '<div class="outcomes-container">',
      listSelector: 'tbody.outcomes',
      name: "" + ($.t('es:outcome_view_type.diag_sof-l2'))
    },
    'recommendations-v1': {
      template: 'views/templates/printouts/recommendations_printout',
      viewType: 'recommendations',
      spliceTag: '<div id="page-container">',
      name: "" + ($.t('es:recommendations.title'))
    },
    'prognosis': {
      template: 'views/templates/printouts/prognosis/prognostic',
      viewType: OutcomeViewTypes.get('PrintoutPrognosis'),
      spliceTag: '<body>',
      listSelector: '.printout-rows',
      name: "" + ($.t('es:outcome_view_type.prognosis'))
    }
  };

  function HtmlExport(model, printoutType, outcomeOrAppendicesIds, options) {
    this.model = model;
    this.printoutType = printoutType;
    this.printout = printoutsMap[printoutType];
    if (_(outcomeOrAppendicesIds).isArray()) {
      this.outcomeOrAppendicesIds = outcomeOrAppendicesIds;
      this.options = options;
    } else {
      this.options = outcomeOrAppendicesIds;
      this.outcomeOrAppendicesIds = null;
    }
  }

  HtmlExport.prototype.getPrintout = function(type) {
    return printoutsMap[type];
  };

  HtmlExport.prototype._exportRecommendations = function(doc) {
    var printoutEl, _ref;
    printoutEl = $(doc).find('#recommendations')[0].cloneNode(true);
    if ((_ref = this.options) != null ? _ref.word : void 0) {
      this._replaceSVGImages(printoutEl);
    }
    _($(printoutEl).find('button')).forEach(function(el) {
      return el.remove();
    });
    _($(printoutEl).find('.editable-content')).forEach(function(el) {
      return el.remove();
    });
    $(printoutEl).find('input[type=radio]:checked').replaceWith(function() {
      return '<span class="checked-marker">&#x25cf;</span>';
    });
    $(printoutEl).find('input[type=radio]:not(:checked)').replaceWith(function() {
      return '<span class="unchecked-marker">&#x25cb;</span>';
    });
    return this.createAppendices(printoutEl);
  };

  HtmlExport.prototype.createAppendices = function(printoutEl) {
    var appendices;
    if (_(this.outcomeOrAppendicesIds).isEmpty()) {
      return printoutEl;
    }
    appendices = [];
    _(this.outcomeOrAppendicesIds).forEach(function(appendixId) {
      var appendixIndex, contentElement, imgElement;
      appendixIndex = appendices.length + 1;
      if (appendixId.match('#')) {
        imgElement = $(printoutEl).find("img[db-image=\"" + appendixId + "\"]")[0];
        if (!imgElement) {
          return;
        }
        imgElement.removeAttribute('style');
        appendices.push({
          index: appendixIndex,
          content: imgElement.outerHTML
        });
        return imgElement.parentNode.innerHTML = "<i>See Appendix " + appendixIndex + "</i>";
      } else {
        contentElement = $(printoutEl).find("div[appendix-type=\"" + appendixId + "\"]")[0];
        if (!contentElement) {
          return;
        }
        appendices.push({
          index: appendixIndex,
          content: contentElement.innerHTML
        });
        return contentElement.innerHTML = "<i>See Appendix " + appendixIndex + "</i>";
      }
    });
    if (_(appendices).isEmpty()) {
      return printoutEl;
    }
    printoutEl.appendChild(this._generateAppendicesDIV(appendices));
    return printoutEl;
  };

  HtmlExport.prototype._replaceSVGImages = function(doc) {
    var canvas, fakeDoc, recommendationsTable, svgImages;
    recommendationsTable = doc;
    if (!/data:image\/svg/.test(recommendationsTable.innerHTML)) {
      return;
    }
    svgImages = recommendationsTable.querySelector("img[src^='data:image/svg']");
    canvas = document.createElement('canvas');
    _(svgImages).forEach((function(_this) {
      return function(svgImage) {
        var PNGDataURL;
        PNGDataURL = _this._convertSVGtoPNG(svgImage, canvas);
        return $(svgImage).attr({
          'src': PNGDataURL
        });
      };
    })(this));
    canvas = null;
    return fakeDoc = null;
  };

  HtmlExport.prototype._convertSVGtoPNG = function(svgImage, canvas) {
    var ctx, _ref;
    ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    _ref = [svgImage.width, svgImage.height], canvas.width = _ref[0], canvas.height = _ref[1];
    ctx.drawImage(svgImage, 0, 0);
    return canvas.toDataURL();
  };

  HtmlExport.prototype._generateAppendicesDIV = function(appendices) {
    var appendicesDiv, appendicesHTML;
    appendicesHTML = Handlebars.partials.recommendationsAppendices({
      appendices: appendices
    });
    appendicesDiv = document.createElement('div');
    appendicesDiv.innerHTML = appendicesHTML;
    return appendicesDiv;
  };

  HtmlExport.prototype.removeEllipsisWrappers = function($printoutEl) {
    return $printoutEl.find('div.ellipsis-wrapper > *').unwrap();
  };

  HtmlExport.prototype.exportOutcomes = function() {
    var $printoutEl, outcomesPrintoutView, _ref;
    outcomesPrintoutView = new OutcomesView(this.getOutcomesObject(this.printout.viewType));
    outcomesPrintoutView.unsubscribeAllEvents();
    outcomesPrintoutView.off();
    outcomesPrintoutView.listSelector = this.printout.listSelector;
    if ((_ref = this.outcomeOrAppendicesIds) != null ? _ref.length : void 0) {
      outcomesPrintoutView.filterer = (function(_this) {
        return function(outcome) {
          var _ref1;
          return _ref1 = outcome.cid, __indexOf.call(_this.outcomeOrAppendicesIds, _ref1) >= 0;
        };
      })(this);
    }
    $printoutEl = outcomesPrintoutView.render().$el;
    _.defer(function() {
      return outcomesPrintoutView.dispose();
    });
    this.removeEllipsisWrappers($printoutEl);
    return $printoutEl[0];
  };

  HtmlExport.prototype.exportQuestion = function() {
    var $printoutEl, questionPrintoutView;
    questionPrintoutView = new QuestionView({
      model: this.model,
      isPrintout: true,
      viewTypeName: this.printout.viewType.name,
      tableTitle: this.model.get('tableTitle')
    });
    $printoutEl = questionPrintoutView.render().$el;
    return $printoutEl.find('div.content');
  };

  HtmlExport.prototype.exportPrevalence = function() {
    var $printoutEl, diagnosticPrevalencePrintoutView;
    diagnosticPrevalencePrintoutView = new DiagnosticPrevalencesView({
      model: this.model
    });
    return $printoutEl = diagnosticPrevalencePrintoutView.render().$el;
  };

  HtmlExport.prototype.exportPooledValues = function() {
    var $printoutEl, pooledSensitivitySpecificityView;
    pooledSensitivitySpecificityView = new PooledSensitivitySpecificityView({
      model: this.model,
      outcomesView: new OutcomesView(this.getOutcomesObject(this.printout.viewType))
    });
    $printoutEl = pooledSensitivitySpecificityView.render().$el;
    return $printoutEl[0];
  };

  HtmlExport.prototype.convertClassToInlineStyles = function(doc, targetSelector) {
    var applyStyle, documentClone, elem, idx, len, rules, targetElement, _i, _j, _len, _ref;
    documentClone = doc.cloneNode(true);
    rules = document.styleSheets[1].cssRules;
    len = rules.length - 1;
    targetElement = $(documentClone).find(targetSelector);
    applyStyle = function(elem, idx) {
      var found;
      found = targetElement.find(elem);
      if (found.length > 0) {
        return elem.style.cssText += rules[idx].style.cssText;
      }
    };
    for (idx = _i = 0; 0 <= len ? _i <= len : _i >= len; idx = 0 <= len ? ++_i : --_i) {
      _ref = $(documentClone).find(rules[idx].selectorText);
      for (_j = 0, _len = _ref.length; _j < _len; _j++) {
        elem = _ref[_j];
        applyStyle(elem, idx);
      }
    }
    return documentClone;
  };

  HtmlExport.prototype.getSource = function(orientation, margins) {
    var doc, fs, outcomes, printoutEl, printoutHTML, printoutTemplate, spliceIdx, _ref, _ref1;
    if (orientation == null) {
      orientation = 'portrait';
    }
    if (margins == null) {
      margins = {};
    }
    this.model.store();
    fs = new FootnotesService({
      model: this.model,
      questionFootnotesAttr: this.printout.viewType.questionFootnotesAttr
    });
    outcomes = ((_ref = this.outcomeOrAppendicesIds) != null ? _ref.length : void 0) ? this.model.get('outcomes').filter((function(_this) {
      return function(o) {
        var _ref1;
        return _ref1 = o.cid, __indexOf.call(_this.outcomeOrAppendicesIds, _ref1) >= 0;
      };
    })(this)) : this.model.get('outcomes').models;
    fs.automaticallySortFootnotes(outcomes);
    if (this.printoutType === 'recommendations-v1') {
      doc = this.convertClassToInlineStyles(document, '.summary-of-judgements');
      printoutEl = this._exportRecommendations(doc);
    } else if ((_ref1 = this.printoutType) === 'layerOne' || _ref1 === 'layerTwo') {
      printoutEl = this.exportOutcomes();
      $(printoutEl).find('.pooled-values-container').append(this.exportPooledValues());
      $(printoutEl).find('.prevalences-container').append(this.exportPrevalence());
    } else {
      printoutEl = this.exportOutcomes();
      $(printoutEl).find('.question-data').append(this.exportQuestion());
    }
    printoutTemplate = require(this.printout.template);
    printoutHTML = printoutTemplate(this._getPrintoutData(orientation, margins));
    _.defer((function(_this) {
      return function() {
        return _this.model.restore();
      };
    })(this));
    $(printoutEl).find('td[style="display: none;"],th[style="display: none;"]').remove();
    spliceIdx = printoutHTML.indexOf(this.printout.spliceTag) + this.printout.spliceTag.length;
    return [printoutHTML.slice(0, spliceIdx), printoutEl.innerHTML, printoutHTML.slice(spliceIdx)].join('');
  };

  HtmlExport.prototype["export"] = function(orientation, margins) {
    var blob;
    blob = new Blob([this.getSource(orientation, margins)], {
      type: 'text/html;charset=utf-8'
    });
    return FileSaver.saveFile(blob, "" + (this.model.get('question').substring(0, 100)) + ".html").then((function(_this) {
      return function() {
        return mediator.dialogs.success({
          message: $.t('projects:export_dialog.table_export_successful', {
            'tablename': _this.printout.name
          }),
          ms: 10000,
          closeBtn: true
        });
      };
    })(this), function(reason) {
      if (reason !== 'cancelled') {
        return mediator.dialogs.error(reason);
      }
    });
  };

  HtmlExport.prototype.getOutcomesObject = function(viewType) {
    var _ref;
    return {
      viewType: viewType,
      questionModel: this.model,
      short: (_ref = this.options) != null ? _ref.short : void 0
    };
  };

  HtmlExport.prototype._getPrintoutData = function(orientation, margins) {
    var filteredOutcomes, notes, _ref, _ref1;
    notes = ((_ref = this.outcomeOrAppendicesIds) != null ? _ref.length : void 0) ? (filteredOutcomes = this.model.get('outcomes').filter((function(_this) {
      return function(o) {
        var _ref1;
        return _ref1 = o.cid, __indexOf.call(_this.outcomeOrAppendicesIds, _ref1) >= 0;
      };
    })(this)), this.model.getFootnotesArray(_(filteredOutcomes).pluck('id'), this.printout.viewType.questionFootnotesAttr)) : this.model.getFootnotesArray();
    return {
      diagnostic: this.model.isDiagnostic(),
      notes: notes,
      short: (_ref1 = this.options) != null ? _ref1.short : void 0,
      question: this.model.get('question'),
      orientation: orientation,
      margins: margins
    };
  };

  return HtmlExport;

})();
