var ProjectActions, ProjectStore, alt, immutable;

alt = require('alt');

immutable = require('stores/utils/immutable');

ProjectActions = require('actions/project_actions');

ProjectStore = (function() {
  function ProjectStore() {
    this.state = Immutable.Map({
      project: Immutable.Map()
    });
    this.bindActions(ProjectActions);
  }

  ProjectStore.prototype.onFetch = function(data) {
    return this.setState(this.state.set('project', Immutable.fromJS(data)));
  };

  return ProjectStore;

})();

module.exports = alt.createStore(immutable(ProjectStore), 'ProjectStore');
