_.namespace(module, function(require) {
  var DbHelper, Exceptions, W, mediator;
  DbHelper = require('base/lib/db_helper');
  Exceptions = require('lib/exceptions');
  W = require('when');
  mediator = require('mediator');
  return {
    exportPresentation: function(recommendationId, presentationData) {
      var adapter, dbName, recommendation;
      recommendation = null;
      dbName = mediator.project.id;
      adapter = mediator.services.storePersistenceAdapter;
      return W(adapter.fetch(dbName, recommendationId, {
        attachments: true
      })).then((function(_this) {
        return function(result) {
          _this.recommendation = result;
          delete _this.recommendation._rev;
          _this.recommendation._id = "" + dbName + "_" + recommendationId;
          _this.recommendation.presentationType = presentationData.type;
          _this.recommendation.presentationTitle = $.t("es:recommendations.presentations.for", {
            for_whom: presentationData.text
          });
          _this.recommendation.questionTitle = presentationData.question;
          return _this._checkForDocument(_this.recommendation._id);
        };
      })(this)).then((function(_this) {
        return function(result) {
          var loadingIndicator;
          if (result) {
            _this.recommendation._rev = result instanceof Object ? result._rev : (JSON.parse(result))._rev;
          }
          loadingIndicator = mediator.dialogs.loading($.t('messages.working'));
          return W($.ajax({
            type: "PUT",
            url: "" + (DbHelper.getPresentationsDbUrl()) + "/" + _this.recommendation._id,
            contentType: "application/json",
            timeout: 20 * 1000,
            data: JSON.stringify(_this.recommendation),
            xhrFields: {
              withCredentials: true
            }
          })).then(function(result) {
            var url;
            url = DbHelper.getPresentationUrl(JSON.parse(result).id);
            return window.open(url, '_blank');
          })["finally"](function() {
            return loadingIndicator.destroy();
          });
        };
      })(this))["catch"](function(error) {
        return typeof Raven !== "undefined" && Raven !== null ? Raven.captureException(error) : void 0;
      });
    },
    _checkForDocument: function(docId) {
      var url;
      url = "" + (DbHelper.getPresentationsDbUrl()) + "/" + docId;
      return W($.get(url)).then(function(result) {
        return result;
      })["catch"](function(xhr) {
        if (xhr.status === 404) {
          return false;
        } else {
          mediator.dialogs.error($.t('errors:general.server_error'));
          throw new Exceptions.server_error;
        }
      });
    }
  };
});
