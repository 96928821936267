var Collection, DbHelper, DraftQuestion, DraftQuestions, Exceptions, FormsApiHelper, Model, Question, QuestionsScopeWorkflowService, W, mediator, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; },
  __slice = [].slice;

DraftQuestions = require('base/models/draft_questions');

DraftQuestion = require('base/models/draft_question');

Question = require('models/question');

Collection = require('models/base/collection');

Model = require('models/base/model');

Exceptions = require('lib/exceptions');

DbHelper = require('base/lib/db_helper');

FormsApiHelper = require('base/lib/forms_api_helper').questionGeneration;

mediator = require('mediator');

W = require('when/when');

utils = require('base/lib/utils');

module.exports = QuestionsScopeWorkflowService = (function() {
  QuestionsScopeWorkflowService.prototype._STEPS = ['questions.initial', 'questions.brainstorming', 'questions.moderation', 'questions.rating', 'questions.proposal', 'questions.approval', 'questions.finished'];

  QuestionsScopeWorkflowService.prototype._STEPS_WITH_NOTIFICATIONS = ['questions.brainstorming', 'questions.rating', 'questions.approval'];

  function QuestionsScopeWorkflowService(_arg) {
    this.project = _arg.project;
    this.saveAdminComments = __bind(this.saveAdminComments, this);
    this._formsApi = FormsApiHelper;
  }

  QuestionsScopeWorkflowService.prototype.getStepsInOrder = function() {
    return this._STEPS;
  };

  QuestionsScopeWorkflowService.prototype.getCurrentStep = function() {
    var _ref;
    return (_ref = this.project.get('questionScopeWorkflowStep')) != null ? _ref : this._STEPS[0];
  };

  QuestionsScopeWorkflowService.prototype._stepAfter = function(step) {
    var currentIndex, index;
    currentIndex = _(this._STEPS).indexOf(step);
    index = currentIndex === this._STEPS.length - 1 ? currentIndex : currentIndex + 1;
    return this._STEPS[index];
  };

  QuestionsScopeWorkflowService.prototype.completeStep = function(step) {
    var completeStepPromise, nextStep;
    completeStepPromise = (function() {
      switch (step) {
        case 'questions.initial':
          return this._publishInitialDraft();
        case 'questions.brainstorming':
          return this._closeBrainstorming();
        case 'questions.moderation':
          return this._sendToRate();
        case 'questions.rating':
          return this._closeRating();
        case 'questions.proposal':
          return this._sendToApproval();
        case 'questions.approval':
          return this._closeApproval();
        case 'questions.finished':
          return W.resolve();
      }
    }).call(this);
    nextStep = this._stepAfter(step);
    return completeStepPromise.then(this._createNotificationRequests(nextStep)).then((function(_this) {
      return function() {
        return _this.project.save('questionScopeWorkflowStep', nextStep);
      };
    })(this));
  };

  QuestionsScopeWorkflowService.prototype.getBrainstormingResults = function(options) {
    return this._invokeIfStep(this._fetchQuestionsFromBrainstorming, 'questions.brainstorming', options);
  };

  QuestionsScopeWorkflowService.prototype.getBrainstormingMembersComments = function() {
    return this._formsApi.getFormDoc(this.project.get('questionScopeCentralDoc')).then(function(doc) {
      var comments;
      comments = {};
      _(doc.questions).each(function(question) {
        return comments[question.id] = question.brainstormingMembersComments;
      });
      return comments;
    }).otherwise((function(_this) {
      return function(error) {
        return _this._formsApi.errorHandler(error, $.t('scope:questions.messages.comments_fetching_error'));
      };
    })(this));
  };

  QuestionsScopeWorkflowService.prototype.getRatingStatus = function(options) {
    return this._invokeIfStep(this._fetchRatingStatus, 'questions.rating', options);
  };

  QuestionsScopeWorkflowService.prototype.getRatingMembersComments = function() {
    return this._getMembersComments('questionsRatingDocId', 'ratingMemberComment');
  };

  QuestionsScopeWorkflowService.prototype.getApprovalStatus = function(options) {
    return this._invokeIfStep(this._fetchApprovalStatus, 'questions.approval', options);
  };

  QuestionsScopeWorkflowService.prototype.getApprovalMembersComments = function() {
    return this._getMembersComments('questionsApprovalDocId', 'approvalMemberComment');
  };

  QuestionsScopeWorkflowService.prototype.getAdminComments = function(step) {
    var _ref;
    return (_ref = this.project.get('draftQuestionsComments')) != null ? _ref[step] : void 0;
  };

  QuestionsScopeWorkflowService.prototype.saveAdminComments = function(step, questionId, commentText) {
    var draftQuestionsComments, stepComments, _ref;
    draftQuestionsComments = (_ref = this.project.get('draftQuestionsComments')) != null ? _ref : {};
    stepComments = draftQuestionsComments[step] || {};
    stepComments[questionId] = commentText;
    draftQuestionsComments[step] = stepComments;
    return mediator.project.save('draftQuestionsComments', draftQuestionsComments).then(function(project) {
      return project.get('draftQuestionsComments')[step];
    });
  };

  QuestionsScopeWorkflowService.prototype.mapMemberIdsToIndexes = function(comments) {
    return _(comments).chain().map(function(comment) {
      return _(comment != null ? comment : {}).keys();
    }).flatten().uniq().map(function(memberId, idx) {
      return [memberId, idx + 1];
    }).object().value();
  };

  QuestionsScopeWorkflowService.prototype._getMembersComments = function(memberDocsKey, commentKeyName) {
    var members;
    members = mediator.colls.teamMembers;
    return this._formsApi.getFormDocs(members.getMembersFormsIds(memberDocsKey)).then(function(docs) {
      var comments;
      comments = {};
      _(docs).each(function(doc) {
        return _(doc.questions).map(function(question) {
          if (!comments[question.id]) {
            comments[question.id] = {};
          }
          return comments[question.id][doc.memberId] = question[commentKeyName];
        });
      });
      return comments;
    }).otherwise((function(_this) {
      return function(error) {
        return _this._formsApi.errorHandler(error, $.t('scope:questions.messages.comments_fetching_error'));
      };
    })(this));
  };

  QuestionsScopeWorkflowService.prototype._invokeIfStep = function(func, step, options) {
    if (this.getCurrentStep() === step || (options != null ? options.force : void 0)) {
      return func.call(this).otherwise(this._formsApi.errorHandler);
    } else {
      return W.reject();
    }
  };

  QuestionsScopeWorkflowService.prototype._parseQuestionsAfterBrainstorming = function(response) {
    var data, question, submittedQuestionIds, submittedQuestions, _i, _len, _results;
    submittedQuestionIds = _(response.submissionData).chain().pluck('submittedQuestions').flatten().value();
    submittedQuestions = _(response.questions).filter(function(q) {
      var _ref;
      return (_ref = q.id, __indexOf.call(submittedQuestionIds, _ref) >= 0) || !q.userId;
    });
    _results = [];
    for (_i = 0, _len = submittedQuestions.length; _i < _len; _i++) {
      data = submittedQuestions[_i];
      question = new DraftQuestion(data);
      if (!question.get('source')) {
        question.set('source', 'brainstorming', {
          clean: true
        });
      }
      _results.push(question);
    }
    return _results;
  };

  QuestionsScopeWorkflowService.prototype._fetchQuestionsFromBrainstorming = function() {
    return this._formsApi.getFormDoc(this.project.get('questionScopeCentralDoc')).then((function(_this) {
      return function(response) {
        return new DraftQuestions(_this._parseQuestionsAfterBrainstorming(response));
      };
    })(this)).otherwise(this._formsApi.errorHandler);
  };

  QuestionsScopeWorkflowService.prototype._fetchRatingStatus = function() {
    var members;
    members = mediator.colls.teamMembers;
    return W.resolve(this._getAttributesFromForms(members.getMembersFormsIds('questionsRatingDocId'), 'givenNames', 'lastName', 'status', 'userRatingStatus'));
  };

  QuestionsScopeWorkflowService.prototype._handleMissingDocs = function(returnedDocs, requestedDocIds) {
    var docIds, nonExistingIds;
    docIds = _(returnedDocs).pluck('_id');
    nonExistingIds = _(requestedDocIds).difference(docIds);
    if (!_(nonExistingIds).isEmpty()) {
      return typeof Raven !== "undefined" && Raven !== null ? Raven.captureMessage("Following form docs are missing on central DB: " + nonExistingIds + ", project: " + mediator.project.id) : void 0;
    }
  };

  QuestionsScopeWorkflowService.prototype._publishInitialDraft = function() {
    var document;
    if (mediator.colls.teamMembers.isEmpty()) {
      return W.reject(Exceptions.localized('scope.no_team_members'));
    }
    document = {
      projectName: this.project.get('name'),
      type: this._stepAfter('questions.initial'),
      questions: this.project.get('draftQuestions'),
      status: 'new'
    };
    return this._formsApi.saveFormDoc(document).then((function(_this) {
      return function(response) {
        _this.project.save('questionScopeCentralDoc', response.id);
        return mediator.colls.teamMembers.map(function(member) {
          return "" + response.id + "/" + member.id;
        });
      };
    })(this));
  };

  QuestionsScopeWorkflowService.prototype._closeBrainstorming = function() {
    return this._formsApi.getFormDoc(this.project.get('questionScopeCentralDoc')).then((function(_this) {
      return function(doc) {
        doc.status = 'closed';
        return _this._formsApi.saveFormDoc(doc).then(function(response) {
          var questions;
          questions = _this._parseQuestionsAfterBrainstorming(doc);
          _this.project.get('draftQuestions').add(_(questions).filter(function(q) {
            return q.get('source') === 'brainstorming';
          }));
          return response.id;
        });
      };
    })(this));
  };

  QuestionsScopeWorkflowService.prototype._sendToRate = function() {
    var documents;
    documents = this._oneDocumentPerMember({
      type: 'questions.rating',
      questions: this.project.get('draftQuestions'),
      status: 'new',
      projectName: this.project.get('name')
    });
    return this._formsApi.bulkSaveFormDocs(documents).then((function(_this) {
      return function(response) {
        var formIds;
        formIds = _(response).pluck('id');
        _this._saveFormIdForMembers('questionsRatingDocId', formIds);
        return formIds;
      };
    })(this));
  };

  QuestionsScopeWorkflowService.prototype._closeRating = function() {
    var formIds, ratings;
    formIds = mediator.colls.teamMembers.getMembersFormsIds('questionsRatingDocId');
    ratings = {};
    return this._formsApi.getFormDocs(formIds).then((function(_this) {
      return function(formDocs) {
        var doc, id, question, ratingsList, _i, _j, _len, _len1, _name, _ref;
        _this._handleMissingDocs(formDocs, formIds);
        for (_i = 0, _len = formDocs.length; _i < _len; _i++) {
          doc = formDocs[_i];
          doc.userRatingStatus = doc.status;
          doc.status = 'closed';
          _ref = doc.questions;
          for (_j = 0, _len1 = _ref.length; _j < _len1; _j++) {
            question = _ref[_j];
            if (ratings[_name = question.id] == null) {
              ratings[_name] = [];
            }
            ratings[question.id].push(Number(question.rating));
          }
        }
        for (id in ratings) {
          ratingsList = ratings[id];
          question = _this.project.get('draftQuestions').get(id);
          question.set('medianRating', utils.arrayMedian(_(ratingsList).compact()));
          question.save();
        }
        return _this._formsApi.bulkSaveFormDocs(formDocs);
      };
    })(this));
  };

  QuestionsScopeWorkflowService.prototype._sendToApproval = function() {
    var documents;
    documents = this._oneDocumentPerMember({
      type: 'questions.approval',
      questions: this.project.get('draftQuestions'),
      status: 'new',
      projectName: this.project.get('name')
    });
    return this._formsApi.bulkSaveFormDocs(documents).then((function(_this) {
      return function(response) {
        var formIds;
        formIds = _(response).pluck('id');
        _this._saveFormIdForMembers('questionsApprovalDocId', formIds);
        return formIds;
      };
    })(this));
  };

  QuestionsScopeWorkflowService.prototype._fetchApprovalStatus = function() {
    var docIds;
    docIds = mediator.colls.teamMembers.getMembersFormsIds('questionsApprovalDocId');
    return W.resolve(this._getAttributesFromForms(docIds, 'givenNames', 'lastName', 'status', 'approved', 'approvalComments'));
  };

  QuestionsScopeWorkflowService.prototype._closeApproval = function() {
    var formIds;
    formIds = mediator.colls.teamMembers.getMembersFormsIds('questionsApprovalDocId');
    return this._formsApi.getFormDocs(formIds).then((function(_this) {
      return function(docs) {
        var attrs, doc, draftQuestion, question, _i, _j, _len, _len1, _ref;
        _this._handleMissingDocs(docs, formIds);
        for (_i = 0, _len = docs.length; _i < _len; _i++) {
          doc = docs[_i];
          doc.status = 'closed';
        }
        _ref = _this.project.get('draftQuestions').where({
          approvalStatus: 'accepted'
        });
        for (_j = 0, _len1 = _ref.length; _j < _len1; _j++) {
          draftQuestion = _ref[_j];
          attrs = _(draftQuestion.attributes).omit(draftQuestion.idAttribute);
          question = new Question(attrs);
          question.set(question.idAttribute, draftQuestion.id);
          _this.project.addQuestion(question);
          question.save();
        }
        return _this._formsApi.bulkSaveFormDocs(docs);
      };
    })(this));
  };

  QuestionsScopeWorkflowService.prototype._getAttributesFromForms = function() {
    var attrs, formIds;
    formIds = arguments[0], attrs = 2 <= arguments.length ? __slice.call(arguments, 1) : [];
    return this._formsApi.getFormDocs(formIds).then((function(_this) {
      return function(docs) {
        var doc, result, _i, _len;
        result = new Collection();
        _this._handleMissingDocs(docs, formIds);
        for (_i = 0, _len = docs.length; _i < _len; _i++) {
          doc = docs[_i];
          result.add(new Model(_(doc).pick(attrs)));
        }
        return result;
      };
    })(this));
  };

  QuestionsScopeWorkflowService.prototype._oneDocumentPerMember = function(documentHeader) {
    return mediator.colls.teamMembers.map(function(member) {
      return _(documentHeader).chain().clone().extend({
        memberId: member.get('_id'),
        givenNames: member.get('givenNames'),
        lastName: member.get('lastName')
      }).value();
    });
  };

  QuestionsScopeWorkflowService.prototype._saveFormIdForMembers = function(attr, formIds) {
    var formId, member, _i, _len, _ref, _ref1, _results;
    _ref = _.zip(mediator.colls.teamMembers.models, formIds);
    _results = [];
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      _ref1 = _ref[_i], member = _ref1[0], formId = _ref1[1];
      _results.push(member.save(attr, formId));
    }
    return _results;
  };

  QuestionsScopeWorkflowService.prototype._createNotificationRequests = function(step) {
    if (__indexOf.call(this._STEPS_WITH_NOTIFICATIONS, step) < 0) {
      return;
    }
    return (function(_this) {
      return function(formIds) {
        var documentHeader, documents, formId, isArray;
        documentHeader = {
          type: 'scopeNotification',
          step: step.slice(step.indexOf('.') + 1),
          status: 'new',
          project: _this.project.get('name')
        };
        if (_(formIds).isArray()) {
          isArray = true;
        } else {
          formId = formIds;
        }
        documents = mediator.colls.teamMembers.map(function(member) {
          var _ref;
          if (isArray) {
            _ref = [_(formIds).head(), _(formIds).tail()], formId = _ref[0], formIds = _ref[1];
          }
          return _(documentHeader).chain().clone().extend({
            givenNames: member.get('givenNames'),
            lastName: member.get('lastName'),
            email: member.get('email'),
            formId: formId
          }).value();
        });
        return _this._formsApi.bulkSaveFormDocs(documents);
      };
    })(this);
  };

  return QuestionsScopeWorkflowService;

})();
