var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "  <div class=\"welcome-message\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.welcome : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.program(4, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </div>\n";
},"2":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <h2>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "projects:welcome_title", {"name":"i18n","hash":{},"data":data})))
    + "</h2>\n\n        <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "projects:welcome_text", {"name":"i18n","hash":{},"data":data})))
    + "</p>\n";
},"4":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <div class=\"message-box\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "projects:replication_in_progress", {"name":"i18n","hash":{},"data":data})))
    + "\n          <span class=\"loader-bar\"></span>\n        </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = ((helpers.empty || (depth0 && depth0.empty) || helperMissing).call(depth0, (depth0 != null ? depth0.items : depth0), {"name":"empty","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n<ol class=\"standard-list\"></ol>\n<div class=\"row mt-10\">\n  <div class=\"col-6\">\n    <button class=\"btn btn-block create\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "projects:add_new", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n  </div>\n  <div class=\"col-6\">\n    <button class=\"btn btn-block import\"\n      title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "projects:import_title", {"name":"i18n","hash":{},"data":data})))
    + "'>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "projects:import", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n  </div>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}