_.namespace(module, function(require) {
  var DbHelper, W, exceptions, mediator, utils;
  mediator = require('mediator');
  DbHelper = require('base/lib/db_helper');
  utils = require('base/lib/utils');
  exceptions = require('lib/exceptions');
  W = require('when');
  W.poll = require('when/poll');
  return {
    _getWindow: function() {
      return window;
    },
    getConnectionStatus: function(service, login) {
      return mediator.services.udb.getDoc(login, 'external_accounts').then(function(doc) {
        if (doc.get(service) != null) {
          return {
            connected: true,
            account: doc.get(service)
          };
        } else {
          return {
            connected: false
          };
        }
      });
    },
    _getModalOptions: function() {
      var height, left, top, width;
      width = 800;
      height = 600;
      left = (screen.width - width) / 2;
      top = (screen.height - height) / 2;
      return "location=no,status=no,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left;
    },
    connect: function(service, login, password) {
      var context, guid;
      guid = utils.generateGUID();
      context = {
        guid: guid
      };
      return W.promise((function(_this) {
        return function(resolve, reject) {
          var popup, url;
          url = ("" + (DbHelper.getBackendUrl()) + "/oauth2/connect/" + service + "?") + ("login=" + (encodeURIComponent(login)) + "&password=" + (encodeURIComponent(password)));
          popup = _this._getWindow().open(url, 'grade_popup', _this._getModalOptions());
          W.poll((function() {
            return popup.closed;
          }), 500, _.identity).then(function() {
            return reject(new exceptions.authentication.oauth_flow_failed());
          });
          mediator.subscribe('popupFinished.oauth.success', function(_arg) {
            var login, provider;
            login = _arg.login, provider = _arg.provider;
            if (this.guid === guid) {
              return resolve(login);
            }
          }, context);
          return mediator.subscribe('popupFinished.oauth.error', function(_arg) {
            var code, message;
            code = _arg.code, message = _arg.message;
            if (this.guid === guid) {
              return reject(new exceptions.server_reported(message));
            }
          }, context);
        };
      })(this))["finally"](function() {
        return mediator.unsubscribe(null, null, context);
      });
    },
    disconnect: function(service, login) {
      var url;
      url = ("" + (DbHelper.getBackendUrl()) + "/oauth2/disconnect/" + service + "?") + ("login=" + (encodeURIComponent(login)));
      return W($.ajax(url, {
        type: 'POST'
      })).otherwise(function(xhr) {
        var response;
        response = $.parseJSON(xhr.responseText);
        throw new exceptions.server_reported(response.reason);
      });
    }
  };
});
