var ReactComponent, View, Voting, VotingActions, VotingView,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

Voting = require('components/administration/voting');

VotingActions = require('actions/voting_actions');

ReactComponent = require('base/lib/traits/react_component');

module.exports = VotingView = (function(_super) {
  __extends(VotingView, _super);

  function VotingView() {
    return VotingView.__super__.constructor.apply(this, arguments);
  }

  VotingView.prototype.container = '#page-container';

  VotingView.prototype.className = 'voting-view';

  VotingView.prototype.autoRender = true;

  VotingView.prototype.initialize = function() {
    this.enable(ReactComponent);
    return VotingView.__super__.initialize.apply(this, arguments);
  };

  VotingView.prototype.afterRender = function() {
    VotingView.__super__.afterRender.apply(this, arguments);
    return this.renderComponent(Voting, VotingActions);
  };

  VotingView.prototype.dispose = function() {
    this.unmountComponent();
    return VotingView.__super__.dispose.apply(this, arguments);
  };

  return VotingView;

})(View);
