var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "  <div class=\"form\">\n    <div class=\"row\">\n      <div class=\"col-12\">\n        <h2>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "general", {"name":"i18n","hash":{},"data":data})))
    + "</h2>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <div class=\"col-3\">\n        <label>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/registration.email", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n      </div>\n      <div class=\"col-9\">\n        "
    + escapeExpression(((helper = (helper = helpers.email || (depth0 != null ? depth0.email : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"email","hash":{},"data":data}) : helper)))
    + "\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-12\">\n        <h2>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "connected_accounts", {"name":"i18n","hash":{},"data":data})))
    + "</h2>\n        <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "connected_accounts_explanation", {"name":"i18n","hash":{},"data":data})))
    + "</p>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <div class=\"col-3\">\n        <img src=\"images/archie-logo.gif\">\n      </div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.archieConnected : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.program(4, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </div>\n    <div class=\"row\">\n      <div class=\"col-4 col-offset-4 mt-20\">\n        <button class=\"btn btn-block close\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/actions.close", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n      </div>\n    </div>\n  </div>\n";
},"2":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <div class=\"col-6\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "connected", {"name":"i18n","hash":{
    'login': ((depth0 != null ? depth0.archieAccountName : depth0))
  },"data":data})))
    + "\n        </div>\n        <div class=\"col-3\">\n          <button class=\"btn btn-block disconnect\" data-provider=\"archie\">\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "disconnect", {"name":"i18n","hash":{},"data":data})))
    + "\n          </button>\n        </div>\n";
},"4":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <div class=\"col-6\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "not_connected", {"name":"i18n","hash":{},"data":data})))
    + "\n        </div>\n        <div class=\"col-3\">\n          <button class=\"btn btn-block connect\" data-provider=\"archie\">\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "connect", {"name":"i18n","hash":{},"data":data})))
    + "\n          </button>\n        </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "account", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}