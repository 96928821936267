PureRenderMixin = React.addons.PureRenderMixin

EtdHeaderLabelCell = React.createClass
  displayName: 'EtdHeaderLabelCell'
  mixins: [PureRenderMixin]

  render: ->
    text = if @props.text then "#{@props.text}:" else ''

    <td className="first" rowSpan={@props.rowSpan}>
      <span className="section-name">
        {text}
      </span>
    </td>

module.exports = EtdHeaderLabelCell
