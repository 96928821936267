RadioWithLabel = require 'components/common/radio_with_label'

HorizontalRadios = React.createClass
  displayName: 'HorizontalRadios'

  propTypes:
    groupName: React.PropTypes.string.isRequired
    options: React.PropTypes.object.isRequired
    additionalOptions: React.PropTypes.object.isRequired
    selectedOption: React.PropTypes.string.isRequired
    onChange: React.PropTypes.func.isRequired

  onChange: (e) ->
    @props.onChange e.currentTarget.value

  _renderRadioCell: (options, cellWidth, additionalOptions = false) ->
    options.map (optionObj, idx) =>
      classes = classNames 'additional-options-border': idx is 0 and additionalOptions
      <td className={classes} style={width: "#{cellWidth}%"} key={optionObj.get 'value'}>
        <RadioWithLabel
          label={optionObj.get 'text'}
          name={@props.groupName}
          value={optionObj.get 'value'}
          checked={optionObj.get('value') is @props.selectedOption}
          onChange={@onChange}
        />
      </td>

  render: ->
    cellWidth = 100 / (@props.options.size + @props.additionalOptions.size)
    <table className="horizontal-radios">
      <tr>
        {@_renderRadioCell @props.options, cellWidth}
        {@_renderRadioCell @props.additionalOptions, cellWidth, true}
      </tr>
    </table>

module.exports = HorizontalRadios
