module.exports = {
  diagnosticQuestionContext: function() {
    return [
      {
        '@base': 'http://dbep.gradepro.org/',
        '@vocab': 'http://dbep.gradepro.org/schema/',
        '@language': i18n.lng(),
        'schema': 'http://schema.org/',
        'cochrane': 'http://linkeddata.cochrane.org/ontologies/pico/',
        'xsd': 'http://www.w3.org/2001/XMLSchema#',
        'version': 'schema:version',
        'name': 'schema:name',
        'alternateName': 'schema:alternateName',
        'sameAs': 'schema:sameAs',
        'publicationTime': {
          '@id': 'schema:datePublished',
          '@type': 'xsd:dateTime'
        },
        'modificationTime': {
          '@id': 'schema:dateModified',
          '@type': 'xsd:dateTime'
        },
        'author': {
          '@id': 'schema:author',
          '@container': '@list'
        },
        'explanation': {
          '@container': '@list'
        },
        'code': 'schema:code',
        'codeValue': 'schema:codeValue',
        'codingSystem': 'schema:codingSystem',
        'unitCode': 'schema:unitCode',
        'unitText': 'schema:unitText',
        'value': 'schema:value',
        'text': 'schema:value',
        'lowerBound': 'schema:minValue',
        'upperBound': 'schema:maxValue',
        'Person': 'schema:Person',
        'MedicalCode': 'schema:MedicalCode',
        'MedicalSymptom': 'schema:MedicalSymptom',
        'MedicalCondition': 'schema:MedicalCondition',
        'QuantitativeValue': 'schema:QuantitativeValue',
        'MedicalTest': 'schema:MedicalTest'
      }, {
        'healthProblemOrPopulation': 'cochrane:population',
        'condition': 'cochrane:condition',
        'outcome': {
          '@id': 'cochrane:outcome',
          '@container': '@list',
          'diagnosticTest': {
            '@container': '@list'
          }
        }
      }, {
        'title': 'name',
        'sofTitle': 'alternateName',
        'evidenceSummary': {
          '@container': '@list'
        },
        'patientGroup': {
          '@container': '@list'
        }
      }
    ];
  },
  recommendationsContext: function() {
    return [
      {
        '@base': 'http://dbep.gradepro.org/',
        '@vocab': 'http://dbep.gradepro.org/schema/',
        '@language': i18n.lng(),
        'schema': 'http://schema.org/',
        'cochrane': 'http://linkeddata.cochrane.org/ontologies/pico/',
        'xsd': 'http://www.w3.org/2001/XMLSchema#',
        'version': 'schema:version',
        'name': 'schema:name',
        'sameAs': 'schema:sameAs',
        'publicationTime': {
          '@id': 'schema:datePublished',
          '@type': 'xsd:dateTime'
        },
        'modificationTime': {
          '@id': 'schema:dateModified',
          '@type': 'xsd:dateTime'
        },
        'author': {
          '@id': 'schema:author',
          '@container': '@list'
        },
        'explanation': {
          '@container': '@list'
        },
        'code': 'schema:code',
        'codeValue': 'schema:codeValue',
        'codingSystem': 'schema:codingSystem',
        'unitCode': 'schema:unitCode',
        'unitText': 'schema:unitText',
        'value': 'schema:value',
        'text': 'schema:value',
        'lowerBound': 'schema:minValue',
        'upperBound': 'schema:maxValue',
        'Person': 'schema:Person',
        'MedicalCode': 'schema:MedicalCode',
        'MedicalSymptom': 'schema:MedicalSymptom',
        'MedicalCondition': 'schema:MedicalCondition',
        'QuantitativeValue': 'schema:QuantitativeValue'
      }, {
        'description': 'schema:description',
        'guidelineSubject': 'schema:guidelineSubject',
        'publicationTime': 'schema:guidelineDate',
        'evidenceProfile': 'schema:evidenceOrigin',
        'recommendationStrength': 'schema:recommendationStrength',
        'MedicalGuidelineRecommendation': 'schema:MedicalGuidelineRecommendation',
        'MedicalGuidelineContraindication': 'schema:MedicalGuidelineContraindication'
      }
    ];
  },
  managementQuestionContext: function() {
    return [
      {
        '@base': 'http://dbep.gradepro.org/',
        '@vocab': 'http://dbep.gradepro.org/schema/',
        '@language': i18n.lng(),
        'schema': 'http://schema.org/',
        'cochrane': 'http://linkeddata.cochrane.org/ontologies/pico/',
        'xsd': 'http://www.w3.org/2001/XMLSchema#',
        'version': 'schema:version',
        'name': 'schema:name',
        'sameAs': 'schema:sameAs',
        'publicationTime': {
          '@id': 'schema:datePublished',
          '@type': 'xsd:dateTime'
        },
        'modificationTime': {
          '@id': 'schema:dateModified',
          '@type': 'xsd:dateTime'
        },
        'author': {
          '@id': 'schema:author',
          '@container': '@list'
        },
        'explanation': {
          '@container': '@list'
        },
        'code': 'schema:code',
        'codeValue': 'schema:codeValue',
        'codingSystem': 'schema:codingSystem',
        'unitCode': 'schema:unitCode',
        'unitText': 'schema:unitText',
        'value': 'schema:value',
        'text': 'schema:value',
        'lowerBound': 'schema:minValue',
        'upperBound': 'schema:maxValue',
        'Person': 'schema:Person',
        'MedicalCode': 'schema:MedicalCode',
        'MedicalSymptom': 'schema:MedicalSymptom',
        'MedicalCondition': 'schema:MedicalCondition',
        'QuantitativeValue': 'schema:QuantitativeValue'
      }, {
        'PICO': 'cochrane:PICO',
        'healthProblemOrPopulation': 'cochrane:population',
        'condition': 'cochrane:condition',
        'intervention': 'cochrane:intervention',
        'comparison': 'cochrane:comparison',
        'outcome': {
          '@id': 'cochrane:outcome',
          '@container': '@list'
        }
      }, {
        'title': 'name',
        'sofTitle': 'alternateName',
        'evidenceSummary': {
          '@container': '@list'
        },
        'patientGroup': {
          '@container': '@list'
        }
      }
    ];
  },
  timestampToISOWithTimeZone: function(timestamp) {
    return (moment(new Date(timestamp))).format();
  },
  capitalizeFirstLetter: function(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },
  existsAndNotEmpty: function(variable) {
    return (variable != null) && variable !== '';
  },
  existsAndIsTrue: function(variable) {
    return _.isBoolean(variable) && variable;
  },
  removeFalseValuesFromObject: function(obj) {
    var key, value;
    for (key in obj) {
      value = obj[key];
      if (!value && value !== '') {
        delete obj[key];
      }
    }
    return obj;
  }
};
