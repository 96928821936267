alt = require 'alt'
EtdStore = require 'stores/etd_store'
EtdActions = require 'actions/etd_actions'
PureRenderMixin = React.addons.PureRenderMixin
Translation = require 'components/mixins/translation'
Button = require 'components/common/button'
Modal = require 'components/common/modal'
DetailedJudgements = require './judgements/detailed_judgements'

Judgement = React.createClass
  displayName: 'JudgementComponent'
  mixins: [
    PureRenderMixin
    Translation('es:recommendations.table')
  ]

  getInitialState: ->
    isModalOpen: false

  openModal: ->
    @etdStoreSnapshot = alt.takeSnapshot EtdStore
    @setState isModalOpen: true

  closeModal: ->
    @setState isModalOpen: false

  cancelDetailedJudgements: ->
    alt.bootstrap @etdStoreSnapshot
    @closeModal()

  applyDetailedJudgements: ->
    EtdActions.save()
    @closeModal()

  judgementChanged: (e) ->
    EtdActions.changeAndSaveJudgement @props.sectionId, e.currentTarget.value

  renderRadioWithLabel: (option, selectedOption) ->
    text = option.get?('text') ? option
    value = option.get?('value') ? option

    <label key={value}>
      <input
        type="radio"
        value={value}
        name={@props.sectionId}
        checked={"#{value}" is "#{selectedOption}"}
        onChange={@judgementChanged}/>
      <span>{text}</span>
      <br/>
    </label>

  renderRegularOptions: ->
    {options, additionalOptions, selectedOption} = @props
    <td rowSpan={@props.rowSpan} className="judgement">
      {options.map((option) => @renderRadioWithLabel option, selectedOption).toList()}
      <hr />
      {additionalOptions.map((option) => @renderRadioWithLabel option, selectedOption).toList()}
      {if @props.details
        <Button
          className="btn btn-block detailed-judgements-btn"
          label={@i18n "detailed_judgements.name"}
          onClick={@openModal} />
        <Modal
          className="detailed-judgements"
          isOpen={@state.isModalOpen}
          closeButton={false}
          onClose={@closeModal}
          title={@i18n 'detailed_judgements.name'} >

          <DetailedJudgements
            sectionId={@props.sectionId}
            sectionName={@props.sectionName}
            criterionDescription={@props.criterionDescription}
            details={@props.details}
            finalJudgementOptions={@props.options}
            finalJudgementAdditionalOptions={@props.additionalOptions}
            finalJudgementSelectedOption={@props.selectedOption}
            onApply={@applyDetailedJudgements}
            onCancel={@cancelDetailedJudgements}
            criticalOutcomes={@props.criticalOutcomes}
            questionType={@props.questionType}
          />
        </Modal>
      }
    </td>

  renderGroupedOptions: ->
    {selectedOption, optionGroups} = @props
    <td className='judgement'>
      <div className='option-groups'>
        <div className='upper-bound'>
          [{optionGroups.get 'upperBound'}]
        </div>
        <table className='groups'>
          {optionGroups.get('groups').map (group) =>
            <tr key={group.get 'groupName'}>
              <td className='options-cell'>
                <div className='options'>
                  {group.get('options').map (option, idx) =>
                    <div key={idx}>{@renderRadioWithLabel option, selectedOption}</div>
                  }
                </div>
              </td>
              <td className='group-name-cell'>
                [{group.get 'groupName'}]
              </td>
            </tr>
          }
        </table>
        <div className='lower-bound'>
          [{optionGroups.get 'lowerBound'}]
        </div>
      </div>
    </td>

  render: ->
    if @props.optionGroups
      @renderGroupedOptions()
    else
      @renderRegularOptions()

module.exports = Judgement
