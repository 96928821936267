var DraftQuestionView, EditableItemView, Question, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

EditableItemView = require('base/views/editable_item_view');

Question = require('models/question');

template = require('views/templates/scope/draft_question');

module.exports = DraftQuestionView = (function(_super) {
  __extends(DraftQuestionView, _super);

  function DraftQuestionView() {
    this.className = __bind(this.className, this);
    return DraftQuestionView.__super__.constructor.apply(this, arguments);
  }

  DraftQuestionView.prototype.template = template;

  DraftQuestionView.prototype.className = function() {
    return this.model.get('type');
  };

  DraftQuestionView.prototype.initialize = function() {
    DraftQuestionView.__super__.initialize.apply(this, arguments);
    this.delegate('click', 'button.duplicate', this._duplicate);
    this.delegate('click', '.add-comparator-test', this._addComparatorTest);
    return this.delegate('click', '.remove-comparator-test', this._removeComparatorTest);
  };

  DraftQuestionView.prototype.bindings = {
    '#intervention': 'intervention',
    '#comparison': 'comparison',
    '#indexTest': 'indexTest',
    '#comparatorTest': 'comparatorTest',
    '#questionFormat': {
      observe: 'questionFormat',
      selectOptions: {
        collection: (function() {
          return Question.QUESTION_FORMATS;
        })
      }
    },
    '#targetCondition': 'targetCondition',
    '#healthProblemOrPopulation': 'healthProblemOrPopulation',
    '.caption > span': 'question'
  };

  DraftQuestionView.prototype._duplicate = function() {
    return this.saveChanges().then((function(_this) {
      return function() {
        return _this.model.collection.add(_this.model.duplicate());
      };
    })(this)).done();
  };

  DraftQuestionView.prototype._addComparatorTest = function() {
    this.model.set('comparatorTestPresent', true);
    return this.render();
  };

  DraftQuestionView.prototype._removeComparatorTest = function() {
    this.model.set('comparatorTestPresent', false);
    return this.render();
  };

  DraftQuestionView.prototype.afterRender = function() {
    DraftQuestionView.__super__.afterRender.apply(this, arguments);
    if (this.options.preview) {
      this.$('button.edit').remove();
    }
    if (this.model.get('comparatorTestPresent')) {
      this.$('button.add-comparator-test').remove();
    }
    return this._setDiagnosticQuestionClass();
  };

  DraftQuestionView.prototype._setDiagnosticQuestionClass = function() {
    if (this.model.isDiagnostic()) {
      if (this.model.get('comparatorTestPresent')) {
        this.$el.addClass('diagnostic-comparator');
        return this.$el.removeClass('diagnostic-index');
      } else {
        this.$el.addClass('diagnostic-index');
        return this.$el.removeClass('diagnostic-comparator');
      }
    }
  };

  DraftQuestionView.prototype["delete"] = function() {
    return DraftQuestionView.__super__["delete"].call(this, $.t('messages.confirm'));
  };

  return DraftQuestionView;

})(EditableItemView);
