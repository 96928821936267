var EtdActions, EtdStore, PouchSource, QuestionsStore, alt, blockDataPath, detailedJudgementsDataPath, embeddedContentProvider, getOrderedSections, immutable, templateDataPath,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

alt = require('alt');

immutable = require('stores/utils/immutable');

embeddedContentProvider = require('lib/embedded_content');

EtdActions = require('actions/etd_actions');

PouchSource = require('stores/sources/pouch_source');

QuestionsStore = require('stores/questions_store');

getOrderedSections = function(blockData) {
  var sections;
  if (!blockData) {
    return;
  }
  sections = Immutable.OrderedMap();
  return sections.withMutations(function(sections) {
    return blockData.get('sectionsOrder').map(function(sectionName) {
      return sections.set(sectionName, blockData.getIn(['sections', sectionName]));
    });
  });
};

templateDataPath = ['template', 'templateData'];

blockDataPath = function(blockName) {
  return templateDataPath.concat(blockName);
};

detailedJudgementsDataPath = function(sectionId) {
  return blockDataPath('assessment').concat(['criteria', sectionId, 'details']);
};

EtdStore = (function() {
  function EtdStore() {
    var stateJS;
    stateJS = {
      _meta: {
        activeContentEditableId: '',
        dbId: '',
        presentationType: 'clinicians'
      },
      template: {}
    };
    this.state = Immutable.fromJS(stateJS);
    this.exportPublicMethods({
      isEditable: this.isEditable,
      getQuestion: this.getQuestion,
      getPresentationSection: this.getPresentationSection,
      getPresentationType: this.getPresentationType,
      getBackgroundDetailSections: this.getBackgroundDetailSections,
      getRecommendation: this.getRecommendation,
      getAssessment: this.getAssessment,
      getTemplate: this.getTemplate,
      getAssessmentSections: this.getAssessmentSections,
      getConclusionsSections: this.getConclusionsSections,
      getCriterion: this.getCriterion,
      getCriterions: this.getCriterions,
      getResearchEvidences: this.getResearchEvidences,
      getResearchEvidence: this.getResearchEvidence,
      getAdditionalConsiderations: this.getAdditionalConsiderations,
      getAdditionalConsideration: this.getAdditionalConsideration,
      isTemplateUndefined: this.isTemplateUndefined,
      getActiveContentEditable: this.getActiveContentEditable,
      getAttachments: this.getAttachments,
      getSelectedOptionText: this.getSelectedOptionText,
      getHeaderSections: this.getHeaderSections,
      getCriterionDescription: this.getCriterionDescription,
      isFetching: this.isFetching
    });
    this.bindActions(EtdActions);
    this.registerAsync(PouchSource(EtdActions));
  }

  EtdStore.prototype.onSave = function() {
    return this.getInstance().save(this.state.getIn(['_meta', 'dbId']), this.state.get('template').toJS());
  };

  EtdStore.prototype.onFetch = function(params) {
    var dbId, docId;
    dbId = params.dbId, docId = params.docId;
    this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'dbId'], dbId);
      return state.setIn(['_meta', 'fetching'], true);
    }));
    return this.getInstance().fetch(dbId, docId, {
      attachments: true
    });
  };

  EtdStore.prototype.onPouchFetchSuccess = function(data) {
    return this.setState(this.state.set('template', Immutable.fromJS(data)).setIn(['_meta', 'templateUndefined'], false).setIn(['_meta', 'fetching'], false));
  };

  EtdStore.prototype.onPouchFetchFailure = function(e) {
    return this.setState(this.state.setIn(['_meta', 'fetching'], false));
  };

  EtdStore.prototype.getTemplate = function() {
    return this.state.get('template');
  };

  EtdStore.prototype.isEditable = function() {
    return true;
  };

  EtdStore.prototype.getPresentationData = function() {
    return this.state.getIn(['template', 'templateData', 'presentationData', 'sections']);
  };

  EtdStore.prototype.getQuestion = function() {
    return getOrderedSections(this.state.getIn(['template', 'templateData', 'question']));
  };

  EtdStore.prototype.getPresentationSection = function(section) {
    return getOrderedSections(this.state.getIn(['template', 'templateData', "presentations", 'sections', section]));
  };

  EtdStore.prototype.getBackgroundDetailSections = function() {
    return getOrderedSections(this.state.getIn(['template', 'templateData', 'presentations', 'sections', 'policymakers', 'sections', 'background', 'details']));
  };

  EtdStore.prototype.getRecommendation = function() {
    var conclusions, content, contentSectionId, getSectionIdFromTemplateDef, optionSectionId, recommendation, recommendationContentSections, recommendationOptionSections, selectedOption, _ref, _ref1;
    conclusions = this.state.getIn(['template', 'templateDef', 'conclusions']);
    recommendationOptionSections = ['recommendationTypeIntervention', 'recommendationTypeOption', 'decisionTypeCoverage', 'decisionTypeImplementation'];
    recommendationContentSections = ['decision', 'recommendation'];
    getSectionIdFromTemplateDef = function(sections) {
      var _ref;
      return conclusions != null ? (_ref = conclusions.find(function(s) {
        var _ref1;
        return _ref1 = s.get('id'), __indexOf.call(sections, _ref1) >= 0;
      })) != null ? _ref.get('id') : void 0 : void 0;
    };
    optionSectionId = getSectionIdFromTemplateDef(recommendationOptionSections);
    contentSectionId = getSectionIdFromTemplateDef(recommendationContentSections);
    content = (_ref = this.state.getIn(['template', 'templateData', 'conclusions', 'sections', contentSectionId])) != null ? _ref.get('content') : void 0;
    selectedOption = (_ref1 = this.state.getIn(['template', 'templateData', 'conclusions', 'sections', optionSectionId])) != null ? _ref1.get('selectedOption') : void 0;
    return recommendation = {
      content: content,
      selectedOption: selectedOption
    };
  };

  EtdStore.prototype.getAssessment = function() {
    return this.state.getIn(['template', 'templateData', 'assessment']);
  };

  EtdStore.prototype.getAssessmentSections = function() {
    return getOrderedSections(this.state.getIn(['template', 'templateData', 'assessment']));
  };

  EtdStore.prototype.getConclusionsSections = function() {
    return getOrderedSections(this.state.getIn(['template', 'templateData', 'conclusions']));
  };

  EtdStore.prototype.getCriterion = function(criterionId) {
    return this.state.getIn(['template', 'templateData', 'assessment', 'criteria', criterionId]);
  };

  EtdStore.prototype.getCriterions = function() {
    return this.state.getIn(['template', 'templateData', 'assessment', 'criteria']);
  };

  EtdStore.prototype.getResearchEvidences = function() {
    return this.state.getIn(['template', 'templateData', 'assessment', 'researchEvidences']);
  };

  EtdStore.prototype.getResearchEvidence = function(researchEvidenceId) {
    return this.state.getIn(['template', 'templateData', 'assessment', 'researchEvidences', researchEvidenceId]);
  };

  EtdStore.prototype.getAdditionalConsiderations = function() {
    return this.state.getIn(['template', 'templateData', 'assessment', 'additionalConsiderations']);
  };

  EtdStore.prototype.getAdditionalConsideration = function(additionalConsiderationId) {
    return this.state.getIn(['template', 'templateData', 'assessment', 'additionalConsiderations', additionalConsiderationId]);
  };

  EtdStore.prototype.getAttachments = function(sectionId) {
    var attachments;
    if (sectionId == null) {
      sectionId = '';
    }
    attachments = this.state.getIn(['template', '_attachments']);
    if (!attachments) {
      return Immutable.Map();
    }
    if (sectionId) {
      return attachments.filter(function(attachment, attachmentName) {
        return attachmentName.match("" + sectionId + "/");
      });
    } else {
      return attachments;
    }
  };

  EtdStore.prototype.getHeaderSections = function() {
    return getOrderedSections(this.state.getIn(['template', 'templateData', 'question']));
  };

  EtdStore.prototype.getCriterionDescription = function(sectionId) {
    var sectionsCriterion;
    sectionsCriterion = this.state.getIn(blockDataPath('assessment').concat(['criteria', sectionId]));
    return sectionsCriterion != null ? sectionsCriterion.get('description') : void 0;
  };

  EtdStore.prototype.getSelectedOptionText = function(sectionId) {
    var option, sectionsCriterion, selectedOption;
    sectionsCriterion = this.state.getIn(blockDataPath('assessment').concat(['criteria', sectionId]));
    if (!sectionsCriterion) {
      return;
    }
    selectedOption = sectionsCriterion.get('selectedOption');
    option = sectionsCriterion.get('options').concat(sectionsCriterion.get('additionalOptions')).find(function(option) {
      return option.get('value') === selectedOption;
    });
    if (!option) {
      return;
    }
    return option.get('text');
  };

  EtdStore.prototype.onUpdateBackground = function(data) {
    var sectionId, sectionPart, updatePath, _ref;
    _ref = data.attr.split('-'), sectionId = _ref[0], sectionPart = _ref[1];
    updatePath = (function() {
      switch (sectionId) {
        case 'background':
          return ['template', 'templateData', 'question', 'sections', sectionPart, 'content'];
        case 'details':
          return ['template', 'templateData', 'presentations', 'sections', 'policymakers', 'sections', 'background', 'details', 'sections', sectionPart, 'content'];
      }
    })();
    return this.setState(this.state.setIn(updatePath, data.value));
  };

  EtdStore.prototype.onSaveBackground = function() {
    return this._save();
  };

  EtdStore.prototype.onChangeAndSaveCriteriaDecision = function(data) {
    var updatePath;
    updatePath = ['template', 'templateData', 'assessment', 'criteria', data.sectionId, 'decision', 'selectedOption'];
    this.setState(this.state.setIn(updatePath, data.value));
    return this._save();
  };

  EtdStore.prototype.onChangeAndSaveDecision = function(value) {
    var updatePath;
    updatePath = ['template', 'templateData', 'presentations', 'sections', 'policymakers', 'sections', 'decision', 'selectedOption'];
    this.setState(this.state.setIn(updatePath, value));
    return this._save();
  };

  EtdStore.prototype._save = function() {
    var dbId;
    dbId = this.state.getIn(['_meta', 'dbId']);
    return this.getInstance().save(dbId, this.getTemplate().toJS());
  };

  EtdStore.prototype.onChangeJudgement = function(data) {
    return this.setState(this.state.setIn(['template', 'templateData', 'assessment', 'criteria', data.sectionId, 'selectedOption'], data.value));
  };

  EtdStore.prototype.onChangeAndSaveJudgement = function(data) {
    var dbId;
    this.setState(this.state.setIn(['template', 'templateData', 'assessment', 'criteria', data.sectionId, 'selectedOption'], data.value));
    dbId = this.state.getIn(['_meta', 'dbId']);
    return this.getInstance().save(dbId, this.state.get('template').toJS());
  };

  EtdStore.prototype.onUpdateAndSaveConclusionsOption = function(data) {
    var dbId;
    this.setState(this.state.setIn(['template', 'templateData', 'conclusions', 'sections', data.section, 'selectedOption'], data.value));
    dbId = this.state.getIn(['_meta', 'dbId']);
    return this.getInstance().save(dbId, this.state.get('template').toJS());
  };

  EtdStore.prototype.onUpdatePanelDiscussion = function(data) {
    return this.setState(this.state.setIn(detailedJudgementsDataPath(data.sectionId).concat(['panelDiscussion']), data.value));
  };

  EtdStore.prototype.onUpdateDetailedGeneralQuestion = function(data) {
    var path;
    path = detailedJudgementsDataPath(data.sectionId).concat(['questions', data.questionIdx, 'selectedOption']);
    return this.setState(this.state.setIn(path, data.value));
  };

  EtdStore.prototype.onUpdateDetailedOutcomesQuestion = function(data) {
    var path;
    path = detailedJudgementsDataPath(data.sectionId).concat(['questions', data.questionIdx, 'selectedOptions', data.outcomeId]);
    return this.setState(this.state.setIn(path, data.value));
  };

  EtdStore.prototype.onChangeDetailedResourceName = function(data) {
    var path;
    path = detailedJudgementsDataPath(data.sectionId).concat(['questions', data.questionIdx, 'resources', data.resourceIdx, 'name']);
    return this.setState(this.state.setIn(path, data.value));
  };

  EtdStore.prototype.onUpdateDetailedResourcesQuestion = function(data) {
    var path;
    path = detailedJudgementsDataPath(data.sectionId).concat(['questions', data.questionIdx, 'resources', data.resourceIdx, 'selectedOption']);
    return this.setState(this.state.setIn(path, data.value));
  };

  EtdStore.prototype.onAddDetailedResource = function(data) {
    var path, resources, _ref;
    path = detailedJudgementsDataPath(data.sectionId).concat(['questions', data.questionIdx, 'resources']);
    resources = (_ref = this.state.getIn(path)) != null ? _ref : Immutable.List();
    return this.setState(this.state.setIn(path, resources.push(Immutable.fromJS({
      name: '',
      selectedOption: ''
    }))));
  };

  EtdStore.prototype.onRemoveDetailedResource = function(data) {
    var path, resources;
    path = detailedJudgementsDataPath(data.sectionId).concat(['questions', data.questionIdx, 'resources']);
    resources = this.state.getIn(path).filter(function(resource, idx) {
      return idx !== data.resourceIdx;
    });
    return this.setState(this.state.setIn(path, resources));
  };

  EtdStore.prototype.onUpdateHeaderFieldContent = function(inputDetails) {
    var content, sectionId;
    content = inputDetails.content, sectionId = inputDetails.sectionId;
    return this.setState(this.state.setIn(['template', 'templateData', 'question', 'sections', sectionId, 'content'], content));
  };

  EtdStore.prototype.onChangeFavours = function(data) {
    return this.setState(this.state.setIn(['template', 'templateData', 'assessment', 'sections', data.section, 'favours'], data.value));
  };

  EtdStore.prototype.onClearFavours = function() {
    return this.setState(this.state.updateIn(['template', 'templateData', 'assessment', 'sections'], function(sections) {
      return sections.map(function(sec) {
        return sec.setIn(['favours'], '');
      });
    }));
  };

  EtdStore.prototype.onChangeImportance = function(data) {
    return this.setState(this.state.setIn(['template', 'templateData', 'assessment', 'sections', data.section, 'importance'], data.value));
  };

  EtdStore.prototype.onClearImportance = function() {
    return this.setState(this.state.updateIn(['template', 'templateData', 'assessment', 'sections'], function(sections) {
      return sections.map(function(sec) {
        return sec.setIn(['importance'], '');
      });
    }));
  };

  EtdStore.prototype.onSaveHeaderData = function() {
    var dbId;
    dbId = this.state.getIn(['_meta', 'dbId']);
    return this.getInstance().save(dbId, this.state.get('template').toJS());
  };

  EtdStore.prototype.onEnableContentEditable = function(contentEditableId) {
    return this.setState(this.state.setIn(['_meta', 'activeContentEditableId'], contentEditableId));
  };

  EtdStore.prototype.onDisableContentEditable = function() {
    return this.setState(this.state.setIn(['_meta', 'activeContentEditableId'], ''));
  };

  EtdStore.prototype.onUpdateContentEditableContent = function(data) {
    var content, contentEditableId, dataURIPattern, detailed, sectionId, sectionPart, updatePath, _ref;
    contentEditableId = data.contentEditableId, content = data.content;
    _ref = contentEditableId.split('#'), sectionId = _ref[0], sectionPart = _ref[1], detailed = _ref[2];
    updatePath = (function() {
      switch (sectionPart) {
        case 'conclusions':
          if (!detailed) {
            return ['template', 'templateData', 'conclusions', 'sections', sectionId, 'content'];
          } else {
            return ['template', 'templateData', 'conclusions', 'sections', sectionId, detailed, 'content'];
          }
          break;
        case 'question':
          return ['template', 'templateData', 'question', 'sections', sectionId, 'content'];
        case 'patients':
          return ['template', 'templateData', 'presentations', 'sections', 'patients', 'sections', sectionId, 'content'];
        case 'policymakers':
          return ['template', 'templateData', 'presentations', 'sections', 'policymakers', 'sections', sectionId, 'content'];
        case 'criteria':
          return ['template', 'templateData', 'assessment', 'criteria', sectionId, 'decision', 'comments'];
        default:
          return ['template', 'templateData', 'assessment', sectionPart, sectionId, 'content'];
      }
    })();
    dataURIPattern = /src="data:\S+"/g;
    content = content.replace(dataURIPattern, 'src=""');
    return this.setState(this.state.setIn(updatePath, content));
  };

  EtdStore.prototype.onSaveContentEditable = function() {
    var dbId;
    if (this.state.getIn(['template', '_attachments'])) {
      this._updateAttachments();
    }
    dbId = this.state.getIn(['_meta', 'dbId']);
    return this.getInstance().save(dbId, this.getTemplate().toJS());
  };

  EtdStore.prototype.onInsertContent = function(insertData) {
    var cellId, file, selectedOption;
    selectedOption = insertData.selectedOption, file = insertData.file;
    switch (selectedOption) {
      case 'sof_short':
      case 'test_accuracy':
      case 'evidence_short':
      case 'relative_importance':
        return this._insertTable(selectedOption);
      case 'sof_v1':
      case 'sof_v2':
      case 'sof_v3':
      case 'l1_sof':
      case 'l2_sof':
        return this._insertSof(selectedOption);
      case 'image':
        cellId = this.state.getIn(['_meta', 'activeContentEditableId']);
        return this._insertImage({
          file: file,
          cellId: cellId
        });
    }
  };

  EtdStore.prototype._insertTable = function(tableName) {
    var tableData, tableHTML;
    tableData = QuestionsStore.getDataForTable(tableName);
    tableHTML = embeddedContentProvider.getResearchEvidenceTable(tableName, tableData.toJS());
    return this.insertContentToContentEditable(tableHTML);
  };

  EtdStore.prototype._insertSof = function(sofType) {
    var questionModel, tableHTML;
    questionModel = QuestionsStore.getQuestionModel();
    tableHTML = embeddedContentProvider.getSofTable(sofType, questionModel);
    return this.insertContentToContentEditable(tableHTML);
  };

  EtdStore.prototype._insertImage = function(params) {
    var attachment, attachmentName, cellId, dbId, file;
    cellId = params.cellId, file = params.file;
    if (!(cellId && file)) {
      throw new Error;
    }
    attachmentName = "" + cellId + "/" + (file.name.replace(/\s/g, '_'));
    this.insertContentToContentEditable(embeddedContentProvider.getImageString({
      'db-image': attachmentName,
      style: "width: 100%;",
      section: cellId.split('#')[0]
    }));
    dbId = this.state.getIn(['_meta', 'dbId']);
    attachment = {
      content_type: file.type,
      data: file
    };
    return this.getInstance().save(dbId, this.state.setIn(['template', '_attachments', attachmentName], attachment).get('template').toJS());
  };

  EtdStore.prototype.insertContentToContentEditable = function(content) {
    var contentEditableId, currentContent, newContent, sectionId;
    contentEditableId = this.getActiveContentEditable();
    sectionId = contentEditableId.split('#')[0];
    currentContent = this.getResearchEvidence(sectionId).get('content');
    newContent = currentContent + content;
    return this.onUpdateContentEditableContent({
      contentEditableId: contentEditableId,
      content: newContent
    });
  };

  EtdStore.prototype._updateAttachments = function() {
    var activeAttachments, attachments;
    attachments = this.state.getIn(['template', '_attachments']);
    if (!attachments) {
      return;
    }
    activeAttachments = attachments.filter((function(_this) {
      return function(attachment, attachmentName) {
        var fieldId, fieldType, _ref;
        _ref = attachmentName.split('/')[0].split('#'), fieldId = _ref[0], fieldType = _ref[1];
        return _this.state.getIn(['template', 'templateData', 'assessment', fieldType, fieldId, 'content']).match(attachmentName) != null;
      };
    })(this));
    if (activeAttachments.size !== this.state.getIn(['template', '_attachments']).size) {
      return this.setState(this.state.setIn(['template', '_attachments'], activeAttachments));
    }
  };

  EtdStore.prototype.getActiveContentEditable = function() {
    return this.state.getIn(['_meta', 'activeContentEditableId']);
  };

  EtdStore.prototype.isFetching = function() {
    return this.state.getIn(['_meta', 'fetching']);
  };

  EtdStore.prototype.onTemplateUndefined = function() {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'templateUndefined'], true);
      return state.setIn(['_meta', 'fetching'], false);
    }));
  };

  EtdStore.prototype.isTemplateUndefined = function() {
    return this.state.getIn(['_meta', 'templateUndefined']);
  };

  EtdStore.prototype.getPresentationType = function() {
    return this.state.getIn(['_meta', 'presentationType']);
  };

  EtdStore.prototype.onUpdatePresentationType = function(type) {
    this.setState(this.state.setIn(['_meta', 'presentationType'], type));
    return this._save();
  };

  return EtdStore;

})();

module.exports = alt.createStore(immutable(EtdStore), 'EtdStore');
