var AuthenticationService, DbHelper, Exceptions, Validation, W, nodefn,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

Exceptions = require('lib/exceptions');

Validation = require('base/validation');

DbHelper = require('base/lib/db_helper');

W = require('when');

nodefn = require('when/node/function');

module.exports = AuthenticationService = (function() {
  function AuthenticationService() {
    this.logout = __bind(this.logout, this);
    this.sessionUrl = DbHelper.getSessionUrl();
    this.registerUrl = DbHelper.getRegistrationUrl();
  }

  AuthenticationService.prototype.getLocalAccountInformation = function() {
    return gdt.storage.get('users').then((function(_this) {
      return function(value) {
        var outputIfTrue, user, users, usersWithDbs;
        users = JSON.parse(value != null ? value : '[]');
        usersWithDbs = (function() {
          var _i, _len, _results;
          _results = [];
          for (_i = 0, _len = users.length; _i < _len; _i++) {
            user = users[_i];
            outputIfTrue = function(user) {
              return function(v) {
                if (v) {
                  return user;
                }
              };
            };
            _results.push(this.udbService.isInitialized(user).then(outputIfTrue(user)));
          }
          return _results;
        }).call(_this);
        return W.map(usersWithDbs).then(function(array) {
          return _(array).compact();
        });
      };
    })(this));
  };

  AuthenticationService.prototype._validateAccountInfo = function(login, password, email, tosAccepted) {
    if (!(login != null ? login.trim() : void 0)) {
      return W.reject(new Exceptions.authentication.empty_login);
    }
    if (!(password != null ? password.trim() : void 0)) {
      return W.reject(new Exceptions.authentication.empty_password);
    }
    if (!Validation.isValidEmail(email)) {
      return W.reject(new Exceptions.authentication.invalid_email);
    }
    if (!Validation.isValidGdtLogin(login)) {
      return W.reject(new Exceptions.authentication.invalid_login);
    }
    if (!tosAccepted) {
      return W.reject(new Exceptions.authentication.tos_not_accepted);
    }
    return this.emailService.checkIfEmailUsed(email).then(function(used) {
      if (used) {
        throw new Exceptions.authentication.email_in_use;
      }
    });
  };

  AuthenticationService.prototype._constructUserDocument = function(login, password, email) {
    return JSON.stringify({
      _id: "org.couchdb.user:" + login,
      type: 'user',
      name: login,
      password: password,
      email: email,
      tosAcceptedVersion: this.tosService.getCurrentVersion(),
      roles: [],
      status: 'new'
    });
  };

  AuthenticationService.prototype._ensureLoginIsAuthenticatedLocally = function(login) {
    return gdt.storage.get('users').then(function(value) {
      var users;
      users = JSON.parse(value != null ? value : '[]');
      if (__indexOf.call(users, login) < 0) {
        users.push(login);
      }
      return gdt.storage.set('users', JSON.stringify(users));
    });
  };

  AuthenticationService.prototype.register = function(login, password, email, tosAccepted) {
    return this._validateAccountInfo(login, password, email, tosAccepted).then(this.logout).then((function(_this) {
      return function() {
        return W($.ajax(_this.registerUrl, {
          type: 'POST',
          contentType: 'application/json',
          data: _this._constructUserDocument(login, password, email)
        }), function(response) {
          return _this._ensureLoginIsAuthenticatedLocally(login);
        }, function(xhr) {
          if (xhr.status === 409) {
            throw new Exceptions.authentication.login_in_use;
          } else {
            throw new Exceptions.no_connection;
          }
        });
      };
    })(this));
  };

  AuthenticationService.prototype.getLoginStatus = function() {
    return gdt.storage.get('active_user').then((function(_this) {
      return function(login) {
        if (!login) {
          return {
            status: 'no'
          };
        }
        return W($.ajax(_this.sessionUrl, {
          dataType: 'json',
          xhrFields: {
            withCredentials: true
          }
        }), function(response) {
          var _ref;
          if (response.ok && ((_ref = response.userCtx) != null ? _ref.name : void 0)) {
            return {
              status: 'central',
              login: response.userCtx.name
            };
          } else {
            return {
              status: 'local',
              login: login
            };
          }
        }, function(error) {
          return {
            status: 'local',
            login: login
          };
        });
      };
    })(this));
  };

  AuthenticationService.prototype._centralLoginSuccessful = function(username) {
    return (function(_this) {
      return function(isInitialized) {
        return W.promise(function(resolve, reject, notify) {
          var _ref;
          return (_ref = _this._ensureLoginIsAuthenticatedLocally(username).then(function() {
            if (!isInitialized) {
              notify({
                action: 'initialization'
              });
            }
            return _this.udbService.initialize(username);
          }).then(function() {
            gdt.storage.set('active_user', username);
            return {
              login: username,
              status: 'central'
            };
          }, function(error) {
            if (error) {
              if (typeof Raven !== "undefined" && Raven !== null) {
                Raven.captureException(error);
              }
            }
            throw new Exceptions.authentication.local_no_udb;
          })).then.apply(_ref, arguments);
        });
      };
    })(this);
  };

  AuthenticationService.prototype._localLogin = function(username) {
    return gdt.storage.get('users').then((function(_this) {
      return function(value) {
        if (__indexOf.call(JSON.parse(value != null ? value : '[]'), username) < 0) {
          throw new Exceptions.authentication.local_login;
        }
        return _this.udbService.isInitialized(username).then(function(isInitialized) {
          if (!isInitialized) {
            throw new Exceptions.authentication.local_no_udb;
          }
          gdt.storage.set('active_user', username);
          return {
            login: username,
            status: 'local'
          };
        });
      };
    })(this));
  };

  AuthenticationService.prototype._isLoginInactive = function(login) {
    var url;
    url = "" + (DbHelper.getBackendUrl()) + "/accounts/is-inactive/" + login;
    return W($.ajax(url, {
      type: 'GET'
    }))["catch"](function(err) {
      if (err.status === 0 || err.status >= 500) {
        return false;
      }
      throw err;
    });
  };

  AuthenticationService.prototype._getLocalLoginForEmail = function(email, loginRequestError) {
    if (loginRequestError == null) {
      loginRequestError = false;
    }
    return this.udbService.getLocalUserDocByEmail(email).then(function(userDoc) {
      var errKey;
      if (userDoc) {
        return userDoc.name;
      } else {
        errKey = loginRequestError ? 'unable_to_get_login_for_email' : 'no_login_for_email_local';
        throw new Exceptions.authentication[errKey];
      }
    });
  };

  AuthenticationService.prototype._getLoginName = function(loginOrEmail) {
    var url;
    if (__indexOf.call(loginOrEmail, '@') < 0) {
      return W.resolve(loginOrEmail);
    }
    url = encodeURI("" + (DbHelper.getBackendUrl()) + "/accounts/login-for-email/" + loginOrEmail);
    return W($.ajax(url, {
      type: 'GET'
    })).then(function(res) {
      return res.login;
    })["catch"]((function(_this) {
      return function(err) {
        if (err.status === 0) {
          return _this._getLocalLoginForEmail(loginOrEmail);
        } else if (err.status === 404) {
          throw new Exceptions.authentication.server;
        } else {
          return _this._getLocalLoginForEmail(loginOrEmail, true);
        }
      };
    })(this));
  };

  AuthenticationService.prototype.login = function(loginOrEmail, password, loginOnUnauthorized) {
    if (loginOnUnauthorized == null) {
      loginOnUnauthorized = false;
    }
    return this._getLoginName(loginOrEmail).then((function(_this) {
      return function(login) {
        return _this._isLoginInactive(login).then(function(inactive) {
          if (inactive) {
            throw new Exceptions.authentication.user_inactive;
          }
          return W($.ajax(_this.sessionUrl, {
            type: 'POST',
            dataType: 'json',
            xhrFields: {
              withCredentials: true
            },
            data: {
              name: login,
              password: password
            }
          }), function(response) {
            var username;
            username = response.name;
            return _this.udbService.isInitialized(username).then(_this._centralLoginSuccessful(username));
          }, function(xhr) {
            if (xhr.status === 401) {
              if (loginOnUnauthorized) {
                throw new Exceptions.authentication.invalid_password;
              } else {
                throw new Exceptions.authentication.server;
              }
            }
            return _this._localLogin(login);
          });
        });
      };
    })(this));
  };

  AuthenticationService.prototype.logout = function() {
    var localLogout;
    localLogout = function() {
      return gdt.storage.remove('active_user');
    };
    return W($.ajax(this.sessionUrl, {
      type: 'DELETE',
      dataType: 'json',
      xhrFields: {
        withCredentials: true
      }
    }), localLogout, localLogout);
  };

  AuthenticationService.prototype._resetPassword = function(data) {
    var url;
    url = "" + (DbHelper.getBackendUrl()) + "/accounts/request-password-reset";
    return W($.ajax(url, {
      type: 'POST',
      data: data
    })).otherwise(function(xhr) {
      var response;
      response = $.parseJSON(xhr.responseText);
      if (xhr.status === 400 && (response != null ? response.error : void 0) === 'not_found') {
        throw new Exceptions.authentication.user_not_found;
      } else {
        throw new Exceptions.server_error;
      }
    });
  };

  AuthenticationService.prototype.resetPasswordForLogin = function(login) {
    return this._resetPassword({
      login: login
    });
  };

  AuthenticationService.prototype.resetPasswordForEmail = function(email) {
    return this._resetPassword({
      email: email
    });
  };

  AuthenticationService.prototype.emailToLogin = function(email, attemptCount) {
    var login;
    if (attemptCount == null) {
      attemptCount = 0;
    }
    login = email.toLowerCase().replace(/[^a-z0-9_-]+/g, '_');
    return "l_" + login + "_" + attemptCount;
  };

  return AuthenticationService;

})();
