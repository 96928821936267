EtdStore = require 'stores/etd_store'
EtdActions = require 'actions/etd_actions'
epPresentations = window.epPresentations
PureRenderMixin = React.addons.PureRenderMixin
CreateStoreMixin = require '../mixins/create_store'
Select = require 'components/common/select'
Translation = require 'components/mixins/translation'
mediator = require 'mediator'
Router = require 'router'
FetchingSpinner = require 'components/mixins/fetching_spinner'

Presentations = React.createClass

  displayName: "Presentations"

  mixins: [
    PureRenderMixin
    CreateStoreMixin(EtdStore)
    Translation('es:recommendations.presentations')
    FetchingSpinner
  ]

  presentationOptions: [
    {value: "clinicians", text: 'Clinicians'}
    {value: "policymakers", text: 'Policymakers'}
    {value: "patients", text: 'Patients'}
  ]

  getStateFromStores: ->
    presentationType: EtdStore.getPresentationType()
    currentEditable: EtdStore.getActiveContentEditable()
    isFetching: EtdStore.isFetching()
    isEditable: EtdStore.isEditable()
    policymakers: EtdStore.getPresentationSection('policymakers')
    clinicians: EtdStore.getPresentationSection('clinicians')
    patients: EtdStore.getPresentationSection('patients')
    assessment: EtdStore.getAssessment()
    question: EtdStore.getQuestion()
    conclusions: EtdStore.getConclusionsSections()
    assessmentSections: EtdStore.getAssessmentSections()
    backgroundDetailSections: EtdStore.getBackgroundDetailSections()
    templateUndefined: EtdStore.isTemplateUndefined()
    recommendation: EtdStore.getRecommendation()
    attachments: EtdStore.getAttachments()

  onChangePresentationType: (value) ->
    @setState presentationType: value
    EtdActions.updatePresentationType value

  routeToRecommendationsModule: (e) ->
    e.preventDefault()
    mediator.publish '!router:route',
      Router::routeForSubmodule('evidence-syntheses', 'recommendations')

  renderFetched: ->
    <div>
      <div className="presentation-header">
        <p>{@i18n 'description'}</p>
        <p>{@i18n 'subheader'}</p>
      </div>
      {if @state.templateUndefined
        <div>
          {@i18n 'fill_recommendation', {},
            link: <a href='#' onClick=@routeToRecommendationsModule>{@i18n 'recommendations'}</a>
          }
        </div>
      else
        <div>
          <p>
            <span className='presentation-type-select-label'>
              {@i18n 'select_presentation_type'}
            </span>
            <Select
              id="presentation-type"
              options={@presentationOptions}
              selected={@state.presentationType}
              onChange={@onChangePresentationType} />
          </p>
          <epPresentations
            attachments={@state.attachments}
            recommendation={@state.recommendation}
            currentEditable={@state.currentEditable}
            actions=EtdActions
            presentationType={@state.presentationType}
            isEditable={@state.isEditable}
            assessment={@state.assessment}
            question={@state.question}
            conclusions={@state.conclusions}
            assessmentSections={@state.assessmentSections}
            policymakers={@state.policymakers}
            clinicians={@state.clinicians}
            patients={@state.patients}
            backgroundDetailSections={@state.backgroundDetailSections}
            />
        </div>
        }
    </div>

module.exports = Presentations
