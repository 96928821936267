var AuthenticationView, Focusable, LoginView, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

AuthenticationView = require('views/authentication_view');

template = require('./templates/login');

mediator = require('mediator');

Focusable = require('base/lib/traits/focusable');

module.exports = LoginView = (function(_super) {
  __extends(LoginView, _super);

  function LoginView() {
    return LoginView.__super__.constructor.apply(this, arguments);
  }

  LoginView.prototype.template = template;

  LoginView.prototype.className = 'login-view';

  LoginView.prototype.container = 'body';

  LoginView.prototype.autoRender = true;

  LoginView.prototype.initialize = function() {
    LoginView.__super__.initialize.apply(this, arguments);
    if (this.options.loginOnUnauthorized) {
      this.enable(Focusable);
      mediator.setFocus(this);
      this.delegate('click', this.gainFocus);
    }
    this.delegate('submit', 'form', function() {
      this._logIn();
      return false;
    });
    this.delegate('click', 'button.registration-view-switch', this._registrationView);
    this.delegate('click', 'button.forgot-password', this._forgotPasswordView);
    this.delegate('click', 'button.user-switch', this._switchUser);
    return this._getLocalAccountInformation();
  };

  LoginView.prototype._getLocalAccountInformation = function() {
    return this.options.authenticationService.getLocalAccountInformation().then((function(_this) {
      return function(accounts) {
        _this.render();
        if (_this.options.loginOnUnauthorized) {
          _this.$('.info').html($.t('user.session_lost'));
          _this.$('.login').val(mediator.user.get('email'));
          return _this.$('.login').prop('disabled', true);
        } else if (accounts.length === 1) {
          return mediator.services.udb.getLocalUserEmail(accounts[0]).then(function(email) {
            return _this.$('.login').val(email);
          })["catch"](function() {
            return _this.$('.login').val('');
          });
        }
      };
    })(this), function(error) {
      return mediator.dialogs.fatalError(error);
    });
  };

  LoginView.prototype._switchUser = function() {
    return this.options.authenticationService.logout().then(function() {
      return mediator.publish('!reloadApplication');
    });
  };

  LoginView.prototype.afterRender = function() {
    LoginView.__super__.afterRender.apply(this, arguments);
    return this.$('.login').focus();
  };

  return LoginView;

})(AuthenticationView);
