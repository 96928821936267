var mediator;

mediator = require('mediator');

module.exports = function(route, projectRoute, submodule) {
  route('', 'welcome');
  route('projects', 'project');
  route('projects/', 'project');
  route('projects/:projectId', 'project#start');
  route('projects/:projectId/most-recent-change', 'project#mostRecentChange');
  route('projects/:projectId/my-most-recent-change', 'project#myMostRecentChange');
  projectRoute('administration');
  projectRoute('administration', '/:submodule', '#submodule');
  projectRoute('tasks');
  projectRoute('team');
  projectRoute('team', '/:submodule', '#submodule');
  projectRoute('scope', null, null, {
    submodules: [
      submodule('outcomes', {
        optional: '/:step'
      })
    ]
  });
  projectRoute('scope', '/:submodule', '#submodule');
  projectRoute('document_sections');
  projectRoute('prognosis');
  projectRoute('prognosis', '/:id', '#question');
  projectRoute('prognosis', '/:id/', '#question');
  projectRoute('prognosis', '/:id/:submodule', '#submodule');
  projectRoute('evidence_syntheses');
  projectRoute('evidence_syntheses', '/:id', '#question');
  projectRoute('evidence_syntheses', '/:id/', '#question');
  projectRoute('evidence_syntheses', '/:id/:submodule', '#submodule');
  projectRoute('dissemination');
  return projectRoute('dissemination', '/:submodule', '#submodule');
};
