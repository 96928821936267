var DbHelper, MembersActions, VotingActions, W, alt, mediator, votingHelpers;

alt = require('alt');

mediator = require('mediator');

MembersActions = require('actions/members_actions');

W = require('when/when');

DbHelper = require('base/lib/db_helper');

votingHelpers = require('stores/utils/rand_voting_helpers');

VotingActions = (function() {
  function VotingActions() {
    this.generateActions('fetchSuccess', 'fetchFailure', 'saveSuccess', 'saveFailure', 'sendMailMessageSuccess', 'sendMailMessageFailure', 'refreshVotingStatusesSuccess', 'refreshVotingStatusesFailure', 'fetchVotingResultsSuccess', 'fetchVotingResultsFailure');
  }

  VotingActions.prototype.updateMailMessage = function(messageType, message) {
    this.dispatch({
      messageType: messageType,
      message: message
    });
    return this.actions.save(mediator.project.id, alt.stores.VotingStore.getVoting().toJS(), 'voting');
  };

  VotingActions.prototype.sendMailMessage = function(messageType, message, members) {
    return W(messageType === 'invitationMailMessage' ? votingHelpers.createVotingDocs(members) : void 0).then((function(_this) {
      return function() {
        return W.all(members.map(function(member) {
          var msgWithLink;
          msgWithLink = votingHelpers.replaceLinkPlaceholder(message, member);
          mediator.user.getRequests().create(votingHelpers.prepareEmailRequestDoc(member, msgWithLink));
          return member;
        }).toArray()).then(function() {
          _this.actions.sendMailMessageSuccess(Immutable.fromJS(members));
          return _this.actions.refreshVotingStatuses(Immutable.fromJS(members));
        });
      };
    })(this))["catch"]((function(_this) {
      return function(e) {
        return _this.actions.sendMailMessageFailure(e);
      };
    })(this));
  };

  VotingActions.prototype.refreshVotingStatuses = function(members) {
    return votingHelpers.getVotingDocsStatuses(members).then((function(_this) {
      return function(votingStatuses) {
        return _this.actions.refreshVotingStatusesSuccess({
          votingStatuses: Immutable.fromJS(votingStatuses)
        });
      };
    })(this))["catch"]((function(_this) {
      return function(e) {
        return _this.actions.refreshVotingStatusesFailure(e);
      };
    })(this));
  };

  VotingActions.prototype.fetchVotingResults = function(members) {
    return votingHelpers.getVotingResults(members).then((function(_this) {
      return function(votingResults) {
        return _this.actions.fetchVotingResultsSuccess(votingResults);
      };
    })(this))["catch"]((function(_this) {
      return function(e) {
        return _this.actions.fetchVotingResultsFailure(e);
      };
    })(this));
  };

  VotingActions.prototype.fetch = function() {
    MembersActions.fetch();
    W(mediator.services.storePersistenceAdapter.fetch(mediator.project.id, 'rand-voting')["catch"]((function(_this) {
      return function(e) {
        if (e.status === 404) {
          return _this.actions.fetchSuccess({
            _id: 'rand-voting'
          });
        } else {
          return _this.actions.fetchFailure(e);
        }
      };
    })(this))).then((function(_this) {
      return function(doc) {
        return votingHelpers.getVotingDocsStatuses(Immutable.fromJS(mediator.colls.teamMembers.models)).then(function(votingStatuses) {
          return _this.actions.fetchSuccess({
            doc: doc,
            votingStatuses: votingStatuses
          });
        });
      };
    })(this));
    return this.dispatch();
  };

  VotingActions.prototype.save = function(dbId, doc, storeKey) {
    return mediator.services.storePersistenceAdapter.save(dbId, doc).then((function(_this) {
      return function(doc) {
        return _this.actions.saveSuccess({
          storeKey: storeKey,
          doc: doc
        });
      };
    })(this))["catch"]((function(_this) {
      return function(e) {
        return _this.actions.saveFailure(e);
      };
    })(this));
  };

  return VotingActions;

})();

module.exports = alt.createActions(VotingActions);
