$(function() {
  var Storage, debug, error, integration, mediator, test, _i, _len, _ref, _ref1, _ref2;
  Storage = require('lib/storage');
  window.gdt.storage = new Storage();
  require('base/lib/when_reporting');
  mediator = require('mediator');
  window.gdt.languages = [
    {
      value: 'en',
      text: 'English'
    }, {
      value: 'es',
      text: 'Español'
    }, {
      value: 'de',
      text: 'Deutsch'
    }, {
      value: 'it',
      text: 'Italiano'
    }
  ];
  debug = window.gdt.debugMode;
  integration = window.gdt.integrationMode;
  if (!debug) {
    window.gdt.storage.get('debugMode').then(function(value) {
      return debug = value;
    });
  }
  test = typeof window !== "undefined" && window !== null ? (_ref = window.gdt) != null ? _ref.testMode : void 0 : void 0;
  if (!$.browser.webkit) {
    $('html').addClass('non-webkit');
  }
  window.gdt.versionTimestamp = Number('{!timestamp!}');
  window.gdt.tosVersion = Number('{!tosVersion!}');
  window.gdt.versionUpdateLink = "http://evidenceprime.com/update/" + window.gdt.versionTimestamp;
  window.document.domain = window.location.hostname;
  window.gdt.popupFinished = function(type, data) {
    return mediator.publish("popupFinished." + type, data);
  };
  window.INITIAL_POUCHDB_SIZE = 20;
  window.Pouch = window.PouchDB;
  if (!(debug || test)) {
    Raven.config('http://6c38d92aa847495880666d2b46bd7d32@sentry.evidenceprime.com/2', {
      dataCallback: function(data) {
        _(data.modules != null ? data.modules : data.modules = {}).extend({
          '{!name!}': '{!version!}'
        });
        _(data.extra != null ? data.extra : data.extra = {}).extend({
          build_time: moment(Number('{!timestamp!}')).format(),
          location_hash: window.location.hash,
          actions_list: mediator.actionsQueue.asArray().reverse()
        });
        return data;
      }
    }).install();
    _ref2 = typeof window !== "undefined" && window !== null ? (_ref1 = window.gdt) != null ? _ref1.ravenQueue : void 0 : void 0;
    for (_i = 0, _len = _ref2.length; _i < _len; _i++) {
      error = _ref2[_i];
      Raven.send(error);
    }
  }
  return window.gdt.storage.get('gdtLanguage').then(function(lng) {
    var nl;
    if (!lng) {
      nl = navigator.language.split('-')[0];
      lng = _.findWhere(window.gdt.languages, {
        value: nl
      }) ? nl : 'en';
      window.gdt.storage.set('gdtLanguage', lng);
    }
    return $.i18n.init({
      debug: debug,
      useLocalStorage: false,
      lng: lng,
      fallbackLng: 'en',
      ns: {
        namespaces: ['translation', 'errors', 'es', 'projects', 'team', 'tasks', 'scope', 'docsec', 'dissemination', 'prognosis', 'administration'],
        defaultNs: 'translation'
      }
    }, function() {
      var Application, app;
      $('.non-webkit.warning').text($.t('non_webkit_warning'));
      $('.old-ie.warning').text($.t('ie_warning'));
      $('.loading-text').html($.t('application_loading'));
      $.ajaxSetup({
        timeout: 5 * 1000
      });
      require('lib/localized_dates');
      Application = require('application');
      app = new Application();
      return app.initialize(debug, integration);
    });
  });
});
