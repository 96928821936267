mediator = require 'mediator'

Tooltip = React.createClass
  _elWithTooltip: ->
    $(@refs.tooltipEl.getDOMNode())

  componentDidMount: ->
    mediator.tooltips.info @_elWithTooltip()

  componentWillUnmount: ->
    mediator.tooltips.hideTooltip @_elWithTooltip()

  render: ->
    React.cloneElement React.Children.only(@props.children), ref: 'tooltipEl'

module.exports = Tooltip
