var QuestionsFinishView, View, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

template = require('views/templates/scope/questions_finish');

module.exports = QuestionsFinishView = (function(_super) {
  __extends(QuestionsFinishView, _super);

  function QuestionsFinishView() {
    return QuestionsFinishView.__super__.constructor.apply(this, arguments);
  }

  QuestionsFinishView.prototype.className = 'questions-finish';

  QuestionsFinishView.prototype.template = template;

  QuestionsFinishView.prototype.container = '#page-container';

  QuestionsFinishView.prototype.autoRender = true;

  return QuestionsFinishView;

})(View);
