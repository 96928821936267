var Chaplin, DbHelper, ReplicationService, W, mediator, nodefn,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

DbHelper = require('base/lib/db_helper');

W = require('when');

Chaplin = require('chaplin');

nodefn = require('when/node/function');

mediator = require('mediator');

module.exports = ReplicationService = (function(_super) {
  __extends(ReplicationService, _super);

  function ReplicationService() {
    this._replicateForegroundProject = __bind(this._replicateForegroundProject, this);
    this._fgProjectReplicated = __bind(this._fgProjectReplicated, this);
    this._stopForegroundProjectReplication = __bind(this._stopForegroundProjectReplication, this);
    this._replicateCentralUdb = __bind(this._replicateCentralUdb, this);
    this._replicateLocalUdb = __bind(this._replicateLocalUdb, this);
    this._replicateBackgroundProjects = __bind(this._replicateBackgroundProjects, this);
    this._handleError = __bind(this._handleError, this);
    this.sendLocalChanges = __bind(this.sendLocalChanges, this);
    this.retry = __bind(this.retry, this);
    this.isConnected = __bind(this.isConnected, this);
    this.isOnline = __bind(this.isOnline, this);
    this.destroy = __bind(this.destroy, this);
    return ReplicationService.__super__.constructor.apply(this, arguments);
  }

  ReplicationService.prototype.namespace = 'replication';

  ReplicationService.prototype.initialState = 'idle';

  ReplicationService.prototype.timeouts = {};

  ReplicationService.prototype.window = null;

  ReplicationService.prototype.udbService = null;

  ReplicationService.prototype.initialize = function() {
    ReplicationService.__super__.initialize.apply(this, arguments);
    _(this).extend(Chaplin.EventBroker);
    this.subscribeEvent('sessionStarted', (function(_this) {
      return function() {
        return _this.handle('sessionStarted');
      };
    })(this));
    this.subscribeEvent('projectChanged', (function(_this) {
      return function() {
        return _this.handle('projectChanged');
      };
    })(this));
    this.subscribeEvent('login', (function(_this) {
      return function() {
        return _this.handle('login');
      };
    })(this));
    return this.on('transition', (function(_this) {
      return function(info) {
        _this.publishEvent("" + _this.namespace + "." + info.toState);
        return _this.publishEvent("" + _this.namespace + ".statusChanged");
      };
    })(this));
  };

  ReplicationService.prototype.destroy = function() {
    this.clearQueue();
    this._stopReplications();
    return this.unsubscribeAllEvents();
  };

  ReplicationService.prototype.isOnline = function() {
    var _ref;
    return (_ref = this.state) === 'connected' || _ref === 'unauthorized';
  };

  ReplicationService.prototype.isConnected = function() {
    return this.state === 'connected';
  };

  ReplicationService.prototype.retry = function() {
    return this.transition('connecting');
  };

  ReplicationService.prototype._handleAccessDeniedProjects = function(dbsWithAccessDenied) {
    if (dbsWithAccessDenied.length) {
      return this.authentication.getLoginStatus().then((function(_this) {
        return function(loginStatus) {
          if (loginStatus.status === 'central') {
            return mediator.user.markAsAccessRevoked(dbsWithAccessDenied);
          } else {
            return _this.transition('unauthorized');
          }
        };
      })(this));
    }
  };

  ReplicationService.prototype._handleDeletedProjects = function(deletedDbs) {
    if (deletedDbs.length) {
      return mediator.user.markProjectsAsDeleted(deletedDbs);
    }
  };

  ReplicationService.prototype._handleProjectReplicationError = function(e, db) {
    if (e.status === 401) {
      return {
        accessDenied: db
      };
    } else if (e.status === 404) {
      return {
        projectDeleted: db
      };
    } else {
      throw e;
    }
  };

  ReplicationService.prototype._handleConnectionTimeout = function() {
    var timeoutFn;
    timeoutFn = (function(_this) {
      return function() {
        var now;
        now = moment(_this.clock.getTimestamp());
        if (now.diff(_this.connectionStart) >= _this.connectionTimeout && _this.state === 'connecting') {
          return _this.transition('connectionTimeout');
        }
      };
    })(this);
    return _.delay(timeoutFn, this.connectionTimeout);
  };

  ReplicationService.prototype._checkServerAvailability = function() {
    var udbName;
    udbName = this.udbService.getUdbName(mediator.user.get('name'));
    return W($.ajax(DbHelper.centralDbUrl(udbName), {
      dataType: 'json',
      xhrFields: {
        withCredentials: true
      }
    }));
  };

  ReplicationService.prototype.sendLocalChanges = function(projects) {
    var notReplicatedProjects;
    notReplicatedProjects = _(projects).difference(mediator.user.getReplicatedProjects());
    if (notReplicatedProjects.length) {
      if (this.state === 'connected') {
        this.timeouts.localChanges = setTimeout(((function(_this) {
          return function() {
            return _this.sendLocalChanges(projects);
          };
        })(this)), this.retryTimeout);
      }
      return W.resolve();
    } else {
      return W.map(projects, (function(_this) {
        return function(db) {
          return nodefn.call(Pouch.replicate.bind(Pouch), db, _this._centralReplica(db)).otherwise(function(e) {
            return _this._handleProjectReplicationError(e, db);
          });
        };
      })(this)).then((function(_this) {
        return function(infos) {
          var dbsWithAccessDenied, deletedDbs;
          dbsWithAccessDenied = _(infos).chain().pluck('accessDenied').compact().value();
          _this._handleAccessDeniedProjects(dbsWithAccessDenied);
          deletedDbs = _(infos).chain().pluck('projectDeleted').compact().value();
          _this._handleDeletedProjects(deletedDbs);
          if (infos.length - dbsWithAccessDenied.length - deletedDbs.length) {
            return _this.publishEvent('replication.backedUp', {
              timestamp: _.max(_(infos).pluck('end_time'))
            });
          }
        };
      })(this));
    }
  };

  ReplicationService.prototype.states = {
    idle: {
      sessionStarted: 'starting',
      '*': function() {
        return this.deferUntilTransition();
      }
    },
    starting: {
      _onEnter: function() {
        this.window.on('online', (function(_this) {
          return function() {
            return _this.handle('windowOnline');
          };
        })(this));
        this.window.on('offline', (function(_this) {
          return function() {
            return _this.handle('windowOffline');
          };
        })(this));
        return this.transition('connecting');
      },
      '*': function() {
        return this.deferUntilTransition();
      }
    },
    connecting: {
      _onEnter: function() {
        this._handleConnectionTimeout(this.connectionStart = moment(this.clock.getTimestamp()));
        return this._checkServerAvailability().then((function(_this) {
          return function() {
            return _this.transition('connected');
          };
        })(this), this._handleError);
      },
      windowOffline: 'disconnected',
      '*': function() {
        return this.deferUntilTransition();
      }
    },
    connectionTimeout: {
      _onEnter: function() {
        return this.timeouts.retry = setTimeout(((function(_this) {
          return function() {
            return _this.transition('connecting');
          };
        })(this)), this.retryTimeout);
      },
      '*': function() {
        return this.deferUntilTransition();
      }
    },
    serverError: {
      _onEnter: function() {
        return this.timeouts.retry = setTimeout(((function(_this) {
          return function() {
            return _this.transition('connecting');
          };
        })(this)), this.retryTimeout);
      },
      '*': function() {
        return this.deferUntilTransition();
      }
    },
    connected: {
      _onEnter: function() {
        this._stopReplications();
        return W.join(this.sendLocalChanges(mediator.user.getReplicatedProjects()), this._replicateBackgroundProjects(), this._replicateLocalUdb(), this._replicateCentralUdb(true), this._replicateForegroundProject()).otherwise(this._handleError);
      },
      projectChanged: function() {
        return this._replicateForegroundProject();
      },
      windowOffline: 'disconnected'
    },
    unauthorized: {
      _onEnter: function() {
        return this._stopReplications();
      },
      login: 'connecting'
    },
    disconnected: {
      _onEnter: function() {
        this._stopReplications();
        return this.timeouts.retry = setTimeout(((function(_this) {
          return function() {
            return _this.transition('connecting');
          };
        })(this)), this.retryTimeout);
      },
      windowOnline: 'connecting',
      retry: 'connecting'
    }
  };

  ReplicationService.prototype._centralReplica = DbHelper.centralReplica;

  ReplicationService.prototype._stopReplications = function() {
    var key, timeout, _ref;
    _ref = this.timeouts;
    for (key in _ref) {
      timeout = _ref[key];
      clearTimeout(timeout);
    }
    return this._stopForegroundProjectReplication();
  };

  ReplicationService.prototype._handleError = function(error) {
    var _ref;
    if (error.status == null) {
      return mediator.dialogs.fatalError(error);
    } else if (error.status === 401) {
      return this.transition('unauthorized');
    } else if (error.status === 409) {
      return typeof Raven !== "undefined" && Raven !== null ? Raven.captureException(error, {
        extra: {
          info: 'Duplicate replication processes detected in the same browser',
          projectId: (_ref = mediator.project) != null ? _ref.id : void 0
        }
      }) : void 0;
    } else if (error.status < 500) {
      return this.transition('disconnected');
    } else {
      return this.transition('serverError');
    }
  };

  ReplicationService.prototype._replicateBackgroundProjects = function() {
    var backgroundProjects, _ref;
    backgroundProjects = _(mediator.user.getReplicatedProjects()).without((_ref = mediator.project) != null ? _ref.id : void 0);
    return W.map(backgroundProjects, (function(_this) {
      return function(db) {
        return nodefn.call(Pouch.replicate.bind(Pouch), _this._centralReplica(db), db).then(function(info) {
          return _(info).extend({
            name: db
          });
        }).otherwise(function(e) {
          return _this._handleProjectReplicationError(e, db);
        });
      };
    })(this)).then((function(_this) {
      return function(infos) {
        var changedProjects, docsChanged;
        changedProjects = _(infos).filter(function(info) {
          return info.docs_written > 0;
        });
        docsChanged = _(changedProjects).chain().pluck('docs_written').reduce((function(acc, x) {
          return acc + x;
        }), 0).value();
        if (docsChanged) {
          _this.publishEvent('replication.bgChanges', {
            timestamp: _.max(_(infos).pluck('end_time')),
            changes: docsChanged,
            projects: _(changedProjects).pluck('name')
          });
        }
        return _this.timeouts.bgProjects = setTimeout(_this._replicateBackgroundProjects, _this.bgSyncPeriod);
      };
    })(this), function(error) {
      var _ref1;
      if (typeof Raven !== "undefined" && Raven !== null) {
        Raven.captureException(error, {
          extra: {
            projectId: (_ref1 = mediator.project) != null ? _ref1.id : void 0,
            backgroundProjects: backgroundProjects
          }
        });
      }
      throw error;
    });
  };

  ReplicationService.prototype._replicateLocalUdb = function() {
    var udbName;
    udbName = this.udbService.getUdbName(mediator.user.get('name'));
    return nodefn.call(Pouch.replicate.bind(Pouch), udbName, this._centralReplica(udbName), {
      continuous: true
    });
  };

  ReplicationService.prototype._replicateCentralUdb = function(firstReplication) {
    var udbName;
    if (firstReplication == null) {
      firstReplication = false;
    }
    udbName = this.udbService.getUdbName(mediator.user.get('name'));
    return nodefn.call(Pouch.replicate.bind(Pouch), this._centralReplica(udbName), udbName).then((function(_this) {
      return function() {
        if (firstReplication) {
          mediator.publish('centralUdbFetched');
        }
        return _this.timeouts.centralUdb = setTimeout(_this._replicateCentralUdb, _this.udbSyncPeriod);
      };
    })(this), this._handleError);
  };

  ReplicationService.prototype._stopForegroundProjectReplication = function() {
    var _ref, _ref1;
    if ((_ref = this.localFgProjectReplication) != null) {
      _ref.cancel();
    }
    return (_ref1 = this.centralFgProjectReplication) != null ? _ref1.cancel() : void 0;
  };

  ReplicationService.prototype._fgProjectReplicated = function(change) {
    var _ref;
    return this.publishEvent('replication.backedUp', {
      timestamp: (_ref = change.end_time) != null ? _ref : this.clock.getTimestamp()
    });
  };

  ReplicationService.prototype._replicateForegroundProject = function() {
    var name;
    this._stopForegroundProjectReplication();
    if (!mediator.project) {
      return;
    }
    name = mediator.project.id;
    if (__indexOf.call(mediator.user.getReplicatedProjects(), name) < 0) {
      this.timeouts.fgProject = setTimeout(this._replicateForegroundProject, this.retryTimeout);
      return;
    }
    this.localFgProjectReplication = Pouch.replicate.bind(Pouch)(name, this._centralReplica(name), {
      continuous: true
    }).on('change', this._fgProjectReplicated);
    this.centralFgProjectReplication = Pouch.replicate.bind(Pouch)(this._centralReplica(name), name, {
      continuous: true
    });
    return W.resolve();
  };

  return ReplicationService;

})(machina.Fsm);
