var ModuleController, TasksController, TasksView, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModuleController = require('controllers/base/module_controller');

TasksView = require('views/tasks/tasks_view');

mediator = require('mediator');

module.exports = TasksController = (function(_super) {
  __extends(TasksController, _super);

  function TasksController() {
    return TasksController.__super__.constructor.apply(this, arguments);
  }

  TasksController.prototype.index = function() {
    return this.view = new TasksView({
      collection: mediator.events
    });
  };

  return TasksController;

})(ModuleController);
