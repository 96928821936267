var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "  "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/effect", depth0, "{\"colNo\": 2,\n    \"effect-test\": \"index\", \"class\": \"prevalence-1\", \"dataPropertySuffix\": \"-index1\"}", {"name":"cell","hash":{},"data":data})))
    + "\n  "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/effect", depth0, "{\"colNo\": 3,\n    \"effect-test\": \"comparison\", \"class\": \"prevalence-1\", \"dataPropertySuffix\": \"-comparison1\"}", {"name":"cell","hash":{},"data":data})))
    + "\n  "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/effect", depth0, "{\"colNo\": 4,\n    \"effect-test\": \"index\", \"class\": \"prevalence-2\", \"dataPropertySuffix\": \"-index2\"}", {"name":"cell","hash":{},"data":data})))
    + "\n  "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/effect", depth0, "{\"colNo\": 5,\n    \"effect-test\": \"comparison\", \"class\": \"prevalence-2\", \"dataPropertySuffix\": \"-comparison2\"}", {"name":"cell","hash":{},"data":data})))
    + "\n  "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/effect", depth0, "{\"colNo\": 6,\n    \"effect-test\": \"index\", \"class\": \"prevalence-3\", \"dataPropertySuffix\": \"-index3\"}", {"name":"cell","hash":{},"data":data})))
    + "\n  "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/effect", depth0, "{\"colNo\": 7,\n    \"effect-test\": \"comparison\", \"class\": \"prevalence-3\", \"dataPropertySuffix\": \"-comparison3\"}", {"name":"cell","hash":{},"data":data})))
    + "\n  "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/no_of_participants_studies", depth0, "{\"colNo\": 8, \"rowspan\": 2}", {"name":"cell","hash":{},"data":data})))
    + "\n  "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "quality", depth0, "{\"colNo\": 9, \"rowspan\": 2}", {"name":"cell","hash":{},"data":data})))
    + "\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0['short'] : depth0), {"name":"unless","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "comments", depth0, "{\"colNo\": 10}", {"name":"cell","hash":{},"data":data})))
    + "\n";
},"4":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "  "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/effect", depth0, "{\"colNo\": 2,\n    \"effect-test\": \"index\", \"class\": \"prevalence-1\", \"dataPropertySuffix\": \"-1\"}", {"name":"cell","hash":{},"data":data})))
    + "\n  "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/effect", depth0, "{\"colNo\": 3,\n    \"effect-test\": \"index\", \"class\": \"prevalence-2\", \"dataPropertySuffix\": \"-2\"}", {"name":"cell","hash":{},"data":data})))
    + "\n  "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/effect", depth0, "{\"colNo\": 4,\n    \"effect-test\": \"index\", \"class\": \"prevalence-3\", \"dataPropertySuffix\": \"-3\"}", {"name":"cell","hash":{},"data":data})))
    + "\n  "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/no_of_participants_studies", depth0, "{\"colNo\": 5, \"rowspan\": 2}", {"name":"cell","hash":{},"data":data})))
    + "\n  "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "quality", depth0, "{\"colNo\": 6, \"rowspan\": 2}", {"name":"cell","hash":{},"data":data})))
    + "\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0['short'] : depth0), {"name":"unless","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "comments", depth0, "{\"colNo\": 7}", {"name":"cell","hash":{},"data":data})))
    + "\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/outcome_label", depth0, "{\"colNo\": 1}", {"name":"cell","hash":{},"data":data})))
    + "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.comparatorTestPresent : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(4, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}