var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          <div class=\"error-message\">"
    + escapeExpression(((helper = (helper = helpers.error || (depth0 != null ? depth0.error : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"error","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "projects:welcome.start_new_section", {"name":"i18nNamespace","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"4":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <div class=\"form-group mt-10\">\n      <div class=\"col-12\">\n        <section class=\"types\">\n          <span class=\"info-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "type_help", {"name":"i18n","hash":{},"data":data})))
    + "\"></span>\n          <h3>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types_header", {"name":"i18n","hash":{},"data":data})))
    + "</h3>\n          <label>\n            <input type=\"radio\" name=\"type\" value=\"grade_ep\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "grade_ep", {"name":"i18n","hash":{},"data":data})))
    + "\n          </label>\n          <label>\n            <input type=\"radio\" name=\"type\" value=\"sof_table\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "sof_table", {"name":"i18n","hash":{},"data":data})))
    + "\n          </label>\n          <label>\n          <input type=\"radio\" name=\"type\" value=\"etd\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "etd", {"name":"i18n","hash":{},"data":data})))
    + "\n          </label>\n          <label>\n            <input type=\"radio\" name=\"type\" value=\"full_guideline\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "full_guideline", {"name":"i18n","hash":{},"data":data})))
    + "\n          </label>\n        </section>\n      </div>\n    </div>\n";
},"6":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <div class=\"form-group mt-10\">\n          <div class=\"col-12\">\n              <input id=\"customer\" class=\"form-control\" type=\"text\"\n                     placeholder=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "projects:new_project_customer", {"name":"i18n","hash":{},"data":data})))
    + "\">\n          </div>\n      </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"form form-horizontal w-400\">\n  <div class=\"form-group mt-10\">\n    <div class=\"col-12\">\n      <input id=\"name\" class=\"form-control\" type=\"text\"\n        placeholder=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "projects:new_project", {"name":"i18n","hash":{},"data":data})))
    + "\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </div>\n  </div>\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.type : depth0), {"name":"unless","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isCustomer : depth0), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  <div class=\"row mt-10\">\n    <div class=\"col-6\">\n      <button class=\"btn btn-block cancel\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "actions.cancel", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n    </div>\n    <div class=\"col-6\">\n      <button class=\"btn btn-block btn-success create\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "projects:new_project_dialog.create", {"name":"i18n","hash":{},"data":data})))
    + "\n      </button>\n    </div>\n  </div>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}