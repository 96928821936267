var CellFootnotesView, Draggable, EditView, Footnotes, Scrolling, Shortcuts, View, mediator, outcomeCellTypes, template, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

View = require('base/views/view');

template = require('views/templates/evidence_syntheses/cell_footnotes');

mediator = require('mediator');

Shortcuts = require('base/lib/traits/shortcuts');

Scrolling = require('base/lib/traits/scrolling');

utils = require('base/lib/utils');

Footnotes = require('models/evidence_syntheses/footnotes');

outcomeCellTypes = require('models/outcome/outcome_cell_types');

EditView = require('base/views/edit_view');

Draggable = require('base/lib/traits/draggable');

module.exports = CellFootnotesView = (function(_super) {
  __extends(CellFootnotesView, _super);

  function CellFootnotesView() {
    this.show = __bind(this.show, this);
    this.onFocusLost = __bind(this.onFocusLost, this);
    this.close = __bind(this.close, this);
    this.saveAppliedChangesAndClose = __bind(this.saveAppliedChangesAndClose, this);
    this.applySaveAndClose = __bind(this.applySaveAndClose, this);
    this.undoDeleteFootnote = __bind(this.undoDeleteFootnote, this);
    return CellFootnotesView.__super__.constructor.apply(this, arguments);
  }

  CellFootnotesView.prototype.className = 'box footnotes-box';

  CellFootnotesView.prototype.template = template;

  CellFootnotesView.prototype.shortcuts = {
    'esc': 'saveAppliedChangesAndClose',
    'enter': 'saveAppliedChangesAndClose',
    'enter.textarea': 'applyActiveFootnoteChanges',
    'up': 'selectPrevFootnote',
    'down': 'selectNextFootnote',
    'space': 'bindOrUnbindActiveFootnote',
    'ctrl+a': 'addFootnote',
    'ctrl+e': 'editActiveFootnote'
  };

  CellFootnotesView.prototype.initialize = function() {
    CellFootnotesView.__super__.initialize.apply(this, arguments);
    this.enable(Shortcuts);
    this.enable(Scrolling, {
      content: '.footnotes-list',
      fixedElements: '.edit-label, .new-footnote-button'
    });
    this.enable(Draggable);
    this.questionModel = this.options.questionModel;
    this.questionModel.store();
    this.subscribeEvent('focusLost', this.applySaveAndClose);
    this.delegate('mouseover', '.footnotes-list li', this.selectFootnoteOnMouseOver);
    this.delegate('click', this.stopPropagation);
    this.delegate('click', '.new-footnote-button', this.addFootnote);
    this.delegate('click', '.footnotes-list button.edit', this.editFootnote);
    this.delegate('click', '.footnotes-list button.save', this.applyActiveFootnoteChanges);
    this.delegate('click', '.footnotes-list button.delete', this.deleteFootnote);
    this.delegate('change', 'input[type=checkbox]', this.bindOrUnbindFootnote);
    return this.delegate('click', '.close', this.saveAppliedChangesAndClose);
  };

  CellFootnotesView.prototype.isPopupView = function() {
    return true;
  };

  CellFootnotesView.prototype.footnotesEnabled = function(modelType, property) {
    var disabledProperties, _ref;
    if (modelType !== 'outcome') {
      return true;
    }
    disabledProperties = ['editSofOutcome'];
    return __indexOf.call(disabledProperties, property) < 0 && ((_ref = outcomeCellTypes[property]) != null ? _ref.editType : void 0) !== EditView.TYPES.COMPOUND;
  };

  CellFootnotesView.prototype.getTemplateData = function() {
    var note, notes, outcomeCellNotes, templateData, _i, _len, _ref, _ref1;
    templateData = {};
    notes = [];
    outcomeCellNotes = (_ref = this.footnotesBindingModel.get('footnotes')[this.options.property]) != null ? _ref : [];
    _ref1 = this.questionModel.get('footnotesList').getNotesList();
    for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
      note = _ref1[_i];
      note.checked = outcomeCellNotes && outcomeCellNotes.indexOf(note.id) !== -1;
      if (!(note.id === Footnotes.prototype.noExplanationProvided && !note.checked)) {
        notes.push(note);
      }
    }
    templateData.notes = notes;
    return templateData;
  };

  CellFootnotesView.prototype.stopPropagation = function(e) {
    return e.stopPropagation();
  };

  CellFootnotesView.prototype.addFootnote = function(e) {
    var newNoteId;
    this.applyActiveFootnoteChanges();
    newNoteId = this.questionModel.addFootnote();
    this.footnotesBindingModel.bindFootnote(newNoteId, this.options.property);
    this.render();
    return this.editFootnote(e, this.$el.find("li[data-footnote-id=" + newNoteId + "]"));
  };

  CellFootnotesView.prototype.editActiveFootnote = function(e) {
    e.preventDefault();
    return this.editFootnote(e, this.footnotesRows.eq(this.activeFootnoteIdx));
  };

  CellFootnotesView.prototype.editFootnote = function(e, footnoteElement) {
    var $noteInput, footnoteId;
    this.applyActiveFootnoteChanges();
    this.setDefaultScope('textarea');
    if (footnoteElement) {
      this.$editingFootnoteElement = footnoteElement;
    } else {
      footnoteId = $(e.target).closest('li').data()['footnoteId'];
      this.$editingFootnoteElement = this.$el.find("li[data-footnote-id=" + footnoteId + "]");
    }
    if (this.$editingFootnoteElement.data()['special']) {
      this.$editingFootnoteElement = void 0;
      this.unsetDefaultScope();
      return;
    }
    this.$editingFootnoteElement.children('div').hide();
    $noteInput = this.$editingFootnoteElement.children('.edit-footnote');
    $noteInput.show();
    return $noteInput.find('.footnote-input-field').focus();
  };

  CellFootnotesView.prototype.deleteFootnote = function() {
    var $footnoteInput, footnoteId;
    if (!this.$editingFootnoteElement) {
      return;
    }
    $footnoteInput = this.$editingFootnoteElement.find('.footnote-input-field');
    footnoteId = $footnoteInput.attr('name');
    this.applyActiveFootnoteChanges();
    this.questionModel.deleteFootnote(footnoteId);
    this.closeEditFootnote();
    this.render();
    return this.footnoteDeletedNotify();
  };

  CellFootnotesView.prototype.footnoteDeletedNotify = function(e) {
    if (this.undoNotifier) {
      this.undoNotifier.destroy();
    }
    this.undoActive = true;
    this.newItem = void 0;
    return this.undoNotifier = mediator.dialogs.undo($.t('es:footnotes.deleted')).on('click:undo', (function(_this) {
      return function() {
        return _this.undoDeleteFootnote();
      };
    })(this));
  };

  CellFootnotesView.prototype.undoDeleteFootnote = function() {
    if (!this.undoActive) {
      return;
    }
    this.undoNotifier.destroy();
    this.questionModel.restore();
    this.publishEvent('footnoteRestored');
    this.questionModel.parse(this.questionModel.attributes);
    this.undoActive = false;
    mediator.dialogs.success($.t('es:footnotes.delete_undone'));
    return this.render();
  };

  CellFootnotesView.prototype.closeEditFootnote = function() {
    var _ref, _ref1;
    if ((_ref = this.$editingFootnoteElement) != null) {
      _ref.children('.display-footnote').show();
    }
    if ((_ref1 = this.$editingFootnoteElement) != null) {
      _ref1.children('.edit-footnote').hide();
    }
    return this.$editingFootnoteElement = void 0;
  };

  CellFootnotesView.prototype.applyActiveFootnoteChanges = function() {
    var $footnoteInput, footnoteId, footnoteVal;
    if (!this.$editingFootnoteElement) {
      return;
    }
    this.unsetDefaultScope();
    this.newNotAppliedFootnote = false;
    $footnoteInput = this.$editingFootnoteElement.find('.footnote-input-field');
    footnoteId = $footnoteInput.attr('name');
    footnoteVal = $footnoteInput.val();
    this.questionModel.updateFootnote(footnoteId, footnoteVal);
    this.closeEditFootnote();
    return this.render();
  };

  CellFootnotesView.prototype.saveFootnotes = function() {
    return this.footnotesBindingModel.save();
  };

  CellFootnotesView.prototype.applySaveAndClose = function() {
    this.applyActiveFootnoteChanges();
    return this.saveAppliedChangesAndClose();
  };

  CellFootnotesView.prototype.forceFootnotesIfNecassary = function() {
    var _ref;
    if (!((_ref = this.footnotesBindingModel.get('footnotes')[this.options.property]) != null ? _ref.length : void 0)) {
      if (this.forceFootnote) {
        this.footnotesBindingModel.bindFootnote(Footnotes.prototype.noExplanationProvided, this.options.property);
        return this.saveFootnotes();
      }
    }
  };

  CellFootnotesView.prototype.saveAppliedChangesAndClose = function() {
    return this.saveFootnotes().then(this.close).otherwise(mediator.dialogs.fatalError);
  };

  CellFootnotesView.prototype.close = function() {
    if (this.disposed) {
      return;
    }
    this.forceFootnotesIfNecassary();
    if (this.options.propertyView && !this.options.propertyView.disposed) {
      mediator.setFocus(this.options.propertyView, (function(_this) {
        return function() {
          _this.dispose();
          return mediator.publish('cellFootnotesClosed');
        };
      })(this));
    } else {
      this.dispose();
      mediator.publish('cellFootnotesClosed');
    }
    return mediator.actionsQueue.push("Closed CellFootnotesView");
  };

  CellFootnotesView.prototype.onFocusLost = function() {
    return this.applySaveAndClose();
  };

  CellFootnotesView.prototype.bindOrUnbindActiveFootnote = function(e) {
    var $footnoteCheckbox;
    e.preventDefault();
    $footnoteCheckbox = this.footnotesRows.eq(this.activeFootnoteIdx).find('input[type=checkbox]:visible');
    return $footnoteCheckbox.prop('checked', !$footnoteCheckbox.prop('checked')).trigger('change');
  };

  CellFootnotesView.prototype.bindOrUnbindFootnote = function(e) {
    var $footnoteCheckbox;
    $footnoteCheckbox = $(e.target);
    if ($footnoteCheckbox.is(':checked')) {
      this.footnotesBindingModel.bindFootnote($footnoteCheckbox.attr('id'), this.options.property);
      return $(e.target).closest('li').addClass('checked');
    } else {
      this.footnotesBindingModel.unbindFootnote($footnoteCheckbox.attr('id'), this.options.property);
      return $(e.target).closest('li').removeClass('checked');
    }
  };

  CellFootnotesView.prototype.setFootnotesBindingModel = function() {
    return this.footnotesBindingModel = this.options.footnotesBindingModel;
  };

  CellFootnotesView.prototype.setActiveFootnote = function(idx) {
    if (!this.footnotesRows.length) {
      return;
    }
    this.footnotesRows.eq(this.activeFootnoteIdx).removeClass('active');
    this.activeFootnoteIdx = idx;
    this.footnotesRows.eq(this.activeFootnoteIdx).addClass('active');
    return false;
  };

  CellFootnotesView.prototype.selectNextFootnote = function(e) {
    return this.setActiveFootnote(utils.cycleUp(this.activeFootnoteIdx, this.footnotesRows.length));
  };

  CellFootnotesView.prototype.selectPrevFootnote = function(e) {
    return this.setActiveFootnote(utils.cycleDown(this.activeFootnoteIdx, this.footnotesRows.length));
  };

  CellFootnotesView.prototype.selectFootnoteOnMouseOver = function(e) {
    return this.setActiveFootnote(this.footnotesRows.index($(e.target).closest('li')));
  };

  CellFootnotesView.prototype.render = function() {
    this.setFootnotesBindingModel();
    return CellFootnotesView.__super__.render.apply(this, arguments);
  };

  CellFootnotesView.prototype.afterRender = function() {
    this.footnotesRows = this.$('.footnotes-list').find('li');
    this.activeFootnoteIdx = this.footnotesRows.length ? 0 : -1;
    return this.footnotesRows.eq(0).addClass('active');
  };

  CellFootnotesView.prototype.show = function(options) {
    return mediator.setFocus(this, (function(_this) {
      return function() {
        var cell, container;
        options = options != null ? options : {};
        _this.render();
        cell = options.cell;
        if (!cell.length) {
          return;
        }
        container = options.container;
        container.append(_this.$el);
        $(_this.$el).css({
          display: 'block',
          left: Math.min(cell.offset().left - container.offset().left, $(window).width() - container.offset().left - _this.$el.width() - 15),
          top: Math.max(0, Math.min(cell.offset().top - container.offset().top, $(window).height() - container.offset().top - _this.$el.height() - 15))
        });
        if (options != null ? options.forceQualityFootnote : void 0) {
          _this.forceFootnote = true;
          _this._forceFootnoteDialog($.t('es:force_footnotes.quality_downgrade'));
        } else if (options != null ? options.forceAutoCalculationFootnote : void 0) {
          _this.forceFootnote = true;
          _this._forceFootnoteDialog($.t('es:force_footnotes.abs_effect_manual_calc'));
        }
        _this.trigger('!fixLayout');
        return _this.makeDraggable();
      };
    })(this));
  };

  CellFootnotesView.prototype._forceFootnoteDialog = function(message) {
    return this.forceDialog = mediator.dialogs.info({
      message: message,
      ms: null
    });
  };

  CellFootnotesView.prototype.dispose = function() {
    var _ref;
    if (this.undoNotifier) {
      this.undoNotifier.destroy();
    }
    if ((_ref = this.forceDialog) != null) {
      _ref.destroy();
    }
    return CellFootnotesView.__super__.dispose.apply(this, arguments);
  };

  return CellFootnotesView;

})(View);
