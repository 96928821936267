LabelCell = require 'components/etd/etd_header_label_cell'
EditCell = require 'components/etd/etd_header_editable_cell'
Translation = require 'components/mixins/translation'
PureRenderMixin = React.addons.PureRenderMixin

EtdHeaderRow = React.createClass
  displayName: 'EtdHeaderRow'
  mixins: [Translation('es:recommendations.header'), PureRenderMixin]

  _prepareSecondColumnCells: ->
    if @props.additionalSection
      [sectionId, sectionData] = @props.additionalSection
      [
        <LabelCell text={sectionData.get 'name'} rowSpan={@props.noOfSectionsRows} key="1" />
        <EditCell
          ref={sectionId}
          rowSpan={@props.noOfSectionsRows}
          content={sectionData.get 'content' }
          sectionId={sectionId}
          isEditing={@props.isEditing}
          handleUserInput={@props.handleUserInput}
          key="2"
        />
      ]

  render: ->
    <tr>
      <LabelCell text={@props.data.get 'name'} />
      <EditCell
        ref={@props.sectionId}
        content={@props.data.get('content') or @props.defaultContent}
        sectionId={@props.sectionId}
        isEditing={@props.isEditing}
        handleUserInput={@props.handleUserInput}
      />

      {@_prepareSecondColumnCells()}
    </tr>

module.exports = EtdHeaderRow
