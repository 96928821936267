var ModalView, ProjectSharingView, Validation, W, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModalView = require('base/views/modal_view');

Validation = require('base/validation');

template = require('./templates/project_sharing');

mediator = require('mediator');

W = require('when/when');

module.exports = ProjectSharingView = (function(_super) {
  __extends(ProjectSharingView, _super);

  function ProjectSharingView() {
    return ProjectSharingView.__super__.constructor.apply(this, arguments);
  }

  ProjectSharingView.prototype.template = template;

  ProjectSharingView.prototype.className = 'share-project-dialog';

  ProjectSharingView.prototype.title = $.t('projects:share_project_dialog.title');

  ProjectSharingView.prototype.shortcuts = {
    'enter': 'share',
    'esc': 'dispose'
  };

  ProjectSharingView.prototype.initialize = function() {
    ProjectSharingView.__super__.initialize.apply(this, arguments);
    this.delegate('click', 'button.share-project', this.share);
    this.delegate('click', 'button.share-copy', this.shareCopy);
    this.delegate('click', 'button.cancel', this.dispose);
    if (mediator.services.switches.isOn('new_projects_list')) {
      return this.sharedWith = _(mediator.user.getRequests().models).filter((function(_this) {
        return function(req) {
          return req.attributes.type === 'share_project' && req.attributes.project === _this.model.id;
        };
      })(this));
    }
  };

  ProjectSharingView.prototype.getTemplateData = function() {
    return {
      sharedWith: _(this.sharedWith).chain().pluck('attributes').pluck('with').value().sort().join(', ')
    };
  };

  ProjectSharingView.prototype.afterRender = function() {
    ProjectSharingView.__super__.afterRender.apply(this, arguments);
    return this.$('#email').focus();
  };

  ProjectSharingView.prototype.validateEmail = function(email) {
    if (Validation.isValidEmail(email)) {
      return mediator.services.email.checkIfEmailUsed(email).then((function(_this) {
        return function(used) {
          if (used) {
            _this.$('.error').text('');
            return true;
          } else {
            _this.$('.error').html($.t('projects:share_project_dialog.no_user_with_email'));
            return false;
          }
        };
      })(this)).otherwise((function(_this) {
        return function() {
          _this.$('.error').text('');
          return true;
        };
      })(this));
    } else {
      this.$('.error').html($.t('projects:share_project_dialog.invalid_email'));
      return W.resolve(false);
    }
  };

  ProjectSharingView.prototype.shareCopy = function() {
    var email;
    email = this.$('#email').val();
    return this.validateEmail(email).then((function(_this) {
      return function(isValid) {
        if (isValid) {
          mediator.services.projectSharing.shareProjectCopy(_this.model.id, email);
          return _this.dispose();
        }
      };
    })(this));
  };

  ProjectSharingView.prototype.share = function() {
    var email;
    email = this.$('#email').val();
    return this.validateEmail(email).then((function(_this) {
      return function(isValid) {
        if (isValid) {
          mediator.services.projectSharing.shareProject(_this.model.id, email);
          mediator.publish('projectShared', _this.model.id);
          return _this.dispose();
        }
      };
    })(this));
  };

  return ProjectSharingView;

})(ModalView);
