var createStoreMixin,
  __slice = [].slice;

createStoreMixin = function() {
  var StoreMixin, stores;
  stores = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
  StoreMixin = {
    getInitialState: function() {
      return this.getStateFromStores();
    },
    componentDidMount: function() {
      return stores.forEach((function(_this) {
        return function(store) {
          return store.listen(_this.onChange);
        };
      })(this));
    },
    componentWillUnmount: function() {
      return stores.forEach((function(_this) {
        return function(store) {
          return store.unlisten(_this.onChange);
        };
      })(this));
    },
    onChange: function() {
      if (this.isMounted()) {
        return this.setState(this.getStateFromStores());
      }
    }
  };
  return StoreMixin;
};

module.exports = createStoreMixin;
