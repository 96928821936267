var DbHelper, Exceptions, W, absoluteServerUrl, dbUrl, launchedFromExtension, mediator,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

Exceptions = require('lib/exceptions');

mediator = require('mediator');

W = require('when');

launchedFromExtension = window.location.protocol === 'chrome-extension:';

absoluteServerUrl = window.gdt.debugMode ? 'http://couch.dev' : launchedFromExtension ? 'http://gdt.gradepro.org' : window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');

dbUrl = window.gdt.debugMode ? absoluteServerUrl : "" + absoluteServerUrl + "/_db";

DbHelper = (function() {
  function DbHelper() {
    this.centralReplica = __bind(this.centralReplica, this);
  }

  DbHelper.prototype.getPresentationsDbUrl = function() {
    return "" + dbUrl + "/presentations";
  };

  DbHelper.prototype.getPresentationUrl = function(id) {
    return "" + absoluteServerUrl + "/presentations/#/" + id;
  };

  DbHelper.prototype.getDbepApiUrl = function() {
    if (mediator.debug || window.location.hostname === 'gdt.test') {
      return 'http://dbep.test/api/';
    } else {
      return 'http://dbep.gradepro.org/api/';
    }
  };

  DbHelper.prototype.getWebPreviewUrl = function() {
    return "" + absoluteServerUrl + "/mobile-preview/preview.html";
  };

  DbHelper.prototype.getMobilePreviewDocUrl = function(id) {
    return "" + dbUrl + "/mobile-preview/" + id;
  };

  DbHelper.prototype.getMobilePreviewUrl = function(id) {
    return "" + absoluteServerUrl + "/mobile-preview/#" + id;
  };

  DbHelper.prototype.getFormsAppUrl = function() {
    return "" + absoluteServerUrl + "/forms/";
  };

  DbHelper.prototype.getRandVotingDbUrl = function() {
    return "" + dbUrl + "/rand-voting";
  };

  DbHelper.prototype.getBackendUrl = function() {
    if (mediator.debug) {
      return 'http://api.gdt.dev:9000';
    } else {
      return "" + absoluteServerUrl + "/_api";
    }
  };

  DbHelper.prototype.getClockUrl = function() {
    return "" + dbUrl + "/clock/_design/clock/_show/serverTime";
  };

  DbHelper.prototype.centralDbUrl = function(dbName) {
    return "" + dbUrl + "/" + dbName;
  };

  DbHelper.prototype.centralReplica = function(name) {
    return PouchDB(this.centralDbUrl(name), {
      skip_setup: true
    });
  };

  DbHelper.prototype.localReplica = function(name) {
    return PouchDB(name, window.gdt.POUCHDB_OPTIONS);
  };

  DbHelper.prototype.getSessionUrl = function() {
    return "" + dbUrl + "/_session";
  };

  DbHelper.prototype.getRegistrationUrl = function() {
    return "" + dbUrl + "/_users";
  };

  return DbHelper;

})();

module.exports = new DbHelper();
