var JSONLDExportManagementQuestionsService, ManagementQuestionExporter, ManagementRecommendationsExporter, lift, utils;

ManagementQuestionExporter = require('./management_question_exporter');

ManagementRecommendationsExporter = require('./management_recommendations_exporter');

utils = require('./exporter_utils');

lift = (require('when/function')).lift;

module.exports = JSONLDExportManagementQuestionsService = (function() {
  function JSONLDExportManagementQuestionsService() {}

  JSONLDExportManagementQuestionsService.prototype._export = function(originalQuestion, etdVersion) {
    var exportedEvidenceSummary, exportedExplanations, exportedOutcomes, exportedQuestion, exportedRecommendations, implementationConsiderations, questionExporter, recommendationsExporter, researchPriorities;
    if (etdVersion == null) {
      etdVersion = 'v2';
    }
    questionExporter = new ManagementQuestionExporter(originalQuestion);
    exportedOutcomes = questionExporter.exportOutcomes();
    exportedExplanations = questionExporter.exportExplanations();
    exportedEvidenceSummary = questionExporter.exportEvidenceSummary();
    exportedQuestion = {
      '@context': utils.managementQuestionContext(),
      '@type': 'ManagementEvidenceProfile',
      'version': (originalQuestion['_rev'].split('-'))[0],
      'modificationTime': utils.timestampToISOWithTimeZone(originalQuestion['$timestamp']),
      'title': originalQuestion['question'],
      'sofTitle': originalQuestion['questionShort'],
      'question': {
        '@id': "questions/" + originalQuestion['_id'],
        '@type': 'PICO',
        'healthProblemOrPopulation': {
          '@type': 'HealthProblemOrPopulation',
          'name': originalQuestion['healthProblemOrPopulation'],
          'setting': originalQuestion['settings']
        },
        'intervention': {
          '@type': 'Intervention',
          'name': originalQuestion['intervention']
        },
        'comparison': {
          '@type': 'Comparison',
          'name': originalQuestion['comparison']
        },
        'outcome': exportedOutcomes
      },
      'explanation': exportedExplanations,
      'evidenceSummary': exportedEvidenceSummary
    };
    if ((originalQuestion['authors'] != null) && originalQuestion['authors'] !== '') {
      exportedQuestion['author'] = {
        '@type': 'Person',
        'name': originalQuestion['authors']
      };
    }
    recommendationsExporter = new ManagementRecommendationsExporter(originalQuestion, etdVersion);
    implementationConsiderations = recommendationsExporter.exportImplementationConsiderations();
    researchPriorities = recommendationsExporter.exportResearchPriorities();
    exportedRecommendations = {
      '@context': utils.recommendationsContext(),
      '@type': ['Recommendation', 'MedicalGuidelineRecommendation'],
      'version': (originalQuestion['_rev'].split('-'))[0],
      'section': recommendationsExporter.exportSections(),
      'modificationTime': utils.timestampToISOWithTimeZone(originalQuestion['$timestamp']),
      'title': originalQuestion['question'],
      'guidelineSubject': {
        '@id': "questions/" + originalQuestion['_id']
      },
      'consequencesBalance': recommendationsExporter.exportConsequencesBalance(),
      'recommendationType': recommendationsExporter.exportRecommendationType(),
      'subgroupConsiderations': recommendationsExporter.exportSubgroupConsiderations(),
      'recommendationText': recommendationsExporter.exportRecommendations(),
      'recommendationJustification': recommendationsExporter.exportJustification(),
      'implementationConsiderations': implementationConsiderations,
      'monitoringAndEvaluation': recommendationsExporter.exportMonitoringAndEvaluation(),
      'header': recommendationsExporter.exportHeader()
    };
    switch (etdVersion) {
      case 'v1':
        exportedRecommendations['researchPossibilities'] = researchPriorities;
        break;
      case 'v2':
        exportedRecommendations['researchPriorities'] = researchPriorities;
    }
    utils.removeFalseValuesFromObject(exportedRecommendations);
    if ((originalQuestion['authors'] != null) && originalQuestion['authors'] !== '') {
      exportedRecommendations['author'] = {
        '@type': 'Person',
        'name': originalQuestion['authors']
      };
    }
    return [exportedQuestion, exportedRecommendations];
  };

  JSONLDExportManagementQuestionsService.prototype["export"] = function(originalQuestion, etdVersion) {
    if (etdVersion == null) {
      etdVersion = 'v2';
    }
    return (lift(this._export, originalQuestion, etdVersion))();
  };

  return JSONLDExportManagementQuestionsService;

})();
