var DocumentSectionsToolbarView, DocumentSectionsView, FileSaver, Shortcuts, View, content_template, mediator, template, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

template = require('views/templates/document_sections');

utils = require('base/lib/utils');

DocumentSectionsToolbarView = require('views/document_sections_toolbar_view');

Shortcuts = require('base/lib/traits/shortcuts');

FileSaver = require('lib/file_saver');

content_template = require('views/templates/document_sections_content');

mediator = require('mediator');

module.exports = DocumentSectionsView = (function(_super) {
  __extends(DocumentSectionsView, _super);

  function DocumentSectionsView() {
    this._getEditorContent = __bind(this._getEditorContent, this);
    return DocumentSectionsView.__super__.constructor.apply(this, arguments);
  }

  DocumentSectionsView.prototype.container = '#page-container';

  DocumentSectionsView.prototype.id = 'document-sections-container';

  DocumentSectionsView.prototype.template = template;

  DocumentSectionsView.prototype.autoRender = true;

  DocumentSectionsView.prototype.sectionsIds = ['document-title', 'document-subtitle', 'authors', 'disclosure-coi', 'review-group', 'table-of-contents', 'executive-summary', 'introduction', 'methodology', 'how-to-use', 'key-quesions', 'recommendations', 'introduction-and-background', 'scope', 'methods', 'group-composition', 'group-meetings', 'appendices'];

  DocumentSectionsView.prototype.initialize = function() {
    var _ref;
    this.startingContent = content_template(this.getTemplateData());
    this.editorContent = (_ref = this.model.get('documentSections')) != null ? _ref : this.startingContent;
    DocumentSectionsView.__super__.initialize.apply(this, arguments);
    this.delegate('click', '.sections li', this.navigateTo);
    this.delegate('click', 'button.export', this._exportContent);
    this.enable(Shortcuts);
    return this.subview('toolbar', new DocumentSectionsToolbarView({
      model: this.model,
      frameContentSelector: '#document-sections-container iframe',
      startingContent: this.startingContent
    }));
  };

  DocumentSectionsView.prototype.navigateTo = function(e) {
    var $target, iframeContents;
    $target = $(e.target);
    mediator.log($target.attr('name'));
    iframeContents = this.$('iframe').contents();
    return iframeContents.scrollTop(iframeContents.find("#" + ($target.attr('name'))).offset().top);
  };

  DocumentSectionsView.prototype.afterRender = function() {
    DocumentSectionsView.__super__.afterRender.apply(this, arguments);
    return this.on('addedToDOM', new TINY.editor.edit('editor', {
      id: 'document-sections',
      width: '100%',
      height: 400,
      cssclass: 'te',
      controlclass: 'tecontrol',
      rowclass: 'teheader',
      dividerclass: 'tedivider',
      controls: ['bold', 'italic', 'underline', 'strikethrough', '|', 'subscript', 'superscript', '|', 'orderedlist', 'unorderedlist', '|', 'outdent', 'indent', '|', 'leftalign', 'centeralign', 'rightalign', 'blockjustify', '|', 'unformat', '|', 'undo', 'redo', 'n', 'font', 'size', 'style', '|', 'image', 'hr', 'link', 'unlink'],
      footer: true,
      fonts: ['Verdana', 'Arial', 'Georgia', 'Trebuchet MS'],
      xhtml: true,
      content: this.editorContent,
      bodyid: 'editor',
      footerclass: 'tefooter',
      toggle: {
        text: 'source',
        activetext: 'wysiwyg',
        cssclass: 'toggle'
      },
      resize: {
        cssclass: 'resize'
      }
    }));
  };

  DocumentSectionsView.prototype.getTemplateData = function() {
    return {
      sections: _.object(_(this.sectionsIds).map(function(sid) {
        return [sid, utils.dashToUnderscore(sid)];
      })),
      projectName: this.model.get('name'),
      mentionedQuestions: _.map(this.model.get('draftQuestions').where({
        approvalStatus: 'mentioned'
      }), function(q) {
        return q.get('question');
      }),
      rejectedQuestions: _.map(this.model.get('draftQuestions').where({
        approvalStatus: 'rejected'
      }), function(q) {
        return q.get('question');
      })
    };
  };

  DocumentSectionsView.prototype._getEditorContent = function() {
    return this.$('iframe').contents().find('#editor').html();
  };

  DocumentSectionsView.prototype._exportContent = function() {
    var blob, content, fileName;
    content = this._getEditorContent();
    fileName = _.str.slugify(this.model.get('name')) + '.html';
    blob = new Blob(['<html><head><meta charset="utf-8"></head><body>', content, '</body></html>'], {
      type: 'text/html;charset=utf-8'
    });
    return FileSaver.saveFile(blob, fileName).otherwise(function(reason) {
      if (reason !== 'cancelled') {
        return mediator.dialogs.error(reason);
      }
    });
  };

  DocumentSectionsView.prototype.dispose = function() {
    this.model.save('documentSections', this._getEditorContent());
    return DocumentSectionsView.__super__.dispose.apply(this, arguments);
  };

  return DocumentSectionsView;

})(View);
