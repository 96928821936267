var ProjectDbService, W, nodefn, utils;

utils = require('base/lib/utils');

W = require('when');

nodefn = require('when/node/function');

module.exports = ProjectDbService = (function() {
  function ProjectDbService() {}

  ProjectDbService.prototype.generateName = function(login) {
    return "p_" + login + "_" + (utils.generateGUID());
  };

  ProjectDbService.prototype.projectsRedirectingTo = function(project) {
    var duplicateOf;
    duplicateOf = project.get('duplicateOf');
    if (duplicateOf) {
      return [duplicateOf];
    } else {
      return [];
    }
  };

  ProjectDbService.prototype.duplicate = function(login, projectName) {
    var p;
    p = Pouch(projectName, {
      size: INITIAL_POUCHDB_SIZE
    });
    return nodefn.call(_(p.get).bind(p), projectName).then((function(_this) {
      return function() {
        var newName;
        newName = _this.generateName(login);
        return nodefn.call(Pouch.replicate.bind(Pouch), projectName, newName).then(function() {
          return _this._renameMetadoc(projectName, newName);
        }).then(function() {
          return newName;
        });
      };
    })(this));
  };

  ProjectDbService.prototype._renameMetadoc = function(projectName, newName) {
    var boundGet, boundPut, boundRemove, db;
    db = Pouch(newName, {
      size: INITIAL_POUCHDB_SIZE
    });
    boundGet = _(db.get).bind(db);
    boundPut = _(db.put).bind(db);
    boundRemove = _(db.remove).bind(db);
    return nodefn.call(boundGet, projectName).then(function(doc) {
      var newDoc;
      newDoc = _({}).extend(doc, {
        _id: newName,
        duplicateOf: projectName
      });
      delete newDoc._rev;
      return nodefn.call(boundPut, newDoc).then(function() {
        return nodefn.call(boundRemove, doc);
      });
    });
  };

  ProjectDbService.prototype.destroy = function(id) {
    return Pouch(id).destroy();
  };

  ProjectDbService.prototype.isProjectDb = function(dbName) {
    return _.str.startsWith(dbName, 'p_');
  };

  return ProjectDbService;

})();
