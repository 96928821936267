var EtdTemplates, EtdTemplatesActions, EtdTemplatesView, ReactComponent, View,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

EtdTemplates = require('components/administration/etd_templates');

EtdTemplatesActions = require('actions/etd_templates_actions');

ReactComponent = require('base/lib/traits/react_component');

module.exports = EtdTemplatesView = (function(_super) {
  __extends(EtdTemplatesView, _super);

  function EtdTemplatesView() {
    return EtdTemplatesView.__super__.constructor.apply(this, arguments);
  }

  EtdTemplatesView.prototype.container = '#page-container';

  EtdTemplatesView.prototype.className = 'etd-templates-view';

  EtdTemplatesView.prototype.autoRender = true;

  EtdTemplatesView.prototype.initialize = function() {
    this.enable(ReactComponent);
    return EtdTemplatesView.__super__.initialize.apply(this, arguments);
  };

  EtdTemplatesView.prototype.afterRender = function() {
    EtdTemplatesView.__super__.afterRender.apply(this, arguments);
    return this.renderComponent(EtdTemplates, EtdTemplatesActions, ['etd-tx-template', 'etd-dx-template']);
  };

  EtdTemplatesView.prototype.dispose = function() {
    this.unmountComponent();
    return EtdTemplatesView.__super__.dispose.apply(this, arguments);
  };

  return EtdTemplatesView;

})(View);
