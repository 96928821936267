var Exceptions, METHOD_MAP, Model, W, collectionChangeListener, fetchAllDocs, fetchDataFromView, fetchSingleDocument, getDb, getDbName, guard, mediator, modelChangeListener, modifyDocument, nodefn, setupListening;

Model = require('models/base/model');

Exceptions = require('lib/exceptions');

W = require('when');

W.lift = require('when/function').lift;

nodefn = require('when/node/function');

guard = require('when/guard');

mediator = require('mediator');

METHOD_MAP = {
  create: 'post',
  update: 'put',
  "delete": 'remove',
  read: 'get'
};

getDbName = function(url) {
  if (url[0] === '/') {
    url = url.slice(1);
  }
  return url.split('/', 2)[0];
};

getDb = function(cache, url) {
  var dbName, _ref;
  dbName = getDbName(url);
  return (_ref = cache[dbName]) != null ? _ref : cache[dbName] = Pouch(dbName, {
    size: INITIAL_POUCHDB_SIZE
  });
};

modifyDocument = function(db, functionName, model, data) {
  return nodefn.call(_(db[functionName]).bind(db), data).then(function(response) {
    if (functionName === 'remove') {
      model.set('_rev', response.rev);
    }
    return {
      _id: response.id,
      _rev: response.rev,
      rev_author: data.rev_author,
      $timestamp: data.$timestamp
    };
  });
};

fetchSingleDocument = function(db, id) {
  return nodefn.call(_(db.get).bind(db), id);
};

fetchAllDocs = function(db) {
  return nodefn.call(_(db.allDocs).bind(db), {
    include_docs: true
  }).then(function(response) {
    return _(response.rows).pluck('doc');
  });
};

fetchDataFromView = function(db, viewName, view) {
  var reduce, _createDesignDoc, _ref;
  _createDesignDoc = function(name, mapFn, reduceFn) {
    var ddoc;
    if (reduceFn == null) {
      reduceFn = false;
    }
    ddoc = {
      _id: "_design/" + name,
      views: {},
      filters: {}
    };
    ddoc.views[name] = {
      map: mapFn.toString()
    };
    if (reduceFn) {
      ddoc.views[name].reduce = reduceFn.toString();
    }
    return ddoc;
  };
  reduce = (_ref = view.reduce) != null ? _ref : false;
  return db.query(viewName).then(function(response) {
    return _(response.rows).pluck('value');
  })["catch"](function(err) {
    var designDoc;
    if (err.status === 404) {
      designDoc = _createDesignDoc(viewName, view.map, reduce);
      db.put(designDoc).then(function(doc) {
        return db.query(viewName, {
          stale: 'update_after'
        });
      })["catch"](function(err) {
        throw new Error(err.message);
      });
      return db.query(view.map, {
        reduce: reduce,
        include_docs: true
      }).then(function(response) {
        return _(response.rows).pluck('doc');
      });
    } else {
      throw new Error(err.message);
    }
  });
};

modelChangeListener = function(model) {
  return function(change) {
    if (change.id !== model.id || parseInt(change.doc._rev) <= parseInt(model.get('_rev'))) {
      return;
    }
    if (change.deleted) {
      model.set({
        _rev: change.doc._rev
      });
      model.trigger('destroy');
    } else {
      model.set(change.doc, {
        external: true
      });
    }
    return model.trigger('extChange', model);
  };
};

collectionChangeListener = function(collection) {
  return function(change) {
    var fn;
    fn = function() {
      var model, modelResult;
      model = collection.get(change.id);
      modelResult = model ? (change.deleted ? collection.remove(model) : void 0, modelChangeListener(model)(change)) : !change.deleted ? collection.add(change.doc) : void 0;
      if (modelResult) {
        return collection.trigger('extChange', collection);
      }
    };
    return _.delay(fn, 100);
  };
};

setupListening = function(db, moc, filter) {
  var _ref;
  if (moc.collection || ((_ref = moc.pouch) != null ? _ref.changeListener : void 0)) {
    return W.resolve();
  }
  return nodefn.call(_(db.info).bind(db)).then(function(info) {
    var listener, _ref1;
    if (!_(moc).has('pouch')) {
      moc.pouch = _((_ref1 = moc.pouch) != null ? _ref1 : {}).clone();
    }
    moc.pouch.changeListener = moc instanceof Model ? modelChangeListener(moc) : collectionChangeListener(moc);
    listener = _(db.changes).bind(db)({
      since: info.update_seq,
      include_docs: true,
      continuous: true,
      onChange: moc.pouch.changeListener,
      filter: filter
    }, function(error) {
      delete moc.pouch.changeListener;
      return moc.trigger('syncError', error);
    });
    moc.pouch.changeListenerObjRef = listener;
    return true;
  });
};

_.namespace(module, function(require) {
  var clock, dbCache;
  clock = require('lib/services/clock_service');
  dbCache = {};
  return {
    attach: W.lift(function(model, attachmentName, blob) {
      var db, url;
      if (model.isNew()) {
        throw new Error('Cannot add attachment prior to saving the model');
      }
      url = _(model).result('url') || (function() {
        throw new Error('Url must be specified');
      })();
      db = getDb(dbCache, url);
      return model.save().then(function(model) {
        var rev;
        rev = model.get('_rev');
        return db.putAttachment(model.id, attachmentName, rev, blob, blob.type);
      }).then(function() {
        return model.fetch();
      });
    }),
    getAttachment: W.lift(function(model, attachmentName) {
      var db, url;
      url = _(model).result('url') || (function() {
        throw new Error('Url must be specified');
      })();
      db = getDb(dbCache, url);
      return db.getAttachment(model.id, attachmentName);
    }),
    removeAttachment: W.lift(function(model, attachmentName) {
      var db, url;
      url = _(model).result('url') || (function() {
        throw new Error('Url must be specified');
      })();
      db = getDb(dbCache, url);
      return model.save().then(function(model) {
        var rev;
        rev = model.get('_rev');
        return db.removeAttachment(model.id, attachmentName, rev);
      }).then(function() {
        return model.fetch();
      })["catch"](function(e) {
        return console.log('Received error when deleting the attachment:', e);
      });
    }),
    getDoc: function(modelClass, id, urlRoot, createIfMissing) {
      var model;
      if (createIfMissing == null) {
        createIfMissing = true;
      }
      model = new modelClass({
        _id: id
      });
      model.urlRoot = urlRoot;
      return model.fetch().otherwise(function(error) {
        if (error.status === 404 && error.message === 'missing') {
          if (createIfMissing) {
            return model.save()["catch"](function(err) {
              if (err.status === 409) {
                return model.fetch();
              }
            });
          } else {
            throw new Exceptions.document_missing;
          }
        } else {
          throw error;
        }
      });
    },
    stopListening: function(moc) {
      var _ref;
      if ((_ref = moc.pouch.changeListenerObjRef) != null) {
        _ref.cancel();
      }
      moc.pouch.changeListenerObjRef = null;
      return moc.pouch.changeListener = null;
    },
    sync: function(configuration) {
      var syncFn;
      syncFn = function(method, moc, options) {
        var db, functionName, params, promise;
        if (options == null) {
          options = {};
        }
        params = _(options).defaults(moc != null ? moc.pouch : void 0, configuration);
        if (params.url == null) {
          params.url = _(moc).result('url') || (function() {
            throw new Error('Url must be specified');
          })();
        }
        if (!params.data && moc && method !== 'read') {
          params.data = moc.toJSON();
          params.data.rev_author = params.author;
          params.data.$timestamp = clock.getTimestamp();
        }
        db = getDb(dbCache, params.url);
        promise = method === 'read' ? moc.id ? fetchSingleDocument(db, moc.id) : params.fetch ? fetchDataFromView(db, params.fetch, params.views[params.fetch]) : fetchAllDocs(db) : (functionName = METHOD_MAP[method], modifyDocument(db, functionName, moc, params.data).then(function(response) {
          mediator.publish('dbWrite', getDbName(params.url), functionName);
          return response;
        }));
        moc.trigger('request', moc, promise, params);
        promise.then(params.success, params.error);
        return promise.then(function(response) {
          var _ref;
          return setupListening(db, moc, (_ref = params.filters) != null ? _ref[params.fetch] : void 0).then(function() {
            return W.resolve(moc, response);
          });
        });
      };
      return guard(guard.n(1), function(method, moc, options) {
        var error, wrappedSyncFn;
        if (options == null) {
          options = {};
        }
        wrappedSyncFn = typeof Raven !== "undefined" && Raven !== null ? Raven.wrap({
          extra: {
            moc: _.functionName(moc.constructor),
            syncMethod: method
          }
        }, syncFn) : syncFn;
        try {
          return wrappedSyncFn(method, moc, options);
        } catch (_error) {
          error = _error;
          if (typeof configuration.errorHandler === "function") {
            configuration.errorHandler();
          }
          throw error;
        }
      });
    }
  };
});
