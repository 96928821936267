ContentEditableWithButtons = require 'components/common/content_editable_with_buttons'
EtdActions = require 'actions/etd_actions'
PureRenderMixin = React.addons.PureRenderMixin

OptionsTypeSection = React.createClass
  displayName: 'OptionsTypeSection'
  mixins: [PureRenderMixin]

  onChange: (e) ->
    EtdActions.updateAndSaveConclusionsOption @props.sectionKey, e.currentTarget.value

  renderOptions: ->
    @props.options.map (option) =>
      <td key={option.get('value')}>
        <label>
          <span>{option.get('text')}</span>
          <br/>
          <input
            type="radio"
            value={option.get('value')}
            name={@props.sectionKey}
            checked={option.get('value') is @props.selectedOption}
            onChange={@onChange}/>
        </label>
      </td>

  renderOptionGroups: ->
    optionGroups = @props.optionGroups
    <td className='option-groups-cell'>
      <div className='option-groups'>
        <table className='groups'>
          <tbody>
            <tr>
              <td rowSpan=2 className='upper-bound-cell'>
                <div className='upper-bound'>
                  [{optionGroups.get 'upperBound'}]
                </div>
              </td>
              {optionGroups.get('groups').map (group) ->
                <td key={group.get 'groupName'} className='group-name-cell'>
                  [{group.get 'groupName'}]
                </td>
              }
              <td rowSpan=2 className='lower-bound-cell'>
                <div className='lower-bound'>
                  [{optionGroups.get 'lowerBound'}]
                </div>
              </td>
            </tr>
            <tr>
              {optionGroups.get('groups').map (group) =>
                <td key={group.get 'groupName'} className='options-cell'>
                  <div className='options'>
                    {
                      group.get('options').map (option) =>
                        <label key={option}>
                          <input
                            type="radio"
                            value={option}
                            name={@props.sectionKey}
                            checked={"#{option}" is "#{@props.selectedOption}"}
                            onChange={@onChange}
                          />
                          <span>{option}</span>
                        </label>
                    }
                  </div>
                </td>
              }
            </tr>
          </tbody>
        </table>
      </div>
    </td>

  render: ->
    <div>
      <table className='conclusions-options'>
        <tbody>
          <tr>
            {if @props.optionGroups
              @renderOptionGroups()
            else if @props.options
              @renderOptions()
            }
          </tr>
        </tbody>
      </table>
      {if @props.content?
        <TextSection
          currentEditable={@props.currentEditable}
          content={@props.content}
          cellId={"#{@props.sectionKey}#conclusions"}
        />
      }
    </div>

TextSection = React.createClass
  displayName: 'TextSection'
  mixins: [PureRenderMixin]

  render: ->
    <ContentEditableWithButtons
      actions={EtdActions}
      id={@props.cellId}
      content={@props.content}
      currentEditable= {@props.currentEditable}
      ref="contentEditable"
    />

ConclusionsSection = React.createClass
  displayName: 'ConclusionsSection'
  mixins: [PureRenderMixin]

  render: ->
    <tr>
      <td className='filled align-left'>{@props.section.get 'description'}</td>
      <td>
        {if @props.section.get('options')? or @props.section.get('optionGroups')?
          <OptionsTypeSection
            options={@props.section.get 'options'}
            optionGroups={@props.section.get 'optionGroups'}
            selectedOption={@props.section.get 'selectedOption'}
            sectionKey={@props.sectionKey}
            currentEditable={@props.currentEditable}
            content={@props.section.get 'content'}
            cellId={"#{@props.sectionKey}#conclusions"}/>
        else if @props.section.get('content')?
          <TextSection
            currentEditable={@props.currentEditable}
            content={@props.section.get 'content'}
            cellId={"#{@props.sectionKey}#conclusions"} />
        else
          throw new Error 'Unsupported conclusions section'
        }
      </td>
    </tr>

module.exports = ConclusionsSection
