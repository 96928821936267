var Chaplin, CollectionView, View, enableTraitMixin, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Chaplin = require('chaplin');

View = require('base/views/view');

mediator = require('mediator');

enableTraitMixin = require('base/enable_trait_mixin');

module.exports = CollectionView = (function(_super) {
  __extends(CollectionView, _super);

  CollectionView.prototype.getTemplateFunction = View.prototype.getTemplateFunction;

  CollectionView.prototype.disableInsertViewByIndex = true;

  CollectionView.prototype.addCollectionSizeValidation = false;

  CollectionView.prototype.itemViewOptions = null;

  function CollectionView() {
    _(this).extend(enableTraitMixin);
    CollectionView.__super__.constructor.apply(this, arguments);
  }

  CollectionView.prototype.initialize = function(options) {
    if (this.addCollectionSizeValidation) {
      this._addCollectionSizeValidation();
    }
    CollectionView.__super__.initialize.call(this, options);
    return this.enable(require('base/lib/traits/tooltips'));
  };

  CollectionView.prototype._addCollectionSizeValidation = function() {
    var markAsEmptyIfSizeIsZero;
    markAsEmptyIfSizeIsZero = (function(_this) {
      return function() {
        return _this.$el.toggleClass('empty', _this.collection.size() === 0);
      };
    })(this);
    this.modelBind('add', markAsEmptyIfSizeIsZero);
    this.modelBind('remove', markAsEmptyIfSizeIsZero);
    return markAsEmptyIfSizeIsZero();
  };

  CollectionView.prototype.getView = function(model) {
    var itemView, options;
    if (this.itemView != null) {
      options = _({
        model: model,
        parentView: this
      }).extend(this.itemViewOptions);
      itemView = new this.itemView(options);
      return itemView;
    } else {
      throw new Error('The CollectionView#itemView property must be defined (or the getView() must be overridden)');
    }
  };

  CollectionView.prototype.getItemView = function(item) {
    return this.getItemViewByCid(item.cid);
  };

  CollectionView.prototype.getItemViewByCid = function(cid) {
    return this.subview("itemView:" + cid) || (function() {
      throw new Error("CollectionView#getItemViewByCid: no view found for " + cid);
    })();
  };

  CollectionView.prototype.getItemViewByIndex = function(index) {
    return this.getItemViewByCid(this.collection.at(index).cid);
  };

  CollectionView.prototype.getSubviewByCid = function(subviewCid) {
    return _(this.subviews).findWhere({
      cid: subviewCid
    });
  };

  CollectionView.prototype.getIndexOfItemView = function(itemView) {
    return this.collection.indexOf(itemView.model);
  };

  CollectionView.prototype.insertView = function(item, view, index, enableAnimation) {
    var $viewEl, included, position, viewEl;
    if (index == null) {
      index = null;
    }
    if (enableAnimation == null) {
      enableAnimation = true;
    }
    position = typeof index === 'number' && !this.disableInsertViewByIndex ? index : typeof this.filterer === 'function' ? _(this.collection.models).filter(this.filterer).indexOf(item) : this.collection.indexOf(item);
    included = typeof this.filterer === 'function' ? this.filterer(item) : true;
    if (included) {
      viewEl = view.el;
      $viewEl = view.$el;
      if (enableAnimation) {
        if (this.useCssAnimation) {
          $viewEl.addClass('animated-item-view');
        } else {
          $viewEl.css('opacity', 0);
        }
      }
      this._insertViewElement(item, viewEl, position);
      view.trigger('addedToDOM');
      this.updateVisibleItems(item, included);
      if (enableAnimation && included) {
        if (this.useCssAnimation) {
          return setTimeout(function() {
            return $viewEl.addClass('animated-item-view-end');
          }, 0);
        } else {
          return $viewEl.animate({
            opacity: 1
          }, this.animationDuration);
        }
      }
    }
  };

  CollectionView.prototype._getItemElements = function() {
    if (this.itemSelector) {
      return this.$list.children(this.itemSelector);
    } else {
      return this.$list.children();
    }
  };

  CollectionView.prototype._insertViewElement = function(item, viewEl, position) {
    var $list, $next, $previous, children, length;
    $list = this.$list;
    children = this._getItemElements();
    if (children.get(position) === viewEl) {
      return;
    }
    length = children.length;
    if (length === 0 || position === length) {
      return $list.append(viewEl);
    } else {
      if (position === 0) {
        $next = children.eq(position);
        return $next.before(viewEl);
      } else {
        $previous = children.eq(position - 1);
        return $previous.after(viewEl);
      }
    }
  };

  CollectionView.prototype.afterRender = function() {
    CollectionView.__super__.afterRender.apply(this, arguments);
    return this._addTooltips('info', this.$('button, a, .info-sign'));
  };

  CollectionView.prototype.dispose = function() {
    this._disableTraits();
    return CollectionView.__super__.dispose.apply(this, arguments);
  };

  return CollectionView;

})(Chaplin.CollectionView);
