var ProjectActions, alt, mediator;

alt = require('alt');

mediator = require('mediator');

ProjectActions = (function() {
  function ProjectActions() {}

  ProjectActions.prototype.fetch = function() {
    return mediator.project.toJSON();
  };

  return ProjectActions;

})();

module.exports = alt.createActions(ProjectActions);
