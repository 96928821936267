var AdaptiveLayout, AuthenticationView, Exceptions, Focusable, SelectionView, Validation, View, mediator,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

SelectionView = require('base/views/selection_view');

Focusable = require('base/lib/traits/focusable');

Exceptions = require('lib/exceptions');

mediator = require('mediator');

Validation = require('base/validation');

AdaptiveLayout = require('base/lib/traits/adaptive_layout');

module.exports = AuthenticationView = (function(_super) {
  __extends(AuthenticationView, _super);

  function AuthenticationView() {
    this._forgotPasswordView = __bind(this._forgotPasswordView, this);
    return AuthenticationView.__super__.constructor.apply(this, arguments);
  }

  AuthenticationView.prototype._logoUrl = 'images/logo94.png';

  AuthenticationView.prototype.initialize = function() {
    AuthenticationView.__super__.initialize.apply(this, arguments);
    this.subscribeEvent('login', this._loggedIn);
    this.enable(Focusable);
    this.languages = window.gdt.languages;
    this.delegate('click', 'button.language-select', this._languageSelect);
    return this.enable(AdaptiveLayout);
  };

  AuthenticationView.prototype._loggedIn = function() {
    if (this.disposed) {
      return;
    }
    return this.dispose();
  };

  AuthenticationView.prototype.gainFocus = function(e) {
    e.stopPropagation();
    return mediator.setFocus(this);
  };

  AuthenticationView.prototype.getTemplateData = function() {
    return {
      loginOnUnauthorized: this.options.loginOnUnauthorized,
      logoUrl: this._logoUrl
    };
  };

  AuthenticationView.prototype._toggleLoadingState = function(button, msg) {
    if (this.disposed) {
      return;
    }
    if (button.hasClass('loading')) {
      return button.removeClass('loading').prop('disabled', false).val(button.data('previousCaption'));
    } else {
      button.data('previousCaption', button.val());
      return button.addClass('loading').prop('disabled', true).val(msg);
    }
  };

  AuthenticationView.prototype._loginView = function() {
    var LoginView;
    LoginView = require('views/login_view');
    new LoginView({
      model: mediator.user,
      authenticationService: mediator.services.authentication,
      replicationService: mediator.services.replication,
      loginOnUnauthorized: this.options.loginOnUnauthorized
    });
    return this.dispose();
  };

  AuthenticationView.prototype._forgotPasswordView = function() {
    var PasswordResetView;
    PasswordResetView = require('views/password_reset_view');
    new PasswordResetView({
      login: this.$('.login').val(),
      authenticationService: this.options.authenticationService,
      loginOnUnauthorized: this.options.loginOnUnauthorized
    });
    return this.dispose();
  };

  AuthenticationView.prototype._registrationView = function() {
    var RegistrationView;
    if (this.options.loginOnUnauthorized) {
      return this.options.authenticationService.logout().then(function() {
        return mediator.publish('!reloadApplication');
      });
    } else {
      RegistrationView = require('views/registration_view');
      new RegistrationView({
        authenticationService: this.options.authenticationService,
        loginOnUnauthorized: this.options.loginOnUnauthorized,
        allowSwitchView: false
      });
      return this.dispose();
    }
  };

  AuthenticationView.prototype._logIn = function(login, password) {
    var initialization;
    login = login != null ? login : this.$('.login').val();
    password = password != null ? password : this.$('.password').val();
    this.$('.error').empty();
    this._toggleLoadingState(this.$('.log-in'), $.t('registration.in_progress'));
    initialization = false;
    return this.options.authenticationService.login(login, password, this.options.loginOnUnauthorized).then(function(loginData) {
      return mediator.publish('login', loginData.login, loginData.status);
    }, (function(_this) {
      return function(error) {
        return _this.$('.error-shared').html(error.message);
      };
    })(this), (function(_this) {
      return function(message) {
        if (message.action === 'initialization') {
          initialization = true;
          return _this.$('.info').text($.t('registration.db_initialization.start'));
        } else if (initialization) {
          return _this.$('.info').text($.t("registration.db_initialization." + message));
        }
      };
    })(this)).ensure((function(_this) {
      return function() {
        if (!_this.disposed) {
          return _this._toggleLoadingState(_this.$('.log-in'), $.t('registration.in_progress'));
        }
      };
    })(this));
  };

  AuthenticationView.prototype._validateLogin = function(login) {
    var $error, $input, ex;
    $input = this.$('input.login');
    $error = this.$('.login.error');
    this._removeErrorMessage($input, $error);
    if (!login) {
      this._showError($input, $error, $.t('errors:authentication.empty_login'));
      return false;
    } else if (!Validation.isValidGdtLogin(login)) {
      ex = new Exceptions.authentication.invalid_login;
      this._showError($input, $error, ex.message);
      return false;
    } else {
      return true;
    }
  };

  AuthenticationView.prototype._validatePassword = function(password) {
    var $error, $input;
    $input = this.$('input.password');
    $error = this.$('.password.error');
    this._removeErrorMessage($input, $error);
    if (password.length === 0) {
      this._showError($input, $error, $.t('errors:authentication.empty_password'));
      return false;
    } else {
      return true;
    }
  };

  AuthenticationView.prototype._validateRepeatPassword = function(repeatedPassword) {
    var $error, $input, password;
    $input = this.$('input.repeat-password');
    $error = this.$('.repeat-password.error');
    this._removeErrorMessage($input, $error);
    password = this.$('input.password').val();
    if (repeatedPassword.length === 0) {
      this._showError($input, $error, $.t('errors:authentication.empty_repeat_password'));
      return false;
    } else if (repeatedPassword !== password) {
      this._showError($input, $error, $.t('registration.passwords_no_match'));
      return false;
    } else {
      return true;
    }
  };

  AuthenticationView.prototype._validateEmail = function(email) {
    var $error, $input, ex;
    $input = this.$('input.email');
    $error = this.$('.email.error');
    $error.text("");
    $input.removeClass('validation-error');
    if (!email) {
      this._showError($input, $error, $.t('errors:authentication.empty_email'));
      return false;
    } else if (!Validation.isValidEmail(email)) {
      ex = new Exceptions.authentication.invalid_email;
      this._showError($input, $error, ex.message);
      return false;
    } else {
      return true;
    }
  };

  AuthenticationView.prototype._validateTos = function(accepted) {
    var $error, $input;
    $input = this.$('input.tos');
    $error = this.$('.error.tos');
    this._removeErrorMessage($input, $error);
    if (!accepted) {
      this._showError($input, $error, $.t('errors:authentication.tos_not_accepted'));
      return false;
    } else {
      return true;
    }
  };

  AuthenticationView.prototype._removeErrorMessage = function(inputEl, errorEl) {
    inputEl.removeClass('validation-error');
    return errorEl.text("");
  };

  AuthenticationView.prototype._showError = function(inputEl, errorEl, msg) {
    inputEl.addClass('validation-error');
    return errorEl.text(msg);
  };

  AuthenticationView.prototype._getLanguageText = function(value) {
    return _.findWhere(this.languages, {
      value: value
    }).text;
  };

  AuthenticationView.prototype._languageSelect = function(e) {
    var languageSelectButton, languageSelection, offset;
    e.stopPropagation();
    mediator.currentFocus = this;
    this.delegate('click', this.gainFocus);
    languageSelectButton = this.$('button.language-select');
    languageSelection = new SelectionView({
      listItems: this.languages,
      acceptHandler: (function(_this) {
        return function(e) {
          var selectedValue;
          selectedValue = languageSelection.getSelectedItemValue();
          languageSelectButton.text(_this._getLanguageText(selectedValue));
          mediator.publish('!setLanguage', selectedValue);
          return languageSelection.hide();
        };
      })(this)
    });
    this.subview('languageSelection', languageSelection);
    offset = languageSelectButton.offset();
    offset.top = offset.top + languageSelectButton.height();
    return languageSelection.show(offset);
  };

  AuthenticationView.prototype.afterRender = function() {
    AuthenticationView.__super__.afterRender.apply(this, arguments);
    this.$el.find('.overlay').remove();
    if (this.options.loginOnUnauthorized) {
      this.$el.append("<div class='overlay overlay-" + this.className + "'}>");
      this.$('.password').focus();
    }
    window.gdt.storage.get('gdtLanguage').then((function(_this) {
      return function(lng) {
        return $('button.language-select').text(_this._getLanguageText(lng));
      };
    })(this));
    return this.trigger('!fixLayout');
  };

  AuthenticationView.prototype.fixLayout = function() {
    var cooefficient, formContainer, margin;
    formContainer = $('.authentication, .password-recovery');
    margin = $(window).height() - formContainer.outerHeight() - 70;
    cooefficient = margin > 0 ? 0.5 : 1;
    return formContainer.css('margin-top', margin * cooefficient);
  };

  return AuthenticationView;

})(View);
