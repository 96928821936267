var View, W, WelcomeCapabilitiesListView, mediator, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

template = require('./templates/welcome_capabilities');

mediator = require('mediator');

W = require('when');

module.exports = WelcomeCapabilitiesListView = (function(_super) {
  __extends(WelcomeCapabilitiesListView, _super);

  function WelcomeCapabilitiesListView() {
    this._showDescription = __bind(this._showDescription, this);
    this._openCapabilitesGroup = __bind(this._openCapabilitesGroup, this);
    return WelcomeCapabilitiesListView.__super__.constructor.apply(this, arguments);
  }

  WelcomeCapabilitiesListView.prototype.className = 'capabilities-view';

  WelcomeCapabilitiesListView.prototype.template = template;

  WelcomeCapabilitiesListView.prototype._getContent = function(type) {
    var path;
    path = "help/" + type + ".html";
    return W($.get(path, {
      contentType: 'text/html'
    }));
  };

  WelcomeCapabilitiesListView.prototype._openCapabilitesGroup = function(e) {
    var content;
    content = $(e.target).siblings('ul');
    if (!content.length) {
      return;
    }
    this.$('#main-menu > li button').removeClass('selected');
    $(e.target).addClass('selected');
    return this.$('#submenu').html(content.clone());
  };

  WelcomeCapabilitiesListView.prototype._enlargePicture = function(e) {
    var $img;
    $img = $(e.target).find('img').clone();
    $img.removeAttr('height');
    $img.removeAttr('width');
    $img.css('max-height', $(document).height() - 50);
    $img.css('max-width', $(document).width() - 50);
    return mediator.dialogs.infoBox($img, {
      position: 'top'
    });
  };

  WelcomeCapabilitiesListView.prototype._showDescription = function(e) {
    var target, type;
    target = $(e.target);
    type = target.data('type');
    return this._getContent(type).then((function(_this) {
      return function(content) {
        var dialog;
        dialog = mediator.dialogs.infoBox(null, {
          title: target.text(),
          message: content,
          width: 700,
          position: 'top',
          offsetY: 50,
          hideOnClick: false
        });
        dialog.$el.find('.thumbnail').on('click', _this._enlargePicture);
        return dialog.$el.find('img').load(function() {
          var height;
          height = Math.min($(window).height() - 100, dialog.$el.find('.notifier-inner').outerHeight());
          return dialog.$el.find('.notifier-inner').css({
            'height': height
          });
        });
      };
    })(this));
  };

  WelcomeCapabilitiesListView.prototype.initialize = function() {
    WelcomeCapabilitiesListView.__super__.initialize.apply(this, arguments);
    this.delegate('mouseenter', '#main-menu button', this._openCapabilitesGroup);
    return this.delegate('click', '#submenu button', this._showDescription);
  };

  return WelcomeCapabilitiesListView;

})(View);
