EtdTemplatesStore = require 'stores/etd_templates_store'
EtdTemplatesActions = require 'actions/etd_templates_actions'
BaseTemplateSelection = require 'components/administration/base_template_selection'
SelectedTemplate = require 'components/administration/selected_template'
Translation = require 'components/mixins/translation'
TemplateSectionsSelection = require 'components/administration/template_sections_selection'
PureRenderMixin = React.addons.PureRenderMixin
CreateStoreMixin = require '../mixins/create_store'
Spinner = require 'components/common/spinner'
FetchingSpinner = require 'components/mixins/fetching_spinner'

EtdTemplates = React.createClass
  displayName: 'EtdTemplates'

  mixins: [
    PureRenderMixin
    Translation('administration:etd_templates')
    CreateStoreMixin(EtdTemplatesStore)
    FetchingSpinner
  ]

  getInitialState: ->
    templateId: ''

  getStateFromStores: ->
    template: EtdTemplatesStore.getTemplate()
    txTemplate: EtdTemplatesStore.getTemplate('tx')
    dxTemplate: EtdTemplatesStore.getTemplate('dx')
    editingTemplate: EtdTemplatesStore.getEditingTemplate()
    templateName: EtdTemplatesStore.getTemplateName()
    txTemplateName: EtdTemplatesStore.getTemplateName('tx')
    dxTemplateName: EtdTemplatesStore.getTemplateName('dx')
    templateChanged: EtdTemplatesStore.isTemplateChanged()
    isFetching: EtdTemplatesStore.isFetching()

  changeBaseTemplate: (templateId) ->
    EtdTemplatesActions.prepareTemplate templateId if templateId
    @setState {templateId}

  useCurrentTemplate: ->
    EtdTemplatesActions.useTemplate @state.template.get('templateDef'),
      @state.template.get('templateData')

  isTemplateDefined: (templateType) ->
    switch templateType
      when 'tx'
        @state.txTemplate.size > 0
      when 'dx'
        @state.dxTemplate.size > 0

  editTemplate: (templateType) ->
    template = @state["#{templateType}Template"]
    EtdTemplatesActions.prepareTemplate template
    EtdTemplatesActions.editTemplate templateType

  renderFetched: ->
    txTemplateDefined = @isTemplateDefined 'tx'
    dxTemplateDefined = @isTemplateDefined 'dx'
    <div className='etd-templates'>
      {
        if not txTemplateDefined and not dxTemplateDefined
          <p>{@i18n 'etd_templates_info'}</p>
          <p>{@i18n 'define_template_text'}</p>
        else
          commonProps =
            editTemplate: @editTemplate
            template: @state.template
            templateChanged: @state.templateChanged
            baseTemplateName: @state.templateName
            editingTemplate: @state.editingTemplate
            changeBaseTemplate: @changeBaseTemplate

          <div>
            <SelectedTemplate
              templateType='tx'
              templateName={@state.txTemplateName}
              {...commonProps}/>
            <SelectedTemplate
              templateType='dx'
              templateName={@state.dxTemplateName}
              {...commonProps}/>
          </div>
      }
      {unless @state.editingTemplate
        <BaseTemplateSelection
          showEmptyValue={true}
          listTxTemplates={not txTemplateDefined}
          listDxTemplates={not dxTemplateDefined}
          changeBaseTemplate={@changeBaseTemplate}/>
      }
      {if @state.templateId and @state.template.size > 0 and not @state.editingTemplate
        <TemplateSectionsSelection
          template={@state.template}
          templateName={@state.templateName}
          nameEditable={@state.templateChanged}
          templateChanged={@state.templateChanged}
          useCurrentTemplate={@useCurrentTemplate}
          changeBaseTemplate={@changeBaseTemplate}
          autoUpdateName={true} />
      }
    </div>

module.exports = EtdTemplates
