var Model, Request, UserSessions,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Model = require('models/base/model');

Request = require('models/request');

module.exports = UserSessions = (function(_super) {
  __extends(UserSessions, _super);

  function UserSessions() {
    return UserSessions.__super__.constructor.apply(this, arguments);
  }

  UserSessions.prototype.saveRoute = function(controller, action, params, options) {
    return this.save('lastRoute', {
      route: {
        controller: controller,
        action: action
      },
      params: params,
      options: options
    });
  };

  UserSessions.prototype.getLastRoute = function() {
    return this.get('lastRoute');
  };

  return UserSessions;

})(Model);
