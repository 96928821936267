var DesignStudy, Entity, obsStudiesList, obsStudiesMap,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Entity = require('base/models/entity');

DesignStudy = (function(_super) {
  __extends(DesignStudy, _super);

  function DesignStudy() {
    return DesignStudy.__super__.constructor.apply(this, arguments);
  }

  return DesignStudy;

})(Entity);

obsStudiesMap = {
  'treatment': {
    'interrupted_time_series': 2,
    'before_after_studies': 2,
    'cohort_studies': 2,
    'case_control_studies': 2,
    'cross_sectional_studies': 2,
    'case_series': 2,
    'case_reports': 2,
    'case_control_and_other_combined': 2,
    'other_design': 2
  },
  'diagnostic': {
    'cross_sectional_cohort_type': 4,
    'case_control_type_accuracy': 4,
    'cohort_and_case_control_type': 4
  },
  'prognostic': {
    'single_arm_studies': 2
  }
};

obsStudiesList = _(obsStudiesMap.treatment).keys().concat(_(obsStudiesMap.diagnostic).keys());

module.exports = {
  ObsStudies: new DesignStudy({
    name: 'ObsStudies',
    label: 'observational_studies',
    baseScore: 2,
    values: obsStudiesList
  }),
  RandTrials: new DesignStudy({
    name: 'RandTrials',
    label: 'randomised_trials',
    baseScore: 4
  }),
  ObsStudiesMap: (function() {
    var baseScore, ds, map, obsStudyKey, questionType, _ref;
    map = {};
    for (questionType in obsStudiesMap) {
      _ref = obsStudiesMap[questionType];
      for (obsStudyKey in _ref) {
        baseScore = _ref[obsStudyKey];
        ds = new DesignStudy({
          name: 'ObsStudies',
          label: questionType === 'diagnostic' || questionType === 'prognostic' ? obsStudyKey : 'observational_studies',
          baseScore: baseScore,
          values: obsStudiesList
        });
        ds.value = obsStudyKey;
        ds.fullName = "" + ds.name + "." + obsStudyKey;
        map[obsStudyKey] = ds;
      }
    }
    return map;
  })(),
  get: function(name, value) {
    if (value) {
      return this["" + name + "Map"][value];
    } else {
      return this[name];
    }
  }
};
