var AdaptiveLayout, DbHelper, MobilePreviewView, ModalView, W, mediator, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModalView = require('base/views/modal_view');

DbHelper = require('base/lib/db_helper');

AdaptiveLayout = require('base/lib/traits/adaptive_layout');

template = require('views/templates/dissemination/mobile_preview');

mediator = require('mediator');

W = require('when');

module.exports = MobilePreviewView = (function(_super) {
  __extends(MobilePreviewView, _super);

  function MobilePreviewView() {
    this._enableEditMode = __bind(this._enableEditMode, this);
    this._publishPreview = __bind(this._publishPreview, this);
    return MobilePreviewView.__super__.constructor.apply(this, arguments);
  }

  MobilePreviewView.prototype.className = 'mobile-preview-view';

  MobilePreviewView.prototype.template = template;

  MobilePreviewView.prototype.title = $.t('dissemination:publish.mobile_preview');

  MobilePreviewView.prototype.shortcuts = {
    'esc': 'dispose'
  };

  MobilePreviewView.prototype.initialize = function() {
    this.options.position = 'center';
    this.options.offsetY = 0;
    this.options.fadeInMs = 0;
    this.options.fadeOutMs = 0;
    MobilePreviewView.__super__.initialize.apply(this, arguments);
    this.delegate('click', 'button.close', this.dispose);
    this.delegate('click', 'button.send-preview', _(this._publishPreview).throttle(500));
    this.delegate('click', 'button.edit-question', function() {
      var $iframe, originalEtdFields;
      $iframe = this.$('#preview-content');
      $iframe[0].contentWindow.gdt.startEditMode();
      originalEtdFields = mediator.services.mobilePublication.originalEtdFields(mediator.questions.get(this.currentQuestionId));
      this.$('.rationale').html(originalEtdFields.remarks);
      this.$('.benefitsAndHarms').html(originalEtdFields.benefitsAndHarms);
      this.$el.toggleClass('edit-mode', true);
      this.$('button.apply-changes').toggleClass('hidden', false);
      return this.$('button.edit-question').toggleClass('hidden', true);
    });
    this.delegate('click', 'button.apply-changes', function() {
      var $iframe, gdtFunctions;
      $iframe = this.$('#preview-content');
      gdtFunctions = $iframe[0].contentWindow.gdt;
      gdtFunctions.finishEditMode(function(data) {
        return mediator.questions.get(data.questionId).save({
          remarksForMobile: data.remarks,
          benefitsAndHarmsForMobile: data.benefitsAndHarms
        }).then(function() {
          return mediator.services.mobilePublication["export"](mediator.project);
        }).then(gdtFunctions.init);
      });
      this.$el.toggleClass('edit-mode', false);
      this.$('button.apply-changes').toggleClass('hidden', true);
      return this.$('button.edit-question').toggleClass('hidden', false);
    });
    this.exporting = mediator.services.mobilePublication["export"](mediator.project)["catch"](function(e) {
      return mediator.dialogs.fatalError(e);
    });
    return this.enable(AdaptiveLayout);
  };

  MobilePreviewView.prototype._publishPreview = function() {
    return mediator.services.mobilePublication.publishPreview(mediator.project).then((function(_this) {
      return function(url) {
        _this.mobilePreviewUrl = url;
        return _this.render();
      };
    })(this))["catch"](function(e) {
      return mediator.dialogs.fatalError(e);
    });
  };

  MobilePreviewView.prototype._enableEditMode = function(questionId) {
    this.currentQuestionId = questionId;
    this.$('button.edit-question').toggleClass('hidden', questionId == null);
    if (questionId == null) {
      this.$el.toggleClass('edit-mode', false);
      return this.$('button.apply-changes').toggleClass('hidden', true);
    }
  };

  MobilePreviewView.prototype.afterRender = function() {
    var $iframe, initData, innerHeight;
    this.fixLayout();
    MobilePreviewView.__super__.afterRender.apply(this, arguments);
    if (this.mobilePreviewUrl) {
      this.$('#qrcode').qrcode(this.mobilePreviewUrl);
      innerHeight = this.$el.parents('.notifier-inner').outerHeight();
      return this.$el.parents('.notifier').css('margin-top', innerHeight / -2);
    } else {
      $iframe = this.$('#preview-content');
      initData = (function(_this) {
        return function() {
          var gdtFunctions;
          gdtFunctions = $iframe[0].contentWindow.gdt;
          if (gdtFunctions.init) {
            gdtFunctions.subscribeQuestionChanged(_this._enableEditMode);
            return _this.exporting.then(gdtFunctions.init);
          } else {
            return setTimeout(initData, 50);
          }
        };
      })(this);
      return $iframe.on('load', initData);
    }
  };

  MobilePreviewView.prototype.getTemplateData = function() {
    return {
      mobilePreviewUrl: this.mobilePreviewUrl,
      previewUrl: DbHelper.getWebPreviewUrl()
    };
  };

  MobilePreviewView.prototype.fixLayout = function() {
    var availableHeight, notifierContainer, _base;
    availableHeight = window.innerHeight;
    notifierContainer = this.$el.parents('.notifier-inner');
    if ((_base = this.fixLayout).defaultHeight == null) {
      _base.defaultHeight = notifierContainer.outerHeight(true);
    }
    if (availableHeight < this.fixLayout.defaultHeight) {
      return notifierContainer.css({
        'max-height': availableHeight,
        'overflow-y': 'auto'
      });
    } else {
      return notifierContainer.css({
        'max-height': '',
        'overflow-y': ''
      });
    }
  };

  return MobilePreviewView;

})(ModalView);
