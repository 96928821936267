var Entity, QualityScore,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Entity = require('base/models/entity');

QualityScore = (function(_super) {
  __extends(QualityScore, _super);

  function QualityScore() {
    return QualityScore.__super__.constructor.apply(this, arguments);
  }

  return QualityScore;

})(Entity);

module.exports = {
  ReducedForRR: new QualityScore({
    name: 'ReducedForRR',
    label: 'reduced_for_RR',
    score: 1
  }),
  IncreasedForRR: new QualityScore({
    name: 'IncreasedForRR',
    label: 'increased_for_RR',
    score: 1
  }),
  VeryLarge: new QualityScore({
    name: 'VeryLarge',
    label: 'very_strong_association',
    score: 2
  }),
  Large: new QualityScore({
    name: 'Large',
    label: 'strong_association',
    score: 1
  }),
  None: new QualityScore({
    name: 'None',
    label: 'not_serious',
    score: 0
  }),
  Yes: new QualityScore({
    name: 'Yes',
    label: 'yes',
    score: 1
  }),
  No: new QualityScore({
    name: 'No',
    label: 'no',
    score: 0
  }),
  Serious: new QualityScore({
    name: 'Serious',
    label: 'serious',
    score: -1
  }),
  VerySerious: new QualityScore({
    name: 'VerySerious',
    label: 'very_serious',
    score: -2
  }),
  get: function(name) {
    return this[name];
  }
};
