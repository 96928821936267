var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"questions-view\">\n  <ul class=\"standard-list\"></ul>\n</div>\n<div class=\"outcomes-table-container\">\n  <table class=\"standard-table outcomes-table\">\n    <thead>\n      <tr class=\"outcome-sof-row\">\n        <th rowspan=\"2\" class=\"outcome-cell\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "outcome", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th colspan=\"2\" class=\"regular-header\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "anticipated_absolute_effects", {"name":"i18n","hash":{},"data":data})))
    + " (95% CI)</th>\n        <th rowspan=\"2\" class=\"relative-effect regular-header\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "relative_effect", {"name":"i18n","hash":{},"data":data})))
    + " (95% CI)\n        </th>\n        <th colspan=\"3\" rowspan=\"2\" class=\"impact narrative-header\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "impact", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th rowspan=\"2\" class=\"no-of-participants\">\n          ";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "no_of_participants", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += " <br>\n          ("
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "studies", {"name":"i18n","hash":{},"data":data})))
    + ")\n        </th>\n        <th rowspan=\"2\" class=\"quality\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "quality", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th rowspan=\"2\" class=\"comments regular-header\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "comments", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th rowspan=\"2\" class=\"table-mode\">\n          <button class=\"table-mode\"></button>\n        </th>\n      </tr>\n      <tr class=\"outcome-sof-row\">\n        <th class=\"control regular-header\"></th>\n        <th class=\"corresponding-risk regular-header\"></th>\n      </tr>\n    </thead>\n\n    <tbody></tbody>\n\n  </table>\n\n  <div class=\"row mt-10\">\n";
  stack1 = this.invokePartial(partials.outcomeButtons, '    ', 'outcomeButtons', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </div>\n</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}