Translation = require 'components/mixins/translation'
SummaryOfJudgementsSection = require 'components/etd/soj_section'

SummaryOfJudgements = React.createClass
  mixins: [Translation('es:recommendations.table')]

  render: ->
    <table className="summary-of-judgements standard-table soj-table">
      <thead>
        <th>{@i18n 'criteria'}</th>
        <th>{@i18n 'summary_of_judgements'}</th>
        <th className="favours-header">
          {@i18n 'favours_comparison_header'} {@props.comparison}</th>
        <th className="favours-header">
          {@i18n 'favours_intervention_header'} {@props.intervention}</th>
        <th className="importance-header">{@i18n 'importance_for_decision'}</th>
      </thead>
      <tbody>
        {@props.sections.map (section, key) =>
          <SummaryOfJudgementsSection key={key} sectionKey={key} section={section}/>
        .toList()}
      </tbody>
    </table>

module.exports = SummaryOfJudgements
