var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"no-of-participants-embeded "
    + escapeExpression(((helper = (helper = helpers.viewTypeName || (depth0 != null ? depth0.viewTypeName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"viewTypeName","hash":{},"data":data}) : helper)))
    + "\">\n  <div class=\"edit-input-block form-group row mt-10\" data-property=\"designStudies\">\n    <label for=\"designStudies\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "study_design", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n    <div class=\"inline\">\n      "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "designStudies", ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.name : stack1), "RandTrials: randomised_trials, ObsStudies: observational_studies", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n    </div>\n    <div class=\"sup\"></div>\n    <div class=\"observational-studies-list\">\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.name : stack1), "ObsStudies", {"name":"is","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </div>\n  </div>\n  <div class=\"edit-input-block form-group row mt-10\">\n    <label for=\"noOfStudies\">";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "no_of_studies", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</label>\n    <input type=\"text\" name=\"noOfStudies\" class=\"form-control\"\n     id=\"noOfStudies\" value=\""
    + escapeExpression(((helper = (helper = helpers.noOfStudies || (depth0 != null ? depth0.noOfStudies : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"noOfStudies","hash":{},"data":data}) : helper)))
    + "\" >\n    <div class=\" sup\"></div>\n  </div>\n  <div class=\"case-control-block\">\n";
  stack1 = this.invokePartial(partials.caseControl, '    ', 'caseControl', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </div>\n  <div class=\"control-block\">\n    <div class=\"edit-input-block form-group row mt-10\">\n      <label for=\"interventionTotal\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "intervention_total", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n      <input type=\"text\" name=\"interventionTotal\" class=\"form-control\"\n        id=\"interventionTotal\" value=\""
    + escapeExpression(((helper = (helper = helpers.interventionTotal || (depth0 != null ? depth0.interventionTotal : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"interventionTotal","hash":{},"data":data}) : helper)))
    + "\" >\n      <div class=\" sup\"></div>\n    </div>\n    <div class=\"edit-input-block form-group row mt-10\">\n      <label for=\"controlTotal\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "control_total", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n      <input type=\"text\" name=\"controlTotal\" class=\"form-control\"\n        id=\"controlTotal\" value=\""
    + escapeExpression(((helper = (helper = helpers.controlTotal || (depth0 != null ? depth0.controlTotal : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"controlTotal","hash":{},"data":data}) : helper)))
    + "\" >\n      <div class=\" sup\"></div>\n    </div>\n  </div>\n</div>\n";
},"2":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials.obsStudiesSelection, '        ', 'obsStudiesSelection', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "/es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}