var CollectionView, DraftQuestion, DraftQuestionView, Focusable, NextStepButton, QuestionsInitialDraftView, Scrolling, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

CollectionView = require('base/views/collection_view');

DraftQuestion = require('base/models/draft_question');

DraftQuestionView = require('views/scope/draft_question_view');

NextStepButton = require('views/scope/next_step_button_view');

Focusable = require('base/lib/traits/focusable');

Scrolling = require('base/lib/traits/scrolling');

template = require('views/templates/scope/initial_draft');

mediator = require('mediator');

module.exports = QuestionsInitialDraftView = (function(_super) {
  __extends(QuestionsInitialDraftView, _super);

  function QuestionsInitialDraftView() {
    return QuestionsInitialDraftView.__super__.constructor.apply(this, arguments);
  }

  QuestionsInitialDraftView.prototype.className = 'questions-initial-draft';

  QuestionsInitialDraftView.prototype.template = template;

  QuestionsInitialDraftView.prototype.container = '#page-container';

  QuestionsInitialDraftView.prototype.itemView = DraftQuestionView;

  QuestionsInitialDraftView.prototype.animationDuration = 10;

  QuestionsInitialDraftView.prototype.listSelector = 'ol';

  QuestionsInitialDraftView.prototype.initialize = function() {
    this.itemViewOptions = {
      preview: this.options.preview
    };
    QuestionsInitialDraftView.__super__.initialize.apply(this, arguments);
    this.enable(Focusable);
    this.enable(Scrolling, {
      fixedElements: 'menu.buttons, div.bottom-bar'
    });
    this.delegate('click', 'button.add-management-question', this._addQuestion('management'));
    return this.delegate('click', 'button.add-diagnostic-question', this._addQuestion('diagnostic'));
  };

  QuestionsInitialDraftView.prototype._addQuestion = function(type) {
    return (function(_this) {
      return function() {
        return mediator.setFocus(_this, function() {
          return _this.collection.add(new DraftQuestion({
            type: type,
            source: 'initial'
          }));
        });
      };
    })(this);
  };

  QuestionsInitialDraftView.prototype.filterer = function(draftQuestion) {
    return draftQuestion.get('source') === 'initial';
  };

  QuestionsInitialDraftView.prototype.getTemplateData = function() {
    return _(QuestionsInitialDraftView.__super__.getTemplateData.apply(this, arguments)).extend({
      preview: this.options.preview
    });
  };

  QuestionsInitialDraftView.prototype.afterRender = function() {
    QuestionsInitialDraftView.__super__.afterRender.apply(this, arguments);
    return this.subview('next-step', new NextStepButton({
      step: 'questions.initial',
      container: this.$('.bottom-bar'),
      getItemsToValidate: (function(_this) {
        return function() {
          return _this.getItemViews();
        };
      })(this)
    }));
  };

  return QuestionsInitialDraftView;

})(CollectionView);
