var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "not-editable-cell";
  },"3":function(depth0,helpers,partials,data) {
  return "    <div class=\"inner-cell\">\n      <div class=\"cell-content main-content\">\n        <span>\n\n        </span>\n      </div>\n    </div>\n";
  },"5":function(depth0,helpers,partials,data) {
  return "    <div class=\"inner-cell\">\n      <div class=\"cell-content main-content\">\n        <span>\n\n        </span>\n      </div>\n    </div>\n\n    <div class=\"inner-cell\">\n      <div class=\"cell-content low-value\">\n        <span>\n\n        </span>\n      </div>\n    </div>\n\n    <div class=\"inner-cell\">\n      <div class=\"cell-content moderate-value\">\n        <span>\n\n        </span>\n      </div>\n    </div>\n\n    <div class=\"inner-cell\">\n      <div class=\"cell-content high-value\">\n        <span>\n\n        </span>\n      </div>\n    </div>\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<td "
    + escapeExpression(((helper = (helper = helpers.colNo || (depth0 != null ? depth0.colNo : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"colNo","hash":{},"data":data}) : helper)))
    + "\ndata-property=\"correspondingRisk\"\ndata-property-type=\"cont\"\nclass=\"corresponding-risk lightgray-cell ";
  stack1 = ((helpers.isnt || (depth0 && depth0.isnt) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "cont", {"name":"isnt","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "cont", {"name":"is","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "dich", {"name":"is","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</td>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}