var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<TR>\n  <TD COLSPAN=\""
    + escapeExpression(((helper = (helper = helpers.colsNo || (depth0 != null ? depth0.colsNo : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"colsNo","hash":{},"data":data}) : helper)))
    + "\">\n    <P>\n      "
    + escapeExpression(((helpers.capTag || (depth0 && depth0.capTag) || helperMissing).call(depth0, "html: es:outcome.printout.asterisk_text", {"name":"capTag","hash":{},"data":data})))
    + "\n      <BR />\n      <BR />\n      "
    + escapeExpression(((helpers.capTag || (depth0 && depth0.capTag) || helperMissing).call(depth0, "html: es:outcome.printout.ci_rr_or", {"name":"capTag","hash":{},"data":data})))
    + "\n    </P>\n  </TD>\n</TR>\n<TR>\n  <TD COLSPAN=\""
    + escapeExpression(((helper = (helper = helpers.colsNo || (depth0 != null ? depth0.colsNo : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"colsNo","hash":{},"data":data}) : helper)))
    + "\">\n    <P>\n      "
    + escapeExpression(((helpers.capTag || (depth0 && depth0.capTag) || helperMissing).call(depth0, "html: es:outcome.printout.qualities_legend", {"name":"capTag","hash":{},"data":data})))
    + "\n    </P>\n  </TD>\n</TR>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}