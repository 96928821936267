var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "disabled";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<th colspan=\""
    + escapeExpression(((helper = (helper = helpers.colspan || (depth0 != null ? depth0.colspan : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"colspan","hash":{},"data":data}) : helper)))
    + "\" class=\"outcome-edit-sof-row\">\n  <div class=\"outcome-edit-block\">\n\n    <div class=\"outcome-toggleable-editor\">\n      <div class=\"outcome-edit row mt-10\">\n        <div class=\"outcome-sort-delete\">\n          <div>\n            <button class=\"sort\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.sort", {"name":"i18n","hash":{},"data":data})))
    + "' tabindex=\"-1\"/>\n          </div>\n          <div>\n            <button class=\"delete\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.delete", {"name":"i18n","hash":{},"data":data})))
    + "' tabindex=\"-1\"></button>\n          </div>\n        </div>\n        <div class=\"edit-container col-11\">\n          <div>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.type", {"name":"i18n","hash":{},"data":data})))
    + "</div>\n\n          <div class=\"outcome-type dich-cont col-2 bordered\">\n            <div class=\"box-bordered\">\n              <ul>\n                <li>\n                  <label class=\"radio-label\">\n                  <input type=\"radio\" data-property=\"type\" name=\"type"
    + escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cid","hash":{},"data":data}) : helper)))
    + "\"\n                    "
    + escapeExpression(((helpers.checkedIfEq || (depth0 && depth0.checkedIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "dich", {"name":"checkedIfEq","hash":{},"data":data})))
    + ">\n                    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.dich", {"name":"i18n","hash":{},"data":data})))
    + "\n                </label></li>\n                <li><label class=\"radio-label ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isCaseControl : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n                  <input type=\"radio\" data-property=\"type\" name=\"type"
    + escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cid","hash":{},"data":data}) : helper)))
    + "\"\n                    "
    + escapeExpression(((helpers.checkedIfEq || (depth0 && depth0.checkedIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "cont", {"name":"checkedIfEq","hash":{},"data":data})))
    + " ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isCaseControl : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n                    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.cont", {"name":"i18n","hash":{},"data":data})))
    + "\n                </label></li>\n                <li><label class=\"radio-label\"><input type=\"radio\" data-property=\"type\"\n                  name=\"type"
    + escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cid","hash":{},"data":data}) : helper)))
    + "\" "
    + escapeExpression(((helpers.checkedIfEq || (depth0 && depth0.checkedIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "narrative", {"name":"checkedIfEq","hash":{},"data":data})))
    + ">\n                  "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.narrative", {"name":"i18n","hash":{},"data":data})))
    + "\n                </label></li>\n              </ul>\n            </div>\n          </div>\n          <div class=\"outcome-type meta col-4\">\n            <div class=\"box-bordered\">\n              <ul class=\"horizontal\">\n                <li><label class=\"radio-label\">\n                  <input type=\"radio\" data-property=\"metaType\" name=\"metaType"
    + escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cid","hash":{},"data":data}) : helper)))
    + "\"\n                    "
    + escapeExpression(((helpers.checkedIfEq || (depth0 && depth0.checkedIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.metaType : depth0), "POOLED", {"name":"checkedIfEq","hash":{},"data":data})))
    + ">\n                    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.pooled", {"name":"i18n","hash":{},"data":data})))
    + "\n                </label></li>\n                <li><label class=\"radio-label\">\n                  <input type=\"radio\" data-property=\"metaType\" name=\"metaType"
    + escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cid","hash":{},"data":data}) : helper)))
    + "\"\n                    "
    + escapeExpression(((helpers.checkedIfEq || (depth0 && depth0.checkedIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.metaType : depth0), "SINGLE_STUDY", {"name":"checkedIfEq","hash":{},"data":data})))
    + ">\n                    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.single_study", {"name":"i18n","hash":{},"data":data})))
    + "\n                </label></li>\n                <li><label class=\"radio-label\">\n                  <input type=\"radio\" data-property=\"metaType\" name=\"metaType"
    + escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cid","hash":{},"data":data}) : helper)))
    + "\"\n                    "
    + escapeExpression(((helpers.checkedIfEq || (depth0 && depth0.checkedIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.metaType : depth0), "NOT_POOLED", {"name":"checkedIfEq","hash":{},"data":data})))
    + ">\n                    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.not_pooled", {"name":"i18n","hash":{},"data":data})))
    + "\n                </label></li>\n                <li><label class=\"radio-label\">\n                  <input type=\"radio\" data-property=\"metaType\" name=\"metaType"
    + escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cid","hash":{},"data":data}) : helper)))
    + "\"\n                    "
    + escapeExpression(((helpers.checkedIfEq || (depth0 && depth0.checkedIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.metaType : depth0), "NOT_MEASURED", {"name":"checkedIfEq","hash":{},"data":data})))
    + ">\n                    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.not_measured", {"name":"i18n","hash":{},"data":data})))
    + "\n                </label></li>\n                <li><label class=\"radio-label\">\n                  <input type=\"radio\" data-property=\"metaType\" name=\"metaType"
    + escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cid","hash":{},"data":data}) : helper)))
    + "\"\n                    "
    + escapeExpression(((helpers.checkedIfEq || (depth0 && depth0.checkedIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.metaType : depth0), "RANGE_OF_EFFECTS", {"name":"checkedIfEq","hash":{},"data":data})))
    + ">\n                    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.range_of_effects", {"name":"i18n","hash":{},"data":data})))
    + "\n                </label></li>\n                <li><label class=\"radio-label\">\n                  <input type=\"radio\" data-property=\"metaType\" name=\"metaType"
    + escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cid","hash":{},"data":data}) : helper)))
    + "\"\n                    "
    + escapeExpression(((helpers.checkedIfEq || (depth0 && depth0.checkedIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.metaType : depth0), "NOT_REPORTED", {"name":"checkedIfEq","hash":{},"data":data})))
    + ">\n                    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.not_reported", {"name":"i18n","hash":{},"data":data})))
    + "\n                </label></li>\n              </ul>\n            </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</th>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}