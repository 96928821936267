ContentEditableWithInsert = require 'components/common/content_editable_with_insert'
SectionCell = require 'components/etd/section_cell'
EtdActions = require 'actions/etd_actions'
PureRenderMixin = React.addons.PureRenderMixin

ResearchEvidence = React.createClass
  displayName: 'ResearchEvidenceComponent'
  mixins: [PureRenderMixin]

  getInsertOptions: ->
    if @props.questionType is 'tx'
      ['relative_importance', 'sof_short', 'sof_v1', 'sof_v2', 'sof_v3', 'image']
    else
      ['evidence_short', 'l1_sof', 'l2_sof', 'test_accuracy', 'image']

  render: ->
    <SectionCell rowSpan={@props.rowSpan}>
      <ContentEditableWithInsert
        actions={EtdActions}
        attachments={@props.attachments}
        id={@props.cellId}
        content={@props.researchEvidence.get 'content'}
        currentEditable={@props.currentEditable}
        insertOptions={@getInsertOptions()}
        ref="contentEditable"
      />
    </SectionCell>

module.exports = ResearchEvidence
