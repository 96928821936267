var AuthenticationView, Focusable, PasswordResetView, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

AuthenticationView = require('views/authentication_view');

template = require('./templates/password_reset');

mediator = require('mediator');

Focusable = require('base/lib/traits/focusable');

module.exports = PasswordResetView = (function(_super) {
  __extends(PasswordResetView, _super);

  function PasswordResetView() {
    return PasswordResetView.__super__.constructor.apply(this, arguments);
  }

  PasswordResetView.prototype.template = template;

  PasswordResetView.prototype.className = 'password-reset-view';

  PasswordResetView.prototype.container = 'body';

  PasswordResetView.prototype.autoRender = true;

  PasswordResetView.prototype._success = false;

  PasswordResetView.prototype.initialize = function() {
    PasswordResetView.__super__.initialize.apply(this, arguments);
    if (this.options.loginOnUnauthorized) {
      this.enable(Focusable);
      mediator.setFocus(this);
      this.delegate('click', this.gainFocus);
    }
    this.delegate('submit', 'form', function() {
      this._requestPasswordReset();
      return false;
    });
    return this.delegate('click', 'button.back-to-login-switch', this._loginView);
  };

  PasswordResetView.prototype.getTemplateData = function() {
    return {
      loginOrEmail: this.options.loginOrEmail,
      success: this._success
    };
  };

  PasswordResetView.prototype._requestPasswordReset = function() {
    var $error, $loginOrEmail, className, loginOrEmail, request, restoreByEmail;
    $loginOrEmail = this.$('.login-or-email');
    $error = this.$('.error.login-or-email-error');
    $error.text("");
    loginOrEmail = $loginOrEmail.val();
    if (!(loginOrEmail != null ? loginOrEmail.trim() : void 0)) {
      this._showError($loginOrEmail, $error, $.t('errors:authentication.empty_login_or_email'));
      return false;
    }
    restoreByEmail = __indexOf.call(loginOrEmail, "@") >= 0;
    className = restoreByEmail ? 'email' : 'login';
    $loginOrEmail.addClass(className);
    $error.addClass(className);
    request = restoreByEmail && this._validateEmail(loginOrEmail) ? this.options.authenticationService.resetPasswordForEmail(loginOrEmail) : this._validateLogin(loginOrEmail) ? this.options.authenticationService.resetPasswordForLogin(loginOrEmail) : void 0;
    $loginOrEmail.removeClass(className);
    $error.removeClass(className);
    if (!request) {
      return;
    }
    this._toggleLoadingState(this.$('input[type=submit]'), $.t('messages.please_wait'));
    return request.then((function(_this) {
      return function() {
        _this._success = true;
        return _this.render();
      };
    })(this), (function(_this) {
      return function(error) {
        return _this.$('.error-shared').text(error.message);
      };
    })(this)).ensure((function(_this) {
      return function() {
        if (!_this.disposed) {
          return _this._toggleLoadingState(_this.$('input[type=submit]'), $.t('messages.please_wait'));
        }
      };
    })(this));
  };

  PasswordResetView.prototype.afterRender = function() {
    PasswordResetView.__super__.afterRender.apply(this, arguments);
    return this.$('input.login-or-email').focus();
  };

  return PasswordResetView;

})(AuthenticationView);
