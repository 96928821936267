HeaderTitleRow = require 'components/etd/etd_header_title_row'
HeaderRow = require 'components/etd/etd_header_row'
EtdActions = require 'actions/etd_actions'
utils = require 'base/lib/utils'
PureRenderMixin = React.addons.PureRenderMixin

Header = React.createClass
  displayName: 'EtdHeaderComponent'
  mixins: [PureRenderMixin]
  propTyps:
    headerSections: React.PropTypes.object.isRequired

  # secondColSections hash describes sections we need to put in one row (like 'Background' next to
  # 'Patients'). [key] is the name of the section owning the row (left most cell contains the
  # 'owner' section), 'value' - is the name of the section we want to put in the second column.
  # Using Immutable here just for its convenient API.
  #
  # TODO: create possibility to specify how many rows the secondColSection should occupy, as well
  # as add possibility to add more than one secondColSection to one 'master' row.
  secondColSections: Immutable.fromJS 'population': 'background'

  getInitialState: ->
    expanded: true
    editing: false

  enableEdit: ->
    @setState editing: true

  handleUserInput: (inputDetails) ->
    EtdActions.updateHeaderFieldContent inputDetails

  handleSave: ->
    EtdActions.saveHeaderData()
    @setState editing: false

  _getFieldsContent: ->
    fieldsContent = {}
    _(fieldsContent).extend refObj.getFieldData() for refName, refObj of @refs
    fieldsContent

  toggleExpand: ->
    @setState expanded: not @state.expanded

  _getQuestionAttrName: (sectionId) ->
    header2questionAttrMap =
      'population': 'healthProblemOrPopulation'
      'setting': 'settings'
      'intervention_tx': 'intervention'
      'comparison_tx': 'comparison'
      'intervention_dx': 'indexTest'
      'comparison_dx': 'comparatorTest'

    header2questionAttrMap[sectionId]

  _getDefaultContent: (sectionId) ->
    if sectionId is 'mainOutcomes'
      @_getMainOutcomesString()
    else
      if sectionId in ['intervention', 'comparison']
        sectionId = "#{sectionId}_#{@props.questionType}"
      defaultContent = @props.question.get @_getQuestionAttrName sectionId
      # do not show "[comparator_test]" text
      placeholderString = utils.bracketize $.t 'es:question.comparator_test'
      if defaultContent is placeholderString then '' else defaultContent

  _getMainOutcomesString: ->
    mainOutcomesString = ''
    criticalOutcomesNames = @props.criticalOutcomes.map (outcome) -> outcome.get 'name'
    .toArray()

    if _(criticalOutcomesNames).isEmpty()
      mainOutcomesString
    else
      mainOutcomesString += '<ul>'
      _(criticalOutcomesNames).forEach (outcomeName) ->
        mainOutcomesString += "<li>#{outcomeName}</li>"
      mainOutcomesString += '</ul>'

  _getNoOfSectionsRows: ->
    # calculating number of sectionsRows depends on whether there are any secondColSections to be
    # rendered
    noOfActiveSecondColSections = @secondColSections.count (additionalSection, masterSection) =>
      @props.headerSections.has additionalSection

    @props.headerSections.count() - noOfActiveSecondColSections

  _secondColSectionsPresent: ->
    @props.headerSections.some (sectionData, sectionId) =>
      @secondColSections.includes sectionId

  _headerRowBuilder: (sectionData, sectionId) ->
    mayHaveSecondCollSection = @secondColSections.has sectionId
    if mayHaveSecondCollSection and @_secondColSectionsPresent()
      @_makeComplexHeaderRow sectionData, sectionId
    else
      # if section belongs to secondColSections - do not render it
      isSecondColSection = @secondColSections.includes sectionId
      @_makeRegularHeaderRow(sectionData, sectionId) unless isSecondColSection

  _makeComplexHeaderRow: (sectionData, sectionId) ->
    # check if additional section is active (checked in template editor)
    additionalSectionActive = @props.headerSections.has(@secondColSections.get sectionId)
    if additionalSectionActive
      # additional section is returned as Array, whose first element is sectionId and the
      # second one is sectionData (see .findEntry() docs for details)
      additionalSection = @props.headerSections.findEntry (v, k) =>
        k is @secondColSections.get sectionId
      # TODO: replace noOfSectionsRows with proper secondColSection rowspan calculation as
      # part of secondColSections  TODO (see secondColSections comment above)
      <HeaderRow
        ref={sectionId}
        key={sectionId}
        noOfSectionsRows={@_getNoOfSectionsRows()}
        sectionId={sectionId}
        data={sectionData}
        defaultContent={@_getDefaultContent(sectionId)}
        additionalSection={additionalSection}
        isEditing={@state.editing}
        handleUserInput={@handleUserInput}
      />
    else
      @_makeRegularHeaderRow sectionData, sectionId

  _makeRegularHeaderRow: (sectionData, sectionId) ->
    <HeaderRow
      ref={sectionId}
      key={sectionId}
      sectionId={sectionId}
      data={sectionData}
      defaultContent={@_getDefaultContent(sectionId)}
      secondColPlaceholderNeeded={not @_secondColSectionsPresent()}
      isEditing={@state.editing}
      handleUserInput={@handleUserInput}
    />

  _makeHeaderTitleRow: ->
    noOfSectionsRows = @_getNoOfSectionsRows()

    <HeaderTitleRow
      noOfSectionsRows={noOfSectionsRows}
      expanded={@state.expanded}
      editing={@state.editing}
      onEdit={@enableEdit}
      onSave={@handleSave}
      toggleExpand={@toggleExpand}
    />

  render: ->
    # return if question hasn't been fetched yet since call to it is async. Later on when Qeustion
    # store is updated render func will re-run and render the content
    return null if _(@props.question).isEmpty()

    <div className="recommendations-header">
      <table>
        <tbody>
          {@_makeHeaderTitleRow()}
          <tr>
            <td className="question first" colSpan="4">
              {@props.question.get 'question'}
            </td>
          </tr>
          {
            if @state.expanded
              @props.headerSections.map(@_headerRowBuilder).toList()
          }
        </tbody>
      </table>
    </div>

module.exports = Header
