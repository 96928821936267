var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "    <tr>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.editing : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.program(7, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        <td rowspan=\"2\" class=\"project-toolbar\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isMenuMode : depth0), {"name":"if","hash":{},"fn":this.program(10, data),"inverse":this.program(12, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        </td>\n\n    </tr>\n    <tr class=\"questions-list\">\n        <td></td>\n        <td></td>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isMenuMode : depth0), {"name":"if","hash":{},"fn":this.program(20, data),"inverse":this.program(22, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </td>\n    </tr>\n";
},"2":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "            <td class=\"toggle-button\">\n                <button class=\"arrow-right\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "show_questions_title", {"name":"i18n","hash":{},"data":data})))
    + "'></button>\n                <button class=\"arrow-down\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "hide_questions_title", {"name":"i18n","hash":{},"data":data})))
    + "'></button>\n            </td>\n            <td>\n                <input type=\"text\" id=\"orderNumber\" name=\"orderNumber\" value=\"\">\n            </td>\n            <td>\n                <button class=\"activate\"></button>\n                <input type=\"text\" id=\"name\" name=\"name\" value=\"\">\n                (";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.shared : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.program(5, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + ")\n            </td>\n            <td>\n                <input type=\"text\" id=\"customer\" name=\"customer\" value=\"\">\n            </td>\n            <td></td>\n";
},"3":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return " "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "shared", {"name":"i18n","hash":{},"data":data})))
    + " ";
},"5":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return " "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "private", {"name":"i18n","hash":{},"data":data})))
    + " ";
},"7":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "            <td class=\"toggle-button\">\n                <button class=\"arrow-right\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "show_questions_title", {"name":"i18n","hash":{},"data":data})))
    + "'></button>\n                <button class=\"arrow-down\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "hide_questions_title", {"name":"i18n","hash":{},"data":data})))
    + "'></button>\n            </td>\n            <td class=\"order-number\">"
    + escapeExpression(((helper = (helper = helpers.orderNumber || (depth0 != null ? depth0.orderNumber : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"orderNumber","hash":{},"data":data}) : helper)))
    + "</td>\n            <td>\n                <a class=\"caption\" href=\"#projects/"
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\"> <button class=\"activate\"></button>\n                    "
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\n                    (";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.shared : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.program(5, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ")\n                </a>\n            </td>\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.isMenuMode : depth0), {"name":"unless","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                <td>"
    + escapeExpression(((helper = (helper = helpers.customer || (depth0 != null ? depth0.customer : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"customer","hash":{},"data":data}) : helper)))
    + "</td>\n                <td class=\"date\">"
    + escapeExpression(((helper = (helper = helpers.date || (depth0 != null ? depth0.date : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"date","hash":{},"data":data}) : helper)))
    + "</td>\n";
},"10":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                <button class=\"share\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "share_title", {"name":"i18n","hash":{},"data":data})))
    + "'></button>\n";
},"12":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isActive : depth0), {"name":"if","hash":{},"fn":this.program(13, data),"inverse":this.program(18, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"13":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.editing : depth0), {"name":"if","hash":{},"fn":this.program(14, data),"inverse":this.program(16, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                    <button class=\"share\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "share_title", {"name":"i18n","hash":{},"data":data})))
    + "'></button>\n                    <button class=\"archive\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "archive_title", {"name":"i18n","hash":{},"data":data})))
    + "'></button>\n";
},"14":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                        <button class=\"save\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "save_title", {"name":"i18n","hash":{},"data":data})))
    + "'></button>\n";
},"16":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                        <button class=\"edit\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "edit_title", {"name":"i18n","hash":{},"data":data})))
    + "'></button>\n";
},"18":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                    <button class=\"activate action\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "activate_title", {"name":"i18n","hash":{},"data":data})))
    + "'></button>\n                    <button class=\"delete\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "delete_title", {"name":"i18n","hash":{},"data":data})))
    + "'></button>\n";
},"20":function(depth0,helpers,partials,data) {
  return "            <td class=\"questions-list-container\">\n";
  },"22":function(depth0,helpers,partials,data) {
  return "        <td colspan=\"3\" class=\"questions-list-container\">\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "projects:list", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}