var Tooltips, ViewTrait, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __slice = [].slice;

ViewTrait = require('base/lib/traits/view_trait');

mediator = require('mediator');

module.exports = Tooltips = (function(_super) {
  __extends(Tooltips, _super);

  function Tooltips() {
    return Tooltips.__super__.constructor.apply(this, arguments);
  }

  Tooltips.prototype.apply = function(view) {
    Tooltips.__super__.apply.apply(this, arguments);
    view.elsWithTooltips = [];
    this._addFunction(view, '_hideTooltips');
    this._addFunction(view, '_addTooltips');
    this._addFunction(view, 'render');
    return this._addFunction(view, 'dispose');
  };

  Tooltips.prototype.render = function(originalFunction, trait) {
    this._hideTooltips(this.elsWithTooltips);
    return originalFunction.call(this);
  };

  Tooltips.prototype._hideTooltips = function(originalFunction, trait, jqueryObject) {
    if (!(this.elsWithTooltips && jqueryObject)) {
      return;
    }
    mediator.tooltips.hideTooltip(jqueryObject);
    return this.elsWithTooltips = _(this.elsWithTooltips).difference(jqueryObject);
  };

  Tooltips.prototype._addTooltips = function() {
    var jqueryObject, originalFunction, rest, trait, type, _ref;
    originalFunction = arguments[0], trait = arguments[1], type = arguments[2], jqueryObject = arguments[3], rest = 5 <= arguments.length ? __slice.call(arguments, 4) : [];
    if (!(this.elsWithTooltips && jqueryObject)) {
      return;
    }
    this.elsWithTooltips = _(this.elsWithTooltips.concat(jqueryObject)).uniq();
    return (_ref = mediator.tooltips)[type].apply(_ref, [jqueryObject].concat(__slice.call(rest)));
  };

  Tooltips.prototype.dispose = function(originalFunction, trait) {
    this._hideTooltips(this.elsWithTooltips);
    this.elsWithTooltips = null;
    return originalFunction.call(this);
  };

  return Tooltips;

})(ViewTrait);
