var Chaplin, utils,
  __hasProp = {}.hasOwnProperty,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

Chaplin = require('chaplin');

utils = Chaplin.utils.beget(Chaplin.utils);

_(utils).extend({

  /*
  Wrap methods so they can be called before a deferred is resolved.
  The actual methods are called once the deferred is resolved.
  
  Parameters:
  
  Expects an options hash with the following properties:
  
  deferred
    The Deferred object to wait for.
  
  methods
    Either:
    - A string with a method name e.g. 'method'
    - An array of strings e.g. ['method1', 'method2']
    - An object with methods e.g. {method: -> alert('resolved!')}
  
  host (optional)
    If you pass an array of strings in the `methods` parameter the methods
    are fetched from this object. Defaults to `deferred`.
  
  target (optional)
    The target object the new wrapper methods are created at.
    Defaults to host if host is given, otherwise it defaults to deferred.
  
  onDeferral (optional)
    An additional callback function which is invoked when the method is called
    and the Deferred isn't resolved yet.
    After the method is registered as a done handler on the Deferred,
    this callback is invoked. This can be used to trigger the resolving
    of the Deferred.
  
  Examples:
  
  deferMethods(deferred: def, methods: 'foo')
    Wrap the method named foo of the given deferred def and
    postpone all calls until the deferred is resolved.
  
  deferMethods(deferred: def, methods: def.specialMethods)
    Read all methods from the hash def.specialMethods and
    create wrapped methods with the same names at def.
  
  deferMethods(
    deferred: def, methods: def.specialMethods, target: def.specialMethods
  )
    Read all methods from the object def.specialMethods and
    create wrapped methods at def.specialMethods,
    overwriting the existing ones.
  
  deferMethods(deferred: def, host: obj, methods: ['foo', 'bar'])
    Wrap the methods obj.foo and obj.bar so all calls to them are postponed
    until def is resolved. obj.foo and obj.bar are overwritten
    with their wrappers.
   */
  deferMethods: function(options) {
    var deferred, func, host, methods, methodsHash, name, onDeferral, target, _i, _len, _results;
    deferred = options.deferred;
    methods = options.methods;
    host = options.host || deferred;
    target = options.target || host;
    onDeferral = options.onDeferral;
    methodsHash = {};
    if (typeof methods === 'string') {
      methodsHash[methods] = host[methods];
    } else if (methods.length && methods[0]) {
      for (_i = 0, _len = methods.length; _i < _len; _i++) {
        name = methods[_i];
        func = host[name];
        if (typeof func !== 'function') {
          throw new TypeError("utils.deferMethods: method " + name + " not found on host " + host);
        }
        methodsHash[name] = func;
      }
    } else {
      methodsHash = methods;
    }
    _results = [];
    for (name in methodsHash) {
      if (!__hasProp.call(methodsHash, name)) continue;
      func = methodsHash[name];
      if (typeof func !== 'function') {
        continue;
      }
      _results.push(target[name] = utils.createDeferredFunction(deferred, func, target, onDeferral));
    }
    return _results;
  },
  createDeferredFunction: function(deferred, func, context, onDeferral) {
    if (context == null) {
      context = deferred;
    }
    return function() {
      var args;
      args = arguments;
      if (deferred.state() === 'resolved') {
        return func.apply(context, args);
      } else {
        deferred.done(function() {
          return func.apply(context, args);
        });
        if (typeof onDeferral === 'function') {
          return onDeferral.apply(context);
        }
      }
    };
  },
  isElementAttached: function(elem) {
    if (!elem) {
      return false;
    }
    return $.contains(document.body, elem.jquery ? elem[0] : elem);
  },
  hasValue: function(value) {
    return !(_.isNull(value) || _.isUndefined(value) || (_.isString(value) && _.string.trim(value) === ''));
  },
  toUnderscore: function(camelCaseString) {
    return camelCaseString.replace(/([A-Z])/g, function($1) {
      return "_" + $1.toLowerCase();
    });
  },
  dashToUnderscore: function(dashedString) {
    return dashedString.replace(/-/g, '_');
  },
  camelcaseToSentence: function(camelCaseString) {
    return camelCaseString.replace(/([A-Z])/g, function($1) {
      return " " + $1.toLowerCase();
    });
  },
  firstLetterToLowerCase: function(string) {
    return "" + (string.charAt(0).toLowerCase()) + (string.slice(1));
  },
  bracketize: function(str) {
    return "[" + str + "]";
  },
  uncapitalizeExceptAllCaps: function(str) {
    var firstWord;
    firstWord = _.string.words(str)[0];
    if (firstWord.toUpperCase() === firstWord) {
      return str;
    } else {
      return str.charAt(0).toLowerCase() + str.slice(1);
    }
  },
  cycleUp: function(currentValue, cycleLength) {
    if (currentValue === cycleLength - 1) {
      return 0;
    } else {
      return currentValue + 1;
    }
  },
  cycleDown: function(currentValue, cycleLength) {
    if (currentValue === 0) {
      return cycleLength - 1;
    } else {
      return currentValue - 1;
    }
  },
  getCaretPos: function(el) {
    var r, rc, re;
    if (el.selectionStart) {
      return el.selectionStart;
    } else if (document.selection) {
      el.focus();
      r = document.selection.createRange();
      if (r === null) {
        return 0;
      }
      re = el.createTextRange();
      rc = re.duplicate();
      re.moveToBookmark(r.getBookmark());
      rc.setEndPoint('EndToStart', re);
      return rc.text.length;
    }
    return 0;
  },
  getEventCoordinates: function(e) {
    var coord, posx, posy;
    if (e.pageX || e.pageY) {
      posx = e.pageX;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
      posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
    }
    return coord = {
      left: posx,
      top: posy
    };
  },
  setSelectionRange: function(input, selectionStart, selectionEnd) {
    var range;
    if (input.setSelectionRange) {
      input.focus();
      return input.setSelectionRange(selectionStart, selectionEnd);
    } else if (input.createTextRange) {
      range = input.createTextRange();
      range.collapse(true);
      range.moveEnd('character', selectionEnd);
      range.moveStart('character', selectionStart);
      return range.select();
    }
  },
  setCaretToPos: function(input, pos) {
    return this.setSelectionRange(input, pos, pos);
  },
  generateGUID: function() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r, v;
      r = Math.random() * 16 | 0;
      v = c === 'x' ? r : r & 0x3 | 0x8;
      return v.toString(16);
    });
  },
  longestCommonPrefix: function(strings, commonDelimiter) {
    var delimiterIndex, l, s1, s2;
    strings = strings.slice(0).sort();
    s1 = strings[0];
    l = s1.length;
    s2 = strings.pop();
    while (l && s2.indexOf(s1) === -1) {
      s1 = s1.substring(0, --l);
    }
    if (commonDelimiter && s2[s1.length] !== commonDelimiter) {
      delimiterIndex = s1.indexOf(commonDelimiter);
      s1 = s1.substring(0, delimiterIndex);
    }
    return s1;
  },
  detectRm5Encoding: function(file) {
    var detectingEncoding, reader;
    detectingEncoding = $.Deferred();
    reader = new FileReader();
    reader.onload = function(loadEvent) {
      var encoding;
      encoding = $.parseXML(loadEvent.target.result).xmlEncoding;
      return detectingEncoding.resolve(encoding);
    };
    reader.error = function(error) {
      return detectingEncoding.reject(error);
    };
    reader.readAsText(file);
    return detectingEncoding.promise();
  },
  isTraitEnabled: function(clazz, traitName) {
    var _ref;
    return __indexOf.call((_ref = clazz.traits) != null ? _ref : [], traitName) >= 0;
  },
  isWebkit: function() {
    return document.documentElement.style.hasOwnProperty('WebkitAppearance');
  },
  scrollbarWidth: function() {
    var child, parent, width;
    if (width === void 0) {
      parent = $('<div style="width:50px;height:50px;overflow:auto"><div/></div>').appendTo('body');
      child = parent.children();
      width = child.innerWidth() - child.height(99).innerWidth();
      parent.remove();
    }
    return width;
  },
  scrollTo: function(element, to, duration) {
    var animateScroll, change, currentTime, increment, start;
    start = element.scrollTop;
    change = to - start;
    currentTime = 0;
    increment = 20;
    animateScroll = function() {
      var val;
      currentTime += increment;
      val = utils.easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        return setTimeout(animateScroll, increment);
      }
    };
    return animateScroll();
  },
  easeInOutQuad: function(t, b, c, d) {
    t /= d / 2;
    if (t < 1) {
      return c / 2 * t * t + b;
    }
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  },
  arrayMedian: function(arr) {
    var half, numberSort;
    numberSort = function(a, b) {
      return a - b;
    };
    arr.sort(numberSort);
    half = Math.floor(arr.length / 2);
    if (arr.length % 2) {
      return arr[half];
    } else {
      return (arr[half - 1] + arr[half]) / 2.0;
    }
  },
  addToObjectWithMaxSize: function(obj, maxSize, keyValuePair, sortFn) {
    if (sortFn == null) {
      sortFn = null;
    }
    _(obj).extend(keyValuePair);
    if (_(obj).keys().length > maxSize) {
      return delete obj[_(obj).keys().sort(sortFn)[0]];
    }
  },
  parseFixedFloat: function(numberToParse, numberScale) {
    if (numberScale == null) {
      numberScale = 2;
    }
    return parseFloat(numberToParse).toFixed(numberScale);
  },
  isNumeric: function(obj) {
    return !jQuery.isArray(obj) && (obj - parseFloat(obj) + 1) >= 0;
  },
  millimetersToTwips: function(millimeters) {
    return Math.round(millimeters * 56.692913);
  }
});

module.exports = utils;

_.prototype.runEvery = function(ms) {
  return {
    interval: setInterval(this._wrapped, ms),
    "for": function(ms) {
      return setTimeout(((function(_this) {
        return function() {
          return clearInterval(_this.interval);
        };
      })(this)), ms);
    }
  };
};

_.functionName = function(f) {
  var _ref;
  return (_ref = f.name) != null ? _ref : f.toString().match(/function\s+([^( ]+)/)[1];
};

_["instanceof"] = function(obj, instances) {
  var instance, _i, _len;
  for (_i = 0, _len = instances.length; _i < _len; _i++) {
    instance = instances[_i];
    if (obj instanceof instance) {
      return true;
    }
  }
  return false;
};

_.indexObjectsArray = function(array, indexKey) {
  var idx;
  if (indexKey == null) {
    indexKey = 'index';
  }
  idx = -1;
  return _(array).map(function(obj) {
    obj[indexKey] = ++idx;
    return obj;
  });
};

_.namespace = function(module, body) {
  var bindFunctions, _ref;
  bindFunctions = function(exposePrivate) {
    return function(require) {
      var fn, name, ns, publicFns, _ref;
      publicFns = {};
      ns = {};
      _ref = body.call(ns, require);
      for (name in _ref) {
        fn = _ref[name];
        ns[name] = fn.bind(ns);
        if (name[0] !== '_') {
          publicFns[name] = ns[name];
        }
      }
      if (exposePrivate) {
        return ns;
      } else {
        return publicFns;
      }
    };
  };
  return module.exports = ((_ref = window.gdt) != null ? _ref.testMode : void 0) ? bindFunctions(true) : bindFunctions(false)(require);
};

$.fn.forceRedraw = function() {
  var el, previousDisplay;
  el = this[0];
  previousDisplay = el.style.display;
  el.style.display = 'none';
  el.offsetHeight;
  return el.style.display = previousDisplay;
};
