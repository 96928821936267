DialogBoxMixin = require 'components/mixins/dialog_box_mixin'
ImportanceEditor = require 'components/etd/soj_importance_editor'
Confirmation = require 'components/common/confirmation'
EtdActions = require 'actions/etd_actions'
Translation = require 'components/mixins/translation'

Importance = React.createClass
  mixins: [DialogBoxMixin, Translation('es:recommendations.table')]

  getDefaultProps: ->
    value: ''

  cancelCbk: ->
    @closeDialogBox()

  applyCbk: (newValue) ->
    @closeDialogBox()
    EtdActions.changeImportance @props.sectionKey, newValue

  edit: (e) ->
    props =
      cancelCbk: @cancelCbk
      applyCbk: @applyCbk
      value: @props.value

    @showInDialogBox e, ImportanceEditor, props, 'soj-importance-editor-dialog'

  confirmCbk: ->
    @closeDialogBox()
    EtdActions.clearImportance()

  clearAll: (e)->
    props =
      cancelCbk: @cancelCbk
      confirmCbk: @confirmCbk
      question: @i18n 'confirm_importance_clear'
    @showInDialogBox e, Confirmation, props, 'soj-clear-dialog'

    e.preventDefault()

  render: ->
    <td
      className={classNames 'importance', @props.value }
      onClick={@edit}
      onContextMenu={@clearAll}>
      {@i18n 'importance_value_' + @props.value if @props.value and @props.value isnt 'clear'}
    </td>

module.exports = Importance
