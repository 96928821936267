# based on https://github.com/lovasoa/react-contenteditable/blob/master/index.js
MediumEditor = require '../mixins/react-medium-editor'

ContentEditable = React.createClass
  mixins: [MediumEditor]

  getDefaultProps: ->
    contentEditable: true
    className: "content"

  shouldComponentUpdate: (nextProps, nextState) ->
    nextProps.html isnt @getDOMNode().innerHTML

  componentDidMount: ->
    @_focus()

  componentDidUpdate: ->
    @_focus()

  _focus: ->
    if @props.contentEditable
      @getDOMNode().focus()

  render: ->
    <div {...@props} dangerouslySetInnerHTML={{__html: @props.html}} />

module.exports = ContentEditable
