var ModalView, NewProjectView, Project, Shortcuts, mediator, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Project = require('models/project');

ModalView = require('base/views/modal_view');

template = require('views/templates/new_project');

mediator = require('mediator');

Shortcuts = require('base/lib/traits/shortcuts');

module.exports = NewProjectView = (function(_super) {
  __extends(NewProjectView, _super);

  function NewProjectView() {
    this.createProject = __bind(this.createProject, this);
    return NewProjectView.__super__.constructor.apply(this, arguments);
  }

  NewProjectView.prototype.template = template;

  NewProjectView.prototype.id = 'new-project-dialog';

  NewProjectView.prototype.title = $.t('projects:new_project_dialog.title');

  NewProjectView.prototype.shortcuts = {
    'enter': 'createProject',
    'esc': 'dispose'
  };

  NewProjectView.prototype.bindings = {
    'input#name': 'name',
    'input[name=type]': 'projectType',
    'input#customer': 'customer'
  };

  NewProjectView.prototype.initialize = function() {
    this.model = new Project({
      projectType: this.options.projectType,
      recommendationsMode: 'static'
    });
    this.options.cls = 'new-modal-dialog';
    if (!this.options.projectType) {
      this.options.position = 'top';
    }
    this.options.offsetY = 50;
    NewProjectView.__super__.initialize.apply(this, arguments);
    this.delegate('click', '.create', this.createProject);
    this.delegate('click', 'button.cancel', this.dispose);
    return this.modelBind('change', _(this._updateCreateButton).debounce(200));
  };

  NewProjectView.prototype.getTemplateData = function() {
    return {
      error: this.savingError,
      type: this.model.get('projectType'),
      isCustomer: mediator.services.switches.isOn('new_projects_list')
    };
  };

  NewProjectView.prototype.afterRender = function() {
    NewProjectView.__super__.afterRender.apply(this, arguments);
    this._updateCreateButton();
    this.$('#name').focus();
    return this.stickit();
  };

  NewProjectView.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    this.unstickit();
    return NewProjectView.__super__.dispose.apply(this, arguments);
  };

  NewProjectView.prototype._updateCreateButton = function() {
    if (this.disposed) {
      return;
    }
    return this.$('button.create').prop('disabled', !this.model.isValid(true));
  };

  NewProjectView.prototype.createProject = function() {
    var projectType;
    if (!this.model.isValid(true)) {
      return;
    }
    this.creating = true;
    projectType = this.model.get('projectType');
    return mediator.projects.create(this.model, {
      success: (function(_this) {
        return function() {
          return mediator.services.modules.enableModulesForPreset(projectType, _this.model).then(function() {
            return mediator.publish('!router:route', "/projects/" + _this.model.id, function(routed) {
              if (!routed) {
                throw new Error('Switching to newly created project was unsuccessful');
              }
              return _this.dispose();
            });
          });
        };
      })(this),
      error: (function(_this) {
        return function(err) {
          _this.savingError = $.t('projects:new_project_dialog.error', {
            error: err.message
          });
          _this.creating = false;
          return _this.render();
        };
      })(this)
    });
  };

  return NewProjectView;

})(ModalView);
