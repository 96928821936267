Confirmation = React.createClass

  render: ->
    <div className="confirmation">
      <div classNam="question">{@props.question}</div>
      <div className="buttons">
        <button className="btn" onClick={@props.cancelCbk}>Cancel</button>
        <button className="btn apply" onClick={@props.confirmCbk}>Yes</button>
      </div>
    </div>

module.exports = Confirmation
