var EditView, Focusable, SelectableAttrList, Shortcuts, View, boxTemplate, mediator, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('./view');

mediator = require('mediator');

Shortcuts = require('base/lib/traits/shortcuts');

Focusable = require('base/lib/traits/focusable');

SelectableAttrList = require('lib/traits/selectable_attr_list');

boxTemplate = require('base/views/templates/box');

utils = require('base/lib/utils');

module.exports = EditView = (function(_super) {
  __extends(EditView, _super);

  function EditView() {
    this.propagateCompoundChanges = __bind(this.propagateCompoundChanges, this);
    this.propagateSelectionChanges = __bind(this.propagateSelectionChanges, this);
    this.dispose = __bind(this.dispose, this);
    this.hideBox = __bind(this.hideBox, this);
    this.onFocusLost = __bind(this.onFocusLost, this);
    this.hide = __bind(this.hide, this);
    this.discardChanges = __bind(this.discardChanges, this);
    this.show = __bind(this.show, this);
    this.bindModelEvents = __bind(this.bindModelEvents, this);
    return EditView.__super__.constructor.apply(this, arguments);
  }

  EditView.TYPES = {
    SELECTION: {
      name: 'selectionList',
      propagateChangesAction: 'propagateSelectionChanges',
      selectItemAction: 'selectListItem',
      deselectItemAction: 'deselectListItem'
    },
    COMPOUND: {
      name: 'compoundForm',
      propagateChangesAction: 'propagateCompoundChanges',
      selectItemAction: 'selectCompoundFormItem',
      deselectItemAction: 'deselectCompoundFormItem'
    }
  };

  EditView.prototype.shortcuts = {
    "tab": "selectNextItem",
    "shift+tab": "selectPrevItem",
    "enter": "accept",
    "esc": "discardChanges",
    "ctrl+enter": "textAreaNextLine",
    "space": "openSelectionList"
  };

  EditView.prototype.selectionListShortcuts = {
    "down": "selectNextItem",
    "up": "selectPrevItem"
  };

  EditView.prototype.inputsSelector = 'input:visible, select, textarea, .selection-list-value';

  EditView.prototype.closeOnAccept = true;

  EditView.prototype.initialize = function() {
    EditView.__super__.initialize.apply(this, arguments);
    this.boxContainer = $('.outcomes-container');
    this.editType = this.options.type;
    if (this.editType === void 0) {
      throw new Error("Undefined edit type!");
    }
    this.additionalTemplateData = this.options.additionalTemplateData || {};
    this.$boxTemplate = $(boxTemplate());
    this.$boxTemplate.addClass("box-" + this.cid);
    this.delegate('click', this.gainFocus);
    this.delegate('click', '.edit-options-list li.edit-option', this.accept);
    this.delegate('mouseover', '.edit-options-list li.edit-option', this.changeListItemSelectionOnMouseOver);
    this.delegate('focus', this.inputsSelector, this.inputFocusHandler);
    this.delegate('click', '.close', this.hide);
    this.delegate('click', '.cancel', this.hide);
    this.delegate('click', '.apply', this.accept);
    this.delegate('click', '.edit-input-block', this.activateEditInputBlock);
    if (this.editType === EditView.TYPES.SELECTION) {
      _(this.shortcuts).extend(this.selectionListShortcuts);
    } else {
      this.shortcuts = _(this.shortcuts).omit(_(this.selectionListShortcuts).keys());
    }
    this._stopInputsPropagation = false;
    this.enable(Shortcuts);
    return this.enable(SelectableAttrList);
  };

  EditView.prototype.gainFocus = function(e) {
    e.stopPropagation();
    return mediator.setFocus(this);
  };

  EditView.prototype.bindModelEvents = function() {};

  EditView.prototype.show = function(parentView, targetSelector, template, model) {
    var showingEditView;
    if (this.model && !this.model.isValid(true)) {
      return;
    }
    showingEditView = $.Deferred();
    mediator.setFocus(this, (function(_this) {
      return function() {
        _this.target = parentView.$el.find(targetSelector);
        _this.template = template;
        _this.parentView = parentView;
        _this.model = model;
        _this.bindModelEvents();
        _this.model.store();
        _this.render();
        _this.box.find('.content').html(_this.el);
        _this._setBoxCss();
        showingEditView.resolve(_this);
        _this.refreshInputs();
        _this.initializeSelectionIndex();
        _this.delegateEvents();
        _this.bindValidationTooltips();
        return _this.trigger('opened');
      };
    })(this));
    return showingEditView;
  };

  EditView.prototype._setBoxCss = function() {
    var containerOffset, targetOffset;
    containerOffset = this.boxContainer.offset();
    targetOffset = this.target.offset();
    return this.box.css({
      display: 'block',
      left: 10 + Math.min(targetOffset.left - containerOffset.left, this.boxContainer.width() - this.box.width()),
      top: 10 + Math.min(targetOffset.top - containerOffset.top, this.boxContainer.height() - this.box.height())
    });
  };

  EditView.prototype.showSelectionList = function(e) {
    var $target, optionsList;
    e.stopPropagation();
    $target = $(e.target).closest('.selection-list-value');
    if (!$target.length) {
      $target = this.inputs.eq(this.selectionIndex);
    }
    if ($target.attr('disabled')) {
      return;
    }
    optionsList = this._prepareOptionsList($target.find('span.selection-list-options > option'));
    return this.createSelectionSubview($target, optionsList);
  };

  EditView.prototype.refreshInputs = function() {
    return this.inputs = this.$el.find(this.inputsSelector);
  };

  EditView.prototype.render = function() {
    EditView.__super__.render.apply(this, arguments);
    this.boxContainer.append(this.$boxTemplate);
    this.box = $(".box-" + this.cid);
    return this.box.click(function(e) {
      return e.stopPropagation();
    });
  };

  EditView.prototype.getTemplateData = function() {
    var data;
    data = EditView.__super__.getTemplateData.apply(this, arguments);
    data = _(data).extend(this.additionalTemplateData);
    return data;
  };

  EditView.prototype.initializeSelectionIndex = function() {
    var $currentLi, currentIndex, idx;
    idx = this.editType === EditView.TYPES.SELECTION ? (this.selectionSize = this.$('li.edit-option').length, $currentLi = this.$('li.edit-option[current=true]'), $currentLi.addClass('current'), currentIndex = this.$('li.edit-option').index($currentLi), currentIndex > 0 ? currentIndex : 0) : (this.selectionSize = this.inputs.length, 0);
    return this.setSelectionIndex(idx);
  };

  EditView.prototype.accept = function() {
    var acceptChangesDeferred, acceptingChanges;
    if (!this.parentView) {
      return;
    }
    if (this.accepting) {
      return;
    }
    this.accepting = true;
    acceptChangesDeferred = $.Deferred();
    acceptingChanges = acceptChangesDeferred.promise();
    acceptingChanges.done((function(_this) {
      return function(result) {
        var save;
        if (result) {
          _this.propagateChangesAndValidate();
          _this.afterChangesPropagation();
          _this.model.validate();
          if (_this.isReadyToSave()) {
            if (_this.closeOnAccept) {
              return _this.hide(save = true);
            } else {
              return _this.model.save();
            }
          } else {
            _this.accepting = false;
            return mediator.fm.setFocus(_this);
          }
        } else {
          return _this.hide();
        }
      };
    })(this));
    return this.acceptChangesConfirmation(acceptChangesDeferred);
  };

  EditView.prototype.isReadyToSave = function() {
    return this.model.isValid(true);
  };

  EditView.prototype.acceptChangesConfirmation = function(acceptChangesDeferred) {
    return acceptChangesDeferred.resolve(true);
  };

  EditView.prototype.discardChanges = function(e) {
    if (!this.parentView) {
      return;
    }
    return this.hide();
  };

  EditView.prototype.hide = function(save) {
    var close;
    if (save == null) {
      save = false;
    }
    close = (function(_this) {
      return function() {
        var accepted;
        if (_this.disposed) {
          return;
        }
        _this.trigger('closed');
        _this.undelegateEvents();
        _this.$el.remove();
        _this.hideBox();
        if (!save) {
          _this.model.restore();
        }
        _this.parentView.editClosed(accepted = save);
        _this.template = _this.parentView = void 0;
        mediator.actionsQueue.push("Closed edit view: " + (_.functionName(_this.constructor)));
        return _this.dispose();
      };
    })(this);
    if (save === true) {
      return this.model.save().then(close);
    } else {
      return this.model.fetch().then(close);
    }
  };

  EditView.prototype.onFocusLost = function() {
    return this.hide();
  };

  EditView.prototype.hideBox = function() {
    return this.box.hide();
  };

  EditView.prototype.dispose = function() {
    Backbone.Validation.unbind(this);
    this.unsubscribeAllEvents();
    $("div.box-" + this.cid).remove();
    return EditView.__super__.dispose.apply(this, arguments);
  };

  EditView.prototype.activateEditInputBlock = function(e) {
    var $target;
    $target = $(e.target).closest('.edit-input-block');
    if ($target.hasClass('active')) {
      return;
    }
    return this.setSelectionIndex(this.inputs.index($target.find(this.inputsSelector)));
  };

  EditView.prototype.openSelectionList = function(e) {
    if (!this.inputs.eq(this.selectionIndex).hasClass('selection-list-value')) {
      return;
    }
    return this.showSelectionList(e);
  };

  EditView.prototype.textAreaNextLine = function() {
    var $activeElement, activeElement, afterText, beforeText, currentPos, value;
    activeElement = document.activeElement;
    if (activeElement.tagName === 'TEXTAREA') {
      $activeElement = $(activeElement);
      value = $activeElement.val();
      currentPos = utils.getCaretPos(activeElement);
      beforeText = value.substr(0, currentPos);
      afterText = value.substr(currentPos);
      $activeElement.val(beforeText + '\n' + afterText);
      return utils.setCaretToPos(activeElement, currentPos + 1);
    }
  };

  EditView.prototype.getSelectedElement = function() {
    return this.$('li.edit-option').eq(this.selectionIndex);
  };

  EditView.prototype._setInputValue = function($input, value) {
    if ($input.hasClass('selection-list-value')) {
      $input.find('input').val(value);
    }
    if ($input.attr('type') === 'checkbox') {
      return $input.prop('checked', $input.prop('checked'));
    } else {
      return $input.val(value);
    }
  };

  EditView.prototype._getInputValue = function($input) {
    if ($input.hasClass('selection-list-value')) {
      return $input.find('input').val();
    } else if ($input.attr('type') === 'checkbox') {
      return $input.is(':checked');
    } else {
      return $input.val();
    }
  };

  EditView.prototype.prevalidate = function() {
    var attr, attrVal, _i, _len, _ref, _results;
    _ref = _.keys(this.model.attributes);
    _results = [];
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      attr = _ref[_i];
      if (!(this.model.isNumberPattern(attr))) {
        continue;
      }
      attrVal = this.model.attributes[attr];
      if (_.isString(attrVal)) {
        _results.push(this.model.set(attr, attrVal.replace(/,/, '.'), {
          silent: true
        }));
      } else {
        _results.push(void 0);
      }
    }
    return _results;
  };

  EditView.prototype.propagateChangesAndValidate = function() {
    var triggerChange;
    this[this.editType.propagateChangesAction](triggerChange = false);
    this.prevalidate();
    return this.model.validate();
  };

  EditView.prototype.propagateSelectionChanges = function(triggerChange) {
    var $listElement;
    if (triggerChange == null) {
      triggerChange = true;
    }
    $listElement = this.getSelectedElement();
    if ($listElement.parent().attr('name')) {
      this.model.set($listElement.parent().attr('name'), $listElement.attr('modifier'), {
        silent: true
      });
    }
    if (triggerChange) {
      return this.model.change();
    }
  };

  EditView.prototype._getInputAttrName = function($input) {
    if ($input.hasClass('selection-list-value')) {
      return $input.find('input').attr('name');
    } else {
      return $input.attr('name');
    }
  };

  EditView.prototype.propagateCompoundChanges = function(triggerChange) {
    var $input, attrName, changed, input, oldValue, value, _i, _len, _ref;
    if (triggerChange == null) {
      triggerChange = true;
    }
    changed = false;
    _ref = this.inputs;
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      input = _ref[_i];
      $input = $(input);
      attrName = this._getInputAttrName($input);
      if (attrName) {
        oldValue = this.model.get(attrName);
        value = this._getInputValue($input);
        if (!_.isEqual(oldValue, value)) {
          this.model.set(attrName, value, {
            silent: true
          });
          changed = true;
        }
      }
    }
    if (changed && triggerChange) {
      return this.model.change();
    }
  };

  EditView.prototype.afterChangesPropagation = function() {};

  EditView.prototype.changeListItemSelectionOnMouseOver = function(e) {
    return this.setSelectionIndex(this.$('li.edit-option').index($(e.target)));
  };

  EditView.prototype.selectCompoundFormItem = function(idx) {
    var input;
    input = this.inputs.eq(idx);
    input.focus();
    return input.closest('.edit-input-block').addClass('active');
  };

  EditView.prototype.deselectCompoundFormItem = function(idx) {
    var input;
    input = this.inputs.eq(idx);
    input.blur();
    return input.closest('.edit-input-block').removeClass('active');
  };

  EditView.prototype.selectListItem = function(idx) {
    var editOptions;
    editOptions = this.$('li.edit-option');
    return editOptions.eq(idx).addClass('active');
  };

  EditView.prototype.deselectListItem = function(idx) {
    var editOptions;
    editOptions = this.$('li.edit-option');
    return editOptions.eq(idx).removeClass('active');
  };

  EditView.prototype.inputFocusHandler = function(e) {
    return this.setFocusedItemActive(e);
  };

  EditView.prototype.setFocusedItemActive = function(e) {
    var inputElement, selectedIndex;
    inputElement = $(e.target).closest(this.inputsSelector);
    selectedIndex = this.inputs.index(inputElement);
    if (this.selectionIndex !== selectedIndex) {
      this.setSelectionIndex(selectedIndex);
    }
    if (this.editType === EditView.TYPES.COMPOUND) {
      return this.trigger('compoundItemFocused', inputElement);
    }
  };

  EditView.prototype.setSelectionIndex = function(idx) {
    this[this.editType.deselectItemAction](this.selectionIndex);
    this.selectionIndex = idx;
    this[this.editType.selectItemAction](this.selectionIndex);
    if (this.editType === EditView.TYPES.COMPOUND) {
      this.trigger('compoundSelectionIndexChanged', this.selectionIndex);
    }
    return true;
  };

  EditView.prototype.selectNextItem = function(e) {
    this.setSelectionIndex((this.selectionIndex + 1) % this.selectionSize);
    return false;
  };

  EditView.prototype.selectPrevItem = function(e) {
    var idx;
    idx = this.selectionIndex === 0 ? this.selectionSize - 1 : (this.selectionIndex - 1) % this.selectionSize;
    this.setSelectionIndex(idx);
    return false;
  };

  return EditView;

})(View);
