var ActiveFootnoteHighlight, CellFootnotesView, ContextMenuView, HelpBoxView, OutcomeEditRowView, Shortcuts, SortOutcomesView, Validation, View, W, WysiwygFootnotes, mediator, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

View = require('base/views/view');

mediator = require('mediator');

Validation = require('base/validation');

Shortcuts = require('base/lib/traits/shortcuts');

WysiwygFootnotes = require('lib/traits/wysiwyg_footnotes');

ActiveFootnoteHighlight = require('lib/traits/active_footnote_highlight');

CellFootnotesView = require('views/evidence_syntheses/cell_footnotes_view');

ContextMenuView = require('views/evidence_syntheses/context_menu_view');

SortOutcomesView = require('views/evidence_syntheses/sort_outcomes_view');

HelpBoxView = require('views/help_box_view');

utils = require('base/lib/utils');

W = require('when/when');

module.exports = OutcomeEditRowView = (function(_super) {
  __extends(OutcomeEditRowView, _super);

  function OutcomeEditRowView() {
    this.deleteOutcome = __bind(this.deleteOutcome, this);
    this.render = __bind(this.render, this);
    this._deleteCurrentOutcomeEditView = __bind(this._deleteCurrentOutcomeEditView, this);
    this._assignCurrentOutcomeEditView = __bind(this._assignCurrentOutcomeEditView, this);
    this._setPropertyAndPublishActivatedPropertyEvent = __bind(this._setPropertyAndPublishActivatedPropertyEvent, this);
    return OutcomeEditRowView.__super__.constructor.apply(this, arguments);
  }

  OutcomeEditRowView.prototype.tagName = 'tr';

  OutcomeEditRowView.prototype.shortcuts = {
    "tab": "selectNextItem",
    "shift+tab": "selectPrevItem",
    "down": "selectNextItem",
    "up": "selectPrevItem",
    "enter": "saveAndHideEdit"
  };

  OutcomeEditRowView.prototype.bindings = {
    'input[data-property=type]': 'type',
    'input[data-property=metaType]': 'metaType',
    'input[data-property=scaleFrom]': 'scaleFrom',
    'input[data-property=scaleTo]': 'scaleTo'
  };

  OutcomeEditRowView.prototype.inputsSelector = 'input, select, textarea';

  OutcomeEditRowView.prototype.initialize = function() {
    OutcomeEditRowView.__super__.initialize.apply(this, arguments);
    this.questionModel = this.options.questionModel;
    this.delegate('focus', 'select, input', this.setFocusedItemActive);
    this.delegate('click', '.outcome-label-edit button.save', this.saveAndHideEdit);
    this.delegate('click', '.outcome-sort-delete button.delete', this.deleteOutcome);
    this.delegate('click', '.outcome-sort-delete button.sort', this.sortOutcome);
    this.delegate('click', '.outcome-toggleable-editor, .outcome-label-edit', function(e) {
      e.stopPropagation();
      return mediator.setFocus(this);
    });
    this.delegate('contextmenu', 'input, label.radio-label', this.showContextMenu);
    this.modelBind('change:type change:metaType', (function(_this) {
      return function() {
        var _ref;
        return (_ref = _this.parentView) != null ? _ref.renderOutcomeRowsForItem(_this.model) : void 0;
      };
    })(this));
    this.modelBind('change:type', (function(_this) {
      return function() {
        return _this._metaTypeChangeds();
      };
    })(this));
    this.delegateFootnotesEvents();
    this._stopInputsPropagation = false;
    this.checkTableMode = true;
    this.enable(Shortcuts);
    this.enable(WysiwygFootnotes);
    return this.enable(ActiveFootnoteHighlight);
  };

  OutcomeEditRowView.prototype.sortOutcome = function(e) {
    var $target, sortOutcomesView, targetOffset;
    e.stopPropagation();
    sortOutcomesView = new SortOutcomesView({
      parentView: this,
      outcomesView: this.parentView
    });
    this.subview('sortOutcomesView', sortOutcomesView);
    $target = $(e.target);
    targetOffset = $target.offset();
    return sortOutcomesView.show(utils.getEventCoordinates(e));
  };

  OutcomeEditRowView.prototype._setPropertyAndPublishActivatedPropertyEvent = function(property, $target) {
    this.property = property;
    return this.publishEvent('activatedProperty', this.property, $target, this.questionModel, this.model);
  };

  OutcomeEditRowView.prototype.delegateFootnotesEvents = function() {};

  OutcomeEditRowView.prototype.showContextMenu = function(e) {
    mediator.setFocus(this, (function(_this) {
      return function() {
        var $input, $target, contextMenu;
        $target = $(e.target);
        $input = $target.hasClass('radio-label') ? $target.find('input[type=radio]') : $target;
        $input.trigger('focus');
        contextMenu = new ContextMenuView({
          questionModel: _this.questionModel,
          footnotesBindingModel: _this.model,
          property: _this.property,
          propertyView: _this,
          cell: $input,
          abortHandler: function() {
            return mediator.setFocus(_this);
          }
        });
        _this.subview('contextMenu', contextMenu);
        return contextMenu.show(utils.getEventCoordinates(e));
      };
    })(this));
    return false;
  };

  OutcomeEditRowView.prototype._assignCurrentOutcomeEditView = function() {
    return this.parentView.currentOutcomeEditView = this;
  };

  OutcomeEditRowView.prototype._deleteCurrentOutcomeEditView = function() {
    var _ref;
    return (_ref = this.parentView) != null ? _ref.currentOutcomeEditView = null : void 0;
  };

  OutcomeEditRowView.prototype.validateOutcome = function() {
    var $input, input, inputs, radioInputs, selectionInputs, textInputs, _i, _len;
    textInputs = this.$('input:text');
    radioInputs = this.$('input:radio:checked');
    selectionInputs = this.$('input.selection-input');
    inputs = $.merge(textInputs, radioInputs);
    inputs = $.merge(inputs, selectionInputs);
    for (_i = 0, _len = inputs.length; _i < _len; _i++) {
      input = inputs[_i];
      $input = $(input);
      if ($input.data('property')) {
        this.model.set($input.data('property'), $input.val(), {
          silent: true
        });
      }
    }
    this.model.validate();
    return this.model.isValid(true);
  };

  OutcomeEditRowView.prototype._checkTableMode = function() {
    if (!this.checkTableMode) {
      return W.resolve(true);
    }
    this.checkTableMode = false;
    return W.promise((function(_this) {
      return function(resolve) {
        if (_this.questionModel.get('$tableMode') === 'narrative' && _this.model.get('type') !== 'narrative') {
          mediator.dialogs.confirm({
            title: $.t('es:outcome.mode.change_to_regular'),
            message: $.t('es:outcome.mode.change_to_regular_desc'),
            yesClass: 'danger',
            buttons: [
              {
                'data-role': 'no',
                text: $.t('es:outcome.mode.keep_narrative_mode')
              }, {
                'data-role': 'yes',
                text: $.t('es:outcome.mode.change_to_regular')
              }
            ]
          }, function(change) {
            if (change) {
              _this.questionModel.set('$tableMode', 'regular', {
                auto: true
              });
            } else {
              _this.model.set('type', 'narrative');
            }
            return resolve(change);
          });
        } else if (_this.questionModel.get('$tableMode') !== 'narrative' && _this.questionModel.areAllOutcomesNarrative()) {
          _this.questionModel.set('$tableMode', 'narrative', {
            auto: true
          });
          resolve(true);
        } else {
          resolve(true);
        }
        return _this.checkTableMode = true;
      };
    })(this));
  };

  OutcomeEditRowView.prototype._isValid = function() {
    if (!this.validateOutcome()) {
      return W.resolve(false);
    }
    return this._checkTableMode();
  };

  OutcomeEditRowView.prototype.applyChangesAndClose = function() {
    this.model.save();
    this._deleteCurrentOutcomeEditView();
    return this.hideEdit();
  };

  OutcomeEditRowView.prototype.saveAndHideEdit = function(e) {
    if (e != null) {
      e.stopPropagation();
    }
    if (e != null) {
      e.preventDefault();
    }
    return this._isValid().then((function(_this) {
      return function(valid) {
        if (valid) {
          return _this.applyChangesAndClose();
        }
      };
    })(this));
  };

  OutcomeEditRowView.prototype.beforeFocusLost = function(deferred, focusTo) {
    if (this.changingFocusToSubview(focusTo)) {
      return deferred.resolve();
    }
    return this._isValid().then(function(valid) {
      if (valid) {
        return deferred.resolve();
      } else {
        deferred.reject();
        return false;
      }
    });
  };

  OutcomeEditRowView.prototype.onFocusLost = function(focusTo) {
    if (this.changingFocusToSubview(focusTo)) {
      return;
    }
    return this.applyChangesAndClose();
  };

  OutcomeEditRowView.prototype.hideEdit = function() {
    this.publishEvent('deactivatedProperty');
    if (this.disposed) {
      return;
    }
    return this.$('.outcome-toggleable-editor').slideUp((function(_this) {
      return function() {
        var _ref, _ref1, _ref2;
        if ((_ref = _this.parentView) != null) {
          _ref.renderItemViews(_this.model);
        }
        if ((_ref1 = _this.parentView) != null) {
          _ref1.trigger('!fixLayout');
        }
        if ((_ref2 = _this.parentView) != null ? _ref2.currentOutcomeEditView : void 0) {
          return typeof _this.hideEditCallback === "function" ? _this.hideEditCallback() : void 0;
        } else {
          return mediator.setFocus(function() {
            return typeof _this.hideEditCallback === "function" ? _this.hideEditCallback() : void 0;
          });
        }
      };
    })(this));
  };

  OutcomeEditRowView.prototype.render = function() {
    this.bindValidationTooltips();
    return OutcomeEditRowView.__super__.render.apply(this, arguments);
  };

  OutcomeEditRowView.prototype.afterRender = function() {
    OutcomeEditRowView.__super__.afterRender.apply(this, arguments);
    this.inputs = this.$el.find(this.inputsSelector);
    this._metaTypeChangeds();
    this.selectionSize = this.inputs.length;
    this.stickit();
    this.stickFootnotes(this.options.questionModel);
    return this.stickHighlightFootnotes();
  };

  OutcomeEditRowView.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    this.inputs = null;
    this.unstickit();
    this.unstickFootnotes();
    this.unstickHighlightFootnotes();
    return OutcomeEditRowView.__super__.dispose.apply(this, arguments);
  };

  OutcomeEditRowView.prototype.deleteOutcome = function(e) {
    var _ref;
    e.preventDefault();
    this.checkTableMode = false;
    this.model.collection.store();
    this.model.destroy();
    return (_ref = this.parentView) != null ? _ref.trigger('!fixLayout') : void 0;
  };

  OutcomeEditRowView.prototype.setFocusedItemActive = function(e) {
    this.setSelectionIndex(this.inputs.index($(e.target)));
    return true;
  };

  OutcomeEditRowView.prototype.setSelectionIndex = function(idx) {
    this.selectionIndex = idx;
    return this.inputs[this.selectionIndex].focus();
  };

  OutcomeEditRowView.prototype.getTemplateData = function() {
    var data;
    data = OutcomeEditRowView.__super__.getTemplateData.apply(this, arguments);
    data.cid = this.model.cid;
    data.isCaseControl = this.model.isCaseControl();
    data.viewTypeName = this.options.viewTypeName;
    data.colspan = this.options.colspan;
    return data;
  };

  OutcomeEditRowView.prototype.selectNextItem = function(e) {
    this.setSelectionIndex((this.selectionIndex + 1) % this.selectionSize);
    return false;
  };

  OutcomeEditRowView.prototype.selectPrevItem = function(e) {
    var idx;
    idx = this.selectionIndex === 0 ? this.selectionSize - 1 : (this.selectionIndex - 1) % this.selectionSize;
    this.setSelectionIndex(idx);
    return false;
  };

  OutcomeEditRowView.prototype._metaTypeChangeds = function() {
    var disableValues, _ref;
    if (this.model.get('type') === 'narrative') {
      disableValues = ['POOLED', 'SINGLE_STUDY', 'RANGE_OF_EFFECTS'];
      if (_ref = this.model.get('metaType'), __indexOf.call(disableValues, _ref) >= 0) {
        this.model.set('metaType', 'NOT_POOLED');
      }
      _(this.$('input[data-property="metaType"]')).chain().filter(function(item) {
        var _ref1;
        return _ref1 = item.value, __indexOf.call(disableValues, _ref1) >= 0;
      }).each(function(item) {
        return $(item).prop('disabled', true);
      });
    } else {
      this.$('input[data-property="metaType"]').prop('disabled', false);
    }
    if (this.model.get('type') === 'cont') {
      return this.$('.range-of-scores').show();
    } else {
      return this.$('.range-of-scores').hide();
    }
  };

  return OutcomeEditRowView;

})(View);
