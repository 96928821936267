var Controller, HeaderController, HeaderView, Projects, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Controller = require('controllers/base/controller');

Projects = require('models/projects');

HeaderView = require('views/header_view');

mediator = require('mediator');

module.exports = HeaderController = (function(_super) {
  __extends(HeaderController, _super);

  function HeaderController() {
    return HeaderController.__super__.constructor.apply(this, arguments);
  }

  HeaderController.prototype.initialize = function() {
    HeaderController.__super__.initialize.apply(this, arguments);
    this.collection = mediator.projects;
    return this.view = new HeaderView({
      collection: this.collection
    });
  };

  return HeaderController;

})(Controller);
