ContentEditableWithButtons = require 'components/common/content_editable_with_buttons'
GeneralScopeActions = require 'actions/general_scope_actions'
GeneralScopeStore = require 'stores/general_scope_store'

GeneralScopeRow = React.createClass
  render: ->
    <tr>
      <td className="title-cell">{@props.title}</td>
      <td className="value-cell">
        <ContentEditableWithButtons
          actions={GeneralScopeActions}
          id={@props.fieldName}
          content={@props.content}
          currentEditable={@props.currentEditable}
          ref="contentEditable"
          />
      </td>
      <td className="description-cell">{@props.description}</td>
    </tr>

module.exports = GeneralScopeRow
