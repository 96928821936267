var AuthenticationView, Exceptions, RegistrationView, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

AuthenticationView = require('views/authentication_view');

Exceptions = require('lib/exceptions');

template = require('./templates/registration');

mediator = require('mediator');

module.exports = RegistrationView = (function(_super) {
  __extends(RegistrationView, _super);

  function RegistrationView() {
    return RegistrationView.__super__.constructor.apply(this, arguments);
  }

  RegistrationView.prototype.template = template;

  RegistrationView.prototype.className = 'registration-view';

  RegistrationView.prototype.container = 'body';

  RegistrationView.prototype.initialize = function() {
    RegistrationView.__super__.initialize.apply(this, arguments);
    this.delegate('submit', 'form', function() {
      this._signUp();
      return false;
    });
    this.delegate('focus change keyup', 'input.login', this.loginTooltip);
    this.delegate('click', 'button.login-switch', this._loginView);
    return this.options.authenticationService.getLocalAccountInformation().then((function(_this) {
      return function(accounts) {
        _this.render();
        if (accounts.length > 0 && _this.options.allowSwitchView) {
          return _this._loginView();
        }
      };
    })(this), function(error) {
      return mediator.dialogs.fatalError(error);
    });
  };

  RegistrationView.prototype.loginTooltip = function(e) {
    var target;
    target = this.$(e.target);
    if (target.val().length !== 0) {
      return this._addTooltips('hideTooltip', target);
    } else if (!target.data('tooltip-id')) {
      return this._addTooltips('loginInfo', target, $.t('registration.login_tooltip'));
    }
  };

  RegistrationView.prototype._signUp = function() {
    var email, password, passwordRepeated, tosAccepted, validationData;
    email = this.$('input.email').val();
    password = this.$('input.password').val();
    passwordRepeated = this.$('input.repeat-password').val();
    tosAccepted = this.$('input.tos').is(':checked');
    validationData = [this._validateEmail(email), this._validatePassword(password), this._validateRepeatPassword(passwordRepeated), this._validateTos(tosAccepted)];
    if (__indexOf.call(validationData, false) < 0) {
      this.$('.error').empty();
      this._toggleLoadingState(this.$('.sign-up'), $.t('registration.in_progress'));
      return this._register(email, password, tosAccepted, 0);
    }
  };

  RegistrationView.prototype._register = function(email, password, tosAccepted, attemptCount) {
    var login;
    login = this.options.authenticationService.emailToLogin(email, attemptCount);
    return this.options.authenticationService.register(login, password, email, tosAccepted).then((function(_this) {
      return function() {
        return _this._logIn(login, password);
      };
    })(this), (function(_this) {
      return function(error) {
        var errorMsgBox, forgotPasswordButton;
        if (error instanceof Exceptions.authentication.login_in_use) {
          return _this._register(email, password, tosAccepted, attemptCount + 1);
        } else if (error instanceof Exceptions.authentication.email_in_use) {
          errorMsgBox = _this.$('.error.email');
          errorMsgBox.text(error.message);
          forgotPasswordButton = $('<button type="button" class="forgot-password">');
          forgotPasswordButton.text($.t('registration.forgot_password'));
          forgotPasswordButton.on('click', _this._forgotPasswordView);
          errorMsgBox.append('<br>');
          return errorMsgBox.append(forgotPasswordButton);
        } else {
          return _this.$('.error-shared').text(error.message);
        }
      };
    })(this)).ensure((function(_this) {
      return function() {
        if (!_this.disposed) {
          return _this._toggleLoadingState(_this.$('.sign-up'), $.t('registration.in_progress'));
        }
      };
    })(this));
  };

  RegistrationView.prototype.afterRender = function() {
    RegistrationView.__super__.afterRender.apply(this, arguments);
    return this.$('.email').focus();
  };

  return RegistrationView;

})(AuthenticationView);
