DialogBox = require 'components/common/dialog_box'

DialogBoxMixin =

  showInDialogBox: (e, InnerComponent, innerComponentProps, dialogClass) ->
    @boxContainer = $('<div></div>')
    $('body').append @boxContainer
    React.render <DialogBox offset={$(e.target).offset()} dialogClass={dialogClass}>
        <InnerComponent {...innerComponentProps}/>
      </DialogBox>
    , @boxContainer[0]

  closeDialogBox: ->
    React.unmountComponentAtNode @boxContainer[0]
    @boxContainer.remove()

module.exports = DialogBoxMixin
