var CoiMemberView, CoiMembersView, CollectionView, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

CollectionView = require('base/views/collection_view');

CoiMemberView = require('views/team/coi_member_view');

template = require('views/templates/team/coi_members');

module.exports = CoiMembersView = (function(_super) {
  __extends(CoiMembersView, _super);

  function CoiMembersView() {
    return CoiMembersView.__super__.constructor.apply(this, arguments);
  }

  CoiMembersView.prototype.template = template;

  CoiMembersView.prototype.itemView = CoiMemberView;

  CoiMembersView.prototype.container = '.coi-members-container';

  CoiMembersView.prototype.listSelector = 'ul';

  CoiMembersView.prototype.animationDuration = 10;

  CoiMembersView.prototype.className = 'coi-members-list';

  return CoiMembersView;

})(CollectionView);
