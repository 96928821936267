var Draggable, ViewTrait,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ViewTrait = require('./view_trait');

module.exports = Draggable = (function(_super) {
  __extends(Draggable, _super);

  function Draggable() {
    return Draggable.__super__.constructor.apply(this, arguments);
  }

  Draggable.prototype.draggingBoundaries = '#page-container';

  Draggable.prototype.apply = function(view) {
    Draggable.__super__.apply.apply(this, arguments);
    return this._addFunction(view, 'makeDraggable');
  };

  Draggable.prototype.makeDraggable = function(originalFunction, trait, elOrSelector) {
    var $el;
    if (elOrSelector == null) {
      elOrSelector = this.$el;
    }
    $el = _.isString(elOrSelector) ? this.$(elOrSelector) : $(elOrSelector);
    $el.draggable({
      containment: trait.draggingBoundaries,
      start: function() {
        $el.removeClass('draggable');
        return $el.addClass('dragging');
      },
      stop: function() {
        $el.removeClass('dragging');
        return $el.addClass('draggable');
      }
    });
    return $el.addClass('draggable');
  };

  return Draggable;

})(ViewTrait);
