Translation = require 'components/mixins/translation'
EtdTemplatesActions = require 'actions/etd_templates_actions'
PureRenderMixin = React.addons.PureRenderMixin
ButtonsRow = require 'components/common/buttons_row'
Button = require 'components/common/button'

TemplateButtons = React.createClass
  displayName: 'TemplateButtons'

  mixins: [Translation('administration:etd_templates'), PureRenderMixin]

  cancelEdit: ->
    EtdTemplatesActions.revertChanges()
    EtdTemplatesActions.cancelEdit()

  closeEdit: ->
    EtdTemplatesActions.cancelEdit()

  templateInUseButtons: ->
    <ButtonsRow stickToBottom={selector: '.selected-template-block', containerPadding: 12}>
      <Button
        className="cancel"
        label={@i18n 'cancel'}
        onClick={@cancelEdit}/>
      {if @props.templateChanged
        <Button
          className="save-changes"
          label={@i18n 'save_changes'}
          onClick={@props.saveTemplateChanges}/>
      else
        <Button
          label={@i18n 'ok'}
          onClick={@closeEdit}/>
      }
    </ButtonsRow>

  templateNotInUseButtons: ->
    <ButtonsRow stickToBottom={selector: '#page-container', containerPadding: 20}>
      <Button
        className="revert"
        onClick={EtdTemplatesActions.revertChanges}
        disabled={not @props.templateChanged}
        label={@i18n 'revert_to_original'}
      />
      <Button
        className="use-template"
        onClick={@props.useCurrentTemplate}
        label={@i18n 'use_this_template'}
      />
    </ButtonsRow>

  render: ->
    if @props.templateInUse
      @templateInUseButtons()
    else
      @templateNotInUseButtons()

module.exports = TemplateButtons
