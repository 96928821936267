var EtdActions, Presentations, PresentationsView, ReactComponent, View,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

ReactComponent = require('base/lib/traits/react_component');

Presentations = require('components/etd/presentations');

EtdActions = require('actions/etd_actions');

module.exports = PresentationsView = (function(_super) {
  __extends(PresentationsView, _super);

  function PresentationsView() {
    return PresentationsView.__super__.constructor.apply(this, arguments);
  }

  PresentationsView.prototype.id = 'presentations';

  PresentationsView.prototype.autoRender = false;

  PresentationsView.prototype.initialize = function() {
    this.enable(ReactComponent);
    return PresentationsView.__super__.initialize.apply(this, arguments);
  };

  PresentationsView.prototype.afterRender = function() {
    PresentationsView.__super__.afterRender.apply(this, arguments);
    return this.renderComponent(Presentations, EtdActions, this.model.get('recommendationIds'));
  };

  return PresentationsView;

})(View);
