var Ellipsis, MemberApprovalStatusView, View, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

Ellipsis = require('base/lib/traits/ellipsis');

template = require('views/templates/scope/member_approval_status');

module.exports = MemberApprovalStatusView = (function(_super) {
  __extends(MemberApprovalStatusView, _super);

  function MemberApprovalStatusView() {
    return MemberApprovalStatusView.__super__.constructor.apply(this, arguments);
  }

  MemberApprovalStatusView.prototype.tagName = 'tr';

  MemberApprovalStatusView.prototype.template = template;

  MemberApprovalStatusView.prototype.initialize = function() {
    MemberApprovalStatusView.__super__.initialize.apply(this, arguments);
    return this.enable(Ellipsis);
  };

  MemberApprovalStatusView.prototype.afterRender = function() {
    MemberApprovalStatusView.__super__.afterRender.apply(this, arguments);
    this.$el.toggleClass('new', this.model.get('status') === 'new');
    return this.$el.addClass((function() {
      switch (this.model.get('approved')) {
        case 'yes':
          return 'approved';
        case 'no':
          return 'not-approved';
      }
    }).call(this));
  };

  return MemberApprovalStatusView;

})(View);
