Member = require './member'
MembersStore = require 'stores/members_store'
MembersActions = require 'actions/members_actions'
EditableItemsList = require 'components/common/editable_items_list'
Translation = require 'components/mixins/translation'

Members = React.createClass
  mixins: [Translation('team:member')]

  displayName: 'Members'

  getInitialState: ->
    members: MembersStore.getState().get 'members'
    _meta: MembersStore.getState().get '_meta'

  componentDidMount: ->
    MembersStore.listen @onChange

  componentWillUnmount: ->
    MembersStore.unlisten @onChange

  onChange: (state) ->
    @setState
      members: state.get('members')
      _meta: state.get('_meta')

  render: ->
    <div>
      <EditableItemsList
        itemsMap={@state.members}
        editingKey={@state._meta.get('editing')}
        validationErrors={@state._meta.get('validationErrors')}
        ItemComponent={Member}
        actions={MembersActions}
      />
      <div className="row mt-10">
        <div className="col-12">
          <button className="btn btn-block add" onClick={MembersActions.add}>
            <span>{@i18n 'add_title'}</span>
          </button>
        </div>
      </div>
    </div>

module.exports = Members
