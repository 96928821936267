var Collection, CollectionView, NextStepButton, QuestionForProposalView, QuestionsProposalView, TableScrolling, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

CollectionView = require('base/views/collection_view');

Collection = require('models/base/collection');

QuestionForProposalView = require('views/scope/question_for_proposal_view');

NextStepButton = require('views/scope/next_step_button_view');

TableScrolling = require('base/lib/traits/table_scrolling');

template = require('views/templates/scope/questions_proposal');

mediator = require('mediator');

module.exports = QuestionsProposalView = (function(_super) {
  __extends(QuestionsProposalView, _super);

  function QuestionsProposalView() {
    return QuestionsProposalView.__super__.constructor.apply(this, arguments);
  }

  QuestionsProposalView.prototype.className = 'questions-proposal';

  QuestionsProposalView.prototype.container = '#page-container';

  QuestionsProposalView.prototype.template = template;

  QuestionsProposalView.prototype.itemView = QuestionForProposalView;

  QuestionsProposalView.prototype.listSelector = 'tbody';

  QuestionsProposalView.prototype.initialize = function() {
    this.collection = new Collection(this.collection.models);
    this.collection.comparator = function(model) {
      return -model.get('medianRating');
    };
    this.collection.sort();
    this._setInitialStatuses();
    QuestionsProposalView.__super__.initialize.apply(this, arguments);
    return this.enable(TableScrolling, {
      content: '.questions',
      fixedElements: '.sticky-header, .information, .bottom-bar'
    });
  };

  QuestionsProposalView.prototype._setInitialStatuses = function() {
    var medianRating, question, _i, _len, _ref;
    _ref = this.collection.models;
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      question = _ref[_i];
      if (question.get('approvalStatus')) {
        return;
      }
      medianRating = question.get('medianRating');
      question.set('approvalStatus', (function() {
        switch (false) {
          case !(medianRating >= 4):
            return 'accepted';
          case !(medianRating > 2):
            return 'mentioned';
          default:
            return 'rejected';
        }
      })());
      question.save();
    }
  };

  QuestionsProposalView.prototype.afterRender = function() {
    QuestionsProposalView.__super__.afterRender.apply(this, arguments);
    if (this.options.preview) {
      this.$('input[type="radio"]').prop('disabled', true);
    }
    return this.subview('next-step', new NextStepButton({
      step: 'questions.proposal',
      container: this.$('.bottom-bar')
    }));
  };

  QuestionsProposalView.prototype.getTemplateData = function() {
    return _(QuestionsProposalView.__super__.getTemplateData.apply(this, arguments)).extend({
      preview: this.options.preview
    });
  };

  return QuestionsProposalView;

})(CollectionView);
