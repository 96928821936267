var CalendarView, CollectionView, Event, Scrolling, Shortcuts, TaskView, TasksView, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

CollectionView = require('base/views/collection_view');

CalendarView = require('views/tasks/calendar_view');

TaskView = require('views/tasks/task_view');

Event = require('models/event');

Shortcuts = require('base/lib/traits/shortcuts');

Scrolling = require('base/lib/traits/scrolling');

template = require('views/templates/tasks/tasks');

mediator = require('mediator');

module.exports = TasksView = (function(_super) {
  __extends(TasksView, _super);

  function TasksView() {
    return TasksView.__super__.constructor.apply(this, arguments);
  }

  TasksView.prototype.container = '#page-container';

  TasksView.prototype.className = 'tasks-page';

  TasksView.prototype.template = template;

  TasksView.prototype.itemView = TaskView;

  TasksView.prototype.animationDuration = 10;

  TasksView.prototype.listSelector = 'ul';

  TasksView.prototype.addCollectionSizeValidation = true;

  TasksView.prototype.shortcuts = {
    'a': 'addTask'
  };

  TasksView.prototype.initialize = function() {
    this.enable(Shortcuts);
    this.enable(Scrolling, {
      fixedElements: 'menu.buttons'
    });
    TasksView.__super__.initialize.apply(this, arguments);
    this.subview('calendar', new CalendarView({
      collection: this.collection,
      parentView: this
    }));
    return this.delegate('click', 'button.add', this.addTask);
  };

  TasksView.prototype.afterRender = function() {
    TasksView.__super__.afterRender.apply(this, arguments);
    return this.$('#calendar-container').html(this.subview('calendar').render().el);
  };

  TasksView.prototype.addTask = function() {
    return mediator.setFocus(this, (function(_this) {
      return function() {
        return _this.collection.add(new Event());
      };
    })(this));
  };

  return TasksView;

})(CollectionView);
