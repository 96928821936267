Translation = require 'components/mixins/translation'
AssessmentSection = require 'components/etd/assessment_section'
PureRenderMixin = React.addons.PureRenderMixin
Tooltip = require 'components/common/tooltip'

Assessment = React.createClass
  displayName: 'AssessmentSectionsContainer'
  mixins: [Translation('es:recommendations.table'), PureRenderMixin]

  # this sets a proper rowspan for each shared cell. Shared cells are ReseachEvidence and
  # Additional Considerations. They are called shared, becasue can be common for several
  # sections. That is why for example if both Desirable and Undesirable effects have one
  # Research Evidence cell shared within both, then this cell should have rowspan = 2.
  # Also this function sets the domainSection attribute - which tells which section's row
  # should own this Research Evidence cell.
  setCalcRowSpan: (type, sharedCells) ->
    sharedCells.map (cellDataObj, sharedCellId) =>
      # since sections share same Id of a shared cell simple calculation of how many sections
      # sharedCellId will give us a rowspan
      rowSpan = @props.sections.count (sectionData) ->
        sectionData.get("#{type}Id") is sharedCellId
      # .findEntry returns the first accurance of truthy resolved predicate, which means the
      # first section row in a future EtD table - exactly where it is needed to inject a shared
      # cell
      domainSection = (@props.sections.findEntry (sectionData) ->
        sectionData.get("#{type}Id") is sharedCellId)?[0]
      return unless domainSection
      cellDataObj.set 'rowSpan', rowSpan
      .set 'domainSection', domainSection

  render: ->
    if @props.sections
      researchEvidences = @setCalcRowSpan 'researchEvidence', @props.researchEvidences
      additionalConsiderations = @setCalcRowSpan 'additionalConsideration',
        @props.additionalConsiderations
      <table className="standard-table judgement-table">
        <colgroup>
          <col className="blank" />
          <col className="criteria" />
          <col className="judgements" />
          <col className="research-evidences" />
          <col className="additional-considerations" />
        </colgroup>
        <thead>
          <tr className="headers">
            <th className="blank"></th>
            <th className="criteria with-info">
              <Tooltip>
                <span className="info-sign" title={@i18n 'tooltips.criteria'}></span>
              </Tooltip>
              {@i18n 'criteria'}
            </th>
            <th className="judgements with-info">
              <Tooltip>
                <span className="info-sign" title={@i18n 'tooltips.judgements'}></span>
              </Tooltip>
              {@i18n 'judgement'}
            </th>
            <th className="research-evidences with-info">
              <Tooltip>
                <span className="info-sign" title={@i18n 'tooltips.research_evidence'}></span>
              </Tooltip>
              {@i18n 'research_evidence'}
            </th>
            <th className="additional-considerations with-info">
              <Tooltip>
                <span className="info-sign" title={@i18n 'tooltips.additional_considerations'}></span>
              </Tooltip>
              {@i18n 'additional_considerations'}
            </th>
          </tr>
        </thead>
        <tbody>
        {
          @props.sections.map (sectionData, sectionId) =>
            <AssessmentSection
              currentEditable={@props.currentEditable}
              attachments={@props.attachments}
              key={sectionId}
              sectionId={sectionId}
              sectionData={sectionData}
              questionType={@props.questionType}
              criticalOutcomes={@props.criticalOutcomes}
              sectionName={sectionData.get 'name'}
              criterion=
                {@props.criterions.get sectionData.get('criterionId')}
              researchEvidence=
                {researchEvidences.get sectionData.get('researchEvidenceId')}
              additionalConsiderations=
                {additionalConsiderations.get sectionData.get('additionalConsiderationId')}
            />
          .toList()
        }
        </tbody>
      </table>
    else
      null

module.exports = Assessment
