Modal = React.createClass
  displayName: 'Modal'

  propTypes:
    title: React.PropTypes.string.isRequired
    isOpen: React.PropTypes.bool.isRequired
    onClose: React.PropTypes.func.isRequired
    closeButton: React.PropTypes.bool

  render: ->
    ReactModal.setAppElement(document.body)

    <ReactModal isOpen={@props.isOpen} className={@props.className}>
      <h2>
        {@props.title}
        {if @props.closeButton
          <div className="modal-close" onClick={@props.onClose}></div>
        }
      </h2>
      {@props.children}
    </ReactModal>

module.exports = Modal
