var FormsApiHelper, Model, TeamMember, W, mediator,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __slice = [].slice,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

Model = require('models/base/model');

FormsApiHelper = require('base/lib/forms_api_helper').coi;

mediator = require('mediator');

W = require('when');

module.exports = TeamMember = (function(_super) {
  __extends(TeamMember, _super);

  function TeamMember() {
    this._setFormStatus = __bind(this._setFormStatus, this);
    this.checkCoiFormStatus = __bind(this.checkCoiFormStatus, this);
    return TeamMember.__super__.constructor.apply(this, arguments);
  }

  TeamMember.prototype.validation = {
    lastName: {
      required: true
    },
    email: {
      pattern: 'email',
      required: true
    }
  };

  TeamMember.prototype.getFullName = function() {
    return [this.get('givenNames'), this.get('lastName')].join(' ');
  };

  TeamMember.prototype.checkCoiFormStatus = function() {
    var formId;
    formId = this.get('coiFormId');
    if (!formId) {
      return;
    }
    return FormsApiHelper.getFormDoc(formId).then((function(_this) {
      return function(resp) {
        return _this._setFormStatus(resp.status);
      };
    })(this))["catch"]((function(_this) {
      return function(response) {
        if (response === 404) {
          _this.unset('coiFormId');
          return _this.save();
        } else {
          return _this._setFormStatus('connection-error');
        }
      };
    })(this));
  };

  TeamMember.prototype._setFormStatus = function(newStatus) {
    if (this.get('coiFormStatus') !== newStatus) {
      return this.save({
        coiFormStatus: newStatus
      });
    }
  };

  TeamMember.prototype.getCoiFormStatus = function() {
    if (!this.get('coiFormId')) {
      return 'not-sent';
    }
    return this.get('coiFormStatus');
  };

  TeamMember.prototype.hasCoiFormStatus = function() {
    var statuses, _ref;
    statuses = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
    return _ref = this.getCoiFormStatus(), __indexOf.call(statuses, _ref) >= 0;
  };

  TeamMember.prototype.hasConflict = function() {
    var question, _i, _len, _ref;
    if (this.get('coiFormStatus') !== 'completed') {
      return false;
    }
    _ref = mediator.questions.models;
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      question = _ref[_i];
      if (question.get('coi')[this.id]) {
        return true;
      }
    }
    return false;
  };

  return TeamMember;

})(Model);
