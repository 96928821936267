var DbHelper, HtmlExport, MobilePublicationService, W;

DbHelper = require('base/lib/db_helper');

HtmlExport = require('lib/html_export');

W = require('when');

module.exports = MobilePublicationService = (function() {
  function MobilePublicationService() {}

  MobilePublicationService.prototype._mapQualityJudgement = function(qualityJudgement) {
    var qualities;
    qualities = {
      'very_low': 1,
      'low': 2,
      'moderate': 3,
      'high': 4
    };
    return qualities[qualityJudgement];
  };

  MobilePublicationService.prototype._getSoF = function(question) {
    var htmlExport;
    htmlExport = new HtmlExport(question, 'sof');
    return htmlExport.getSource();
  };

  MobilePublicationService.prototype._getAttr = function(q, v, defaultKey) {
    var defaultValue, _ref, _ref1;
    defaultValue = defaultKey ? q.get(defaultKey) : void 0;
    return (_ref = (_ref1 = q.get(v)) != null ? _ref1 : defaultValue) != null ? _ref : '';
  };

  MobilePublicationService.prototype._processQuestion = function(q, original) {
    var balance;
    if (original == null) {
      original = false;
    }
    balance = q.get('consequencesBalance');
    return {
      id: q.id,
      sofTableAvailable: true,
      sofSource: this._getSoF(q),
      text: this._getAttr(q, 'question'),
      benefitsAndHarms: original ? this._getAttr(q, 'benefitsHarmsAdditionalConsiderations') : this._getAttr(q, 'benefitsAndHarmsForMobile', 'benefitsHarmsAdditionalConsiderations'),
      valuesAndPreferences: this._getAttr(q, 'valuesPreferencesExplanation'),
      remarks: original ? this._getAttr(q, 'recommendationJustification') : this._getAttr(q, 'remarksForMobile', 'recommendationJustification'),
      resources: this._getAttr(q, 'incrementalCostToNetBenefitsAdditionalConsiderations'),
      evidenceQuality: this._mapQualityJudgement(this._getAttr(q, 'overallCertaintyJudgement')),
      recommendations: [
        {
          text: this._getAttr(q, 'recommendationText'),
          strength: (balance != null ? balance.indexOf('probably') : void 0) === 0 ? 1 : (balance != null ? balance.indexOf('clearly') : void 0) === 0 ? 2 : void 0
        }
      ]
    };
  };

  MobilePublicationService.prototype["export"] = function(project) {
    return project.getDocFor('comparisons_question_groups').then((function(_this) {
      return function(groups) {
        var caption, idx, questionGroups;
        questionGroups = (function() {
          var _i, _len, _ref, _results;
          _ref = groups.getGroupCaptions();
          _results = [];
          for (idx = _i = 0, _len = _ref.length; _i < _len; idx = ++_i) {
            caption = _ref[idx];
            _results.push({
              id: idx,
              title: caption,
              questions: []
            });
          }
          return _results;
        })();
        return project.getCollections().questions.fetch().then(function(questions) {
          var questionsWoGroup;
          questionsWoGroup = [];
          questions.each(function(question) {
            var groupIdx, questionData;
            questionData = _this._processQuestion(question);
            groupIdx = groups.getGroupIndexForQuestionId(question.id);
            if (groupIdx != null) {
              return questionGroups[groupIdx].questions.push(questionData);
            } else {
              return questionsWoGroup.push(questionData);
            }
          });
          if (questionsWoGroup.length) {
            questionGroups.push({
              id: questionGroups.length,
              title: $.t('dissemination:publish.questions_wo_group'),
              questions: questionsWoGroup
            });
          }
          return {
            questionGroups: questionGroups,
            title: project.get('name'),
            background: project.get('documentSections')
          };
        });
      };
    })(this));
  };

  MobilePublicationService.prototype.originalEtdFields = function(question) {
    return this._processQuestion(question, true);
  };

  MobilePublicationService.prototype.publishPreview = function(project) {
    var updateOrCreate, url;
    url = DbHelper.getMobilePreviewDocUrl(project.id);
    updateOrCreate = function(id, newContent) {
      return W($.get(url)).then(function(doc) {
        return $.parseJSON(doc);
      })["catch"](function(xhr) {
        if (xhr.status === 404) {
          return {
            _id: id
          };
        } else {
          throw new Exceptions.server_error;
        }
      }).then(function(doc) {
        var content;
        content = _(newContent).extend(_(doc).pick('_id', '_rev'));
        return W($.ajax(url, {
          type: 'PUT',
          data: JSON.stringify(content)
        }));
      });
    };
    return this["export"](project).then(function(previewDoc) {
      return updateOrCreate(project.id, previewDoc).then(function() {
        return DbHelper.getMobilePreviewUrl(project.id);
      });
    });
  };

  return MobilePublicationService;

})();
